/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState } from 'react';
import WebFont from 'webfontloader';
import {
  withStyles,
  Fab,
  Typography,
  IconButton,
  InputAdornment,
  Box,
  createTheme,
  ThemeProvider,
  Grid,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string';
import styles from './styles';
import { Card, CardBody } from '~/components/Card';
import logo from '~/assets/logo_horizontal.svg';
import { loginUser } from '~/store/auth/actions';
import MCTextField from '~/components/MCTextField';
import { isAuthenticated } from '~/services/authentication';

const title = createTheme({
  typography: {
    fontFamily: 'Montserrat',
  },
});

const SignIn = ({
  auth,
  history,
  location,
  classes,
  t: translate,
  loginUser: login,
  errors,
}) => {
  const [state, setState] = useState({
    email: '',
    password: '',
    showPassword: false,
  });

  const { expired } = queryString.parse(location.search);

  useEffect(() => {
    if (isAuthenticated() || auth.isAuthenticated) {
      history.push('/');
    }

    WebFont.load({ google: { families: ['Montserrat:700'] } });
  }, [auth.isAuthenticated, history]);

  const handleInputChange = name => ({ target }) => {
    setState(s => ({ ...s, [name]: target.value }));
  };

  const handleShowPassword = () => {
    setState(s => ({ ...s, showPassword: !s.showPassword }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { email, password } = state;
    const user = { email, password };
    login(user);
  };

  const hasError = () => Boolean(errors.length > 0);

  return (
    <div className={classes.background}>
      <form className={classes.formSignin} onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={8} style={{ alignSelf: 'center' }}>
            <ThemeProvider theme={title}>
              <Typography
                variant="h5"
                align="center"
                gutterBottom
                style={{ color: 'white' }}
              >
                {translate('common:WelcomeTo')}
              </Typography>
              <Typography
                variant="h2"
                align="center"
                gutterBottom
                style={{ color: 'white' }}
              >
                {translate('common:MiningControl')}
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item xs={4}>
            <Card login>
              <CardBody style={{ textAlign: 'center' }}>
                <Box textAlign={'center'} margin={'15px'}>
                  <img src={logo} alt="''" style={{ width: '50%' }} />
                </Box>
                <ThemeProvider theme={title}>
                  <Typography variant="h5" align="center" gutterBottom>
                    {translate('common:AccessYourAccount').toUpperCase()}
                  </Typography>
                </ThemeProvider>
                {expired === 'true' && (
                  <Typography
                    align="center"
                    style={{
                      fontFamily: 'Roboto',
                      fontStyle: 'normal',
                      fontSize: 14,
                      textAlign: 'center',
                      color: '#EC3337',
                      marginLeft: 10,
                    }}
                    gutterBottom
                  >
                    {translate('validation:ExpiredSession')}
                  </Typography>
                )}
                <MCTextField
                  label="E-mail"
                  name="E-mail"
                  id="E-mail"
                  margin="normal"
                  variant="outlined"
                  onChange={handleInputChange('email')}
                  value={state.email}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                    htmlFor: 'E-mail',
                  }}
                  error={hasError()}
                />
                <MCTextField
                  label={translate('common:Password')}
                  fullWidth
                  type={state.showPassword ? 'text' : 'password'}
                  onChange={handleInputChange('password')}
                  value={state.password}
                  name="Password"
                  id="Password"
                  margin="normal"
                  variant="outlined"
                  error={hasError()}
                  inputProps={{
                    className: classes.customHeight,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    htmlFor: 'Password',
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          classes={{
                            root: classes.textFieldPassword,
                          }}
                          onClick={handleShowPassword}
                        >
                          {state.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {hasError() ? (
                  <Typography
                    variant="body2"
                    align="center"
                    gutterBottom
                    color="error"
                  >
                    {errors[0].message}
                  </Typography>
                ) : null}

                <Fab
                  variant="extended"
                  size="large"
                  className={classes.signInButton}
                  disabled={!state.email || !state.password}
                  type="submit"
                >
                  {translate('common:Enter').toUpperCase()}
                </Fab>
              </CardBody>
            </Card>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  loginUser: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors.errors,
});

export default connect(mapStateToProps, { loginUser })(
  withStyles(styles)(
    withTranslation()(SignIn),
  ),
);

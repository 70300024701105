import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import FireIdTab from './FireIdTab';
import DrillholeTypeTab from './DrillholeTypeTab';
import DrillholeDepthTab from './DrillholeDepthTab';
import DrillholeFanTab from './FandrillFanTab';
import DrillholeBitTab from './FandrillBitTab';
import DrillholeBitNameTab from './FandrillBitNameTab';
import JumboTab from './JumboTab';
import PF2MTabs from '~/components/PF2MTabs';
import { setAlertDialog } from '~/store/manager/actions';


const Drilling = () => {
  const isFireIdsDirty = useSelector(state => state.manager.isFireIdsDirty);
  const isDrillholeTypesDirty = useSelector(state => state.manager.isDrillholeTypesDirty);
  const isDrillholeDepthsDirty = useSelector(state => state.manager.isDrillholeDepthsDirty);
  const isFandrillFansDirty = useSelector(state => state.manager.isFandrillFansDirty);
  // eslint-disable-next-line max-len
  const isFandrillBitDiametersDirty = useSelector(state => state.manager.isFandrillBitDiametersDirty);
  const isFandrillBitDiametersNamesDirty = useSelector(
    state => state.manager.isFandrillBitDiametersNamesDirty,
  );

  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(0);
  const { t: translate } = useTranslation();

  const tabs = [
    { key: 0, label: translate('common:FireID') },
    { key: 1, label: translate('common:HoleType') },
    { key: 2, label: translate('common:Rod') },
    { key: 3, label: translate('common:Fan') },
    { key: 4, label: translate('common:BitDiameter') },
    { key: 5, label: translate('common:BitDiameterName') },
    { key: 6, label: translate('common:JumboTab') },
  ];

  const handleChange = (event, value) => {
    let blockChange = false;

    switch (value) {
      case 1:
        blockChange = isFireIdsDirty || isDrillholeDepthsDirty
          || isFandrillFansDirty || isFandrillBitDiametersDirty
          || isFandrillBitDiametersNamesDirty;
        break;
      case 2:
        blockChange = isFireIdsDirty || isDrillholeTypesDirty
          || isFandrillFansDirty || isFandrillBitDiametersDirty
          || isFandrillBitDiametersNamesDirty;
        break;
      case 3:
        blockChange = isFireIdsDirty || isDrillholeTypesDirty
          || isDrillholeDepthsDirty || isFandrillBitDiametersDirty
          || isFandrillBitDiametersNamesDirty;
        break;
      case 4:
        blockChange = isFireIdsDirty || isDrillholeTypesDirty
          || isDrillholeDepthsDirty || isFandrillFansDirty
          || isFandrillBitDiametersNamesDirty;
        break;
      case 5:
        blockChange = isFireIdsDirty || isDrillholeTypesDirty
          || isDrillholeDepthsDirty || isFandrillFansDirty
          || isFandrillBitDiametersDirty;
        break;
      default:
        blockChange = isDrillholeTypesDirty || isDrillholeDepthsDirty
          || isFandrillFansDirty || isFandrillBitDiametersDirty
          || isFandrillBitDiametersNamesDirty;
        break;
    }

    if (blockChange) {
      event.preventDefault();
      dispatch(setAlertDialog());
    } else {
      setSelectedTab(value);
    }
  };

  return (
    <>
      <PF2MTabs
        value={selectedTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        {tabs}
      </PF2MTabs>
      {selectedTab === 0 ? (<FireIdTab />) : null}
      {selectedTab === 1 ? (<DrillholeTypeTab />) : null}
      {selectedTab === 2 ? (<DrillholeDepthTab />) : null}
      {selectedTab === 3 ? (<DrillholeFanTab />) : null}
      {selectedTab === 4 ? (<DrillholeBitTab />) : null}
      {selectedTab === 5 ? (<DrillholeBitNameTab />) : null}
      {selectedTab === 6 ? (<JumboTab />) : null}
    </>
  );
};

export default withStyles(styles)(Drilling);

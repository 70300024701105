import React, { useState, useEffect } from 'react';
import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PF2MSortButton from '~/components/PF2MSortButton';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import PF2MScrollbar from '~/components/PF2MScrollbar';
import {
  searchHourMachineList,
  deleteHourMachineItem,
  setHourMachineValues,
  updateHourMachineList,
  restoreOriginalHourMachine,
  createHourMachineItem,
} from '~/store/dailyparts/actions';
import { getCodeTypes } from '~/store/manager/actions';
import { dailyPartsGridHeight } from '~/utils';
import { useTranslation } from 'react-i18next';
import Header from '../Header';
import styles from '../styles';
import ListRow from './ListRow';

const HourMachineList = ({ classes }) => {
  const { t: translate } = useTranslation();

  const titles = [
    { label: translate('common:Date'), field: 'timestamp_op' },
    { label: translate('common:Time'), field: 'timestamp_op' },
    { label: translate('common:CodeType'), field: 'code_type' },
    { label: translate('common:CodeGroup'), field: 'code_group' },
    { label: translate('common:Code'), field: 'code_id' },
    { label: translate('common:OperatorGroup'), field: 'operator_group' },
    { label: translate('common:Operator'), field: 'operator_id' },
    { label: translate('common:EquipmentType'), field: 'equip_type' },
    { label: translate('common:EquipmentGroup'), field: 'equip_group' },
    { label: translate('common:Equipment'), field: 'equip_id' },
    { label: translate('common:Condition'), field: 'condition' },
    { label: `${translate('common:Hourmeter')} 1`, field: 'hourmeter_1' },
    { label: `${translate('common:Hourmeter')} 2`, field: 'hourmeter_2' },
    { label: `${translate('common:Hourmeter')} 3`, field: 'hourmeter_3' },
    { label: `${translate('common:Hourmeter')} 4`, field: 'hourmeter_4' },
    { label: `${translate('common:Hourmeter')} 5`, field: 'hourmeter_5' },
    { label: `${translate('common:Hourmeter')} 6`, field: 'hourmeter_6' },
    { label: `${translate('common:Hourmeter')} 7`, field: 'hourmeter_7' },
    { label: `${translate('common:Hourmeter')} 8`, field: 'hourmeter_8' },
    { label: `${translate('common:Hourmeter')} 9`, field: 'hourmeter_9' },
    { label: `${translate('common:Hourmeter')} 10`, field: 'hourmeter_10' },
  ];
  const hourMachineList = useSelector(state => state.dailyParts.hourMachineList);
  const filters = useSelector(state => state.dailyParts.filters);
  const page = useSelector(state => state.dailyParts.page);
  const limit = useSelector(state => state.dailyParts.limit);
  const { field, order } = useSelector(state => state.dailyParts.codeSortBy);
  const turns = useSelector(state => state.manager.turns);
  const teams = useSelector(state => state.manager.teams);
  const codeTypes = useSelector(state => state.manager.codeTypes.map(e => ({ ...e, name: translate(`common:${e.name}`) })), shallowEqual);
  const equipmentTypes = useSelector(state => state.manager.equipmentTypes);
  const equipmentsGroups = useSelector(state => state.manager.equipmentsGroups);
  const equipments = useSelector(state => state.manager.equipments);
  const operatorsGroups = useSelector(state => state.manager.operatorsGroups);
  const operators = useSelector(state => state.manager.operators);
  const codeGroups = useSelector(state => state.manager.codeGroups);
  const codes = useSelector(state => state.manager.codes);
  const elements = useSelector(state => state.manager.elements);
  const subElements = useSelector(state => state.manager.subElements);
  const exceptionTypes = useSelector(state => state.manager.exceptionTypes);
  const isHourMachineListDirty = useSelector(state => state.dailyParts.isHourMachineListDirty);
  const [sortBy, setSortBy] = useState({ field, order });
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchCodeTypes() {
      dispatch(getCodeTypes());
    }

    if (codeTypes.length <= 0) {
      fetchCodeTypes();
    }
  });

  const handleSort = (title) => {
    if (hourMachineList.some(t => t.isDirty)) {
      setIsAlertOpen(true);
    } else {
      const newOrder = (sortBy.order === 'asc' && sortBy.field === title.field) ? 'desc' : 'asc';
      const newSortBy = { field: title.field, order: newOrder };
      setSortBy(newSortBy);
      dispatch(searchHourMachineList(filters, page, limit, newSortBy));
    }
  };

  const renderTableTitle = (title, key) => (
    <TableCell
      align="center"
      className={classes.tableCell}
      key={key}
      style={{
        position: 'sticky',
        top: 0,
        backgroundColor: 'white',
        zIndex: 1,
        textOverflow: 'ellipsis',
      }}
    >
      <PF2MSortButton
        label={title.label}
        field={title.field}
        sortField={sortBy.field}
        sortOrder={sortBy.order}
        onClick={() => handleSort(title)}
      />
    </TableCell>
  );

  const renderTableHeader = () => titles.map((t, i) => renderTableTitle(t, `${i}_${t}`));

  const renderRows = () => hourMachineList
    .map(d => (
      <ListRow
        key={d.id}
        data={d}
        onDelete={data => dispatch(deleteHourMachineItem(data))}
        onRestore={data => dispatch(restoreOriginalHourMachine(data))}
        onEdit={(item, name, value) => dispatch(setHourMachineValues(item, name, value))}
        classes={classes}
        turns={turns.filter(o => o.name)}
        teams={teams}
        codeTypes={codeTypes}
        equipmentTypes={equipmentTypes}
        equipmentsGroups={equipmentsGroups}
        equipments={equipments}
        operatorsGroups={operatorsGroups.filter(o => o.name)}
        operators={operators.filter(o => o.name)}
        codeGroups={codeGroups}
        codes={codes}
        elements={elements}
        subElements={subElements}
        exceptionTypes={exceptionTypes}
      />
    ));

  return (
    <>
      <Header
        updateData={(data, filter) => dispatch(updateHourMachineList(data, filter))}
        restoreData={() => dispatch(restoreOriginalHourMachine())}
        createData={filter => dispatch(createHourMachineItem(filter))}
        data={hourMachineList}
        filters={filters}
        isDirty={isHourMachineListDirty}
      />
      {hourMachineList.length > 0 ? (
        <PF2MScrollbar style={{ backgroundColor: 'white', height: dailyPartsGridHeight }}>
          <>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {renderTableHeader()}
                </TableRow>
              </TableHead>
              <TableBody>
                {renderRows()}
              </TableBody>
            </Table>
            <PF2MAlertDialog
              hasCancel={false}
              confirmText={translate('common:Understood')}
              description={translate('validation:YouHaveUnsavedData')}
              open={isAlertOpen}
              onConfirm={() => setIsAlertOpen(false)}
            />
          </>
        </PF2MScrollbar>
      ) : (
        <div
          className="container"
          style={{
            backgroundColor: 'white',
            padding: 20,
          }}
        >
          <span>{translate('common:NoEntries')}</span>
        </div>
      )}
    </>
  );
};

HourMachineList.propTypes = {
  classes: PropTypes.object.isRequired,
};

HourMachineList.defaultProps = {};

export default withStyles(styles)(HourMachineList);

import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import withModal from '~/components/PF2MModal/withModal';
import { Card, CardBody } from '~/components/Card';
import ModalHeader from '~/components/PF2MModal/ModalHeader';
import { ListItemText } from '@material-ui/core';
import PF2MScrollbar from '~/components/PF2MScrollbar';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import { useTranslation } from 'react-i18next';
import PF2MButton from '~/components/PF2MButton';
import { DeleteOutline, Done } from '@material-ui/icons';
import { getCodes, setActivityTypesChecklist } from '~/store/manager/actions';
import DelayedInput from '~/components/PF2MDelayedInput';

function useRequestCodeFilter() {
  const INIT_VALUE = useMemo(() => ({
    // salva valores que significam todos
    name: '',
    equipmentType: null,
    codeType: null,
    codeGroup: null,
  }), []);
  const [filter, setFilter] = useState(INIT_VALUE);
  const dispatch = useDispatch();
  const codes = useSelector(st => st.manager.codes.filter(e => e.id !== -14), shallowEqual);

  const filteredCodes = useMemo(() => {
    if (!Object.values(filter).some(Boolean)) return [];
    return codes
      .filter(c => c.active)
      .filter((c) => {
        if (filter.name) return c.name.toLowerCase().includes(filter.name.toLowerCase());
        return true;
      })
      .filter((c) => {
        if (filter.equipmentType === INIT_VALUE.equipmentType) return true;
        return c.id_equip === filter.equipmentType;
      })
      .filter((c) => {
        if (filter.codeType === INIT_VALUE.codeType) return true;
        return c.code_type === filter.codeType;
      })
      .filter((c) => {
        if (filter.codeGroup === INIT_VALUE.codeGroup) return true;
        return c.id_group === filter.codeGroup;
      });
  }, [codes, filter, INIT_VALUE]);

  useEffect(() => {
    dispatch(getCodes());
  }, [dispatch]);

  const setFilters = useMemo(() => ({
    setName: value => setFilter(f => ({ ...f, name: value })),
    setEquipmentType: value => setFilter(f => ({ ...f, equipmentType: value })),
    setCodeType: value => setFilter(f => (
      f.codeType !== value
        ? ({ ...f, codeType: value, codeGroup: null })
        : f
    )),
    codeGroup: value => setFilter(f => ({ ...f, codeGroup: value })),
  }), []);

  return [filter, filteredCodes, setFilters, INIT_VALUE];
}

function useAssociatedCodes(modalData) {
  const [stageCodes, setStageCodes] = useState(modalData.associated_codes.filter(c => c.active));
  const codesActivitySet = useMemo(
    () => new Set(stageCodes.filter(c => c.update_status !== 'D').map(c => c.code_id)),
    [stageCodes],
  );
  const hasChange = useMemo(() => stageCodes.some(c => c.update_status), [stageCodes]);

  const setStage = {
    addCode: code => setStageCodes((stCodes) => {
      let isOnArray = false;
      const newList = stCodes.map((c) => {
        if (c.code_id === code.id_pk) {
          isOnArray = true;
          const { update_status, ...newCode } = c;
          return newCode;
        }
        return c;
      });

      if (!isOnArray) {
        const newCode = {
          activity_type_checklist_id: modalData.id,
          equipment_type_id: code.id_equip,
          code_group_id: code.id_group,
          code_id: code.id_pk,
          active: true,
          update_status: 'I',
        };
        newList.push(newCode);
      }
      return newList;
    }),
    removeCode: code => setStageCodes(stCodes => stCodes.map((c) => {
      if (c.code_id !== code.id_pk) return c;
      if (c.update_status === undefined) return { ...c, update_status: 'D' }; // mark to delete
      if (c.update_status === 'I') return undefined; // filter after the map
      return c;
    })
      .filter(Boolean)),

    discartAll: () => setStageCodes(stCodes => stCodes
      .filter(c => c.update_status !== 'I')
      .map(({ update_status, ...c }) => c)),
  };

  return [stageCodes, codesActivitySet, hasChange, setStage];
}

// eslint-disable-next-line no-unused-vars
const RequestModal = React.forwardRef(({ modalData, closeModal, classes }, _ref) => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const [filter, codes, setFilter, filterInit] = useRequestCodeFilter();
  const [stageCodes, codesActivitySet, hasChange, setStage] = useAssociatedCodes(modalData);
  const equipmentTypes = useSelector(st => st.manager.equipmentTypes);
  const codeTypes = useSelector(st => st.manager.codeTypes
    .map(e => ({ ...e, name: translate(`common:${e.name}`) })));

  const codeGroups = useSelector(st => st.manager.codeGroups);

  return (
    <Card style={{ width: 800, height: 600 }}>
      <ModalHeader closeModal={closeModal} title={translate('common:RequestByCode')} />

      <CardBody>
        <div className={classes.containerTypeSelector}>
          <div>
            <p className={classes.labelTypeSelector}>
              {translate('common:FilterByEquipType')}
            </p>
            <PF2MSearchSelectOutlined
              value={filter.equipmentType}
              onChange={e => setFilter.setEquipmentType(e.target.value)}
              className={classes.typeSelector}
              clearedValue={filterInit.equipmentType}
            >
              {[
                { value: filterInit.equipmentType, label: translate('common:Select') },
                ...equipmentTypes
                  .map(et => ({ label: et.name, value: et.id })),
              ]}
            </PF2MSearchSelectOutlined>
          </div>
          <div>
            <p className={classes.labelTypeSelector}>
              {translate('common:CodeType')}
            </p>
            <PF2MSearchSelectOutlined
              value={filter.codeType}
              onChange={e => setFilter.setCodeType(e.target.value)}
              clearedValue={filterInit.codeType}
              className={classes.typeSelector}
            >
              {[
                { value: filterInit.codeType, label: translate('common:Select') },
                ...codeTypes
                  .map(ct => ({ label: ct.name, value: ct.id })),
              ]}
            </PF2MSearchSelectOutlined>
          </div>
          <div>
            <p className={classes.labelTypeSelector}>
              {translate('common:CodeGroup')}
            </p>
            <PF2MSearchSelectOutlined
              value={filter.codeGroup}
              onChange={e => setFilter.codeGroup(e.target.value)}
              className={classes.typeSelector}
              clearedValue={filterInit.codeGroup}
            >
              {[
                { value: filterInit.codeGroup, label: translate('common:Select') },
                ...codeGroups
                  .filter(cg => cg.code_type === filter.codeType)
                  .filter(cg => cg.id_equip === filter.equipmentType)
                  .map(cg => ({ label: cg.name, value: cg.id })),
              ]}
            </PF2MSearchSelectOutlined>
          </div>
        </div>

        <div className={classes.inputRequestFilter}>
          <div style={{ display: 'flex', flexWrap: 'no-wrap', padding: 10 }}>
            <Checkbox
              checked={codes.length && codes.every(c => codesActivitySet.has(c.id_pk))}
              onChange={(e) => {
                if (e.target.checked) {
                  codes.forEach(setStage.addCode);
                } else {
                  codes.forEach(setStage.removeCode);
                }
              }}
            />
            <ListItemText secondary={translate('common:SelectAll')} style={{ padding: 0 }} />
          </div>
          <DelayedInput
            value={filter.name}
            className={classes.typeSelector}
            delayedChange={setFilter.setName}
            delay={350}
          />
        </div>

        <div>
          <PF2MScrollbar className={classes.scrollbars}>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}>
              {
                codes.map(code => (
                  <div key={code.id_pk} style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                      checked={codesActivitySet.has(code.id_pk)}
                      color="primary"
                      name="associated_codes"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setStage.addCode(code);
                        } else {
                          setStage.removeCode(code);
                        }
                      }}
                    />
                    <ListItemText secondary={code.name} style={{ padding: 0 }} />
                  </div>
                ))
              }
            </div>
          </PF2MScrollbar>
        </div>

        <PF2MButton
          color="primary"
          onClick={setStage.discartAll}
          disabled={!hasChange}
        >
          <DeleteOutline className={classes.icon} />
          {translate('common:DiscardChanges').toUpperCase()}
        </PF2MButton>
        <PF2MButton
          onClick={() => {
            dispatch(setActivityTypesChecklist(modalData, 'associated_codes', stageCodes));
            closeModal();
          }}
          disabled={!hasChange}
        >
          <Done className={classes.icon} />
          {translate('common:End').toUpperCase()}
        </PF2MButton>
      </CardBody>
    </Card>
  );
});

RequestModal.propTypes = {
  modalData: PropTypes.shape({
    id: PropTypes.number,
    associated_codes: PropTypes.arrayOf(
      PropTypes.shape({ name: PropTypes.string.isRequired }),
    ).isRequired,
  }),
  closeModal: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    containerTypeSelector: PropTypes.string.isRequired,
    labelTypeSelector: PropTypes.string.isRequired,
    scrollbars: PropTypes.string.isRequired,
    typeSelector: PropTypes.string.isRequired,
    inputRequestFilter: PropTypes.string.isRequired,
    textField: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  }).isRequired,
};

RequestModal.defaultProps = {
  modalData: { associated_codes: [] },
};

export default withModal(RequestModal);

import * as types from './types';
import { SET_TURNS_GROUPS_LIST } from '../manager/types';
import { remapData } from '~/utils';

const OPERATION_CONTROL_INITIAL_FILTER = {
  id_element: 0,
  id_element_point: 0,
  id_project_group: 0,
  id_unitary_operation: 0,
  id_unitary_operation_group: 0,
  id_code_ug: 0,
  id_code_group_ug: 0,
  id_code_type_ug: 0,
  priority: -1,
  show_all: false,
};

const INITIAL_STATE = {
  projectGroups: [],
  originalProjectGroups: [],
  isProjectGroupsDirty: false,
  isProjectSubElementsDirty: false,
  originalProjectSubElements: [],
  projectSubElements: [],
  codeTypes: [],
  originalCodeTypes: [],
  isCodeTypesDirty: false,
  codeGroups: [],
  originalCodeGroups: [],
  isCodeGroupsDirty: false,
  codes: [],
  originalCodes: [],
  isCodesDirty: false,
  priorities: [],
  originalPriorities: [],
  isPrioritiesDirty: false,
  monthlyAdvances: [],
  originalMonthlyAdvances: [],
  isMonthlyAdvancesDirty: false,
  unitOperationGroups: [],
  originalUnitOperationGroups: [],
  isUnitOperationGroupsDirty: false,
  originalUnitOperations: [],
  unitOperations: [],
  isUnitOperationsDirty: false,
  dailyPartsFilters: {},
  operationControls: [],
  currentOperationControl: {},
  originalCurrentOperationControl: {},
  isCurrentOperationControlUpdated: false,
  currentOperationControlFilter: OPERATION_CONTROL_INITIAL_FILTER,
  turnGroup: 0,
  isTurnGroupDirty: false,
  originalInputUnits: [],
  inputUnits: [],
  isInputUnitsDirty: false,
  cicleAdvances: [],
  isCicleAdvancesDirty: false,
  originalCicleAdvances: [],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_PROJECT_GROUPS_LIST: {
      return {
        ...state,
        isProjectGroupsDirty: false,
        originalProjectGroups: action.payload,
        projectGroups: action.payload,
      };
    }
    case types.RESET_PROJECT_GROUPS: {
      return {
        ...state,
        projectGroups: state.originalProjectGroups,
        isProjectGroupsDirty: false,
      };
    }
    case types.SET_PROJECT_GROUP_VALUE: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.projectGroups, newItem, 'id');
      const isProjectGroupsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isProjectGroupsDirty,
        projectGroups: newData,
      };
    }
    case types.ADD_PROJECT_GROUP: {
      const newItem = {
        ...action.payload,
        id: (0 - state.projectGroups.length),
        update_status: 'I',
        active: 1,
        isDirty: true,
      };
      const newData = [newItem, ...state.projectGroups];
      const isProjectGroupsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isProjectGroupsDirty,
        projectGroups: newData,
      };
    }
    case types.SET_PROJECT_SUBELEMENTS_LIST: {
      return {
        ...state,
        isProjectSubElementsDirty: false,
        originalProjectSubElements: action.payload,
        projectSubElements: action.payload,
      };
    }
    case types.RESET_PROJECT_SUBELEMENTS: {
      return {
        ...state,
        isProjectSubElementsDirty: false,
        projectSubElements: [...state.originalProjectSubElements],
      };
    }
    case types.SET_PROJECT_SUBELEMENT_VALUE: {
      const { projectGroup, subElement, value } = action.payload;
      const projectSubElement = state
        .projectSubElements
        .find(m => m.id_project_group === projectGroup
          && m.id_element_point === subElement);

      const newData = [
        ...state.projectSubElements.filter(s => s.id_project_group !== projectGroup
          || (s.id_project_group === projectGroup && s.id_element_point !== subElement)),
      ];

      if (!projectSubElement) {
        newData.push({
          id_project_group: projectGroup,
          id_element_point: subElement,
          isDirty: !(action.payload.id < 1),
          update_status: value ? 'I' : 'D',
        });
      } else if (projectSubElement && projectSubElement.id > 0) {
        newData.push({
          ...projectSubElement,
          update_status: value ? 'I' : 'D',
        });
      }

      const isProjectSubElementsDirty = newData.some(d => d.update_status);

      return {
        ...state,
        isProjectSubElementsDirty,
        projectSubElements: newData,
      };
    }
    case types.SET_CODE_TYPES_UG_LIST: {
      return {
        ...state,
        isCodeTypesDirty: false,
        originalCodeTypes: action.payload,
        codeTypes: action.payload,
      };
    }
    case types.RESET_CODE_TYPES_UG: {
      return {
        ...state,
        codeTypes: state.originalCodeTypes,
        isCodeTypesDirty: false,
      };
    }
    case types.SET_CODE_TYPE_UG_VALUE: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.codeTypes, newItem, 'id');
      const isCodeTypesDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isCodeTypesDirty,
        codeTypes: newData,
      };
    }
    case types.ADD_CODE_TYPE_UG: {
      const newItem = {
        ...action.payload,
        id: (0 - state.codeTypes.length),
        update_status: 'I',
        active: 1,
        isDirty: true,
      };
      const newData = [newItem, ...state.codeTypes];
      const isCodeTypesDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isCodeTypesDirty,
        codeTypes: newData,
      };
    }
    case types.SET_CODE_GROUPS_UG_LIST: {
      return {
        ...state,
        isCodeGroupsDirty: false,
        originalCodeGroups: action.payload,
        codeGroups: action.payload,
      };
    }
    case types.SET_CODE_GROUP_UG_VALUE: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.codeGroups, newItem, 'id');
      const isCodeGroupsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isCodeGroupsDirty,
        codeGroups: newData,
      };
    }
    case types.ADD_CODE_GROUP_UG: {
      const newItem = {
        ...action.payload,
        id: (0 - state.codeGroups.length),
        update_status: 'I',
        active: 1,
        isDirty: true,
      };

      const newData = [newItem, ...state.codeGroups];
      const isCodeGroupsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isCodeGroupsDirty,
        codeGroups: newData,
      };
    }
    case types.RESET_CODE_GROUPS_UG: {
      return {
        ...state,
        isCodeGroupsDirty: false,
        codeGroups: [...state.originalCodeGroups],
      };
    }
    case types.SET_CODES_UG_LIST: {
      return {
        ...state,
        isCodesDirty: false,
        originalCodes: action.payload,
        codes: action.payload,
      };
    }
    case types.SET_CODE_UG_VALUE: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.codes, newItem, 'id');
      const isCodesDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isCodesDirty,
        codes: newData,
      };
    }
    case types.ADD_CODE_UG: {
      const newItem = {
        ...action.payload,
        id: (0 - state.codes.length),
        update_status: 'I',
        active: 1,
        isDirty: true,
      };

      const newData = [newItem, ...state.codes];
      const isCodesDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isCodesDirty,
        codes: newData,
      };
    }
    case types.RESET_CODES_UG: {
      return {
        ...state,
        isCodesDirty: false,
        codes: [...state.originalCodes],
      };
    }
    case types.SET_PRIORITIES_LIST: {
      const priorities = action.payload
        .map((item, index) => ({
          ...item,
          id: item.id > 0 ? item.id : (0 - index),
        }));
      return {
        ...state,
        isPrioritiesDirty: false,
        originalPriorities: priorities,
        priorities,
      };
    }
    case types.SET_PRIORITY_VALUE: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.priorities, newItem, 'id');
      const newDataSorted = newData.sort((a, b) => a.priority - b.priority);
      const isPrioritiesDirty = newDataSorted.some(d => d.update_status);
      return {
        ...state,
        isPrioritiesDirty,
        priorities: newDataSorted,
      };
    }
    case types.RESET_PRIORITIES: {
      return {
        ...state,
        isPrioritiesDirty: false,
        priorities: [...state.originalPriorities],
      };
    }
    case types.SET_MONTHLY_ADVANCE_LIST: {
      return {
        ...state,
        isMonthlyAdvancesDirty: false,
        originalMonthlyAdvances: action.payload,
        monthlyAdvances: action.payload,
      };
    }
    case types.SET_MONTHLY_ADVANCE_VALUE: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.monthlyAdvances, newItem, 'id');
      const isMonthlyAdvancesDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isMonthlyAdvancesDirty,
        monthlyAdvances: newData,
      };
    }
    case types.ADD_MONTHLY_ADVANCE: {
      const newItem = {
        ...action.payload,
        id: (0 - state.monthlyAdvances.length),
        update_status: 'I',
        active: 1,
        isDirty: true,
      };
      const newData = [newItem, ...state.monthlyAdvances];
      const isMonthlyAdvancesDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isMonthlyAdvancesDirty,
        monthlyAdvances: newData,
      };
    }
    case types.RESET_MONTHLY_ADVANCE: {
      return {
        ...state,
        isMonthlyAdvancesDirty: false,
        monthlyAdvances: [...state.originalMonthlyAdvances],
      };
    }
    case types.SET_UNIT_OPERATION_GROUPS_LIST: {
      return {
        ...state,
        isUnitOperationGroupsDirty: false,
        originalUnitOperationGroups: action.payload,
        unitOperationGroups: action.payload,
      };
    }
    case types.RESET_UNIT_OPERATION_GROUPS: {
      return {
        ...state,
        unitOperationGroups: state.originalUnitOperationGroups,
        isUnitOperationGroupsDirty: false,
      };
    }
    case types.SET_UNIT_OPERATION_GROUP_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.unitOperationGroups, newItem, 'id');
      const isUnitOperationGroupsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isUnitOperationGroupsDirty,
        unitOperationGroups: newData,
      };
    }
    case types.ADD_UNIT_OPERATION_GROUP: {
      const newItem = {
        ...action.payload,
        id: (0 - state.unitOperationGroups.length),
        update_status: 'I',
        active: 1,
        isDirty: true,
      };
      const newData = [newItem, ...state.unitOperationGroups];
      const isUnitOperationGroupsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isUnitOperationGroupsDirty,
        unitOperationGroups: newData,
      };
    }
    case types.SET_UNIT_OPERATION_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.unitOperations, newItem, 'id');
      const isUnitOperationsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isUnitOperationsDirty,
        unitOperations: newData,
      };
    }
    case types.ADD_UNIT_OPERATION: {
      const newItem = {
        ...action.payload,
        id: (0 - state.unitOperations.length),
        update_status: 'I',
        active: 1,
        isDirty: true,
      };
      const newData = [newItem, ...state.unitOperations];
      const isUnitOperationsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isUnitOperationsDirty,
        unitOperations: newData,
      };
    }
    case types.RESET_UNIT_OPERATIONS: {
      return {
        ...state,
        isUnitOperationsDirty: false,
        unitOperations: [...state.originalUnitOperations],
      };
    }
    case types.SET_UNIT_OPERATIONS_LIST: {
      return {
        ...state,
        isUnitOperationsDirty: false,
        originalUnitOperations: action.payload,
        unitOperations: action.payload,
      };
    }
    case types.SET_OPERATION_CONTROLS_LIST: {
      return {
        ...state,
        operationControls: action.payload,
      };
    }
    case types.SET_OPERATION_CONTROL_ITEM: {
      return {
        ...state,
        isCurrentOperationControlUpdated: false,
        currentOperationControl: action.payload,
      };
    }
    case types.SET_ORIGINAL_OPERATION_CONTROL_ITEM: {
      return {
        ...state,
        originalCurrentOperationControl: action.payload,
      };
    }
    case types.RESTORE_OPERATION_CONTROL_ITEM: {
      return {
        ...state,
        isCurrentOperationControlUpdated: false,
        currentOperationControl: { ...state.originalCurrentOperationControl },
      };
    }
    case types.SET_UNDERGROUND_OPERATION_CONTROL_SAVED: {
      return {
        ...state,
        isCurrentOperationControlUpdated: true,
      };
    }
    case types.RESTORE_OPERATION_CONTROL_FILTER: {
      return {
        ...state,
        currentOperationControlFilter: { ...OPERATION_CONTROL_INITIAL_FILTER },
      };
    }
    case types.SET_OPERATION_CONTROL_FILTER: {
      const newData = {
        ...state.currentOperationControlFilter,
        ...action.payload,
      };
      return {
        ...state,
        currentOperationControlFilter: newData,
      };
    }
    case SET_TURNS_GROUPS_LIST: {
      const { id = 0 } = action.payload.find(t => t.is_underground) || {};
      return {
        ...state,
        isTurnGroupDirty: false,
        turnGroup: id,
        originalTurnGroup: id,
      };
    }
    case types.SET_UNDERGROUND_TURN_GROUP_VALUE: {
      return {
        ...state,
        isTurnGroupDirty: true,
        turnGroup: action.payload,
      };
    }
    case types.RESET_UNDERGROUND_TURN_GROUP: {
      return {
        ...state,
        isTurnGroupDirty: false,
        turnGroup: state.originalTurnGroup,
      };
    }
    case types.SET_INPUT_UNITS_LIST: {
      return {
        ...state,
        isInputUnitsDirty: false,
        originalInputUnits: action.payload,
        inputUnits: action.payload,
      };
    }
    case types.SET_INPUT_UNIT_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.inputUnits, newItem, 'id');
      const isInputUnitsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isInputUnitsDirty,
        inputUnits: newData,
      };
    }
    case types.ADD_INPUT_UNIT: {
      const newItem = {
        ...action.payload,
        id: 0 - state.inputUnits.length,
        update_status: 'I',
        active: 1,
        isDirty: true,
      };

      const newData = [newItem, ...state.inputUnits];
      const isInputUnitsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isInputUnitsDirty,
        inputUnits: newData,
      };
    }
    case types.RESET_INPUT_UNITS: {
      return {
        ...state,
        isInputUnitsDirty: false,
        inputUnits: [...state.originalInputUnits],
      };
    }
    case types.SET_UNDERGROUND_CICLE_ADVANCE: {
      return {
        ...state,
        cicleAdvances: [...action.payload],
        originalCicleAdvances: [...action.payload],
        isCicleAdvancesDirty: false,
      };
    }
    case types.ADD_UNDERGROUND_CICLE_ADVANCE: {
      const cicleAdvances = [action.payload, ...state.cicleAdvances];
      return {
        ...state,
        cicleAdvances,
        isCicleAdvancesDirty: cicleAdvances.some(cA => cA.update_status && cA.isDirty),
      };
    }
    case types.DELETE_UNDERGROUND_CICLE_ADVANCE: {
      const cicleAdvances = remapData(state.cicleAdvances, action.payload, 'id');
      return {
        ...state,
        cicleAdvances,
        isCicleAdvancesDirty: cicleAdvances.some(cA => cA.update_status && cA.isDirty),
      };
    }
    case types.CHANGE_UNDERGROUND_CICLE_ADVANCE: {
      const cicleAdvances = remapData(state.cicleAdvances, action.payload, 'id');
      return {
        ...state,
        cicleAdvances,
        isCicleAdvancesDirty: cicleAdvances.some(cA => cA.update_status && cA.isDirty),
      };
    }
    case types.RESTORE_CICLE_ADVANCE: {
      return {
        ...state,
        cicleAdvances: state.originalCicleAdvances,
        isCicleAdvancesDirty: false,
      };
    }
    default: {
      return state;
    }
  }
}

const styles = {
  container: {
    justifyContent: 'space-between',
    paddingTop: 10,
    paddingBottom: 10,
  },
  headerData: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  dataValue: {
    fontWeight: 'bold',
    marginLeft: 5,
  },
  text: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 300,
  },

};

export default styles;

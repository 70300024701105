import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import SystemsTab from './SystemsTab';
import SystemItemsTab from './SystemItemsTab';
import FailureItemsTab from './FailureItemsTab';
import FailureModeSystemItemsTab from './FailureModeSystemItemsTab';
import EquipmentGroupsSystemItemsTab from './EquipmentGroupsSystemItemsTab';
import RegisterTab from './RegisterTab';
import PF2MTabs from '~/components/PF2MTabs';
import { setAlertDialog } from '~/store/manager/actions';

const Systems = () => {
  const isSystemsDirty = useSelector(state => state.maintenance.isSystemsDirty);
  const isSystemItemsDirty = useSelector(state => state.maintenance.isSystemItemsDirty);
  const isFailureModesDirty = useSelector(state => state.maintenance.isFailureModesDirty);
  const isFailureModesSystemItemsDirty = useSelector(
    state => state.maintenance.isFailureModesSystemItemsDirty,
  );
  const isEquipmentGroupsSystemItemsDirty = useSelector(
    state => state.maintenance.isEquipmentGroupsSystemItemsDirty,
  );
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(0);
  const { t: translate } = useTranslation();

  const tabs = [
    { key: 0, label: translate('common:Register') },
    { key: 1, label: translate('common:System') },
    { key: 2, label: translate('common:SystemItems') },
    { key: 3, label: translate('common:FailureModes') },
    { key: 4, label: translate('common:ConnectItems') },
    { key: 5, label: translate('common:ConnectFailureModes') },
  ];

  const handleChange = (event, value) => {
    let blockChange = false;

    switch (value) {
      case 1:
        blockChange = isSystemsDirty || isFailureModesDirty || isFailureModesSystemItemsDirty
        || isEquipmentGroupsSystemItemsDirty;
        break;
      case 2:
        blockChange = isSystemsDirty || isSystemItemsDirty || isFailureModesSystemItemsDirty
         || isEquipmentGroupsSystemItemsDirty;
        break;
      case 3:
        blockChange = isSystemsDirty || isSystemItemsDirty || isFailureModesDirty
         || isEquipmentGroupsSystemItemsDirty;
        break;
      case 4:
        blockChange = isSystemsDirty || isSystemItemsDirty || isFailureModesDirty
         || isFailureModesSystemItemsDirty;
        break;
      case 5:
        blockChange = false;
        break;
      default:
        blockChange = isSystemItemsDirty || isFailureModesDirty || isFailureModesSystemItemsDirty
         || isEquipmentGroupsSystemItemsDirty;
        break;
    }

    if (blockChange) {
      event.preventDefault();
      dispatch(setAlertDialog());
    } else {
      setSelectedTab(value);
    }
  };

  return (
    <>
      <PF2MTabs
        value={selectedTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        {tabs}
      </PF2MTabs>
      {selectedTab === 0 ? (<RegisterTab />) : null}
      {selectedTab === 1 ? (<SystemsTab />) : null}
      {selectedTab === 2 ? (<SystemItemsTab />) : null}
      {selectedTab === 3 ? (<FailureItemsTab />) : null}
      {selectedTab === 4 ? (<EquipmentGroupsSystemItemsTab />) : null}
      {selectedTab === 5 ? (<FailureModeSystemItemsTab />) : null}
    </>
  );
};

export default withStyles(styles)(Systems);

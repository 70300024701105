import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  withStyles,
} from '@material-ui/core';
import styles from '../styles';
import Header from '~/pages/Manager/Header';
import { getMassInputTemplate, sendMassInputTemplate } from '~/store/underground/actions';

const MassInputTab = ({ classes }) => {
  const dispatch = useDispatch();

  return (
    <div className={classes.tabContainer}>
      <Header
        getTemplateAction={() => { dispatch(getMassInputTemplate()); }}
        importAction={(file) => { dispatch(sendMassInputTemplate(file)); }}
      />
    </div>
  );
};

MassInputTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MassInputTab);

import React from 'react';
import { MTableEditField } from 'material-table';
import PropTypes from 'prop-types';

// removing bulking edit to avoid erro on console
const DefaultCell = ({ bulkEdit, ...p }) => {
  const { tooltip, lookup } = p.columnDef;

  if (tooltip && lookup) {
    return (
      <MTableEditField
        {...p}
        title={lookup[p.value]}
        onChange={e => bulkEdit(e, p)}
      />
    );
  }

  if (tooltip) {
    return (
      <MTableEditField
        {...p}
        title={p.value}
        onChange={e => bulkEdit(e, p)}
      />
    );
  }

  return (
    <MTableEditField
      {...p}
      onChange={e => bulkEdit(e, p)}
    />
  );
};

DefaultCell.propTypes = {
  bulkEdit: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
};

DefaultCell.defaultProps = {
  bulkEdit: () => { },
};

export default DefaultCell;

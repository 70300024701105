export const common = {
  en: {
    CodeMigrations: 'Code Migrations',
    CodeMigrationsDynamic: 'Code Migrations (Dynamic)',
    UnavailableModule: 'Unavailable Module',
    KnowMore: 'Know More',
    Settings: 'Settings',
    Logout: 'Logout',
    MiningControl: 'Mining Control',
    WelcomeTo: 'Welcome to',
    Parameter: 'Parameter',
    EquipmentConfigurations: 'Equipment Configurations',
    GeneralConfigurations: 'General Configurations',
    CyclesAutomation: 'Cycles Automation',
    ControlType: 'Control Type',
    Front: 'Front',
    Equipments: 'Equipments',
    PerforatorSchedules: 'Perforator Schedules',
    InactiveTurnPersistency: 'Inactive turn persistency',
    RainMode: 'Rain Mode',
    ToggleRainMode: 'Toggle Rain Mode',
    SpeedLimit: 'Speed Limit',
    Issue: 'Issue',
    Accept: 'Accept',
    ScreenCount: 'Screen Count',
    CrusherTab: 'Crusher',
    CrusherTime: 'Crusher Time',
    CrusherStopTime: 'Crusher Stop Time',
    Source: 'Source',
    FeatureInDevelopment: 'Test this feature in development',
    LastServerConnection: 'Last Server Connection',
    LastDataSent: 'Last Data Sent',
    DiconnectedTime: 'Diconnected Time',
    DataToSend: 'Data To Send',
    AddEditAllocation: 'Add/Edit Allocation',
    EditEquipmentScheduling: 'Edit Equipment Scheduling',
    GoalTypes: 'Goal Types',
    Goal: 'Goal',
    Planning: 'Planning',
    Short: 'Short',
    Medium: 'Medium',
    Long: 'Long',
    Granularity: 'Granularity',
    DefinitiveNote: 'Definitive Note',
    Class: 'Class',
    Goals: 'Goals',
    GoalsBETA: 'Goals (BETA)',
    Production: 'Production',
    State: 'State',
    ValueType: 'Value Type',
    Number: 'Number',
    TotalTimeInCode: 'Total Time In Code',
    FullDistance: 'Full Distance',
    EmptyDistance: 'Empty Distance',
    LoadLatitude: 'Latitude Loading',
    LoadLongitude: 'Longitude Loading',
    LoadAltitude: 'Altitude Loading',
    UnloadLatitude: 'Latitude Download',
    UnloadLongitude: 'Unloading length',
    UnloadAltitude: 'Altitude Unloading',
    LoadEquipmentNumber: 'Load Equipment Number',
    CycleStageTimes: 'Cycle Stage Times',
    railroad_cycle_time_port_exit: 'Port departure maneuver',
    railroad_cycle_time_empty: 'Empty train circulation',
    railroad_cycle_time_load_entry: 'Mine Entry Maneuver',
    railroad_cycle_time_load_queue: 'Loading queue',
    railroad_cycle_time_load: 'Loading',
    railroad_cycle_time_load_exit: 'Mine exit maneuver',
    railroad_cycle_time_full: 'Loaded train circulation',
    railroad_cycle_time_port_entry: 'Port entry maneuver',
    railroad_cycle_time_unload_queue: 'Unloading queue',
    railroad_cycle_time_unload: 'Unloading',
    TotalInCode: 'Total time in Code',
    StandardLoad: 'Standard Load',
    ConfirmChanges: 'Confirm changes?',
    Step: 'Step',
    LoadingEquipment: 'Nº Loading Equipment',
    Finalize: 'Finalize',
    Text: 'Text',
    ThereAreNoChanges: 'There are no changes',
    AutoAllocationLogs: 'Auto allocation logs',
    DynamicAllocationExplanation: 'Determines if this allocation should be dynamic',
    AllocationGroupExplanation: 'Defines the allocation group for this load allocation',
    MaxAllocationCapacityExplanation: 'Defines the maximum capacity of trucks that can be allocated to this load allocation',
    MaxLoadQueueTimeExplanation: 'Maximum queue time allowed for the load equipment',
    MeanQueueTimeRangeExplanation: 'Time range (minutes) to calculate the mean queue time for a load equipment',
    MinPermanentAllocationCyclesExplanation: 'Minimum cycles that the truck has to complete in its permanent allocation',
    MaxTemporaryAllocationCyclesExplanation: 'Maximum number of cycles to be completed in temporary allocations before the truck is forced to return to its permanent allocation',
    DynamicAllocation: 'Dynamic allocation',
    MaxAllocationCapacity: 'Maximum allocation capacity',
    MaxLoadQueueTime: 'Maximum load queue time (min)',
    MeanQueueTimeRange: 'Mean queue time range (min)',
    MinimumPermanentAllocationCycles: 'Minimum cycles in permanent allocation',
    MaximumTemporaryAllocationCycles: 'Maximum cycles in temporary allocation',
    Resources: 'Resources',
    Elevation: 'Elevation',
    FilterByElevation: 'Filter by elevation',
    DependentElements: 'Dependent elements',
    DependentSubElements: 'Dependent sub-elements',
    Precedences: 'Precedences',
    CalculatePrecedences: 'Calculate precedences',
    MineAvailability: 'Mine availability',
    AvailableMass: 'Available mass (t)',
    ScenarioInConfiguration: 'Scenario in configuration',
    Configure: 'Configure',
    AppliedToAllocations: 'Applied to allocations',
    AllocationGroup: 'Allocation group',
    MaxQueueTime: 'Max queue time (min)',
    Elevations: 'Elevations',
    ElevationGroups: 'Elevation groups',
    ElevationGroup: 'Elevation group',
    ElementLimits: 'Element limits',
    ElevationsAssociatedToTheElement: 'Elevations associated to the element',
    InitialElevation: 'Initial elevation',
    FinalElevation: 'Final elevation',
    Creation: 'Creation',
    Current: 'Current',
    LoadScenarioToContinue: 'Load a scenario to continue',
    Loaded: 'Loaded',
    LoadAction: 'Load',
    FilterByDate: 'Filter by date',
    ElementsAssociatedToTheScenario: 'Elements associated to the scenario',
    SubelementsAssociatedToTheScenario: 'Subelements associated to the scenario',
    LineupNewSchedule: 'Line-up: New Schedule',
    LineupPlanning: 'Line-up: Planning',
    Scenarios: 'Scenarios',
    Empty: 'Empty',
    Full: 'Full',
    Queue: 'Queue',
    Maneuver: 'Maneuver',
    Dump: 'Dump',
    Stop: 'Stop',
    NotStarted: 'Not started',
    Disapproved: 'Disapproved',
    Paused: 'Paused',
    InProgress: 'In progress',
    OnApproval: 'On approval',
    Finalized: 'Finalized',
    DeleteSchedule: 'Delete Schedule',
    Time: 'Time',
    MassT: 'Mass (t)',
    Closings: 'Closings',
    ClosingsList: 'Closings list',
    Condition: 'Condition',
    Entry: 'Entry',
    LoginLogout: 'Login/Logout',
    StockClosings: 'Stock closings',
    Velocity: 'Velocity',
    AllocationMode: 'Allocation mode',
    Permanent: 'Permanent',
    Standard: 'Standard',
    Temporary: 'Temporary',
    AllocatedTo: 'Allocated to',
    CurrentElement: 'Current element',
    CurrentElementPoint: 'Current subelement',
    CurrentCycleStep: 'Current cycle step',
    OriginSubElement: 'Origin Sub-Element',
    AllocateTo: 'Allocate to',
    LastModified: 'Last modified',
    Origins: 'Origins',
    Destinations: 'Destinations',
    Category: 'Category',
    Input: 'Input',
    MaintenanceType: 'Maintenance Type',
    MaintenanceGroup: 'Maintenance Group',
    Maintenance: 'Maintenance',
    SelectAll: 'Select All',
    HideInterruptedOrDeletedCycles: 'Hide interrupted or deleted cycles',
    HideDeletedEvents: 'Hide deleted events',
    ShiftGroupConnectedToUndergroundModule: 'Shift Group Associated To The Underground Module',
    ShiftGroupConnectedToOpenPitModule: 'Shift Group Associated To The Open Pit Module',
    FilterByEquipmentGroup: 'Filter By Equipment Group',
    FilterByMaterial: 'Filter By Material',
    FilterByShiftGroup: 'Filter By Shift Group',
    NumberOfIntervals: 'Number of Intervals',
    RpmNoRotation: 'RPM No Rotation (initial)',
    RpmSlowRunning: 'RPM Slow Running (initial)',
    RpmHighSpin: 'RPM High Spin (initial)',
    RpmOperation: 'RPM Operation (initial)',
    Content: 'Content',
    ContentVariables: 'Manage Contents',
    Associations: 'Associations',
    AssociationsList: 'Listagem de Associações',
    ConnectSubElements: 'Associate Sub-Elements',
    FilterByProjectGroup: 'Filter By Project Group',
    FilterByElement: 'Filter By Element',
    OriginSubElements: 'Origin Sub Elements',
    CodeTime: 'Code Time',
    MaintenanceLogoutLimit: 'Maintenance Logout Limit',
    SelectMaintenanceLogoutLevel: 'Select the logout level for maintenance',
    ClearCache: 'Clear Cache',
    DisableCache: 'Disable Cache',
    AccessYourAccount: 'Access your account',
    AverageLoad: 'Average Load',
    Load: 'Load',
    MonthlyAdvance: 'Monthly Advance',
    MonthlyAdvanceMeters: 'Monthly Advance (meters)',
    LoadFactor: 'Load Factor',
    Welcome: 'Welcome to the Mining Control Platform',
    RefuellingPerEquipment: 'Refuelling Per Equipment',
    RemainingLitersAlarm: 'Remaining Liters Alarm',
    ConnectCodes: 'Associate Codes',
    GreenPercentage: 'Green (%)',
    YellowPercentage: 'Yellow (%)',
    RedPercentage: 'Red (%)',
    PurplePercentage: 'Purple (%)',
    FilterByEquipType: 'Filter By Equip. Type',
    FilterByEquipGroup: 'Filter by equip. group',
    TankCapacityLiters: 'Tank Capacity (liters)',
    LitersPerHourConsumption: 'Consumption (liters/hour)',
    MapElement: 'Map Element',
    MapMaterial: 'Map Material',
    OriginPlural: 'Origin(s)',
    DestinationPlural: 'Destination(s)',
    FilterOriginElements: 'Filter By Origin Elements',
    FilterDestinationElements: 'Filter By Destination Elements',
    FireID: 'Fire ID',
    FireDescription: 'Fire Description',
    HoleType: 'Hole Type',
    HoleMeters: 'Hole Footage',
    Fan: 'Fan',
    FanName: 'Fan Name',
    OriginElement: 'Origin Element',
    SubOriginElement: 'Origin Sub-Element',
    DestinationElement: 'Destination Element',
    SubDestinationElement: 'Destination Sub-Element',
    DMTMeters: 'DMT (meters)',
    OperationalControl: 'Operational Control',
    OperationalStop: 'Parada Operativa',
    Operation: 'Operando',
    AnomalyLogs: 'Anomaly Logs',
    AnomalyType: 'Anomaly Type',
    AnomalousTable: 'Anomalous Table',
    Occurrences: 'Occurrences',
    Registers: 'Registers',
    EquipScheduling: 'Equip. Scheduling',
    EquipmentScheduling: 'Equipment Scheduling',
    PendenciesApproval: 'Pendencies Approval',
    Approve: 'Approve',
    Duration: 'Duration',
    DurationMatch: 'Current Duration (min)',
    DurationPercentage: 'Duration (%)',
    Delay: 'Delay',
    Observation: 'Comment',
    PreventiveType: 'Preventive Type',
    RefuelOperatorRegistration: 'Refuel Operator Registration',
    RefuelEquipmentGroup: 'Refuel Equipment Group',
    RefuelEquipment: 'Refuel Equipment',
    RefueledEquipmentType: 'Refueled Equipment Type',
    RefueledEquipmentGroup: 'Refueled Equipment Group',
    RefueledEquipment: 'Refueled Equipment',
    RefueledOperatorGroup: 'Refueled Operator Group',
    RefueledOperator: 'Refueled Operator',
    RefuelInitialLatitude: 'Refuel Initial Latitude',
    RefuelInitialLongitude: 'Refuel Initial Longitude',
    RefuelInitialElement: 'Refuel Initial Element',
    RefuelInitialSubElement: 'Refule Initial Sub-Element',
    RefuelFinalElement: 'Refuel Final Element',
    RefuelFinalLatitude: 'Refuel Final Latitude',
    RefuelFinalLongitude: 'Refuel Final Longitude',
    RefuelFinalSubElement: 'Refule Final Sub-Element',
    RefueledHourmeterEquipment: 'Refueled Hourmeter Equipment',
    RefueledEquipmentKilometer: 'Refueled Equipment Footage',
    RefueledLiters: 'Liters Refueled',
    StopOperationTime: 'Operation Stoppage Code Time / Maint.',
    RefuelTime: 'Refuel TIme',
    RefuelIntervalTime: 'Refuel Interval Time',
    RefuelIntervalDistance: 'Refuel Interval Distance',
    ReturnDate: 'Return Date',
    ReturnTime: 'Return Time',
    ReturnLatitude: 'Return Latitude',
    ReturnLongitude: 'Return Longitude',
    ReturnAltitude: 'Return Altitude',
    ReturnElement: 'Return Element',
    ReturnSubElement: 'Return Sub-Element',
    ReturnDistance: 'Return Distance',
    ReturnDuration: 'Return Duration',
    CutDate: 'Cut Date',
    CutTime: 'Cut Time',
    CutLatitude: 'Cut Latitude',
    CutLongitude: 'Cut Longitude',
    CutAltitude: 'Cut Altitude',
    CutElement: 'Cut Element',
    CutSubElement: 'Cut Sub-Element',
    CutDistance: 'Cut Distance',
    CutDuration: 'Cut Duration',
    UnloadTime: 'Unload Time',
    UnloadTimeAB: 'U. Time',
    LoadQueueTime: 'Load Queue Time',
    LoadQueueTimeAB: 'L. Q. Time',
    UnloadQueueTime: 'Unload Queue Time',
    UnloadQueueTimeAB: 'U. Q. Time',
    LoadManeuverTime: 'Load Maneuver Time',
    LoadManeuverTimeAB: 'L. M. Time',
    UnloadManeuverTime: 'Unload Maneuver Time',
    UnloadManeuverTimeAB: 'U. M. Time',
    EmptyTrafficTime: 'Empty Traffic Time',
    EmptyTrafficTimeAB: 'E. Traffic Time',
    FullTrafficTime: 'Full Traffic Time',
    FullTrafficTimeAB: 'F. Traffic Time',
    EmptyTrafficDistance: 'Empty Traffic Distance',
    EmptyTrafficDistanceAB: 'E. Traffic Dist.',
    FullTrafficDistance: 'Full Traffic Distance',
    FullTrafficDistanceAB: 'F. Traffic Dist.',
    MaintenanceStopTime: 'Operational Stops Duration / Maintenance',
    MaintenanceStopTimeAB: 'Op. Stops Duration / Maint.',
    LoadDefault: 'Load Default',
    LoadDefaultAB: 'L. Default',
    LoadBalance: 'Load Balance (Kg)',
    LoadBalanceAB: 'L. Balance (Kg)',
    DMTRangesRegister: 'DMT Ranges Registration',
    RangeCostRegister: 'Range Cost Registration',
    ManageMoneyPerWorkedHour: 'Manage $/WH',
    EquipmentType: 'Equipment Type',
    EquipmentTypeAB: 'E. Type',
    EquipmentGroup: 'Equipment Group',
    EquipmentGroupMatch: 'Equipment Group',
    EquipmentGroupAB: 'Equipment Group',
    RangeName: 'Range Name',
    InitialM: 'Initial (m)',
    FinalM: 'Final (m)',
    InitialRangeM: 'Initial Range (meters)',
    FinalRangeM: 'Final Range (meters)',
    CostMoneyTime: 'Cost ($/t)',
    ValueMoneyTime: 'Value ($/ht)',
    CheckListItem: 'Checklist Item',
    ConnectChecklist: 'Associate Checklist',
    FilterByEquipmentType: 'Filter by equip. type',
    ChecklistItems: 'Checklist Items',
    ObligatoryStop: 'Mandatory Stop',
    DurationMinutes: 'Duration (minutes)',
    MaintenanceData: 'Maintenance info',
    AllocatedTrucks: 'Allocated trucks',
    ClearAllocations: 'Clear allocations',
    AvailableEquipments: 'Available equipments',
    MaintenanceEquipments: 'Maintenance equipments',
    NewAllocation: 'New allocation',
    TruckEquipments: 'Trucks',
    TruckEquipmentGroups: 'Truck groups',
    NoRegisteredAllocations: 'No registered allocations',
    DeleteAllocation: 'Delete allocation',
    Allocation: 'Allocation',
    LoadManager: 'Material Volume',
    ApplyFilterToFetchData: 'Apply filters to fetch data',
    Mapping: 'Mapping',
    Filter: 'Filter',
    Filters: 'Filters',
    LoadSchedules: 'Load Schedules',
    ShowAll: 'Show all',
    ShiftGroupConnectedToMaintenanceModule: 'Shift Group Associated To The Maintenance Module',
    NoOptions: 'No options',
    DestinationSubElement: 'Destination Sub-Element',
    LoadEquipment: 'Load Equipment',
    LoadEquipmentGroup: 'Load Equipment Group',
    TruckEquipment: 'Truck',
    TruckEquipmentGroup: 'Truck Group',
    RodNumber: 'Rod Number',
    RodPosition: 'Rod Position',
    HolePosition: 'Hole Position',
    Rod: 'Rod',
    NewSchedule: 'New Schedule',
    RpmRanges: 'RPM Ranges',
    HourmeterLimits: 'Hourmeter Limits',
    HourmeterStart: 'Hourmeter Start',
    HourmeterMax: 'Hourmeter Max',
    EquipmentStatus: 'Equipment Status',
    EquipmentStatusAbbreviated: 'Equip. Status',
    AverageAdvance: 'Average Advance',
    InputRework: 'Rework',
    InputEffective: 'Effective',
    ConnectItems: 'Associate System Items',
    CodeTab: 'Codes',
    HourmeterTab: 'Hourmeter',
    LoadTab: 'Load',
    PerforatorTab: 'Perforator',
    RefuelerTab: 'Refueler',
    TransportTab: 'Transport',
    Transport: 'Transport',
    TractorTab: 'Tractor',
    LoaderTab: 'Loader',
    InfrastructureTab: 'Infrastructure',
    ShaftTab: 'Shaft',
    Active: 'Active',
    InpUnit: 'Inp. Unit',
    InputUnit: 'Input Unit',
    Unit: 'Unit',
    Until: 'Until',
    DailyPartsModule: 'Database',
    ManagementModule: 'Management',
    FuelModule: 'Fuel',
    ContractsModule: 'Contracts',
    UndergroundModule: 'Underground',
    OpenPitModule: 'Open Pit',
    DynamicModule: 'Dynamic',
    MaintenanceModule: 'Maintenance',
    DispatchModule: 'Dispatch',
    TelemetryModule: 'Telemetry',
    LineupModule: 'Line-Up',
    ReportModule: 'Reports',
    DateAndTurn: 'Date and Shift',
    No: 'No',
    Yes: 'Yes',
    Start: 'Start',
    Finish: 'End',
    All: 'All',
    Add: 'Add',
    Year: 'Year',
    Month: 'Month',
    Day: 'Day',
    Days: 'Days',
    DaysQuantity: 'Number of Days',
    Register: 'Register',
    Enter: 'Enter',
    Cancel: 'Cancel',
    Confirm: 'Confirm',
    NoName: 'No Name',
    NoEntries: 'No Records',
    Select: 'Select...',
    Understood: 'Understood',
    Origin: 'Origin',
    Destination: 'Destination',
    DestinationPoint: 'Destination Point',
    DestinationPointAB: 'D. Point',
    OriginPoint: 'Origin Point',
    OriginPointAB: 'O. Point',
    Name: 'Name',
    Password: 'Password',
    MaxVariation: 'Maximum Variation',
    PasswordRedefiniton: 'Password Reset',
    ConfirmPassword: 'Confirm Password',
    Description: 'Description',
    Codes: 'Codes',
    Code: 'Code',
    OriginCode: 'Origin Code',
    OriginCodeMatch: 'Current Code',
    DestinationCode: 'Destination Code',
    DestinationCodeMatch: 'Added Code',
    CodeReportTable: 'Code Report',
    CloudPointReportTable: 'Points History',
    CodeGroup: 'Code Group',
    OriginCodeGroup: 'Origin Code Group',
    DestinationCodeGroup: 'Destination Code Group',
    CodeGroups: 'Code Groups',
    CodeType: 'Code Type',
    OriginCodeType: 'Origin Code Type',
    DestinationCodeType: 'Destination Code Type',
    CodeTypes: 'Code Types',
    Visible: 'Visible',
    Equipment: 'Equipment',
    EquipmentMach: 'Equip.',
    Range: 'Range',
    Material: 'Material',
    MaterialsGroup: 'Materials Group',
    MaterialGroup: 'Material Group',
    Materials: 'Materials',
    NumOfBoons: 'Number of Boons',
    BitDiameter: 'Bit Diameter',
    BitDiameterSecond: 'Bit Diameter 2',
    BitDiameterName: 'Bit Diameter Name',
    BitDiameterNameSecond: 'Bit Diameter Name 2',
    Footage: 'Footage',
    RodFootage: 'Rod Footage',
    Diameter: 'Diameter',
    Element: 'Element',
    Elements: 'Elements',
    SubElement: 'Sub-Element',
    SubElements: 'Sub-Elements',
    Polygon: 'Polygon',
    Polygons: 'Polygons',
    Type: 'Type',
    Group: 'Group',
    Warning: 'Warning',
    Success: 'Success',
    Error: 'Error',
    DiscardChanges: 'Discard Changes',
    Discard: 'Discard',
    End: 'Finish',
    InsertNewEntries: 'Insert New Records',
    ImportSheet: 'Import Sheet',
    EditEntries: 'Edit Records',
    ExportEntries: 'Export Records',
    EntryType: 'Record Type',
    Movement: 'Movement',
    MovementType: 'Movement Type',
    ProjectGroup: 'Project Group',
    Order: 'Order',
    Priority: 'Priority',
    Priorities: 'Priorities',
    UnitOperation: 'Unitary Operation',
    UnitOperationGroup: 'Unitary Operation Group',
    UnitOpGroup: 'Unitary Op. Group',
    UnitOperationTime: 'Unitary Operation Time',
    SaveData: 'Save Data',
    Save: 'Save',
    Cycle: 'Cycle',
    Cycles: 'Cycles',
    CycleId: 'Cycle ID',
    CycleTime: 'Cycle Time',
    CycleDuration: 'Cycle Duration',
    CycleType: 'Cycle Type',
    CycleInitialTime: 'Cycle Start Time',
    CycleFinalTime: 'Cycle End Time',
    CycleInitialDate: 'Cycle Start Date',
    CycleFinalDate: 'Cycle End Data',
    SaveDataAndStartNewCycle: 'Save Data and Start New Cycle',
    Comment: 'Comment',
    ClearFilters: 'Clear Filters',
    FilterBy: 'Filter By',
    NoElement: 'No Element',
    NotConnected: 'Not associated',
    Productivity: 'Productivity',
    OperationType: 'Operation Type',
    Value: 'Value',
    Operator: 'Operator',
    OperatorGroup: 'Operator Group',
    OperatorGroupAB: 'O. Group',
    OperatorTeam: 'Operator Team',
    Registration: 'Registration',
    Team: 'Team',
    Teams: 'Teams',
    TeamGroup: 'Team Group',
    TeamGroups: 'Team Groups',
    TeamsGroup: 'Teams Group',
    TeamsListing: 'Teams Listing',
    Pause: 'Pause',
    AddPause: 'Add Pause',
    Period: 'Period',
    Shift: 'Shift',
    ShiftsGroup: 'Shifts Group',
    ShiftGroup: 'Shift Group',
    ShiftRegistration: 'Shift Registration',
    ShiftSchedule: 'Shift Schedule',
    ConnectShift: 'Associate Shift',
    Color: 'Color',
    InitialDate: 'Start Date',
    StartDate: 'Start Date',
    FinalDate: 'End Date',
    EndDate: 'End Date',
    EndTime: 'End Time',
    Date: 'Date',
    DateMatch: 'Initial Date/Time',
    EndDateMatch: 'End Date/Time',
    Hour: 'Time',
    InitialHour: 'Start Time',
    FinalHour: 'End Time',
    InitialTime: 'Start Time',
    FinalTime: 'End Time',
    AddEditPause: 'Add/Edit Pause',
    DayOff: 'Day Off',
    Interval: 'Interval',
    Inactive: 'Inactive',
    Weighing: 'Weighing',
    Advances: 'Advances',
    Advance: 'Advance',
    EstimatedAdvance: 'Estimated Advance',
    MeasuredAdvance: 'Measured Advance',
    Search: 'Search',
    ResultsFor: 'Results For',
    Pointer: 'Pointer',
    SeeMore: 'See More',
    Export: 'Export',
    EntyType: 'Record Type',
    Latitude: 'Latitude',
    Longitude: 'Longitude',
    Altitude: 'Altitude',
    InitialHourmeter: 'Initial Hourmeter',
    FinalHourmeter: 'Final Hourmeter',
    Hourmeter: 'Hourmeter',
    Initial: 'Initial',
    Final: 'Final',
    NamesVisibility: 'Names/Visibility',
    InitialHourmeterDiesel: 'Initial Hourmeter Diesel Motor',
    FinalHourmeterDiesel: 'Final Hourmeter Diesel Motor',
    InitialHourmeterElectric: 'Initial Hourmeter Electric Motor',
    FinalHourmeterElectric: 'Final Hourmeter Electric Motor',
    InitialKilometer: 'Initial Footage',
    FinalKilometer: 'Final Footage',
    HourMachine: 'Hour Machine',
    HourMachineTab: 'Hour Machine',
    MaterialWeight: 'Material Weight',
    MaterialAndOrigin: 'Material and Origin',
    LoadTime: 'Load Time',
    LoadTimeAB: 'L. Time',
    IdleTime: 'Idle Time',
    Drilling: 'Drilling',
    DrillingStartTime: 'Drilling Start Time',
    DrillingStartDate: 'Drilling Start Date',
    DrillingType: 'Drilling Type',
    DrillingID: 'Drilling ID',
    DrillingCount: 'Number of Holes',
    DrillingCountSecond: 'Number of Holes 2',
    DrillingDepth: 'Hole Depth',
    DrillingDepthSecond: 'Hole Depth 2',
    DrillingDuration: 'Drilling Duration',
    Bulldozer: 'Bulldozer',
    SleepLevel: 'Sleep Level',
    SleepLevelAB: 'S. Level',
    RailRoadTab: 'Locomotive',
    TaskCodes: 'Activities',
    TaskCodesAndAlerts: 'Activities and Alerts',
    Task: 'Activity',
    TaskCodeGroup: 'Activity Group',
    TaskCodeType: 'Activity Type',
    TaskCodeItem: 'Activity Item',
    MandatoryStop: 'Mandatory stop',
    System: 'System',
    SystemItems: 'System Items',
    FailureModes: 'Failure Modes',
    ConnectFailureModes: 'Associate Failure Modes',
    FilterSystem: 'Filter System',
    FilterSystemItem: 'Filter System Items',
    UpdateApps: 'Update Apps',
    PageNotFound: 'Page Not Found',
    Tractor: 'Tractor',
    Control: 'Control',
    DailyParts: 'Database',
    Projects: 'Projects',
    UnitOp: 'Unitary Op.',
    Configuration: 'Configuration',
    ServerError: 'Server Error',
    QualityControlModule: 'Quality',
    FilterByName: 'Filter by Name',
    Standby: 'Standby',
    Flow: 'Flow',
    OriginType: 'Origin Type',
    DestinationType: 'Destination Type',
    Version: 'Version',
    UpdateToVersion: 'Update To Version',
    UpdateTablets: 'Update Tablets',
    AndroidAppVersion: 'Android App Version Manager',
    TabletConfiguration: 'Tablet Configuration',
    PlatformConfiguration: 'Platform Configuration',
    Key: 'Key',
    CurrentVersion: 'Current Version',
    User: 'User',
    Users: 'Users',
    Level: 'Level',
    PlatformLevel: 'Platform Level',
    ReportsLevel: 'Reports Level',
    InvalidPassword: 'Passwords must contain at least 8 characters and be exactly the same',
    RockFillTab: 'Fillin',
    Batch: 'Batch',
    StopTime: 'Stop time',
    EnableEdit: 'Enable Edit',
    MassEdit: 'Mass Editing',
    NotChange: 'Without Changes',
    ResetPassword: 'Reset Password',
    ResetPasswordDisclaimer: 'Are you sure you want to reset the password?',
    Parent: 'Parent',
    IsDefault: 'Is Default Model',
    Upload: 'Upload',
    Process: 'Process',
    Review: 'Review',
    LayerType: 'Layer Type',
    LayerName: 'Layer Name',
    Modules: 'Modules',
    PlatformModules: 'Platform Modules',
    LoadAllocation: 'Load Allocation',
    ScissorLiftTab: 'Scissor Lift',
    OperationActivity: 'Operating Activity',
    TotalDimension: 'Total Dimension',
    OperationTime: 'Operating Time',
    Page: 'Page',
    Table: 'Table',
    UpdateTimestamp: 'Update Timestamp',
    ControlSync: 'Control Sync',
    MassInput: 'Bulk Insertion',
    Equipament: 'Equipament',
    Plate: 'Plate',
    WeighingTare: 'Weighing Tare',
    Workflow: 'Workflow',
    VelocityLimit: 'Velocity Limit',
    None: 'None',
    FailToLoadData: 'Fail to load data',
    ReminderByTimeMinutes: 'Reminder by time (min)',
    RequestByCode: 'Request By Code',
    CodesRawTab: 'Codes',
    DatetimeStart: 'Start Datetime',
    DatetimeEnd: 'End Datetime',
    CodeMaintenanceType: 'Maintenance',
    CodeOperationalStopType: 'Operational Stop',
    CodeEffectiveType: 'Effective',
    CodeInactiveType: 'Inactive',
    CodeIdleType: 'Idle',
    Railroad_associate_material: 'Mandatory to associate at least one material',
    CyclesNumber: 'Cycles Number',
    'Commit Changes': 'Commit Changes',
    'Discard Changes': 'Discard Changes',
    Edit: 'Edit',
    Import: 'Import',
    TotalLoad: 'Total Load',
    EquipmentAllocation: 'Equipment Allocation',
    IsPersistent: 'Definitive Record',
    CicleAdvance: 'Cicle Advance',
    AdvanceRegister: 'Advance Register',
    DistanceKm: 'Distance (km)',
    StepsPerformedByAutomation: 'Steps Performed by Automation',
    LAE: 'LAE',
    OperatorLoad: 'Operator who loaded',
    LS: 'LS',
    LE: 'LE',
    US: 'US',
    UE: 'UE',
    LocalAutomationEnable: 'Local Automation Enable',
    LoadStart: 'Load Start',
    LoadEnd: 'Load End',
    UnloadStart: 'Unload Start',
    UnloadEnd: 'Unload End',
    Automatic: 'Automatic',
    Manual: 'Manual',
    transport_cycle_empty: 'Transit Empty',
    transport_cycle_full: 'Transit Full',
    transport_cycle_load_queue: 'Load Queue',
    transport_cycle_load_maneuver: 'Load Maneuver',
    transport_cycle_load: 'Load',
    transport_cycle_unload_queue: 'Unload Queue',
    transport_cycle_unload_maneuver: 'Unload Maneuver',
    transport_cycle_unload: 'Unload',
    HoleID: 'Hole ID',
    ShowInactive: 'Show Inactives',
    ChatBeta: 'Chat (Beta)',
    Chat: 'Chat(Beta)',
    JumboTab: 'Jumbo',
    Reason: 'Reason',
    JumboExplanation: 'Reasons for the two arms Jumbo to operate with one arm',
    InactivateAllSelectedItems: 'Inactivate All Selected Items',
    RemoveAllSelectedItems: 'Remove All Selected Items',
    ClearAllSelectedItems: 'Clear All Selected Items',
    ChooseEquipmentType: 'Choose an Equipment Type',
    Frequency: 'Frequency',
    Indicators: 'Indicators',
    CodeMigrationRules: 'Code Migration Rules',
    MaxTimeInOriginCode: 'Max Time In Origin Code',
    NewCurrentDuration: 'New Current Code Duration (min)',
    NewCodeDuration: 'Durantion of the New Code (min)',
    CodesMigrations: 'Exceeded Duration Codes',
    AleatoryDurationPercentage: 'Aleatory Duration (%)',
    AleatoryProbabilityPercentage: 'Draw Probability (%)',
  },
  ptbr: {
    CodeMigrations: 'Migração de Códigos',
    CodeMigrationsDynamic: 'Migração de Códigos (Dynamic)',
    UnavailableModule: 'Módulo Não Contratado',
    KnowMore: 'Saiba mais',
    Settings: 'Configurações',
    Logout: 'Sair',
    MiningControl: 'Mining Control',
    WelcomeTo: 'Bem-vindo à plataforma',
    Parameter: 'Parâmetro',
    EquipmentConfigurations: 'Configurações de Equipamentos',
    GeneralConfigurations: 'Configurações Gerais',
    CyclesAutomation: 'Automação de Ciclos',
    ControlType: 'Tipo de Controle',
    Front: 'Frente',
    Equipments: 'Equipamentos',
    PerforatorSchedules: 'Programação de Perfuração',
    InactiveTurnPersistency: 'Persistência em Turno Inativo',
    RainMode: 'Modo Chuva',
    ToggleRainMode: 'Ativar Modo Chuva',
    SpeedLimit: 'Limite de Velocidade',
    Issue: 'Problema',
    Accept: 'Aceitar',
    ScreenCount: 'Quantidade de Telas',
    CrusherTab: 'Britador',
    CrusherTime: 'Tempo de Britagem',
    CrusherStopTime: 'Tempo de Parada',
    Source: 'Fonte',
    FeatureInDevelopment: 'Teste a nova funcionalidade em desenvolvimento',
    LastServerConnection: 'Última Conexão com o Servidor',
    LastDataSent: 'Últimos Dados Enviados',
    DiconnectedTime: 'Tempo de Desconexão',
    DataToSend: 'Dados para Enviar',
    AddEditAllocation: 'Adicionar/Editar Alocação',
    EditEquipmentScheduling: 'Editar Programação de Equipamento',
    Class: 'Classe',
    Goals: 'Metas',
    GoalsBETA: 'Metas (BETA)',
    Planning: 'Planejamento',
    Short: 'Curto',
    Medium: 'Médio',
    Long: 'Longo',
    Production: 'Produção',
    State: 'Estado',
    GoalTypes: 'Tipos de Metas',
    Goal: 'Meta',
    Granularity: 'Granularidade',
    DefinitiveNote: 'Registro Definitivo',
    ValueType: 'Tipo de Valor',
    Number: 'Número',
    TotalTimeInCode: 'Tempo Total em Código',
    FullDistance: 'Distância Cheio',
    EmptyDistance: 'Distância Vazio',
    LoadLatitude: 'Latitude Carregamento',
    LoadLongitude: 'Longitude Carregamento',
    LoadAltitude: 'Altitude Carregamento',
    UnloadLatitude: 'Latitude Descarregamento',
    UnloadLongitude: 'Longitude Descarregamento',
    UnloadAltitude: 'Altitude  Descarregamento',
    LoadEquipmentNumber: 'Nº de Equip. de Carga',
    CycleStageTimes: 'Tempos das Etapas do Ciclo',
    StandardLoad: 'Carga padrão',
    Step: 'Etapa',
    railroad_cycle_time_port_exit: 'Manobra de Saída do Porto',
    railroad_cycle_time_empty: 'Circulação Trem Vazio',
    railroad_cycle_time_load_entry: 'Manobra de Entrada na Mina',
    railroad_cycle_time_load_queue: 'Fila de Carregamento',
    railroad_cycle_time_load: 'Carregamento',
    railroad_cycle_time_load_exit: 'Manobra de Saída da Mina',
    railroad_cycle_time_full: 'Circulação Trem Carregado',
    railroad_cycle_time_port_entry: 'Manobra de Entrada no Porto',
    railroad_cycle_time_unload_queue: 'Fila de Descarregamento',
    railroad_cycle_time_unload: 'Descarregamento',
    TotalInCode: 'tempo total em código',
    LoadingEquipment: 'Nº Equipamentos de carga',
    Finalize: 'Finalizar',
    ConfirmChanges: 'Confirmar alterações?',
    Text: 'Texto',
    ThereAreNoChanges: 'Não há alterações',
    AutoAllocationLogs: 'Logs Aloc. Dinamica',
    DynamicAllocationExplanation: 'Determina se a funcionalidade de alocação dinâmica deve contemplar esta alocação',
    AllocationGroupExplanation: 'Define a qual grupo de alocação de cada equipamento de carga pertence',
    MaxAllocationCapacityExplanation: 'Define uma capacidade máxima de equipamentos de transporte permitidos em uma escavadeira e bloqueia novas alocações automáticas à escavadeira enquanto não haver um slot disponível​',
    MaxLoadQueueTimeExplanation: 'Tempo máximo de fila que o equipamento de carregamento pode ter, valores acima disso ativam a alocação dinâmica',
    TaskCodesAndAlerts: 'Atividades e Alertas',
    MeanQueueTimeRangeExplanation: 'Janela de tempo em minutos para calcular tempo de fila médio para uma escavadeira​',
    MinPermanentAllocationCyclesExplanation: 'Quantidade mínima de ciclos que o caminhão deve ficar em sua alocação permanente​, antes de ser alocado temporariamente​',
    MaxTemporaryAllocationCyclesExplanation: 'Quantidade máxima de ciclos que o caminhão deve ficar em alocações temporárias, antes de ser obrigado a retornar para sua alocação permanente​',
    DynamicAllocation: 'Alocação dinâmica',
    MaxAllocationCapacity: 'Capacidade max. de alocações',
    MaxLoadQueueTime: 'Tempo max. de fila de carregamento (min)',
    MeanQueueTimeRange: 'Intervalo de avaliação de fila (min)',
    MinimumPermanentAllocationCycles: 'Minimo de ciclos na alocação permanente',
    MaximumTemporaryAllocationCycles: 'Máximo de ciclos na alocação temporária',
    Resources: 'Recursos',
    Elevation: 'Elevação',
    FilterByElevation: 'Filtrar por elevação',
    DependentElements: 'Elementos dependentes',
    DependentSubElements: 'Sub-elementos dependentes',
    Precedences: 'Precedências',
    CalculatePrecedences: 'Calcular precedências',
    MineAvailability: 'Disponibilidade de lavra',
    AvailableMass: 'Massa disponível (t)',
    ScenarioInConfiguration: 'Cenário em configuração',
    Configure: 'Configurar',
    AppliedToAllocations: 'Aplicado às alocações',
    AllocationGroup: 'Grupo de alocação',
    MaxQueueTime: 'Tempo máximo de fila (min)',
    Elevations: 'Elevações',
    ElevationGroups: 'Grupos de elevação',
    ElevationGroup: 'Grupo de elevação',
    ElementLimits: 'Limites de Elementos',
    ElevationsAssociatedToTheElement: 'Elevações associadas ao elemento',
    InitialElevation: 'Elevação inicial',
    FinalElevation: 'Elevação final',
    Creation: 'Criação',
    Current: 'Atual',
    LoadScenarioToContinue: 'Carregue um cenário para continuar',
    Loaded: 'Carregado',
    LoadAction: 'Carregar',
    FilterByDate: 'Filtrar por data',
    ElementsAssociatedToTheScenario: 'Elementos associados ao cenário',
    SubelementsAssociatedToTheScenario: 'Subelementos associados ao cenário',
    LineupNewSchedule: 'Line-up: Nova programação',
    LineupPlanning: 'Line-up: Planejamento',
    Scenarios: 'Cenários',
    Empty: 'Vazio',
    Full: 'Cheio',
    Queue: 'Fila',
    Maneuver: 'Manobra',
    Dump: 'Basculamento',
    Stop: 'Parada',
    NotStarted: 'Não iniciada',
    Disapproved: 'Reprovada',
    Paused: 'Pausada',
    InProgress: 'Em andamento',
    OnApproval: 'Em aprovação',
    Finalized: 'Finalizada',
    DeleteSchedule: 'Apagar Programação',
    Time: 'Hora',
    MassT: 'Massa (t)',
    Closings: 'Fechamentos',
    ClosingsList: 'Lista de fechamentos',
    Condition: 'Condição',
    Entry: 'Entrada',
    LoginLogout: 'Login/Logout',
    StockClosings: 'Fechamentos de estoque',
    Velocity: 'Velocidade',
    AllocationMode: 'Modo de alocação',
    Permanent: 'Permanente',
    Standard: 'Padrão',
    Temporary: 'Temporário',
    AllocatedTo: 'Alocado para',
    CurrentElement: 'Elemento atual',
    CurrentElementPoint: 'Subelemento atual',
    CurrentCycleStep: 'Etapa do ciclo atual',
    OriginSubElement: 'Subelemento de origem',
    AllocateTo: 'Alocar para',
    LastModified: 'Última alteração',
    Origins: 'Origens',
    Destinations: 'Destinos',
    Category: 'Categoria',
    Input: 'Insumo',
    MaintenanceType: 'Tipo de Manutenção',
    MaintenanceGroup: 'Grupo de Manutenção',
    Maintenance: 'Manutenção',
    SelectAll: 'Selecionar todos',
    HideInterruptedOrDeletedCycles: 'Esconder ciclos interrompidos ou deletados',
    HideDeletedEvents: 'Esconder eventos deletados',
    FilterByEquipmentGroup: 'Filtrar por Grupo de Equipamento',
    FilterByMaterial: 'Filtrar por Material',
    ShiftGroupConnectedToUndergroundModule: 'Grupo de turno associado ao módulo Underground',
    ShiftGroupConnectedToOpenPitModule: 'Grupo de turno associado ao módulo Open Pit',
    FilterByShiftGroup: 'Filtrar Por Grupo de Turno',
    NumberOfIntervals: 'Número de Intervalos',
    RpmNoRotation: 'RPM Sem Rotação (início)',
    RpmSlowRunning: 'RPM Marcha Lenta (início)',
    RpmHighSpin: 'RPM Rotação Alta (início)',
    RpmOperation: 'RPM Operação (início)',
    Content: 'Teor',
    ContentVariables: 'Gerenciar Teores',
    Associations: 'Associações',
    AssociationsList: 'Listagem de Associações',
    ConnectSubElements: 'Associar sub-elementos',
    FilterByProjectGroup: 'Filtrar por grupo de projeto',
    FilterByElement: 'Filtrar por elemento',
    OriginSubElements: 'Sub-elementos de origem',
    OperationalControl: 'Controle Operacional',
    OperationalStop: 'Parada Operacional',
    Operation: 'Operando',
    AnomalyLogs: 'Registros de Anomalias',
    AnomalyType: 'Tipo de anomalia',
    AnomalousTable: 'Tabela anômala',
    Occurrences: 'Ocorrências',
    Registers: 'Registros',
    CodeTime: 'Tempo de Código',
    MonthlyAdvance: 'Avanço Mensal',
    MonthlyAdvanceMeters: 'Avanço Mensal (metros)',
    MaintenanceLogoutLimit: 'Maintenance Logout Limit',
    SelectMaintenanceLogoutLevel: 'Select the logout level for maintenance',
    ClearCache: 'Limpar cache',
    DisableCache: 'Desabilitar cache',
    AccessYourAccount: 'Acesse sua conta',
    AverageLoad: 'Carga Média',
    Load: 'Carregamento',
    LoadFactor: 'Fator de carga',
    Welcome: 'Seja bem-vindo à Plataforma Mining Control',
    RefuellingPerEquipment: 'Abastecimento por Equipamento',
    RemainingLitersAlarm: 'Alarme de Litros Restantes',
    ConnectCodes: 'Associar Códigos',
    GreenPercentage: 'Verde (%)',
    YellowPercentage: 'Amarelo (%)',
    RedPercentage: 'Vermelho (%)',
    PurplePercentage: 'Roxo (%)',
    FilterByEquipType: 'Filtrar por Tipo de Equip.',
    FilterByEquipGroup: 'Filtrar por grupo de equip.',
    TankCapacityLiters: 'Capacidade Tanque (litros)',
    LitersPerHourConsumption: 'Consumo (litros/hora)',
    MapElement: 'Mapear Elemento',
    MapMaterial: 'Mapear Material',
    OriginPlural: 'Origem(s)',
    DestinationPlural: 'Destino(s)',
    FilterOriginElements: 'Filtrar Elementos de Origem',
    FilterDestinationElements: 'Filtrar Elementos de Destino',
    FireID: 'ID Fogo',
    FireDescription: 'Descrição Fogo',
    HoleType: 'Tipo de Furo',
    HoleMeters: 'Haste',
    Fan: 'Leque',
    FanName: 'Nome do Leque',
    OriginElement: 'Elemento de Origem',
    SubOriginElement: 'Sub-Elemento de Origem',
    DestinationElement: 'Elemento de Destino',
    SubDestinationElement: 'Sub-Elemento de Destino',
    DMTMeters: 'DMT (metros)',
    EquipScheduling: 'Programação',
    EquipmentScheduling: 'Programação de Equipamento',
    PendenciesApproval: 'Aprovação de Pendências',
    Approve: 'Aprovar',
    Duration: 'Duração',
    DurationMatch: 'Duração Atual (min)',
    DurationPercentage: 'Duração (%)',
    Delay: 'Atraso',
    Observation: 'Observação',
    PreventiveType: 'Tipo de Preventiva',
    RefuelOperatorRegistration: 'Matrícula Operador Abastecimento',
    RefuelEquipmentGroup: 'Grupo Equipamento Abastecimento',
    RefuelEquipment: 'Equipamento Abastecimento',
    RefueledEquipmentType: 'Tipo Equipamento Abastecido',
    RefueledEquipmentGroup: 'Grupo Equipamento Abastecido',
    RefueledEquipment: 'Equipamento Abastecido',
    RefueledOperatorGroup: 'Grupo do Operador Abastecido',
    RefueledOperator: 'Operador Abastecido',
    RefuelInitialLatitude: 'Latitude Início Abastecimento',
    RefuelInitialLongitude: 'Longitude Início Abastecimento',
    RefuelInitialElement: 'Elemento Início Abastecimento',
    RefuelInitialSubElement: 'Sub-Elemento Início Abastecimento',
    RefuelFinalElement: 'Elemento Fim Abastecimento',
    RefuelFinalLatitude: 'Latitude Fim Abastecimento',
    RefuelFinalLongitude: 'Logitude Fim Abastecimento',
    RefuelFinalSubElement: 'Sub-Elemento Fim Abastecimento',
    RefueledHourmeterEquipment: 'Horímetro Equipamento Abastecido',
    RefueledEquipmentKilometer: 'Quilometragem Equipamento Abastecido',
    RefueledLiters: 'Litragem Abastecida',
    StopOperationTime: 'Tempo Em Código de Parada Operação / Manut',
    RefuelTime: 'Tempo Abastecimento',
    RefuelIntervalTime: 'Tempo Intervalo Abastecimento',
    RefuelIntervalDistance: 'Distância Intervalo Abastecimento',
    ReturnDate: 'Data de Retorno',
    ReturnTime: 'Hora de Retorno',
    ReturnLatitude: 'Latitude do Retorno',
    ReturnLongitude: 'Longitude do Retorno',
    ReturnAltitude: 'Altitude do Retorno',
    ReturnElement: 'Elemento do Retorno',
    ReturnSubElement: 'Sub-Elemento do Retorno',
    ReturnDistance: 'Distância do Retorno',
    ReturnDuration: 'Duração do Retorno',
    CutDate: 'Data de Corte',
    CutTime: 'Hora de Corte',
    CutLatitude: 'Latitude do Corte',
    CutLongitude: 'Longitude do Corte',
    CutAltitude: 'Altitude do Corte',
    CutElement: 'Elemento do Corte',
    CutSubElement: 'Sub-Elemento do Corte',
    CutDistance: 'Distância de Corte',
    CutDuration: 'Tempo de Corte',
    UnloadTime: 'Tempo de Basculamento',
    UnloadTimeAB: 'T. de Basculamento',
    LoadQueueTime: 'Tempo de Fila de Carr.',
    LoadQueueTimeAB: 'T. de Fila de Carr.',
    UnloadQueueTime: 'Tempo de Fila de Basculamento',
    UnloadQueueTimeAB: 'T. de Fila de Desc.',
    LoadManeuverTime: 'Tempo de Manobra de Carregamento',
    LoadManeuverTimeAB: 'T. de Man. de Carr.',
    UnloadManeuverTime: 'Tempo de Manobra de Basculamento',
    UnloadManeuverTimeAB: 'T. de Man. de Basc.',
    EmptyTrafficTime: 'Tempo de Trânsito Vazio',
    EmptyTrafficTimeAB: 'T. de Trâns. Vazio',
    FullTrafficTime: 'Tempo de Trânsito Cheio',
    FullTrafficTimeAB: 'T. de Trâns. Cheio',
    EmptyTrafficDistance: 'Distância de Deslocamento em Trânsito Vazio',
    EmptyTrafficDistanceAB: 'D. de Desloc. em Trâns. Vazio',
    FullTrafficDistance: 'Distância de Deslocamento em Trânsito Cheio',
    FullTrafficDistanceAB: 'D. de Desloc. em Trâns. Cheio',
    MaintenanceStopTime: 'Tempo de Paradas Operacionais / Manutenção',
    MaintenanceStopTimeAB: 'T. de Paradas Op. / Man.',
    LoadDefault: 'Carga Padrão',
    LoadDefaultAB: 'C. Padrão',
    LoadBalance: 'Carga Pesagem (Kg)',
    LoadBalanceAB: 'C. Pesagem (Kg)',
    DMTRangesRegister: 'Cadastro de faixas de DMT',
    RangeCostRegister: 'Cadastro de custo por faixa',
    ManageMoneyPerWorkedHour: 'Gerenciar R$/HT',
    EquipmentType: 'Tipo de Equipamento',
    EquipmentTypeAB: 'T. de Equipamento',
    EquipmentGroup: 'Grupo de Equipamento',
    EquipmentGroupMatch: 'Grupo de Equip.',
    EquipmentGroupAB: 'G. de Equipamento',
    RangeName: 'Nome Faixa',
    InitialM: 'Inicial (m)',
    FinalM: 'Final (m)',
    InitialRangeM: 'Faixa Inicial (metros)',
    FinalRangeM: 'Faixa Final (metros)',
    CostMoneyTime: 'Custo (R$/t)',
    ValueMoneyTime: 'Valor (R$/ht)',
    CheckListItem: 'Item de Checklist',
    ConnectChecklist: 'Associar Checklist',
    FilterByEquipmentType: 'Filtrar por tipo de equip.',
    ChecklistItems: 'Itens de Checklist',
    ObligatoryStop: 'Parada obrigatória',
    DurationMinutes: 'Duração (minutos)',
    MaintenanceData: 'Dados de manutenção',
    AllocatedTrucks: 'Caminhões alocados',
    ClearAllocations: 'Limpar alocações',
    AvailableEquipments: 'Equipamentos disponíveis',
    MaintenanceEquipments: 'Equipamentos em manutenção',
    NewAllocation: 'Nova alocação',
    TruckEquipments: 'Caminhões',
    TruckEquipmentGroups: 'Grupos de Caminhão',
    NoRegisteredAllocations: 'Nenhuma alocação cadastrada',
    DeleteAllocation: 'Excluir alocação',
    Allocation: 'Alocação',
    LoadManager: 'Volume de Material',
    ApplyFilterToFetchData: 'Aplique filtros para buscar dados',
    Mapping: 'Mapeamento',
    Filter: 'Filtrar',
    Filters: 'Filtros',
    LoadSchedules: 'Programação Carga',
    ShowAll: 'Exibir tudo',
    ShiftGroupConnectedToMaintenanceModule: 'Grupo de turno associado ao módulo Manutenção',
    NoOptions: 'Nenhuma opção',
    DestinationSubElement: 'Sub-Elemento de Destino',
    LoadEquipment: 'Equipamento de Carga',
    LoadEquipmentGroup: 'Grupo de Equip. de Carga',
    TruckEquipment: 'Caminhão',
    TruckEquipmentGroup: 'Grupo de Caminhão',
    RodNumber: 'Número de Hastes',
    RodPosition: 'Posição da Haste',
    HolePosition: 'Posição do Furo',
    Rod: 'Haste',
    NewSchedule: 'Nova Programação',
    RpmRanges: 'Faixas RPM',
    HourmeterLimits: 'Limítes de Horímetro',
    HourmeterStart: 'Horímetro Inicial',
    HourmeterMax: 'Horímetro Máximo',
    EquipmentStatus: 'Situação de Equipamento',
    EquipmentStatusAbbreviated: 'Situação Equip.',
    AverageAdvance: 'Avanço Médio',
    InputRework: 'Retrabalho',
    InputEffective: 'Efetivo',
    ConnectItems: 'Associar Itens de Sistema',
    CodeTab: 'Códigos',
    HourmeterTab: 'Horímetro',
    LoadTab: 'Carga',
    PerforatorTab: 'Perfuratriz',
    RefuelerTab: 'Abastecimento',
    TransportTab: 'Transporte',
    Transport: 'Transporte',
    TractorTab: 'Trator',
    LoaderTab: 'Carregadeira',
    InfrastructureTab: 'Infraestrutura',
    ShaftTab: 'Shaft',
    Active: 'Ativo',
    InpUnit: 'Un. de Insumo',
    InputUnit: 'Unidade de Insumo',
    Unit: 'Unidade',
    Until: 'Até',
    DailyPartsModule: 'Partes Diárias',
    ManagementModule: 'Gerenciamento',
    FuelModule: 'Combustível',
    ContractsModule: 'Contratos',
    UndergroundModule: 'Underground',
    OpenPitModule: 'Open Pit',
    DynamicModule: 'Dynamic',
    MaintenanceModule: 'Manutenção',
    DispatchModule: 'Despacho',
    TelemetryModule: 'Telemetria',
    LineupModule: 'Line-Up',
    ReportModule: 'Relatórios',
    DateAndTurn: 'Data e Turno',
    No: 'Não',
    Yes: 'Sim',
    Start: 'Início',
    Finish: 'Fim',
    All: 'Todos',
    Add: 'Adicionar',
    Year: 'Ano',
    Month: 'Mês',
    Days: 'Dias',
    Day: 'Dia',
    DaysQuantity: 'Quantidade de Dias',
    Register: 'Cadastrar',
    Enter: 'Entrar',
    Cancel: 'Cancelar',
    Confirm: 'Confirmar',
    NoName: 'Sem nome',
    NoEntries: 'Sem registros',
    Select: 'Selecione...',
    Understood: 'Entendi',
    Origin: 'Origem',
    Destination: 'Destino',
    DestinationPoint: 'Ponto de Destino',
    DestinationPointAB: 'P. de Destino',
    OriginPoint: 'Ponto de Origem',
    OriginPointAB: 'P. de Origem',
    Name: 'Nome',
    Password: 'Senha',
    MaxVariation: 'Variação Máxima',
    PasswordRedefiniton: 'Redefinição de senha',
    ConfirmPassword: 'Confirmação de senha',
    Description: 'Descrição',
    Codes: 'Códigos',
    Code: 'Código',
    OriginCode: 'Código de Origem',
    OriginCodeMatch: 'Código Atual',
    DestinationCode: 'Código de Destino',
    DestinationCodeMatch: 'Código Adicionado',
    CodeReportTable: 'Registro de Estados',
    CloudPointReportTable: 'Histórico de Pontos',
    CodeGroup: 'Grupo de Código',
    OriginCodeGroup: 'Grupo de Código de Origem',
    DestinationCodeGroup: 'Grupo de Código de Destino',
    CodeGroups: 'Grupos de Código',
    CodeType: 'Tipo de Código',
    OriginCodeType: 'Tipo de Código de Origem',
    DestinationCodeType: 'Tipo de Código de Destino',
    CodeTypes: 'Tipos de Código',
    Visible: 'Visível',
    Equipment: 'Equipamento',
    EquipmentMach: 'Equip.',
    Range: 'Faixa',
    Material: 'Material',
    MaterialsGroup: 'Grupo de Materiais',
    MaterialGroup: 'Grupo do Material',
    Materials: 'Materiais',
    NumOfBoons: 'Nº de Braços',
    BitDiameter: 'Diâmetro Bit',
    BitDiameterSecond: 'Diâmetro Bit 2',
    BitDiameterName: 'Nome Diâmetro Bit',
    BitDiameterNameSecond: 'Nome Diâmetro Bit 2',
    Footage: 'Metragem',
    RodFootage: 'Metragem da Haste',
    Diameter: 'Diâmetro',
    Element: 'Elemento',
    Elements: 'Elementos',
    SubElement: 'Sub-Elemento',
    SubElements: 'Sub-Elementos',
    Polygon: 'Polígono',
    Polygons: 'Polígonos',
    Type: 'Tipo',
    Group: 'Grupo',
    Warning: 'Aviso',
    Success: 'Sucesso',
    Error: 'Erro',
    DiscardChanges: 'Descartar Alterações',
    End: 'Finalizar',
    InsertNewEntries: 'Inserir Novos Registros',
    ImportSheet: 'Importar Planilha',
    EditEntries: 'Editar Registros',
    ExportEntries: 'Exportar Registros',
    EntryType: 'Tipo de Registro',
    Movement: 'Movimentação',
    MovementType: 'Tipo de Movimentação',
    ProjectGroup: 'Grupo de Projeto',
    Order: 'Ordem',
    Priority: 'Prioridade',
    Priorities: 'Prioridades',
    UnitOperation: 'Operação Unitária',
    UnitOperationGroup: 'Grupo de Operação Unitária',
    UnitOpGroup: 'Grupo de Op. Unitária',
    UnitOperationTime: 'Tempo de Operação Unitária',
    SaveData: 'Salvar Dados',
    Save: 'Salvar',
    Cycle: 'Ciclo',
    Cycles: 'Ciclos',
    Discard: 'Descartar',
    CycleId: 'ID do Ciclo',
    CycleTime: 'Tempo de Ciclo',
    CycleDuration: 'Duração do Ciclo',
    CycleType: 'Tipo de Ciclo',
    CycleInitialTime: 'Hora do Início do Ciclo',
    CycleFinalTime: 'Hora do Fim do Ciclo',
    CycleInitialDate: 'Data do Início do Ciclo',
    CycleFinalDate: 'Data do Fim de Ciclo',
    SaveDataAndStartNewCycle: 'Salvar Dados e Iniciar Novo Ciclo',
    Comment: 'Comentário',
    ClearFilters: 'Limpar filtros',
    FilterBy: 'Filtrar Por',
    NoElement: 'Sem elemento',
    NotConnected: 'Não associado',
    Productivity: 'Produtividade',
    OperationType: 'Tipo de Operação',
    Value: 'Valor',
    Operator: 'Operador',
    OperatorGroup: 'Grupo de Operador',
    OperatorGroupAB: 'G. de Operador',
    OperatorTeam: 'Turma do Operador',
    Registration: 'Matrícula',
    Team: 'Turma',
    Teams: 'Turmas',
    TeamGroup: 'Grupo de Turma',
    TeamGroups: 'Grupos de Turma',
    TeamsGroup: 'Grupo de Turmas',
    TeamsListing: 'Listagem de Turmas',
    RailRoadTab: 'Locomotiva',
    Pause: 'Pausa',
    AddPause: 'Adicionar Pausa',
    Period: 'Período',
    Shift: 'Turno',
    ShiftsGroup: 'Grupo de Turnos',
    ShiftGroup: 'Grupo de Turno',
    ShiftRegistration: 'Cadastro de Turno',
    ShiftSchedule: 'Escala de Turno',
    ConnectShift: 'Associar Turno',
    Color: 'Cor',
    InitialDate: 'Data Inicial',
    StartDate: 'Data Início',
    FinalDate: 'Data Final',
    EndDate: 'Data Fim',
    EndTime: 'Hora Fim',
    Date: 'Data',
    DateMatch: 'Data/Hora Inicial',
    EndDateMatch: 'Data/Hora Final',
    Hour: 'Hora',
    InitialHour: 'Hora Inicial',
    FinalHour: 'Hora Final',
    InitialTime: 'Hora Início',
    FinalTime: 'Hora Fim',
    AddEditPause: 'Adicionar/Editar Pausa',
    DayOff: 'Folga',
    Interval: 'Intervalo',
    Inactive: 'Inativo',
    Weighing: 'Pesagem',
    Advances: 'Avanços',
    Advance: 'Avanço',
    EstimatedAdvance: 'Avanço Estimado',
    MeasuredAdvance: 'Avanço Medido',
    Search: 'Pesquisar',
    ResultsFor: 'Resultados para',
    Pointer: 'Apontador',
    SeeMore: 'Ver Mais',
    Export: 'Exportar',
    EntyType: 'Tipo de Registro',
    Latitude: 'Latitude',
    Longitude: 'Longitude',
    Altitude: 'Altitude',
    InitialHourmeter: 'Horímetro Inicial',
    FinalHourmeter: 'Horímetro Final',
    Hourmeter: 'Horímetro',
    Initial: 'Inicial',
    Final: 'Final',
    NamesVisibility: 'Nomes/Visibilidade',
    InitialHourmeterDiesel: 'Horímetro Motor Díesel Inicial',
    FinalHourmeterDiesel: 'Horímetro Motor Díesel Final',
    InitialHourmeterElectric: 'Horímetro Motor Elétrico Inicial',
    FinalHourmeterElectric: 'Horímetro Motor Elétrico Final',
    InitialKilometer: 'Quilometragem Inicial',
    FinalKilometer: 'Quilometragem Final',
    HourMachine: 'Hora-Máquina',
    HourMachineTab: 'Hora-Máquina',
    MaterialWeight: 'Peso do Material',
    MaterialAndOrigin: 'Material e Origem',
    LoadTime: 'Tempo de Carregamento',
    LoadTimeAB: 'T. de Carreg.',
    IdleTime: 'Tempo Ocioso',
    Drilling: 'Perfuração',
    DrillingStartTime: 'Hora de Início da Perfuração',
    DrillingStartDate: 'Data de Início da Perfuração',
    DrillingType: 'Tipo do Furo',
    DrillingID: 'Identificador do Furo',
    DrillingCount: 'Número de Furos',
    DrillingCountSecond: 'Número de Furos 2',
    DrillingDepth: 'Profundidade do Furo',
    DrillingDepthSecond: 'Profundidade do Furo 2',
    DrillingDuration: 'Tempo de Perfuração',
    Bulldozer: 'Escavadeira',
    SleepLevel: 'Nível de Sono',
    SleepLevelAB: 'N. de Sono',
    TaskCodes: 'Atividades',
    Task: 'Atividade',
    TaskCodeGroup: 'Grupo de Atividade',
    TaskCodeType: 'Tipo de Atividade',
    TaskCodeItem: 'Item da Atividade',
    MandatoryStop: 'Parada Obrigatória',
    System: 'Sistema',
    SystemItems: 'Itens de Sistema',
    FailureModes: 'Modos de Falha',
    ConnectFailureModes: 'Associar Modos de Falha',
    FilterSystem: 'Filtrar Sistema',
    FilterSystemItem: 'Filtrar Item de Sistema',
    UpdateApps: 'Atualizar aplicativos',
    PageNotFound: 'Página Não Encontrada',
    Tractor: 'Trator',
    Control: 'Controle',
    DailyParts: 'P. Diárias',
    Projects: 'Projetos',
    UnitOp: 'Op. Unitária',
    Configuration: 'Configuração',
    ServerError: 'Erro no Servidor',
    QualityControlModule: 'Qualidade',
    FilterByName: 'Filtrar por Nome',
    Standby: 'Modo de Espera',
    Flow: 'Fluxo',
    OriginType: 'Tipo de Origem',
    DestinationType: 'Tipo de Destino',
    Version: 'Versão',
    UpdateToVersion: 'Atualizar para versão',
    UpdateTablets: 'Atualizar Tablets',
    AndroidAppVersion: 'Gerenciador de versão do app android',
    TabletConfiguration: 'Configuração do Tablet',
    PlatformConfiguration: 'Configuração da Plataforma',
    CurrentVersion: 'Versão atual',
    Key: 'Chave',
    User: 'Usuário',
    Users: 'Usuários',
    Level: 'Nível',
    PlatformLevel: 'Nível na Plataforma',
    ReportsLevel: 'Nível nos Relatórios',
    InvalidPassword: 'As senhas devem conter pelo menos 8 caracteres e serem exatamente iguais',
    RockFillTab: 'Preenchimento',
    Batch: 'Batelada',
    StopTime: 'Tempo parado',
    EnableEdit: 'Habilitar Edição',
    MassEdit: 'edição em massa',
    NotChange: 'Sem Alterações',
    ResetPassword: 'Redefinir Senha',
    ResetPasswordDisclaimer: 'Tem certeza que deseja redefinir a senha?',
    Parent: 'Pai',
    IsDefault: 'Modelo Padrão',
    Upload: 'Enviar',
    Process: 'Processar',
    Review: 'Revisar',
    LayerType: 'Tipo de Camada',
    LayerName: 'Nome da Camada',
    Modules: 'Módulos',
    PlatformModules: 'Módulos na Plataforma',
    LoadAllocation: 'Alocação de carga',
    ScissorLiftTab: 'Plataforma Elevatória',
    OperationActivity: 'Atividade de Operação',
    TotalDimension: 'Dimensão Total',
    OperationTime: 'Tempo Operando',
    Page: 'Página',
    Table: 'Tabela',
    UpdateTimestamp: 'Timestamp da ultima atualização',
    ControlSync: 'Control Sync',
    MassInput: 'Inserção em Massa',
    Equipament: 'Equipamento',
    Plate: 'Placa',
    WeighingTare: 'Tara',
    Workflow: 'Fluxo de Trabalho',
    VelocityLimit: 'Limite de Velocidade',
    None: 'Nenhum',
    FailToLoadData: 'Falha ao carregar dados',
    ReminderByTimeMinutes: 'Lembrete por tempo (min)',
    RequestByCode: 'Solicitação por código',
    CodesRawTab: 'Códigos',
    DatetimeStart: 'Data/Hora Início',
    DatetimeEnd: 'Data/Hora Final',
    CodeMaintenanceType: 'Manutenção',
    CodeOperationalStopType: 'Parada Operacional',
    CodeEffectiveType: 'Efetivo',
    CodeInactiveType: 'Inativo',
    CodeIdleType: 'ocioso',
    Railroad_associate_material: 'Obrigatório associar pelo menos um material',
    CyclesNumber: 'N° de Viagens',
    'Commit Changes': 'Salvar Alterações',
    'Discard Changes': 'Descartar Alterações',
    Edit: 'Editar',
    Import: 'Importar',
    TotalLoad: 'Carga Total',
    EquipmentAllocation: 'Alocação de Equipamento',
    CicleAdvance: 'Avanço de ciclo',
    AdvanceRegister: 'Cadastro de Avanços',
    DistanceKm: 'Quilometragem',
    StepsPerformedByAutomation: 'Etapas Executadas Pela Automação',
    LAE: 'ALL',
    OperatorLoad: 'Operador que carregou',
    LS: 'IC',
    LE: 'FC',
    US: 'IB',
    UE: 'FB',
    LocalAutomationEnable: 'Automação Local Ligada',
    LoadStart: 'Início do Carregamento',
    LoadEnd: 'Fim do Carregamento',
    UnloadStart: 'Início do Basculamento',
    UnloadEnd: 'Fim do Basculamento',
    Automatic: 'Automático',
    Manual: 'Manual',
    transport_cycle_empty: 'Trânsito Vazio',
    transport_cycle_full: 'Trânsito Cheio',
    transport_cycle_load_queue: 'Fila de Carregamento',
    transport_cycle_load_maneuver: 'Manobra de Carregamento',
    transport_cycle_load: 'Carregamento',
    transport_cycle_unload_queue: 'Fila de Basculamento',
    transport_cycle_unload_maneuver: 'Manobra de Basculamento',
    transport_cycle_unload: 'Basculamento',
    HoleID: 'ID do Furo',
    ShowInactive: 'Mostrar Inativos',
    ChatBeta: 'Chat (Beta)',
    Chat: 'Chat(Beta)',
    JumboTab: 'Jumbo',
    Reason: 'Motivo',
    JumboExplanation: 'Motivos para o Jumbo de dois braços operar com apenas um braço',
    InactivateAllSelectedItems: 'Inativar todos os itens selecionados',
    RemoveAllSelectedItems: 'Remover todos os itens selecionados',
    ClearAllSelectedItems: 'Limpar todos os itens selecionados',
    ChooseEquipmentType: 'Escolha o tipo de equipamento',
    Frequency: 'Frequência',
    Indicators: 'Indicadores',
    CodeMigrationRules: 'Regras de Migração de Código',
    MaxTimeInOriginCode: 'Tempo Máximo no Código de Origem',
    NewCurrentDuration: 'Nova Duração do Código Atual (min)',
    NewCodeDuration: 'Duração do Código Adicionado (min)',
    CodesMigrations: 'Códigos com Duração Excedida',
    AleatoryDurationPercentage: 'Duração Período Aleatório (%)',
    AleatoryProbabilityPercentage: 'Probabilidade de Sorteio (%)',
  },
  es: {
    CodeMigrations: 'Migraciones de Códigos',
    CodeMigrationsDynamic: 'Migraciones de Códigos (Dynamic)',
    UnavailableModule: 'Módulo no disponible',
    KnowMore: 'Saber más',
    Settings: 'Configuraciones',
    Logout: 'Cerrar sesión',
    MiningControl: 'Mining Control',
    WelcomeTo: 'Bienvenido a',
    Parameter: 'Parámetro',
    EquipmentConfigurations: 'Configuraciones de Equipos',
    GeneralConfigurations: 'Configuraciones Generales',
    CyclesAutomation: 'Automatización de Ciclos',
    ControlType: 'Tipo de Control',
    Front: 'Frente',
    Equipments: 'Equipos',
    PerforatorSchedules: 'Programación de Perforadora',
    InactiveTurnPersistency: 'Persistencia de Turno Inactivo',
    RainMode: 'Modo Lluvia',
    ToggleRainMode: 'Activar Modo Lluvia',
    SpeedLimit: 'Límite de Velocidad',
    Issue: 'Problema',
    Accept: 'Aceptar',
    ScreenCount: 'Cantidad de Pantallas',
    CrusherTab: 'Molino',
    CrusherTime: 'Tiempo de Molino',
    CrusherStopTime: 'Tiempo de Parada de Molino',
    Source: 'Fuente',
    FeatureInDevelopment: 'Funcionalidad en desarrollo',
    LastServerConnection: 'Última Conexión al Servidor',
    LastDataSent: 'Últimos Datos Enviados',
    DiconnectedTime: 'Tiempo Desconectado',
    DataToSend: 'Datos a Enviar',
    AddEditAllocation: 'Agregar/Editar Asignación',
    EditEquipmentScheduling: 'Editar Programación de Equipamientos',
    GoalTypes: 'Tipos de Metas',
    Goal: 'Meta',
    GoalsBETA: 'Metas (BETA)',
    Planning: 'Planificación',
    Short: 'Corto',
    Medium: 'Medio',
    Long: 'Largo',
    Granularity: 'Granularidad',
    DefinitiveNote: 'Registro Definitivo',
    ValueType: 'Tipo de Valor',
    Number: 'Número',
    Discard: 'Desechar',
    discardChanges: 'Descartar los cambios',
    TotalTimeInCode: 'Tiempo total en código',
    FullDistance: 'Distância completa',
    EmptyDistance: 'Distância vacía',
    LoadLatitude: 'Carga de latitud',
    LoadLongitude: 'Longitud de carga',
    LoadAltitude: 'Carga de altitud',
    UnloadLatitude: 'Descargar Latitud',
    UnloadLongitude: 'Longitud de descarga',
    UnloadAltitude: 'Descarga en altitud',
    LoadEquipmentNumber: 'Número de equipamiento de carga',
    CycleStageTimes: 'Tiempos de etapa del ciclo',
    Step: 'Etapa',
    railroad_cycle_time_port_exit: 'Maniobra de salida de puerto',
    railroad_cycle_time_empty: 'Circulación de trenes vacíos',
    railroad_cycle_time_load_entry: 'Maniobra de entrada a la mina',
    railroad_cycle_time_load_queue: 'Cola de carga',
    railroad_cycle_time_load: 'Cargando',
    railroad_cycle_time_load_exit: 'Maniobra de salida de mina',
    railroad_cycle_time_full: 'Circulación de trenes cargados',
    railroad_cycle_time_port_entry: 'Maniobra de Entrada a Puerto',
    railroad_cycle_time_unload_queue: 'Cola de descarga',
    railroad_cycle_time_unload: 'Descarga',
    TotalInCode: 'Tiempo total en Código',
    StandardLoad: 'Carga estándar',
    LoadingEquipment: 'Nº Equipamiento de Carga',
    Finalize: 'Finalizar',
    ConfirmChanges: 'Confirmar cambios?',
    TaskCodesAndAlerts: 'Actividades y Alertas',
    Text: 'Texto',
    ThereAreNoChanges: 'No hay cambios',
    Resources: 'Recursos',
    Elevation: 'Elevación',
    ScenarioInConfiguration: 'Escenario en la configuración',
    Configure: 'Configurar',
    AppliedToAllocations: 'Aplicado a asignaciones',
    AllocationGroup: 'Grupo de asignación',
    MaxQueueTime: 'Tiempo máximo de cola (min)',
    Elevations: 'Elevación',
    ElevationGroups: 'Grupos de elevación',
    ElevationGroup: 'Grupo de elevación',
    ElementLimits: 'Límites de Elementos',
    ElevationsAssociatedToTheElement: 'Alzados asociados al elemento',
    InitialElevation: 'Elevación inicial',
    FinalElevation: 'Elevación final',
    Creation: 'Creación',
    Current: 'Actual',
    LoadScenarioToContinue: 'Sube un escenario para continuar',
    Loaded: 'Cargado',
    LoadAction: 'Cargar',
    FilterByDate: 'Filtrar por fecha',
    ElementsAssociatedToTheScenario: 'Elementos asociados al escenario',
    SubelementsAssociatedToTheScenario: 'Subelementos asociados al escenario',
    LineupNewSchedule: 'Line-up: Nuevo horario',
    LineupPlanning: 'Line-up: Planificación',
    Scenarios: 'Guión',
    Empty: 'Vacío',
    Full: 'Lleno',
    Queue: 'Cola',
    Maneuver: 'Aculatamiento',
    Dump: 'Descarga',
    Stop: 'Parada',
    NotStarted: 'No empezado',
    Disapproved: 'Desaprobada',
    Paused: 'Pausado',
    InProgress: 'En curso',
    OnApproval: 'A prueba',
    Finalized: 'Finalizada',
    DeleteSchedule: 'Eliminar Programación',
    Time: 'Hora',
    MassT: 'Masa (t)',
    Closings: 'Cierres',
    ClosingsList: 'Lista de cierres',
    Condition: 'Condición',
    Entry: 'Entrada',
    LoginLogout: 'Login/Logout',
    StockClosings: 'Cierres de stock',
    Velocity: 'Velocidad',
    AllocationMode: 'Modo de asignación',
    Permanent: 'Permanente',
    Standard: 'Estándar',
    Temporary: 'Temporal',
    AllocatedTo: 'Asignado a',
    CurrentElement: 'Elemento actual',
    CurrentElementPoint: 'Subelemento actual',
    CurrentCycleStep: 'Paso del ciclo actual',
    OriginSubElement: 'Subelemento de origen',
    AllocateTo: 'Asignar a',
    LastModified: 'Ultimo cambio',
    Origins: 'Orígenes',
    Destinations: 'Destinos',
    Category: 'Categoría',
    Input: 'Insumo',
    MaintenanceType: 'Tipo de Manutenção',
    MaintenanceGroup: 'Grupo de Manutenção',
    Maintenance: 'Mantenimiento',
    SelectAll: 'Selecionar todos',
    HideInterruptedOrDeletedCycles: 'Esconder ciclos interrompidos ou deletados',
    HideDeletedEvents: 'Esconder eventos deletados',
    FilterByEquipmentGroup: 'Filtrar por Grupo de Equipamiento',
    FilterByMaterial: 'Filtrar por Material',
    ShiftGroupConnectedToUndergroundModule: 'Grupo de turno associado ao módulo Underground',
    ShiftGroupConnectedToOpenPitModule: 'Grupo de turno associado ao módulo Open Pit',
    FilterByShiftGroup: 'Filtrar Por Grupo de Turno',
    NumberOfIntervals: 'Número de Intervalos',
    RpmNoRotation: 'RPM Sem Rotação (início)',
    RpmSlowRunning: 'RPM Marcha Lenta (início)',
    RpmHighSpin: 'RPM Rotação Alta (início)',
    RpmOperation: 'RPM Operação (início)',
    ContentVariables: 'Gerenciar Teores',
    AssociationsList: 'Listagem de Associações',
    ConnectSubElements: 'Associar sub-elementos',
    FilterByProjectGroup: 'Filtrar por grupo de projeto',
    FilterByElement: 'Filtrar por elemento',
    OriginSubElements: 'Sub-elementos de origem',
    OperationalControl: 'Controle Operacional',
    OperationalStop: 'Operational Stop',
    Operation: 'Operation',
    AnomalyLogs: 'Registros de Anomalías',
    AnomalyType: 'Tipo de Anomalía',
    AnomalousTable: 'Tabla anómala',
    Occurrences: 'Ocorrencias',
    Registers: 'Registros',
    MonthlyAdvance: 'Avanço Mensal',
    MonthlyAdvanceMeters: 'Avanço Mensal (metros)',
    MaintenanceLogoutLimit: 'Maintenance Logout Limit',
    SelectMaintenanceLogoutLevel: 'Select the logout level for maintenance',
    ClearCache: 'Limpar Cache',
    DisableCache: 'Desabilitar Cache',
    AccessYourAccount: 'Acesse sua conta',
    LoadFactor: 'Fator de carga',
    Welcome: 'Seja bem-vindo à Plataforma Mining Control',
    RefuellingPerEquipment: 'Abastecimento por Equipamento',
    RemainingLitersAlarm: 'Alarme de Litros Restantes',
    ConnectCodes: 'Associar Códigos',
    GreenPercentage: 'Verde (%)',
    YellowPercentage: 'Amarelo (%)',
    RedPercentage: 'Vermelho (%)',
    PurplePercentage: 'Roxo (%)',
    FilterByEquipType: 'Filtrar por Tipo de Equip.',
    FilterByEquipGroup: 'Filtrar por grupo de equip.',
    TankCapacityLiters: 'Capacidade Tanque (litros)',
    LitersPerHourConsumption: 'Consumo (litros/hora)',
    MapElement: 'Mapear Elemento',
    MapMaterial: 'Mapear Material',
    OriginPlural: 'Origem(s)',
    DestinationPlural: 'Destino(s)',
    FilterOriginElements: 'Filtrar Elementos de Origem',
    FilterDestinationElements: 'Filtrar Elementos de Destino',
    HoleMeters: 'Haste',
    OriginElement: 'Elemento de Origem',
    SubOriginElement: 'Sub-Elemento de Origem',
    SubDestinationElement: 'Sub-Elemento de Destino',
    DMTMeters: 'DMT (metros)',
    EquipmentScheduling: 'Programação de Equipamento',
    PendenciesApproval: 'Aprobación de Pendientes',
    Approve: 'Aprobar',
    Delay: 'Atraso',
    Observation: 'Observação',
    PreventiveType: 'Tipo de Preventiva',
    RefuelOperatorRegistration: 'Matrícula Operador Abastecimento',
    RefuelEquipmentGroup: 'Grupo Equipamento Abastecimento',
    RefuelEquipment: 'Equipamento Abastecimento',
    RefueledEquipmentType: 'Tipo Equipamento Abastecido',
    RefueledEquipmentGroup: 'Grupo Equipamento Abastecido',
    RefueledEquipment: 'Equipamento Abastecido',
    RefueledOperatorGroup: 'Grupo do Operador Abastecido',
    RefueledOperator: 'Operador Abastecido',
    RefuelInitialLatitude: 'Latitude Início Abastecimento',
    RefuelInitialLongitude: 'Longitude Início Abastecimento',
    RefuelInitialElement: 'Elemento Início Abastecimento',
    RefuelInitialSubElement: 'Sub-Elemento Início Abastecimento',
    RefuelFinalElement: 'Elemento Fim Abastecimento',
    RefuelFinalLatitude: 'Latitude Fim Abastecimento',
    RefuelFinalLongitude: 'Logitude Fim Abastecimento',
    RefuelFinalSubElement: 'Sub-Elemento Fim Abastecimento',
    RefueledHourmeterEquipment: 'Horímetro Equipamento Abastecido',
    RefueledEquipmentKilometer: 'Quilometragem Equipamento Abastecido',
    RefueledLiters: 'Litragem Abastecida',
    StopOperationTime: 'Tempo Em Código de Parada Operação / Manut',
    RefuelTime: 'Tempo Abastecimento',
    RefuelIntervalTime: 'Tempo Intervalo Abastecimento',
    RefuelIntervalDistance: 'Distância Intervalo Abastecimento',
    ReturnDate: 'Data de Retorno',
    ReturnTime: 'Hora de Retorno',
    ReturnLatitude: 'Latitude do Retorno',
    ReturnLongitude: 'Longitude do Retorno',
    ReturnAltitude: 'Altitude do Retorno',
    ReturnElement: 'Elemento do Retorno',
    ReturnSubElement: 'Sub-Elemento do Retorno',
    ReturnDistance: 'Distância do Retorno',
    ReturnDuration: 'Duração do Retorno',
    CutDate: 'Data de Corte',
    CutTime: 'Hora de Corte',
    CutLatitude: 'Latitude do Corte',
    CutLongitude: 'Longitude do Corte',
    CutAltitude: 'Altitude do Corte',
    CutElement: 'Elemento do Corte',
    CutSubElement: 'Sub-Elemento do Corte',
    CutDistance: 'Distância de Corte',
    CutDuration: 'Tempo de Corte',
    UnloadTime: 'Tempo de Basculamento',
    UnloadTimeAB: 'T. de Basculamento',
    LoadQueueTime: 'Tempo de Fila de Carregamento',
    LoadQueueTimeAB: 'T. de Fila de Carr.',
    UnloadQueueTime: 'Tempo de Fila de Descarregamento',
    UnloadQueueTimeAB: 'T. de Fila de Desc.',
    LoadManeuverTime: 'Tempo de Manobra de Carregamento',
    LoadManeuverTimeAB: 'T. de Man. de Carr.',
    UnloadManeuverTime: 'Tempo de Manobra de Basculamento',
    UnloadManeuverTimeAB: 'T. de Man. de Basc.',
    EmptyTrafficTime: 'Tempo de Trânsito Vazio',
    EmptyTrafficTimeAB: 'T. de Trâns. Vazio',
    FullTrafficTime: 'Tempo de Trânsito Cheio',
    FullTrafficTimeAB: 'T. de Trâns. Cheio',
    EmptyTrafficDistance: 'Distância de Deslocamento em Trânsito Vazio',
    EmptyTrafficDistanceAB: 'D. de Desloc. em Trâns. Vazio',
    FullTrafficDistance: 'Distância de Deslocamento em Trânsito Cheio',
    FullTrafficDistanceAB: 'D. de Desloc. em Trâns. Cheio',
    MaintenanceStopTime: 'Tempo de Paradas Operacionais / Manutenção',
    MaintenanceStopTimeAB: 'T. de Paradas Op. / Man.',
    LoadDefault: 'Carga Padrão',
    LoadDefaultAB: 'C. Padrão',
    LoadBalance: 'Carga Pesagem (Kg)',
    LoadBalanceAB: 'C. Pesagem (Kg)',
    DMTRangesRegister: 'Cadastro de faixas de DMT',
    RangeCostRegister: 'Cadastro de custo por faixa',
    ManageMoneyPerWorkedHour: 'Gerenciar R$/HT',
    RangeName: 'Nome Faixa',
    InitialM: 'Inicial (m)',
    FinalM: 'Final (m)',
    InitialRangeM: 'Faixa Inicial (metros)',
    FinalRangeM: 'Faixa Final (metros)',
    CostMoneyTime: 'Custo (R$/t)',
    ValueMoneyTime: 'Valor (R$/ht)',
    CheckListItem: 'Item de Checklist',
    ConnectChecklist: 'Associar Checklist',
    FilterByEquipmentType: 'Filtrar por tipo de equip.',
    ChecklistItems: 'Itens de Checklist',
    ObligatoryStop: 'Parada obrigatória',
    DurationMinutes: 'Duração (minutos)',
    MaintenanceData: 'Dados de manutenção',
    CodeTab: 'Códigos',
    TractorTab: 'Trator',
    NumOfBoons: 'Nº de Lanças',
    BitDiameterName: 'Nome Diâmetro Bit',
    BitDiameterNameSecond: 'Nome Diâmetro Bit 2',
    EntryType: 'Tipo de Registro',
    Cycle: 'Ciclo',
    EndTime: 'Hora Fim',
    Initial: 'Inicial',
    Final: 'Final',
    NamesVisibility: 'Nomes/Visibilidade',
    InitialHourmeterDiesel: 'Horímetro Motor Díesel Inicial',
    FinalHourmeterDiesel: 'Horímetro Motor Díesel Final',
    InitialHourmeterElectric: 'Horímetro Motor Elétrico Inicial',
    FinalHourmeterElectric: 'Horímetro Motor Elétrico Final',


    DispatchModule: 'Envío',
    AllocatedTrucks: 'Camiones seleccionados',
    ClearAllocations: 'Limpiar selecciones',
    AvailableEquipments: 'Equipamientos disponibles',
    MaintenanceEquipments: 'Equipamientos en mantenimiento',
    NewAllocation: 'Nueva selección',
    TruckEquipments: 'Camiones',
    TruckEquipmentGroups: 'Grupos de camión',
    NoRegisteredAllocations: 'Ninguna selección registrada',
    DeleteAllocation: 'Eliminar selección',
    Allocation: 'Selección',
    LoadManager: 'Volumen de material',
    ApplyFilterToFetchData: 'Aplique filtros para buscar datos',
    Mapping: 'Mapeo',
    Filter: 'Filtrar',
    Filters: 'Filtros',
    LoadSchedules: 'Programación de carga',
    ShowAll: 'Mostrar todo',
    ShiftGroupConnectedToMaintenanceModule: 'Grupo de turno asociado al módulo mantenimiento',
    NoOptions: 'Ninguna opción',
    DestinationElement: 'Elemento de destino',
    DestinationSubElement: 'Subelemento de destino',
    LoadEquipment: 'Equipamiento de Carguío',
    LoadEquipmentGroup: 'Grupo de equipamiento de Carguío',
    TruckEquipment: 'Camión',
    TruckEquipmentGroup: 'Grupo de camión',
    RodNumber: 'Número de varillas',
    RodPosition: 'Posición de la varilla',
    FireDescription: 'Descripción Fuego',
    HoleType: 'Tipo de hueco',
    HolePosition: 'Posición del hueco',
    Rod: 'Varilla',
    Fan: 'Corte',
    FanName: 'Nombre del Corte',
    NewSchedule: 'Nueva programación',
    RpmRanges: 'Rangos RPM',
    HourmeterLimits: 'Limites de Horómetro',
    HourmeterStart: 'Horómetro Inicial',
    HourmeterMax: 'Horómetro Máximo',
    EquipmentStatus: 'Estado del equipamiento',
    EquipmentStatusAbbreviated: 'Estado Equip.',
    AverageAdvance: 'Avance promedio',
    InputRework: 'Retrabajo',
    InputEffective: 'Efectivo',
    ConnectItems: 'Asociar elementos de sistema',
    Codes: 'Códigos',
    HourmeterTab: 'Horómetro',
    LoadTab: 'Carga',
    PerforatorTab: 'Perforadora',
    RefuelerTab: 'Abastecimiento',
    TransportTab: 'Transporte',
    Transport: 'Transporte',
    Tractor: 'Tractor',
    LoaderTab: 'Cargador',
    InfrastructureTab: 'Infraestructura',
    ShaftTab: 'Shaft',
    Active: 'Activo',
    InpUnit: 'Un. de insumo',
    InputUnit: 'Unidad de insumo',
    Unit: 'Unidad',
    Until: 'Hasta',
    DailyPartsModule: 'Partes diarias',
    ManagementModule: 'Gestión',
    FuelModule: 'Combustible',
    ContractsModule: 'Contratos',
    UndergroundModule: 'Underground',
    OpenPitModule: 'Open Pit',
    DynamicModule: 'Dynamic',
    MaintenanceModule: 'Mantenimiento',
    TelemetryModule: 'Telemetría',
    LineupModule: 'Line-Up',
    DateAndTurn: 'Fecha y turno',
    No: 'No',
    Yes: 'Sí',
    Start: 'Inicio',
    Finish: 'Fin',
    All: 'Todos',
    Add: 'Agregar',
    Year: 'Año',
    Month: 'Mes',
    Days: 'Días',
    Day: 'Día',
    DaysQuantity: 'Cantidad de días',
    Register: 'Registrar',
    Enter: 'Entrar',
    Cancel: 'Cancelar',
    Confirm: 'Confirmar',
    NoName: 'Sin nombre',
    NoEntries: 'Sin registros',
    Select: 'Seleccione...',
    Understood: 'Entendí',
    Origin: 'Origen',
    Destination: 'Destino',
    DestinationPoint: 'Punto de destino',
    DestinationPointAB: 'P. de destino',
    OriginPoint: 'Punto de origen',
    OriginPointAB: 'P. de origen',
    Name: 'Nombre',
    Password: 'Contraseña',
    MaxVariation: 'Variación Máxima',
    PasswordRedefiniton: 'Restablecimiento de contraseña',
    ConfirmPassword: 'Confirmación de contraseña',
    Description: 'Descripción',
    Code: 'Código',
    OriginCode: 'Código de Origen',
    OriginCodeMatch: 'Código Atual',
    DestinationCode: 'Código de Destino',
    DestinationCodeMatch: 'Código Agregado',
    CodeReportTable: 'Registro de Estados',
    CloudPointReportTable: 'Histórico de Pontos',
    CodeGroup: 'Grupo de código',
    OriginCodeGroup: 'Grupo de código de origen',
    DestinationCodeGroup: 'Grupo de código de destino',
    CodeGroups: 'Grupos de código',
    CodeType: 'Tipo de código',
    OriginCodeType: 'Tipo de Código de origen',
    DestinationCodeType: 'Tipo de Código de destino',
    CodeTypes: 'Tipos de código',
    CodeTime: 'Tiempo de código',
    Visible: 'Visible',
    Equipment: 'Equipamiento',
    EquipmentMach: 'Equip.',
    EquipmentType: 'Tipo de equipamiento',
    EquipmentTypeAB: 'T. de equipamiento',
    EquipmentGroup: 'Grupo de equipamiento',
    EquipmentGroupMatch: 'Grupo de equip.',
    EquipmentGroupAB: 'G. de equipamiento',
    Range: 'Rango',
    Material: 'Material',
    MaterialsGroup: 'Grupo de materiales',
    MaterialGroup: 'Grupo del material',
    Materials: 'Materiales',
    BitDiameter: 'Diámetro Broca',
    BitDiameterSecond: 'Diámetro Broca 2',
    Footage: 'Metraje',
    RodFootage: 'Metraje de la varilla',
    Diameter: 'Diámetro',
    Element: 'Elemento',
    Elements: 'Elementos',
    SubElement: 'Subelemento',
    SubElements: 'Subelementos',
    Polygon: 'Polígono',
    RailRoadTab: 'Locomotora',
    Polygons: 'Polígonos',
    Type: 'Tipo',
    Group: 'Grupo',
    Warning: 'Aviso',
    Success: 'Éxito',
    Error: 'Error',
    DiscardChanges: 'Descartar modificaciones',
    End: 'Finalizar',
    InsertNewEntries: 'Insertar nuevos registros',
    ImportSheet: 'Importar planilla',
    EditEntries: 'Editar registros',
    ExportEntries: 'Exportar Registros',
    EntyType: 'Tipo de registro',
    Movement: 'Movimiento',
    MovementType: 'Tipo de movimiento',
    ProjectGroup: 'Grupo de proyecto',
    Order: 'Orden',
    Priority: 'Prioridad',
    Priorities: 'Prioridades',
    UnitOperation: 'Operación Unitaria',
    UnitOperationGroup: 'Grupo de operación unitaria',
    UnitOperationTime: 'Tiempo de operación unitaria',
    SaveData: 'Guardar datos',
    Save: 'Guardar',
    Cycles: 'Ciclos',
    CycleId: 'ID del ciclo',
    CycleTime: 'Tiempo de ciclo',
    CycleDuration: 'Duración del ciclo',
    CycleType: 'Tipo de ciclo',
    CycleInitialTime: 'Hora del inicio del ciclo',
    CycleFinalTime: 'Hora de la finalización del ciclo',
    CycleInitialDate: 'Fecha del inicio del ciclo',
    CycleFinalDate: 'Fecha de la finalización del ciclo',
    SaveDataAndStartNewCycle: 'Guardar datos e iniciar un nuevo ciclo',
    Comment: 'Comentario',
    ClearFilters: 'Limpiar filtros',
    FilterBy: 'Filtrar por',
    NoElement: 'Sin elemento',
    NotConnected: 'No asociado',
    Productivity: 'Productividad',
    OperationType: 'Tipo de operación',
    Value: 'Valor',
    Operator: 'Operador',
    OperatorGroup: 'Grupo de operador',
    OperatorGroupAB: 'G.o de operador',
    OperatorTeam: 'Equipo del operador',
    Registration: 'Matrícula',
    Team: 'Equipo',
    Teams: 'Equipos',
    TeamGroup: 'Grupo de equipo',
    TeamGroups: 'Grupos de equipo',
    TeamsGroup: 'Grupo de equipo',
    TeamsListing: 'Listado de equipos',
    Associations: 'Asociaciones',
    Pause: 'Pausa',
    AddPause: 'Agregar pausa',
    Period: 'Período',
    Shift: 'Turno',
    ShiftsGroup: 'Grupo de turnos',
    ShiftGroup: 'Grupo de turno',
    ShiftRegistration: 'Registro de turno',
    ShiftSchedule: 'Escala de turno',
    ConnectShift: 'Asociar turno ',
    Color: 'Color',
    InitialDate: 'Fecha inicial',
    StartDate: 'Fecha inicio',
    FinalDate: 'Fecha final',
    EndDate: 'Fecha fin',
    FinalTime: 'Hora fin',
    Date: 'Fecha',
    DateMatch: 'Fecha/Hora de inicio',
    EndDateMatch: 'Fecha/Hora de fin',
    Hour: 'Hora',
    InitialHour: 'Hora inicial',
    FinalHour: 'Hora final',
    InitialTime: 'Hora inicio',
    AddEditPause: 'Agregar/Editar pausa',
    DayOff: 'Día libre',
    Interval: 'Descanso',
    Inactive: 'Inactivo',
    Weighing: 'Peso',
    Advances: 'Avances',
    Advance: 'Avance',
    EstimatedAdvance: 'Avance estimado',
    MeasuredAdvance: 'Avance medido',
    Search: 'Buscar',
    ResultsFor: 'Resultados para',
    Pointer: 'Usuario Web',
    SeeMore: 'Ver más',
    Export: 'Exportar',
    Duration: 'Duración',
    DurationMatch: 'Duración Actual (min)',
    DurationPercentage: 'Duración (%)',
    Latitude: 'Latitud',
    Longitude: 'Longitud',
    Altitude: 'Altitud',
    InitialHourmeter: 'Horómetro inicial',
    FinalHourmeter: 'Horómetro final',
    Hourmeter: 'Horómetro',
    InitialKilometer: 'Kilometraje inicial',
    FinalKilometer: 'Kilometraje final',
    HourMachine: 'Hora Máquina',
    HourMachineTab: 'Hora Máquina',
    MaterialWeight: 'Peso del material',
    MaterialAndOrigin: 'Material y Origen',
    LoadTime: 'Tiempo de carga',
    LoadTimeAB: 'T. de carga',
    IdleTime: 'Tiempo Ocioso',
    Drilling: 'Perforación',
    DrillingStartTime: 'Hora de inicio de la perforación',
    DrillingStartDate: 'Fecha de inicio de la perforación',
    DrillingType: 'Tipo de hueco',
    DrillingID: 'Identificador del hueco',
    DrillingCount: 'Número de huecos',
    DrillingCountSecond: 'Número de huecos 2',
    DrillingDepth: 'Profundidad del hueco',
    DrillingDepthSecond: 'Profundidad del hueco 2',
    DrillingDuration: 'Tiempo de perforación',
    Bulldozer: 'Excavadora',
    SleepLevel: 'Nivel de sueño',
    SleepLevelAB: 'N. de sueño',
    TaskCodes: 'Actividades',
    Task: 'Actividad',
    TaskCodeGroup: 'Grupo de actividad',
    TaskCodeType: 'Tipo de actividad',
    TaskCodeItem: 'Elemento de actividad',
    MandatoryStop: 'Parada obligatoria',
    System: 'Sistema',
    SystemItems: 'Elementos del sistema',
    FailureModes: 'Modos de falla',
    ConnectFailureModes: 'Asociar modos de falla ',
    FilterSystem: 'Filtrar Sistema',
    FilterSystemItem: 'Filtrar elemento de Sistema',
    UpdateApps: 'Actualizar aplicaciones',
    PageNotFound: 'Página no encontrada',
    Control: 'Control',
    DailyParts: 'P. diarias',
    Projects: 'Proyectos',
    UnitOp: 'Op. Unitaria',
    Configuration: 'Configuración',
    AverageLoad: 'Carga media',
    Load: 'Carguío',
    ServerError: 'Error del Servidor',
    EquipScheduling: 'Programación',
    QualityControlModule: 'Calidad',
    Content: 'Contenido',
    FireID: 'ID fuego',
    Flow: 'Fluxo',
    OriginType: 'Tipo de Origen',
    DestinationType: 'Tipe de Destino ',
    Version: 'Versión',
    UpdateToVersion: 'Actualizar a la versión',
    UpdateTablets: 'Actualizar tabletas',
    AndroidAppVersion: 'Administrador de versiones de aplicaciones de Android',
    TabletConfiguration: 'Configuración de tablets',
    PlatformConfiguration: 'Configuración de la plataforma',
    CurrentVersion: 'Versión actual',
    Key: 'Clave',
    User: 'Usuario',
    Users: 'Usuarios',
    Level: 'Nivel',
    PlatformLevel: 'Nivel en la Plataforma',
    ReportsLevel: 'Nivel en los Informes',
    InvalidPassword: 'Las contraseñas deben contener al menos 8 caracteres y ser exactamente iguales',
    RockFillTab: 'llenar',
    Batch: 'lote',
    StopTime: 'falta de tiempo',
    EnableEdit: 'habilitar Editar',
    MassEdit: 'edición masiva',
    NotChange: 'sin alteraciones',
    ResetPassword: 'redefinir contraseña',
    ResetPasswordDisclaimer: '¿Estás seguro de que deseas restablecer la contraseña?',
    Parent: 'Padre',
    IsDefault: 'Modelo Predeterminado',
    Upload: 'Subir',
    Process: 'Procesar',
    Review: 'Revisar',
    LayerType: 'Tipo de capa',
    LayerName: 'Nombre de capa',
    Modules: 'Módulos',
    PlatformModules: 'Módulos en la Plataforma',
    LoadAllocation: 'asignación de carga',
    ScissorLiftTab: 'Plataforma Elevadora',
    OperationActivity: 'Actividad Operativa',
    TotalDimension: 'Dimensiones Totales',
    OperationTime: 'Tiempo de Funcionamiento',
    Page: 'Página',
    Table: 'Tabla',
    UpdateTimestamp: 'Marca de tiempo de la última actualización',
    ControlSync: 'Control Sync',
    MassInput: 'Inserción a granel',
    Equipament: 'Equipamiento',
    Plate: 'Matrícula',
    WeighingTare: 'Tara',
    Workflow: 'Flujo de Trabajo',
    VelocityLimit: 'Límite de Velocidad',
    None: 'Ninguno',
    FailToLoadData: 'Error al cargar datos',
    ReminderByTimeMinutes: 'Recordatorio por tiempo (min)',
    RequestByCode: 'Solicitud por código',
    Railroad_associate_material: 'Obligatorio asociar al menos un material',
    CyclesNumber: 'Número de Ciclos',
    'Commit Changes': 'Cometer cambios',
    'Discard Changes': 'Descartar los cambios',
    Edit: 'Editar',
    Import: 'importar',
    TotalLoad: 'Carga Total',
    EquipmentAllocation: 'Asignación de equipamientos',
    CodeMaintenanceType: 'Mantenimiento',
    CodeOperationalStopType: 'Cierre Operativo',
    CodeEffectiveType: 'Eficaz',
    CodeInactiveType: 'Inactivo',
    CodeIdleType: 'Omisión',
    IsPersistent: 'Registro Definitivo',
    CicleAdvance: 'Avance de ciclo',
    AdvanceRegister: 'Registro de Avance',
    DistanceKm: 'Kilometraje',
    StepsPerformedByAutomation: 'Pasos realizados por la automatización',
    LAE: 'HAL',
    OperatorLoad: 'Operador que cargó',
    LS: 'IC',
    LE: 'FC',
    US: 'ID',
    UE: 'FD',
    LocalAutomationEnable: 'Habilitación de automatización local',
    LoadStart: 'Inicio de carga',
    LoadEnd: 'Fin de carga',
    UnloadStart: 'Inicio de Descargar',
    UnloadEnd: 'Fin de Descargar',
    Automatic: 'Automático',
    Manual: 'Manual',
    transport_cycle_empty: 'Trafico Vacio',
    transport_cycle_full: 'Trafico Completo',
    transport_cycle_load_queue: 'Cola de Carga',
    transport_cycle_load_maneuver: 'Manobra de Descarga',
    transport_cycle_load: 'Carga',
    transport_cycle_unload_queue: 'Cola de Descarga',
    transport_cycle_unload_maneuver: 'Manobra de Descarga',
    transport_cycle_unload: 'Descarga',
    HoleID: 'ID del Agujero',
    ShowInactive: 'Mostrar Inactivos',
    ChatBeta: 'Chat (Beta)',
    Chat: 'Chat(Beta)',
    JumboTab: 'Jumbo',
    Reason: 'Razón',
    JumboExplanation: 'Razones para que el Jumbo de dos brazos opere con un brazo',
    InactivateAllSelectedItems: 'Desactivar todos los elementos seleccionados',
    RemoveAllSelectedItems: 'Eliminar todos los elementos seleccionados',
    ClearAllSelectedItems: 'Borrar todos los elementos seleccionados',
    ChooseEquipmentType: 'Elija el tipo de equipamiento',
    Frequency: 'Frecuencia',
    Indicators: 'Indicadores',
    CodeMigrationRules: 'Reglas de migración de código',
    MaxTimeInOriginCode: 'Tiempo máximo en código de origen',
    NewCurrentDuration: 'Duración del nuevo código actual (min)',
    NewCodeDuration: 'Duración del código actual agregado (min)',
    CodesMigrations: 'Códigos de duración excedidos',
    AleatoryDurationPercentage: 'Duración Período aleatorio (%)',
    AleatoryProbabilityPercentage: 'Probabilidad aleatoria (%)',
  },
};

export const date = {
  en: {
    Mon: 'Mon',
    Tue: 'Tue',
    Wed: 'Wed',
    Thu: 'Thu',
    Fri: 'Fri',
    Sat: 'Sat',
    Sun: 'Sun',
    January: 'January',
    February: 'February',
    March: 'March',
    April: 'April',
    May: 'May',
    June: 'June',
    July: 'July',
    August: 'August',
    September: 'September',
    October: 'October',
    November: 'November',
    December: 'December',
    DateFormat: 'MM/DD/YYYY',
    DateTimeFormat: 'MM/DD/YYYY HH:mm',
    Locale: 'en-US',

  },
  ptbr: {
    Mon: 'Seg',
    Tue: 'Ter',
    Wed: 'Qua',
    Thu: 'Qui',
    Fri: 'Sex',
    Sat: 'Sab',
    Sun: 'Dom',
    January: 'Janeiro',
    February: 'Fevereiro',
    March: 'Março',
    April: 'Abril',
    May: 'Maio',
    June: 'Junho',
    July: 'Julho',
    August: 'Agosto',
    September: 'Setembro',
    October: 'Outubro',
    November: 'Novembro',
    December: 'Dezembro',
    DateFormat: 'DD/MM/YYYY',
    DateTimeFormat: 'DD/MM/YYYY HH:mm',
    Locale: 'pt-BR',
  },
  es: {
    Mon: 'Lun',
    Tue: 'Mar',
    Wed: 'Mié',
    Thu: 'Jue',
    Fri: 'Vie',
    Sat: 'Sáb',
    Sun: 'Dom',
    January: 'Enero',
    February: 'Febrero',
    March: 'Marzo',
    April: 'Abril',
    May: 'Mayo',
    June: 'Junio',
    July: 'Julio',
    August: 'Agosto',
    September: 'Septiembre',
    October: 'Octubre',
    November: 'Noviembre',
    December: 'Diciembre',
    DateFormat: 'DD/MM/YYYY',
    DateTimeFormat: 'DD/MM/YYYY HH:mm',
    Locale: 'es',
  },
};

export const validation = {
  en: {
    PrecedenceCalculationWillOverrideCurrentConfiguration: 'Precedence calculation will override your current configuration. Would you like to continue?',
    FinalElevationMustBeBiggerThanInitialElevation: 'Final elevation has to be higher than initial elevation',
    LineupScenariosInDateRangeWillBeOverriden: 'Scenarios in this date range will be overriden',
    FormWasNotFilledCorrectly: 'The form was not fille correctly',
    ExistingSchedule: 'This equipment is already scheduled for this period',
    WarningScheduleChange: 'The modifications will affect subsequent schedules. Would you like to continue?',
    WarningTerrainModelDelete: 'Are you sure you want to delete this terrain model?',
    WarningNoDefaultModelSelected: 'You must select a default model',
    ThisActionWillClearAllYourAllocations: 'This action will remove all allocations. Would you like to continue?',
    ExpiredSession: 'Your session has expired, login to continue',
    DateIntervalAlreadyRegistered: 'Date interval already registered',
    AlreadyHasFootage: 'Depth already registered',
    TurnGroupAlreadyRegisteredForEquipGroup: 'A turn group has already been registered for this equipment group',
    ThereAreBlankFields: 'You must fill all fields before saving',
    CantHaveMultipleTurnsWithSameTeam: 'A team can\'t be assigned to multiple turns in the same day',
    AlreadyRegisteredUnit: 'Unit was already registered',
    WouldYouLikeToUpdateApps: 'Would you like to update the apps?',
    WouldYouLikeToExit: 'Would you like to quit?',
    RequiredField: 'Required Field',
    AlreadyRegisteredFootage: 'Footage already registered',
    AlreadyRegisteredDiameter: 'Diameter already registered',
    AlreadyRegisteredName: 'Name already registered',
    CantHaveSameOrder: 'Order can\' be the same as in other operations',
    SameOrderInSameGroupNotAllowed: 'Repeated orders are not allowed in the same group',
    SameNameInSameGroupNotAllowed: 'Repeated names are not allowed in the same group',
    ProductivityAlreadyExists: 'Productivity already exists',
    InitialDateRequired: 'Start date is required',
    FinalDateRequired: 'End date is required',
    FinalDateSmallerThanInitialDate: 'End date can\'t be less than start date',
    SearchTimeRangeCantExceed: 'Search time can\'t exceed',
    CodeTypeDoesntAllowNewGroups: 'Code type doesn\'t allow new groups',
    ThereAreUnsavedPriorities: 'There are unsaved priorities. Would you like to save anyway?',
    CantHaveSubElementsWithSamePriority: 'Two sub-elements can\'t have the same priority',
    NoDataMessage: 'No data to show yet',
    YouHaveUnsavedData: 'You have unsaved data',
    SheetImportSuccess: 'Spreadsheet imported successfully',
    SavedSuccess: 'Saved successfully',
    UpdateSentSuccessfully: 'Update sent successfuly',
    InvalidDate: 'Invalid date',
    InvalidPeriodDate: 'Period date before limit date',
    InvalidHour: 'Invalid hour',
    FutureDateCantBeSelected: 'Future date can\'t be selected',
    FutureTimeCantBeSelected: 'Future hour can\'t be selected',
    NoMaintenanceItems: 'No maintenance items registered',
    ModelUploadSuccess: 'Model uploaded successfully',
    ModelUploadError: 'Model upload error',
    ModelProcessingSuccess: 'Model processed successfully',
    UploadCanceled: 'Upload canceled',
    YouCanCheckYourDates: 'Attention! By submitting changes you may generate inconsistency in the timeline, are you sure you want to continue?',
    EndMomentCantBeLowerThanStartMoment: 'End moment can\'t be lower than Start moment',
    DateCantBeLowerThanConsolidation: 'Date can\'t be lower than consolidation time',
    ImportErrors: 'Import Errors',
    Row: 'Row',
    Col: 'Column',
    notEmpty: 'Cannot be empty',
    maxLength64: 'Can only have 64 characters',
    mustBeNumeric: 'Must be numeric',
    validChars: 'Can only contain -, _, |, +',
    GroupNotFound: 'Group not found.',
    DuplicatedRegister: 'Duplicated register.',
    CannotEditIdOrGroup: 'Cannot edit ID or Group.',
    Inactive: 'Inactive',
    EquipTypeNotFound: 'Equip type not found.',
    CodeTypeNotFound: 'Code type not found.',
    CodeTypeAndCodeGroupNotRelated: 'Code type, code group aren\'t related.',
    EquipTypeAndGroupTypeAndGroupCodeNotRelated: 'Equip type, code type, code group aren\'t related.',
  },
  ptbr: {
    PrecedenceCalculationWillOverrideCurrentConfiguration: 'O cálculo de precedências irá sobrescrever sua configuração atual. Deseja continuar?',
    FinalElevationMustBeBiggerThanInitialElevation: 'Elevação final deve ser maior que a inicial',
    LineupScenariosInDateRangeWillBeOverriden: 'Cenários neste intervalo de datas serão sobrescritos',
    FormWasNotFilledCorrectly: 'O formulário não foi preenchido corretamente',
    ExistingSchedule: 'Este equipamento ja está programado para este período',
    WarningScheduleChange: 'A modificação afetará programações subsequentes. Deseja continuar?',
    WarningTerrainModelDelete: 'Tem certeza que deseja excluir este modelo de terreno?',
    WarningNoDefaultModelSelected: 'Você deve selecionar um modelo padrão',
    ThisActionWillClearAllYourAllocations: 'Esta ação excluirá todas as alocações atuais. Deseja continuar?',
    ExpiredSession: 'Sua sessão expirou, faça login novamente para continuar',
    DateIntervalAlreadyRegistered: 'Intervalo de datas já registrado',
    AlreadyHasFootage: 'Metragem já cadastrada',
    TurnGroupAlreadyRegisteredForEquipGroup: 'Já existe um grupo de turnos cadastrado para este grupo de equipamentos',
    ThereAreBlankFields: 'Todos os campos devem ser preenchidos antes de salvar',
    CantHaveMultipleTurnsWithSameTeam: 'Uma turma não pode ser associada a dois turnos distintos no mesmo dia',
    AlreadyRegisteredUnit: 'Unidade já cadastrada',
    WouldYouLikeToUpdateApps: 'Você gostaria de atualizar os aplicativos?',
    WouldYouLikeToExit: 'Você gostaria de sair do sistema?',
    RequiredField: 'Campo obrigatório',
    AlreadyRegisteredFootage: 'Metragem já cadastrada',
    AlreadyRegisteredDiameter: 'Diâmetro já cadastrado',
    AlreadyRegisteredName: 'Nome já cadastrado',
    CantHaveSameOrder: 'Não pode ter a mesma ordem que outra operação',
    SameOrderInSameGroupNotAllowed: 'Não é permitido mesma ordem no mesmo grupo',
    SameNameInSameGroupNotAllowed: 'Não é permitido mesmo nome no mesmo grupo',
    ProductivityAlreadyExists: 'Produtividade já existe',
    InitialDateRequired: 'Data inicial deve ser preenchida',
    FinalDateRequired: 'Data final deve ser preenchida',
    FinalDateSmallerThanInitialDate: 'Data final não pode ser menor que Data inicial',
    SearchTimeRangeCantExceed: 'Tempo de pesquisa não pode exceder',
    CodeTypeDoesntAllowNewGroups: 'Tipo de código não permite grupos novos',
    ThereAreUnsavedPriorities: 'Existem prioridades ainda não definidas. Gostaria de salvar mesmo assim?',
    CantHaveSubElementsWithSamePriority: 'Você não pode ter sub-elementos com mesma prioridade.',
    NoDataMessage: 'Ainda não existem dados disponíveis',
    YouHaveUnsavedData: 'Você possui dados alterados que precisam ser salvos',
    SheetImportSuccess: 'Planilha importada com sucesso',
    SavedSuccess: 'Salvo com sucesso',
    UpdateSentSuccessfully: 'Atualização enviada com sucesso',
    InvalidDate: 'Data inválida',
    InvalidPeriodDate: 'Data anterior a data limite',
    InvalidHour: 'Hora inválida',
    FutureDateCantBeSelected: 'Data futura não pode ser selecionada',
    FutureTimeCantBeSelected: 'Hora futura não pode ser selecionada',
    NoMaintenanceItems: 'Não existem dados de manutenção cadastrados',
    ModelUploadSuccess: 'Modelo enviado com sucesso',
    ModelUploadError: 'Erro ao enviar modelo',
    ModelProcessingSuccess: 'Modelo processado com sucesso',
    UploadCanceled: 'Upload cancelado',
    YouCanCheckYourDates: 'Atenção! Ao enviar as alterações você pode gerar inconsistência na linha do tempo, tem certeza que deseja continuar?',
    EndMomentCantBeLowerThanStartMoment: 'Momento Final não pode ser menor que o inicial',
    DateCantBeLowerThanConsolidation: 'A data não pode ser menor do que a data de consolidação',
    ImportErrors: 'Erros de Importação',
    Row: 'Linha',
    Col: 'Coluna',
    notEmpty: 'Não pode estar vazio',
    maxLength64: 'Pode ter no máximo 64 caracteres',
    mustBeNumeric: 'Deve ser numérico',
    validChars: 'Pode conter apenas -, _, |, +',
    GroupNotFound: 'Grupo não encontrado.',
    DuplicatedRegister: 'Registro duplicado.',
    CannotEditIdOrGroup: 'Não é possível editar o ID ou o Grupo.',
    Inactive: 'Inativo',
    EquipTypeNotFound: 'Tipo de equipamento não encontrado.',
    CodeTypeNotFound: 'Tipo de código não encontrado.',
    CodeTypeAndCodeGroupNotRelated: 'Tipo de código e grupo de código não estão relacionados.',
    EquipTypeAndGroupTypeAndGroupCodeNotRelated: 'Tipo de equipamento, tipo de código e grupo de código não estão relacionados.',
  },
  es: {
    FinalElevationMustBeBiggerThanInitialElevation: 'La elevación final debe ser mayor que la inicial',
    LineupScenariosInDateRangeWillBeOverriden: 'Los escenarios en este rango de fechas se sobrescribirán',
    FormWasNotFilledCorrectly: 'El formulario no se ha llenado correctamente',
    ExistingSchedule: 'Este equipamento ja está programado para este período',
    WarningScheduleChange: 'A modificação afetará programações subsequentes. Deseja continuar?',
    WarningTerrainModelDelete: '¿Está seguro de que desea eliminar este modelo de terreno?',
    WarningNoDefaultModelSelected: 'Debe seleccionar un modelo predeterminado',
    AlreadyHasFootage: 'Metraje ya registrado',
    YouHaveUnsavedData: 'Se han modificado datos que deben guardarse',
    ThisActionWillClearAllYourAllocations: 'Esta acción eliminará todas las selecciones actuales. ¿Desea continuar?',
    ExpiredSession: 'Su sesión ha expirado, vuelva a iniciar sesión para continuar',
    DateIntervalAlreadyRegistered: 'Intervalo de fechas ya registrado',
    AlreadyRegisteredFootage: 'Metraje ya registrado',
    TurnGroupAlreadyRegisteredForEquipGroup: 'Ya hay un grupo de turnos registrados para este grupo de equipos',
    ThereAreBlankFields: 'Todos los campos deben completarse antes de guardar',
    CantHaveMultipleTurnsWithSameTeam: 'Un equipo no se puede asociar a dos turnos diferentes en el mismo día',
    AlreadyRegisteredUnit: 'Unidad ya registrada',
    WouldYouLikeToUpdateApps: '¿Le gustaría actualizar las aplicaciones?',
    WouldYouLikeToExit: '¿Le gustaría salir del sistema?',
    RequiredField: 'Campo obligatorio',
    AlreadyRegisteredDiameter: 'Diámetro ya registrado',
    AlreadyRegisteredName: 'Nombre ya registrado',
    CantHaveSameOrder: 'No puede tener el mismo orden que otra operación',
    SameOrderInSameGroupNotAllowed: 'No se permite el mismo orden en el mismo grupo',
    SameNameInSameGroupNotAllowed: 'No se permite el mismo nombre en el mismo grupo',
    ProductivityAlreadyExists: 'Productividad ya existente',
    InitialDateRequired: 'Se debe completar la fecha de inicio',
    FinalDateRequired: 'Se debe completar la fecha de final',
    FinalDateSmallerThanInitialDate: 'La fecha final no puede ser menor que la fecha inicial',
    SearchTimeRangeCantExceed: 'El tiempo de búsqueda no puede excederse',
    CodeTypeDoesntAllowNewGroups: 'El tipo de código no permite grupos nuevos',
    ThereAreUnsavedPriorities: 'Hay prioridades que aún no se han definido. ¿Le gustaría guardar de todos modos?',
    CantHaveSubElementsWithSamePriority: 'No puede tener subelementos con la misma prioridad.',
    NoDataMessage: 'Aún no hay datos disponibles',
    SheetImportSuccess: 'Planilla importada correctamente',
    SavedSuccess: 'Se ha guardado exitosamente',
    UpdateSentSuccessfully: 'Actualización enviada exitosamente',
    InvalidDate: 'Fecha inválida',
    InvalidPeriodDate: 'Fecha antes de la fecha límite',
    InvalidHour: 'Hora inválida',
    FutureDateCantBeSelected: 'No se puede seleccionar la fecha futura',
    FutureTimeCantBeSelected: 'No se puede seleccionar la hora futura',
    NoMaintenanceItems: 'No hay datos de mantenimiento registrados',
    ModelUploadSuccess: 'Modelo subido exitosamente',
    ModelUploadError: 'Error al subir modelo',
    ModelProcessingSuccess: 'Modelo procesado exitosamente',
    UploadCanceled: 'Subida cancelada',
    YouCanCheckYourDates: '¡Atención! Al enviar cambios, puede generar inconsistencias en la programación. Estás seguro de que quieres continuar?',
    EndMomentCantBeLowerThanStartMoment: 'La hora de finalización no puede ser anterior a la hora de inicio',
    DateCantBeLowerThanConsolidation: 'La fecha no puede ser anterior a la fecha de consolidación',
    ImportErrors: 'Errores de Importación',
    Row: 'Fila',
    Col: 'Columna',
    notEmpty: 'No puede estar vacío',
    maxLength64: 'Puede tener un máximo de 64 caracteres',
    mustBeNumeric: 'Debe ser numérico',
    validChars: 'Solo puede contener -, _, |, +',
    GroupNotFound: 'Grupo no encontrado.',
    DuplicatedRegister: 'Registro duplicado.',
    CannotEditIdOrGroup: 'No se puede editar el ID o el Grupo.',
    Inactive: 'Inactivo',
    EquipTypeNotFound: 'Tipo de equipo no encontrado.',
    CodeTypeNotFound: 'Tipo de código no encontrado.',
    CodeTypeAndCodeGroupNotRelated: 'Tipo de código y grupo de código no están relacionados.',
    EquipTypeAndGroupTypeAndGroupCodeNotRelated: 'Tipo de equipo, tipo de código y grupo de código no están relacionados.',
  },
};

export const tips = {
  en: {
    CharacterLimitExceeded: 'Character limit exceeded',
    YouMustSelectAllFilters: 'You must select all filters',
    LimitExceeded: 'Limit exceeded',
    DynamicText: `Discover the power of the Dynamic Module!
    Automate your transportation and loading cycles, manage your database intelligently and get dynamic allocations in dispatch.
    With Data Driven triggers and many other advanced features, Dynamic is the tool you need to optimize your operations.
    Want to know how Dynamic can transform your business? Our sales team is available to provide all the details and benefits.`,
    NoModuleText: `You don't have this module yet.
    Would you like to know more about it? Our sales team will be happy to assist you.`,
  },
  ptbr: {
    CharacterLimitExceeded: 'Limite de caracteres excedido',
    YouMustSelectAllFilters: 'Você deve selecionar todos os filtros',
    LimitExceeded: 'Limite excedido',
    DynamicText: `Descubra o poder do Módulo Dynamic!   
    Automatize seus ciclos de transporte e carga, gerencie sua base de dados de forma inteligente e obtenha alocações dinâmicas no despacho.
    Com gatilhos Data Driven e muitas outras funcionalidades avançadas, o Dynamic é a ferramenta que você precisa para otimizar suas operações. 
    Quer saber como o Dynamic pode transformar seu negócio? Nossa equipe comercial está à disposição para oferecer todos os detalhes e benefícios.`,
    NoModuleText: `Você ainda não tem esse módulo contratado. 
    Deseja saber mais sobre? Nossa equipe comercial vai ter o prazer de atendê-lo.`,
  },
  es: {
    CharacterLimitExceeded: 'Límite de caracteres excedido',
    YouMustSelectAllFilters: 'Debe seleccionar todos los filtros',
    LimitExceeded: 'Límite excedido',
    DynamicText: `¡Descubre el poder del Módulo Dynamic!
    Automatiza tus ciclos de transporte y carga, gestiona tu base de datos de forma inteligente y obtén asignaciones dinámicas en el despacho.
    Con disparadores Data Driven y muchas otras funciones avanzadas, Dynamic es la herramienta que necesitas para optimizar tus operaciones.
    ¿Quieres saber cómo Dynamic puede transformar tu negocio? Nuestro equipo de ventas está disponible para proporcionarte todos los detalles y beneficios.`,
    NoModuleText: `Aún no tienes este módulo contratado.
    ¿Te gustaría saber más sobre él? Nuestro equipo de ventas estará encantado de ayudarte.`,
  },
};

export const indicators = {
  en: {
    load_queue_time: 'Loading Queue Time',
    unload_queue_time: 'Unloading Queue Time',
    total_queue_time: 'Total Queue Time',
    load_maneuver_time: 'Loading Maneuver Time',
    unload_maneuver_time: 'Unloading Maneuver Time',
    total_maneuver_time: 'Total Maneuver Time',
    unload_time: 'Unloading Time',
    load_time: 'Loading Time',
    empty_time: 'Empty Time',
    full_time: 'Full Time',
    total_transport_time: 'Total Transport Time',
    empty_distance: 'Empty Distance',
    full_distance: 'Full Distance',
    total_distance: 'Average Distance',
    load_manager: 'Material Volume',
    stop_time: 'Stop Time',
    last_1_cicles: 'Last Cycle',
    last_10_cicles: 'Last 10 Cycles',
  },
  ptbr: {
    load_queue_time: 'Tempo de Fila de Carregamento',
    unload_queue_time: 'Tempo de Fila de Descarregamento',
    total_queue_time: 'Tempo Total de Fila',
    load_maneuver_time: 'Tempo de Manobra de Carregamento',
    unload_maneuver_time: 'Tempo de Manobra de Basculamento',
    total_maneuver_time: 'Tempo Total de Manobra',
    unload_time: 'Tempo de Basculamento',
    load_time: 'Tempo de Carregamento',
    empty_time: 'Tempo Vazio',
    full_time: 'Tempo Cheio',
    total_transport_time: 'Tempo Total de Transporte',
    empty_distance: 'Distância Vazia',
    full_distance: 'Distância Cheia',
    total_distance: 'Distância Média',
    load_manager: 'Volume de Material',
    stop_time: 'Tempo de Parada',
    last_1_cicles: 'Último ciclo',
    last_10_cicles: 'Últimos 10 ciclos',
  },
  es: {
    load_queue_time: 'Tiempo de Cola de Carga',
    unload_queue_time: 'Tiempo de Cola de Descarga',
    total_queue_time: 'Tiempo Total de Cola',
    load_maneuver_time: 'Tiempo de Maniobra de Carga',
    unload_maneuver_time: 'Tiempo de Maniobra de Descarga',
    total_maneuver_time: 'Tiempo Total de Maniobra',
    unload_time: 'Tiempo de Descarga',
    load_time: 'Tiempo de Carga',
    empty_time: 'Tiempo Vacío',
    full_time: 'Tiempo Lleno',
    total_transport_time: 'Tiempo Total de Transporte',
    empty_distance: 'Distancia Vacía',
    full_distance: 'Distancia Llena',
    total_distance: 'Distancia Promedio',
    load_manager: 'Volumen de Material',
    stop_time: 'Tiempo de Parada',
    last_1_cicles: 'Último Ciclo',
    last_10_cicles: 'Últimos 10 Ciclos',
  },
};

/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import {
  Card, CardBody, CardHeader, CardIcon,
} from '~/components/Card';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { setRailroadValues } from '~/store/dailyparts/actions';
import PF2MDataTable from '~/components/PF2MDataTable';
import PF2MDiscardChangesButton from '~/components/PF2MDiscardChangesButton';
import PF2MFinalizeButton from '~/components/PF2MFinalizeButton';

import { diffTwoDateTimeStr, formatDateToServerFormat } from '~/utils/moment';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import { useDispatch } from 'react-redux';
import { AccessTime } from '@material-ui/icons';
import styles from './styles';

const RailroadCycleStepTimesModal = ({ handleClose, railroadReport }) => {
  const { t: translate } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cycleEntryTimeData, setCycleEntryTimeData] = useState([]);
  const [isModalDiscardOpen, setIsModalDiscardOpen] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const dispatch = useDispatch();

  const getDurationTime = ({
    start_date,
    start_time,
    end_date,
    end_time,
    duration,
  }) => {
    if (!start_date || !start_time || !end_date || !end_time) {
      return 0;
    }
    const diff = diffTwoDateTimeStr(start_date, start_time, end_date, end_time);

    const durationTime = (((diff / 1000) / 60) - (duration ?? 0));

    return Number(durationTime.toFixed(2));
  };

  const resetCycleEntryTimeData = useCallback(() => {
    const prefixFieldsCycleEntryTime = [
      'port_exit',
      'empty',
      'load_entry',
      'load_queue',
      'load',
      'load_exit',
      'full',
      'port_entry',
      'unload_queue',
      'unload',
    ];

    const newCycleEntryTimeData = prefixFieldsCycleEntryTime.map((prefix, index) => {
      const id = index;
      const step = translate(`common:railroad_cycle_time_${prefix}`);
      const start_date = railroadReport[`${prefix}_start_date`];
      const start_time = railroadReport[`${prefix}_start_time`];
      const end_date = railroadReport[`${prefix}_end_date`];
      const end_time = railroadReport[`${prefix}_end_time`];
      const duration = railroadReport[`${prefix}_time`] ? Number(railroadReport[`${prefix}_time`]?.toFixed(2)) : 0;
      const code_time = getDurationTime(
        {
          start_date,
          start_time,
          end_date,
          end_time,
          duration,
        },
      );

      return {
        id, prefix, step, start_date, start_time, end_date, end_time, duration, code_time,
      };
    });

    setHasChanges(false);
    setCycleEntryTimeData(newCycleEntryTimeData);
  }, [railroadReport, translate]);

  useEffect(() => resetCycleEntryTimeData(), [resetCycleEntryTimeData]);

  const columns = [
    {
      editable: 'never',
      title: translate('common:Step'),
      field: 'step',
    },
    {
      editable: 'always',
      title: translate('common:InitialDate'),
      field: 'start_date',
      type: 'date',
    },
    {
      editable: 'always',
      title: translate('common:InitialHour'),
      field: 'start_time',
      customType: 'time',
    },
    {
      editable: 'always',
      title: translate('common:FinalDate'),
      field: 'end_date',
      type: 'date',
    },
    {
      editable: 'always',
      title: translate('common:FinalHour'),
      field: 'end_time',
      customType: 'time',
    },
    {
      editable: 'never',
      title: translate('common:Duration'),
      field: 'duration',
    },
    {
      editable: 'never',
      title: translate('common:TotalTimeInCode'),
      field: 'code_time',
    },
  ];

  const handleOnChange = (cycleEntryTimeDataToChange) => {
    setCycleEntryTimeData((currentCycleEntryTimeData) => {
      currentCycleEntryTimeData.forEach((cycleStepTime, index) => {
        cycleEntryTimeDataToChange.forEach((updated) => {
          if (cycleStepTime.id === updated.id) {
            let {
              // eslint-disable-next-line prefer-const
              start_date, end_date, start_time, end_time, duration,
            } = updated;
            if (start_date?.getDate !== undefined) {
              start_date = formatDateToServerFormat(start_date);
            }
            if (end_date?.getDate !== undefined) {
              end_date = formatDateToServerFormat(end_date);
            }
            // eslint-disable-next-line
            currentCycleEntryTimeData[index] = {
              ...updated,
              start_date: start_date || '',
              end_date: end_date || '',
              duration: railroadReport.update_status !== 'I' ? duration : ((diffTwoDateTimeStr(start_date, start_time, end_date, end_time) / 1000) / 60),
            };
          }
        });
      });
      return currentCycleEntryTimeData;
    });
    setHasChanges(true);
  };

  const handleOnApply = () => {
    const newRailroadReport = {
      ...railroadReport,
      tableData: undefined,
    };

    cycleEntryTimeData.forEach(({
      prefix, start_date, start_time, end_date, end_time, duration,
    }) => {
      newRailroadReport[`${prefix}_start_date`] = start_date;
      newRailroadReport[`${prefix}_start_time`] = start_time;
      newRailroadReport[`${prefix}_end_date`] = end_date;
      newRailroadReport[`${prefix}_end_time`] = end_time;
      newRailroadReport[`${prefix}_time`] = duration;
    });

    dispatch(setRailroadValues(newRailroadReport));
    handleClose();
  };

  const handleConfirmChanges = () => {
    setIsModalOpen(false);
    if (hasChanges) {
      handleOnApply();
    }
  };

  const handleOnDiscardChanges = () => {
    if (hasChanges) {
      setIsModalDiscardOpen(true);
    }
  };

  const handleConfirmDiscardChanges = () => {
    resetCycleEntryTimeData();
    setIsModalDiscardOpen(false);
    setHasChanges(false);
  };

  return (
    <div>
      <Card>
        <CardHeader icon>
          <CardIcon color="warning">
            <AccessTime />
          </CardIcon>
          <CardIcon
            onClick={handleClose}
            style={{
              float: 'right',
              cursor: 'pointer',
              background: 'darkgray',
              borderRadius: 50,
              boxShadow: '10px 10px 50px gray',
            }}
          >
            <CloseIcon />
          </CardIcon>
        </CardHeader>
        <CardBody>
          <Typography
            variant="h4"
            align="center"
            gutterBottom
          >
            {translate('common:CycleStageTimes')}
          </Typography>
          <PF2MDataTable
            options={{
              search: true,
              selection: true,
              hideDelete: true,
              hideAdd: true,
            }}
            onChange={handleOnChange}
            onDiscard={handleOnDiscardChanges}
            onApply={() => setIsModalOpen(true)}
            columns={columns}
            data={cycleEntryTimeData.length > 0 ? cycleEntryTimeData : []}
          />

        </CardBody>

        <Grid item style={{ alignSelf: 'center' }}>
          <PF2MDiscardChangesButton
            onClick={handleOnDiscardChanges}
          />
          <PF2MFinalizeButton onClick={() => setIsModalOpen(true)} />
        </Grid>

      </Card>

      <PF2MAlertDialog
        hasCancel
        confirmText={translate(hasChanges ? 'common:Confirm' : 'common:Ok')}
        description={translate(hasChanges ? 'common:ConfirmChanges' : 'common:ThereAreNoChanges')}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirmChanges}
      />

      <PF2MAlertDialog
        hasCancel
        confirmText={translate('common:Discard')}
        description={translate('common:DiscardChanges')}
        open={isModalDiscardOpen}
        onClose={() => setIsModalDiscardOpen(false)}
        onConfirm={handleConfirmDiscardChanges}
      />
    </div>
  );
};

RailroadCycleStepTimesModal.propTypes = {
  handleClose: PropTypes.func,
  railroadReport: PropTypes.object,
};

RailroadCycleStepTimesModal.defaultProps = {
  handleClose: () => {},
  railroadReport: {},
};

export default withStyles(styles)(RailroadCycleStepTimesModal);

import React from 'react';
import {
  withStyles, Grid, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import PF2MSearchButton from '~/components/PF2MSearchButton';

function PF2MSearchHeader({ classes, handleModal }) {
  const filters = useSelector(state => state.dailyParts.filters);
  const { t: translate } = useTranslation();

  const {
    startDate = moment(new Date()),
    endDate = moment(new Date()),
    projectGroup,
    element,
    subElement,
  } = filters;

  return (
    <div className="container">
      <Grid container className={classes.container}>
        <Grid item container xs className={classes.headerData}>
          <Grid item>
            <Typography variant="caption">
              {`${translate('common:ResultsFor')}:`}
            </Typography>
          </Grid>
          <Grid item container>
            <Typography className={classes.text}>
              <span style={styles.dataValue}>{`${startDate.format(translate('date:DateFormat'))} - ${endDate.format(translate('date:DateFormat'))}`}</span>
              <span>{projectGroup ? `, ${translate('common:ProjectGroup')}` : null}</span>
              <span style={styles.dataValue}>{projectGroup ? projectGroup.name : null}</span>
              <span>{element ? `, ${translate('common:Element')}` : null}</span>
              <span style={styles.dataValue}>{element ? element.name : null}</span>
              <span>{subElement ? `, ${translate('common:SubElement')}` : null}</span>
              <span style={styles.dataValue}>{subElement ? subElement.name : null}</span>
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <PF2MSearchButton size="medium" onClick={() => handleModal(true)} />
        </Grid>
      </Grid>
    </div>
  );
}

PF2MSearchHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  handleModal: PropTypes.func,
};

PF2MSearchHeader.defaultProps = {
  handleModal: null,
};

export default withStyles(styles)(PF2MSearchHeader);

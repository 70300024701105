import React, { useEffect, useState } from 'react';
import {
  Form, Field, ErrorMessage, Formik,
} from 'formik';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  Card, CardBody, CardHeader, CardIcon,
} from '~/components/Card';
import PF2MSearchSelect from '~/components/PF2MSearchSelect';
import PF2MSearchButton from '~/components/PF2MSearchButton';
import PF2MClearFiltersButton from '~/components/PF2MClearFiltersButton';
import PF2MDatepicker from '~/components/PF2MDatepicker';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import {
  getOperatorsGroups,
  getOperators,
  getEquipmentTypes,
  getEquipmentsGroups,
  getEquipments,
  getExceptionTypes,
  getTurns,
  getTurnsGroups,
  getTeamGroups,
  getTeams,
  getCodeGroups as getManagementCodeGroups,
  getCodes as getManagementCodes,
} from '~/store/manager/actions';
import { setMaintenanceFilters } from '~/store/dailyparts/actions';
import {
  getCodeTypes,
  getCodeGroups,
  getCodes,
  getSystems,
  getSystemItems,
  getFailureModes,
} from '~/store/maintenance/actions';
import { newMomentDate } from '~/utils/moment';

const MAINTENANCE_TYPE = 2;

const renderMenuItem = (key, value, text) => (
  { label: text, value }
);

const SearchForm = ({ classes, onSearch, onClose }) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const dispatch = useDispatch();
  const filters = useSelector(state => state.dailyParts.filters);
  const turnsGroups = useSelector(state => state.manager.turnsGroups);
  const turns = useSelector(state => state.manager.turns);
  const operatorsGroups = useSelector(state => state.manager.operatorsGroups
    .filter(s => s.name));
  const operators = useSelector(state => state.manager.operators
    .filter(s => s.name && s.type === MAINTENANCE_TYPE));
  const equipmentTypes = useSelector(state => state.manager.equipmentTypes);
  const equipmentGroups = useSelector(state => state.manager.equipmentsGroups);
  const equipments = useSelector(state => state.manager.equipments);

  const codeTypes = useSelector(state => state.maintenance.codeTypes);
  const codeGroups = useSelector(state => state.maintenance.codeGroups);
  const codes = useSelector(state => state.maintenance.codes);
  const { t: translate } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      dispatch(getTurns(true));
      dispatch(getTurnsGroups(true));
      dispatch(getOperatorsGroups(true));
      dispatch(getOperators(true));
      dispatch(getEquipmentTypes(true));
      dispatch(getEquipmentsGroups(true));
      dispatch(getEquipments(true));
      dispatch(getExceptionTypes());
      dispatch(getTeamGroups(true));
      dispatch(getTeams(true));
      dispatch(getCodeTypes());
      dispatch(getCodeGroups());
      dispatch(getCodes());
      dispatch(getSystems());
      dispatch(getManagementCodeGroups());
      dispatch(getManagementCodes());
      dispatch(getFailureModes());
      dispatch(getSystemItems());
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch]);

  const renderTurnsGroups = () => [renderMenuItem('turn_group_0', 0, translate('common:All'))]
    .concat(...turnsGroups.map(e => renderMenuItem(e.id, e.id, `${e.name}`)));

  const renderTurns = (turnGroupId = 0) => [renderMenuItem('turn_0', null, translate('common:All'))]
    .concat(...turns
      .filter(o => turnGroupId === 0 || o.id_turn_group === turnGroupId)
      .map(e => renderMenuItem(e.id, e.id, `${e.name}`)));

  const renderEquipmentTypes = () => [renderMenuItem('equipment_type_0', 0, translate('common:All'))]
    .concat(...equipmentTypes.map(e => renderMenuItem(e.id, e.id, `${e.name}`)));

  const renderEquipmentGroups = (equipmentTypeId = 0) => [renderMenuItem('equipment_group_0', 0, translate('common:All'))]
    .concat(...equipmentGroups
      .filter(o => equipmentTypeId === 0 || o.id_equipament === equipmentTypeId)
      .map(e => renderMenuItem(e.id, e.id, `${e.name}`)));

  const renderEquipments = (equipmentGroupId = 0) => [renderMenuItem('equipment_0', 0, translate('common:All'))]
    .concat(...equipments
      .filter(o => equipmentGroupId === 0 || o.id_group === equipmentGroupId)
      .map(e => renderMenuItem(e.id, e.id, `${e.name}`)));

  const renderOperatorsGroups = () => [renderMenuItem('operator_group_0', 0, translate('common:All'))]
    .concat(...operatorsGroups.map(e => renderMenuItem(e.id, e.id, `${e.name}`)));

  const renderOperators = (operatorGroupId = 0) => [renderMenuItem('operator_0', 0, translate('common:All'))]
    .concat(...operators
      .filter(o => operatorGroupId === 0 || o.id_group === operatorGroupId)
      .map(e => renderMenuItem(e.id_operator, e.id_operator, `${e.name}`)));

  const renderMaintenanceTypes = () => [renderMenuItem('maintenance_type_0', 0, translate('common:All'))]
    .concat(...codeTypes.map(e => renderMenuItem(e.id, e.id, `${e.name}`)));
  const renderMaintenanceGroups = (codeTypeId = 0) => [renderMenuItem('maintenance_group_0', 0, translate('common:All'))]
    .concat(...codeGroups
      .filter(o => codeTypeId === 0 || o.id_code_type_maintenance === codeTypeId)
      .map(e => renderMenuItem(e.id, e.id, `${e.name}`)));
  const renderMaintenanceCodes = (codeGroupId = 0) => [renderMenuItem('maintenance_code_0', 0, translate('common:All'))]
    .concat(...codes
      .filter(o => codeGroupId === 0 || o.id_code_group_maintenance === codeGroupId)
      .map(e => renderMenuItem(e.id, e.id, `${e.name}`)));

  const renderDate = field => (
    <Field name={field} component={PF2MDatepicker} />
  );

  const renderSelect = (name, children, handleState) => (
    <>
      <Field
        name={name}
        render={({ field, form }) => (
          <PF2MSearchSelect
            placeholder={null}
            {...field}
            onChange={(e) => {
              form.setFieldValue(field.name, e.target.value, true);
              if (handleState) {
                handleState(e.target.value.id);
              }
            }}
            style={{ height: 31, width: '100%' }}
          >
            {children}
          </PF2MSearchSelect>
        )}
      />
      <ErrorMessage
        name={name}
        render={msg => (
          <Typography
            color="error"
            variant="caption"
          >
            {msg}
          </Typography>
        )}
      />
    </>
  );

  const renderCheckbox = (name, label, checked) => (
    <Field
      name={name}
      render={({ field, form }) => (
        <FormControlLabel
          style={{ marginLeft: 0 }}
          control={(
            <Checkbox
              checked={!!checked}
              onChange={() => form.setFieldValue(field.name, !field.value)}
            />
          )}
          label={label}
        />
      )}
    />
  );

  return (
    <Formik
      isInitialValid
      initialValues={{
        startDate: filters.startDate || newMomentDate(),
        endDate: filters.endDate || newMomentDate(),
        turn: filters.turn ? filters.turn.id : null,
        turnGroup: filters.turnGroup ? filters.turnGroup.id : 0,
        equipmentType: filters.equipmentType ? filters.equipmentType.id : 0,
        equipmentGroup: filters.equipmentGroup ? filters.equipmentGroup.id : 0,
        equipment: filters.equipment ? filters.equipment.id : 0,
        operatorGroup: filters.operatorGroup ? filters.operatorGroup.id : 0,
        operator: filters.operator ? filters.operator.id_operator : 0,
        codeType: filters.codeType ? filters.codeType.id : 0,
        codeGroup: filters.codeGroup ? filters.codeGroup.id : 0,
        code: filters.code ? filters.code.id : 0,
        hideInterruptedOrDeleted: filters.hideInterruptedOrDeleted,
      }}
      validate={(values) => {
        const { startDate, endDate } = values;
        const startDateMoment = moment(startDate);
        const endDateMoment = moment(endDate);
        const errors = {};

        if (!startDateMoment.isValid()) {
          errors.startDate = translate('validations:InitialDateRequired');
        }

        if (!endDateMoment.isValid()) {
          errors.endDate = translate('validations:FinalDateRequired');
        }

        if (startDateMoment.isValid() && endDateMoment.isValid()) {
          const days = endDateMoment.diff(startDateMoment, 'days');
          if (days < 0) {
            errors.endDate = translate('validations:FinalDateSmallerThanInitialDate');
          }

          const searchLimit = process.env.REACT_APP_SEARCH_LIMIT_IN_DAYS || 7;
          if (days > searchLimit) {
            errors.endDate = `${translate('validations:SearchTimeRangeCantExceed')} ${searchLimit} ${translate('common:Days')}`;
          }
        }

        return errors;
      }}
      onSubmit={async (values, { resetForm }) => {
        const {
          turn, turnGroup,
          equipmentType,
          equipmentGroup, equipment, operatorGroup, operator,
          codeType, codeGroup, code,
          hideInterruptedOrDeleted,
        } = values;
        const currentTurn = turns.find(e => e.id === turn);
        const currentTurnGroup = turnsGroups.find(e => e.id === turnGroup);
        const currentEquipmentType = equipmentTypes.find(e => e.id === equipmentType);
        const currentEquipmentGroup = equipmentGroups.find(e => e.id === equipmentGroup);
        const currentEquipment = equipments.find(e => e.id === equipment);
        const currentOperatorGroup = operatorsGroups.find(e => e.id === operatorGroup);
        const currentOperator = operators.find(e => e.id_operator === operator);
        const currentCodeType = codeTypes.find(e => e.id === codeType);
        const currentCodeGroup = codeGroups.find(e => e.id === codeGroup);
        const currentCode = codes.find(e => e.id === code);
        dispatch(setMaintenanceFilters({
          ...values,
          turn: currentTurn || 0,
          turnGroup: currentTurnGroup || 0,
          equipmentType: currentEquipmentType || 0,
          equipmentGroup: currentEquipmentGroup || 0,
          equipment: currentEquipment || 0,
          operatorGroup: currentOperatorGroup || 0,
          operator: currentOperator || 0,
          codeType: currentCodeType || 0,
          codeGroup: currentCodeGroup || 0,
          code: currentCode || 0,
          hideInterruptedOrDeleted: Boolean(hideInterruptedOrDeleted),
        }));
        resetForm();
        onSearch();
      }}
    >
      {({
        isSubmitting,
        isValid,
        values,
        resetForm,
      }) => (
        <Form>
          <Card style={{ width: 900 }}>
            <CardHeader icon>
              <>
                <CardIcon color="warning">
                  <SearchIcon />
                </CardIcon>
                <CardIcon
                  onClick={onClose}
                  style={{
                    float: 'right',
                    cursor: 'pointer',
                    background: 'darkgray',
                    borderRadius: 50,
                    boxShadow: '10px 10px 50px gray',
                  }}
                >
                  <CloseIcon />
                </CardIcon>
              </>
            </CardHeader>
            <CardBody>
              <Typography
                variant="h4"
                align="center"
                gutterBottom
              >
                {translate('common:Search')}
              </Typography>
              <div style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, minmax(200px, 1fr))',
                gridColumnGap: 10,
                gridRowGap: 10,
                padding: 30,
              }}
              >
                {/* primeira linha */}
                <div>
                  <span className={classes.labelSpan}>
                    {translate('common:InitialDate')}
                  </span>
                  {renderDate('startDate', values.startDate)}
                </div>
                <div style={{ gridColumn: '2 / 3' }}>
                  <span className={classes.labelSpan}>
                    {translate('common:EndDate')}
                  </span>
                  {renderDate('endDate', values.endDate)}
                </div>
                {/* segunda linha */}
                <div style={{ gridColumnStart: '1' }}>
                  <span className={classes.labelSpan}>
                    {translate('common:ShiftGroup')}
                  </span>
                  {renderSelect('turnGroup', renderTurnsGroups())}
                </div>
                <div style={{ gridColumn: '2 / 3' }}>
                  <span className={classes.labelSpan}>
                    {translate('common:Shift')}
                  </span>
                  {renderSelect('turn', renderTurns(values.turnGroup))}
                </div>
                {/* terceira linha */}
                <div style={{ gridColumnStart: '1' }}>
                  <span className={classes.labelSpan}>
                    {translate('common:EquipmentType')}
                  </span>
                  {renderSelect('equipmentType', renderEquipmentTypes())}
                </div>
                <div>
                  <span className={classes.labelSpan}>
                    {translate('common:EquipmentGroup')}
                  </span>
                  {renderSelect('equipmentGroup', renderEquipmentGroups(values.equipmentType))}
                </div>
                <div>
                  <span className={classes.labelSpan}>
                    {translate('common:Equipment')}
                  </span>
                  {renderSelect('equipment', renderEquipments(values.equipmentGroup))}
                </div>
                {/* quarta linha */}
                <div style={{ gridColumnStart: '1' }}>
                  <span className={classes.labelSpan}>
                    {translate('common:OperatorGroup')}
                  </span>
                  {renderSelect('operatorGroup', renderOperatorsGroups())}
                </div>
                <div>
                  <span className={classes.labelSpan}>
                    {translate('common:Operator')}
                  </span>
                  {renderSelect('operator', renderOperators(values.operatorGroup))}
                </div>
                {/* quinta linha */}
                <div style={{ gridColumnStart: '1' }}>
                  <span className={classes.labelSpan}>
                    {translate('common:MaintenanceType')}
                  </span>
                  {renderSelect('codeType', renderMaintenanceTypes())}
                </div>
                <div>
                  <span className={classes.labelSpan}>
                    {translate('common:MaintenanceGroup')}
                  </span>
                  {renderSelect('codeGroup', renderMaintenanceGroups(values.codeType))}
                </div>
                <div>
                  <span className={classes.labelSpan}>
                    {translate('common:Maintenance')}
                  </span>
                  {renderSelect('code', renderMaintenanceCodes(values.codeGroup))}
                </div>
                <div style={{ gridColumn: '1 / -1' }}>
                  {renderCheckbox(
                    'hideInterruptedOrDeleted',
                    translate('common:HideInterruptedOrDeletedCycles'),
                    values.hideInterruptedOrDeleted,
                  )}
                </div>
              </div>
              <div className={classes.fieldsContainer}>
                <PF2MClearFiltersButton
                  onClick={() => resetForm({
                    startDate: newMomentDate(),
                    endDate: newMomentDate(),
                    turn: 0,
                    turnGroup: 0,
                    equipmentType: 0,
                    equipmentGroup: 0,
                    equipment: 0,
                    operatorGroup: 0,
                    operator: 0,
                    codeType: 0,
                    codeGroup: 0,
                    code: 0,
                  })}
                />
                <PF2MSearchButton
                  type="submit"
                  disabled={isSubmitting || !isValid}
                  onClick={null}
                />
              </div>
            </CardBody>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

SearchForm.propTypes = {
  classes: PropTypes.object.isRequired,
  onSearch: PropTypes.func,
  onClose: PropTypes.func,
};

SearchForm.defaultProps = {
  onSearch: null,
  onClose: null,
};

export default withStyles(styles)(SearchForm);

import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import Footer from '~/pages/Manager/Footer';
import PF2MTable from '~/components/PF2MTable';
import {
  getHourmeterNames,
  getEquipmentTypes,
  setHourmeterNamesValue,
  addHourmeterNames,
  resetHourmeterNames,
  updateHourmeterNames,

} from '~/store/manager/actions';
import Header from './Header';
import { parseFloat } from '~/utils';

const parseHourmeterNames = (hourmeterNamesObject) => {
  const fhn = hourmeterNamesObject;
  const formatHourmeter = (id, fieldName, variationName, visibleName) => ({
    id,
    id_equip_type: fhn.equipament_types_id,
    internalId: fhn.id,
    name: fhn[fieldName],
    variation: fhn[variationName],
    active: fhn[visibleName] * 1,
    fieldName,
    variationName,
    visibleName,
  });

  const mapped = [
    formatHourmeter(1, 'hourmeter_name', 'max_variation_1', 'hourmeter_visible'),
    formatHourmeter(2, 'kilometer_name', 'max_km_variation_2', 'kilometer_visible'),
    formatHourmeter(3, 'hourmeter_name_3', 'max_variation_3', 'hourmeter_visible_3'),
    formatHourmeter(4, 'hourmeter_name_4', 'max_variation_4', 'hourmeter_visible_4'),
    formatHourmeter(5, 'hourmeter_name_5', 'max_variation_5', 'hourmeter_visible_5'),
    formatHourmeter(6, 'hourmeter_name_6', 'max_variation_6', 'hourmeter_visible_6'),
    formatHourmeter(7, 'hourmeter_name_7', 'max_variation_7', 'hourmeter_visible_7'),
    formatHourmeter(8, 'hourmeter_name_8', 'max_variation_8', 'hourmeter_visible_8'),
    formatHourmeter(9, 'hourmeter_name_9', 'max_variation_9', 'hourmeter_visible_9'),
    formatHourmeter(10, 'hourmeter_name_10', 'max_variation_10', 'hourmeter_visible_10'),
  ];
  return mapped;
};

const unparseHourmeterNames = (parsedHourmeterNamesObject) => {
  const hn = {
    id: parsedHourmeterNamesObject[0].internalId,
    equipament_types_id: parsedHourmeterNamesObject[0].id_equip_type,
  };
  parsedHourmeterNamesObject.forEach((k) => {
    hn[k.fieldName] = k.name;
    hn[k.visibleName] = !!k.active;
    hn[k.variationName] = k.variation;
  });

  return hn;
};

const HourmeterManagerTab = ({
  classes,
  hourmeterNames,
  equipmentTypes,
  getEquipmentTypes: loadEquipmentTypes,
  getHourmeterNames: getAll,
  setHourmeterNamesValue: updateItem,
  updateHourmeterNames: update,
  addHourmeterNames: add,
  resetHourmeterNames: reset,

  isHourmeterNamesDirty,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [parsedHourmeterNames, setParsedHourmeterNames] = useState([]);
  const [selectedEquipamentType, setSelectedEquipamentType] = useState(0);
  const { t: translate } = useTranslation();

  const handleUpdateItem = (item, key, value) => {
    const newItem = unparseHourmeterNames(parsedHourmeterNames);
    const mappedKeyName = {
      name: { name: item.fieldName, transformValue: v => v },
      active: { name: item.visibleName, transformValue: Boolean },
      variation: { name: item.variationName, transformValue: parseFloat },
    };
    const newKey = mappedKeyName[key].name;
    const newValue = mappedKeyName[key].transformValue(value);
    return updateItem(newItem, newKey, newValue);
  };

  useEffect(() => {
    async function fetchData() {
      await getAll();
      await loadEquipmentTypes();
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, getAll, loadEquipmentTypes]);

  useEffect(() => {
    const fhn = hourmeterNames.find(e => e.equipament_types_id === selectedEquipamentType);
    if (fhn) {
      setParsedHourmeterNames(parseHourmeterNames(fhn));
    } else {
      const blankObject = [
        {
          id: 1, id_equip_type: selectedEquipamentType, internalId: -1, name: '', active: false, variation: '', fieldName: 'hourmeter_name', visibleName: 'hourmeter_visible', variationName: 'max_variation_1',
        },
        {
          id: 2, id_equip_type: selectedEquipamentType, internalId: -1, name: '', active: false, variation: '', fieldName: 'kilometer_name', visibleName: 'kilometer_visible', variationName: 'max_km_variation_2',
        },
        {
          id: 3, id_equip_type: selectedEquipamentType, internalId: -1, name: '', active: false, variation: '', fieldName: 'hourmeter_name_3', visibleName: 'hourmeter_visible_3', variationName: 'max_variation_3',
        },
        {
          id: 4, id_equip_type: selectedEquipamentType, internalId: -1, name: '', active: false, variation: '', fieldName: 'hourmeter_name_4', visibleName: 'hourmeter_visible_4', variationName: 'max_variation_4',
        },
        {
          id: 5, id_equip_type: selectedEquipamentType, internalId: -1, name: '', active: false, variation: '', fieldName: 'hourmeter_name_5', visibleName: 'hourmeter_visible_5', variationName: 'max_variation_5',
        },
        {
          id: 6, id_equip_type: selectedEquipamentType, internalId: -1, name: '', active: false, variation: '', fieldName: 'hourmeter_name_6', visibleName: 'hourmeter_visible_6', variationName: 'max_variation_6',
        },
        {
          id: 7, id_equip_type: selectedEquipamentType, internalId: -1, name: '', active: false, variation: '', fieldName: 'hourmeter_name_7', visibleName: 'hourmeter_visible_7', variationName: 'max_variation_7',
        },
        {
          id: 8, id_equip_type: selectedEquipamentType, internalId: -1, name: '', active: false, variation: '', fieldName: 'hourmeter_name_8', visibleName: 'hourmeter_visible_8', variationName: 'max_variation_8',
        },
        {
          id: 9, id_equip_type: selectedEquipamentType, internalId: -1, name: '', active: false, variation: '', fieldName: 'hourmeter_name_9', visibleName: 'hourmeter_visible_9', variationName: 'max_variation_9',
        },
        {
          id: 10, id_equip_type: selectedEquipamentType, internalId: -1, name: '', active: false, variation: '', fieldName: 'hourmeter_name_10', visibleName: 'hourmeter_visible_10', variationName: 'max_variation_10',
        },
      ];
      if (selectedEquipamentType !== 0) {
        add(unparseHourmeterNames(blankObject));
        setParsedHourmeterNames(blankObject);
      }
      setParsedHourmeterNames([]);
    }
  }, [hourmeterNames, selectedEquipamentType, add]);

  const columns = [
    {
      field: 'id',
      title: translate('common:Id'),
      editable: false,
    },
    {
      field: 'name',
      title: translate('common:Name'),
      editable: true,
    },
    {
      field: 'variation',
      title: translate('common:MaxVariation'),
      editable: true,
      align: 'left',
      type: 'decimal',
    },
    {
      field: 'active',
      title: 'STATUS',
      editable: true,
      align: 'left',
    },
  ];

  return (
    <div className={classes.tabContainer}>
      <Header
        add={add}
        equipmentTypes={equipmentTypes}
        update={setSelectedEquipamentType}
      />
      <PF2MTable
        data={parsedHourmeterNames}
        columns={columns}
        updateItem={handleUpdateItem}
      />
      <Footer
        isDirty={isHourmeterNamesDirty}
        discard={() => reset()}
        sendData={() => update(unparseHourmeterNames(parsedHourmeterNames))}
      />
    </div>
  );
};

HourmeterManagerTab.propTypes = {
  classes: PropTypes.object.isRequired,

  hourmeterNames: PropTypes.array,
  equipmentTypes: PropTypes.array,

  getEquipmentTypes: PropTypes.func,
  getHourmeterNames: PropTypes.func,


  setHourmeterNamesValue: PropTypes.func,
  updateHourmeterNames: PropTypes.func,
  addHourmeterNames: PropTypes.func,
  isHourmeterNamesDirty: PropTypes.bool,
};

HourmeterManagerTab.defaultProps = {

  hourmeterNames: [],
  equipmentTypes: [],

  getEquipmentTypes: () => {},
  getHourmeterNames: () => {},

  setHourmeterNamesValue: () => {},
  updateHourmeterNames: () => {},
  addHourmeterNames: () => {},
  isHourmeterNamesDirty: false,
};

const mapStateToProps = state => ({
  equipmentTypes: state.manager.equipmentTypes,
  hourmeterNames: state.manager.hourmeterNames,
  isHourmeterNamesDirty: state.manager.isHourmeterNamesDirty,
});

const mapDispatchToProps = {
  setHourmeterNamesValue,
  updateHourmeterNames,
  addHourmeterNames,
  resetHourmeterNames,

  getEquipmentTypes,
  getHourmeterNames,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(HourmeterManagerTab));

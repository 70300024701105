import * as types from './types';
import socketBuilder from '~/services/socketioService';
import { getTenant } from '~/services/multiTenant';

export const closeSocketConnection = (dispatch) => {
  dispatch({
    type: types.CLOSE_SOCKET_CONNECTION,
    payload: true,
  });
};

export const newMessage = (newMessageText, type = 'sended') => (dispatch) => {
  dispatch({
    type: types.RECEIVE_NEW_MESSAGE,
    payload: { ...newMessageText, type },
  });
};

export const setConnectionStatus = connections => (dispatch) => {
  dispatch({
    type: types.SET_CONNECTION_STATUS,
    payload: connections,
  });
};

export const readChat = room => (dispatch) => {
  dispatch({
    type: types.READ_CHAT,
    payload: room,
  });
};

export const setSocketConnection = () => (dispatch, getState) => {
  const { token } = getState().auth.user || {};
  if (!token) return;
  const tenant = getTenant();
  const socket = socketBuilder(token, `/${tenant}/chat`);

  if (!socket) return;

  socket.on('connect', () => {
    console.log(true, 'connected');
  });
  socket.on('disconnect', () => {
    console.log(false, 'disconnected');
  });
  socket.on('platform_message', (body) => {
    dispatch(newMessage(body, 'received'));
  });
  socket.on('connected_equipments', (body) => {
    dispatch(setConnectionStatus(body));
  });

  dispatch({
    type: types.SET_SOCKET_CONNECTION,
    payload: socket,
  });
};

import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import LogoutTab from './LogoutTab';
import Main from '../Main';
import { ZendeskAPI } from 'react-zendesk';
// import { useTranslation } from 'react-i18next';
// import PF2MTabs from '~/components/PF2MTabs';

// const [selectedTab, setSelectedTab] = useState(0);
// const { t: translate } = useTranslation();
// const tabs = [
//   { key: 0, label: translate('common:MaintenanceLogoutLimit') },
// ];
// const handleChange = (event, value) => {
//   setSelectedTab(value);
// };
/* <div className={classes.container}>
    <PF2MTabs
      value={selectedTab}
      onChange={handleChange}
      indicatorColor="primary"
      textColor="primary"
    >
      {tabs}
    </PF2MTabs>
    {selectedTab === 0 ? (<LogoutTab />) : null}
  </div> */
  const Configuration = () => {

    useEffect(() => {
      ZendeskAPI('webWidget', 'show');
      return () => {
        ZendeskAPI('webWidget', 'hide');
      };
    }, []);
  
    return (
      <Main>
        <LogoutTab />
      </Main>
    );
  };
  export default withStyles(styles)(Configuration);

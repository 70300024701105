/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import {
  withStyles, FormControl, FormLabel, FormGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getSubElements, getElements } from '~/store/manager/actions';
import { getPriorities, restorePriorities, updatePriorities } from '~/store/lineup/actions';
import styles from '../styles';
import PriorityQueue from './PriorityQueue';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import Footer from '~/pages/Manager/Footer';
import PF2MScrollbar from '~/components/PF2MScrollbar';


const ResourcesTab = ({
  classes,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [selectedElement, setSelectedElement] = useState(0);
  const subElements = useSelector(state => state.manager.subElements);
  const elements = useSelector(state => state.manager.elements);
  const selectedLineup = useSelector(state => state.lineup.selectedLineup);
  const priorities = useSelector(state => state.lineup.priorities);
  const isDirty = useSelector(state => state.lineup.isPrioritiesDirty);
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  useEffect(() => {
    const loadData = async () => {
      dispatch(getElements());
    };
    if (!dataLoaded) {
      loadData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch]);

  useEffect(() => {
    const loadData = async () => {
      dispatch(getSubElements(false, { id_element: selectedElement }));
      dispatch(getPriorities(selectedLineup.id, selectedElement));
    };
    if (!dataLoaded && selectedElement !== 0) {
      loadData();
      setDataLoaded(true);
    }
  }, [selectedElement, priorities, dataLoaded, dispatch, selectedLineup]);

  const priorities_low = priorities.filter(priority => priority.priority === 3)
    .map(e => e.element_point_id);
  const priorities_mid = priorities.filter(priority => priority.priority === 2)
    .map(e => e.element_point_id);
  const priorities_high = priorities.filter(priority => priority.priority === 1)
    .map(e => e.element_point_id);

  const renderFilters = () => (

    <div style={{
      display: 'grid',
      gridTemplateColumns: 'repeat(5, 200px)',
      gridColumnGap: 10,
      gridRowGap: 10,
      margin: '10px 0',
    }}
    >
      <FormControl>
        <FormLabel className={classes.formLabel}>
          {translate('common:Element')}
        </FormLabel>
        <FormGroup>
          <PF2MSearchSelectOutlined
            value={selectedElement}
            onChange={(e) => {
              setSelectedElement(e.target.value);
              setDataLoaded(false);
            }}
            className={classes.fieldSelect}
          >
            {[{ value: 0, label: translate('common:Select') }]
              .concat(...elements
                .map(e => ({ value: e.id, label: e.name })))}

          </PF2MSearchSelectOutlined>

        </FormGroup>

      </FormControl>

    </div>

  );

  return (
    <div className={classes.tabContainer}>
      <div className={classes.tab}>
        <div>
          {renderFilters()}
        </div>
        <PF2MScrollbar className={classes.scrollbars}>
          {selectedElement !== 0
        && subElements.filter(e => (!priorities_high.includes(e.id)
                && !priorities_mid.includes(e.id)
                && !priorities_low.includes(e.id))).length > 0 && (
                  <div>
                    <h3>Sem prioridade definida</h3>
                    <PriorityQueue
                      subElementsList={
                        subElements.filter(e => (!priorities_high.includes(e.id)
                        && !priorities_mid.includes(e.id)
                        && !priorities_low.includes(e.id)))
                        }
                      filters={{ element_id: selectedElement, lineup_id: selectedLineup.id }}
                      dispatch={dispatch}
                    />
                  </div>
          )}
          {priorities_high.length > 0 && (
            <div>
              <h3>Alta Prioridade</h3>
              <PriorityQueue
                subElementsList={subElements.filter(e => (priorities_high.includes(e.id)))}
                dispatch={dispatch}
              />
            </div>
          )}
          {priorities_mid.length > 0 && (
            <div>
              <h3>Média Prioridade</h3>
              <PriorityQueue
                subElementsList={subElements.filter(e => (priorities_mid.includes(e.id)))}
                dispatch={dispatch}
              />
            </div>
          )}
          {priorities_low.length > 0 && (
            <div>
              <h3>Baixa Prioridade</h3>
              <PriorityQueue
                subElementsList={subElements.filter(e => (priorities_low.includes(e.id)))}
                dispatch={dispatch}
              />
            </div>
          )}
        </PF2MScrollbar>
        <Footer
          isDirty={isDirty}
          sendData={() => {
            dispatch(
              updatePriorities(
                priorities, { lineup_id: selectedLineup.id, element_id: selectedElement },
              ),
            );
          }}
          discard={() => {
            dispatch(restorePriorities());
          }}
        />
      </div>
    </div>
  );
};

ResourcesTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

ResourcesTab.defaultProps = {
};

export default withStyles(styles)(ResourcesTab);

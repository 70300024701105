/* eslint max-len:0 */
import React, { useState, useEffect, useCallback } from 'react';
import {
  withStyles,
  Checkbox,
  ListItemText,
  FormControl,
  FormLabel,
  FormGroup,
} from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import PF2MButton from '~/components/PF2MButton';
import Footer from '~/pages/Manager/Footer';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import {
  getElements,
  setSubElementValue,
  getSubElements,
  updateSubElements,
  resetSubElements,
} from '~/store/manager/actions';
import {
  getProjectGroups,
  getProjectSubElements,
} from '~/store/underground/actions';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import PF2MScrollbar from '~/components/PF2MScrollbar';
import PF2MCheckboxSelect from '~/components/PF2MCheckboxSelect';

const ProjectsStatusTab = ({ classes }) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [projectGroup, setProjectGroup] = useState(0);
  const [filteredSubElements, setFilteredSubElements] = useState([]);
  const [element, setElement] = useState(0);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [nameFilter, setNameFilter] = useState('');
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const projectSubElements = useSelector(state => state.underground.projectSubElements);
  const isSubElementsDirty = useSelector(state => state.manager.isSubElementsDirty);
  const projectGroups = useSelector(state => state.underground.projectGroups);
  const elements = useSelector(state => state.manager.elements);
  const subElements = useSelector(state => state.manager.subElements);

  useEffect(() => {
    async function fetchData() {
      dispatch(getProjectSubElements());
      dispatch(getProjectGroups());
      dispatch(getElements());
      dispatch(getSubElements());
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch]);

  useEffect(() => {
    const newSubElements = subElements
      .filter(e => e.active)
      .filter(e => element === 0 || e.id_element === element)
      .filter(e => projectGroup === 0 || projectSubElements.some(k => e.id === k.id_element_point
        && k.id_project_group === projectGroup && e.sub_element_type_id !== null))
      .filter(e => elements.some(k => e.id_element === k.id))
      .filter(e => e.name.toLowerCase().includes(nameFilter.toLowerCase().trim()));
    setFilteredSubElements(newSubElements);
  }, [subElements, setFilteredSubElements, projectSubElements, projectGroup, element, elements, nameFilter]);

  const renderSubElements = useCallback(() => filteredSubElements
    .filter(e => e.active && e.sub_element_type_id !== null)
    .map(s => (
      <div key={s.id} style={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox
          checked={s.status}
          color="primary"
          name={s.id.toString()}
          onChange={e => dispatch(setSubElementValue(s, 'status', e.target.checked))}
        />
        <ListItemText secondary={s.name} style={{ padding: 0 }} />
      </div>
    )), [filteredSubElements, dispatch]);

  const handleSelectAll = () => {
    filteredSubElements.forEach((s) => {
      dispatch(setSubElementValue(s, 'status', true));
    });
  };

  const handleChangeProjectGroup = (e) => {
    const selected = projectSubElements.find(m => m.id_project_group === projectGroup);
    if (e.target.value !== projectGroup && selected && selected.isDirty) {
      setIsAlertOpen(true);
    } else {
      setProjectGroup(e.target.value);
    }
  };

  const handleChangeElement = (e) => {
    const selected = projectSubElements.find(m => m.id_element === element);
    if (e.target.value !== element && selected && selected.isDirty) {
      setIsAlertOpen(true);
    } else {
      setElement(e.target.value);
    }
  };

  const filterElementCheckBox = (checkeds, item) => {
    if (!item.active) {
      return false;
    }
    if (!checkeds.is_origin && !checkeds.is_destination) {
      return true;
    }
    if (item.is_origin === checkeds.is_origin && checkeds.is_origin && !item.is_destination) {
      return true;
    }
    if (item.is_destination === checkeds.is_destination
        && checkeds.is_destination && !item.is_origin) {
      return true;
    }
    return false;
  };

  const renderHeader = () => (
    <Formik
      initialValues={{ name: '', projectGroup: 0, element: 0 }}
      validate={() => {
        const errors = {};
        return errors;
      }}
      onSubmit={() => { }}
    >
      {({ errors }) => (
        <Form style={{ display: 'flex', alignItems: 'center' }}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              {`${translate('common:FilterByProjectGroup')}:`}
            </FormLabel>
            <FormGroup>
              <Field
                name="projectGroup"
                render={({ field, form }) => (
                  <PF2MSearchSelectOutlined
                    {...field}
                    value={projectGroup}
                    placeholder={null}
                    onChange={handleChangeProjectGroup}
                    className={classnames(form.errors[field.name] ? classes.fieldError : classes.field)}
                  >
                    {[{ value: 0, label: translate('common:Select') }]
                      .concat(...projectGroups
                        .map(e => ({ value: e.id, label: e.name })))}
                  </PF2MSearchSelectOutlined>
                )}
              />
            </FormGroup>
          </FormControl>
          <FormControl style={{ marginLeft: 10 }}>
            <FormLabel className={classes.formLabel}>
              {`${translate('common:FilterByElement')}:`}
            </FormLabel>
            <FormGroup>
              <PF2MCheckboxSelect
                inputLabel={translate('common:Element')}
                options={elements}
                onChange={handleChangeElement}
                checkboxs={
                  [
                    { field: 'is_origin', label: translate('common:Origins') },
                    { field: 'is_destination', label: translate('common:Destinations') },
                  ]
                }
                filter={filterElementCheckBox}
                map={e => ({ value: e.id, label: e.name })}
                selectedValue={element}
                initialState={{ is_origin: false, is_destination: false }}
                headOptions={[{ value: 0, label: translate('common:All') }]}
              />
            </FormGroup>
          </FormControl>
          <FormControl className={classes.classGroupName}>
            <FormLabel className={classes.formLabel}>
              {`${translate('common:FilterByName')}:`}
            </FormLabel>
            <FormGroup>
              <Field
                type="text"
                name="name"
                value={nameFilter}
                onChange={e => setNameFilter(e.target.value)}
                className={classnames(errors.name ? classes.fieldError : classes.field)}
              />
            </FormGroup>
          </FormControl>
        </Form>
      )}
    </Formik>
  );

  return (
    <div className={classes.tabContainer}>
      {renderHeader()}

      <div>
        <PF2MButton
          color="primary"
          onClick={() => handleSelectAll()}
        >
          {translate('common:SelectAll')}
        </PF2MButton>
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '100%' }}>
          <span className={classes.label}>
            {translate('common:SubElements')}
            :
          </span>
          <PF2MScrollbar className={classes.scrollbars} style={{ height: 'auto' }}>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
              {renderSubElements()}
            </div>
          </PF2MScrollbar>
        </div>
      </div>
      <div style={{ marginTop: 20 }}>
        <Footer
          isDirty={isSubElementsDirty}
          discard={() => dispatch(resetSubElements())}
          sendData={() => dispatch(updateSubElements(subElements))}
          style={{ marginTop: 10 }}
        />
      </div>
      <PF2MAlertDialog
        hasCancel={false}
        confirmText={translate('common:Understood')}
        description={translate('validation:YouHaveUnsavedData')}
        open={isAlertOpen}
        onConfirm={() => setIsAlertOpen(false)}
      />
    </div>
  );
};

ProjectsStatusTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

ProjectsStatusTab.defaultProps = {};

export default withStyles(styles)(ProjectsStatusTab);

import React from 'react';
import {
  withStyles,
  FormControl,
  FormLabel,
  FormGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import classnames from 'classnames';
import { KeyboardDatePicker } from '@material-ui/pickers';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import { months } from '~/utils';
import PF2MAddButton from '~/components/PF2MAddButton';
import PF2MCheckboxSelect from '~/components/PF2MCheckboxSelect';

const INITIAL_VALUES = {
  year: new Date(),
  month: new Date().getMonth() + 1,
  projectGroup: 0,
  element: 0,
  subElement: 0,
  advance: '',
};

function Header({
  classes,
  add,
  projectGroups,
  elements,
  subElements,
  handleProjectGroup,
  handleElement,
  monthlyAdvances,
  setFilters,
}) {
  const { t: translate } = useTranslation();

  const updateFilters = (values, field, input) => {
    const filters = {
      year: values.year,
      month: values.month,
      projectGroup: values.projectGroup,
      element: values.element,
      subElement: values.subElement,
    };
    filters[field] = input;
    filters.year = filters.year.year
      ? filters.year.year()
      : filters.year.getFullYear();

    if (field === 'projectGroup') {
      filters.element = 0;
      filters.subElement = 0;
    }
    if (field === 'element') filters.subElement = 0;

    setFilters(filters);
  };

  const filterElementCheckBox = (checkeds, item) => {
    if (!item.active) {
      return false;
    }
    if (!checkeds.is_origin && !checkeds.is_destination) {
      return true;
    }
    if (item.is_origin === checkeds.is_origin && checkeds.is_origin && !item.is_destination) {
      return true;
    }
    if (item.is_destination === checkeds.is_destination
        && checkeds.is_destination && !item.is_origin) {
      return true;
    }
    return false;
  };

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      validate={({
        month,
        element,
        subElement,
        advance,
      }) => {
        const errors = {};

        if (month === 0) {
          errors.month = 'Required';
        }

        if (element <= 0) {
          errors.element = 'Required';
        }

        if (subElement === 0) {
          errors.subElement = 'Required';
        }

        if (month > 0
          && monthlyAdvances.some(m => m.month === month && m.id_element_point === subElement)) {
          errors.month = 'Required';
        }

        if (!advance) {
          errors.advance = 'Required';
        }
        return errors;
      }}
      onSubmit={async (values, { resetForm }) => {
        const projectSubElement = subElements.find(p => p.id === values.subElement);
        const selectedElement = elements.find(p => p.id === values.element);
        const newData = {
          year: values.year.getFullYear(),
          month: values.month,
          advance: values.advance,
          id_element_point: projectSubElement.id,
          element_point_name: projectSubElement.name,
          id_element: selectedElement.id,
          element_name: selectedElement.name,
        };

        let selectedProjectGroup = null;
        if (values.projectGroup) {
          selectedProjectGroup = projectGroups.find(p => p.id === values.projectGroup);
          newData.id_project_group = selectedProjectGroup.id;
          newData.project_group_name = selectedProjectGroup.name;
        }
        add(newData);
        resetForm({ ...values, advance: 0 });
      }}
    >
      {({
        isSubmitting,
        isValid,
        errors,
        setFieldValue,
        values,
      }) => (
        <Form>
          <div style={{ display: 'flex', alignItems: 'baseline', marginBottom: 5 }}>
            <FormControl>
              <FormLabel className={classes.formLabel}>
                {`${translate('common:Year')}:`}
              </FormLabel>
              <FormGroup style={{ marginRight: 10 }}>
                <Field
                  name="year"
                  render={({ field, form }) => (
                    <KeyboardDatePicker
                      {...field}
                      onChange={(date) => {
                        form.setFieldValue(field.name, date, true);
                        updateFilters(form.values, 'year', date);
                      }}
                      views={['year']}
                      autoOk
                      inputVariant="outlined"
                      invalidDateMessage=""
                      minDateMessage=""
                      minDate={new Date()}
                      disablePast
                      InputProps={{
                        style: {
                          height: 40,
                          width: '150px',
                          marginRight: 5,
                        },
                      }}
                      cancelLabel={translate('common:Cancel')}
                      okLabel={translate('common:Confirm')}
                    />
                  )}
                />
              </FormGroup>
            </FormControl>
            <FormControl>
              <FormLabel className={classes.formLabel}>
                {`${translate('common:Month')}:`}
              </FormLabel>
              <FormGroup style={{ marginRight: 10 }}>
                <Field
                  name="month"
                  render={({ field, form }) => (
                    <PF2MSearchSelectOutlined
                      {...field}
                      placeholder={null}
                      onChange={(e) => {
                        form.setFieldValue(field.name, e.target.value, true);
                        updateFilters(form.values, 'month', e.target.value);
                      }}
                      className={classnames(errors[field.name] ? classes.fieldError : '')}
                    >
                      {[{ value: 0, label: translate('common:Select') }]
                        .concat(...months.map(e => (
                          { value: e.id, label: e.name }
                        )))}
                    </PF2MSearchSelectOutlined>
                  )}
                />
              </FormGroup>
            </FormControl>
            <FormControl>
              <FormLabel className={classes.formLabel}>
                {`${translate('common:ProjectGroup')}:`}
              </FormLabel>
              <FormGroup style={{ marginRight: 10 }}>
                <Field
                  name="projectGroup"
                  render={({ field, form }) => (
                    <PF2MSearchSelectOutlined
                      {...field}
                      placeholder={null}
                      className={classnames(errors[field.name] ? classes.fieldError : '')}
                      onChange={async (e) => {
                        setFieldValue('projectGroup', e.target.value, true);
                        setFieldValue('element', 0, true);
                        setFieldValue('subElement', 0, true);
                        if (handleProjectGroup) handleProjectGroup(e.target.value);
                        updateFilters(form.values, 'projectGroup', e.target.value);
                      }}
                    >
                      {[{ value: 0, label: translate('common:Select') }]
                        .concat(...projectGroups
                          .map(e => ({ value: e.id, label: e.name })))}
                    </PF2MSearchSelectOutlined>
                  )}
                />
              </FormGroup>
            </FormControl>
            <FormControl>
              <Field
                name="element"
                render={({ form }) => (
                  <PF2MCheckboxSelect
                    inputLabel={translate('common:Element')}
                    options={elements}
                    onChange={async (e) => {
                      setFieldValue('element', e.target.value, true);
                      setFieldValue('subElement', 0, true);
                      if (handleElement) handleElement(e.target.value);
                      updateFilters(form.values, 'element', e.target.value);
                    }}
                    checkboxs={
                      [
                        { field: 'is_origin', label: translate('common:Origins') },
                        { field: 'is_destination', label: translate('common:Destinations') },
                      ]
                    }
                    filter={filterElementCheckBox}
                    map={e => ({ value: e.id, label: e.name })}
                    selectedValue={values.element}
                    initialState={{ is_origin: false, is_destination: false }}
                    headOptions={[{ value: 0, label: translate('common:All') }]}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <FormLabel className={classes.formLabel}>
                {translate('common:SubElement')}
              </FormLabel>
              <FormGroup style={{ marginRight: 10 }}>
                <Field
                  name="subElement"
                  render={({ field, form }) => (
                    <PF2MSearchSelectOutlined
                      {...field}
                      placeholder={null}
                      className={classnames(errors[field.name] ? classes.fieldError : '')}
                      onChange={async e => form.setFieldValue('subElement', e.target.value)}
                    >
                      {[{ value: 0, label: translate('common:Select') }]
                        .concat(...subElements
                          .filter(sub => !values.element || values.element === sub.id_element)
                          .map(e => ({ value: e.id, label: e.name })))}
                    </PF2MSearchSelectOutlined>
                  )}
                />
              </FormGroup>
            </FormControl>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
            <FormControl className={classes.classGroupName}>
              <FormLabel className={classes.formLabel}>
                {translate('common:MonthlyAdvanceMeters')}
              </FormLabel>
              <FormGroup style={{ marginRight: 10 }}>
                <Field
                  name="advance"
                  render={({ field }) => (
                    <NumberFormat
                      {...field}
                      className={classnames(
                        'form-control', errors.advance ? classes.fieldError : '',
                      )}
                      decimalSeparator=","
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  )}
                />
              </FormGroup>
            </FormControl>
            <PF2MAddButton
              disabled={isSubmitting || !isValid}
              style={{ marginTop: 20 }}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  add: PropTypes.func,
  projectGroups: PropTypes.array,
  elements: PropTypes.array,
  subElements: PropTypes.array,
  handleProjectGroup: PropTypes.func,
  handleElement: PropTypes.func,
  handleProjectSubElement: PropTypes.func,
  monthlyAdvances: PropTypes.array,
  setFilters: PropTypes.func,
};

Header.defaultProps = {
  add: () => { },
  projectGroups: [],
  elements: [],
  subElements: [],
  handleProjectGroup: null,
  handleElement: null,
  handleProjectSubElement: null,
  monthlyAdvances: [],
  setFilters: () => { },
};


export default withStyles(styles)(Header);

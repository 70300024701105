import React, { useState } from 'react';
import {
  withStyles,
  FormControl,
  FormLabel,
  FormGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import classnames from 'classnames';
import { KeyboardDatePicker } from '@material-ui/pickers';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import styles from '~/pages/Loads/styles';
import { formatDateToServerFormat, parseDateToServerFormat, newMomentDate } from '~/utils/moment';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';

import PF2MAddButton from '~/components/PF2MAddButton';

const INITIAL_VALUES = {
  startDate: newMomentDate(),
  endDate: newMomentDate(),
  material: 0,
  element: 0,
  subElement: 0,
  loadFactor: '',
};

function Header({
  classes,
  add,
  materials,
  elements,
  subElements,
  loadFactors,
}) {
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const { t: translate } = useTranslation();

  return (
    <>
      <Formik
        initialValues={INITIAL_VALUES}
        validate={({
          startDate,
          endDate,
          subElement,
          material,
          value,
        }) => {
          const errors = {};

          if (!startDate) {
            errors.startDate = 'Required';
          }

          if (!endDate) {
            errors.endDate = 'Required';
          }

          if (subElement === 0) {
            errors.subElement = 'Required';
          }

          if (material <= 0) {
            errors.material = 'Required';
          }

          if (startDate.isAfter(endDate)) {
            errors.startDate = 'Invalid date';
          }

          if (!value) {
            errors.value = 'Required';
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          const { startDate, endDate } = values;
          const factorsWithSameSubElementAndMaterial = loadFactors
            .filter(r => r.material_id === values.material
              && r.element_point_id === values.subElement);

          const hasDuplicates = factorsWithSameSubElementAndMaterial.some((possibleDuplicate) => {
            const parsedStartDate = parseDateToServerFormat(possibleDuplicate.start_date);
            const parsedEndDate = parseDateToServerFormat(possibleDuplicate.end_date);
            return (parsedStartDate.isBetween(startDate, endDate, 'day', '[]')
              || parsedEndDate.isBetween(startDate, endDate, 'day', '[]'));
          });

          if (hasDuplicates) {
            setIsAlertOpen(true);
            setSubmitting(false);
            return;
          }

          const material = materials.find(p => p.id === values.material);
          const subElement = subElements.find(p => p.id === values.subElement);
          const newData = {
            start_date: formatDateToServerFormat(values.startDate),
            end_date: formatDateToServerFormat(values.endDate),
            value: values.value,
            material_id: material.id,
            material_name: material.name,
            element_id: subElement.id_element,
            element_name: subElement.element_name,
            element_point_id: subElement.id,
            element_point_name: subElement.name,
          };
          add(newData);
          setSubmitting(false);
        }}
      >
        {({
          isSubmitting,
          isValid,
          errors,
        }) => (
          <Form>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 5,
            }}
            >
              <div>
                <FormControl>
                  <FormLabel className={classes.formLabel}>
                    {`${translate('common:StartDate')}:`}
                  </FormLabel>
                  <FormGroup>
                    <Field
                      name="startDate"
                      render={({ field, form }) => (
                        <KeyboardDatePicker
                          {...field}
                          onChange={(date) => {
                            form.setFieldValue(field.name, date, true);
                            if (form.values.endDate.isBefore(date, 'day')) {
                              form.setFieldValue('endDate', date);
                            }
                          }}
                          autoOk
                          format={translate('date:DateFormat')}
                          inputVariant="outlined"
                          invalidDateMessage=""
                          minDateMessage=""
                          InputProps={{
                            style: {
                              height: 40,
                              width: '150px',
                              marginRight: 5,
                            },
                          }}
                          cancelLabel={translate('common:Cancel')}
                          okLabel={translate('common:Confirm')}
                        />
                      )}
                    />
                  </FormGroup>
                </FormControl>
                <FormControl>
                  <FormLabel className={classes.formLabel}>
                    {`${translate('common:EndDate')}:`}
                  </FormLabel>
                  <FormGroup>
                    <Field
                      name="endDate"
                      render={({ field, form }) => (
                        <KeyboardDatePicker
                          {...field}
                          onChange={date => form.setFieldValue(field.name, date, true)}
                          autoOk
                          format={translate('date:DateFormat')}
                          inputVariant="outlined"
                          invalidDateMessage=""
                          minDateMessage=""
                          minDate={form.values.startDate}
                          InputProps={{
                            style: {
                              height: 40,
                              width: '150px',
                              marginRight: 5,
                            },
                          }}
                          cancelLabel={translate('common:Cancel')}
                          okLabel={translate('common:Confirm')}
                        />
                      )}
                    />
                  </FormGroup>
                </FormControl>
                <FormControl style={{ marginLeft: 5 }}>
                  <FormLabel className={classes.formLabel}>
                    {`${translate('common:Element')}:`}
                  </FormLabel>
                  <FormGroup>
                    <Field
                      name="element"
                      render={({ field, form }) => (
                        <PF2MSearchSelectOutlined
                          {...field}
                          placeholder={null}
                          onChange={e => form.setFieldValue('element', e.target.value)}
                        >
                          {[{ value: 0, label: translate('common:Select') }]
                            .concat(...elements
                              .map(e => ({ label: e.name, value: e.id })))}
                        </PF2MSearchSelectOutlined>
                      )}
                    />
                  </FormGroup>
                </FormControl>
                <FormControl style={{ marginLeft: 5 }}>
                  <FormLabel className={classes.formLabel}>
                    {`${translate('common:SubElement')}:`}
                  </FormLabel>
                  <FormGroup>
                    <Field
                      name="subElement"
                      render={({ field, form }) => (
                        <PF2MSearchSelectOutlined
                          {...field}
                          placeholder={null}
                          className={classnames(errors[field.name] ? classes.fieldError : '')}
                          onChange={e => form.setFieldValue('subElement', e.target.value)}
                        >
                          {[{ value: 0, label: translate('common:Select') }]
                            .concat(...subElements
                              .filter(e => (
                                form.values.element === 0 || e.id_element === form.values.element
                              ))
                              .map(e => ({ label: e.name, value: e.id })))}
                        </PF2MSearchSelectOutlined>
                      )}
                    />
                  </FormGroup>
                </FormControl>
                <FormControl style={{ marginLeft: 5 }}>
                  <FormLabel className={classes.formLabel}>
                    {`${translate('common:Material')}:`}
                  </FormLabel>
                  <FormGroup>
                    <Field
                      name="material"
                      render={({ field, form }) => (
                        <PF2MSearchSelectOutlined
                          {...field}
                          placeholder={null}
                          className={classnames(errors[field.name] ? classes.fieldError : '')}
                          onChange={e => form.setFieldValue('material', e.target.value)}
                        >
                          {[{ value: 0, label: translate('common:Select') }]
                            .concat(...materials
                              .map(e => ({ label: e.name, value: e.id })))}
                        </PF2MSearchSelectOutlined>
                      )}
                    />
                  </FormGroup>
                </FormControl>
                <FormControl className={classes.classGroupName} style={{ marginLeft: 5 }}>
                  <FormLabel className={classes.formLabel}>
                    {translate('common:LoadFactor')}
                  </FormLabel>
                  <FormGroup>
                    <Field
                      name="value"
                      render={({ field }) => (
                        <NumberFormat
                          {...field}
                          className={classnames('form-control', errors.value ? classes.fieldError : '')}
                          decimalSeparator=","
                          decimalScale={6}
                          fixedDecimalScale
                          style={{ height: 40, width: '100%' }}
                        />
                      )}
                    />
                  </FormGroup>
                </FormControl>
              </div>
              <div>
                <PF2MAddButton
                  disabled={isSubmitting || !isValid}
                  style={{ marginTop: 20 }}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <PF2MAlertDialog
        hasCancel={false}
        confirmText={translate('common:Understood')}
        description={translate('validation:DateIntervalAlreadyRegistered')}
        open={isAlertOpen}
        onConfirm={() => setIsAlertOpen(false)}
      />
    </>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  add: PropTypes.func,
  materials: PropTypes.array,
  loadFactors: PropTypes.array,
  elements: PropTypes.array,
  subElements: PropTypes.array,
};

Header.defaultProps = {
  add: () => { },
  materials: [],
  loadFactors: [],
  elements: [],
  subElements: [],
};


export default withStyles(styles)(Header);

import React from 'react';
import {
  withStyles,
  FormControl,
  FormLabel,
  FormGroup,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CleanFilterIcon from '~/assets/clean_filter.svg';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import PF2MButton from '~/components/PF2MButton';
import { newMomentDate } from '~/utils/moment';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import Checkbox from '@material-ui/core/Checkbox';
import {
  restoreAnomalyLogsFilter, setCurrentAnomalyLogsFilter,
} from '~/store/dispatch/actions';

const AnomalyLogsFilter = ({ classes }) => {
  const equipmentTypes = useSelector(state => state.manager.equipmentTypes);
  const equipmentGroups = useSelector(state => state.manager.equipmentsGroups);
  const equipments = useSelector(state => state.manager.equipments);
  const anomalyTypes = useSelector(state => state.dispatch.anomalyTypes);
  const currentFilter = useSelector(state => state.dispatch.currentAnomalyLogsFilter);
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const renderSelectAll = () => (
    <div style={{ display: 'flex', alignItems: 'center', paddingTop: '20px' }}>
      <Checkbox
        checked={currentFilter.status}
        color="primary"
        name="all"
        onChange={() => {
          const newFilter = { ...currentFilter, status: !currentFilter.status };
          dispatch(setCurrentAnomalyLogsFilter(newFilter));
        }}
      />
      <FormLabel style={{ padding: 0, margin: 0 }}>
        {translate('common:HideDeletedEvents')}
      </FormLabel>
    </div>
  );

  const renderDatePicker = (filter, key, label) => (
    <FormControl style={{ marginRight: '10px', width: 200 }}>
      <FormLabel className={classes.formLabel}>
        {label}
      </FormLabel>
      <FormGroup>
        <KeyboardDatePicker
          clearable
          inputVariant="outlined"
          InputProps={{
            style: {
              height: 40,
              width: 200,
              color: '#647886',
            },
          }}
          format={translate('date:DateFormat')}
          autoOk
          maxDate={
            key === 'startDate'
              ? currentFilter.endDate
              : newMomentDate().add(30, 'days')
          }
          minDate={
            key === 'endDate'
              ? currentFilter.startDate
              : newMomentDate().subtract(30, 'days')
          }
          onChange={(e) => {
            const newFilter = {
              ...currentFilter,
              [key]: e,
            };
            dispatch(setCurrentAnomalyLogsFilter(newFilter));
          }}
          value={filter[key]}
          invalidDateMessage={translate('validation:InvalidDate')}
        />
      </FormGroup>
    </FormControl>
  );

  const renderFormControl = (
    item,
    key,
    title,
    collection,
    initialValue = 0,
  ) => (
    <FormControl style={{ marginRight: '10px', width: 200 }}>
      <FormLabel className={classes.formLabel}>
        {title}
      </FormLabel>
      <FormGroup>
        <PF2MSearchSelectOutlined
          placeholder={null}
          className={classnames(classes.field)}
          value={item[key] || initialValue}
          onChange={(e) => {
            const newFilter = {
              ...currentFilter,
              [key]: e.target.value,
            };
            if (key === 'equipType') {
              newFilter.equipGroup = 0;
              newFilter.equip = 0;
            } else if (key === 'equipGroup') {
              newFilter.equip = 0;
            }
            dispatch(setCurrentAnomalyLogsFilter(newFilter));
          }}
        >
          {[{ value: initialValue, label: translate('common:All') }]
            .concat(collection
              .map(e => ({ value: e.id, label: e.name || '0' })))}
        </PF2MSearchSelectOutlined>
      </FormGroup>
    </FormControl>
  );

  return (
    <>
      <div style={{ display: 'flex' }}>
        {renderDatePicker(currentFilter, 'date', translate('common:Date'))}
      </div>
      <div style={{ display: 'flex', marginTop: 10 }}>
        {renderFormControl(currentFilter, 'equipType', translate('common:EquipmentType'), equipmentTypes)}
        {renderFormControl(currentFilter, 'equipGroup', translate('common:EquipmentGroup'), equipmentGroups
          .filter(c => c.id_equipament === currentFilter.equipType))}
        {renderFormControl(currentFilter, 'equip', translate('common:Equipment'), equipments
          .filter(c => c.id_group === currentFilter.equipGroup))}
        {renderFormControl(currentFilter, 'anomalyType', translate('common:AnomalyType'), anomalyTypes)}
      </div>
      <div style={{ display: 'flex', marginTop: 10 }}>
        <FormControl style={{ width: 200 }}>
          <FormGroup>
            {renderSelectAll()}
          </FormGroup>
        </FormControl>
        <FormControl style={{ width: 200 }}>
          <FormGroup>
            <PF2MButton
              color="primary"
              onClick={() => dispatch(restoreAnomalyLogsFilter())}
              style={{ marginTop: 15 }}
            >
              <img src={CleanFilterIcon} className={classes.icon} alt="" />
              {translate('common:ClearFilters')}
            </PF2MButton>
          </FormGroup>
        </FormControl>
      </div>
    </>
  );
};

AnomalyLogsFilter.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AnomalyLogsFilter);

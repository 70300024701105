/* eslint-disable no-param-reassign */
import * as types from './types';
import api from '~/services/api';
import { clearFileUploadError } from '../errors/actions';
import { getTurnsGroups } from '../manager/actions';
import {
  exportData, importData, getTemplate, handleErrors,
} from '../common';
import { parseFloat, downloadAxios } from '~/utils';
import {
  SET_UPLOAD_SUCCESS,
  GET_GENERIC_ERROR,
  SET_UPLOAD_ERROR,
  SET_UPLOAD_SUCCESS_WARNING,
} from '../errors/types';
import i18n from '~/i18n';

const getEndpoints = {
  projectgroup: {
    url: '/projectgroup',
    responseObject: 'project_groups',
    typeAction: types.SET_PROJECT_GROUPS_LIST,
  },
  projectgroupsubelements: {
    url: '/projectgroupsubelements',
    responseObject: 'project_groups_element_points',
    typeAction: types.SET_PROJECT_SUBELEMENTS_LIST,
  },
  codetypesug: {
    url: '/codetypes/ug',
    responseObject: 'code_type_ug',
    typeAction: types.SET_CODE_TYPES_UG_LIST,
  },
  codegroupsug: {
    url: '/codegroups/ug',
    responseObject: 'code_group_ug',
    typeAction: types.SET_CODE_GROUPS_UG_LIST,
  },
  codesug: {
    url: '/codes/ug',
    responseObject: 'code_ug',
    typeAction: types.SET_CODES_UG_LIST,
  },
  priorities: {
    url: '/elementpointpriority',
    responseObject: 'element_point_priority',
    typeAction: types.SET_PRIORITIES_LIST,
  },
  monthlyadvance: {
    url: '/monthlyadvance',
    responseObject: 'monthly_advance',
    typeAction: types.SET_MONTHLY_ADVANCE_LIST,
  },
  unitaryoperationgroup: {
    url: '/unitaryoperationgroup',
    responseObject: 'unitary_operation_groups',
    typeAction: types.SET_UNIT_OPERATION_GROUPS_LIST,
  },
  unitaryoperation: {
    url: '/unitaryoperation',
    responseObject: 'unitary_operation',
    typeAction: types.SET_UNIT_OPERATIONS_LIST,
  },
  undergroundoperationcontrol: {
    url: '/undergroundoperationcontrol',
    responseObject: 'underground_operation_control',
    typeAction: types.SET_OPERATION_CONTROLS_LIST,
  },
  undergroundoperationcontrolfilter: {
    url: '/undergroundoperationcontrol/get_by_filter',
    responseObject: 'underground_operation_control',
    typeAction: types.SET_OPERATION_CONTROLS_LIST,
  },
  inputunit: {
    url: '/underground/inputunit',
    responseObject: 'underground_input_unit',
    typeAction: types.SET_INPUT_UNITS_LIST,
  },
  cicleadvance: {
    url: '/undergroundcicleadvance',
    responseObject: 'underground_cicle_advance',
    typeAction: types.SET_UNDERGROUND_CICLE_ADVANCE,
  },
};

export function getAll({
  domain, clearUploadError = true, all = false, filters = {},
}) {
  return async (dispatch) => {
    try {
      if (clearUploadError) {
        dispatch(clearFileUploadError());
      }
      const endpoint = getEndpoints[domain];
      const endpointUrl = `${endpoint.url}${all ? '?all' : ''}`;
      const {
        data: {
          result: { [endpoint.responseObject]: data = [] },
        },
      } = await api.get(endpointUrl, { params: filters });

      dispatch({
        type: endpoint.typeAction,
        payload: data,
      });
    } catch ({ response }) {
      dispatch(handleErrors(response));
    }
  };
}

export const updateData = (domain, data, successCallback) => async (dispatch) => {
  try {
    const items = data
      .filter(d => d.update_status)
      .map((d) => {
        const {
          isDirty, id, ...rest
        } = d;

        if (rest.update_status !== 'I') return { id, ...rest };
        return rest;
      });
    const endpoint = getEndpoints[domain];

    const body = {
      [endpoint.responseObject]: items,
    };
    const { status } = await api.post(`${endpoint.url}`, body);
    if (status === 200) {
      if (successCallback) {
        successCallback();
      } else {
        dispatch(getAll({ domain }));
      }
    }
  } catch (err) {
    dispatch(handleErrors(err.response));
  }
};

const setValues = (item, key, value, type) => (dispatch) => {
  let setType = '';
  switch (type) {
    case 'projectgroupsubelements':
      setType = types.SET_PROJECT_SUBELEMENT_VALUE;
      break;
    case 'codetypesug':
      setType = types.SET_CODE_TYPE_UG_VALUE;
      break;
    case 'codegroupsug':
      setType = types.SET_CODE_GROUP_UG_VALUE;
      break;
    case 'codesug':
      setType = types.SET_CODE_UG_VALUE;
      break;
    case 'priorities':
      setType = types.SET_PRIORITY_VALUE;
      break;
    case 'monthlyadvance':
      setType = types.SET_MONTHLY_ADVANCE_VALUE;
      break;
    case 'unitaryoperationgroup':
      setType = types.SET_UNIT_OPERATION_GROUP_VALUES;
      break;
    case 'unitaryoperation':
      setType = types.SET_UNIT_OPERATION_VALUES;
      break;
    case 'inputunit':
      setType = types.SET_INPUT_UNIT_VALUES;
      break;
    default:
      setType = types.SET_PROJECT_GROUP_VALUE;
      break;
  }
  const updateStatus = item.update_status === 'I' ? 'I' : 'U';
  dispatch({
    type: setType,
    payload: {
      ...item,
      [key]: value,
      update_status: updateStatus,
    },
  });
};

export const getProjectGroups = (all = false) => async dispatch => dispatch(getAll({ domain: 'projectgroup', all }));
export const getProjectSubElements = (all = false) => async (dispatch) => {
  dispatch(getAll({ domain: 'projectgroupsubelements', all }));
};
export const getCodeTypes = (all = false) => async dispatch => dispatch(getAll({ domain: 'codetypesug', all }));
export const getCodeGroups = (all = false) => async dispatch => dispatch(getAll({ domain: 'codegroupsug', all }));
export const getCodes = (all = false) => async dispatch => dispatch(getAll({ domain: 'codesug', all }));
export const getMonthlyAdvances = (all = false) => async dispatch => dispatch(getAll({ domain: 'monthlyadvance', all }));
export const getUnitOperationGroups = (all = false) => async dispatch => dispatch(getAll({ domain: 'unitaryoperationgroup', all }));
export const getUnitOperations = (all = false) => async dispatch => dispatch(getAll({ domain: 'unitaryoperation', all }));

export const addProjectGroup = data => async dispatch => dispatch({
  type: types.ADD_PROJECT_GROUP,
  payload: data,
});

export const getCycleAdvance = (all = false, filters) => async dispatch => (
  dispatch(getAll({
    domain: 'cicleadvance',
    all,
    filters: { id_elements: String(filters) },
  })));
export const addCycleAdvance = item => (dispatch, getState) => dispatch({
  type: types.ADD_UNDERGROUND_CICLE_ADVANCE,
  payload: {
    ...item,
    id: -getState().underground.cicleAdvances.length - 1,
    update_status: 'I',
    isDirty: item.isDirty,
  },
});
export const deleteCycleAdvance = item => ({
  type: types.DELETE_UNDERGROUND_CICLE_ADVANCE,
  payload: {
    ...item,
    update_status: 'D',
    isDirty: true,
  },
});
export const changeCycleAdvance = cycle => ({
  type: types.CHANGE_UNDERGROUND_CICLE_ADVANCE,
  payload: {
    ...cycle,
    update_status: cycle.update_status || 'U',
    isDirty: true,
  },
});
export const updateCycleAdvance = (cycles, filters, all = true) => dispatch => (
  dispatch(updateData(
    'cicleadvance',
    cycles,
    () => dispatch(getCycleAdvance(all, filters)),
  ))
);
export const restoreCycleAdvance = () => ({ type: types.RESTORE_CICLE_ADVANCE });
export const exportCycleAdvance = filters => exportData(
  'undergroundcicleadvance',
  { id_elements: filters },
);
export const importCycleAdvance = (file, filters) => (
  importData(
    'undergroundcicleadvance',
    file,
    () => getCycleAdvance(true, filters),
    filters,
  )
);

export const resetProjectGroups = () => async dispatch => dispatch({
  type: types.RESET_PROJECT_GROUPS,
});

export const setProjectGroupValue = (item, key, value) => dispatch => dispatch(setValues(item, key, value, 'projectgroup'));
export const setProjectSubElementsValues = (projectGroup, subElement, value) => (dispatch) => {
  dispatch({
    type: types.SET_PROJECT_SUBELEMENT_VALUE,
    payload: {
      projectGroup,
      subElement,
      value,
    },
  });
};
export const setCodeTypeValue = (item, key, value) => dispatch => dispatch(setValues(item, key, value, 'codetypesug'));
export const setCodeGroupValue = (item, key, value) => dispatch => dispatch(setValues(item, key, value, 'codegroupsug'));
export const setCodeValue = (item, key, value) => dispatch => dispatch(setValues(item, key, value, 'codesug'));
export const setPriorityValue = (item, key, value) => (dispatch) => {
  const numberValue = Number(value);
  dispatch(setValues(item, key, numberValue, 'priorities'));
};
export const setMonthlyAdvanceValues = (item, key, value) => dispatch => dispatch(setValues(item, key, value, 'monthlyadvance'));
export const setUnitOperationGroupValues = (item, key, value) => dispatch => dispatch(setValues(item, key, value, 'unitaryoperationgroup'));
export const setUnitOperationValue = (item, key, value) => dispatch => dispatch(setValues(item, key, value, 'unitaryoperation'));

export const updateProjectGroups = data => async dispatch => dispatch(updateData('projectgroup', data));
export const resetProjectSubElements = () => async (dispatch) => {
  dispatch({ type: types.RESET_PROJECT_SUBELEMENTS });
};
export const updateProjectSubElements = data => async (dispatch) => {
  dispatch(updateData('projectgroupsubelements', data));
};
export const addCodeType = data => async dispatch => dispatch({
  type: types.ADD_CODE_TYPE_UG,
  payload: data,
});
export const resetCodeTypes = () => async dispatch => dispatch({
  type: types.RESET_CODE_TYPES_UG,
});
export const updateCodeTypes = data => async dispatch => dispatch(updateData('codetypesug', data));
export const addCodeGroup = data => async dispatch => dispatch({
  type: types.ADD_CODE_GROUP_UG,
  payload: data,
});
export const resetCodeGroups = () => async dispatch => dispatch({
  type: types.RESET_CODE_GROUPS_UG,
});
export const updateCodeGroups = data => async dispatch => dispatch(updateData('codegroupsug', data));

export const addCode = data => async dispatch => dispatch({
  type: types.ADD_CODE_UG,
  payload: data,
});

export const resetCodes = () => async dispatch => dispatch({
  type: types.RESET_CODES_UG,
});
export const updateCodes = data => async dispatch => dispatch(updateData('codesug', data));

export const exportCodes = () => async dispatch => dispatch(exportData('codes/ug'));
export const importCodes = file => async (dispatch) => {
  await dispatch(importData('codes/ug', file));
  dispatch(getAll({ domain: 'codesug', clearUploadError: false }));
};
export const getCodesTemplate = () => async dispatch => dispatch(getTemplate('codes/ug'));

export const getPriorities = () => async dispatch => dispatch(getAll({ domain: 'priorities' }));

export const resetPriorities = () => async dispatch => dispatch({
  type: types.RESET_PRIORITIES,
});

export const updatePriorities = data => async (dispatch) => {
  const newData = data.map(item => ({
    ...item,
    update_status: item.id > 0 ? 'U' : 'I',
  }));
  dispatch(updateData('priorities', newData));
};

export const resetMonthlyAdvances = () => async (dispatch) => {
  dispatch({ type: types.RESET_MONTHLY_ADVANCE });
};

export const updateMonthlyAdvances = data => async (dispatch) => {
  const newData = data.map((d) => {
    d.advance = parseFloat(d.advance);
    return d;
  });
  dispatch(updateData('monthlyadvance', newData));
};

export const addMonthlyAdvance = data => async dispatch => dispatch({
  type: types.ADD_MONTHLY_ADVANCE, payload: data,
});

export const addUnitOperationGroup = data => async dispatch => dispatch({
  type: types.ADD_UNIT_OPERATION_GROUP,
  payload: data,
});

export const resetUnitOperationGroups = () => async dispatch => dispatch({
  type: types.RESET_UNIT_OPERATION_GROUPS,
});

export const updateUnitOperationGroups = data => async dispatch => dispatch(updateData('unitaryoperationgroup', data));
export const addUnitOperation = data => async dispatch => dispatch({
  type: types.ADD_UNIT_OPERATION,
  payload: data,
});
export const resetUnitOperations = () => async dispatch => dispatch({
  type: types.RESET_UNIT_OPERATIONS,
});
export const updateUnitOperations = data => async dispatch => dispatch(updateData('unitaryoperation', data));

export const searchDailyParts = (parameters, page, limit, orderBy, reorder) => async (dispatch) => {
  try {
    const body = {
      parameters,
      page,
      results_per_page: limit,
      order_by: orderBy,
    };

    const {
      data: {
        result: { underground_operation_report: data = [] },
      },
    } = await api.post('/undergroundoperationreport/get_by_filter', body);

    dispatch({
      type: types.SET_UNDERGROUND_DAILY_PARTS_LIST,
      payload: {
        page,
        limit,
        reorder,
        data,
        dailyPartsSortBy: orderBy,
      },
    });
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const setFilters = (parameters, page = 1, limit = 30) => async (dispatch) => {
  dispatch({ type: types.SET_FILTERS, payload: parameters });
  dispatch(searchDailyParts(parameters, page, limit));
};

export const getUndergroundOperationControls = (
  filters = {},
  clearUploadError = true,
) => async (dispatch) => {
  try {
    if (clearUploadError) {
      dispatch(clearFileUploadError());
    }
    const priority = Object.prototype.hasOwnProperty.call(filters, 'priority')
      && filters.priority !== -1 ? filters.priority : null;
    const normalizedFilters = {
      id_element: filters.id_element || null,
      id_element_point: filters.id_element_point || null,
      id_project_group: filters.id_project_group || null,
      id_unitary_operation: filters.id_unitary_operation || null,
      id_unitary_operation_group: filters.id_unitary_operation_group || null,
      id_code_ug: filters.id_code_ug || null,
      id_code_group_ug: filters.id_code_group_ug || null,
      id_code_type_ug: filters.id_code_type_ug || null,
      show_all: filters.show_all || false,
      priority,
    };

    const endpoint = getEndpoints.undergroundoperationcontrolfilter;
    const endpointUrl = `${endpoint.url}`;
    const {
      data: {
        result: { [endpoint.responseObject]: data = [] },
      },
    } = await api.post(endpointUrl, normalizedFilters);

    dispatch({
      type: endpoint.typeAction,
      payload: data,
    });
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const updateUndergroundOperationControls = (operationControl, filters = {}, domain = 'undergroundoperationcontrol') => async (dispatch) => {
  try {
    const endpoint = getEndpoints[domain];
    const body = {
      underground_operation_control: {
        ...operationControl,
        estimated_advance: parseFloat(operationControl.estimated_advance || 0),
        measured_advance: parseFloat(operationControl.measured_advance || 0),
        input_efective: parseFloat(operationControl.input_efective || 0),
        input_rework: parseFloat(operationControl.input_rework || 0),
      },
    };
    const { status } = await api.post(`${endpoint.url}`, body);

    if (status === 200) {
      dispatch(getUndergroundOperationControls(filters));
      dispatch({
        type: types.SET_UNDERGROUND_OPERATION_CONTROL_SAVED,
        payload: i18n.t('validation:SavedSuccess'),
      });
      dispatch({ type: SET_UPLOAD_SUCCESS, payload: i18n.t('validation:UpdateSentSuccessfully') });
    }
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const setCurrentOperationControl = (item, key = null, value = null) => (dispatch) => {
  const newItem = { ...item };

  if (key) {
    newItem[key] = value;
  }
  dispatch({
    type: types.SET_OPERATION_CONTROL_ITEM,
    payload: newItem,
  });
};

export const setOriginalCurrentOperationControl = item => (dispatch) => {
  dispatch({
    type: types.SET_ORIGINAL_OPERATION_CONTROL_ITEM,
    payload: item,
  });
};

export const setCurrentOperationControlFilter = filters => ({
  type: types.SET_OPERATION_CONTROL_FILTER, payload: filters,
});

export const restoreOperationControlFilter = () => (dispatch) => {
  dispatch({ type: types.RESTORE_OPERATION_CONTROL_FILTER });
};

export const setUndergroundTurnGroupValue = value => (dispatch) => {
  dispatch({
    type: types.SET_UNDERGROUND_TURN_GROUP_VALUE,
    payload: value,
  });
};

export const updateUndergroundTurnGroup = idTurnGroup => async (dispatch) => {
  try {
    const body = { id_turn_group: idTurnGroup };
    const { status } = await api.post('/turnsgroups/set_underground', body);

    if (status === 200) {
      dispatch(getTurnsGroups());
      dispatch({ type: SET_UPLOAD_SUCCESS, payload: i18n.t('validation:SavedSuccess') });
    }
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const resetUndergroundTurnGroup = () => (dispatch) => {
  dispatch({ type: types.RESET_UNDERGROUND_TURN_GROUP });
};

export const getInputUnits = (all = false) => async dispatch => dispatch(getAll({ domain: 'inputunit', all }));

export const setInputUnitValue = (item, key, value) => async dispatch => dispatch(setValues(item, key, value, 'inputunit'));

export const updateInputUnits = data => async dispatch => dispatch(updateData('inputunit', data));

export const addInputUnit = data => async dispatch => dispatch({
  type: types.ADD_INPUT_UNIT,
  payload: data,
});

export const resetInputUnits = () => async dispatch => dispatch({
  type: types.RESET_INPUT_UNITS,
});

export const getMassInputTemplate = () => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/undergroundmassinput`;
    await downloadAxios(url, 'template_undergroundmassinput.xlsx');
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};
export const sendMassInputTemplate = file => async (dispatch) => {
  let clearUploadError = false;
  try {
    dispatch({ type: SET_UPLOAD_ERROR, payload: '' });
    const result = await api.put('/undergroundmassinput', file);
    const { data, status } = result;
    if (status === 200 && data.errors) {
      dispatch({
        type: SET_UPLOAD_SUCCESS_WARNING,
        payload: {
          success: `${i18n.t('validation:SheetImportSuccess')}\n${data.errors[0].message}`,
          error: data.errors[0].message,
        },
      });
    } else if (status === 200) {
      dispatch({ type: SET_UPLOAD_SUCCESS, payload: i18n.t('validation:SheetImportSuccess') });
    }
    clearUploadError = true;
  } catch (error) {
    const { response } = error;
    if (response) {
      dispatch({
        type: SET_UPLOAD_ERROR,
        payload: response.data.errors[0].message,
      });
    } else {
      dispatch({
        type: GET_GENERIC_ERROR,
        payload: i18n.t('common:ServerError'),
      });
    }
  } finally {
    if (clearUploadError) {
      dispatch(clearFileUploadError());
    }
  }
};

import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import AndroidAppTab from './AndroidAppTab';
import AndroidAppTabNoFilter from './AndroidAppTab/AppTabNoFilter';
import PF2MTabs from '~/components/PF2MTabs';
import PF2MButton from '~/components/PF2MButton';
import Main from '../Main/index';
import { ZendeskAPI } from 'react-zendesk';
import { useFeatureFlagPlatformConfiguration } from '~/services/platformConfiguration';
import PF2MWarnFeatureFlag from '~/PF2MWarnFeatureFlag';
import { useDispatch } from 'react-redux';
import { getApkFile } from '~/store/admin/actions';

const Admin = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { t: translate } = useTranslation();
  const featureFlagTableIdFilter = useFeatureFlagPlatformConfiguration('ff_tablet_id_filter', true);
  const dispatch = useDispatch();

  const tabs = [
    { key: 0, label: translate('common:AndroidAppVersion') },
  ];

  const handleChange = (event, value) => {
    setSelectedTab(value);
  };

  useEffect(() => {
    ZendeskAPI('webWidget', 'show');
    return () => {
      ZendeskAPI('webWidget', 'hide');
    };
  }, []);
  return (
    <Main className={styles.main}>
      <>
        <PF2MTabs
          value={selectedTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          {tabs}
        </PF2MTabs>
        {selectedTab === 0 ? (
          <PF2MWarnFeatureFlag
            description="Filtro de Tablet Id altere com ff_tablet_id_filter"
            flag={featureFlagTableIdFilter}
            OnComponent={AndroidAppTab}
            OffComponent={AndroidAppTabNoFilter}
          />
        ) : null}
      </>
      <div style={{ display: 'flex', margin: '10px', justifyContent: 'center' }}>
        <div style={{ width: 'fit-content' }}>
          <PF2MButton onClick={() => dispatch(getApkFile())}>DOWNLOAD CURRENT APK</PF2MButton>
        </div>
      </div>
    </Main>
  );
};

export default withStyles(styles)(Admin);

import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  withStyles,
  FormControl,
  FormLabel,
  FormGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import classnames from 'classnames';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import PF2MAddButton from '~/components/PF2MAddButton';

const TRACTOR = 13;

function Header({
  classes, add, materials, equipmentsGroups, equipments,
}) {
  const { t: translate } = useTranslation();

  const tractorProductivities = useSelector(
    state => state.manager.tractorProductivities,
  );
  const INITIAL_VALUES = {
    id_equip_type: TRACTOR,
    id_equip_group: 0,
    id_equip: 0,
    id_material: 0,
    value: '',
  };
  const isValidItem = useCallback(
    (equipGroup, equip, material) => {
      const existingItem = tractorProductivities.find(
        c => c.id_equip_group === equipGroup
          && c.id_equip === equip
          && c.id_material === material,
      );
      return !existingItem;
    },
    [tractorProductivities],
  );

  const renderItems = (data, itemId) => [
    { value: 0, label: translate('common:Select') },
    ...data.map(e => (
      { value: e[itemId], label: e.name }
    )),
  ];

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      validate={(values) => {
        const errors = {};

        if (values.id_material <= 0) {
          errors.id_material = 'Required';
        }

        if (values.id_equip_group <= 0) {
          errors.id_equip_group = 'Required';
        }

        if (values.id_equip <= 0) {
          errors.id_equip = 'Required';
        }

        if (values.value === '' || values.value <= 0) {
          errors.value = 'Required';
        }

        if (
          !isValidItem(
            values.id_equip_group,
            values.id_equip,
            values.id_material,
          )
        ) {
          errors.value = translate('validation:ProductivityAlreadyExists');
        }

        return errors;
      }}
      onSubmit={async (values, { resetForm }) => {
        await add(values);
        resetForm();
      }}
    >
      {({ isSubmitting, isValid, values }) => (
        <Form>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 5,
            }}
          >
            <FormControl>
              <FormLabel className={classes.formLabel}>
                {`${translate('common:EquipmentGroup')}:`}
              </FormLabel>
              <FormGroup>
                <Field
                  name="id_equip_group"
                  render={({ field, form }) => (
                    <PF2MSearchSelectOutlined
                      {...field}
                      value={(field.value || 0)}
                      className={classes.typeSelector}
                      onChange={(e) => {
                        form.setFieldValue('id_equip_group', e.target.value);
                        form.setFieldValue('id_equip', 0);
                      }}
                    >
                      {renderItems(equipmentsGroups, 'id')}
                    </PF2MSearchSelectOutlined>
                  )}
                />
              </FormGroup>
            </FormControl>
            <FormControl>
              <FormLabel className={classes.formLabel}>
                {`${translate('common:Equipment')}:`}
              </FormLabel>
              <FormGroup>
                <Field
                  name="id_equip"
                  render={({ field, form }) => (
                    <PF2MSearchSelectOutlined
                      {...field}
                      value={(field.value || 0)}
                      onChange={e => form.setFieldValue('id_equip', e.target.value)}
                      className={classnames(
                        classes.typeSelector,
                        form.errors[field.name]
                          ? classes.fieldError
                          : classes.typeSelector,
                      )}
                    >
                      {renderItems(
                        equipments.filter(
                          e => e.id_group === values.id_equip_group,
                        ),
                        'id',
                      )}
                    </PF2MSearchSelectOutlined>
                  )}
                />
              </FormGroup>
            </FormControl>
            <FormControl>
              <FormLabel className={classes.formLabel}>
                {`${translate('common:Material')}:`}
              </FormLabel>
              <FormGroup>
                <Field
                  name="id_material"
                  render={({ field, form }) => (
                    <PF2MSearchSelectOutlined
                      {...field}
                      value={(field.value || 0)}
                      onChange={e => form.setFieldValue('id_material', e.target.value)}
                      className={classnames(
                        classes.typeSelector,
                        form.errors[field.name]
                          ? classes.fieldError
                          : classes.typeSelector,
                      )}
                    >
                      {renderItems(materials, 'id')}
                    </PF2MSearchSelectOutlined>
                  )}
                />
              </FormGroup>
            </FormControl>
            <FormControl style={{ marginRight: 20, width: 200 }}>
              <FormLabel className={classes.formLabel}>
                {`${translate('common:Value')}:`}
              </FormLabel>
              <FormGroup>
                <Field
                  name="value"
                  render={({ field, form }) => (
                    <NumberFormat
                      {...field}
                      className={classnames(
                        'form-control',
                        classes.field,
                        form.errors[field.name]
                          ? classes.fieldError
                          : classes.field,
                      )}
                      decimalSeparator=","
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  )}
                />
              </FormGroup>
            </FormControl>
            <PF2MAddButton
              disabled={isSubmitting || !isValid}
              style={{ marginTop: 20 }}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  add: PropTypes.func,
  materials: PropTypes.array,
  equipmentsGroups: PropTypes.array,
  equipments: PropTypes.array,
};

Header.defaultProps = {
  add: null,
  materials: [],
  equipmentsGroups: [],
  equipments: [],
};

export default withStyles(styles)(Header);

import React, { useState } from 'react';
import {
  withStyles,
  FormControl,
  FormLabel,
  FormGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';

function Header({
  classes, equipmentTypes, update,
}) {
  const { t: translate } = useTranslation();

  const [equipmentType, setEquipmentType] = useState(0);

  const handleChange = (e) => {
    update(e.target.value);
    setEquipmentType(e.target.value);
  };

  const renderItems = (data, itemId) => [
    { value: 0, label: translate('common:Select') },
    ...data.map(e => (
      { value: e[itemId], label: e.name }
    )),
  ];

  return (
    <Formik onSubmit={(_, { resetForm }) => { resetForm(); }}>
      {() => (
        <Form>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 5,
            }}
          >
            <FormControl>
              <FormLabel className={classes.formLabel}>
                {`${translate('common:EquipmentType')}:`}
              </FormLabel>
              <FormGroup>
                <Field
                  name="id_equip_type"
                  render={({ field }) => (
                    <PF2MSearchSelectOutlined
                      {...field}
                      value={equipmentType}
                      className={classes.typeSelector}
                      onChange={handleChange}
                    >
                      {renderItems(equipmentTypes, 'id')}
                    </PF2MSearchSelectOutlined>
                  )}
                />
              </FormGroup>
            </FormControl>
          </div>
        </Form>
      )}
    </Formik>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  update: PropTypes.func,
  equipmentTypes: PropTypes.array,
};

Header.defaultProps = {
  update: null,
  equipmentTypes: [],
};

export default withStyles(styles)(Header);

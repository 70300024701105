import * as types from './types';

const INITIAL_STATE = {
  rpmRanges: [],
  hourmeterLimits: [],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_RPM_RANGES_LIST: {
      return {
        ...state,
        rpmRanges: action.payload,
      };
    }
    case types.SET_HOURMETER_LIMITS_LIST: {
      return {
        ...state,
        hourmeterLimits: action.payload,
      };
    }
    default:
      return state;
  }
}

const styles = {
  fieldsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  labelSpan: {
    fontFamily: 'Roboto',
    fontSize: 12,
    color: '#455873',
    display: 'block',
  },
};

export default styles;

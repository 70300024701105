import { dispatchOperationTypeIncludes, DispatchStatus } from '~/utils';

const TruckOperators = to => ({
  isHealthy: to.status !== DispatchStatus.MA,
  isOnQuickPause: to.current_excavator_id && !to.is_logout && to.status === DispatchStatus.PO,
  // to.code_group_id === 1 is only for OP, but not all OP
  isOnCicle: to.current_excavator_id && to.code_group_id === 1 && !to.is_logout,
  isLoading: dispatchOperationTypeIncludes(
    to, 'LOAD', 'LOAD_MANEUVER', 'LOAD_QUEUE', 'LOAD_TRAFFIC',
  ),
  to,
  // Note that status IN is on avilable
});

export default TruckOperators;

/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import {
  withStyles,
  FormControl,
  FormLabel,
  FormGroup,
  Typography,
} from '@material-ui/core';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Info from '@material-ui/icons/Info';
import {
  Card, CardBody, CardHeader, CardIcon,
} from '~/components/Card';
import { useTranslation } from 'react-i18next';
import PF2MButton from '~/components/PF2MButton';
import styles from '../styles';
import PF2MMaskTextField from '~/components/PF2MMaskTextField';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import { updateEquipmentsSchedules } from '~/store/dispatch/actions';

const PENDING_STATUS = 1;

const EquipmentProgramsKanbanModal = React.forwardRef(({
  classes, setModalOpen, data, onRemove, onChange,
}) => {
  const dispatch = useDispatch();
  const isEquipmentsSchedulesDirty = useSelector(st => st.dispatch.isEquipmentsSchedulesDirty);
  const equipmentsSchedules = useSelector(state => state.dispatch.equipmentsSchedules);
  const currentFilter = useSelector(state => state.dispatch.currentEquipmentSchedulesFilter);
  const [delay, setDelay] = useState(data.delay || '');
  const [showAlert, setShowAlert] = useState(false);
  const { t: translate } = useTranslation();
  const renderHeader = () => (
    <div>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
      >
        {translate('common:EquipmentScheduling')}
      </Typography>
      <Typography variant="h6" align="center">
        {data.equipament_type_name}
        {' - '}
        {data.equipament_groups_name}
        {': '}
        {data.group_equipament_links_name}
      </Typography>
    </div>
  );

  useEffect(() => {
    if (isEquipmentsSchedulesDirty) {
      dispatch(updateEquipmentsSchedules(equipmentsSchedules, currentFilter));
      setModalOpen(false);
    }
  }, [
    isEquipmentsSchedulesDirty,
    currentFilter,
    dispatch,
    equipmentsSchedules,
    setModalOpen,
  ]);

  return (
    <div>
      <Card>
        <CardHeader icon>
          <CardIcon color="warning">
            <Info />
          </CardIcon>
        </CardHeader>
        <CardBody>
          {renderHeader()}
          <hr />

          {/* linha horarios formulario */}
          <div style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 160px)',
            gridColumnGap: 10,
            gridRowGap: 10,
            margin: '10px 0',
          }}
          >
            <FormControl>
              <FormLabel className={classes.formLabel}>
                {translate('common:InitialTime')}
              </FormLabel>
              <FormGroup>
                <Typography>{data.start_time}</Typography>
              </FormGroup>
            </FormControl>
            <FormControl>
              <FormLabel className={classes.formLabel}>
                {translate('common:EndTime')}
              </FormLabel>
              <FormGroup>
                <Typography>{data.end_time}</Typography>
              </FormGroup>
            </FormControl>
            <FormControl>
              <FormLabel className={classes.formLabel}>
                {translate('common:Duration')}
              </FormLabel>
              <FormGroup>
                <Typography>{data.duration}</Typography>
              </FormGroup>
            </FormControl>

            <FormControl>
              <FormLabel className={classes.formLabel}>
                {translate('common:CodeType')}
              </FormLabel>
              <FormGroup>
                <Typography>{data.code_type_name}</Typography>
              </FormGroup>
            </FormControl>
            <FormControl>
              <FormLabel className={classes.formLabel}>
                {translate('common:CodeGroup')}
              </FormLabel>
              <FormGroup>
                <Typography>{data.code_groups_name}</Typography>
              </FormGroup>
            </FormControl>
            <FormControl>
              <FormLabel className={classes.formLabel}>
                {translate('common:Code')}
              </FormLabel>
              <FormGroup>
                <Typography>{data.codes_name}</Typography>
              </FormGroup>
            </FormControl>

            <FormControl>
              <FormLabel className={classes.formLabel}>
                {translate('common:Element')}
              </FormLabel>
              <FormGroup>
                <Typography>{data.flowchart_element_name}</Typography>
              </FormGroup>
            </FormControl>
            <FormControl>
              <FormLabel className={classes.formLabel}>
                {translate('common:SubElement')}
              </FormLabel>
              <FormGroup>
                <Typography>{data.flowchart_element_point_name}</Typography>
              </FormGroup>
            </FormControl>
            {data.status_id === PENDING_STATUS && (
              <FormControl>
                <FormLabel className={classes.formLabel}>
                  {translate('common:Delay')}
                </FormLabel>
                <FormGroup>
                  <PF2MMaskTextField
                    variant="outlined"
                    mask="99:99:99"
                    className={classes.textField}
                    placeholder={'00:00:00'}
                    value={delay}
                    onChange={e => setDelay(e.target.value)}
                    InputProps={{
                      style: {
                        height: 40,
                        width: 150,
                        fontFamily: 'Roboto',
                        fontWeight: 300,
                        fontSize: 14,
                        color: '#647886',
                      },
                    }}
                  />
                </FormGroup>
              </FormControl>
            )}
          </div>
          {/* fim linha combos formulario inclusao */}
          <div style={{ display: 'flex' }}>
            <FormControl className={classes.classObservation}>
              <FormLabel className={classes.formLabel}>
                {`${translate('common:Observation')}:`}
              </FormLabel>
              <FormGroup>
                <Typography>{data.observation}</Typography>
              </FormGroup>
            </FormControl>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}>
            { data.status_id === PENDING_STATUS && (
              <PF2MButton
                variant="extended"
                size="medium"
                color="primary"
                onClick={() => {
                  onRemove(data);
                }}
                className={classes.buttonWhiteText}
              >
                <DeleteOutline />
                {translate('common:DeleteSchedule')}
              </PF2MButton>
            )
            }
            <PF2MButton
              className={classes.buttonWhiteText}
              onClick={() => {
                if (delay !== data.delay) {
                  setShowAlert(true);
                } else {
                  setModalOpen(false);
                }
              }}
            >
              Ok
            </PF2MButton>
          </div>
        </CardBody>
      </Card>
      <PF2MAlertDialog
        confirmText={translate('common:Yes')}
        description={translate('validation:WarningScheduleChange')}
        open={showAlert}
        onClose={() => setShowAlert(false)}
        onConfirm={() => {
          setShowAlert(false);
          onChange(data, delay, 'delay');
        }}
      />
    </div>
  );
});

EquipmentProgramsKanbanModal.propTypes = {
  classes: PropTypes.object.isRequired,
  setModalOpen: PropTypes.func,
  data: PropTypes.object,
  onRemove: PropTypes.func,
  onChange: PropTypes.func,
};

EquipmentProgramsKanbanModal.defaultProps = {
  setModalOpen: () => null,
  data: {},
  onRemove: () => null,
  onChange: () => null,
};

export default withStyles(styles)(EquipmentProgramsKanbanModal);

import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Card,
  Chip,
  CircularProgress,
  IconButton, Typography, withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Close,
  DragIndicator,
  ErrorOutline,
  KeyboardArrowDown, KeyboardArrowLeft,
} from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useDrag, useDrop } from 'react-dnd';
import styles from '../styles';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import { getEndpoints } from '~/store/manager/actions';
import {
  get, getGoalTypes, getGoalTypesLevels,
} from '~/store/goals/actions';
import { ItemTypes } from './GoalsDnd';
import PF2MAutocomplete from '~/components/PF2MAutocomplete';
import PF2MTooltip from '~/components/PF2MTooltip';
import { getFilterStates } from './FilterStates';

// Goal chip component for drag and drop
const GoalCell = ({
  title, table, data, filters, isGoalType = false, setGoalChips,
}) => {
  const outerRef = useRef(null);
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const [cardVisible, setCardVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(0);
  const [cellOptions, setCellOptions] = useState({});
  const [cellFilters, setCellFilters] = useState(filters);

  // Check if all filters are selected
  const valid = getFilterStates(translate)[table]
    ? Object.keys(cellFilters).length === getFilterStates(translate)[table].length
    : false;

  const [, drag] = useDrag({
    item: {
      type: ItemTypes.GOAL_LEVEL1,
      obj: {
        data: {
          ...data, title, table, cellFilters,
        },
      },
    },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
    canDrag: () => valid,
  });

  const getManagerData = (endpoint) => {
    // Make a synchronous call to the API and retrieve data to the filter select
    if (endpoint) {
      if (isLoaded !== getFilterStates(translate)[table].length) {
        setIsLoading(true);
        return dispatch(get(
          endpoint,
          true,
          (res) => {
            setIsLoading(false);
            setCardVisible(true);
            setIsLoaded(isLoaded + 1);
            setCellOptions(old => ({
              ...old,
              [endpoint.name]: res.map(r => ({
                value: r.id,
                label: r.name,
                data: r,
              })),
            }));
          },
        ));
      }
      return setCardVisible(true);
    } return null;
  };

  const renderSearchSelects = cellStates => cellStates.map(s => (
    // Render a search select for each filter depending on the table name
    <PF2MAutocomplete
      style={{ padding: '8px' }}
      key={s.name}
      title={s.label}
      options={cellOptions[s.name] ? cellOptions[s.name].filter((o) => {
        // if single parent, filter options by parent
        if (s.parent) {
          return cellFilters[s.parent]
            ? cellFilters[s.parent].map(f => (f.value)).includes(o.data[s.key])
            : true;
        } if (s.parents) {
        // if multiple parents, filter options by parents
          return !s.parents.map((p, i) => (
            cellFilters[p]
              ? cellFilters[p].map(f => (f.value)).includes(o.data[s.keys[i]])
              : true
          )).includes(false);
        }
        return true;
      }) : []}
      value={cellFilters[s.name] || []}
      onChange={(e, val) => {
        if (!s.parent) { setCellFilters({}); }
        setCellFilters(old => ({ ...old, [s.name]: val }));
        if (!isGoalType) {
          setGoalChips(old => ([
            ...old.filter(o => o.id !== data.id),
            {
              ...data,
              cellFilters: {
                ...data.cellFilters,
                [s.name]: val,
              },
            },
          ]));
        }
      }}
      limitTags={1}
    />
  ));

  const getFiltersData = (tableName) => {
    // Request data from the api for each filter and parent filter (eqp_types->eqp_groups->eqps)
    getFilterStates(translate)[tableName].forEach((s) => {
      getManagerData({ ...getEndpoints[s.name], name: s.name });
    });
  };


  const renderSelectMenu = () => {
    // Render the little modal with the filters
    const cellStates = getFilterStates(translate)[table];
    return (
      <Card
        style={{
          height: cardVisible ? '100%' : 0,
          transition: 'height 0.3s ease-in-out',
        }}
        key={table}
      >
        {cellStates ? renderSearchSelects(cellStates) : null}
        <IconButton
          onClick={() => { setCardVisible(false); }}
        >
          <Close />
        </IconButton>
      </Card>
    );
  };

  return (
    <div ref={outerRef} style={{ display: 'flex', flexDirection: 'column' }}>
      <Chip
        style={
          valid
            ? styles.validCell
            : styles.invalidCell
        }
        ref={drag}
        label={title}
        icon={!valid ? (
          <PF2MTooltip
            title={translate('tips:YouMustSelectAllFilters')}
          >
            <ErrorOutline fontSize="small" style={{ marginRight: '5px' }} />
          </PF2MTooltip>
        ) : <DragIndicator />}
        deleteIcon={
          isLoading
            ? <CircularProgress size={18} />
            : <KeyboardArrowDown style={{ transform: `rotate(${cardVisible ? 0 : 180}deg)` }} />
        }
        variant="outlined"
        onDelete={() => (!cardVisible
          ? getFiltersData(table)
          : setCardVisible(false))}
      />
      {renderSelectMenu()}
    </div>
  );
};
GoalCell.propTypes = {
  title: PropTypes.string.isRequired,
  table: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  filters: PropTypes.object,
  isGoalType: PropTypes.bool,
  setGoalChips: PropTypes.func,
};
GoalCell.defaultProps = {
  filters: {},
  isGoalType: true,
  setGoalChips: () => {},
};

const GoalsFilters = ({
  setCustomColumns,
  classes,
}) => {
  const dispatch = useDispatch();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [level, setLevel] = useState(1);
  const [goalChips, setGoalChips] = useState([]);
  const [goalFilters, setGoalFilters] = useState({
    goalType: 0,
  });

  const goalTypes = useSelector(state => state.goals.goalTypes);
  const goalTypesLevels = useSelector(state => state.goals.goalTypesLevels);

  const { t: translate } = useTranslation();

  useEffect(() => {
    setCustomColumns(goalChips);
  }, [goalChips, setCustomColumns]);

  useEffect(() => {
    async function fetchData() {
      dispatch(getGoalTypes());
      dispatch(getGoalTypesLevels());
    }

    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dispatch, dataLoaded]);

  const renderGoalTypesChips = goalType => goalTypesLevels
    .filter(i => i.level === level && i.goal_types_id === goalType)
    .filter(i => (level > 1 && goalChips.length === level - 1
      ? goalChips[level - 2][`level_type${level - 1}_id`] === i[`level_type${level - 1}_id`]
      : true))
    .filter((i) => {
      if (level > 1 && goalChips.length === level - 1) {
        for (let j = 1; j < level; j += 1) {
          if (goalChips[j - 1][`level_type${j}_id`] !== i[`level_type${j}_id`]) {
            return false;
          }
        }
      }
      return true;
    })
    .map(o => (
      <GoalCell
        key={`${o.id}+${o[`level_type${level}_table_name`]}`}
        table={o[`level_type${level}_table_name`]}
        title={o[`level_type${level}_name`]}
        data={o}
      />
    ));
    // Render a goal chip if the current global level is equal to the level of the goal_type
    // and if the goal_type is not already in the goalChips array

  const renderGoalChips = () => {
    const chips = goalChips.map(c => (
      <GoalCell
        key={c.id}
        title={c.title}
        table={c.table}
        data={c}
        filters={c.cellFilters}
        isGoalType={false}
        setGoalChips={setGoalChips}
      />
    ));
    return chips;
  };

  const GoalBoard = ({ rollback }) => {
    // eslint-disable-next-line no-unused-vars
    const [{ item }, drop] = useDrop({
      accept: (ItemTypes.GOAL_LEVEL1),
      drop: (e) => {
        const filterLevel = e.obj.data.level;
        if (!rollback) {
          setLevel(l => l + 1);
          setGoalChips(old => [
            ...old,
            {
              ...e.obj.data,
              fieldName: e.obj.data.title,
              mainTable: e.obj.data[`level_type${filterLevel}_table_name`],
              mainState: Object.keys(e.obj.data.cellFilters).pop(-1),
            },
          ]);
        } else {
          setLevel(filterLevel);
          setGoalChips(old => old.filter(i => i.level < filterLevel));
        }
      },
      collect: monitor => ({
        isOver: !!monitor.isOver(),
        item: monitor.getItem(),
      }),
    });

    return (
      <div
        style={{
          display: 'flex',
          flexGrow: 1,
        }}
        ref={drop}
      />
    );
  };

  GoalBoard.propTypes = {
    rollback: PropTypes.bool,
  };
  GoalBoard.defaultProps = {
    rollback: false,
  };

  return (
    <Card className={classes.goalCard} style={{ width: isOpen ? '500px' : '24px' }}>
      <Card className={classes.goalSection} style={{ display: isOpen ? 'flex' : 'none' }}>
        <Box>
          <Typography align="center" level="h2" fontSize="md">
            {translate('common:GoalTypes')}
          </Typography>
        </Box>

        <PF2MSearchSelectOutlined
          className={classes.typeSelectorInput}
          value={goalFilters.goalType}
          onChange={(e) => { setGoalFilters({ ...goalFilters, goalType: e.target.value }); }}
          placeholder={translate('common:Select')}
        >
          {goalTypes.map(e => ({ value: e.id, label: e.name || '0' }))}
        </PF2MSearchSelectOutlined>
        {renderGoalTypesChips(goalFilters.goalType)}
        <GoalBoard rollback />
      </Card>
      <Card className={classes.goalSection} style={{ display: isOpen ? 'flex' : 'none' }}>
        <Box>
          <Typography align="center" level="h2" fontSize="md">
            {translate('common:Goal')}
          </Typography>
        </Box>
        {renderGoalChips()}
        <GoalBoard />
      </Card>
      <div style={{ display: 'flex' }}>
        <IconButton
          style={{ borderRadius: 0 }}
          onClick={() => setIsOpen(!isOpen)}
        >
          <KeyboardArrowLeft
            className={classes.transition}
            style={{ transform: isOpen ? 'rotate(0deg)' : 'rotate(180deg)' }}
          />
        </IconButton>
      </div>
    </Card>
  );
};

GoalsFilters.propTypes = {
  classes: PropTypes.object.isRequired,
  setCustomColumns: PropTypes.func.isRequired,
};

export default withStyles(styles)(GoalsFilters);

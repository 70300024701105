import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styles from './styles';
import OperationalControlTab from './OperationalControlTab';
import UndergroundMassInputTab from './UndergroundMassInput';
import PF2MTabs from '~/components/PF2MTabs';
import UndergroundCicleAdvanceTab from './UndergroundCicleAdvanceTab';

const OperationalControl = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [UGMassInput, setUGMassInput] = useState(false);
  const { t: translate } = useTranslation();
  const user = useSelector(state => state.auth.user);

  useEffect(() => {
    if (Object.keys(user).find(key => key === 'config')) {
      setUGMassInput(
        user.config.find(e => e.key === 'UG_mass_input') !== undefined
        && user.config.find(e => e.key === 'UG_mass_input').value === '1'
        && user.level <= 2,
      );
    }
  }, [user, UGMassInput]);

  const tabs = [
    { key: 0, label: translate('common:OperationalControl') },
    { key: 1, label: translate('common:MassInput'), hidden: !UGMassInput },
    { key: 2, label: translate('common:AdvanceRegister') },
  ];

  const handleChange = (event, value) => {
    setSelectedTab(value);
  };

  return (
    <>
      <PF2MTabs
        value={selectedTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        {tabs}
      </PF2MTabs>
      {selectedTab === 0 ? (<OperationalControlTab />) : null}
      {selectedTab === 1 && UGMassInput ? (<UndergroundMassInputTab />) : null}
      {selectedTab === 2 ? (<UndergroundCicleAdvanceTab />) : null}
    </>
  );
};

export default withStyles(styles)(OperationalControl);

import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import styles from './styles';

const PF2MFullScreenButton = ({ isFullScreenActive, open, close }) => (
  <div
    style={{ display: 'flex', justifyContent: 'flex-end' }}
  >
    {isFullScreenActive
      ? <FullscreenExitIcon style={{ cursor: 'pointer' }} onClick={isFullScreenActive ? close : open} />
      : <FullscreenIcon style={{ cursor: 'pointer' }} onClick={isFullScreenActive ? close : open} />}
  </div>
);

PF2MFullScreenButton.propTypes = {
  isFullScreenActive: PropTypes.bool,
  open: PropTypes.func,
  close: PropTypes.func,
};

PF2MFullScreenButton.defaultProps = {
  isFullScreenActive: false,
  open: () => null,
  close: () => null,
};

export default withStyles(styles)(PF2MFullScreenButton);

import React, { useState, useEffect } from 'react';
import {
  withStyles, FormControl, FormLabel, FormGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import Footer from '~/pages/Manager/Footer';
import PF2MTable from '~/components/PF2MTable';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import Header from '~/pages/Manager/Header';
import {
  getCodeGroups,
  getCodeTypes,
  getCodes,
  addCode,
  setCodeValue,
  updateCodes,
  resetCodes,
  getCodesTemplate,
  exportCodes,
  importCodes,
} from '~/store/underground/actions';
import PF2MAddButton from '~/components/PF2MAddButton';

const validateName = (codes, codeType, codeGroup, name) => {
  if (codes.some(c => c.id_code_type === codeType
    && c.id_code_type === codeType
    && c.name === name)) {
    return false;
  }
  return true;
};

const CodesTab = ({ classes }) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const isCodesDirty = useSelector(state => state.underground.isCodesDirty);
  const codeTypes = useSelector(state => state.underground.codeTypes);
  const codeGroups = useSelector(state => state.underground.codeGroups);
  const codes = useSelector(state => state.underground.codes);
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      dispatch(getCodes());
      dispatch(getCodeTypes());
      await dispatch(getCodeGroups());
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch]);

  const columns = [
    {
      field: 'code_type_name', title: translate('common:CodeType'),
    },
    {
      field: 'code_group_name', title: translate('common:CodeGroup'),
    },
    {
      field: 'name',
      title: translate('common:Code'),
      width: '100%',
      editable: true,
      updateOnChange: true,
    },
    {
      field: 'active',
      title: 'STATUS',
      editable: true,
    },
  ];

  const renderHeader = () => (
    <Formik
      initialValues={{ name: '', codeType: 0, codeGroup: 0 }}
      validate={({ name, codeType, codeGroup }) => {
        const errors = {};
        if (!name) {
          errors.name = translate('validation:RequiredField');
        }
        if (codeType <= 0) {
          errors.codeType = translate('validation:RequiredField');
        }
        if (codeGroup <= 0) {
          errors.codeGroup = translate('validation:RequiredField');
        }
        if (!validateName(codes, codeType, codeGroup, name)) {
          errors.name = translate('validation:AlreadyRegisteredName');
        }
        return errors;
      }}
      onSubmit={async ({ codeType, name, codeGroup }, { resetForm }) => {
        const selectedCodeType = codeTypes.find(t => t.id === codeType);
        const selectedCodeGroup = codeGroups.find(t => t.id === codeGroup);
        const newItem = {
          name,
          id_code_type_ug: selectedCodeType.id,
          code_type_name: selectedCodeType.name,
          id_code_group_ug: selectedCodeGroup.id,
          code_group_name: selectedCodeGroup.name,
        };

        await dispatch(addCode(newItem));
        resetForm();
      }}
    >
      {({
        isSubmitting,
        errors,
        isValid,
        values,
      }) => (
        <Form style={{ display: 'flex', alignItems: 'center' }}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              {`${translate('common:CodeType')}:`}
            </FormLabel>
            <FormGroup>
              <Field
                name="codeType"
                render={({ field, form }) => (
                  <PF2MSearchSelectOutlined
                    {...field}
                    placeholder={null}
                    onChange={(e) => {
                      form.setFieldValue('codeType', e.target.value);
                      form.setFieldValue('codeGroup', 0);
                    }}
                    className={classnames(
                      form.errors[field.name] ? classes.fieldError : classes.field,
                    )}
                  >
                    {[{ value: 0, label: translate('common:Select') }]
                      .concat(...codeTypes
                        .map(e => ({ value: e.id, label: e.name })))}
                  </PF2MSearchSelectOutlined>
                )}
              />
            </FormGroup>
          </FormControl>
          <FormControl style={{ marginLeft: 10 }}>
            <FormLabel className={classes.formLabel}>
              {`${translate('common:CodeGroup')}:`}
            </FormLabel>
            <FormGroup>
              <Field
                name="codeGroup"
                render={({ field, form }) => (
                  <PF2MSearchSelectOutlined
                    {...field}
                    placeholder={null}
                    onChange={(e) => {
                      form.setFieldValue('codeGroup', e.target.value);
                    }}
                    className={classnames(
                      form.errors[field.name] ? classes.fieldError : classes.field,
                    )}
                  >
                    {[{ value: 0, label: translate('common:Select') }]
                      .concat(...codeGroups
                        .filter(c => c.id_code_type_ug === values.codeType)
                        .map(e => ({ value: e.id, label: e.name })))}
                  </PF2MSearchSelectOutlined>
                )}
              />
            </FormGroup>
          </FormControl>
          <FormControl className={classes.classGroupName}>
            <FormLabel className={classes.formLabel}>
              {`${translate('common:Code')}:`}
            </FormLabel>
            <FormGroup>
              <Field
                type="text"
                name="name"
                className={classnames('form-control', errors.name ? classes.fieldError : classes.field)}
              />
            </FormGroup>
          </FormControl>
          <div style={{ marginTop: 15 }}>
            <PF2MAddButton disabled={isSubmitting || !isValid} />
          </div>
        </Form>
      )}
    </Formik>
  );

  return (
    <div className={classes.tabContainer}>
      <Header
        importAction={file => dispatch(importCodes(file))}
        exportAction={() => dispatch(exportCodes())}
        getTemplateAction={() => dispatch(getCodesTemplate())}
      />
      {renderHeader()}
      <PF2MTable
        data={codes}
        columns={columns}
        updateItem={(row, field, value) => dispatch(setCodeValue(row, field, value))}
        className={classes.table}
      />
      <Footer
        isDirty={isCodesDirty}
        discard={() => dispatch(resetCodes())}
        sendData={() => dispatch(updateCodes(codes))}
      />
    </div>
  );
};

CodesTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

CodesTab.defaultProps = {};

export default withStyles(styles)(CodesTab);

import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import {
  getTabletConfiguration,
  updateTabletConfigurations,
  resetTabletConfigurations,
  setTabletConfigurationsValues,
} from '~/store/manager/actions';
import PF2MDataTable from '~/components/PF2MDataTable';

const CyclesAutomationTab = ({
  classes,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  const tabletConfiguration = useSelector(state => state.manager.tabletConfiguration);


  useEffect(() => {
    async function fetchData() {
      dispatch(getTabletConfiguration());
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch]);

  const columns = [
    {
      editable: 'never', title: translate('common:Key'), field: 'key',
    },
    {
      editable: 'never',
      title: translate('common:Description'),
      field: 'description',
      cellStyle: {
        whiteSpace: 'normal', maxWidth: 1,
      },
    },
    {
      editable: 'always',
      title: translate('common:Value'),
      field: 'value',
    },
  ];

  return (
    <div className={classes.tabContainer}>
      <PF2MDataTable
        title={translate('common:CyclesAutomation')}
        options={{
          showTitle: true,
          search: true,
          selection: false,
          pageSize: 20,
          pageSizeOptions: [20, 50, 100],
          hideDelete: true,
          editableRowValidation: rowData => (rowData.id < 0 && rowData.update_status !== 'I'),
          maxBodyHeight: 'calc(100vh - 260px)',
          hideAdd: true,
        }}
        onChange={(newData) => {
          newData.forEach((row) => {
            dispatch(setTabletConfigurationsValues(row));
          });
        }}
        onDiscard={() => dispatch(resetTabletConfigurations())}
        onApply={() => dispatch(updateTabletConfigurations(tabletConfiguration))}
        columns={columns}
        data={tabletConfiguration.filter(row => row.key.startsWith('automation_'))}
      />
    </div>
  );
};

CyclesAutomationTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

CyclesAutomationTab.defaultProps = {
};

export default (withStyles(styles)(CyclesAutomationTab));

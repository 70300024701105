import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Header from './Header';
import PF2MTable from '~/components/PF2MTable';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import {
  getEquipmentTypes,
  getEquipmentsGroups,
} from '~/store/manager/actions';
import {
  getHourmeterLimits, importHourmeterLimits, exportHourmeterLimits, getHourmeterLimitsTemplate,
} from '~/store/telemetry/actions';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';

const HourmeterLimitsTab = ({ classes }) => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [equipmentType, setEquipmentType] = useState(0);
  const [equipmentGroup, setEquipmentGroup] = useState(0);
  const equipmentTypes = useSelector(state => state.manager.equipmentTypes);
  const equipmentGroups = useSelector(state => state.manager.equipmentsGroups);
  const hourmeterLimits = useSelector(state => state.telemetry.hourmeterLimits);

  useEffect(() => {
    async function fetchData() {
      await dispatch(getEquipmentTypes());
      await dispatch(getEquipmentsGroups());
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch]);

  useEffect(() => {
    async function fetchData() {
      await dispatch(getHourmeterLimits(equipmentType, equipmentGroup));
    }
    if (equipmentType > 0 && equipmentGroup > 0) {
      fetchData();
    }
  }, [equipmentType, equipmentGroup, dispatch]);

  const columns = [
    { field: 'equipament_group_name', title: translate('common:EquipmentGroup') },
    { field: 'group_equipament_links_name', title: translate('common:Equipment') },
    { field: 'hourmeter_max', title: translate('common:HourmeterMax') },
    { field: 'hourmeter_start', title: translate('common:HourmeterStart') },
  ];

  const renderItems = (items = []) => [{ value: 0, label: translate('common:Select') }]
    .concat(...items
      .map(e => ({ value: e.id, label: e.name })));

  const prepareData = data => data
    .map(r => ({
      ...r,
      rpm_no_rotation: '0',
    }))
    .filter(r => r.equipament_group_id === equipmentGroup);

  return (
    <div className={classes.tabContainer}>
      <Header
        importAction={file => dispatch(importHourmeterLimits(file))}
        exportAction={() => dispatch(exportHourmeterLimits())}
        getTemplateAction={() => dispatch(getHourmeterLimitsTemplate())}
      />
      <div className={classes.containerTypeSelector}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <span className={classes.labelTypeSelector}>
            {`${translate('common:EquipmentType')}:`}
          </span>
          <PF2MSearchSelectOutlined
            value={equipmentType}
            onChange={(e) => {
              setEquipmentType(e.target.value);
              setEquipmentGroup(0);
            }}
            className={classes.typeSelector}
          >
            {renderItems(equipmentTypes)}
          </PF2MSearchSelectOutlined>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
          <span className={classes.labelTypeSelector}>
            {`${translate('common:EquipmentGroup')}:`}
          </span>
          <PF2MSearchSelectOutlined
            value={equipmentGroup}
            onChange={e => setEquipmentGroup(e.target.value)}
            className={classes.typeSelector}
          >
            {renderItems(equipmentGroups.filter(e => e.id_equipament === equipmentType))}
          </PF2MSearchSelectOutlined>
        </div>
      </div>
      <PF2MTable
        data={prepareData(hourmeterLimits)}
        columns={columns}
        className={classes.grid}
      />
    </div>
  );
};

HourmeterLimitsTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

HourmeterLimitsTab.defaultProps = {
};

export default withStyles(styles)(HourmeterLimitsTab);

import React from 'react';
import {
  AppBar, withStyles, Toolbar, Button,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Home from '@material-ui/icons/Home';
import classNames from 'classnames';
import styles from './styles';
import { modules as modulesUrls } from '~/utils';
import { Fast2MineLogo } from '../PF2MTopBarMain';
import UserMenu from '../UserMenu';

const PF2MTopBar = ({
  classes, modules, config,
}) => {
  const { t: translate } = useTranslation();

  const renderModules = () => {
    const enableOpenPit = config.find(c => c.key === 'enable_openpit' && c.value === '1');

    return (modules.map(m => ((modulesUrls[m.module_value] && modulesUrls[m.module_value].url) ? (
      <Button
        key={m.module_value}
        component={NavLink}
        to={m.module_value !== 'REPORTS' ? modulesUrls[m.module_value].url : { pathname: config.find(c => c.key === 'url_reports')?.value }}
        activeClassName={classes.active}
        disabledClassName={classes.disabled}
        className={classNames(
          classes.title,
          modulesUrls[m.module_value].disabled && classes.disabled,
        )}
        disabled={modulesUrls[m.module_value].disabled}
        target={m.module_value === 'REPORTS' ? '_blank' : undefined}
      >
        {translate(`common:${enableOpenPit && m.module_name === 'UndergroundModule' ? 'OpenPitModule' : m.module_name}`)}
      </Button>
    ) : null)));
  };

  return (
    <div>
      <AppBar position="static" color="primary">
        <Toolbar variant="dense" className={classes.topBarContainer}>
          <NavLink to="/" className={classes.title}>
            <Fast2MineLogo className={classes.f2mLogo} />
          </NavLink>
          <div className={classes.topBarModulesContainer}>
            <div className={classes.topBarModulesScrollContent}>
              {modules && renderModules()}
            </div>
          </div>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          >
            <UserMenu />
            <Button
              component={React.forwardRef((props, ref) => (<NavLink {...props} ref={ref} />))}
              exact
              to="/"
              activeClassName={classes.active}
              className={classes.title}
            >
              <Home />
            </Button>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};
PF2MTopBar.propTypes = {
  classes: PropTypes.object,
  modules: PropTypes.array,
  config: PropTypes.array,
};

PF2MTopBar.defaultProps = {
  classes: {},
  modules: [],
  config: [],
};

const mapStateToProps = state => ({
  // eslint-disable-next-line no-underscore-dangle
  modules: state.auth.user._roles,
  version: state.auth.version,
  config: state.auth.user.config,
});

export default connect(mapStateToProps, {})(withStyles(styles)(PF2MTopBar));

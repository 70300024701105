import React, { useEffect, useState } from 'react';
import {
  Form, Field, ErrorMessage, Formik,
} from 'formik';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import PropTypes from 'prop-types';
import {
  Grid, Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { newMomentDate, parseDate } from '~/utils/moment';
import {
  Card, CardBody, CardHeader, CardIcon,
} from '~/components/Card';
import PF2MSearchSelect from '~/components/PF2MSearchSelect';
import PF2MSearchButton from '~/components/PF2MSearchButton';
import PF2MClearFiltersButton from '~/components/PF2MClearFiltersButton';
import PF2MDatepicker from '~/components/PF2MDatepicker';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import {
  getElements,
  getOriginSubElements,
  getEquipmentTypes,
  getEquipmentsGroups,
  getEquipments,
  getShiftScales,
  getExceptionTypes,
  getTurnsGroups,
  getTurns,
  getTeamGroups,
  getTeams,
} from '~/store/manager/actions';
import { setUndergroundFilters } from '~/store/dailyparts/actions';
import {
  getProjectGroups,
  getUnitOperationGroups,
  getUnitOperations,
  getCodeTypes,
  getCodeGroups,
  getCodes,
  getPriorities,
} from '~/store/underground/actions';

const SearchForm = ({ classes, onSearch, onClose }) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [projectGroup, setProjectGroup] = useState(0);
  const [element, setElement] = useState(0);
  const dispatch = useDispatch();
  const filters = useSelector(state => state.dailyParts.filters);
  const elements = useSelector(state => state.manager.elements);
  const subElements = useSelector(state => state.manager.subElements);
  const projectGroups = useSelector(state => state.underground.projectGroups);
  const { t: translate } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      dispatch(getProjectGroups(true));
      await dispatch(getElements(true));
      dispatch(getUnitOperationGroups(true));
      dispatch(getUnitOperations(true));
      dispatch(getCodeTypes(true));
      dispatch(getCodeGroups(true));
      dispatch(getCodes(true));
      dispatch(getEquipmentTypes(true));
      dispatch(getEquipmentsGroups(true));
      dispatch(getEquipments(true));
      dispatch(getShiftScales(true));
      dispatch(getPriorities());
      dispatch(getExceptionTypes());
      dispatch(getTurnsGroups());
      dispatch(getTurns());
      dispatch(getTeamGroups());
      dispatch(getTeams());
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch]);

  useEffect(() => {
    dispatch(getOriginSubElements(projectGroup, element, false, true));
  }, [projectGroup, element, dispatch]);

  const renderMenuItem = (key, value, text) => (
    { label: text, value }
  );

  const renderProjectGroups = () => [renderMenuItem('projectGroup_0', 0, translate('common:Select'))]
    .concat(...projectGroups.map(e => renderMenuItem(e.id, e.id, e.name)));

  const renderElements = () => [renderMenuItem('element_0', 0, translate('common:Select'))]
    .concat(
      ...elements
        .sort((i, k) => (i.name < k.name ? 1 : -1)) // alphanumeric sorting
        .sort(i => i.active * -1) // move inactive items to the end of the array
        .map(e => renderMenuItem(e.id, e.id,
          `${e.name} ${!e.active ? `- ${translate('common:Inactive')}` : ''}`)), // add inactive tag to inactive items
    );

  const renderSubElements = () => [renderMenuItem('subElement_0', 0, translate('common:Select'))]
    .concat(
      ...subElements
        .sort((i, k) => (i.name < k.name ? 1 : -1)) // alphanumeric sorting
        .sort(i => i.active * -1) // move inactive items to the end of the array
        .map(e => renderMenuItem(e.id, e.id,
          `${e.name} ${!e.active ? `- ${translate('common:Inactive')}` : ''}`)), // add inactive tag to inactive items
    );

  const renderDate = field => (
    <Field name={field} component={PF2MDatepicker} />
  );

  const renderSelect = (name, children, handleState) => (
    <>
      <Field
        name={name}
        render={({ field, form }) => (
          <PF2MSearchSelect
            placeholder={null}
            {...field}
            onChange={(e) => {
              form.setFieldValue(field.name, e.target.value, true);
              if (handleState) {
                handleState(e.target.value);
              }
            }}
            style={{ width: '100%' }}
          >
            {children}
          </PF2MSearchSelect>
        )}
      />
      <ErrorMessage
        name={name}
        render={msg => (
          <Typography
            color="error"
            variant="caption"
          >
            {msg}
          </Typography>
        )}
      />
    </>
  );

  const renderCheckbox = (name, label, checked) => (
    <Field
      name={name}
      render={({ field, form }) => (
        <FormControlLabel
          style={{ marginLeft: 0 }}
          control={(
            <Checkbox
              checked={!!checked}
              onChange={() => form.setFieldValue(field.name, !field.value)}
            />
          )}
          label={label}
        />
      )}
    />
  );

  return (
    <Formik
      isInitialValid
      initialValues={{
        startDate: filters.startDate || new Date(),
        endDate: filters.endDate || new Date(),
        projectGroup: filters.projectGroup ? filters.projectGroup.id : 0,
        element: filters.element ? filters.element.id : 0,
        subElement: filters.subElement ? filters.subElement.id : 0,
        hideInterruptedOrDeleted: filters.hideInterruptedOrDeleted,
      }}
      validate={(values) => {
        const {
          startDate,
          endDate,
        } = values;
        const startDateMoment = moment(startDate);
        const endDateMoment = moment(endDate);
        const errors = {};

        if (!startDateMoment.isValid()) {
          errors.startDate = translate('validation:InitialDateRequired');
        }

        if (!endDateMoment.isValid()) {
          errors.endDate = translate('validation:FinalDateRequired');
        }

        if (startDateMoment.isValid() && endDateMoment.isValid()) {
          const days = endDateMoment.diff(startDateMoment, 'days');
          if (days < 0) {
            errors.endDate = translate('validation:FinalDateSmallerThanInitialDate');
          }

          const searchLimit = process.env.REACT_APP_SEARCH_LIMIT_IN_DAYS || 7;
          if (days > searchLimit) {
            errors.endDate = `${translate('validation:SearchTimeRangeCantExceed')} ${searchLimit} ${translate('common:Days').toLowerCase()}`;
          }
        }

        return errors;
      }}
      onSubmit={async (values, { resetForm }) => {
        const {
          projectGroup: group,
          element: selectedElement,
          subElement,
          hideInterruptedOrDeleted,
        } = values;
        const currentProjectGroup = projectGroups.find(e => e.id === group);
        const currentElement = elements.find(e => e.id === selectedElement);
        const currentSubElment = subElements.find(e => e.id === subElement);

        dispatch(setUndergroundFilters({
          ...values,
          projectGroup: currentProjectGroup,
          element: currentElement,
          subElement: currentSubElment,
          startDate: parseDate(values.startDate, true),
          endDate: parseDate(values.endDate, true),
          hideInterruptedOrDeleted,
        }));
        dispatch(getOriginSubElements());
        resetForm();
        onSearch();
      }}
    >
      {({
        isSubmitting,
        isValid,
        values,
        resetForm,
      }) => (
        <Form>
          <Card style={{ width: 900 }}>
            <CardHeader icon>
              <>
                <CardIcon color="warning">
                  <SearchIcon />
                </CardIcon>
                <CardIcon
                  onClick={onClose}
                  style={{
                    float: 'right',
                    cursor: 'pointer',
                    background: 'darkgray',
                    borderRadius: 50,
                    boxShadow: '10px 10px 50px gray',
                  }}
                >
                  <CloseIcon />
                </CardIcon>
              </>
            </CardHeader>
            <CardBody>
              <Typography
                variant="h4"
                align="center"
                gutterBottom
              >
                {translate('common:Search').toUpperCase()}
              </Typography>
              <Grid container spacing={2} className={classes.fieldsContainer}>
                <Grid item container spacing={1} xs style={{ marginTop: 10 }}>
                  <Grid item xs>
                    <span className={classes.labelSpan}>
                      {translate('common:InitialDate')}
                    </span>
                    {renderDate('startDate', values.startDate)}
                  </Grid>
                  <Grid item xs>
                    <span className={classes.labelSpan}>
                      {translate('common:FinalDate')}
                    </span>
                    {renderDate('endDate', values.endDate)}
                  </Grid>
                  <Grid item xs />
                </Grid>
                <Grid item container spacing={1} xs style={{ marginTop: 10 }}>
                  <Grid item xs>
                    <span className={classes.labelSpan}>
                      {translate('common:ProjectGroup')}
                    </span>
                    {renderSelect('projectGroup', renderProjectGroups(), setProjectGroup)}
                  </Grid>
                  <Grid item xs>
                    <span className={classes.labelSpan}>
                      {translate('common:Element')}
                    </span>
                    {renderSelect('element', renderElements(), setElement)}
                  </Grid>
                  <Grid item xs>
                    <span className={classes.labelSpan}>
                      {translate('common:SubElement')}
                    </span>
                    {renderSelect('subElement', renderSubElements())}
                  </Grid>
                </Grid>
                <Grid item container spacing={1} xs style={{ marginTop: 10 }}>
                  <Grid item xs>
                    {renderCheckbox(
                      'hideInterruptedOrDeleted',
                      translate('common:HideInterruptedOrDeletedCycles'),
                      values.hideInterruptedOrDeleted,
                    )}
                  </Grid>
                </Grid>
                <Grid item style={{ alignSelf: 'center' }}>
                  <PF2MClearFiltersButton
                    onClick={() => resetForm({
                      startDate: newMomentDate(),
                      endDate: newMomentDate(),
                      projectGroup: 0,
                      element: 0,
                      subElement: 0,
                    })}
                  />
                  <PF2MSearchButton
                    type="submit"
                    disabled={isSubmitting || !isValid}
                    onClick={null}
                  />
                </Grid>
              </Grid>
            </CardBody>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

SearchForm.propTypes = {
  classes: PropTypes.object.isRequired,
  onSearch: PropTypes.func,
  onClose: PropTypes.func,
};

SearchForm.defaultProps = {
  onSearch: null,
  onClose: null,
};

export default withStyles(styles)(SearchForm);

import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from '~/pages/Loads/styles';
import Footer from '~/pages/Manager/Footer';
import PF2MTable from '~/components/PF2MTable';
import {
  getMaterials,
  getLoadFactors,
  setLoadFactorValues,
  updateLoadFactors,
  addLoadFactor,
  resetLoadFactors,
  getElements,
  getSubElements,
} from '~/store/manager/actions';

import Header from './Header';

const LoadFactorTab = ({ classes }) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const dispatch = useDispatch();
  const materials = useSelector(state => state.manager.materials);
  const loadFactors = useSelector(state => state.manager.loadFactors);
  const elements = useSelector(state => state.manager.elements);
  const subElements = useSelector(state => state.manager.subElements);
  const isLoadFactorsDirty = useSelector(state => state.manager.isLoadFactorsDirty);
  const { t: translate } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      dispatch(getLoadFactors());
      dispatch(getMaterials());
      dispatch(getElements());
      dispatch(getSubElements());
    }

    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch]);

  const columns = [
    { field: 'start_date', title: translate('common:StartDate') },
    { field: 'end_date', title: translate('common:EndDate') },
    { field: 'element_name', title: translate('common:Element') },
    { field: 'element_point_name', title: translate('common:SubElement') },
    { field: 'material_name', title: translate('common:Material') },
    {
      field: 'value',
      title: translate('common:LoadFactor'),
      editable: true,
      type: 'decimal',
      decimalPrecision: 6,
    },
    {
      field: 'active',
      title: 'STATUS',
      editable: true,
      align: 'left',
    },
  ];

  return (
    <div className={classes.tabContainer}>
      <Header
        add={values => dispatch(addLoadFactor(values))}
        materials={materials}
        elements={elements}
        subElements={subElements}
        loadFactors={loadFactors}
      />
      <PF2MTable
        data={loadFactors}
        columns={columns}
        updateItem={(row, field, value) => dispatch(setLoadFactorValues(row, field, value))}
        className={classes.advanceTable}
      />
      <Footer
        isDirty={isLoadFactorsDirty}
        discard={() => dispatch(resetLoadFactors())}
        sendData={() => dispatch(updateLoadFactors(loadFactors))}
      />
    </div>
  );
};

LoadFactorTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

LoadFactorTab.defaultProps = {};

export default withStyles(styles)(LoadFactorTab);

import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from '../styles';
import Header from '~/pages/Manager/Header';
import Footer from '~/pages/Manager/Footer';
import PF2MTable from '~/components/PF2MTable';
import { useTranslation } from 'react-i18next';

import {
  getMaterialsTemplate,
  exportMaterials,
  importMaterials,
  getMaterials,
} from '~/store/manager/actions';

const MaterialsTab = ({
  classes,
  materials,
  getMaterialsTemplate: getTemplateAction,
  exportMaterials: exportAction,
  importMaterials: importAction,
  getMaterials: getAll,
}) => {
  const { t: translate } = useTranslation();
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    async function fetchOperators() {
      await getAll();
    }
    if (!dataLoaded) {
      fetchOperators();
      setDataLoaded(true);
    }
  }, [dataLoaded, getAll]);

  const columns = [
    { field: 'name', title: translate('common:Name') },
    { field: 'active_name', title: 'STATUS' },
    {
      field: 'group_name',
      title: translate('common:Group'),
      width: '100%',
      wrap: true,
    },
  ];

  return (
    <div className={classes.tabContainer}>
      <Header
        getTemplateAction={getTemplateAction}
        importAction={importAction}
        exportAction={exportAction}
      />
      <PF2MTable
        data={materials}
        columns={columns}
      />
      <Footer />
    </div>
  );
};

MaterialsTab.propTypes = {
  classes: PropTypes.object.isRequired,
  materials: PropTypes.array,
  getMaterialsTemplate: PropTypes.func,
  exportMaterials: PropTypes.func,
  importMaterials: PropTypes.func,
  getMaterials: PropTypes.func,
};

MaterialsTab.defaultProps = {
  materials: [],
  getMaterialsTemplate: () => {},
  exportMaterials: () => {},
  importMaterials: () => {},
  getMaterials: () => {},
};

const mapStateToProps = state => ({
  materials: state.manager.materials,
});

const mapDispatchToProps = {
  getMaterialsTemplate,
  exportMaterials,
  importMaterials,
  getMaterials,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MaterialsTab));

import React, { useState } from 'react';
import {
  withStyles,
  FormControl,
  FormLabel,
  FormGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import PF2MOutlinedInput from '~/components/PF2MOutlinedInput';
import PF2MAddButton from '~/components/PF2MAddButton';

function Header({
  classes,
  add,
  turnsGroups,
  handleTurnGroup,
}) {
  const { t: translate } = useTranslation();
  const [turnGroup, setTurnGroup] = useState(0);
  const INITIAL_VALUES = {
    turn_group: 0,
    intervals: 0,
  };

  const renderFilterTurnsGroups = () => [{ value: 0, label: translate('common:Select') }]
    .concat(...turnsGroups.filter(t => t.active === 1)
      .map(e => ({ value: e.id, label: e.name })));
  return (
    <Formik
      initialValues={INITIAL_VALUES}
      validate={(values) => {
        const errors = {};
        if (values.turn_group < 0) {
          errors.turn_group = 'Required';
        }
        if (values.intervals <= 0) {
          errors.name = 'Required';
        }
        return errors;
      }}
      onSubmit={async (values, { setFieldValue, setSubmitting }) => {
        const newItem = { ...values, turn_group: turnGroup };
        const turn = turnsGroups.find(t => t.id === turnGroup);
        await add({ ...newItem, turn_group_name: turn.name });
        // resetForm();
        setFieldValue('intervals', 0);
        setSubmitting(false);
      }}
    >
      {({
        isSubmitting,
        errors,
        isValid,
        handleChange,
      }) => (
        <Form>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{
              marginTop: 17,
              display: 'flex',
              alignItems: 'baseline',
              justifyContent: 'center',
              width: '100%',
            }}
            >
              <span className={classes.labelTypeSelector}>
                {translate('common:FilterByShiftGroup')}
              </span>
              <Field
                name="turn_group"
                render={({ field }) => (
                  <PF2MSearchSelectOutlined
                    {...field}
                    outline="true"
                    fullWidth={false}
                    input={<PF2MOutlinedInput />}
                    className={classes.typeSelector}
                    value={turnGroup}
                    onChange={async (e) => {
                      handleChange(e);
                      handleTurnGroup(e.target.value);
                      setTurnGroup(e.target.value);
                    }}
                  >
                    {renderFilterTurnsGroups()}
                  </PF2MSearchSelectOutlined>
                )}
              />
            </div>
            <FormControl style={{ width: 290, marginRight: 20 }}>
              <FormLabel className={classes.formLabel}>
                {translate('common:NumberOfIntervals')}
              </FormLabel>
              <FormGroup>
                <Field
                  type="number"
                  name="intervals"
                  min={0}
                  className={classnames('form-control', errors.description ? classes.fieldError : classes.field)}
                />
              </FormGroup>
            </FormControl>
            <div style={{ marginTop: 15 }}>
              <PF2MAddButton disabled={isSubmitting || !isValid} />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  add: PropTypes.func,
  turnsGroups: PropTypes.array,
  handleTurnGroup: PropTypes.func,
};

Header.defaultProps = {
  turnsGroups: [],
  add: null,
  handleTurnGroup: null,
};


export default withStyles(styles)(Header);

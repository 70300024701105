import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from '../../OperationType/styles';
import Footer from '~/pages/Manager/Footer';
import PF2MTable from '~/components/PF2MTable';
import {
  getTractorProductivities,
  addTractorProductivity,
  setTractorProductivityValues,
  resetTractorProductivities,
  updateTractorProductivities,
  getMaterials,
  getEquipmentsGroups,
  getEquipments,
} from '~/store/manager/actions';
import Header from './Header';

const TRACTOR = 13;

const TractorProductivityTab = ({
  classes,
  tractorProductivities,
  materials,
  equipmentsGroups,
  equipments,
  getEquipmentsGroups: loadEquipmentsGroups,
  getEquipments: loadEquipments,
  getMaterials: loadMaterials,
  getTractorProductivities: getAll,
  setTractorProductivityValues: updateItem,
  updateTractorProductivities: update,
  addTractorProductivity: add,
  resetTractorProductivities: reset,
  isTractorProductivitiesDirty,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const { t: translate } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      await getAll();
      await loadEquipmentsGroups();
      await loadEquipments();
      await loadMaterials();
    }

    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, getAll, loadMaterials, loadEquipmentsGroups, loadEquipments]);

  const columns = [
    {
      field: 'id_equip_group',
      title: translate('common:EquipmentGroup'),
      editable: true,
      selectItems: equipmentsGroups,
    },
    {
      field: 'id_equip',
      title: translate('common:Equipment'),
      editable: true,
      selectItems: equipments,
      filterKey: 'id_group',
      filterValue: 'id_equip_group',
    },
    {
      field: 'id_material',
      title: translate('common:Material'),
      editable: true,
      selectItems: materials,
    },
    {
      field: 'value',
      title: translate('common:Value'),
      editable: true,
      type: 'decimal',
    },
    {
      field: 'active',
      title: 'STATUS',
      editable: true,
      align: 'left',
    },
  ];

  return (
    <div className={classes.tabContainer}>
      <Header
        add={add}
        materials={materials}
        equipmentsGroups={equipmentsGroups}
        equipments={equipments}
      />
      <PF2MTable
        data={tractorProductivities}
        columns={columns}
        updateItem={updateItem}
      />
      <Footer
        isDirty={isTractorProductivitiesDirty}
        discard={() => reset()}
        sendData={() => update(tractorProductivities)}
      />
    </div>
  );
};

TractorProductivityTab.propTypes = {
  classes: PropTypes.object.isRequired,
  tractorProductivities: PropTypes.array,
  materials: PropTypes.array,
  equipmentsGroups: PropTypes.array,
  equipments: PropTypes.array,
  getMaterials: PropTypes.func,
  getEquipmentsGroups: PropTypes.func,
  getEquipments: PropTypes.func,
  getTractorProductivities: PropTypes.func,
  setTractorProductivityValues: PropTypes.func,
  updateTractorProductivities: PropTypes.func,
  addTractorProductivity: PropTypes.func,
  resetTractorProductivities: PropTypes.func,
  isTractorProductivitiesDirty: PropTypes.bool,
};

TractorProductivityTab.defaultProps = {
  tractorProductivities: [],
  materials: [],
  equipmentsGroups: [],
  equipments: [],
  getMaterials: () => {},
  getEquipmentsGroups: () => {},
  getEquipments: () => {},
  getTractorProductivities: () => {},
  setTractorProductivityValues: () => {},
  updateTractorProductivities: () => {},
  addTractorProductivity: () => {},
  resetTractorProductivities: () => {},
  isTractorProductivitiesDirty: false,
};

const mapStateToProps = state => ({
  equipmentsGroups: state.manager.equipmentsGroups.filter(
    e => e.id_equipament === TRACTOR,
  ),
  equipments: state.manager.equipments.filter(e => e.id_equip === TRACTOR),
  materials: state.manager.materials,
  tractorProductivities: state.manager.tractorProductivities,
  isTractorProductivitiesDirty: state.manager.isTractorProductivitiesDirty,
});

const mapDispatchToProps = {
  getTractorProductivities,
  setTractorProductivityValues,
  updateTractorProductivities,
  addTractorProductivity,
  resetTractorProductivities,
  getMaterials,
  getEquipmentsGroups,
  getEquipments,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(TractorProductivityTab));

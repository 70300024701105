import React, { useEffect, useState } from 'react';
import { withStyles, Modal } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles';
import SearchHeader from '../SearchHeader';
import MaintenanceReport from '../MaintenanceReport';
import SearchForm from '../SearchForm';
import MaintenanceDetails from '../MaintenanceDetails';
import { restoreOriginalUnderground } from '~/store/dailyparts/actions';
import { resetMaintenanceOperationItems } from '~/store/maintenance/actions';

const DailyParts = ({ classes }) => {
  const [searchModalOpen, setSearchModalOpen] = useState(true);
  // eslint-disable-next-line max-len
  const showMaintenanceDetailsModal = useSelector(state => state.maintenance.showMaintenanceDetailsModal);
  const dispatch = useDispatch();

  useEffect(() => () => {
    dispatch(restoreOriginalUnderground(true));
  }, [dispatch]);

  return (
    <div className={classes.container}>
      <SearchHeader handleModal={setSearchModalOpen} />
      <MaintenanceReport />
      <Modal
        open={searchModalOpen}
        onBackdropClick={() => setSearchModalOpen(false)}
      >
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        >
          <SearchForm
            onSearch={() => setSearchModalOpen(false)}
            onClose={() => setSearchModalOpen(false)}
          />
        </div>
      </Modal>
      <Modal
        open={showMaintenanceDetailsModal}
        onBackdropClick={() => dispatch(resetMaintenanceOperationItems())}
      >
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        >
          <MaintenanceDetails />
        </div>
      </Modal>
    </div>
  );
};

DailyParts.propTypes = {
  classes: PropTypes.object.isRequired,
};
DailyParts.defaultProps = {};

export default withStyles(styles)(DailyParts);

/* eslint-disable camelcase */
import React from 'react';
import {
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { DispatchDragNDropTypes } from '~/utils';
import styles from '../styles';
import PF2MScrollbar from '~/components/PF2MScrollbar';
import PF2MEquipIcon from '~/components/PF2MEquipIcon';
import PF2MDispatchIconMaker from '~/components/PF2MDispatchIconMaker';

const AllocationRow = ({
  subelement, perforators,
  onEquipClick,
  classes,
}) => {
  if (perforators.length === 0) {
    return null;
  }
  return (
    <div
      key={subelement.id}
      style={{
        display: 'flex',
        width: '100%',
        height: 110,
      }}
    >
      <div
        className={classes.subelementIcon}
      >
        <PF2MDispatchIconMaker
          iconName={'material'}
          settings={{
            iconSize: 100,
            alt: subelement.name,
          }}
        />
        <div
          className={classes.labelTypeSelectorSubelement}
          style={{ width: '100%', textAlign: 'center' }}
        >
          {subelement.name.slice(0, 15)}
        </div>
      </div>
      <PF2MScrollbar
        className={classes.scrollbars}
        style={{ width: '77%' }}
      >
        <div
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#dedede',
          }}
        >
          {perforators
            .map(perforator => (

              <PF2MEquipIcon
                key={perforator.id}
                item={{ ...perforator, equip_type_id: perforator.equipaments_id }}
                customStyles={{
                  marginLeft: 15,
                }}
                itemType={DispatchDragNDropTypes.PERFORATOR}
                onClick={() => onEquipClick(perforator)}
                reverse
              />

            ))}
        </div>
      </PF2MScrollbar>
    </div>
  );
};

AllocationRow.propTypes = {
  subelement: PropTypes.object,
  onEquipClick: PropTypes.func,
  perforators: PropTypes.array,
  classes: PropTypes.object,
};

AllocationRow.defaultProps = {
  subelement: {},
  onEquipClick: () => {},
  perforators: [],
  classes: {},
};

export default withStyles(styles)(AllocationRow);

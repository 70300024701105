import React from 'react';
import PropTypes from 'prop-types';
import { Select, FormControl, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import styles from './styles';
import PF2MOutlinedInput from '../PF2MOutlinedInput';

const PF2MSelectOutlined = ({
  children,
  classes,
  value,
  onChange,
  placeholder,
  fullWidth,
  className,
  ...props
}) => (
  <FormControl className={classes.noLabel}>
    <Select
      displayEmpty
      value={(value || []).toString()}
      onChange={onChange}
      className={classnames(classes.typeSelector, className)}
      outlined="true"
      input={<PF2MOutlinedInput />}
      {...props}
    >
      {placeholder && (
        <MenuItem key={0} value={value}>
          <em className={classes.placeholder}>{placeholder}</em>
        </MenuItem>
      )}
      {children}
    </Select>
  </FormControl>
);

PF2MSelectOutlined.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  classes: PropTypes.object,
  value: PropTypes.any,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
};

PF2MSelectOutlined.defaultProps = {
  children: [],
  classes: {},
  value: {},
  onChange: {},
  placeholder: '',
  fullWidth: true,
  className: '',
};

export default withStyles(styles)(PF2MSelectOutlined);

/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import {
  withStyles, FormControl, FormGroup, FormLabel,
} from '@material-ui/core';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { getFilterStates } from './FilterStates';
import styles from '../styles';

import {
  getSubElementTypes,
  getSubElements,
  getElements,
  getMaterialsGroups,
  getMaterials,
  getEquipmentTypes,
  getEquipmentsGroups,
  getEquipments,
} from '~/store/manager/actions';
import PF2MDatePickerOutlined from '~/components/PF2MDatePickerOutlined';

import PF2MDataTable from '~/components/PF2MDataTable';
import GoalsFilters from './GoalsFilters';
import {
  setGoalTableList, setGoalTableValue, updateGoals, getGoals, getGoalsTemplate, importGoals,
} from '~/store/goals/actions';


const Goals = ({
  classes,
}) => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const [fileUploadRef, setFileUploadRef] = useState(null);
  const [customColumns, setCustomColumns] = useState([]);
  const goalTable = useSelector(state => state.goals.goalTable);
  const selector = useSelector(state => state.manager);
  const [requestFilters, setRequestFilters] = useState({});
  const [mainFilters, setMainFilters] = useState({
    planning: 1,
    granularity: 1,
    date: moment(),
  });

  const defaultColumns = () => {
    const days = mainFilters.date.daysInMonth();
    const dayColumns = [...Array(days)].map((_, i) => ({
      field: `day${i + 1}`,
      title: `${i + 1}`,
    }));

    const custom = customColumns.map((c) => {
      const stateName = getFilterStates(translate)[c.mainTable].find(s => s.state).state;
      return {
        field: c.fieldName,
        title: c[`level_type${c.level}_name`],
        editable: 'always',
        lookup: selector[stateName],
      };
    });

    const columns = [...custom, ...dayColumns];
    return columns;
  };

  useEffect(() => {
    const cclength = customColumns.length;
    const absoluteFiltersValues = customColumns
      .reduce((a, c) => ([...a, c.cellFilters[c.mainState]
        .map(s => (s.value))]), []);

    if (cclength > 0) {
      const requestFiltersObj = {
        month: mainFilters.date.month() + 1,
        year: mainFilters.date.year(),
        level_type1_id: customColumns[cclength - 1].level_type1_id,
        level_type2_id: customColumns[cclength - 1].level_type2_id || 0,
        level_type3_id: customColumns[cclength - 1].level_type3_id || 0,
        level_type4_id: customColumns[cclength - 1].level_type4_id || 0,
        level_type5_id: customColumns[cclength - 1].level_type5_id || 0,
        level_type1_table_name: customColumns[cclength - 1].level_type1_table_name || '',
        level_type2_table_name: customColumns[cclength - 1].level_type2_table_name || '',
        level_type3_table_name: customColumns[cclength - 1].level_type3_table_name || '',
        level_type4_table_name: customColumns[cclength - 1].level_type4_table_name || '',
        level_type5_table_name: customColumns[cclength - 1].level_type5_table_name || '',
        level1_values: absoluteFiltersValues[0] || 0,
        level2_values: absoluteFiltersValues[1] || 0,
        level3_values: absoluteFiltersValues[2] || 0,
        level4_values: absoluteFiltersValues[3] || 0,
        level5_values: absoluteFiltersValues[4] || 0,
        goal_types_levels_link_id: customColumns[cclength - 1].id,
        goal_planning_types_id: mainFilters.planning,
        goal_granularity_types_id: mainFilters.granularity,
      };
      setRequestFilters(requestFiltersObj);
      dispatch(getGoals(requestFiltersObj));
    }
  }, [dispatch, customColumns, mainFilters, setRequestFilters]);

  const handleChange = (event) => {
    const currentFile = event.target.files[0];
    dispatch(importGoals(currentFile, requestFilters));
    fileUploadRef.value = null;
  };

  // essa função faz uma permutação das colunas em todas as combinações possíveis de linhas.
  const cartesian = (...args) => {
    const r = []; const
      max = args.length - 1;
    const helper = (arr, i) => {
      // eslint-disable-next-line no-plusplus
      for (let j = 0, l = args[i].length; j < l; j++) {
        const a = arr.slice(0); // clone arr
        a.push(args[i][j]);
        if (i === max) { r.push(a); } else { helper(a, i + 1); }
      }
    };
    helper([], 0);
    return r;
  };

  useEffect(() => {
    if (customColumns.length > 0) {
      const dataCols = cartesian(
        ...customColumns.map(c => (
          c.cellFilters[c.mainState]
        )),
      );

      const defaultData = {
        goal_planning_types_id: mainFilters.planning,
        goal_granularity_types_id: mainFilters.granularity,
        goal_date: mainFilters.date.format('1/M/YYYY00:00:00'),
        days_in_month: mainFilters.date.daysInMonth(),
      };

      const realData = dataCols.map((d, i) => {
        const obj = {
          ...defaultData,
        };
        d.forEach((c, j) => {
          const col = customColumns[j];
          obj.id = -1 - (i * (j + 1));
          obj.goal_types_levels_link_id = col.id;
          obj[col.fieldName] = c.value;
          obj[`level${col.level}_value`] = c.value;
        });
        return obj;
      });
      dispatch(setGoalTableList(realData));
    } else {
      dispatch(setGoalTableList([]));
    }
  }, [customColumns, mainFilters, dispatch]);


  return (
    <div className={classes.tabContainer}>
      <div className={classes.filterMenu}>
        <FormControl className={classes.typeSelector}>
          <FormLabel>
            {translate('common:Planning')}
          </FormLabel>
          <FormGroup>
            <PF2MSearchSelectOutlined
              className={classes.typeSelectorInput}
              placeholder={translate('common:Select')}
              value={mainFilters.planning}
              onChange={e => setMainFilters({ ...mainFilters, planning: e.target.value })}
            >
              {[
                { value: 1, label: translate('common:Short') },
                { value: 2, label: translate('common:Medium') },
                { value: 3, label: translate('common:Long') },
              ]}
            </PF2MSearchSelectOutlined>
          </FormGroup>
        </FormControl>
        <FormControl className={classes.typeSelector}>
          <FormLabel>
            {translate('common:Granularity')}
          </FormLabel>
          <FormGroup>
            <PF2MSearchSelectOutlined
              className={classes.typeSelectorInput}
              placeholder={translate('common:Select')}
              value={mainFilters.granularity}
              onChange={e => setMainFilters({ ...mainFilters, granularity: e.target.value })}
            >
              {[
                { value: 1, label: translate('common:Month') },
                { value: 2, label: translate('common:Year') },
              ]}
            </PF2MSearchSelectOutlined>
          </FormGroup>
        </FormControl>
        <FormControl className={classes.typeSelector}>
          <FormLabel>
            {translate('common:Year')}
          </FormLabel>
          <PF2MDatePickerOutlined
            disableFuture={false}
            openTo="year"
            views={['year']}
            format="YYYY"
            value={mainFilters.date}
            onChange={(date) => {
              setMainFilters({ ...mainFilters, date: mainFilters.date.set('year', date.get('year')) });
            }}
          />
        </FormControl>
        <FormControl className={classes.typeSelector}>
          <FormLabel>
            {translate('common:Month')}
          </FormLabel>
          <FormControl>
            <PF2MDatePickerOutlined
              disableFuture={false}
              openTo="month"
              views={['month']}
              format="MM"
              value={mainFilters.date}
              onChange={(date) => {
                setMainFilters({ ...mainFilters, month: mainFilters.date.set('month', date.get('month')) });
              }}
            />
          </FormControl>
        </FormControl>
      </div>
      <div
        style={{
          position: 'relative',
          width: 'calc(100vw - 180px)',
        }}
      >
        <DndProvider backend={HTML5Backend}>
          <GoalsFilters setCustomColumns={setCustomColumns} />
        </DndProvider>
        <input
          ref={ref => setFileUploadRef(ref)}
          type="file"
          style={{ display: 'none' }}
          onChange={handleChange}
        />

        <PF2MDataTable
          style={{ paddingLeft: 30 }}
          columns={defaultColumns()}
          data={goalTable}
          onExport={() => { dispatch(getGoalsTemplate(requestFilters, goalTable)); }}
          onImport={() => {
            fileUploadRef.click();
          }}
          options={{
            maxBodyHeight: 'calc(100vh - 350px)',
            minBodyHeight: 'calc(100vh - 350px)',
            hideDelete: true,
            hideAdd: true,
            selection: true,
          }}
          onApply={() => {
            dispatch(updateGoals(goalTable, () => {}));
          }}
          onChange={(newData) => {
            newData.forEach((row) => {
              dispatch(setGoalTableValue(row));
            });
          }}
        />
      </div>
    </div>
  );
};

Goals.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  subElementTypes: state.manager.subElementTypes,
  subElements: state.manager.subElements,
  elements: state.manager.elements,
  equipments: state.manager.equipments,
  equipmentTypes: state.manager.equipmentTypes,
  equipmentsGroups: state.manager.equipmentsGroups,
  materials: state.manager.materials,
  materialsGroups: state.manager.materialsGroups,
});

const mapDispatchToProps = {
  getSubElementTypes,
  getSubElements,
  getElements,
  getEquipments,
  getEquipmentTypes,
  getEquipmentsGroups,
  getMaterials,
  getMaterialsGroups,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Goals));

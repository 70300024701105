import * as types from './types';

const INITIAL_STATE = {
  fuels: [],
  fuelRemainingAlert: [],
  originalFuelRemainingAlert: [],
  isFuelRemainingAlertValuesDirty: false,
  isCodesEquipmentLinkMapsDirty: false,
  originalCodesEquipmentLinkMaps: [],
  codesEquipmentLinkMaps: [],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_FUEL_LIST: {
      return {
        ...state,
        fuels: action.payload,
      };
    }
    case types.SET_FUEL_REMAINING_ALERT: {
      const item = {
        id: 0,
        ...action.payload,
      };
      return {
        ...state,
        isFuelRemainingAlertValuesDirty: false,
        fuelRemainingAlert: [item],
        originalFuelRemainingAlert: [item],
      };
    }
    case types.RESET_FUEL_REMAINING_ALERTS: {
      return {
        ...state,
        fuelRemainingAlert: state.originalFuelRemainingAlert,
        isFuelRemainingAlertValuesDirty: false,
      };
    }
    case types.SET_FUEL_REMAINING_ALERT_VALUES: {
      const { yellow, red, purple } = state.fuelRemainingAlert[0];
      const { yellow: newYellow, red: newRed, purple: newPurple } = action.payload;
      const isDirty = ((newYellow !== yellow) || (newRed !== red) || (newPurple !== purple));
      const newItem = {
        isDirty,
        ...action.payload,
      };

      const newData = [newItem];
      const isFuelRemainingAlertValuesDirty = newData.some(d => d.isDirty);
      return {
        ...state,
        isFuelRemainingAlertValuesDirty,
        fuelRemainingAlert: newData,
      };
    }
    case types.SET_CODES_EQUIPMENT_LINK_MAPS_LIST: {
      return {
        ...state,
        isCodesEquipmentLinkMapsDirty: false,
        originalCodesEquipmentLinkMaps: action.payload,
        codesEquipmentLinkMaps: action.payload,
      };
    }
    case types.RESET_CODES_EQUIPMENT_LINK_MAPS: {
      return {
        ...state,
        isCodesEquipmentLinkMapsDirty: false,
        codesEquipmentLinkMaps: [...state.originalCodesEquipmentLinkMaps],
      };
    }
    case types.SET_CODES_EQUIPMENT_LINK_MAP_VALUES: {
      const {
        equipmentGroup = 0,
        item = null,
      } = action.payload;
      const codesMap = state.codesEquipmentLinkMaps
        .find(m => m.id_equip_group === equipmentGroup) || {};
      const {
        codes = [],
        id = (0 - state.codesEquipmentLinkMaps.length),
      } = codesMap;

      let newCodesMaps = [];

      if (item) {
        if (item.checked) {
          newCodesMaps = [...codes, item.id];
        } else {
          newCodesMaps = codes.filter(m => m !== item.id);
        }
      } else {
        newCodesMaps = [...codes];
      }
      let newData = [];
      const newItem = {
        id,
        id_equip_group: equipmentGroup,
        codes: newCodesMaps,
        isDirty: !(action.payload.id < 1),
        update_status: action.payload.update_status === 'U' ? 'U' : 'I',
      };

      if (!codesMap) {
        newData = [...state.codesEquipmentLinkMaps, newItem];
      } else {
        newData = [newItem, ...state.codesEquipmentLinkMaps
          .filter(s => s.id_equip_group !== equipmentGroup)];
      }

      const isCodesEquipmentLinkMapsDirty = newData.some(d => d.update_status);

      return {
        ...state,
        isCodesEquipmentLinkMapsDirty,
        codesEquipmentLinkMaps: newData,
      };
    }
    default: {
      return state;
    }
  }
}

import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import {
  getEquipmentTypes,
  getCodes,
  getCodeTypes,
  getCodeGroups,
  getCodesMigrationRules,
  addCodeMigrationRules,
  setCodeMigrationRulesValues,
  resetCodeMigrationRules,
  updateCodeMigrationRules,
} from '~/store/manager/actions';
import PF2MDataTable from '~/components/PF2MDataTable';

const MigrationCodesRulesTab = ({
  classes,
  rules,
  codes,
  equipmentTypes,
  codeTypes,
  codeGroups,
  getEquipmentTypes: loadEquipmentTypes,
  getCodeTypes: loadCodeTypes,
  getCodeGroups: loadCodeGroups,
  getCodes: codeList,
  getCodesMigrationRules: getRules,
  addCodeMigrationRules: addRules,
  setCodeMigrationRulesValues: updateItem,
  resetCodeMigrationRules: reset,
  updateCodeMigrationRules: update,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const { t: translate } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      codeList(true);
      loadEquipmentTypes();
      loadCodeTypes();
      getRules();
      await loadCodeGroups();
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [
    dataLoaded,
    codeList,
    loadCodeGroups,
    loadCodeTypes,
    loadEquipmentTypes,
    getRules,
    rules,
  ]);

  const data = rules
    .filter(e => e.status === true)
    .map(e => ({
      ...e,
      id_equip: codes.find(el => el.id_pk === e.origin_codes_id)?.id_equip,
      origin_code_type: codes.find(el => el.id_pk === e.origin_codes_id)
        ?.code_type,
      origin_code_group: codes.find(el => el.id_pk === e.origin_codes_id)
        ?.id_group,
      destination_code_type: codes.find(
        el => el.id_pk === e.destination_codes_id,
      )?.code_type,
      destination_code_group: codes.find(
        el => el.id_pk === e.destination_codes_id,
      )?.id_group,
      max_time_in_origin_code: e.max_time_in_origin_code / 60,
    }));

  const columns = [
    {
      editable: 'always',
      title: translate('common:EquipmentType'),
      field: 'id_equip',
      lookup: equipmentTypes,
    },
    {
      editable: 'always',
      title: translate('common:OriginCodeType'),
      field: 'origin_code_type',
      lookup: codeTypes.map(item => ({
        ...item,
        name: translate(`common:${item.name}`),
      })),
    },
    {
      editable: 'always',
      title: translate('common:OriginCodeGroup'),
      field: 'origin_code_group',
      customType: 'filterLookup',
      lookupFilter: (codeGroupOption, row) => (String(codeGroupOption.code_type)
      === String(row.origin_code_type)
        && String(row.id_equip)
        === String(codeGroupOption.id_equip)),
      lookup: codeGroups,
    },
    {
      editable: 'always',
      title: translate('common:OriginCode'),
      field: 'origin_codes_id',
      lookup: codes,
      customType: 'filterLookup',
      lookupKey: 'id_pk',
      lookupFilter: (codeOption, row) => {
        // No code Group Selected
        if (String(row.origin_code_group) === '0') {
          return (
            String(row.origin_code_type) === String(codeOption.code_type)
            && String(row.id_equip) === String(codeOption.id_equip)
          );
        }
        return (
          String(codeOption.id_group) === String(row.origin_code_group)
          && String(row.id_equip) === String(codeOption.id_equip)
        );
      },
    },
    {
      editable: 'always',
      customType: 'number',
      title: translate('common:MaxTimeInOriginCode'),
      field: 'max_time_in_origin_code',
    },
    {
      editable: 'always',
      title: translate('common:DestinationCodeType'),
      field: 'destination_code_type',
      lookup: codeTypes.map(item => ({
        ...item,
        name: translate(`common:${item.name}`),
      })),
    },
    {
      editable: 'always',
      title: translate('common:DestinationCodeGroup'),
      field: 'destination_code_group',
      customType: 'filterLookup',
      lookupFilter: (codeGroupOption, row) => String(codeGroupOption.code_type)
          === String(row.destination_code_type)
        && String(row.id_equip) === String(codeGroupOption.id_equip),
      lookup: codeGroups,
    },
    {
      editable: 'always',
      title: translate('common:DestinationCode'),
      field: 'destination_codes_id',
      lookup: codes,
      lookupKey: 'id_pk',
      customType: 'filterLookup',
      lookupFilter: (codeOption, row) => {
        // No code Group Selected
        if (String(row.destination_code_group) === '0') {
          return (
            String(row.destination_code_type)
              === String(codeOption.code_type)
            && String(row.id_equip) === String(codeOption.id_equip)
          );
        }
        return (
          String(codeOption.id_group) === String(row.destination_code_group)
          && String(row.id_equip) === String(codeOption.id_equip)
        );
      },
    },
  ];

  return (
    <div className={classes.tabContainer}>
      <hr style={{ margin: '0px' }} />
      <PF2MDataTable
        title={translate('common:Codes')}
        options={{
          showTitle: false,
          selection: true,
          pageSize: 20,
          pageSizeOptions: [20, 50, 100],
          hideDelete: false,
          // editableRowValidation: rowData => (rowData.id < 0 && rowData.update_status !== 'I'),
          maxBodyHeight: 'calc(100vh - 350px)',
          minBodyHeight: 'calc(100vh - 350px)',
        }}
        onAdd={() => {
          addRules({
            id: -Math.floor(Date.now() / 1000),
            origin_codes_id: 98,
            destination_codes_id: 98,
            max_time_in_origin_code: 600,
            status: true,
            update_status: 'I',
          });
        }}
        onChange={(newData) => {
          newData.forEach((row) => {
            const item = {
              id: row.id,
              origin_codes_id: row.origin_codes_id,
              destination_codes_id: row.destination_codes_id,
              max_time_in_origin_code: row.max_time_in_origin_code * 60,
              status: row.status,
            };
            Object.keys(item).forEach((key) => {
              updateItem(item, key, item[key]);
            });
          });
        }}
        onDiscard={() => reset()}
        onApply={() => {
          update(rules);
        }}
        onDelete={(items) => {
          items.forEach((row) => {
            const item = {
              id: row.id,
              origin_codes_id: row.origin_codes_id,
              destination_codes_id: row.destination_codes_id,
              max_time_in_origin_code: row.max_time_in_origin_code * 60,
              status: false,
            };
            updateItem(item, 'status', false);
          });
        }}
        columns={columns}
        data={data}
      />
    </div>
  );
};

MigrationCodesRulesTab.propTypes = {
  classes: PropTypes.object.isRequired,
  rules: PropTypes.array,
  codes: PropTypes.array,
  equipmentTypes: PropTypes.array,
  codeTypes: PropTypes.array,
  codeGroups: PropTypes.array,
  getEquipmentTypes: PropTypes.func,
  getCodes: PropTypes.func,
  getCodeTypes: PropTypes.func,
  getCodeGroups: PropTypes.func,
  getCodesMigrationRules: PropTypes.func,
  addCodeMigrationRules: PropTypes.func,
  setCodeMigrationRulesValues: PropTypes.func,
  resetCodeMigrationRules: PropTypes.func,
  updateCodeMigrationRules: PropTypes.func,
};

MigrationCodesRulesTab.defaultProps = {
  rules: [],
  codes: [],
  equipmentTypes: [],
  codeTypes: [],
  codeGroups: [],
  getEquipmentTypes: () => {},
  getCodes: () => {},
  getCodeTypes: () => {},
  getCodeGroups: () => {},
  getCodesMigrationRules: () => {},
  addCodeMigrationRules: () => {},
  setCodeMigrationRulesValues: () => {},
  resetCodeMigrationRules: () => {},
  updateCodeMigrationRules: () => {},
};

const mapStateToProps = state => ({
  equipmentTypes: state.manager.equipmentTypes,
  rules: state.manager.codeMigrationRules,
  codes: state.manager.codes,
  codeTypes: state.manager.codeTypes,
  codeGroups: state.manager.codeGroups,
  isCodeMigrationRulesDirty: state.manager.isCodeMigrationRulesDirty,
});

const mapDispatchToProps = {
  getEquipmentTypes,
  getCodes,
  getCodeTypes,
  getCodeGroups,
  getCodesMigrationRules,
  addCodeMigrationRules,
  setCodeMigrationRulesValues,
  resetCodeMigrationRules,
  updateCodeMigrationRules,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(MigrationCodesRulesTab));

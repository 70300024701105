import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PF2MTable from '~/components/PF2MTable';
import Footer from '~/pages/Manager/Footer';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import {
  getFuelRemainingAlerts,
  resetFuelRemainingAlerts,
  updateFuelRemainingAlerts,
  setFuelRemainingAlertsValue,
} from '~/store/fuel/actions';

const AlarmTab = ({
  classes,
  fuelRemainingAlert,
  getFuelRemainingAlerts: getAll,
  resetFuelRemainingAlerts: reset,
  updateFuelRemainingAlerts: update,
  setFuelRemainingAlertsValue: updateItem,
  isFuelRemainingAlertValuesDirty,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const { t: translate } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      await getAll();
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, getAll]);

  const columns = [
    {
      field: 'green', title: translate('common:GreenPercentage'), width: '100px',
    },
    {
      field: 'yellow', title: translate('common:YellowPercentage'), width: '100px', editable: true, type: 'number',
    },
    {
      field: 'red', title: translate('common:RedPercentage'), width: '100px', editable: true, type: 'number',
    },
    {
      field: 'purple', title: translate('common:PurplePercentage'), width: '100px', editable: true, type: 'number',
    },
    { field: '', title: '', width: '100px' },
  ];

  return (
    <div className={classes.tabContainer}>
      <PF2MTable
        data={fuelRemainingAlert}
        columns={columns}
        className={classes.grid}
        updateItem={updateItem}
      />
      <Footer
        isDirty={isFuelRemainingAlertValuesDirty}
        discard={() => reset()}
        sendData={() => update(fuelRemainingAlert)}
      />
    </div>
  );
};

AlarmTab.propTypes = {
  classes: PropTypes.object.isRequired,
  fuelRemainingAlert: PropTypes.array,
  getFuelRemainingAlerts: PropTypes.func,
  resetFuelRemainingAlerts: PropTypes.func,
  updateFuelRemainingAlerts: PropTypes.func,
  isFuelRemainingAlertValuesDirty: PropTypes.bool,
  setFuelRemainingAlertsValue: PropTypes.func,
};

AlarmTab.defaultProps = {
  fuelRemainingAlert: [],
  getFuelRemainingAlerts: null,
  resetFuelRemainingAlerts: null,
  updateFuelRemainingAlerts: null,
  setFuelRemainingAlertsValue: null,
  isFuelRemainingAlertValuesDirty: false,
};

const mapStateToProps = state => ({
  fuelRemainingAlert: state.fuel.fuelRemainingAlert,
  isFuelRemainingAlertValuesDirty: state.fuel.isFuelRemainingAlertValuesDirty,
});

const mapDispatchToProps = {
  getFuelRemainingAlerts,
  resetFuelRemainingAlerts,
  updateFuelRemainingAlerts,
  setFuelRemainingAlertsValue,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AlarmTab));

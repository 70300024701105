import React, { useState, useEffect } from 'react';
import {
  withStyles, FormControl, FormLabel, FormGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import Footer from '~/pages/Maintenance/Footer';
import Header from '~/pages/Maintenance/Header';
import PF2MTable from '~/components/PF2MTable';
import {
  getSystems,
  addSystem,
  setSystemValue,
  updateSystems,
  resetSystems,
  getSystemsTemplate,
  importSystems,
  exportSystems,
} from '~/store/maintenance/actions';
import PF2MAddButton from '~/components/PF2MAddButton';

const validateName = (items, name) => {
  if (items.some(c => c.name === name)) {
    return false;
  }
  return true;
};

const SystemsTab = ({
  classes,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const isSystemsDirty = useSelector(state => state.maintenance.isSystemsDirty);
  const systems = useSelector(state => state.maintenance.systems);
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      await dispatch(getSystems());
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch]);

  const columns = [
    {
      field: 'name', title: translate('common:Name'), editable: true, updateOnChange: true,
    },
    {
      field: 'description',
      title: translate('common:Description'),
      width: '100%',
      editable: true,
      updateOnChange: true,
    },
    {
      field: 'active',
      title: 'STATUS',
      editable: true,
    },
  ];

  const renderHeader = () => (
    <Formik
      initialValues={{ name: '', description: '' }}
      validate={(values) => {
        const errors = {};
        if (!values.name) {
          errors.name = translate('validation:RequiredField');
        }
        if (values.description <= 0) {
          errors.description = translate('validation:RequiredField');
        }
        if (!validateName(systems, values.name)) {
          errors.name = translate('validation:AlreadyRegisteredName');
        }
        return errors;
      }}
      onSubmit={async (values, { resetForm }) => {
        await dispatch(addSystem(values));
        resetForm();
      }}
    >
      {({
        isSubmitting,
        errors,
        isValid,
      }) => (
        <Form style={{ display: 'flex', minHeight: 75 }}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              {`${translate('common:Name')}:`}
            </FormLabel>
            <FormGroup>
              <Field
                type="text"
                name="name"
                className={classnames('form-control', errors.name ? classes.fieldError : classes.field)}
              />
              <ErrorMessage
                name="name"
                component="span"
                className={classes.errorMessage}
              />
            </FormGroup>
          </FormControl>
          <FormControl className={classes.fieldDescription}>
            <FormLabel className={classes.formLabel}>
              {`${translate('common:Description')}:`}
            </FormLabel>
            <FormGroup>
              <Field
                type="text"
                name="description"
                className={classnames('form-control', errors.name ? classes.fieldError : classes.field)}
              />
              <ErrorMessage
                name="description"
                component="span"
                className={classes.errorMessage}
              />
            </FormGroup>
          </FormControl>
          <div style={{ marginTop: 15 }}>
            <PF2MAddButton disabled={isSubmitting || !isValid} />
          </div>
        </Form>
      )}
    </Formik>
  );

  return (
    <div className={classes.tabContainer}>
      <Header
        getTemplateAction={() => dispatch(getSystemsTemplate())}
        importAction={file => dispatch(importSystems(file))}
        exportAction={() => dispatch(exportSystems())}
      />
      {renderHeader()}
      <PF2MTable
        className={classes.table}
        data={systems}
        columns={columns}
        updateItem={(row, field, value) => dispatch(setSystemValue(row, field, value))}
      />
      <Footer
        isDirty={isSystemsDirty}
        discard={() => dispatch(resetSystems())}
        sendData={() => dispatch(updateSystems(systems))}
      />
    </div>
  );
};

SystemsTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

SystemsTab.defaultProps = {};

export default withStyles(styles)(SystemsTab);

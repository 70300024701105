/**
 * Replicate this component to separate business logic from ui
 * as its in PF2MEquipIcon/index.js
 *
 * TODO: apply the steps of the overwrite strategy
 * Strategy is:
 *  [] create this reusable component
 *  [] overwrite the original component
 *  [] transform this file in index.js
 */
import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './styles';
import { DispatchDragNDropTypes } from '~/utils';
import PF2MDispatchIconMaker from '../PF2MDispatchIconMaker';

const PF2MEquipIcon = React.forwardRef((
  {
    item,
    itemType,
    classes,
    reverse,
    name = '',
    header = '',
    onContextMenu = null,
    icon,
    ...rest
  },
  ref,
) => (
  <div
    onContextMenu={event => onContextMenu && onContextMenu(event, item)}
    className={classes.equipmentIconContainer}
    ref={ref}
    {...rest}
  >
    {header || null}
    <PF2MDispatchIconMaker
      iconName={icon}
      settings={{
        iconSize: 70,
        reverse,
        alt: item.equip_name,
      }}
    />
    {name || null}
  </div>
));

PF2MEquipIcon.propTypes = {
  classes: PropTypes.object,
  customStyles: PropTypes.object,
  itemType: PropTypes.string,
  item: PropTypes.object,
  onClick: PropTypes.func,
  reverse: PropTypes.bool,
  onContextMenu: PropTypes.func,
  header: PropTypes.string,
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

PF2MEquipIcon.defaultProps = {
  classes: {},
  customStyles: {},
  itemType: DispatchDragNDropTypes.TRUCK,
  item: {},
  onClick: () => null,
  reverse: false,
  header: '',
  onContextMenu: null,
};

export default withStyles(styles)(PF2MEquipIcon);

import { managerGridHeight } from '~/utils';

const styles = {
  container: {
    display: 'flex',
    backgroundColor: '#F9F9F9',
    height: managerGridHeight,
    padding: 10,
  },
  icon: {
    marginRight: 10,
  },
  button: {
    color: '#FFF',
    margin: 5,
  },
  tabContainer: {
    borderRadius: 5,
    borderTopLeftRadius: 0,
    backgroundColor: 'white',
    padding: 10,
  },
  field: {
    // border: '1px solid #CDCDCD',
    // borderRadius: 5,
    height: 40,
  },
  fieldError: {
    border: '1px solid red !important',
    borderRadius: 5,
    height: 40,
  },
  classGroupName: {
    flex: 1,
    minWidth: 290,
    marginLeft: 32,
    marginRight: 32,
  },
  formLabel: {
    color: '#647886',
    fontSize: 12,
    fontFamily: 'Roboto',
    marginBottom: 5,
  },
  errorMessage: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 12,
    textAlign: 'center',
    color: '#EC3337',
  },
  containerTypeSelector: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'center',
    width: '100%',
    padding: '10px 0px',
  },
  labelTypeSelector: {
    color: '#647886',
    fontFamily: 'Barlow Condensed',
    fontWeight: 600,
    fontSize: 18,
    textTransform: 'uppercase',
    marginRight: 10,
    marginTop: 12,
  },
  typeSelector: {
    height: 40,
    fontFamily: 'Roboto',
    fontWeight: 300,
    fontSize: 14,
    color: '#647886', // '#CDCDCD',
  },
  modalContainer: {
    justifyContent: 'space-between',
    paddingTop: 10,
    paddingBottom: 10,
  },
  paper: {
    position: 'absolute',
    width: 600,
    backgroundColor: 'white',
    // boxShadow: theme.shadows[5],
    padding: 20,
    outline: 'none',
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  calendars: {
    width: '100%',
    overflowY: 'auto',
    height: 'calc(100vh - 52px - 52px - 210px)',
  },
  table: {
    minWidth: 700,
  },
  dayOffTitle: {
    fontFamily: 'Barlow Condensed',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 18,
    textTransform: 'uppercase',
    color: '#647886',
  },
};

export default styles;

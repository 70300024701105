import React from 'react';
import { Card, Typography, withStyles } from '@material-ui/core';
import { withModal } from '~/components/PF2MModal';
import { ModalPropTypes } from '~/components/PF2MModal/withModal';
import { useTranslation } from 'react-i18next';
import { CardBody, CardHeader, CardIcon } from '~/components/Card';
import CloseIcon from '@material-ui/icons/Close';
import Send from '@material-ui/icons/Send';
import PF2MButton from '~/components/PF2MButton';
import classNames from 'classnames';
import styles from './styles';

const DynamicModal = React.forwardRef(({
  closeModal, modalData, classes,
}, _ref) => {
  const { t: translate } = useTranslation();

  return (
    <Card className={classes.modal}>
      <CardHeader icon>
        <div>
          <CardIcon
            onClick={closeModal}
            style={{
              float: 'right',
              cursor: 'pointer',
              background: '#F6F5EE',
              border: '2px solid #111110',
              boxShadow: 'none',
              color: '#111110',
              borderRadius: 50,
            }}
          >
            <CloseIcon />
          </CardIcon>
        </div>
      </CardHeader>
      <Typography variant="h4" align="center" className={classNames(classes.cardTitle, classes.modalTitle)}>
        {modalData === 'DYNAMIC' ? 'Dynamic' : translate('common:UnavailableModule')}
      </Typography>
      <CardBody className={classes.modalBody}>
        <div>
          <Typography className={classes.modalText}>
            {modalData === 'DYNAMIC' ? translate('tips:DynamicText') : translate('tips:NoModuleText')}
          </Typography>
          {modalData === 'DYNAMIC' && (
            <PF2MButton
              className={classes.knowMoreButton}
              onClick={() => window.open('https://materiais.fast2mine.com/dynamic', '_blank')}
            >
              <Send />
              {translate('common:KnowMore')}
            </PF2MButton>
          )}
        </div>

        <div style={{ margin: 40 }} />

      </CardBody>
    </Card>
  );
});

DynamicModal.propTypes = {
  ...ModalPropTypes,
};

export default withModal(withStyles(styles)(DynamicModal));

/*eslint-disable */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEquipments, getPlatformConfiguration } from '~/store/manager/actions';
import { setSocketConnection } from '../../store/chat/actions';


const SocketioConnection = () => {
  const dispatch = useDispatch();

  const socketEnableToggle = useSelector(state => state.manager.platformConfiguration
    .find(e => e.key === 'enable_chat')?.value);

  useEffect(() => {
    dispatch(getPlatformConfiguration());
    dispatch(getEquipments(true));
  }, []);

  useEffect(() => {
    if (socketEnableToggle === '1') {
      dispatch(setSocketConnection());
    }
  }, [socketEnableToggle]);

  return (
    <>
    </>
  );
};

export default SocketioConnection;

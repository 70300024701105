/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import {
  withStyles, FormControl, FormLabel, FormGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Footer from '~/pages/Manager/Footer';
import { useTranslation } from 'react-i18next';
import {
  getLoadAllocations,
  importLoadAllocation,
  getLoadAllocationEdit,
  getLoadAllocationTemplate,
} from '~/store/lineup/actions';
import styles from '../styles';
import { getElements, getSubElements, getEquipments } from '~/store/manager/actions';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import Header from '~/pages/Manager/Header';
import PF2MTable from '~/components/PF2MTable';
import { newMomentDate } from '~/utils/moment';
import PF2MDatePickerOutlined from '~/components/PF2MDatePickerOutlined';

const LoadAllocationsTab = ({
  classes,
}) => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [selectedElement, setSelectedElement] = useState(0);
  const [selectedSubelement, setSelectedSubelement] = useState(0);
  const [dateFilter, setDateFilter] = useState(newMomentDate());
  const selectedLineup = useSelector(state => state.lineup.selectedLineup);
  const loadAllocations = useSelector(state => state.lineup.loadAllocations);
  const elements = useSelector(state => state.manager.elements);
  const subElements = useSelector(state => state.manager.subElements);
  const equipments = useSelector(state => state.manager.equipments);

  useEffect(() => {
    async function fetchData() {
      dispatch(getElements());
      dispatch(getSubElements());
      dispatch(getEquipments());
    }

    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch]);

  useEffect(() => {
    if (selectedLineup && dateFilter) {
      dispatch(getLoadAllocations(selectedLineup.id, dateFilter.format('DD/MM/YYYY')));
    }
  }, [selectedLineup, dateFilter, dispatch]);

  const columns = [
    {
      field: 'start_date',
      title: translate('common:StartDate'),
      editFunc: () => false,
      datePicker: true,
    },
    {
      field: 'equipment_id',
      title: translate('common:Equipment'),
      selectItems: equipments,
      editFunc: () => false,
    },
    {
      field: 'element_id',
      title: translate('common:Element'),
      selectItems: elements,
      editFunc: () => false,
    },
    {
      field: 'element_point_id',
      title: translate('common:SubElement'),
      selectItems: subElements,
      editFunc: () => false,
    },
  ];

  const renderFilters = () => (
    <div style={{
      display: 'grid',
      gridTemplateColumns: 'repeat(5, 200px)',
      gridColumnGap: 10,
      gridRowGap: 10,
      margin: '10px 0',
    }}
    >
      <FormControl>
        <FormLabel className={classes.formLabel}>
          {translate('common:InitialDate')}
        </FormLabel>
        <FormGroup>
          <PF2MDatePickerOutlined
            helperText=""
            disableFuture={false}
            InputProps={{
              style: {
                minWidth: 150,
                height: 40,
              },
            }}
            value={dateFilter}
            onChange={(e) => {
              setDateFilter(e);
            }}
          />
        </FormGroup>
      </FormControl>
      <FormControl>
        <FormLabel className={classes.formLabel}>
          {translate('common:Element')}
        </FormLabel>
        <FormGroup>
          <PF2MSearchSelectOutlined
            value={selectedElement}
            onChange={(e) => {
              setSelectedElement(e.target.value);
            }}
            className={classes.fieldSelect}
          >
            {[{ value: 0, label: translate('common:Select') }]
              .concat(...elements
                .map(e => ({ value: e.id, label: e.name })))}
          </PF2MSearchSelectOutlined>
        </FormGroup>
      </FormControl>
      <FormControl>
        <FormLabel className={classes.formLabel}>
          {translate('common:SubElement')}
        </FormLabel>
        <FormGroup>
          <PF2MSearchSelectOutlined
            value={selectedSubelement}
            onChange={(e) => {
              setSelectedSubelement(e.target.value);
            }}
            className={classes.fieldSelect}
          >
            {[{ value: 0, label: translate('common:Select') }]
              .concat(...subElements
                .filter(e => e.id_element === selectedElement || selectedElement === 0)
                .map(e => ({ value: e.id, label: e.name })))}
          </PF2MSearchSelectOutlined>
        </FormGroup>
      </FormControl>
    </div>
  );

  return (
    <div className={classes.tabContainer}>
      <Header
        importAction={(file) => {
          dispatch(importLoadAllocation(file));
          setTimeout(() => {
            setDataLoaded(false);
            dispatch(getLoadAllocations(selectedLineup.id, dateFilter.format('DD/MM/YYYY')));
          }, 2000);
        }}
        getTemplateAction={() => dispatch(getLoadAllocationTemplate())}
        exportAction={() => dispatch(getLoadAllocationEdit(selectedLineup.id, dateFilter.format('DD-MM-YYYY')))}
      />
      {renderFilters()}
      <PF2MTable
        data={loadAllocations
          .filter(e => (selectedElement === e.element_id || selectedElement === 0)
          && (selectedSubelement === e.element_point_id || selectedSubelement === 0))
        }
        columns={columns}
      />
      <Footer
        isDirty={false}
        sendData={() => {}}
      />
    </div>
  );
};

LoadAllocationsTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

LoadAllocationsTab.defaultProps = {
};

export default withStyles(styles)(LoadAllocationsTab);

/* eslint-disable no-param-reassign */
import * as types from './types';
import api from '~/services/api';
import { handleErrors } from '../common';
import { convertToUrlParameters, downloadAxios } from '~/utils';

const getEndpoints = {
  allTabletsList: {
    url: '/admin/alltablets',
    responseObject: 'all_tablets',
    typeAction: types.GET_ALL_TABLETS,
  },
  apkVersion: {
    url: '/admin/apkversion',
    responseObject: 'apk_version',
    typeAction: types.GET_APK_VERSION,
  },
  getTabletUpdate: {
    url: '/admin/tabletupdate',
    responseObject: 'tablet_update',
    typeAction: types.GET_TABLET_UPDATE,
  },
  postTabletUpdate: {
    url: '/admin/tabletupdate',
    responseObject: 'tablet_update',
    typeAction: types.POST_TABLET_UPDATE,
  },
  getTabletControlSync: {
    url: '/admin/controlsync',
    responseObject: 'control_sync',
    typeAction: types.GET_TABLET_CONTROL_SYNC,
  },
  postTabletControlSync: {
    url: '/admin/controlsync',
    responseObject: 'control_sync',
    typeAction: types.POST_TABLET_CONTROL_SYNC,
  },
  webModules: {
    url: '/admin/webmodules',
    responseObject: 'web_modules',
    typeAction: types.GET_WEB_MODULES,
  },
};

export const getAll = ({
  domain, filters = {},
}) => async (dispatch) => {
  try {
    const endpoint = getEndpoints[domain];
    const queryString = convertToUrlParameters(filters);
    const endpointUrl = `${endpoint.url}?${queryString}`;

    const {
      data: {
        result: { [endpoint.responseObject]: data = [] },
      },
    } = await api.get(endpointUrl);

    dispatch({
      type: endpoint.typeAction,
      payload: data,
    });
  } catch ({ response }) {
    if (response) {
      dispatch(handleErrors(response));
    }
  }
};

export const getWebModules = () => async (dispatch) => {
  dispatch(getAll({ domain: 'webModules' }));
};

export const getApkFile = () => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/admin/apkversion/files`;
    await downloadAxios(url, 'fast2mine.apk');
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const getAllTablets = () => async (dispatch) => {
  dispatch(getAll({ domain: 'allTabletsList' }));
};

export const getApkVersion = () => async (dispatch) => {
  dispatch(getAll({ domain: 'apkVersion' }));
};

export const getTabletUpdate = () => async (dispatch) => {
  dispatch(getAll({ domain: 'getTabletUpdate' }));
};

export const postTabletUpdate = (tabletsId, version) => async (dispatch) => {
  try {
    const payload = {
      tablet_update: [...tabletsId.map(tabletId => ({ tablet_id: tabletId, version }))],
    };

    const endpointUrl = getEndpoints.postTabletUpdate.url;

    const { status } = await api.post(endpointUrl, payload);
    if (status === 200) dispatch(getTabletUpdate());
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const getTabletControlSync = tabletId => async (dispatch) => {
  const filters = { id_tablet: tabletId };
  dispatch(getAll({ domain: 'getTabletControlSync', filters }));
};

const setValues = (item, key, value, type = '') => (dispatch) => {
  const updateStatus = item.update_status === 'I' ? 'I' : 'U';
  if (type === '') type = types.SET_TABLET_CONTROL_SYNC;

  if (item[key] !== value) {
    dispatch({
      type,
      payload: {
        ...item,
        [key]: value,
        update_status: updateStatus,
      },
    });
  }
};

export const setValuesControlSync = (item, key, value) => (dispatch) => {
  dispatch(setValues(item, key, value));
};
export const postTabletControlSync = controlSyncData => async (dispatch) => {
  try {
    const payload = {
      control_sync: [...controlSyncData.filter(e => e.update_status === 'U').map((cs) => {
        delete cs.update_status;
        return cs;
      })],
    };

    const endpointUrl = getEndpoints.postTabletControlSync.url;

    const { status } = await api.post(endpointUrl, payload);
    if (status === 200) dispatch(getTabletUpdate());
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};
export const resetControlSync = () => (dispatch) => {
  dispatch({
    type: types.RESET_TABLET_CONTROL_SYNC,
  });
};

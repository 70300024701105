import backgroundImage from '~/assets/background.jpg';

const styles = () => ({
  customButton: {
    height: 50,
    marginTop: 10,
    marginBottom: 10,
  },
  signInButton: {
    color: 'white',
    marginBottom: 15,
    marginTop: 15,
    width: '100%',
  },
  customHeader: {
    background: '#F2AA00 !important',
    textAlign: 'center',
  },
  textFieldPassword: {
    paddingRight: 14,
  },
  customAdornment: {
    paddingRight: 0,
  },
  customHeight: {
    height: '3.5625em',
  },
  formSignin: {
    width: '100%',
    maxWidth: 432,
  },
  background: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: '#2E3841',
    backgroundImage: `url(${backgroundImage})`,
    height: '100vh',
  },
});

export default styles;

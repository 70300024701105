import React from 'react';
import PropTypes from 'prop-types';
import Done from '@material-ui/icons/Done';
import Clear from '@material-ui/icons/Clear';

const tableStyle = { color: 'rgb(242 170 0)', fontSize: '2rem' };
const ActiveCell = ({ bulkEdit, editing, ...p }) => {
  const components = [Done, Clear];
  const inverted = p.inverted || false;
  const value = Boolean(p.value);
  const Component = inverted ? components[Number(value)] : components[Number(!value)];

  return (
    <Component
      style={{ ...tableStyle, cursor: editing ? 'pointer' : undefined }}
      onClick={editing ? () => bulkEdit(!p.value, p) : undefined}
    />
  );
};

ActiveCell.propTypes = {
  bulkEdit: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
  editing: PropTypes.bool.isRequired,
};

export default ActiveCell;

import React, { useEffect } from 'react';
import {
  withStyles, FormControl, FormLabel, FormGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../styles';
import {
  getBoomReasons,
  setBoomReasonValue,
  updateBoomReason,
  addBoomReason,
  resetBoomReasons,
} from '~/store/manager/actions';
import PF2MAddButton from '~/components/PF2MAddButton';
import PF2MDataTable from '~/components/PF2MDataTable';


const JumboTab = ({
  classes,
}) => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const boomReasons = useSelector(state => state.manager.boomReasons);

  useEffect(() => {
    dispatch(getBoomReasons(true));
  }, [dispatch]);

  const columns = [
    {
      field: 'reason',
      title: translate('common:Reason'),
      editable: 'always',
    },
    {
      field: 'active',
      title: translate('common:Active'),
      editable: 'always',
      customType: 'boolean',
    },

  ];

  const renderHeader = () => (
    <Formik
      initialValues={{ reason: '' }}
      validate={(values) => {
        const errors = {};
        if (!values.reason) {
          errors.reason = 'Required';
        }
        return errors;
      }}
      onSubmit={(values, { resetForm }) => {
        dispatch(addBoomReason(values));
        resetForm();
      }}
    >
      {({
        isSubmitting,
        errors,
        isValid,
      }) => (
        <Form style={{ display: 'flex', alignItems: 'center', width: '100' }}>
          <FormControl className={classes.boomReasons}>
            {`${translate('common:JumboExplanation')}:`}
            <FormLabel className={classes.formLabel}>
              {`${translate('common:Reason')}:`}
            </FormLabel>
            <FormGroup>
              <Field type="text" name="reason" className={classnames('form-control', errors.reason ? classes.fieldError : classes.field)} />
            </FormGroup>
          </FormControl>
          <div style={{ marginTop: 40 }}>
            <PF2MAddButton disabled={isSubmitting || !isValid} />
          </div>
        </Form>
      )}
    </Formik>
  );

  return (
    <div className={classes.tabContainer}>
      {renderHeader()}
      <PF2MDataTable
        data={boomReasons}
        columns={columns}
        onApply={() => dispatch(updateBoomReason(boomReasons))}
        onChange={(newData) => {
          newData.forEach((row) => {
            dispatch(setBoomReasonValue(row));
          });
        }}
        options={{
          hideAdd: true,
          hideDelete: true,
        }}
        onDiscard={() => dispatch(resetBoomReasons())}
      />

    </div>
  );
};

JumboTab.propTypes = {
  classes: PropTypes.object.isRequired,
  boomReasons: PropTypes.array,
};

JumboTab.defaultProps = {
  boomReasons: [],
};

export default withStyles(styles)(JumboTab);

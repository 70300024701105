export const SET_CONTENTS_LIST = 'SET_CONTENTS_LIST';
export const SET_CONTENT_VALUE = 'SET_CONTENT_VALUE';
export const RESET_CONTENTS = 'RESET_CONTENTS';
export const ADD_CONTENT = 'ADD_CONTENT';
export const SET_CONTENT_VARIABLES_LIST = 'SET_CONTENT_VARIABLES_LIST';
export const SET_CONTENT_VARIABLE_VALUE = 'SET_CONTENT_VARIABLE_VALUE';
export const RESET_CONTENT_VARIABLES = 'RESET_CONTENT_VARIABLES';
export const ADD_CONTENT_VARIABLE = 'ADD_CONTENT_VARIABLE';
export const SET_STOCK_CLOSINGS_LIST = 'SET_STOCK_CLOSINGS_LIST';
export const SET_STOCK_CLOSING_VALUE = 'SET_STOCK_CLOSING_VALUE';
export const RESET_STOCK_CLOSINGS = 'RESET_STOCK_CLOSINGS';
export const ADD_STOCK_CLOSING = 'ADD_STOCK_CLOSING';

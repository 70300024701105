import React, { useState, useEffect } from 'react';
import {
  withStyles,
  Checkbox,
  ListItemText,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PF2MOutlinedInput from '~/components/PF2MOutlinedInput';
import Footer from '~/pages/Manager/Footer';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import {
  getCodesEquipmentMaps,
  updateCodesEquipmentMaps,
  resetCodesEquipmentMaps,
  setCodesEquipmentMapValues,
} from '~/store/fuel/actions';
import {
  getEquipmentTypes,
  getEquipmentsGroups,
  getCodes,
} from '~/store/manager/actions';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import PF2MScrollbar from '~/components/PF2MScrollbar';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';

const CodesEquipmentLinkMap = ({
  classes,
  codesEquipmentLinkMaps,
  equipmentTypes,
  equipmentsGroups,
  codes,
  getEquipmentTypes: loadEquipmentTypes,
  getEquipmentsGroups: loadEquipmentGroups,
  getCodes: loadCodes,
  getCodesEquipmentMaps: getAll,
  isCodesEquipmentLinkMapsDirty,
  updateCodesEquipmentMaps: update,
  resetCodesEquipmentMaps: reset,
  setCodesEquipmentMapValues: updateItem,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [equipmentType, setEquipmentType] = useState(0);
  const [equipmentGroup, setEquipmentGroup] = useState(0);
  const [groups, setGroups] = useState([]);
  const [codesItems, setCodesItems] = useState([]);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const { t: translate } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      getAll();
      loadEquipmentTypes();
      loadEquipmentGroups();
      await loadCodes();
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, getAll, loadEquipmentTypes, loadEquipmentGroups, loadCodes]);

  useEffect(() => {
    if (!isCodesEquipmentLinkMapsDirty) { setEquipmentType(0); }
  }, [isCodesEquipmentLinkMapsDirty]);

  useEffect(() => {
    if (equipmentType > 0 && equipmentsGroups.length > 0) {
      const egroups = equipmentsGroups.filter(e => e.id_equipament === equipmentType);
      setGroups(egroups);
      setEquipmentGroup(0);
    } else if (equipmentType <= 0) {
      setGroups([]);
      setEquipmentGroup(0);
    }
  }, [equipmentType, equipmentsGroups]);

  useEffect(() => {
    function refreshItems(items = []) {
      return codes
        .filter(c => c.id_equip === equipmentType)
        .map(s => (
          {
            id: s.id_pk,
            name: s.name || translate('common:NoName'),
            checked: items.some(o => s.id_pk === o) || false,
          }
        ));
    }

    if (equipmentType && equipmentGroup) {
      const selected = codesEquipmentLinkMaps.find(m => m.id_equip_group === equipmentGroup);
      setCodesItems(refreshItems(selected ? selected.codes : []));
    } else {
      setCodesItems(refreshItems([]));
    }
  }, [equipmentType, equipmentGroup, codesEquipmentLinkMaps, codes, translate]);

  const handleEquipmentType = (e) => {
    const selectedMap = codesEquipmentLinkMaps
      .find(m => m.id_equip_group === equipmentGroup);
    if (e.target.value !== equipmentType && selectedMap && selectedMap.isDirty) {
      setIsAlertOpen(true);
    } else {
      setEquipmentType(e.target.value);
    }
  };

  const handleEquipmentGroup = (e) => {
    const selectedMap = codesEquipmentLinkMaps
      .find(m => m.id_equip_group === equipmentGroup);
    if (e.target.value !== equipmentGroup && selectedMap && selectedMap.isDirty) {
      setIsAlertOpen(true);
    } else {
      setEquipmentGroup(e.target.value);
    }
  };

  const handleCheck = (field, id, checked) => {
    updateItem(equipmentGroup, field, { id, checked });
  };

  const renderElements = (field, items) => items.map(s => (
    <div key={s.id} style={{ display: 'flex', alignItems: 'center' }}>
      <Checkbox
        checked={s.checked}
        color="primary"
        name={s.id.toString()}
        onChange={e => handleCheck(field, s.id, e.target.checked)}
      />
      <ListItemText secondary={s.name} style={{ padding: 0 }} />
    </div>
  ));

  const renderEquipmentTypes = () => [
    { value: 0, label: translate('common:Select') },
    ...equipmentTypes.map(e => ({ value: e.id, label: e.name })),
  ];

  const renderEquipmentGroups = () => [
    { value: 0, label: translate('common:Select') },
    ...groups.map(e => ({ value: e.id, label: e.name })),
  ];

  return (
    <div className={classes.tabContainer}>
      <div style={{ display: 'flex' }}>
        <div className={classes.containerTypeSelector}>
          <p className={classes.labelTypeSelector}>

            {translate('common:FilterByEquipType').toUpperCase()}
            :
          </p>
          <PF2MSearchSelectOutlined
            value={equipmentType}
            onChange={handleEquipmentType}
            outlined="true"
            fullWidth={false}
            input={<PF2MOutlinedInput />}
            className={classes.typeSelector}
          >
            {renderEquipmentTypes()}
          </PF2MSearchSelectOutlined>
        </div>
        <div className={classes.containerTypeSelector}>
          <p htmlFor="equipmentGroup" className={classes.labelTypeSelector}>

            {translate('common:FilterByEquipGroup').toUpperCase()}
            :
          </p>
          <PF2MSearchSelectOutlined
            value={equipmentGroup}
            onChange={handleEquipmentGroup}
            outlined="true"
            fullWidth={false}
            input={<PF2MOutlinedInput name="equipmentGroup" />}
            className={classes.typeSelector}
          >
            {renderEquipmentGroups()}
          </PF2MSearchSelectOutlined>
        </div>
      </div>
      <div className={classes.itens}>
        <span className={classes.label}>

          {`${translate('common:Codes')}:`}
        </span>
        <PF2MScrollbar className={classes.scrollbars} style={{ marginBottom: 5 }}>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
            {equipmentGroup > 0 && renderElements('item', codesItems)}
          </div>
        </PF2MScrollbar>
      </div>
      <div style={{ marginTop: 30 }}>
        <Footer
          isDirty={isCodesEquipmentLinkMapsDirty}
          discard={() => reset()}
          sendData={() => update(codesEquipmentLinkMaps)}
          style={{ marginTop: 10 }}
        />
      </div>
      <PF2MAlertDialog
        hasCancel={false}
        confirmText={translate('common:Understood')}
        description={translate('validation:YouHaveUnsavedData')}
        open={isAlertOpen}
        onConfirm={() => setIsAlertOpen(false)}
      />
    </div>
  );
};

CodesEquipmentLinkMap.propTypes = {
  classes: PropTypes.object.isRequired,
  codesEquipmentLinkMaps: PropTypes.array,
  equipmentTypes: PropTypes.array,
  equipmentsGroups: PropTypes.array,
  codes: PropTypes.array,
  getEquipmentTypes: PropTypes.func,
  getEquipmentsGroups: PropTypes.func,
  getCodes: PropTypes.func,
  getCodesEquipmentMaps: PropTypes.func,
  isCodesEquipmentLinkMapsDirty: PropTypes.bool,
  updateCodesEquipmentMaps: PropTypes.func,
  resetCodesEquipmentMaps: PropTypes.func,
  setCodesEquipmentMapValues: PropTypes.func,
};

CodesEquipmentLinkMap.defaultProps = {
  codesEquipmentLinkMaps: [],
  equipmentTypes: [],
  equipmentsGroups: [],
  codes: [],
  isCodesEquipmentLinkMapsDirty: false,
  getEquipmentTypes: null,
  getEquipmentsGroups: null,
  getCodes: null,
  getCodesEquipmentMaps: null,
  updateCodesEquipmentMaps: null,
  resetCodesEquipmentMaps: null,
  setCodesEquipmentMapValues: null,
};

const mapStateToProps = state => ({
  equipmentTypes: state.manager.equipmentTypes,
  equipmentsGroups: state.manager.equipmentsGroups,
  codes: state.manager.codes,
  codesEquipmentLinkMaps: state.fuel.codesEquipmentLinkMaps,
  isCodesEquipmentLinkMapsDirty: state.fuel.isCodesEquipmentLinkMapsDirty,
});

const mapDispatchToProps = {
  getEquipmentTypes,
  getEquipmentsGroups,
  getCodes,
  getCodesEquipmentMaps,
  updateCodesEquipmentMaps,
  resetCodesEquipmentMaps,
  setCodesEquipmentMapValues,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(CodesEquipmentLinkMap));

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import WarningIcon from '@material-ui/icons/Warning';
import PF2MButton from '../PF2MButton';

const PF2MActionConfirmation = ({
  onSuccess, onCancel, confirmationText,
}) => {
  const { t: translate } = useTranslation();
  return (
    <div style={{
      width: '30vw',
      padding: '20px',
      display: 'flex',
      flexFlow: 'column nowrap',
      textAlign: 'center',
      backgroundColor: '#fff',
      justifyContent: 'space-evenly',
    }}
    >
      <h2><WarningIcon style={{ height: '60px', width: '60px', color: '#F2AA00' }} /></h2>
      <h4>{confirmationText}</h4>
      <div style={{
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-evenly',
        marginTop: 20,
      }}
      >
        <PF2MButton onClick={onCancel} color="primary">{translate('common:Cancel')}</PF2MButton>
        <PF2MButton onClick={onSuccess}>{translate('common:Confirm')}</PF2MButton>
      </div>
    </div>
  );
};

PF2MActionConfirmation.propTypes = {
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
  confirmationText: PropTypes.string,
};

PF2MActionConfirmation.defaultProps = {
  onSuccess: () => {},
  onCancel: () => {},
  confirmationText: '',
};

export default (PF2MActionConfirmation);

/* eslint-disable no-param-reassign */
import * as types from './types';
import api from '~/services/api';
import { clearFileUploadError } from '../errors/actions';
import { handleErrors } from '../common';
import { convertToUrlParameters } from '~/utils';
import {
  GET_GENERIC_ERROR, SET_UPLOAD_ERROR, SET_UPLOAD_SUCCESS, SET_UPLOAD_SUCCESS_WARNING,
} from '../errors/types';
import i18n from '~/i18n';
// import { formatDateToServerFormat } from '~/utils/moment';
// import { SET_UPLOAD_SUCCESS } from '../errors/types';
// import i18n from '~/i18n';

const getEndpoints = {
  terrainModelsList: {
    url: '/terrain/terrainmodel',
    responseObject: 'terrain_model',
    typeAction: types.SET_TERRAIN_MODEL_LIST,
  },
  terrainModelUploadsList: {
    url: '/terrain/terrainmodelupload',
    responseObject: 'terrain_model_upload',
    typeAction: types.SET_TERRAIN_MODEL_UPLOAD_LIST,
  },
  terrainModelLayersList: {
    url: '/terrain/terrainmodeluploadlayers',
    responseObject: 'terrain_model_upload_layers',
    typeAction: types.SET_TERRAIN_MODEL_UPLOAD_LAYERS_LIST,
  },
  terrainDefaultLayersList: {
    url: '/terrain/terraindefaultlayers',
    responseObject: 'terrain_default_layers',
    typeAction: types.SET_TERRAIN_DEFAULT_LAYERS_LIST,
  },
  cloudPointReportList: {
    url: '/cloudpointreport',
    responseObject: 'cloud_point_report',
    typeAction: types.SET_CLOUD_POINT_REPORT_LIST,
  },
  polygonsList: {
    url: '/elements',
    responseObject: 'elements',
    typeAction: types.SET_POLYGON_LIST,
  },
};

export const getAll = ({
  domain, clearUploadError = true, all = false, filters = {},
}) => async (dispatch) => {
  try {
    if (clearUploadError) {
      dispatch(clearFileUploadError());
    }
    const endpoint = getEndpoints[domain];
    if (all) filters.all = true;
    const queryString = convertToUrlParameters(filters);
    const endpointUrl = `${endpoint.url}?${queryString}`;

    const {
      data: {
        result: { [endpoint.responseObject]: data = [] },
      },
    } = await api.get(endpointUrl);

    dispatch({
      type: endpoint.typeAction,
      payload: data,
    });
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

const setValues = (item, key, value, type) => (dispatch) => {
  let setType = '';
  switch (type) {
    case 'terrainModelUpload':
      setType = types.SET_TERRAIN_MODEL_UPLOAD_VALUES;
      break;
    case 'terrainModelUploadQueue':
      setType = types.SET_TERRAIN_MODEL_UPLOAD_QUEUE_VALUES;
      break;
    case 'terrainModelLayer':
      setType = types.SET_TERRAIN_MODEL_UPLOAD_LAYER_VALUES;
      break;
    case 'terrainModel':
    default:
      setType = types.SET_TERRAIN_MODEL_VALUES;
      break;
  }
  const updateStatus = item.update_status === 'I' ? 'I' : 'U';
  dispatch({
    type: setType,
    payload: {
      ...item,
      [key]: value,
      update_status: updateStatus,
    },
  });
};

export const updateData = (
  domain,
  data,
  refreshData = true,
  all = false,
  filters = {},
) => async (dispatch) => {
  try {
    const items = data
      .filter(d => d.update_status)
      .map((d) => {
        const {
          isDirty,
          id,
          ...rest
        } = d;
        if (rest.update_status !== 'I') return { id, ...rest };
        return rest;
      });
    const endpoint = getEndpoints[domain];
    const body = {
      [endpoint.responseObject]: items,
    };
    const { status } = await api.post(`${endpoint.url}`, body);
    if (status === 200 && refreshData) {
      dispatch(getAll({ domain, all, filters }));
    }
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const addTerrainModelUpload = data => async dispatch => dispatch({
  type: types.ADD_TERRAIN_MODEL_UPLOAD,
  payload: data,
});
export const clearTempTerrainModelUpload = data => async dispatch => dispatch({
  type: types.CLEAR_TEMP_TERRAIN_MODEL_UPLOAD,
  payload: data,
});
export const setTerrainModelUploadValue = (item, key, value) => async dispatch => dispatch(
  setValues(item, key, value, 'terrainModelUpload'),
);
export const updateTerrainModelUpload = data => async dispatch => dispatch(updateData('terrainModelUploadsList', data));
export const getTerrainModelUploads = () => async (dispatch) => {
  dispatch(getAll({ domain: 'terrainModelUploadsList' }));
};

export const addTerrainModelUploadQueue = data => async dispatch => dispatch({
  type: types.ADD_TERRAIN_MODEL_UPLOAD_QUEUE,
  payload: data,
});
export const setTerrainModelUploadQueueValue = (item, key, value) => async dispatch => dispatch(
  setValues(item, key, value, 'terrainModelUploadQueue'),
);
export const setQueueDone = id => async dispatch => dispatch({
  type: types.SET_QUEUE_ITEM_DONE,
  payload: id,
});

export const addTerrainModel = data => async dispatch => dispatch({
  type: types.ADD_TERRAIN_MODEL,
  payload: data,
});
export const setTerrainModelValue = (item, key, value) => async dispatch => dispatch(
  setValues(item, key, value, 'terrainModel'),
);

export const updateTerrainModel = data => async dispatch => dispatch(updateData('terrainModelsList', data));
export const getTerrainModels = filters => async (dispatch) => {
  dispatch(getAll({ domain: 'terrainModelsList', filters: { ...filters } }));
};

export const getTerrainModelLayers = (loadData = false, uploadId = 0) => async (dispatch) => {
  dispatch(getAll({ domain: 'terrainModelLayersList', filters: { upload_id: uploadId, load_data: loadData } }));
};
export const addTerrainModelUploadLayer = data => async dispatch => dispatch({
  type: types.ADD_TERRAIN_MODEL_UPLOAD_LAYER,
  payload: data,
});
export const updateTerrainModelUploadLayer = data => async dispatch => dispatch(updateData('terrainModelLayersList', data));
export const setTerrainModelLayerValue = (item, key, value) => async dispatch => dispatch(
  setValues(item, key, value, 'terrainModelLayer'),
);

export const getCloudPointReport = filters => async (dispatch) => {
  dispatch(getAll({ domain: 'cloudPointReportList', filters: { ...filters } }));
};

export const getPolygons = filters => async (dispatch) => {
  dispatch(getAll({ domain: 'polygonsList', filters: { ...filters, grouped: true } }));
};

export const getTerrainDefaultLayers = () => async (dispatch) => {
  dispatch(getAll({ domain: 'terrainDefaultLayersList' }));
};

export const uploadFile = (
  domain,
  file,
  row,
) => async (dispatch) => {
  try {
    dispatch({ type: SET_UPLOAD_ERROR, payload: '' });
    const json = JSON.stringify(row);
    const blob = new Blob([json], { type: 'application/json' });
    const formData = new FormData();
    formData.append('document', blob);
    formData.append('file', file);
    const result = await api.put(`/${domain}/files`, formData);
    const { data, status } = result;
    if (status === 200 && data.errors) {
      dispatch({
        type: SET_UPLOAD_SUCCESS_WARNING,
        payload: {
          success: `${i18n.t('validation:ModelUploadError')}\n${data.errors[0].message}`,
          error: data.errors[0].message,
        },
      });
    } else if (status === 200) {
      dispatch({
        type: types.SET_TERRAIN_MODEL_UPLOAD_ITEM,
        payload: data.result.terrain_model_upload[0],
        row_id: row.id,
      });
      dispatch({ type: SET_UPLOAD_SUCCESS, payload: i18n.t('validation:ModelUploadSuccess') });
      dispatch(setQueueDone(row.id));
    }
  } catch (error) {
    const { response } = error;
    if (response) {
      dispatch({
        type: SET_UPLOAD_ERROR,
        payload: response.data.errors[0].message,
      });
    } else if (error.constructor.name === 'Cancel') {
      dispatch(setTerrainModelUploadValue(row, 'status', 0));
      dispatch(setQueueDone(row.id));
      dispatch({
        type: GET_GENERIC_ERROR,
        payload: i18n.t('validation:UploadCanceled'),
      });
    } else {
      dispatch({
        type: GET_GENERIC_ERROR,
        payload: i18n.t('common:ServerError'),
      });
    }
  }
};

export const processFile = row => async (dispatch) => {
  dispatch(setTerrainModelValue(row, 'status', 4));
  const url = '/terrain/terrainmodelupload/process';
  try {
    const { data, status } = await api.post(`${url}`, row);
    if (status === 200 && data.errors) {
      dispatch({
        type: SET_UPLOAD_SUCCESS_WARNING,
        payload: {
          success: `${i18n.t('validation:ModelProcessingSuccess')}\n${data.errors[0].message}`,
          error: data.errors[0].message,
        },
      });
    } else if (status === 200) {
      dispatch({ type: SET_UPLOAD_SUCCESS, payload: i18n.t('validation:ModelProcessingSuccess') });
      dispatch({
        type: types.SET_TERRAIN_MODEL_UPLOAD_ITEM,
        payload: data.result.terrain_model_upload[0],
        row_id: row.id,
      });
    }
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const uploadTerrainModel = (file, row) => async (dispatch) => {
  dispatch(uploadFile('terrain/terrainmodelupload', file, row));
};

export const importHeightmap = (row, model_type = 'heightmap', onLoad = () => {}) => async (dispatch) => {
  const url = '/terrain/terrainmodelupload/files';
  try {
    const { data, status } = await api.get(`${url}`, {
      responseType: 'blob',
      params: {
        model_id: row.id,
        path: row.path,
        model_type,
      },
    });
    if (status === 200) {
      const src = URL.createObjectURL(data);
      onLoad(src);
      dispatch(setTerrainModelUploadValue(row, model_type, src));
      dispatch(setTerrainModelValue(row, model_type, src));
    }
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const approveTerrainModel = row => async (dispatch) => {
  dispatch(setTerrainModelUploadValue(row, 'status', 6));
  dispatch(updateTerrainModelUpload([row].map(item => ({ ...item, status: 6 }))));
};


const styles = {
  toolbarButton: {
    margin: '15px',
    marginLeft: '25px',
  },

};

export default styles;

import { createTheme } from '@material-ui/core/styles';

const titles = {
  fontFamily: 'Barlow Condensed',
  fontWeight: 600,
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#3D5465',
    },
    secondary: {
      main: '#F2AA00',
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: 'Roboto',
    fontWeightLight: 300,
    fontWeightRegular: 'normal',
    fontWeightMedium: 500,
    h1: {
      ...titles,
      fontWeight: 500,
    },
    h2: {
      ...titles,
    },
    h3: {
      ...titles,
    },
    h4: {
      ...titles,
    },
    h5: {
      ...titles,
    },
    h6: {
      ...titles,
    },
    button: {
      fontFamily: 'Roboto',
      fontWeight: 500,
      textTransform: 'uppercase',
    },
  },
  overrides: {
    MuiCheckbox: {
      root: {
        padding: '0px',
      },
    },
    MuiTableCell: {
      root: {
        padding: '4px',
        minHeight: '40px',
        height: '40px',
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
      },
    },
    MuiInputAdornment: {
      root: {
        marginRight: 0,
      },
    },
  },
});

export default theme;

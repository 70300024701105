import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreIcon from '@material-ui/icons/Restore';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import CreateIcon from '@material-ui/icons/Create';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import classNames from 'classnames';
import { TimePicker } from '@material-ui/pickers';
import NumberFormat from 'react-number-format';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutline from '@material-ui/icons/HelpOutline';
import { useTranslation } from 'react-i18next';
import i18n from '~/i18n';
import CustomTableCell from './TableCell';
import styles from './styles';
import {
  parseTime, formatTime, parseDate, formatDateToCurrentLocale,
} from '~/utils/moment';
import PF2MOutlinedInput from '../PF2MOutlinedInput';
import PF2MScrollbar from '../PF2MScrollbar';
import PF2MDatePickerOutlined from '../PF2MDatePickerOutlined';
import PF2MMaskTextField from '~/components/PF2MMaskTextField';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import PF2MButton from '../PF2MButton';

const INITIAL_BOOLEAN_SELECT_VALUES = [
  { id: '0', name: i18n.t('common:Inactive') },
  { id: '1', name: i18n.t('common:Active') },
];

const PF2MBooleanSelect = ({
  onChange, options = INITIAL_BOOLEAN_SELECT_VALUES, value, ...props
}) => {
  const menuItems = options
    .map(r => (<MenuItem key={r.id} value={r.id}>{r.name}</MenuItem>));

  return (
    <Select
      style={{
        height: 45,
        color: '#647886',
      }}
      input={<OutlinedInput fullWidth style={{ width: 130 }} labelWidth={0} />}
      {...props}
      onChange={onChange}
      value={value}
    >
      {menuItems}
    </Select>
  );
};

PF2MBooleanSelect.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.array,
  value: PropTypes.string,
};

PF2MBooleanSelect.defaultProps = {
  onChange: () => { },
  options: undefined,
  value: '0',
};

const PF2MTable = ({
  classes,
  data,
  columns,
  noDataMessage,
  className,
  updateItem,
  removeItem,
  restoreItem,
  dataKey,
  keyExtractor,
  showEditModal,
  selectItem,
  selectedItems,
  selectAll,
  disableSelectItem,
  disableSelectCheckboxItem,
  approveItem,
  disapproveItem,
  customStyles,
  itemsPerPage,
  currentPage,
}) => {
  const { t: translate } = useTranslation();

  const renderItems = ({
    items,
    itemKey = 'id',
    itemText = 'name',
    filterKey = null,
    filterValue = null,
    defaultLabel = null,
    filterFunction = null,
    row = null,
    defaultValue = 0,
  }) => {
    let filteredItems = [];
    if (filterFunction) {
      filteredItems = [...items.filter(item => filterFunction(item, row))];
    } else {
      filteredItems = [...items.filter(i => filterKey === null || i[filterKey] === filterValue)];
    }
    return [
      { value: defaultValue, label: (defaultLabel || translate('common:Select')) },
      ...filteredItems.map(e => (
        { value: e[itemKey], label: e[itemText] }
      )),
    ];
  };

  const renderTitles = () => columns.map(col => (
    <CustomTableCell
      key={col.field}
      align={col.align || 'inherit'}
      style={{
        width: col.width || 'auto',
        whiteSpace: col.wrap || 'nowrap',
        textAlign: col.textAlign || 'left',
        padding: col.checkbox ? '0px' : '4px',
        textTransform: 'uppercase',
        position: 'sticky',
        top: 0,
        backgroundColor: 'white',
        zIndex: 1,
      }}
    >
      {col.title}
      {col.tooltip && (
        <Tooltip title={col.tooltip} style={{ marginLeft: 5 }}>
          <HelpOutline fontSize="small" />
        </Tooltip>
      )}
    </CustomTableCell>
  ));

  const handleMask = value => (value ? [/\d/, /\d/, ':', /\d/, /\d/] : []);

  const renderField = (row, column) => {
    let disabled = false;

    if (column.renderCustomComponent) {
      return column.renderCustomComponent(row);
    }

    if (column.editFunc) {
      disabled = !column.editFunc(row);
    }

    if (!column.editable && column.field === 'active') {
      return row.active_name;
    }

    if (column.button) {
      return (
        <PF2MButton
          onClick={() => (column.onClick ? column.onClick(row) : null)}
          disabled={column.disabled(row) || disabled}
        >
          {column.title}
        </PF2MButton>
      );
    }

    if (column.datePicker) {
      return (
        <PF2MDatePickerOutlined
          onChange={e => updateItem(row, column.field, e)}
          value={parseDate(formatDateToCurrentLocale(row[column.field]))}
          helperText=""
          disabled={!column.editable || row.blocked}
          disableFuture={column.disableFuture !== undefined ? column.disableFuture : true}
        />
      );
    }

    if (column.timePicker) {
      return (
        <TimePicker
          ampm={false}
          autoOk
          keyboard
          mask={column.handleMask || handleMask}
          variant="outlined"
          format={column.timePickerFormat || 'HH:mm'}
          onChange={e => updateItem(row, column.field, formatTime(e, 'HH:mm:ss'))}
          value={parseTime(row[column.field] || '00:00:00')}
          disableOpenOnEnter
          InputProps={{
            style: {
              height: 42,
              width: column.width || '150px',
              fontFamily: 'Roboto',
              fontWeight: 300,
              fontSize: 14,
              color: '#647886',
            },
          }}
          disabled={!column.editable || row.blocked}
          invalidDateMessage=""
        />
      );
    }

    if (column.field === 'active') {
      return (
        <PF2MBooleanSelect
          value={(row[column.field] || 0).toString()}
          onChange={e => updateItem(row, column.field, Number(e.target.value))}
          disabled={row.update_status === 'I' || disabled}
          className={classes.select}
          style={{ maxHeight: 42 }}
        />
      );
    }

    if (column.type === 'boolean') {
      return (
        <PF2MBooleanSelect
          value={(row[column.field] || 0).toString()}
          onChange={e => updateItem(row, column.field, Number(e.target.value))}
          disabled={
            row.allow_edit === false
            || column.disabled
            || (column.disable_condition && !column.disable_condition(row))}
          className={classes.select}
          options={column.options || [
            { id: '0', name: translate('common:No') },
            { id: '1', name: translate('common:Yes') },
          ]}
          style={{ maxHeight: 42 }}
        />
      );
    }

    if (column.field === 'is_logout') {
      return (
        <PF2MBooleanSelect
          value={(row[column.field] || 0).toString()}
          onChange={e => updateItem(row, column.field, Number(e.target.value))}
          disabled={row.allow_edit === false || disabled}
          className={classes.select}
          options={[
            { id: '0', name: translate('common:No') },
            { id: '1', name: translate('common:Yes') },
          ]}
          style={{ maxHeight: 42 }}
        />
      );
    }

    if (column.selectItems) {
      return (
        <PF2MSearchSelectOutlined
          fullWidth={false}
          disabled={disabled}
          className={classes.select}
          onChange={e => updateItem && updateItem(row, column.field, e.target.value)}
          value={(row[column.field] || 0)}
          style={{ width: column.width || '' }}
        >
          {renderItems({
            items: column.selectItems,
            itemKey: column.itemKey,
            itemText: column.itemText,
            filterKey: column.filterKey,
            filterValue: row[column.filterValue],
            defaultLabel: column.comboDefaultLabel,
            filterFunction: column.filterFunction || null,
            row,
            defaultValue: column.defaultValue || 0,
          })}
        </PF2MSearchSelectOutlined>
      );
    }

    if (column.lookup) {
      return column.lookup[row[column.field] || 0];
    }

    if (column.type === 'decimal') {
      return (
        <NumberFormat
          customInput={PF2MOutlinedInput}
          value={row[column.field]}
          min={0}
          onBlur={e => updateItem(row, column.field, e.target.value)}
          decimalSeparator={column.separator || ','}
          decimalScale={column.decimalPrecision || 2}
          className={classes.textField}
          style={{ minWidth: column.width || 200, maxHeight: 42 }}
          disabled={column.blocked}
        />
      );
    }

    if (column.checkbox) {
      return (
        <Checkbox
          checked={row[column.field] || false}
          onChange={e => updateItem(row, column.field, e.target.checked)}
          value={row[column.field] ? 'true' : 'false'}
          color="primary"
        />
      );
    }
    const newProps = {};

    if (column.updateOnChange) {
      newProps.onChange = e => updateItem(row, column.field, e.target.value);
    } else {
      newProps.onBlur = e => updateItem(row, column.field, e.target.value);
    }

    const value = column.translateField ? translate(`common:${row[column.field]}`) : row[column.field] || column.defaultValue || (column.type === 'number' ? 0 : '');

    if (!column.editable) {
      return column.truncate
        ? `${value.toString().substring(1, 50)}...`
        : value;
    }

    if (column.durationField) {
      return (
        <PF2MMaskTextField
          variant="outlined"
          mask="99:99:99"
          className={classes.textField}
          placeholder={'00:00:00'}
          value={row[column.field] || '00:00:00'}
          onChange={e => updateItem(row, column.field, e.target.value)}
          InputProps={{
            style: {
              height: 40,
              width: 150,
              fontFamily: 'Roboto',
              fontWeight: 300,
              fontSize: 14,
              color: '#647886',
            },
          }}
        />
      );
    }

    return (
      <PF2MOutlinedInput
        value={value.toString()}
        {...newProps}
        className={classNames(classes.textField)}
        style={{ minWidth: column.width || 200, maxHeight: 42 }}
        type={column.type === 'number' ? 'number' : 'text'}
        inputProps={{
          min: 0,
        }}
        disabled={disabled}
      />
    );
  };

  const getPagedData = () => {
    const dataSlice = data.slice();
    const pageSize = itemsPerPage || 0;
    const page = currentPage || 0;

    return pageSize > 0
      ? dataSlice.slice(page * pageSize, (page + 1) * pageSize)
      : data;
  };

  const renderRows = () => getPagedData().map((row) => {
    let key = row[dataKey];

    if (keyExtractor) {
      key = keyExtractor(row);
    }

    return (
      <TableRow className={classes.row} key={key} hover>
        {approveItem && (
          <CustomTableCell>
            <div style={{ display: 'flex' }}>
              <Fab
                size="small"
                color="secondary"
                aria-label="Check"
                onClick={() => approveItem && approveItem(row)}
              >
                <CheckIcon />
              </Fab>
              <Fab
                size="small"
                color="primary"
                aria-label="Clear"
                style={{ marginLeft: '10px' }}
                onClick={() => disapproveItem && disapproveItem(row)}
              >
                <ClearIcon />
              </Fab>
            </div>
          </CustomTableCell>
        )}

        {columns.map(c => (
          !c.hidden && (
            <CustomTableCell
              style={{
                width: c.width || 'auto',
                whiteSpace: c.wrap || 'nowrap',
                padding: c.checkbox ? '0px' : '4px',
                textAlign: c.textAlign || 'left',
              }}
              key={`${c.field}-data`}
              align={c.align || 'inherit'}
            >
              {renderField(row, c)}
            </CustomTableCell>
          )
        ))}
        {removeItem && (
          <CustomTableCell style={{ width: '50px', paddingRight: '24px' }}>
            {row.update_status === 'D' ? (
              <Fab
                size="small"
                color="primary"
                aria-label="Restore"
                onClick={() => restoreItem && restoreItem(row)}
              >
                <RestoreIcon />
              </Fab>
            ) : (
              <Fab
                size="small"
                color="primary"
                aria-label="Delete"
                onClick={() => removeItem && removeItem(row)}
              >
                <DeleteIcon />
              </Fab>
            )}
          </CustomTableCell>
        )}
        {showEditModal && (
          <CustomTableCell>
            {!row.hideEdit && (
              <Fab
                size="small"
                color="primary"
                aria-label="Restore"
                onClick={() => updateItem(row)}
              >
                <CreateIcon />
              </Fab>
            )}
          </CustomTableCell>
        )}
        {selectItem && (
          <CustomTableCell style={{ textAlign: 'center', paddingLeft: '45px' }}>
            <Checkbox
              checked={row.checked ? row.checked : selectedItems.includes(row.id)}
              disabled={
                disableSelectCheckboxItem ? disableSelectCheckboxItem(row) : disableSelectItem
              }
              onChange={e => selectItem(row, e.target.checked)
              }
              color="primary"
            />
          </CustomTableCell>
        )}
      </TableRow>
    );
  });

  return (
    <div className={classNames(classes.root, className)} style={{ ...customStyles }}>
      <PF2MScrollbar>
        <Table className={classes.table} style={{ ...customStyles.table }}>
          <TableHead>
            <TableRow>
              {renderTitles()}
              {removeItem && <CustomTableCell align="right" />}
              {showEditModal && (
                <CustomTableCell
                  align="right"
                  style={{
                    width: 'auto',
                    textTransform: 'uppercase',
                    position: 'sticky',
                    top: 0,
                    backgroundColor: 'white',
                    zIndex: 1,
                  }}
                />
              )}
              {selectItem && (
                <CustomTableCell
                  align="right"
                  style={{
                    width: 'auto',
                    textTransform: 'uppercase',
                    position: 'sticky',
                    top: 0,
                    backgroundColor: 'white',
                    zIndex: 1,
                  }}
                >
                  <PF2MButton
                    onClick={() => selectAll(data)}
                    disabled={disableSelectItem}
                  >
                    {translate('common:SelectAll')}
                  </PF2MButton>
                </CustomTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              renderRows()
            ) : (
              <TableRow className={classes.row}>
                <CustomTableCell align="center" colSpan={columns.length}>
                  {noDataMessage || translate('validation:NoDataMessage')}
                </CustomTableCell>
                {removeItem && <CustomTableCell align="right" />}
                {showEditModal && <CustomTableCell align="right" />}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </PF2MScrollbar>
    </div>
  );
};

PF2MTable.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array,
  columns: PropTypes.array,
  noDataMessage: PropTypes.string,
  className: PropTypes.string,
  updateItem: PropTypes.func,
  removeItem: PropTypes.func,
  restoreItem: PropTypes.func,
  dataKey: PropTypes.string,
  keyExtractor: PropTypes.func,
  showEditModal: PropTypes.bool,
  selectItem: PropTypes.func,
  selectedItems: PropTypes.array,
  selectAll: PropTypes.func,
  disableSelectItem: PropTypes.bool,
  approveItem: PropTypes.func,
  disapproveItem: PropTypes.func,
  customStyles: PropTypes.object,
  disableSelectCheckboxItem: PropTypes.func,
  itemsPerPage: PropTypes.number,
  currentPage: PropTypes.number,
};

PF2MTable.defaultProps = {
  data: [],
  columns: [],
  noDataMessage: undefined,
  className: '',
  updateItem: null,
  removeItem: null,
  restoreItem: null,
  dataKey: 'id',
  keyExtractor: null,
  showEditModal: false,
  selectItem: null,
  selectedItems: [],
  selectAll: null,
  disableSelectItem: false,
  disableSelectCheckboxItem: null,
  approveItem: null,
  disapproveItem: null,
  customStyles: {},
  itemsPerPage: null,
  currentPage: null,
};

export default withStyles(styles)(PF2MTable);

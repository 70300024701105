import React from 'react';
import PF2MMaskTextField from '../../PF2MMaskTextField';

export default function TimeCell(p) {
  const helperText = p.error
    ? (p.helperText || true) // red line with empty message
    : p.helperText; // normal message

  return (
    <PF2MMaskTextField
      style={{ height: '28px' }}
      mask="99:99:99"
      value={p.value}
      error={p.error}
      helperText={helperText}
      InputProps={{ style: { fontSize: 13 } }}
      onChange={e => p.bulkEdit(e, p)}
    />
  );
}

import * as types from './types';
import { remapData } from '~/utils';

const INITIAL_STATE = {
  lineups: [],
  selectedLineup: {},
  lineupElements: [],
  lineupSubelements: [],
  elevationGroups: [],
  elevations: [],
  elevationAssociations: [],
  elementPrecedences: [],
  subElementPrecedences: [],
  resources: [],
  resourcesFlat: [],
  priorities: [],
  loadAllocations: [],
  originalElevationGroups: [],
  originalElevations: [],
  originalLineups: [],
  originalLineupElements: [],
  originalLineupSubelements: [],
  originalElevationAssociations: [],
  originalElementPrecedences: [],
  originalSubElementPrecedences: [],
  originalResources: [],
  originalPriorities: [],
  isLineupsDirty: false,
  isLineupElementsDirty: false,
  isLineupSubelementsDirty: false,
  isElevationGroupsDirty: false,
  isElevationsDirty: false,
  isElevationAssociationsDirty: false,
  isElementPrecedencesDirty: false,
  isSubElementPrecedencesDirty: false,
  isResourcesDirty: false,
  isPrioritiesDirty: false,
  isLoadAllocationDirty: false,
};

const adjustResources = (items = []) => {
  const data = items.map((c) => {
    const {
      available_contents,
      ...rest
    } = c;
    const contentKeys = {};
    available_contents.forEach((content) => {
      contentKeys[`content_${content.content_id}`] = content.value;
    });
    return {
      ...rest,
      ...contentKeys,
    };
  });
  return data;
};


export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_LINEUP_LIST: {
      return {
        ...state,
        lineups: [...action.payload],
        originalLineups: [...action.payload],
        isLineupsDirty: false,
      };
    }
    case types.SET_SELECTED_LINEUP: {
      const foundLineup = state.lineups.find(r => r.id === action.payload);
      if (!foundLineup) return state;
      return {
        ...state,
        selectedLineup: foundLineup,
      };
    }
    case types.SET_LINEUP_ELEMENTS_LIST: {
      return {
        ...state,
        lineupElements: action.payload,
        originalLineupElements: action.payload,
        isLineupElementsDirty: false,
      };
    }
    case types.SET_LINEUP_SUBELEMENTS_LIST: {
      return {
        ...state,
        lineupSubelements: action.payload,
        originalLineupSubelements: action.payload,
        isLineupSubelementsDirty: false,
      };
    }
    case types.SET_LINEUP_VALUES: {
      const newItem = {
        ...action.payload.item,
        isDirty: !(action.payload.item.id < 1),
      };

      let newLineups = [...state.lineups];

      if (action.payload.key === 'is_top_priority') {
        newLineups = newLineups.map(r => ({
          ...r, is_top_priority: false,
        }));
      }

      const newData = remapData(newLineups, newItem, 'id');
      const isLineupsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isLineupsDirty,
        lineups: newData,
      };
    }
    case types.ADD_LINEUP: {
      const newItem = {
        ...action.payload,
        id: -state.lineups.length,
        update_status: 'I',
        active: 1,
        is_top_priority: false,
        isDirty: true,
      };
      const newData = [newItem, ...state.lineups];
      const isLineupsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isLineupsDirty,
        lineups: newData,
      };
    }
    case types.TOGGLE_LINEUP_ELEMENT_ASSOCIATION: {
      const foundLineupElement = state.lineupElements.find(r => r.element_id === action.payload);
      if (foundLineupElement) {
        const deletedLineupElement = {
          ...foundLineupElement,
          update_status: foundLineupElement.update_status === 'D' ? 'I' : 'D',
        };
        const filteredLineupElements = state.lineupElements
          .filter(r => r.element_id !== deletedLineupElement.element_id);
        return {
          ...state,
          isLineupElementsDirty: true,
          lineupElements: [...filteredLineupElements, deletedLineupElement],
        };
      }
      const element = {
        id: null,
        lineup_id: state.selectedLineup.id,
        element_id: action.payload,
        active: true,
        update_status: 'I',
      };
      return {
        ...state,
        isLineupElementsDirty: true,
        lineupElements: [...state.lineupElements, element],
      };
    }
    case types.TOGGLE_LINEUP_SUBELEMENT_ASSOCIATION: {
      const foundLineupSubelement = state.lineupSubelements
        .find(r => r.element_point_id === action.payload);
      if (foundLineupSubelement) {
        const deletedLineupSubelement = {
          ...foundLineupSubelement,
          update_status: foundLineupSubelement.update_status === 'D' ? 'I' : 'D',
        };
        const filteredLineupSublements = state.lineupSubelements
          .filter(r => r.element_point_id !== deletedLineupSubelement.element_point_id);
        return {
          ...state,
          isLineupSubelementsDirty: true,
          lineupSubelements: [...filteredLineupSublements, deletedLineupSubelement],
        };
      }
      const subelement = {
        id: null,
        lineup_id: state.selectedLineup.id,
        element_point_id: action.payload,
        active: true,
        update_status: 'I',
      };
      return {
        ...state,
        isLineupSubelementsDirty: true,
        lineupSubelements: [...state.lineupSubelements, subelement],
      };
    }
    case types.BULK_SELECT_LINEUP_SUBELEMENTS: {
      const { subelementIds, lineup } = action.payload;
      const subelements = subelementIds.map(r => ({
        id: null,
        lineup_id: lineup.id,
        element_point_id: r,
        active: true,
        update_status: 'I',
      }
      ));

      const newState = [...state.lineupSubelements];

      subelements.forEach((r) => {
        const foundLineupSubelement = newState
          .find(x => x.lineup_id === lineup.id && x.element_point_id === r.element_point_id);
        if (!foundLineupSubelement) {
          newState.push(r);
        } else {
          foundLineupSubelement.update_status = 'I';
        }
      });

      return {
        ...state,
        isLineupSubelementsDirty: true,
        lineupSubelements: newState,
      };
    }
    case types.BULK_DESELECT_LINEUP_SUBELEMENTS: {
      const { subelementIds, lineup } = action.payload;

      const newState = [...state.lineupSubelements];
      newState.forEach((r) => {
        if (subelementIds.includes(r.element_point_id) && r.lineup_id === lineup.id) {
          // eslint-disable-next-line no-param-reassign
          r.update_status = 'D';
        }
      });

      return {
        ...state,
        isLineupSubelementsDirty: true,
        lineupSubelements: newState,
      };
    }
    case types.RESTORE_LINEUPS: {
      return {
        ...state,
        isLineupsDirty: false,
        lineups: [...state.originalLineups],
      };
    }
    case types.RESTORE_LINEUP_ELEMENTS: {
      return {
        ...state,
        isLineupElementsDirty: false,
        lineupElements: [...state.originalLineupElements],
      };
    }
    case types.RESTORE_LINEUP_SUBELEMENTS: {
      return {
        ...state,
        isLineupSubelementsDirty: false,
        lineupSubelements: [...state.originalLineupSubelements],
      };
    }
    case types.SET_ELEVATION_GROUPS_LIST: {
      return {
        ...state,
        isElevationGroupsDirty: false,
        elevationGroups: action.payload,
        originalElevationGroups: action.payload,
      };
    }
    case types.ADD_ELEVATION_GROUP: {
      const newItem = {
        ...action.payload,
        id: -state.elevationGroups.length,
        update_status: 'I',
        active: 1,
        isDirty: true,
      };
      const newData = [newItem, ...state.elevationGroups];
      const isElevationGroupsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isElevationGroupsDirty,
        elevationGroups: newData,
      };
    }
    case types.RESTORE_ELEVATION_GROUPS: {
      return {
        ...state,
        isElevationGroupsDirty: false,
        elevationGroups: [...state.originalElevationGroups],
      };
    }
    case types.SET_ELEVATION_ASSOCIATIONS_LIST: {
      return {
        ...state,
        isElevationAssociationsDirty: false,
        originalElevationAssociations: action.payload,
        elevationAssociations: action.payload,
      };
    }
    case types.RESTORE_ELEVATION_ASSOCIATIONS: {
      return {
        ...state,
        isElevationAssociationsDirty: false,
        elevationAssociations: state.originalElevationAssociations,
      };
    }
    case types.CREATE_ELEVATION_ASSOCIATION: {
      const { elementId, elevationGroupId, lineupId } = action.payload;
      const found = state.elevationAssociations
        .find(r => r.lineup_id === lineupId
          && r.elevation_group_id === elevationGroupId
          && r.element_id === elementId);

      if (found) return state;

      const newAssoc = {
        id: -(state.elevationAssociations.length + 1),
        element_id: elementId,
        elevation_group_id: elevationGroupId,
        excluded_elevations: [],
        lineup_id: lineupId,
        update_status: 'I',
      };

      const filteredAssocs = state.elevationAssociations
        .filter(r => r.lineup_id !== newAssoc.lineup_id
          || r.element_id !== newAssoc.element_id);

      return {
        ...state,
        elevationAssociations: [...filteredAssocs, newAssoc],
        isElevationAssociationsDirty: true,
      };
    }
    case types.DESELECT_ALL_ELEVATIONS: {
      const {
        initial_elevation: initialElevation,
        final_elevation: finalElevation,
        interval,
      } = state.elevationGroups
        .find(r => r.id === action.payload.elevationGroupId);

      const elevationsList = [];
      for (let i = initialElevation; i <= finalElevation; i += interval) {
        elevationsList.push(i);
      }
      const foundAssoc = state.elevationAssociations
        .find(r => r.lineup_id === action.payload.lineupId
          && r.elevation_group_id === action.payload.elevationGroupId
          && r.element_id === action.payload.elementId);

      const newAssoc = {
        ...foundAssoc,
        excluded_elevations: elevationsList,
        update_status: foundAssoc.update_status === 'I' ? 'I' : 'U',
      };

      const filteredAssocs = state.elevationAssociations
        .filter(r => r.lineup_id !== action.payload.lineupId
          || r.elevation_group_id !== action.payload.elevationGroupId
          || r.element_id !== action.payload.elementId);

      return {
        ...state,
        isElevationAssociationsDirty: true,
        elevationAssociations: [...filteredAssocs, newAssoc],
      };
    }
    case types.SELECT_ALL_ELEVATIONS: {
      const foundAssoc = state.elevationAssociations
        .find(r => r.lineup_id === action.payload.lineupId
          && r.elevation_group_id === action.payload.elevationGroupId
          && r.element_id === action.payload.elementId);

      const newAssoc = {
        ...foundAssoc,
        excluded_elevations: [],
        update_status: foundAssoc.update_status === 'I' ? 'I' : 'U',
      };

      const filteredAssocs = state.elevationAssociations
        .filter(r => r.lineup_id !== action.payload.lineupId
          || r.elevation_group_id !== action.payload.elevationGroupId
          || r.element_id !== action.payload.elementId);

      return {
        ...state,
        isElevationAssociationsDirty: true,
        elevationAssociations: [...filteredAssocs, newAssoc],
      };
    }
    case types.TOGGLE_ELEVATION_ASSOCIATION: {
      const {
        selectedElement,
        selectedElevationGroup,
        selectedLineup,
        elevation: selectedElevation,
      } = action.payload;

      let foundAssoc = state.elevationAssociations
        .find(r => r.lineup_id === selectedLineup.id
          && r.elevation_group_id === selectedElevationGroup
          && r.element_id === selectedElement);

      if (!foundAssoc) {
        foundAssoc = {
          id: -(state.elevationAssociations.length + 1),
          element_id: selectedElement,
          elevation_group_id: selectedElevationGroup,
          excluded_elevations: [],
          lineup_id: selectedLineup.id,
          update_status: 'I',
        };
      }

      if (foundAssoc.excluded_elevations.includes(selectedElevation)) {
        foundAssoc.excluded_elevations = foundAssoc.excluded_elevations
          .filter(r => r !== selectedElevation);
      } else {
        foundAssoc.excluded_elevations = [...foundAssoc.excluded_elevations, selectedElevation];
      }

      const filteredElevationAssociations = state.elevationAssociations
        .filter(r => r.id !== foundAssoc.id);

      return {
        ...state,
        isElevationAssociationsDirty: true,
        elevationAssociations: [...filteredElevationAssociations, {
          ...foundAssoc,
          update_status: foundAssoc.update_status === 'I' ? 'I' : 'U',
        }],
      };
    }

    case types.SET_ELEMENT_PRECEDENCE_LIST: {
      return {
        ...state,
        isElementPrecedencesDirty: false,
        originalElementPrecedences: action.payload,
        elementPrecedences: action.payload,
      };
    }
    case types.RESTORE_ELEMENT_PRECEDENCES: {
      return {
        ...state,
        isElementPrecedencesDirty: false,
        elementPrecedences: state.originalElementPrecedences,
      };
    }
    case types.DESELECT_ALL_ELEMENT_PRECEDENCES: {
      const {
        fatherElementId, elements, lineupId,
      } = action.payload;

      const precedencesForCurrentFather = state.elementPrecedences
        .filter(r => r.father_element_id === fatherElementId && r.lineup_id === lineupId);

      const newPrecedences = [];
      elements.forEach((r) => {
        const foundPrecedence = precedencesForCurrentFather
          .find(s => s.child_element_id === r.element_id);
        if (foundPrecedence) {
          newPrecedences.push({ ...foundPrecedence, update_status: 'D' });
        }
      });

      const filteredPrecs = state.elementPrecedences
        .filter(r => !newPrecedences.map(s => s.id).includes(r.id));

      return {
        ...state,
        isElementPrecedencesDirty: true,
        elementPrecedences: [...filteredPrecs, ...newPrecedences],
      };
    }
    case types.SELECT_ALL_ELEMENT_PRECEDENCES: {
      const {
        fatherElementId, elements, lineupId,
      } = action.payload;

      const precedencesForCurrentFather = state.elementPrecedences
        .filter(r => r.father_element_id === fatherElementId && r.lineup_id === lineupId);

      const newPrecedences = [];
      elements.forEach((r) => {
        const foundPrecedence = precedencesForCurrentFather
          .find(s => s.child_element_id === r.element_id);
        if (foundPrecedence) {
          if (foundPrecedence.update_status === 'D') {
            newPrecedences.push({ ...foundPrecedence, update_status: 'I' });
          } else {
            newPrecedences.push(foundPrecedence);
          }
        } else {
          newPrecedences.push({
            id: -(state.elementPrecedences.length + 1),
            father_element_id: fatherElementId,
            child_element_id: r.element_id,
            lineup_id: lineupId,
            update_status: 'I',
          });
        }
      });

      const filteredPrecs = state.elementPrecedences
        .filter(r => !newPrecedences.map(s => s.id).includes(r.id));

      return {
        ...state,
        isElementPrecedencesDirty: true,
        elementPrecedences: [...filteredPrecs, ...newPrecedences],
      };
    }
    case types.TOGGLE_ELEMENT_PRECEDENCE: {
      const {
        fatherElementId,
        childElementId,
        lineupId,
      } = action.payload;

      let foundAssoc = state.elementPrecedences
        .find(r => r.lineup_id === lineupId
          && r.father_element_id === fatherElementId
          && r.child_element_id === childElementId);

      if (!foundAssoc) {
        foundAssoc = {
          id: -(state.elementPrecedences.length + 1),
          father_element_id: fatherElementId,
          child_element_id: childElementId,
          lineup_id: lineupId,
          update_status: 'D',
        };
      }

      const filteredPrecedences = state.elementPrecedences
        .filter(r => r.id !== foundAssoc.id);

      return {
        ...state,
        isElementPrecedencesDirty: true,
        elementPrecedences: [...filteredPrecedences, {
          ...foundAssoc,
          update_status: !foundAssoc.update_status || foundAssoc.update_status === 'I' ? 'D' : 'I',
        }],
      };
    }

    case types.SET_SUBELEMENT_PRECEDENCE_LIST: {
      return {
        ...state,
        isSubElementPrecedencesDirty: false,
        originalSubElementPrecedences: action.payload,
        subElementPrecedences: action.payload,
      };
    }
    case types.RESTORE_SUBELEMENT_PRECEDENCES: {
      return {
        ...state,
        isSubElementPrecedencesDirty: false,
        subElementPrecedences: state.originalSubElementPrecedences,
      };
    }
    case types.DESELECT_ALL_SUBELEMENT_PRECEDENCES: {
      const {
        fatherSubElementId, subElements, lineupId,
      } = action.payload;

      const precedencesForCurrentFather = state.subElementPrecedences
        .filter(r => r.father_element_point_id === fatherSubElementId && r.lineup_id === lineupId);

      const newPrecedences = [];
      subElements.forEach((r) => {
        const foundPrecedence = precedencesForCurrentFather
          .find(s => s.child_element_point_id === r.element_point_id);
        if (foundPrecedence) {
          newPrecedences.push({ ...foundPrecedence, update_status: 'D' });
        }
      });

      const filteredPrecs = state.subElementPrecedences
        .filter(r => !newPrecedences.map(s => s.id).includes(r.id));

      return {
        ...state,
        isSubElementPrecedencesDirty: true,
        subElementPrecedences: [...filteredPrecs, ...newPrecedences],
      };
    }
    case types.SELECT_ALL_SUBELEMENT_PRECEDENCES: {
      const {
        fatherSubElementId, subElements, lineupId,
      } = action.payload;

      const precedencesForCurrentFather = state.subElementPrecedences
        .filter(r => r.father_element_point_id === fatherSubElementId && r.lineup_id === lineupId);

      const newPrecedences = [];
      subElements.forEach((r) => {
        const foundPrecedence = precedencesForCurrentFather
          .find(s => s.child_element_point_id === r.element_point_id);
        if (foundPrecedence) {
          if (foundPrecedence.update_status === 'D') {
            newPrecedences.push({ ...foundPrecedence, update_status: 'I' });
          } else {
            newPrecedences.push(foundPrecedence);
          }
        } else {
          newPrecedences.push({
            id: -(state.subElementPrecedences.length + 1),
            father_element_point_id: fatherSubElementId,
            child_element_point_id: r.element_point_id,
            lineup_id: lineupId,
            update_status: 'I',
          });
        }
      });

      const filteredPrecs = state.subElementPrecedences
        .filter(r => !newPrecedences.map(s => s.id).includes(r.id));

      return {
        ...state,
        isSubElementPrecedencesDirty: true,
        subElementPrecedences: [...filteredPrecs, ...newPrecedences],
      };
    }
    case types.TOGGLE_SUBELEMENT_PRECEDENCE: {
      const {
        fatherSubElementId,
        childSubElementId,
        lineupId,
      } = action.payload;

      let foundAssoc = state.subElementPrecedences
        .find(r => r.lineup_id === lineupId
          && r.father_element_point_id === fatherSubElementId
          && r.child_element_point_id === childSubElementId);

      if (!foundAssoc) {
        foundAssoc = {
          id: -(state.subElementPrecedences.length + 1),
          father_element_point_id: fatherSubElementId,
          child_element_point_id: childSubElementId,
          lineup_id: lineupId,
          update_status: 'D',
        };
      }

      const filteredPrecedences = state.subElementPrecedences
        .filter(r => r.id !== foundAssoc.id);

      return {
        ...state,
        isSubElementPrecedencesDirty: true,
        subElementPrecedences: [...filteredPrecedences, {
          ...foundAssoc,
          update_status: !foundAssoc.update_status || foundAssoc.update_status === 'I' ? 'D' : 'I',
        }],
      };
    }

    case types.SET_RESOURCES_LIST: {
      const data = adjustResources(action.payload);
      return {
        ...state,
        isResourcesDirty: false,
        originalResources: action.payload,
        resources: action.payload,
        resourcesFlat: data,
      };
    }
    case types.RESTORE_RESOURCES: {
      const data = adjustResources(state.originalResources);
      return {
        ...state,
        resources: state.originalResources,
        resourcesFlat: data,
        isResourcesDirty: false,
      };
    }
    case types.SET_RESOURCE_VALUE: {
      const newItem = {
        ...action.payload.item,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.resourcesFlat, newItem, 'id');
      const isResourcesDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isResourcesDirty,
        resourcesFlat: newData,
      };
    }
    case types.SET_PRIORITIES_LIST: {
      return {
        ...state,
        isPrioritiesDirty: false,
        priorities: action.payload,
        originalPriorities: action.payload,
      };
    }
    case types.SET_LINEUP_PRIORITY_VALUE: {
      const item = state.priorities.find(r => r.element_point_id === action.payload.item);
      const newItem = {
        ...item,
        update_status: item.update_status === 'I' ? 'I' : 'U',
        priority: action.payload.value,
      };
      const newData = remapData(state.priorities, newItem, 'id');
      const isPrioritiesDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isPrioritiesDirty,
        priorities: newData,
      };
    }
    case types.ADD_PRIORITY: {
      const newItem = {
        ...action.payload,
        id: -state.priorities.length,
        update_status: 'I',
      };
      const newData = [newItem, ...state.priorities];
      const isPrioritiesDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isPrioritiesDirty,
        priorities: newData,
      };
    }
    case types.RESTORE_PRORITIES: {
      return {
        ...state,
        isPrioritiesDirty: false,
        priorities: state.originalPriorities,
      };
    }
    case types.SET_LOAD_ALLOCATIONS_LIST: {
      return {
        ...state,
        isLoadAllocationDirty: false,
        originalLoadAllocation: action.payload,
        loadAllocations: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  getEquipmentTypes,
  getActivityItemChecklist,
  resetActivitItemsChecklist,
  setActivityItemsChecklist,
  getActivityTypesChecklist,
  getEquipmentsGroups,
  addActivityItemsChecklist,
  updateActivityItemsChecklist,
  getActivityItemChecklistTemplate,
  importActivityItemChecklist,
} from '~/store/manager/actions';
import PF2MDataTable from '~/components/PF2MDataTable';
import styles from '../styles';
import Header from '~/pages/Manager/Header';

const TasksTab = ({ classes }) => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const activityItemsChecklist = useSelector(st => st.manager.activityItemsChecklist);
  const activityTypesChecklist = useSelector(st => st.manager.activityTypesChecklist);
  const equipmentGroups = useSelector(st => st.manager.equipmentsGroups);
  const equipmentTypes = useSelector(st => st.manager.equipmentTypes);

  useEffect(() => {
    dispatch(getEquipmentTypes(true));
    dispatch(getEquipmentsGroups(true));

    dispatch(getActivityItemChecklist());
    dispatch(getActivityTypesChecklist(true));
  }, [dispatch]);

  const columns = [
    {
      field: 'equipment_type_id',
      title: translate('common:EquipmentType'),
      width: '200px',
      validate: item => Boolean(item.name),
      lookup: equipmentTypes,
    },
    {
      field: 'equipment_group_id',
      customType: 'filterLookup',
      title: translate('common:EquipmentGroup'),
      lookup: equipmentGroups,
      lookupFilter: (e, f) => String(e.id_equipament) === String(f.equipment_type_id)
      || String(f.equipment_type_id) === '0',
    },
    {
      title: translate('common:TaskCodeType'),
      field: 'activity_type_checklist_id',
      lookup: activityTypesChecklist,
    },
    {
      field: 'name',
      title: translate('common:TaskCodeItem'),
    },
    {
      field: 'mandatory',
      title: translate('common:MandatoryStop'),
      customType: 'boolean',
    },
  ];

  return (
    <div className={classes.tabContainer}>
      <Header
        getTemplateAction={() => dispatch(getActivityItemChecklistTemplate())}
        importAction={file => dispatch(importActivityItemChecklist(file))}
      />
      <PF2MDataTable
        onDiscard={() => dispatch(resetActivitItemsChecklist())}
        onChange={(newData) => {
          newData.forEach((row) => {
            dispatch(setActivityItemsChecklist(row));
          });
        }}
        onDelete={(newData) => {
          newData.forEach((row) => {
            dispatch(setActivityItemsChecklist({ ...row, update_status: 'D', active: 0 }));
          });
        }}
        onAdd={newData => dispatch(addActivityItemsChecklist(newData))}
        onApply={() => dispatch(updateActivityItemsChecklist())}
        options={{ selection: true, search: true }}
        data={activityItemsChecklist.filter(aic => aic.update_status !== 'D' && aic.active)}
        columns={columns}
        initialFormData={{
          active: true,
          name: '',
          mandatory: false,
          activity_type_checklist_id: 0,
          id: -activityItemsChecklist.length,
        }}
      />
    </div>
  );
};

TasksTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TasksTab);

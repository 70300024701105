/* eslint max-len:0 */
import React, { useState, useEffect } from 'react';
import { withStyles, ListItemText } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import Footer from '~/pages/Manager/Footer';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import {
  getSystems,
  getSystemItems,
  getFailureModes,
  getFailureModesSystemItems,
  setFailureModeSystemItemValue,
  updateFailureModesSystemItems,
  resetFailureModesSystemItems,
  getFailureModeEquipmentGroupMaintenanceItems,
  getEquipmentGroupsSystemItems,
} from '~/store/maintenance/actions';

import {
  getEquipmentTypes,
  getEquipmentsGroups,
} from '~/store/manager/actions';

import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import PF2MScrollbar from '~/components/PF2MScrollbar';

const FailureModeSystemItemsTab = ({ classes }) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [selectedSystem, setSelectedSystem] = useState(0);
  const [selectedSystemItem, setSelectedSystemItem] = useState(0);
  const [selectedEquipmentType, setSelectedEquipmentType] = useState(0);
  const [selectedEquipmentGroup, setSelectedEquipmentGroup] = useState(0);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const isFailureModesSystemItemsDirty = useSelector(state => state.maintenance.isFailureModesSystemItemsDirty);
  const systems = useSelector(state => state.maintenance.systems);
  const failureModes = useSelector(state => state.maintenance.failureModes);
  const failureModesSystemItems = useSelector(state => state.maintenance.failureModesSystemItems);
  const equipmentTypes = useSelector(state => state.manager.equipmentTypes);
  const equipmentsGroups = useSelector(state => state.manager.equipmentsGroups);
  const failureModeEquipmentGroup = useSelector(state => state.maintenance.failureModeEquipmentGroup);
  const equipmentGroupsSystemItems = useSelector(state => state.maintenance.equipmentGroupsSystemItems);


  useEffect(() => {
    async function fetchData() {
      dispatch(getSystemItems());
      dispatch(getSystems());
      dispatch(getFailureModes());
      dispatch(getFailureModesSystemItems());
      dispatch(getEquipmentTypes());
      dispatch(getEquipmentsGroups());
      dispatch(getFailureModeEquipmentGroupMaintenanceItems());
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch]);

  useEffect(() => {
    if (selectedEquipmentGroup && selectedSystem) {
      dispatch(getEquipmentGroupsSystemItems(selectedEquipmentGroup, selectedSystem));
    }
  }, [selectedEquipmentGroup, selectedSystem, dispatch]);

  /* funcao resposavel por obter somente os modos de falhas que existem na tabela
  failure mode equipment group */
  const selectedEquipmentFailureGroup = failureModeEquipmentGroup.filter(e => e.equipment_group_id === selectedEquipmentGroup
    && failureModesSystemItems.find(f => f.id === e.id_failure_mode_system_item));


  /* a logica do Checkbox, renderiza os modos de falhas que pertencem a tabela
  failure modes system items, OU renderiza os modos de falha que ja foram add na tabela
  failure mode equipment group  */
  const renderFailureModesSystemItems = () => failureModes
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(failureMode => (
      <div key={failureMode.id} style={{ display: 'flex', alignItems: 'center' }}>
        <input
          type="checkbox"
          checked={failureModesSystemItems.some((systemItem) => {
            const isCurrentFailureMode = systemItem.id_failure_mode_maintenance === failureMode.id
              && systemItem.id_system_item_maintenance === selectedSystemItem
              && systemItem.update_status !== 'D';
            if (selectedEquipmentFailureGroup.length === 0) {
              return isCurrentFailureMode;
            }
            const isCurrentEquipGroup = selectedEquipmentFailureGroup
              .some(equipGroup => systemItem.id === equipGroup.id_failure_mode_system_item
                && systemItem.update_status !== 'D');
            return isCurrentEquipGroup && isCurrentFailureMode;
          })}
          color="primary"
          name={failureMode.id.toString()}
          onChange={e => dispatch(
            setFailureModeSystemItemValue(
              selectedSystemItem, failureMode.id, selectedEquipmentGroup, e.target.checked,
            ),
          )}
          disabled={selectedSystemItem === 0}
        />
        <ListItemText secondary={failureMode.name} style={{ padding: 0, paddingLeft: 5 }} />
      </div>
    ));

  const handleEquipmentType = (e) => {
    const selected = equipmentTypes.some(m => m.update_status);
    if (selected) {
      setIsAlertOpen(true);
    } else {
      setSelectedSystemItem(0);
      setSelectedSystem(0);
      setSelectedEquipmentGroup(0);
      setSelectedEquipmentType(e.target.value);
    }
  };

  const handleEquipmentGroup = (e) => {
    const selected = equipmentsGroups.some(m => m.update_status);
    if (selected) {
      setIsAlertOpen(true);
    } else {
      setSelectedSystemItem(0);
      setSelectedSystem(0);
      setSelectedEquipmentGroup(e.target.value);
    }
  };

  const handleSystemChange = (e) => {
    const selected = failureModesSystemItems.some(m => m.update_status);
    if (selected) {
      setIsAlertOpen(true);
    } else {
      setSelectedSystemItem(0);
      setSelectedSystem(e.target.value);
    }
  };

  const handleSystemItemChange = (e) => {
    const selected = failureModesSystemItems.some(m => m.update_status);
    if (selected) {
      setIsAlertOpen(true);
    } else {
      setSelectedSystemItem(e.target.value);
    }
  };


  return (
    <div className={classes.tabContainer}>
      <div style={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>
        <div style={{ marginRight: 10 }}>
          <div className={classes.containerTypeSelector} style={{ flexDirection: 'column' }}>
            <div className={classes.formLabel}>
              {translate('common:EquipmentType')}
            </div>
            <PF2MSearchSelectOutlined
              value={selectedEquipmentType}
              onChange={handleEquipmentType}
              fullWidth={false}
              className={classes.typeSelector}
            >
              {[{ value: 0, label: translate('common:Select') }]
                .concat(...equipmentTypes
                  .map(e => ({ value: e.id, label: e.name })))}
            </PF2MSearchSelectOutlined>
          </div>
        </div>
        <div style={{ marginRight: 10 }}>
          <div className={classes.containerTypeSelector} style={{ flexDirection: 'column' }}>
            <p className={classes.formLabel}>
              {translate('common:EquipmentGroup')}
            </p>
            <PF2MSearchSelectOutlined
              value={selectedEquipmentGroup}
              onChange={handleEquipmentGroup}
              fullWidth={false}
              className={classes.typeSelector}
            >
              {[{ value: 0, label: translate('common:Select') }]
                .concat(...equipmentsGroups
                  .filter(e => selectedEquipmentType === 0 || e.id_equipament === selectedEquipmentType)
                  .map(e => ({ value: e.id, label: e.name })))}
            </PF2MSearchSelectOutlined>
          </div>
        </div>
        <div style={{ marginRight: 10 }}>
          <div className={classes.containerTypeSelector} style={{ flexDirection: 'column' }}>
            <p className={classes.formLabel}>
              {`${translate('common:FilterSystem')}:`}
            </p>
            <PF2MSearchSelectOutlined
              value={selectedSystem}
              onChange={handleSystemChange}
              fullWidth={false}
              className={classes.typeSelector}
            >
              {[{ value: 0, label: translate('common:Select') }]
                .concat(...systems
                  .map(e => ({ value: e.id, label: e.name })))}
            </PF2MSearchSelectOutlined>
          </div>
        </div>
        <div style={{ marginRight: 10 }}>
          <div className={classes.containerTypeSelector} style={{ flexDirection: 'column' }}>
            <p className={classes.formLabel}>
              {`${translate('common:FilterSystemItem')}:`}
            </p>
            <PF2MSearchSelectOutlined
              value={selectedSystemItem}
              onChange={handleSystemItemChange}
              fullWidth={false}
              className={classes.typeSelector}
            >
              {[{ value: 0, label: translate('common:Select') }]
                .concat(...equipmentGroupsSystemItems
                  .filter(f => f.active === 1)
                  .map(e => ({
                    value: e.system_item_maintenance_id,
                    label: e.system_item_maintenance_name,
                  })))}
            </PF2MSearchSelectOutlined>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '100%' }}>
          <span className={classes.formLabel}>
            {`${translate('common:FailureModes')}:`}
          </span>
          <PF2MScrollbar className={classes.scrollbars} style={{ height: 'auto' }}>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
              {renderFailureModesSystemItems()}
            </div>
          </PF2MScrollbar>
        </div>
      </div>
      <div style={{ marginTop: 20 }}>
        <Footer
          isDirty={isFailureModesSystemItemsDirty}
          discard={() => dispatch(resetFailureModesSystemItems())}
          sendData={() => dispatch(
            updateFailureModesSystemItems(failureModesSystemItems, failureModeEquipmentGroup),
          )}
          style={{ marginTop: 10 }}
        />
      </div>
      <PF2MAlertDialog
        hasCancel={false}
        confirmText={translate('common:Understood')}
        description={translate('validation:YouHaveUnsavedData')}
        open={isAlertOpen}
        onConfirm={() => setIsAlertOpen(false)}
      />
    </div>
  );
};

FailureModeSystemItemsTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

FailureModeSystemItemsTab.defaultProps = {};

export default withStyles(styles)(FailureModeSystemItemsTab);

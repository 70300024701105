/* eslint-disable no-use-before-define */

import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { withStyles } from '@material-ui/core';
import i18n from '../../i18n';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const PF2MAutocomplete = ({
  options, value, onChange, title, ...props
}) => (
  <Autocomplete
    multiple
    id="checkboxes-tags-demo"
    options={[{ value: -1, label: i18n.t('common:SelectAll') }, ...options]}
    value={value}
    onChange={(e, val) => {
      if (val.length && val[val.length - 1].value === -1) {
        return onChange(e, options);
      }
      return onChange(e, val);
    }}
    disableCloseOnSelect
    getOptionLabel={option => option.label}
    getOptionSelected={(option, optionValue) => option.value === optionValue.value
      && !(option.value === -1)}
    size="small"
    renderOption={(option, { selected }) => (
      <React.Fragment>
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          checked={selected}
        />
        {option.label}
      </React.Fragment>
    )}
    style={{ width: 200 }}
    renderInput={params => (
      <TextField {...params} variant="outlined" label={title} placeholder={title} />
    )}
    {...props}
  />
);

PF2MAutocomplete.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string,
};
PF2MAutocomplete.defaultProps = {
  title: 'Select',
};

export default withStyles({
  inputRoot: { flexWrap: 'nowrap', overflow: 'hidden' },
  endAdornment: { backgroundColor: 'white', borderRadius: '20px' },
})(PF2MAutocomplete);

import { createTheme } from '@material-ui/core/styles';

export const config = {
  showTitle: false,
  pageSize: 10,
  search: false,
  sorting: true,
  tableLayout: 'auto',
  maxBodyHeight: 'calc(100vh - 140px)',

};

export const localization = translate => ({
  pagination: {
    labelDisplayedRows: '{from}-{to} of {count}',
  },
  toolbar: {
    nRowsSelected: '{0} row(s) selected',
  },
  header: {
    actions: translate('common:Edit'),
  },
  body: {
    emptyDataSourceMessage: 'No records to display',
    filterRow: {
      filterTooltip: 'Filter',
    },
  },
});

export const theme = createTheme({
  overrides: {
    MuiTableCell: {
      root: {
        padding: '4px 5px',
        whiteSpace: 'nowrap',
      },
    },
    MuiPaper: {
      elevation2: {
        boxShadow: 'none',
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
      },
    },
  },
});

import React, { useEffect, useCallback } from 'react';
import { withStyles } from '@material-ui/core';
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import PF2MMenu from '~/components/PF2MMenu';
import ProjectGroups from '../ProjectGroups';
import UndergroundCodes from './UndergroundCodes';
import Operation from '../Operation';
import MonthlyAdvance from '../MonthlyAdvance';
import SubElementPriority from '../SubElementPriority';
import OperationalControl from '../OperationalControl';
import UndergroundTurn from './UndergroundTurn';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import PF2MScrollbar from '~/components/PF2MScrollbar';
import Main from '../Main';
import { setAlertDialog } from '~/store/manager/actions';
import UndergroundDailyParts from './UndergroundDailyParts';
import { ZendeskAPI } from 'react-zendesk';

const Underground = ({
  classes,
  match,
  history,
  isProjectGroupsDirty,
  isProjectSubElementsDirty,
  alertDialog,
  setAlertDialog: setDialog,
  isCodeTypesDirty,
  isCodeGroupsDirty,
  isPrioritiesDirty,
  isUnitOperationGroupsDirty,
  isUnitOperationsDirty,
  isMonthlyAdvancesDirty,
  isCodesDirty,
  isInputUnitsDirty,
}) => {
  const { t: translate } = useTranslation();

  const validateNavigation = useCallback(({ pathname = '' }) => {
    if ((isProjectGroupsDirty || isProjectSubElementsDirty) && !pathname.includes('projectgroups')) {
      setDialog();
      return false;
    }
    if ((isCodeTypesDirty || isCodeGroupsDirty || isCodesDirty) && !pathname.includes('undergroundcodes')) {
      setDialog();
      return false;
    }
    if ((isUnitOperationGroupsDirty || isUnitOperationsDirty)
      && !pathname.includes('operation')) {
      setDialog();
      return false;
    }

    if (isPrioritiesDirty && !pathname.includes('priority')) {
      setDialog();
      return false;
    }

    if (isMonthlyAdvancesDirty && !pathname.includes('monthlyadvance')) {
      setDialog();
      return false;
    }

    if (isInputUnitsDirty && !pathname.includes('inputunit')) {
      setDialog();
      return false;
    }

    return true;
  }, [
    isProjectGroupsDirty,
    isProjectSubElementsDirty,
    setDialog,
    isCodeTypesDirty,
    isCodeGroupsDirty,
    isCodesDirty,
    isPrioritiesDirty,
    isMonthlyAdvancesDirty,
    isUnitOperationGroupsDirty,
    isUnitOperationsDirty,
    isInputUnitsDirty,
  ]);

  const handleNavigation = (e, pathname = '') => {
    if (!validateNavigation(pathname)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (history.location && history.location.length > 0) {
      validateNavigation(history.location);
    }
  }, [history.location, validateNavigation]);


  useEffect(() => {
    const timer = setTimeout(() => {
      ZendeskAPI('webWidget', 'hide');
    }, 1000);
    return () => clearTimeout(timer);
  }, []);


  return (
    <Main>
      <div className={classes.container}>
        <PF2MScrollbar style={{ width: 150, padding: 10 }}>
          <PF2MMenu
            handleNavigation={handleNavigation}
            moduleName="underground"
          />
        </PF2MScrollbar>
        <div style={{ flex: 1 }}>
          <Switch>
            <Route exact path={`${match.path}/operationalcontrol`} component={OperationalControl} />
            <Route exact path={`${match.path}/dailyparts`} component={UndergroundDailyParts} />
            <Route exact path={`${match.path}/projectgroups`} component={ProjectGroups} />
            <Route exact path={`${match.path}/undergroundcodes`} component={UndergroundCodes} />
            <Route exact path={`${match.path}/operation`} component={Operation} />
            <Route exact path={`${match.path}/priority`} component={SubElementPriority} />
            <Route exact path={`${match.path}/monthlyadvance`} component={MonthlyAdvance} />
            <Route exact path={`${match.path}/turn`} component={UndergroundTurn} />
            <Redirect from={`${match.path}`} to={`${match.path}/operationalcontrol`} />
          </Switch>
        </div>
      </div>
      <PF2MAlertDialog
        hasCancel={false}
        confirmText={translate('common:Understood')}
        description={translate('validation:YouHaveUnsavedData')}
        open={alertDialog}
        onConfirm={() => setDialog()}
      />
    </Main>
  );
};

Underground.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isProjectGroupsDirty: PropTypes.bool,
  isProjectSubElementsDirty: PropTypes.bool,
  alertDialog: PropTypes.bool,
  setAlertDialog: PropTypes.func,
  isCodeTypesDirty: PropTypes.bool,
  isCodeGroupsDirty: PropTypes.bool,
  isPrioritiesDirty: PropTypes.bool,
  isUnitOperationGroupsDirty: PropTypes.bool,
  isUnitOperationsDirty: PropTypes.bool,
  isMonthlyAdvancesDirty: PropTypes.bool,
  isCodesDirty: PropTypes.bool,
  isInputUnitsDirty: PropTypes.bool,
};

Underground.defaultProps = {
  isProjectGroupsDirty: false,
  isProjectSubElementsDirty: false,
  alertDialog: false,
  setAlertDialog: null,
  isCodeTypesDirty: false,
  isCodeGroupsDirty: false,
  isPrioritiesDirty: false,
  isUnitOperationGroupsDirty: false,
  isUnitOperationsDirty: false,
  isMonthlyAdvancesDirty: false,
  isCodesDirty: false,
  isInputUnitsDirty: false,
};

const mapStateToProps = state => ({
  isProjectGroupsDirty: state.underground.isProjectGroupsDirty,
  isProjectSubElementsDirty: state.underground.isProjectSubElementsDirty,
  alertDialog: state.manager.alertDialog,
  isCodeTypesDirty: state.underground.isCodeTypesDirty,
  isCodeGroupsDirty: state.underground.isCodeGroupsDirty,
  isPrioritiesDirty: state.underground.isPrioritiesDirty,
  isUnitOperationGroupsDirty: state.underground.isUnitOperationGroupsDirty,
  isUnitOperationsDirty: state.underground.isUnitOperationsDirty,
  isMonthlyAdvancesDirty: state.underground.isMonthlyAdvancesDirty,
  isCodesDirty: state.underground.isCodesDirty,
  isUndergroundListDirty: state.dailyParts.isUndergroundListDirty,
  isInputUnitsDirty: state.underground.isInputUnitsDirty,
});

const mapDispatchToProps = {
  setAlertDialog,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withStyles(styles)(Underground)));

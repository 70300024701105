import React, { useState, useEffect } from 'react';
import {
  withStyles,
  Checkbox,
  ListItemText,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import PF2MScrollbar from '~/components/PF2MScrollbar';
import Footer from '~/pages/Manager/Footer';
import Header from '~/pages/Manager/Header';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import {
  getElements,
  getElementsTemplate,
  exportElements,
  importElements,
} from '~/store/manager/actions';

import {
  getLineupElements,
  restoreLineupElements,
  toggleLineupElementAssociation,
  updateLineupElements,
} from '~/store/lineup/actions';

const ElementsTab = ({
  classes,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const selectedLineup = useSelector(state => state.lineup.selectedLineup);
  const elements = useSelector(state => state.manager.elements);
  const lineupElements = useSelector(state => state.lineup.lineupElements);
  const isLineupElementsDirty = useSelector(state => state.lineup.isLineupElementsDirty);

  useEffect(() => {
    async function fetchData() {
      dispatch(getElements(true));
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [selectedLineup, dataLoaded, dispatch]);

  useEffect(() => {
    if (selectedLineup) {
      dispatch(getLineupElements(selectedLineup));
    }
  }, [selectedLineup, dispatch]);

  const renderElements = items => items
    .map(s => (
      <div key={s.id} style={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox
          checked={lineupElements.filter(r => r.update_status !== 'D').map(r => r.element_id).includes(s.id)}
          color="primary"
          name={s.id.toString()}
          onChange={() => dispatch(toggleLineupElementAssociation(s.id))}
        />
        <ListItemText secondary={s.name} style={{ padding: 0 }} />
      </div>
    ));

  return (
    <div className={classes.tabContainer}>
      <Header
        getTemplateAction={() => dispatch(getElementsTemplate())}
        importAction={file => dispatch(importElements(file))}
        exportAction={() => dispatch(exportElements())}
      />
      <span className={classes.label}>
        {`${translate('common:ElementsAssociatedToTheScenario')}:`}
      </span>
      <PF2MScrollbar className={classes.scrollbars} style={{ height: 'auto' }}>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}>
          {renderElements(elements)}
        </div>
      </PF2MScrollbar>
      <Footer
        isDirty={isLineupElementsDirty}
        sendData={() => dispatch(updateLineupElements(lineupElements, selectedLineup))}
        discard={() => dispatch(restoreLineupElements())}
      />
    </div>
  );
};

ElementsTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

ElementsTab.defaultProps = {
};

export default withStyles(styles)(ElementsTab);

/* eslint-disable max-len */
import { convertToUrlParameters, downloadAxios } from '~/utils';
import api from '~/services/api';
import * as types from './types';
import { getAll } from '../manager/actions';
import {
  handleErrors,
} from '../common';
import {
  GET_GENERIC_ERROR, SET_UPLOAD_ERROR, SET_UPLOAD_SUCCESS, SET_UPLOAD_SUCCESS_WARNING,
} from '../errors/types';
import i18n from '~/i18n';

export const a = 1;

export const getTemplate = (domain, filters) => async () => {
  try {
    const queryString = convertToUrlParameters(filters);
    const url = `${process.env.REACT_APP_API_URL}/goal/${domain}/files?${queryString}`;
    await downloadAxios(url, `template_${domain}.xlsx`);
  // eslint-disable-next-line no-empty
  } catch ({ response }) {
  }
};

const getEndpoints = {
  goalTypes: {
    url: '/goal/goaltype',
    responseObject: 'goal_types',
    typeAction: types.SET_GOAL_TYPES_LIST,
  },
  goalTypesLevels: {
    url: '/goal/goaltypelevels',
    responseObject: 'goal_types_levels',
    typeAction: types.SET_GOAL_TYPES_LEVELS_LIST,
  },
  goals: {
    url: '/goal/goal',
    responseObject: 'goals',
    typeAction: types.REMAP_GOAL_TABLE_LIST,
  },
};

export const get = (endpoint, skipLoading, onLoad, filters = {}) => async (dispatch) => {
  try {
    if (skipLoading) {
      dispatch({ type: 'SKIP_LOADING', payload: true });
    }
    const queryString = convertToUrlParameters(filters);
    const endpointUrl = `${endpoint.url}?${queryString}`;
    const {
      data: {
        result: { [endpoint.responseObject]: data = [] },
      },
    } = await api.get(endpointUrl);

    dispatch({
      type: endpoint.typeAction,
      payload: data,
    });

    if (onLoad !== undefined && typeof onLoad === 'function') {
      onLoad(data);
    }
    if (skipLoading) {
      dispatch({ type: 'SKIP_LOADING', payload: false });
    }
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const importData = (
  domain,
  file,
  shouldGetAll = null,
  filters = {},
) => async (dispatch) => {
  let clearUploadError = false;
  try {
    dispatch({ type: SET_UPLOAD_ERROR, payload: '' });
    const queryString = convertToUrlParameters(filters);
    const result = await api.put(`/goal/${domain}/files?${queryString}`, file);
    const { data, status } = result;
    if (status === 200 && data.errors && !shouldGetAll) {
      dispatch({
        type: SET_UPLOAD_SUCCESS_WARNING,
        payload: {
          success: `${i18n.t('validation:SheetImportSuccess')}\n${data.errors[0].message}`,
          error: data.errors[0].message,
        },
      });
    } else if (status === 200) {
      dispatch({ type: SET_UPLOAD_SUCCESS, payload: i18n.t('validation:SheetImportSuccess') });
      dispatch(get(getEndpoints.goals, false, () => {}, filters));
    }
    clearUploadError = true;
  } catch (error) {
    const { response } = error;
    if (response) {
      dispatch({
        type: SET_UPLOAD_ERROR,
        payload: response.data.errors[0].message,
      });
    } else {
      dispatch({
        type: GET_GENERIC_ERROR,
        payload: i18n.t('common:ServerError'),
      });
    }
  } finally {
    if (shouldGetAll) {
      dispatch(getAll({ domain, clearUploadError, filters }));
    }
  }
};

export const updateData = (
  domain,
  data,
  refreshData = false,
  all = false,
  filters = {},
  callback = null,
) => async (dispatch) => {
  try {
    const endpoint = getEndpoints[domain];
    const body = {
      [endpoint.responseObject]: data,
    };
    const { status } = await api.post(`${endpoint.url}`, body);
    if (callback) {
      callback();
    }
    if (status === 200 && refreshData) {
      dispatch(getAll({ domain, all, filters }));
    }
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const updateGoals = (data, callback) => async dispatch => dispatch(updateData('goals', data, false, false, {}, callback));
export const setGoalTableList = data => async (dispatch) => {
  dispatch({
    type: types.SET_GOAL_TABLE_LIST,
    payload: data,
  });
};
export const setGoalTableValue = item => async (dispatch) => {
  dispatch({
    type: types.SET_GOAL_TABLE_VALUES,
    payload: item,
  });
};

const readyGoalTableToExport = (filters, data) => async (dispatch) => {
  const dataZeroed = data.map(item => ({
    ...item,
    day1: item.day1 ? item.day1 : 0,
  }));
  dispatch(updateGoals(dataZeroed, () => {
    dispatch(getTemplate('goal', filters));
  }));
};

export const getGoalTypes = () => async dispatch => dispatch(get(getEndpoints.goalTypes, true));
export const getGoalTypesLevels = () => async dispatch => dispatch(get(getEndpoints.goalTypesLevels, true));
export const getGoals = filters => async dispatch => dispatch(get(getEndpoints.goals, true, null, filters));

export const getGoalsTemplate = (filters, data) => async (dispatch) => {
  dispatch(readyGoalTableToExport(filters, data));
};
export const importGoals = (file, filters) => async dispatch => dispatch(importData('goal', file, false, filters));

export const getOriginGoalsTemplate = filters => async dispatch => dispatch(getTemplate('origin', filters));
export const getDestinationGoalsTemplate = filters => async dispatch => dispatch(getTemplate('destination', filters));
export const getMaterialGoalsTemplate = filters => async dispatch => dispatch(getTemplate('material', filters));
export const getLoadProductionGoalsTemplate = filters => async dispatch => dispatch(getTemplate('loadproduction', filters));
export const getDrillingProductionGoalsTemplate = filters => async dispatch => dispatch(getTemplate('drillingproduction', filters));
export const getTransportProductionGoalsTemplate = filters => async dispatch => dispatch(getTemplate('transport', filters));
export const getCodeTypeGoalsTemplate = filters => async dispatch => dispatch(getTemplate('codetypes', filters));

export const importOriginGoals = file => async dispatch => dispatch(importData('goal/origin', file));
export const importDestinationGoals = file => async dispatch => dispatch(importData('goal/destination', file));
export const importMaterialGoals = file => async dispatch => dispatch(importData('goal/material', file));
export const importLoadProductionGoals = file => async dispatch => dispatch(importData('goal/loadproduction', file));
export const importDrillingProductionGoals = file => async dispatch => dispatch(importData('goal/drillingproduction', file));
export const importTransportProductionGoals = file => async dispatch => dispatch(importData('goal/transport', file));
export const importCodeTypeGoals = file => async dispatch => dispatch(importData('goal/codetypes', file));

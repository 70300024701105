import React from 'react';
import PropTypes from 'prop-types';
import SubElementCard from '../SubElementCard';
import PF2MScrollbar from '~/components/PF2MScrollbar';


const PriorityQueue = ({ subElementsList, filters, dispatch }) => (
  <PF2MScrollbar style={{
    display: 'flex',
    flexDirection: 'row wrap',
    overflowX: 'scroll',
    maxWidth: '80vw',
    margin: '0 auto',
    height: 'fit-content',
    padding: 10,
    backgroundColor: '#e6e7e8',
    borderRadius: 5,
  }}
  >
    {subElementsList.map(e => (
      <SubElementCard
        subElement={e}
        key={e.id}
        filters={filters}
        dispatch={dispatch}
      />
    ))}
  </PF2MScrollbar>
);

PriorityQueue.propTypes = {
  subElementsList: PropTypes.object.isRequired,
  filters: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
};

PriorityQueue.defaultProps = {
  filters: {},
};

export default PriorityQueue;

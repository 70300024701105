import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Checkbox, FormControlLabel, Typography, withStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import {
  addCycleAdvance,
  changeCycleAdvance,
  deleteCycleAdvance,
  exportCycleAdvance,
  getCycleAdvance,
  importCycleAdvance,
  restoreCycleAdvance,
  updateCycleAdvance,
} from '~/store/underground/actions';
import { getElements, getSubElements } from '~/store/manager/actions';
import PF2MDataTable from '~/components/PF2MDataTable';
import useUploadFile from '~/components/PF2MDataTable/uploadFile';
import PF2MAutocomplete from '~/components/PF2MAutocomplete';

const UndergroundCicleAdvanceTab = ({ classes }) => {
  const { t: translate } = useTranslation();
  const cicleAdvances = useSelector(state => state.underground.cicleAdvances);
  const isCicleAdvancesDirty = useSelector(state => state.underground.isCicleAdvancesDirty);
  const [filters, setFilters] = useState({ origin: true, destination: true });
  const [elementFilters, setElementFilters] = useState([]);
  const elementFiltersValues = elementFilters.map(f => f.value);
  const elements = useSelector(state => state.manager.elements);
  const subElements = useSelector(state => state.manager.subElements);
  const dispatch = useDispatch();
  const [input, choseFile] = useUploadFile({
    onFileChange: (selectedFile) => {
      dispatch(importCycleAdvance(selectedFile, elementFiltersValues));
    },
  });

  useEffect(() => {
    dispatch(getCycleAdvance(true, elementFiltersValues));
  // eslint-disable-next-line
  }, [elementFilters]);

  useEffect(() => {
    dispatch(getElements());
    dispatch(getSubElements());
  }, [dispatch]);

  const columns = [
    {
      title: translate('common:Element'),
      field: 'id_element',
      editable: 'never',
      customType: 'filterLookup',
      lookup: elements,
      validate: row => Boolean(row.id_element_point),
    },
    {
      title: translate('common:SubElement'),
      field: 'id_element_point',
      customType: 'filterLookup',
      lookup: subElements,
      lookupFilter: (option, row) => (
        String(row.id_element) === '0'
        || String(option.id_element) === String(row.id_element)),
      validate: row => Boolean(row.id_element_point),
      editable: 'never',
    },
    {
      title: translate('common:Cycle'),
      field: 'cicle',
      editable: 'never',
    },
    {
      title: translate('common:StartDate'),
      field: 'timestamp',
      type: 'date',
      editable: 'never',
      customType: 'dateTimeByTimestamp',
    },
    {
      title: translate('common:EndDate'),
      field: 'end_timestamp',
      type: 'date',
      editable: 'never',
      customType: 'dateTimeByTimestamp',
    },
    {
      title: translate('common:EstimatedAdvance'),
      field: 'estimated_advance',
    },
    {
      title: translate('common:MeasuredAdvance'),
      field: 'measured_advance',
    },
  ];

  return (
    <div className={classes.tabContainer}>
      {input}
      <div style={{ display: 'flex' }}>
        <PF2MAutocomplete
          options={elements.filter(
            e => (!!e.is_origin === filters.origin
              && !!e.is_destination === filters.destination),
          ).map(e => ({ value: e.id, label: e.name }))}
          onChange={(_, v) => {
            setElementFilters(v);
          }}
          value={elementFilters}
          limitTags={1}
        />
        <FormControlLabel
          key={'is_origin'}
          style={{ marginLeft: 0 }}
          control={(
            <Checkbox
              checked={filters.origin}
              onChange={e => setFilters(f => ({ ...f, origin: e.target.checked }))}
            />
          )}
          label={(
            <Typography style={styles.checkboxLabel}>
              {translate('common:Origins')}
            </Typography>
          )}
        />
        <FormControlLabel
          key={'is_destination'}
          style={{ marginLeft: 0 }}
          control={(
            <Checkbox
              checked={filters.destination}
              onChange={e => setFilters(f => ({ ...f, destination: e.target.checked }))}
            />
          )}
          label={(
            <Typography style={styles.checkboxLabel}>
              {translate('common:Destinations')}
            </Typography>
          )}
        />
      </div>

      <PF2MDataTable
        options={{
          search: true,
          selection: false,
          hideCommit: !isCicleAdvancesDirty,
          hideReload: !isCicleAdvancesDirty,
          hideDelete: true,
        }}
        columns={columns}
        data={cicleAdvances.map(c => ({
          ...c, timestamp: c.timestamp * 1000, end_timestamp: c.end_timestamp * 1000,
        }))}
        onDelete={cicle => dispatch(deleteCycleAdvance(cicle))}
        onChange={cicle => cicle.forEach((row) => {
          dispatch(
            changeCycleAdvance({
              ...row,
              timestamp: row.timestamp / 1000,
              end_timestamp: row.end_timestamp / 1000,
            }),
          );
        })
        }
        onApply={() => dispatch(updateCycleAdvance(cicleAdvances, elementFiltersValues, true))}
        onDiscard={() => dispatch(restoreCycleAdvance())}
        onExport={() => dispatch(exportCycleAdvance(elementFiltersValues))}
        onImport={choseFile}
        onAdd={cicle => dispatch(addCycleAdvance({
          ...cicle,
          isDirty: false, // item still not sendable because empty values
        }))}
        initialFormData={{
          id_element_point: 0,
          id_element: [],
          cicle: 0,
          estimated_advance: 0,
          measured_advance: 0,
        }}
      />
    </div>
  );
};

UndergroundCicleAdvanceTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UndergroundCicleAdvanceTab);

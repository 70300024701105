import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PF2MCustomDragLayer from '~/components/PF2MCustomDragLayer';
import styles from './styles';
import MappingsTab from './MappingsTab';
import AllocationsTab from './AllocationsTab';
import AutoAllocationLogs from './AutoAllocationLogs';
import PF2MTabs from '~/components/PF2MTabs';

const LoadSchedules = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { t: translate } = useTranslation();
  const tabs = [
    { key: 0, label: translate('common:Allocation') },
    { key: 1, label: translate('common:Mapping') },
    { key: 2, label: translate('common:AutoAllocationLogs'), allowedUserLevels: [1] },
  ];

  const handleChange = (event, value) => {
    setSelectedTab(value);
  };

  return (
    <>
      <PF2MTabs
        value={selectedTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        hideSyncButton
      >
        {tabs}
      </PF2MTabs>
      {selectedTab === 0 ? (
        <>
          <AllocationsTab />
          <PF2MCustomDragLayer />
        </>
      ) : null}
      {selectedTab === 1 ? (<MappingsTab />) : null}
      {selectedTab === 2 ? (<AutoAllocationLogs />) : null}
    </>
  );
};

export default withStyles(styles)(LoadSchedules);

import React, { useState, useEffect } from 'react';
import {
  withStyles,
  Checkbox,
  ListItemText,
  FormLabel,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import PF2MScrollbar from '~/components/PF2MScrollbar';
import Footer from '~/pages/Manager/Footer';
import Header from '~/pages/Manager/Header';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import {
  getElements,
  getSubElements,
  getSubElementsTemplate,
  exportSubElements,
  importSubElements,
} from '~/store/manager/actions';

import {
  getLineupSubelements,
  updateLineupSubelements,
  restoreLineupSubelements,
  toggleLineupSubelementAssociation,
  selectAllLineupSubelements,
  deselectAllLineupSubelements,
} from '~/store/lineup/actions';

const SubElementsTab = ({
  classes,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const selectedLineup = useSelector(state => state.lineup.selectedLineup);
  const elements = useSelector(state => state.manager.elements);
  const subelements = useSelector(state => state.manager.subElements
    .filter(s => state.lineup.lineupElements.map(r => r.element_id).includes(s.id_element)));
  const lineupElementIds = useSelector(state => state.lineup.lineupElements
    .map(r => r.element_id));
  const lineupSubelements = useSelector(state => state.lineup.lineupSubelements);
  const isLineupSubelementsDirty = useSelector(state => state.lineup.isLineupSubelementsDirty);
  const [selectedElements, setSelectedElements] = useState([]);
  const [allSelected, setAllSelected] = useState(false);

  useEffect(() => {
    async function fetchData() {
      dispatch(getElements(true));
      dispatch(getSubElements(true));
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [selectedLineup, dataLoaded, dispatch]);

  useEffect(() => {
    if (selectedLineup) {
      dispatch(getLineupSubelements(selectedLineup));
    }
  }, [selectedLineup, dispatch]);

  const renderSubelements = items => items
    .map(s => (
      <div key={s.id} style={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox
          checked={lineupSubelements
            .filter(r => r.update_status !== 'D')
            .map(r => r.element_point_id).includes(s.id)}
          color="primary"
          name={s.id.toString()}
          onChange={() => {
            dispatch(toggleLineupSubelementAssociation(s.id));
            if (allSelected) {
              setAllSelected(false);
            }
          }}
        />
        <ListItemText secondary={s.name} style={{ padding: 0 }} />
      </div>
    ));

  const renderSelectAll = () => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Checkbox
        checked={allSelected}
        color="primary"
        name="all"
        onChange={() => {
          if (allSelected) {
            setAllSelected(false);
            dispatch(deselectAllLineupSubelements(
              subelements
                .filter(r => selectedElements.includes(r.id_element) || !selectedElements.length)
                .map(r => r.id),
              selectedLineup,
            ));
            return;
          }
          setAllSelected(true);
          dispatch(selectAllLineupSubelements(
            subelements
              .filter(r => selectedElements.includes(r.id_element) || !selectedElements.length)
              .map(r => r.id),
            selectedLineup,
          ));
        }}
      />
      <FormLabel style={{ padding: 0, margin: 0 }}>
        {translate('common:SelectAll')}
      </FormLabel>
    </div>
  );

  return (
    <div className={classes.tabContainer}>
      <Header
        getTemplateAction={() => dispatch(getSubElementsTemplate())}
        importAction={file => dispatch(importSubElements(file))}
        exportAction={() => dispatch(exportSubElements())}
      />
      <div style={{ marginBottom: 20, width: 300 }}>
        <span className={classes.label}>
          {`${translate('common:Element')}:`}
        </span>
        <PF2MSearchSelectOutlined
          value={selectedElements}
          multiple
          placeholder={translate('common:Select')}
          onChange={e => setSelectedElements([...e.target.value.map(r => r.value)])}
        >
          {elements
            .filter(r => lineupElementIds.includes(r.id))
            .map(e => ({ value: e.id, label: e.name }))}
        </PF2MSearchSelectOutlined>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span
          style={{ display: 'flex', alignItems: 'center' }}
          className={classes.label}
        >
          {`${translate('common:SubelementsAssociatedToTheScenario')}:`}
        </span>
        {renderSelectAll()}
      </div>
      <PF2MScrollbar className={classes.scrollbars} style={{ height: 'auto' }}>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}>
          {renderSubelements(subelements
            .filter(r => selectedElements.includes(r.id_element) || !selectedElements.length))}
        </div>
      </PF2MScrollbar>
      <Footer
        isDirty={isLineupSubelementsDirty}
        sendData={() => {
          dispatch(updateLineupSubelements(lineupSubelements, selectedLineup));
          setAllSelected(false);
        }}
        discard={() => {
          dispatch(restoreLineupSubelements());
          setAllSelected(false);
        }}
      />
    </div>
  );
};

SubElementsTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

SubElementsTab.defaultProps = {
};

export default withStyles(styles)(SubElementsTab);

import React, { useState, useEffect } from 'react';
import { withStyles, Modal } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import PF2MTable from '~/components/PF2MTable';
import PF2MSelectOutlined from '~/components/PF2MSelectOutlined';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import PF2MButton from '~/components/PF2MButton';
import { useTranslation } from 'react-i18next';
import ControlSyncTable from './ControlSyncTable';
import {
  getAllTablets, getApkVersion, getTabletUpdate, postTabletUpdate,
} from '~/store/admin/actions';
import styles from '../styles';

// Separation for the tablet_filter_id feature flag
const AppTab = ({ classes }) => {
  const dispatch = useDispatch();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [apkVersion, setApkVersion] = useState(0);
  const [csTabletId, setCsTabletId] = useState(false);
  const admin = useSelector(state => state.admin);
  const { t: translate } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      await dispatch(getTabletUpdate());
      await dispatch(getAllTablets());
      await dispatch(getApkVersion());
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
      setSelectedItems([...selectedItems, ...admin.tablet_update.map(e => e.tablet_id)]);
    }
  }, [dataLoaded, dispatch, admin, selectedItems, csTabletId]);

  const columns = [
    {
      field: 'id',
      title: translate('common:ControlSync').toUpperCase(),
      width: '10%',
      button: true,
      onClick: (e) => { setCsTabletId(e.id); },
      disabled: () => false,
    },
    {
      field: 'tablet_id',
      title: translate('common:Name').toUpperCase(),
      width: '42%',
    },

    {
      field: 'version',
      title: translate('common:CurrentVersion').toUpperCase(),
      width: '42%',
    },
  ];

  const findLastVersion = (tabletId) => {
    if (apkVersion === 0) return false;
    const selectedVersion = parseInt(apkVersion.replace(/\./g, ''), 10);
    const actualVersion = admin.tablet_update.find(e => e.tablet_id === tabletId);
    return actualVersion ? parseInt(actualVersion.version.replace(/\./g, ''), 10) < selectedVersion : true;
  };

  const dataTablets = admin.tablets.map((e) => {
    const version = admin.tablet_update
      .filter(el => el.tablet_id === e)
      .sort(el => el.id)[0];
    const isToUpdate = !findLastVersion(e);
    return {
      id: e,
      tablet_id: e,
      version: version ? version.version : '-',
      checked: isToUpdate,
    };
  });

  if (admin.tablets_update && selectedItems.length === 0 && admin.tablets_update.length > 0) {
    setSelectedItems(selectedItems.concat(admin.tablets_update.map(e => e.tablet_id)));
  }

  const selectItem = (data, isChecked) => {
    if (!isChecked) {
      const unselected = selectedItems.filter(e => data.id !== e);
      setSelectedItems(unselected);
    } else {
      setSelectedItems([...selectedItems, data.id]);
    }
  };

  const renderVersions = admin.apkversion.map(
    e => <MenuItem key={e.version} value={e.version}>{e.version}</MenuItem>,
  );
  return (
    <>
      <div className={classes.containerTypeSelector}>
        <div className={classes.labelTypeSelector}>{translate('common:UpdateToVersion')}</div>
        <PF2MSelectOutlined
          className={classes.typeSelector}
          onChange={e => setApkVersion(e.target.value)}
          placeholder={null}
          value={apkVersion}
        >
          {[<MenuItem key={0} value={0}>{translate('common:Select')}</MenuItem>]
            .concat(...renderVersions)}
        </PF2MSelectOutlined>
      </div>
      {apkVersion !== 0
      && (
      <>
        <div className={classes.tabContainer}>
          <PF2MTable
            columns={columns}
            data={dataTablets}
            selectedItems={selectedItems}
            selectItem={selectItem}
            disable
            disableSelectCheckboxItem={e => !findLastVersion(e.id)}
            selectAll={data => setSelectedItems(data.map(e => e.id))}
          />
        </div>
        <div className={classes.buttonContainer}>
          <PF2MButton
            color="primary"
            onClick={() => {
              setSelectedItems([]);
            }}
            disabled={selectedItems.length === 0}
          >
            <DeleteOutline className={classes.icon} />
            {translate('common:DiscardChanges')}
          </PF2MButton>
          <PF2MButton
            onClick={
              () => { dispatch(postTabletUpdate(selectedItems, apkVersion)); setSelectedItems([]); }
            }
            disabled={selectedItems.length === 0}
          >
            {translate('common:UpdateTablets')}
          </PF2MButton>
        </div>
      </>
      )}
      <Modal
        open={csTabletId !== false}
        onBackdropClick={() => setCsTabletId(false)}
      >
        <div>
          {csTabletId !== false
        && <ControlSyncTable tabletId={csTabletId} onSend={() => { setCsTabletId(false); }} />}
        </div>
      </Modal>
    </>
  );
};

AppTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppTab);

/* eslint-disable import/prefer-default-export */
import * as THREE from 'three';

const textureToCanvasData = (texture) => {
  const canvas = document.createElement('canvas');
  canvas.width = texture.image.width;
  canvas.height = texture.image.height;
  const ctx = canvas.getContext('2d');
  // draw image to canvas
  ctx.drawImage(texture.image, 0, 0);
  // get image data
  const imageData = ctx.getImageData(0, 0, texture.image.width, texture.image.height);
  return imageData;
};

const calculateHeight = (imageData, scale) => {
  const g = new THREE.PlaneBufferGeometry(1024, 1024, 1023, 1023);
  g.rotateX(Math.PI * -0.5);
  const pos = g.attributes.position;

  // get height data from image data
  const heightData = imageData.data.filter((_, i) => i % 4 === 0);
  for (let i = 0; i < heightData.length; i += 1) {
    // check if i is multiple of 1024
    if (i % 1024 === 0
        || i < 1024
        || i > heightData.length - 1024
    ) {
      pos.setY(i, 0);
    } else {
      pos.setY(i, heightData[i] / 255 * scale);
    }
  }
  g.computeVertexNormals();
  return g;
};

export const computeHeight = (heightMap, scale) => {
  const imageData = textureToCanvasData(heightMap);
  const geometry = calculateHeight(imageData, scale);
  return geometry;
};

export const removeGeometryIntersections = (g1, g2) => {
  const g1Pos = g1.attributes.position.array;
  const g2Pos = g2.attributes.position.array;
  const g = new THREE.PlaneGeometry(1024, 1024, 1023, 1023);
  g.rotateX(Math.PI * -0.5);
  for (let i = 1; i < g1Pos.length; i += 3) {
    g.attributes.position.array[i] = g2Pos[i] === 0 ? g1Pos[i] : g2Pos[i] - 0.1;
  }
  g.computeVertexNormals();
  return g;
};


export const compareTextures = (textures) => {
  if (!textures.every(texture => texture.image.width === textures[0].image.width)) {
    return false;
  }
  if (!textures.every(texture => texture.image.height === textures[0].image.height)) {
    return false;
  }
  const canvasList = textures.map((texture) => {
    const canvas = document.createElement('canvas');
    canvas.width = texture.image.width;
    canvas.height = texture.image.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(texture.image, 0, 0);
    return ctx.getImageData(0, 0, texture.image.width, texture.image.height);
  });
  const imageData = canvasList[0].data.map(
    (v, i) => (!canvasList.every(canvas => canvas.data[i] === v) ? v : 0),
  );
  return calculateHeight({ data: imageData }, 120);
};

export const createNewModel = (geometry, color) => {
  const material = new THREE.MeshLambertMaterial({
    color,
    side: THREE.DoubleSide,
    wireframe: false,
  });
  const mesh = new THREE.Mesh(geometry, material);
  mesh.castShadow = true;
  mesh.receiveShadow = true;
  return mesh;
};

export const getHeight = (x, y, g) => {
  const index = (Math.floor(x) + 512) + ((Math.floor(y) + 512) * 1024);
  const gpos = g.attributes.position.getY(index);
  return gpos || 0;
};

const utmToXY = (utm, originCoord, ppu) => (utm - originCoord) / ppu;

const getZ = (x, y, g) => getHeight(x, y, g) + 0.8;

export const createPolygon = (polygonData = [], name, originPoint, ppu, g) => {
  const pointList = [];
  polygonData.forEach((p, i) => {
    const x1 = utmToXY(p[1], originPoint[1], ppu);
    const y1 = utmToXY(p[0], originPoint[0], ppu);
    const z1 = getZ(x1, y1, g);
    if (i < polygonData.length - 1) {
      const x2 = (polygonData[i + 1][1] - originPoint[1]) / ppu;
      const y2 = (polygonData[i + 1][0] - originPoint[0]) / ppu;
      const z2 = getHeight(x2, y2, g) + 0.8;
      const zDistance = Math.abs(z1 - z2);
      if (zDistance > 1 && zDistance < 30) {
        let x3 = 0;
        let y3 = 0;
        let z3 = 0;
        for (let j = 0; j < zDistance; j += 0.25) {
          const x = x1 + (x2 - x1) * (j / zDistance);
          const y = y1 + (y2 - y1) * (j / zDistance);
          const z = getHeight(x, y, g) + 0.8;
          if (j < zDistance - 1) {
            x3 = x1 + (x2 - x1) * ((j + 0.25) / zDistance);
            y3 = y1 + (y2 - y1) * ((j + 0.25) / zDistance);
            z3 = getHeight(x3, y3, g) + 0.8;
            pointList.push(x, z, y, x3, z3, y3);
          }
        }
        pointList.push(x3, z3, y3, x2, z2, y2);
      } else {
        pointList.push(x1, z1, y1, x2, z2, y2);
      }
    }
  });

  const geometry = new THREE.BufferGeometry();

  geometry.setAttribute('position', new THREE.Float32BufferAttribute(pointList, 3));

  const group = new THREE.Group();
  const linethree = new THREE.LineSegments(
    geometry,
    new THREE.LineBasicMaterial({
      color: 0x0000ff,
      opacity: 1,
    }),
  );
  group.visible = false;
  group.name = name;
  group.add(linethree);
  return group;
};


export const createLayerLines = (layer, layersData, originPoint, ppu, g) => {
  const lineListJson = layersData.find(l => l.default_name === layer).data;
  const lineList = JSON.parse(lineListJson);
  const group = new THREE.Group();
  if (lineList) {
    const geometry = new THREE.BufferGeometry();
    const pointList = [];
    lineList.forEach((line) => {
      line.map((p, i) => {
        const x1 = (p[1] - originPoint[1]) / ppu;
        const y1 = (p[0] - originPoint[0]) / ppu;
        const z1 = getHeight(x1, y1, g) + 0.8;

        if (i < line.length - 1) {
          const x2 = (line[i + 1][1] - originPoint[1]) / ppu;
          const y2 = (line[i + 1][0] - originPoint[0]) / ppu;
          const z2 = getHeight(x2, y2, g) + 0.8;

          pointList.push(x1, z1, y1, x2, z2, y2);
        }
        return [x1, z1, y1];
      });
    });

    geometry.setAttribute('position', new THREE.Float32BufferAttribute(pointList, 3));

    const linethree = new THREE.LineSegments(
      geometry,
      new THREE.LineBasicMaterial({
        color: layer === 'ROTAS' ? 0xffff00 : 0xffffff,
        opacity: layer === 'ROTAS' ? 1 : 0.5,
        linewidth: 5,
      }),
    );
    group.visible = false;
    group.name = layer;
    group.add(linethree);
  }
  return group;
};

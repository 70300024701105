import React, { useState } from 'react';
import {
  withStyles,
  FormControl,
  FormLabel,
  FormGroup,
} from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import PF2MButton from '~/components/PF2MButton';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import PF2MOutlinedInput from '~/components/PF2MOutlinedInput';

function Header({
  classes,
  add,
  turnsGroups,
  equipmentTypes,
  equipmentsGroups,
  equipmentTurnGroups,
}) {
  const [equipmentType, setEquipmentType] = useState(0);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const { t: translate } = useTranslation();

  const INITIAL_VALUES = {
    id_turn_group: 0,
    id_equipment: 0,
  };


  const renderEquipment = (data, itemId) => [{ value: 0, label: translate('common:Select') }]
    .concat(...data.map(e => ({ value: e[itemId], label: e.name })));
  return (
    <>
      <Formik
        initialValues={INITIAL_VALUES}
        validate={(values) => {
          const errors = {};

          if (values.id_turn_group <= 0) {
            errors.id_turn_group = 'Required';
          }

          if (values.id_equipment <= 0) {
            errors.id_equipment = 'Required';
          }
          return errors;
        }}
        onSubmit={async (values, { resetForm, setSubmitting }) => {
          const alreadyAssociatedEquipGroups = equipmentTurnGroups
            .map(r => r.id_equipment);

          if (alreadyAssociatedEquipGroups.includes(values.id_equipment)) {
            setAlertOpen(true);
            setSubmitting(false);
            return;
          }

          const equipGroupName = equipmentsGroups.find(r => r.id === values.id_equipment);
          const valuesWithEquipName = {
            ...values,
            equipment_name: equipGroupName ? equipGroupName.name : '',
          };
          await add(valuesWithEquipName);
          setEquipmentType('0');
          resetForm();
          setSubmitting(false);
        }}
      >
        {({
          isSubmitting,
          isValid,
          setFieldValue,
        }) => (
          <Form>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 5,
            }}
            >
              <FormControl>
                <FormLabel className={classes.formLabel}>
                  {`${translate('common:EquipmentType')}:`}
                </FormLabel>
                <FormGroup>
                  <PF2MSearchSelectOutlined
                    outline="true"
                    fullWidth={false}
                    input={<PF2MOutlinedInput />}
                    className={classes.typeSelector}
                    value={equipmentType}
                    onChange={async (e) => {
                      setEquipmentType(e.target.value);
                      setFieldValue('id_equipment', 0);
                    }}
                  >
                    {renderEquipment(equipmentTypes, 'id_equipment')}
                  </PF2MSearchSelectOutlined>
                </FormGroup>
              </FormControl>
              <FormControl>
                <FormLabel className={classes.formLabel}>
                  {`${translate('common:EquipmentGroup')}:`}
                </FormLabel>
                <FormGroup>
                  <Field
                    name="id_equipment"
                    render={({ field, form }) => (
                      <PF2MSearchSelectOutlined
                        {...field}
                        placeholder={null}
                        onChange={e => form.setFieldValue('id_equipment', e.target.value)}
                      >
                        {[{ value: 0, label: translate('common:Select') }]
                          .concat(...equipmentsGroups
                            .filter(e => equipmentType === e.id_equipament || equipmentType === 0)
                            .map(e => ({ value: e.id, label: e.name })))}
                      </PF2MSearchSelectOutlined>

                    )}
                  />

                </FormGroup>
              </FormControl>
              <FormControl>
                <FormLabel className={classes.formLabel}>
                  {`${translate('common:ShiftGroup')}:`}
                </FormLabel>
                <FormGroup>
                  <Field
                    name="id_turn_group"
                    render={({ field, form }) => (
                      <PF2MSearchSelectOutlined
                        {...field}
                        placeholder={null}
                        onChange={e => form.setFieldValue('id_turn_group', e.target.value)}
                      >
                        {[{ value: 0, label: translate('common:Select') }]
                          .concat(...turnsGroups
                            .map(e => ({ value: e.id, label: e.name })))}
                      </PF2MSearchSelectOutlined>
                    )}
                  />
                </FormGroup>
              </FormControl>
              <PF2MButton
                type="submit"
                disabled={isSubmitting || !isValid}
                style={{ marginTop: 20 }}
              >
                <Add className={classes.icon} />
                {translate('common:ConnectShift')}
              </PF2MButton>
            </div>
          </Form>
        )}
      </Formik>
      <PF2MAlertDialog
        hasCancel={false}
        confirmText={translate('common:Understood')}
        description={translate('validation:TurnGroupAlreadyRegisteredForEquipGroup')}
        open={isAlertOpen}
        onConfirm={() => setAlertOpen(false)}
      />
    </>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  add: PropTypes.func,
  turnsGroups: PropTypes.array,
  equipmentTypes: PropTypes.array,
  equipmentsGroups: PropTypes.array,
  equipmentTurnGroups: PropTypes.array,
};

Header.defaultProps = {
  add: null,
  turnsGroups: [],
  equipmentTypes: [],
  equipmentsGroups: [],
  equipmentTurnGroups: [],
};


export default withStyles(styles)(Header);

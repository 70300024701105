import React, { useState } from 'react';
import {
  withStyles,
  FormControl,
  FormLabel,
  FormGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import styles from '../../styles';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import PF2MSearchButton from '~/components/PF2MSearchButton';

const ElementsFilter = ({ classes, setCurrentFilter }) => {
  const [currentLocalFilter, setCurrentLocalFilter] = useState({ name: '', active: -1 });
  const { t: translate } = useTranslation();

  const renderFormControl = () => (
    <Formik
      initialValues={{ name: '', active: -1 }}
      onSubmit={async (_, { resetForm }) => {
        setCurrentFilter(currentLocalFilter);
        resetForm();
      }}
    >
      {({
        isSubmitting,
        initialValues,
        errors,
      }) => (
        <Form style={{ display: 'flex', alignItems: 'center' }}>
          <FormControl className={classes.nameField}>
            <FormLabel className={classes.labelTypeSelector}>
              {translate('common:Name')}
            </FormLabel>
            <FormGroup>
              <Field
                type="text"
                name="name"
                value={currentLocalFilter.name || initialValues.name}
                className={classnames(
                  'form-control', errors.name ? classes.fieldError : classes.field,
                )}
                onChange={e => setCurrentLocalFilter({
                  ...currentLocalFilter, name: e.target.value,
                })}
              />
            </FormGroup>
          </FormControl>
          <FormControl className={classes.typeSelector}>
            <FormLabel className={classes.labelTypeSelector}>
              {translate('common:Active')}
            </FormLabel>
            <FormGroup>
              <PF2MSearchSelectOutlined
                placeholder={null}
                value={currentLocalFilter.active}
                clearedValue={-1}
                onChange={e => setCurrentLocalFilter({
                  ...currentLocalFilter, active: e.target.value,
                })}
              >
                {[
                  { value: -1, label: translate('common:All') },
                  { value: 0, label: translate('common:Inactive') },
                  { value: 1, label: translate('common:Active') }]}
              </PF2MSearchSelectOutlined>
            </FormGroup>
          </FormControl>
          <div style={{ marginTop: 25 }}>
            <PF2MSearchButton type="submit" disabled={isSubmitting} />
          </div>
        </Form>
      )}
    </Formik>
  );

  return (
    <div className={classes.tabContainer}>
      {renderFormControl()}
    </div>
  );
};

ElementsFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  setCurrentFilter: PropTypes.func,
};
ElementsFilter.defaultProps = {
  setCurrentFilter: null,
};

export default withStyles(styles)(ElementsFilter);

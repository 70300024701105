import React from 'react';
import Add from '@material-ui/icons/Add';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Done from '@material-ui/icons/Done';
import {
  withStyles,
  Fab,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import {
  updateUndergroundList,
  restoreOriginalUnderground,
  createUndergroundItem,
} from '~/store/dailyparts/actions';

const Header = ({
  classes,
}) => {
  const isUndergroundListDirty = useSelector(state => state.dailyParts.isUndergroundListDirty);
  const undergroundList = useSelector(state => state.dailyParts.undergroundList);
  const filters = useSelector(state => state.dailyParts.filters);
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  return (
    <div
      className="container"
      style={{
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      <div>
        <Fab
          variant="extended"
          color="primary"
          size="medium"
          onClick={() => dispatch(createUndergroundItem(filters))}
          className={classes.button}
        >
          <Add />
          {translate('common:Add')}
        </Fab>
        <Fab
          variant="extended"
          size="medium"
          color="primary"
          onClick={() => dispatch(restoreOriginalUnderground())}
          className={classes.buttonWhiteText}
          disabled={!isUndergroundListDirty}
        >
          <DeleteOutline />
          {translate('common:DiscardChanges')}
        </Fab>
        <Fab
          variant="extended"
          size="medium"
          color="secondary"
          onClick={() => dispatch(updateUndergroundList(undergroundList, filters))}
          className={classes.buttonWhiteText}
          disabled={!isUndergroundListDirty}
        >
          <Done />
          {translate('common:End')}
        </Fab>
      </div>
    </div>
  );
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

Header.defaultProps = {};

export default withStyles(styles)(Header);

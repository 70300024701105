import React, { useState, useEffect, useCallback } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DayPicker from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import MomentLocaleUtils from 'react-day-picker/moment';
import styles from '../styles';
import Footer from '~/pages/Manager/Footer';
import PF2MScrollbar from '~/components/PF2MScrollbar';
import {
  getTurnsGroups,
  getTeamGroups,
  getCalendarTurnsTeams,
  updateCalendarTurnsTeams,
  resetCalendarTurnsTeams,
  setCalendarTurnsTeams,
  setAlertDialog,
  updateDaysOff,
} from '~/store/manager/actions';
import Header from './Header';
import 'react-day-picker/lib/style.css';
import 'moment/locale/pt-br';
import { parseDate, newMomentDate } from '~/utils/moment';
import DayOffModal from './DayOffModal';

const dayPickerStyle = `
  .DayPicker-Day {
    border-radius: 0 !important;
  }

  .DayPicker-Day--disabled {
    background-color: #D3D3D3 !important;
    color: white;
  }

  .DayPicker-Month {}

  .DayPicker-Caption {
    font-family: Barlow Condensed;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    /* line-height: 22px; */
    /* identical to box height */

    text-align: center;
    text-transform: uppercase;

    /* cinza_300 */
    color: #647886;
  }
  .DayPicker-weekdays {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #647886;
  }
  .DayPicker-Day--outside {
    color: #CDCDCD;
    background-color: transparent !important;
  }
  `;

const TeamAssociationsTab = ({
  classes,
  turnsGroups,
  teamGroups,
  getTurnsGroups: loadTurnsGroups,
  getTeamGroups: loadTeamGroups,
  getCalendarTurnsTeams: get,
  updateCalendarTurnsTeams: update,
  resetCalendarTurnsTeams: reset,
  calendarTurnsTeams,
  isCalendarTurnsTeamsDirty,
  setCalendarTurnsTeams: setData,
  setAlertDialog: setDialog,
  updateDaysOff: updateDays,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [turnGroup, setTurnGroup] = useState(0);
  const [teamGroup, setTeamGroup] = useState(0);
  const [color, setColor] = useState(0);
  const [startDate, setStartDate] = useState(newMomentDate());
  const [endDate, setEndDate] = useState(newMomentDate());
  const [daysStyles, setDaysStyles] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedDays, setSelectedDays] = useState({});
  const [disabledDays, setDisabledDays] = useState({});
  const { t: translate } = useTranslation();

  const resetFields = useCallback(async () => {
    setSelectedDays({});
    setDisabledDays([]);
    setDaysStyles('');
    setColor(0);
    setStartDate(newMomentDate());
    setEndDate(newMomentDate());
    setTeamGroup(0);
    setTurnGroup(0);
    await reset();
  }, [reset]);

  useEffect(() => {
    async function fetchData() {
      loadTeamGroups();
      await loadTurnsGroups();
    }

    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, loadTurnsGroups, loadTeamGroups]);

  useEffect(() => {
    async function fetchData() {
      await get(turnGroup, teamGroup > 0 ? teamGroup : null);
    }

    if (turnGroup > 0) {
      fetchData();
    } else {
      resetFields();
    }
  }, [turnGroup, teamGroup, get, resetFields]);

  useEffect(() => {
    const calendarDays = {};
    const disabled = [];
    const existingTurnTeam = calendarTurnsTeams
      .find(c => c.id_team_group === teamGroup && !c.day_off);
    if (existingTurnTeam) {
      setColor(existingTurnTeam.color);
      setStartDate(parseDate(existingTurnTeam.start_date, true));
      setEndDate(parseDate(existingTurnTeam.end_date, true));
    } else {
      setColor(0);
      setStartDate(newMomentDate());
      setEndDate(newMomentDate());
    }

    calendarTurnsTeams
      .forEach((c) => {
        if (!c.day_off) {
          calendarDays[c.id_team_group] = {
            from: parseDate(c.start_date, true).toDate(),
            to: parseDate(c.end_date, true).toDate(),
          };
        } else if (c.day_off && (!c.update_status || c.update_status !== 'D')) {
          disabled.push({
            from: parseDate(c.start_date, true).toDate(),
            to: parseDate(c.end_date, true).toDate(),
          });
        }
      });
    const calendarStyles = calendarTurnsTeams.map(c => `.DayPicker-Day--${c.id_team_group} {
        background-color: ${c.color};
      }`).join('');
    setSelectedDays(calendarDays);
    setDisabledDays(disabled);
    setDaysStyles(calendarStyles);
  }, [calendarTurnsTeams, teamGroup]);

  return (
    <div className={classes.tabContainer}>
      <Header
        turnsGroups={turnsGroups}
        turnGroup={turnGroup}
        handleTurnGroup={setTurnGroup}
        teamGroup={teamGroup}
        handleTeamGroup={setTeamGroup}
        color={color}
        handleColor={setColor}
        startDate={startDate}
        handleStartDate={setStartDate}
        endDate={endDate}
        handleEndDate={setEndDate}
        teamGroups={teamGroups.filter(t => t.id_turn_group === turnGroup)}
        handleModal={() => setModalOpen(true)}
        setCalendarTurnsTeams={setData}
        setAlertDialog={setDialog}
        isCalendarTurnsTeamsDirty={isCalendarTurnsTeamsDirty}
      />
      <div className={classes.calendars}>
        <PF2MScrollbar>
          <>
            <style>{dayPickerStyle}</style>
            <style>{daysStyles}</style>
            <DayPicker
              locale={translate('date:Locale')}
              localeUtils={MomentLocaleUtils}
              numberOfMonths={12}
              modifiers={selectedDays}
              disabledDays={disabledDays}
            />
          </>
        </PF2MScrollbar>
      </div>
      <Footer
        isDirty={isCalendarTurnsTeamsDirty}
        discard={() => {
          reset();
          resetFields();
        }}
        sendData={() => {
          update(calendarTurnsTeams, turnGroup, teamGroup);
          resetFields();
        }}
      />
      {turnGroup && teamGroup && modalOpen
        && (
          <DayOffModal
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            startDate={startDate}
            endDate={endDate}
            daysOff={calendarTurnsTeams.filter(c => c.day_off && (!c.update_status || c.update_status !== 'D'))}
            handleDaysOff={setData}
            teamGroup={teamGroup}
            turnGroup={turnGroup}
            updateDaysOff={updateDays}
          />
        )}
    </div>
  );
};

TeamAssociationsTab.propTypes = {
  classes: PropTypes.object.isRequired,
  calendarTurnsTeams: PropTypes.array,
  turnsGroups: PropTypes.array,
  teamGroups: PropTypes.array,
  getCalendarTurnsTeams: PropTypes.func,
  updateCalendarTurnsTeams: PropTypes.func,
  resetCalendarTurnsTeams: PropTypes.func,
  isCalendarTurnsTeamsDirty: PropTypes.bool,
  getTurnsGroups: PropTypes.func,
  getTeamGroups: PropTypes.func,
  setCalendarTurnsTeams: PropTypes.func,
  setAlertDialog: PropTypes.func,
  updateDaysOff: PropTypes.func,
};

TeamAssociationsTab.defaultProps = {
  calendarTurnsTeams: [],
  turnsGroups: [],
  teamGroups: [],
  isCalendarTurnsTeamsDirty: false,
  getTurnsGroups: null,
  getTeamGroups: null,
  getCalendarTurnsTeams: null,
  updateCalendarTurnsTeams: null,
  resetCalendarTurnsTeams: null,
  setCalendarTurnsTeams: null,
  setAlertDialog: null,
  updateDaysOff: null,
};

const mapStateToProps = state => ({
  calendarTurnsTeams: state.manager.calendarTurnsTeams,
  isCalendarTurnsTeamsDirty: state.manager.isCalendarTurnsTeamsDirty,
  turnsGroups: state.manager.turnsGroups,
  teamGroups: state.manager.teamGroups,
});

const mapDispatchToProps = {
  getTurnsGroups,
  getTeamGroups,
  getCalendarTurnsTeams,
  updateCalendarTurnsTeams,
  resetCalendarTurnsTeams,
  setCalendarTurnsTeams,
  setAlertDialog,
  updateDaysOff,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(TeamAssociationsTab));

import truckIcon from '~/assets/new_dispatch_icons/caminhão_vazio_direita.png';
import excavatorIcon from '~/assets/new_dispatch_icons/escavadeira_direita.png';
import perforatorIcon from '~/assets/new_dispatch_icons/perfuratriz_direita.png';
import infraIcon from '~/assets/new_dispatch_icons/carga_infra_direita.png';
import tratorIcon from '~/assets/new_dispatch_icons/trator_direita.png';
import motoIcon from '~/assets/new_dispatch_icons/motoniveladora_direita.png';
import pipeTruckIcon from '~/assets/new_dispatch_icons/pipa_direita.png';
import combTruckIcon from '~/assets/new_dispatch_icons/comboio_direita.png';
import robotIcon from '~/assets/new_dispatch_icons/robot.svg';
import genericIcon from '~/assets/new_dispatch_icons/generic_equip.svg';
import rompIcon from '~/assets/new_dispatch_icons/rompedor_direita.png';
import compactIcon from '~/assets/new_dispatch_icons/rolo_compactador_direita.png';

const IMAGE_MAPPER = {
  1: excavatorIcon,
  2: truckIcon,
  4: perforatorIcon,
  5: infraIcon,
  6: tratorIcon,
  7: motoIcon,
  8: pipeTruckIcon,
  9: infraIcon,
  10: combTruckIcon,
  11: genericIcon,
  13: tratorIcon,
  15: infraIcon,
  22: robotIcon,
  26: rompIcon,
  27: compactIcon,
};

const equipImagePicker = equipId => (IMAGE_MAPPER[equipId] ? IMAGE_MAPPER[equipId] : genericIcon);

export default equipImagePicker;

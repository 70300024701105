import React from 'react';
import PropTypes from 'prop-types';
import MuiMenuItem from '@material-ui/core/MenuItem';

// using an third library component as owner component to create a fast2mine standart
const PF2MMenuItem = React.forwardRef(({ autoClose: _autoClose, ...props }, _ref) => (
  <MuiMenuItem {...props} />
));

PF2MMenuItem.propTypes = {
  ...MuiMenuItem.propTypes,
  autoClose: PropTypes.bool,
};

PF2MMenuItem.defaultProps = {
  autoClose: true,
};

export default PF2MMenuItem;

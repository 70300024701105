import * as types from './types';

const INITIAL_STATE = {
  tablets: [],
  apkversion: [],
  tablet_update: [],
  tabletControlSync: [],
  originalTabletControlSync: [],
  isTabletControlSyncDirty: false,
  webModules: [],
};

const remapData = (items, newItem, key) => items.map((item) => {
  if (item[key] === newItem[key]) {
    return {
      ...newItem,
    };
  }
  return item;
});

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.GET_WEB_MODULES: {
      return {
        ...state,
        webModules: action.payload,
      };
    }
    case types.GET_ALL_TABLETS: {
      return {
        ...state,
        tablets: action.payload,
      };
    }
    case types.GET_APK_VERSION: {
      return {
        ...state,
        apkversion: action.payload,
      };
    }
    case types.GET_TABLET_UPDATE: {
      return {
        ...state,
        tablet_update: action.payload,
      };
    }
    case types.GET_TABLET_CONTROL_SYNC: {
      return {
        ...state,
        tabletControlSync: action.payload,
        originalTabletControlSync: action.payload,
        istabletControlSyncDirty: false,
      };
    }
    case types.SET_TABLET_CONTROL_SYNC: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.tabletControlSync, newItem, 'db_table');
      return {
        ...state,
        tabletControlSync: newData,
        istabletControlSyncDirty: true,
      };
    }
    case types.RESET_TABLET_CONTROL_SYNC: {
      return {
        ...state,
        tabletControlSync: state.originalTabletControlSync,
        istabletControlSyncDirty: false,
      };
    }
    default: {
      return state;
    }
  }
}

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import languageDetector from 'i18next-browser-languagedetector';
import {
  common, validation, date, tips, indicators
} from './locale';

const fallbackLng = ['pt-BR'];
const availableLanguages = ['pt-BR', 'en', 'es'];

const options = {
  order: ['navigator'],
};

const generateLanguageResources = () => ({
  en: {
    common: common.en,
    validation: validation.en,
    date: date.en,
    tips: tips.en,
    indicators: indicators.en,
  },
  es: {
    common: common.es,
    validation: validation.es,
    date: date.es,
    tips: tips.es,
    indicators: indicators.es,
  },
  'pt-BR': {
    common: common.ptbr,
    validation: validation.ptbr,
    date: date.ptbr,
    tips: tips.ptbr,
    indicators: indicators.ptbr,
  },
});

const resources = generateLanguageResources();
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(languageDetector)
  .init({
    resources,
    fallbackLng,
    availableLanguages,
    detection: options,
  });

i18n.changeLanguage();
export default i18n;

import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import ProjectGroupTab from './ProjectGroupTab';
import ProjectsStatusTab from './ProjectsStatusTab';
import ProjectsSubElementsTab from './ProjectsSubElementsTab';
import PF2MTabs from '~/components/PF2MTabs';
import { setAlertDialog } from '~/store/manager/actions';

const Projects = () => {
  const isProjectGroupsDirty = useSelector(state => state.underground.isProjectGroupsDirty);
  // eslint-disable-next-line max-len
  const isProjectSubElementsDirty = useSelector(state => state.underground.isProjectSubElementsDirty);
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(0);
  const { t: translate } = useTranslation();

  const tabs = [
    { key: 0, label: translate('common:ProjectGroup') },
    { key: 1, label: translate('common:ConnectSubElements') },
    { key: 2, label: translate('common:Standby') },
  ];

  const handleChange = (event, value) => {
    let blockChange = false;

    switch (value) {
      case 1:
        blockChange = isProjectGroupsDirty;
        break;
      case 2:

        break;
      default:
        blockChange = isProjectSubElementsDirty;
        break;
    }

    if (blockChange) {
      event.preventDefault();
      dispatch(setAlertDialog());
    } else {
      setSelectedTab(value);
    }
  };

  return (
    <>
      <PF2MTabs
        value={selectedTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        {tabs}
      </PF2MTabs>
      {selectedTab === 0 ? (<ProjectGroupTab />) : null}
      {selectedTab === 1 ? (<ProjectsSubElementsTab />) : null}
      {selectedTab === 2 ? (<ProjectsStatusTab />) : null}
    </>
  );
};

export default withStyles(styles)(Projects);

import React from 'react';
import { withStyles } from '@material-ui/core';

const CenterSpan = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
})(({ classes, children, color }) => (
  <span className={classes.root} style={{ backgroundColor: color }}>{children}</span>
));

export default CenterSpan;

import React, { useEffect } from 'react';
import {
  withStyles, FormControl, FormLabel, FormGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import {
  Formik, ErrorMessage, Field, Form,
} from 'formik';
import NumberFormat from 'react-number-format';
import Add from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import Footer from '~/pages/Manager/Footer';
import PF2MTable from '~/components/PF2MTable';
import {
  getFandrillBitDiameters,
  setFandrillBitDiameterValue,
  resetFandrillBitDiameters,
  updateFandrillBitDiameters,
  addFandrillBitDiameter,
  getEquipmentTypes,
  getEquipmentsGroups,
} from '~/store/manager/actions';
import PF2MButton from '~/components/PF2MButton';
import { parseFloat, sortByProperties } from '~/utils';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';

const BIT_DIAMETER_EQUIP_TYPES = [11, 16, 4, 17, 18];
const FandrillBitDiameterTab = ({ classes }) => {
  const fandrillBitDiameters = useSelector(state => state.manager.fandrillBitDiameters);
  // eslint-disable-next-line max-len
  const isFandrillBitDiametersDirty = useSelector(state => state.manager.isFandrillBitDiametersDirty);
  const equipmentTypes = useSelector(state => state.manager.equipmentTypes);
  const equipmentGroups = useSelector(state => state.manager.equipmentsGroups);
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      dispatch(getFandrillBitDiameters(true));
      await dispatch(getEquipmentTypes());
      await dispatch(getEquipmentsGroups());
    }
    fetchData();
  }, [dispatch]);

  const columns = [
    {
      field: 'equipment_group_name',
      title: translate('common:EquipmentGroup'),
      editable: false,
    },
    {
      field: 'diameter',
      title: translate('common:Diameter'),
      type: 'decimal',
      separator: ',',
      blocked: true,
    },
    {
      field: 'active',
      title: 'STATUS',
      editable: true,
    },
  ];

  const isValidDiameter = (items, values) => items
    .every(r => r.equipment_group_id !== values.equipGroup
      || parseFloat(r.diameter) !== parseFloat(values.diameter));

  const getEquipGroupName = (id) => {
    const filteredEquipGroups = equipmentGroups.filter(r => r.id === id);
    return filteredEquipGroups.length
      ? filteredEquipGroups[0].name : '';
  };

  const renderHeader = () => (
    <Formik
      initialValues={{ equipType: 0, equipGroup: 0, diameter: '' }}
      validate={(values) => {
        const errors = {};
        const { diameter, equipGroup } = values;

        if (!diameter) {
          errors.diameter = translate('validation:RequiredField');
        }

        if (!equipGroup) {
          errors.equipGroup = translate('validation:RequiredField');
        }

        if (!isValidDiameter(fandrillBitDiameters, values)) {
          errors.diameter = translate('validation:AlreadyRegisteredDiameter');
        }

        return errors;
      }}
      onSubmit={async (values, { resetForm }) => {
        const { diameter, equipGroup } = values;
        const newValues = {
          diameter,
          equipment_group_id: equipGroup,
          equipment_group_name: getEquipGroupName(equipGroup),
        };
        dispatch(addFandrillBitDiameter(newValues));
        resetForm();
      }}
    >
      {({
        isSubmitting,
        errors,
        isValid,
      }) => (
        <Form>
          <div style={{ display: 'flex', marginBottom: 15, minHeight: 75 }}>
            <FormControl>
              <FormLabel className={classes.formLabel}>
                {translate('common:EquipmentType')}
              </FormLabel>
              <FormGroup>
                <Field
                  name="equipType"
                  render={({ field, form }) => (
                    <PF2MSearchSelectOutlined
                      {...field}
                      placeholder={null}
                      className={classnames(classes.field)}
                      value={field.value || 0}
                      onChange={(e) => {
                        form.setFieldValue(field.name, e.target.value, true);
                        form.setFieldValue('equipGroup', 0, true);
                      }}
                    >
                      {[{ value: 0, label: translate('common:Select') }]
                        .concat(equipmentTypes
                          .filter(r => BIT_DIAMETER_EQUIP_TYPES.includes(r.id))
                          .map(e => ({ value: e.id, label: e.name || '0' })))}
                    </PF2MSearchSelectOutlined>
                  )}
                />
              </FormGroup>
            </FormControl>
            <FormControl style={{ marginLeft: 10 }}>
              <FormLabel className={classes.formLabel}>
                {translate('common:EquipmentGroup')}
              </FormLabel>
              <FormGroup>
                <Field
                  name="equipGroup"
                  render={({ field, form }) => (
                    <PF2MSearchSelectOutlined
                      {...field}
                      placeholder={null}
                      className={classnames(classes.field)}
                      value={field.value || 0}
                      onChange={(e) => {
                        form.setFieldValue(field.name, e.target.value, true);
                      }}
                    >
                      {[{ value: 0, label: translate('common:Select') }]
                        .concat(equipmentGroups
                          .filter(r => r.id_equipament === form.values.equipType)
                          .map(e => ({ value: e.id, label: e.name || '0' })))}
                    </PF2MSearchSelectOutlined>
                  )}
                />
              </FormGroup>
            </FormControl>
            <FormControl className={classes.classGroupName} style={{ marginLeft: 10 }}>
              <FormLabel className={classes.formLabel}>
                {translate('common:BitDiameter')}
              </FormLabel>
              <FormGroup>
                <Field
                  name="diameter"
                  render={({ field }) => (
                    <NumberFormat
                      {...field}
                      className={classnames('form-control', errors.diameter ? classes.fieldError : classes.field)}
                      decimalSeparator=","
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  )}
                />
                <ErrorMessage
                  name="diameter"
                  component="span"
                  className={classes.errorMessage}
                />
              </FormGroup>
            </FormControl>
            <div style={{ marginTop: 12 }}>
              <PF2MButton type="submit" disabled={isSubmitting || !isValid}>
                <Add className={classes.icon} />
                {translate('common:Register').toUpperCase()}
              </PF2MButton>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );


  return (
    <div className={classes.tabContainer}>
      {renderHeader()}
      <PF2MTable
        data={sortByProperties(
          fandrillBitDiameters, [
            { prop: 'equipment_group_name', type: 'string' },
            { prop: 'diameter', type: 'number' }],
        )}
        keyExtractor={item => `${item.id}_${item.diameter.toString().replace(',', '')}`}
        columns={columns}
        updateItem={(row, field, value) => dispatch(setFandrillBitDiameterValue(row, field, value))}
      />
      <Footer
        isDirty={isFandrillBitDiametersDirty}
        discard={() => dispatch(resetFandrillBitDiameters())}
        sendData={() => dispatch(updateFandrillBitDiameters(fandrillBitDiameters, true))}
      />
    </div>
  );
};

FandrillBitDiameterTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FandrillBitDiameterTab);

/* eslint-disable prefer-destructuring */

export const modelInfo = [
  {
    name: 'dumptruck',
    equipIds: [2, 10],
  },
  {
    name: 'excavator',
    equipIds: [1, 5, 15],
  },
  {
    name: 'watertruck',
    equipIds: [8, 14],
  },
  {
    name: 'perforator',
    equipIds: [4],
  },
];

export const getModel = (models, id) => models[modelInfo.find(m => m.equipIds.includes(id))?.name]
    || models.dumptruck;

export const loadModels = (loader, onLoad) => {
  const models = {};
  modelInfo.forEach((item) => {
    loader.load(`/models/${item.name}.gltf`, (gltf) => {
      models[item.name] = gltf.scene.children[0];
      if (modelInfo.length === Object.keys(models).length) {
        onLoad(models);
      }
    });
  });
  return models;
};


export const a = 1;

import React, { useEffect, useRef, useCallback } from 'react';
import {
  withStyles, FormControl, FormLabel, FormGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import Footer from '~/pages/Manager/Footer';
import PF2MTable from '~/components/PF2MTable';
import {
  getProjectGroups,
  addProjectGroup,
  setProjectGroupValue,
  resetProjectGroups,
  updateProjectGroups,
} from '~/store/underground/actions';
import PF2MAddButton from '~/components/PF2MAddButton';

const ProjectTab = ({ classes }) => {
  const projectGroups = useSelector(state => state.underground.projectGroups);
  const isProjectGroupsDirty = useSelector(state => state.underground.isProjectGroupsDirty);
  const dispatch = useDispatch();
  const formikRef = useRef();
  const { t: translate } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      dispatch(getProjectGroups());
    }
    fetchData();
  }, [dispatch]);

  const isValidName = useCallback((name) => {
    const existingItem = projectGroups.find(c => c.name === name);
    return !existingItem;
  }, [projectGroups]);

  const columns = [
    {
      field: 'name', title: translate('common:Name'), width: '100%', editable: true,
    },
    {
      field: 'active',
      title: 'STATUS',
      editable: true,
    },
  ];

  const renderHeader = () => (
    <Formik
      ref={formikRef}
      initialValues={{ name: '' }}
      validate={(values) => {
        const errors = {};
        const { name } = values;

        if (!name) {
          errors.name = translate('validation:RequiredField');
        }

        if (name) {
          if (!isValidName(name)) {
            errors.name = translate('validation:AlreadyRegisteredName');
          }
        }

        return errors;
      }}
      onSubmit={async (values, { resetForm }) => {
        const newValues = {
          name: values.name,
        };
        dispatch(addProjectGroup(newValues));
        resetForm();
      }}
    >
      {({
        isSubmitting,
        errors,
        isValid,
      }) => (
        <Form>
          <div style={{ display: 'flex', marginBottom: 15, minHeight: 75 }}>
            <FormControl className={classes.classGroupName} style={{ marginLeft: 0 }}>
              <FormLabel className={classes.formLabel}>
                {`${translate('common:Name')}:`}
              </FormLabel>
              <FormGroup>
                <Field
                  type="text"
                  name="name"
                  className={classnames('form-control', errors.name ? classes.fieldError : classes.field)}
                  style={{ width: '100%' }}
                />
                <ErrorMessage
                  name="name"
                  component="span"
                  className={classes.errorMessage}
                />
              </FormGroup>
            </FormControl>
            <div style={{ marginTop: 12 }}>
              <PF2MAddButton disabled={isSubmitting || !isValid} />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );

  return (
    <div className={classes.tabContainer}>
      {renderHeader()}
      <PF2MTable
        data={projectGroups}
        columns={columns}
        updateItem={(row, field, value) => dispatch(setProjectGroupValue(row, field, value))}
      />
      <Footer
        isDirty={isProjectGroupsDirty}
        discard={() => {
          dispatch(resetProjectGroups());

          if (formikRef) {
            formikRef.current.resetForm({ name: '' });
          }
        }}
        sendData={() => dispatch(updateProjectGroups(projectGroups))}
      />
    </div>
  );
};

ProjectTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProjectTab);

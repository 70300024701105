const styles = {
  inputMask: {
    textAlign: 'center',
    padding: 12,
    paddingTop: 6,
    paddingBottom: 7,
    fontSize: 16,
    maxWidth: 200,
  },
};

export default styles;

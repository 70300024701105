const styles = {
  root: {
    flexGrow: 1,
    backgroundColor: '#CDCDCD',
  },
  tabRoot: {
    '&$tabSelected': {
      color: '#FFF',
      backgroundColor: '#F2AA00',
    },
  },
  tabSelected: { },
  tabsRoot: { },
  tabsIndicator: { },
};


export default styles;

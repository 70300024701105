
const styles = {
  label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '190px',
  },
  text: {
  },
};

export default styles;

import { managerGridHeight } from '~/utils';

const styles = {

  currentPageLabel: {
    color: '#647886',
    fontFamily: 'Barlow Condensed',
    fontWeight: 600,
    fontSize: 18,
    textTransform: 'uppercase',
    marginRight: 10,
    marginTop: 10,
    marginLeft: 10,
    marginBottom: 0,
  },
  pageButton: {
    minWidth: 20,
    width: 20,
  },
  container: {
    display: 'flex',
    backgroundColor: '#E6E7E8',
    height: managerGridHeight,
    padding: 10,
  },
  button: {
    color: '#FFF',
    margin: 5,
  },
};

export default styles;

const styles = {
  grid: {
    justifyContent: 'center',
  },
  fieldsContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  labelSpan: {
    fontFamily: 'Roboto',
    fontSize: 12,
    color: '#455873',
    display: 'block',
  },
};

export default styles;

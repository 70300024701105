import React, { useEffect, useState, useCallback } from 'react';
import { Modal, withStyles } from '@material-ui/core';
import {
  Switch,
  withRouter,
  Route,
  Redirect,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import PF2MMenu from '~/components/PF2MMenu';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import PF2MScrollbar from '~/components/PF2MScrollbar';
import Scenarios from './Scenarios';
import Elements from './Elements';
import Precedences from './Precedences';
import Elevations from './Elevations';
import MineAvailability from './MineAvailability';
import Priorities from './Priorities';
import LoadAllocations from './LoadAllocations';
import Main from '../Main';
import SearchForm from './SearchForm';
import { setAlertDialog } from '~/store/manager/actions';
import { ZendeskAPI } from 'react-zendesk';

const Lineup = ({ classes, match, history }) => {
  const dispatch = useDispatch();
  const alertDialog = useSelector(state => state.manager.alertDialog);
  const [isSearchModalOpen, setSearchModalOpen] = useState(false);
  const isEquipmentsSchedulesDirty = useSelector(
    state => state.dispatch.isEquipmentsSchedulesDirty,
  );
  const selectedLineup = useSelector(state => state.lineup.selectedLineup);
  const { t: translate } = useTranslation();

  const validateNavigation = useCallback(({ pathname = '' }) => {
    if ((isEquipmentsSchedulesDirty) && !pathname.includes('equipmentprogram')) {
      dispatch(setAlertDialog());
      return false;
    }
    return true;
  }, [isEquipmentsSchedulesDirty, dispatch]);

  const handleNavigation = (e, pathname = '') => {
    if (!validateNavigation(pathname)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (history.location && history.location.length > 0) {
      validateNavigation(history.location);
    }
  }, [history.location, validateNavigation]);

  useEffect(() => {
    const timer = setTimeout(() => {
      ZendeskAPI('webWidget', 'hide');
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Main>
      <div className={classes.container}>
        <div className={classes.scenarioInfoHeader}>
          <span style={{ fontWeight: 100 }}>{`${translate('common:ScenarioInConfiguration')}: `}</span>
          {selectedLineup?.id ? (
            <span>{`${selectedLineup.name} (${selectedLineup.start_date} - ${selectedLineup.end_date})`}</span>
          ) : <span>{'--'}</span>}
        </div>
        <PF2MScrollbar style={{ width: 150, padding: 10 }}>
          <PF2MMenu
            handleNavigation={handleNavigation}
            moduleName="lineup"
          />
        </PF2MScrollbar>
        <div style={{ flex: 1 }}>
          <Switch>
            <Route exact path={`${match.path}/scenarios`} component={Scenarios} />
            <Route exact path={`${match.path}/elements`} component={Elements} />
            <Route exact path={`${match.path}/elevations`} component={Elevations} />
            <Route exact path={`${match.path}/precedences`} component={Precedences} />
            <Route exact path={`${match.path}/mineavailability`} component={MineAvailability} />
            <Route exact path={`${match.path}/priorities`} component={Priorities} />
            <Route exact path={`${match.path}/loadallocations`} component={LoadAllocations} />
            <Redirect from={`${match.path}`} to={`${match.path}/scenarios`} />
          </Switch>
        </div>
      </div>
      <PF2MAlertDialog
        hasCancel={false}
        confirmText={translate('common:Understood')}
        description={translate('validation:YouHaveUnsavedData')}
        open={alertDialog}
        onConfirm={() => dispatch(setAlertDialog())}
      />
      <Modal open={isSearchModalOpen}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        >
          <SearchForm onClose={() => setSearchModalOpen(false)} />
        </div>
      </Modal>
    </Main>
  );
};

Lineup.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

Lineup.defaultProps = {};

export default withRouter(withStyles(styles)(Lineup));

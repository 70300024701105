import React from 'react';
import { useTranslation } from 'react-i18next';
import PF2MSearchSelect from '../../PF2MSearchSelect';
import { dangerColor } from '~/assets/styles';

function lookup(func, item, rowData) {
  if (!func || !rowData) return true;
  return func(item, rowData);
}

const renderInactiveIcon = (text, value, active, translate, tooltip) => {
  const tip = tooltip ? text : '';

  return {
    label: text,
    inactiveCondition: active !== undefined && !active,
    tooltip: active !== undefined && !active ? translate('common:Inactive') : tip,
    value,
  };
};

const checkError = (columnDef, value) => (
  columnDef?.required
  && !columnDef.lookupData.some(item => item[columnDef.lookupKey] === value)
);

export default function FilterLookupCell(p) {
  const {
    rowData, columnDef, value, bulkEdit,
  } = p;

  const { t: translate } = useTranslation();

  return (
    <>
      <PF2MSearchSelect
        value={value}
        onChange={e => bulkEdit(e, p)}
      >
        {
          columnDef.lookupData
            .filter(item => lookup(columnDef.lookupFilter, item, rowData))
            .map(
              item => renderInactiveIcon(item.name,
                item[columnDef.lookupKey],
                item.active, translate,
                columnDef.tooltip),
            )
        }
      </PF2MSearchSelect>
      {
        checkError(columnDef, value)
          && (
            <div style={{ color: dangerColor[0], textAlign: 'center' }}>
              {translate('validation:RequiredField')}
            </div>
          )
      }
    </>
  );
}

import React, { useState, useEffect } from 'react';
import {
  withStyles, FormControl, FormLabel, FormGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import {
  Formik, ErrorMessage, Field, Form,
} from 'formik';
import Add from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import Footer from '~/pages/Manager/Footer';
import PF2MTable from '~/components/PF2MTable';
import {
  getFandrillBitDiameters,
  getFandrillBitDiametersNames,
  setFandrillBitDiametersNamesValue,
  resetFandrillBitDiametersNames,
  updateFandrillBitDiametersNames,
  addFandrillBitDiameterName,
  getEquipmentsGroups,
  getEquipmentTypes,
} from '~/store/manager/actions';
import PF2MButton from '~/components/PF2MButton';
import { sortByProperties } from '~/utils';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';

const DRILLING_EQUIP_TYPES = [4, 11, 16, 17, 18, 19];

const FandrillBitDiameterNameTab = ({ classes }) => {
  const fandrillBitDiameters = useSelector(state => state.manager.fandrillBitDiameters);
  const equipGroups = useSelector(state => state.manager.equipmentsGroups);
  const equipTypes = useSelector(state => state.manager.equipmentTypes);
  const [tableData, setTableData] = useState([]);

  // eslint-disable-next-line max-len
  const isFandrillBitDiametersNamesDirty = useSelector(state => state.manager.isFandrillBitDiametersNamesDirty);
  const fandrillBitDiametersNames = useSelector(state => state.manager.fandrillBitDiametersNames);

  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      await dispatch(getFandrillBitDiameters(true));
      await dispatch(getEquipmentsGroups());
      await dispatch(getEquipmentTypes());
      await dispatch(getFandrillBitDiametersNames(true));
    }
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (fandrillBitDiametersNames === undefined
      || equipGroups === undefined
      || fandrillBitDiameters === undefined) {
      return setTableData([]);
    }
    const fatherObject = e => fandrillBitDiameters.find(
      f => e.fandrill_bit_diameter_id === f.id,
    );
    const tableDataList = fandrillBitDiametersNames.map(e => ({
      ...e,
      key: e.id,
      equipment_group_name: fatherObject(e)
        ? equipGroups.find(k => k.id === fatherObject(e).equipment_group_id).name
        : '',
    }));
    return setTableData(tableDataList);
  }, [fandrillBitDiameters, equipGroups, fandrillBitDiametersNames]);

  const columns = [
    {
      field: 'equipment_group_name',
      title: translate('common:EquipmentGroup'),
      editable: false,
    },
    {
      field: 'diameter',
      title: translate('common:BitDiameter'),
      editable: false,
    },
    {
      field: 'name',
      title: translate('common:BitDiameterName'),
      editable: true,
      width: '100%',
      updateOnChange: true,
    },
    {
      field: 'active',
      title: 'STATUS',
      editable: true,
    },
  ];

  const getBitDiameterById = (id) => {
    const filteredBitDiameters = fandrillBitDiameters.filter(r => r.id === id);
    return filteredBitDiameters.length
      ? filteredBitDiameters[0].diameter : '';
  };

  const renderHeader = () => (
    <Formik
      initialValues={{ bitDiameter: 0, name: '' }}
      validate={(values) => {
        const errors = {};
        const { name, bitDiameter } = values;

        if (!name) {
          errors.name = translate('validation:RequiredField');
        }

        if (!bitDiameter) {
          errors.bitDiameter = translate('validation:RequiredField');
        }

        return errors;
      }}
      onSubmit={async (values, { resetForm }) => {
        const { name, bitDiameter } = values;
        const newValues = {
          fandrill_bit_diameter_id: bitDiameter,
          diameter: getBitDiameterById(bitDiameter),
          name,
        };
        dispatch(addFandrillBitDiameterName(newValues));
        resetForm();
      }}
    >
      {({
        isSubmitting,
        errors,
        isValid,
      }) => (
        <Form>
          <div style={{ display: 'flex', marginBottom: 15, minHeight: 75 }}>
            <FormControl>
              <FormLabel className={classes.formLabel}>
                {translate('common:EquipmentType')}
              </FormLabel>
              <FormGroup>
                <Field
                  name="equipType"
                  render={({ field, form }) => (
                    <PF2MSearchSelectOutlined
                      {...field}
                      placeholder={null}
                      className={classnames(classes.field)}
                      value={field.value || 0}
                      onChange={(e) => {
                        form.setFieldValue(field.name, e.target.value, true);
                        form.setFieldValue('equipGroup', 0, true);
                      }}
                    >
                      {[{ value: 0, label: translate('common:Select') }]
                        .concat(equipTypes
                          .filter(r => DRILLING_EQUIP_TYPES.includes(r.id))
                          .map(e => ({ value: e.id, label: e.name || '0' })))}
                    </PF2MSearchSelectOutlined>
                  )}
                />
              </FormGroup>
            </FormControl>
            <FormControl style={{ marginLeft: 10 }}>
              <FormLabel className={classes.formLabel}>
                {translate('common:EquipmentGroup')}
              </FormLabel>
              <FormGroup>
                <Field
                  name="equipGroup"
                  render={({ field, form }) => (
                    <PF2MSearchSelectOutlined
                      placeholder={null}
                      className={classnames(classes.field)}
                      value={field.value || 0}
                      onChange={(e) => {
                        form.setFieldValue(field.name, e.target.value, true);
                      }}
                    >
                      {[{ value: 0, label: translate('common:Select') }]
                        .concat(equipGroups
                          .filter(r => r.id_equipament === form.values.equipType)
                          .map(e => ({ value: e.id, label: e.name || '0' })))}
                    </PF2MSearchSelectOutlined>
                  )}
                />
              </FormGroup>
            </FormControl>
            <FormControl style={{ marginLeft: 10 }}>
              <FormLabel className={classes.formLabel}>
                {translate('common:BitDiameter')}
              </FormLabel>
              <FormGroup>
                <Field
                  name="bitDiameter"
                  render={({ field, form }) => (
                    <PF2MSearchSelectOutlined
                      {...field}
                      placeholder={null}
                      className={classnames(classes.field)}
                      value={field.value || 0}
                      onChange={(e) => {
                        form.setFieldValue(field.name, e.target.value, true);
                      }}
                    >
                      {[{ value: 0, label: translate('common:Select') }]
                        .concat(fandrillBitDiameters
                          .filter(r => r.equipment_group_id === form.values.equipGroup)
                          .map(e => ({ value: e.id, label: e.diameter || '0' })))}
                    </PF2MSearchSelectOutlined>
                  )}
                />
              </FormGroup>
            </FormControl>
            <FormControl className={classes.classGroupName} style={{ marginLeft: 10 }}>
              <FormLabel className={classes.formLabel}>
                {translate('common:BitDiameterName')}
              </FormLabel>
              <FormGroup>
                <Field
                  type="text"
                  name="name"
                  className={classnames('form-control', errors.name ? classes.fieldError : classes.field)}
                  style={{ width: '100%' }}
                />
                <ErrorMessage
                  name="name"
                  component="span"
                  className={classes.errorMessage}
                />
              </FormGroup>
            </FormControl>
            <div style={{ marginTop: 12 }}>
              <PF2MButton type="submit" disabled={isSubmitting || !isValid}>
                <Add className={classes.icon} />
                {translate('common:Register').toUpperCase()}
              </PF2MButton>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );


  return (
    <div className={classes.tabContainer}>
      {renderHeader()}
      <PF2MTable
        data={sortByProperties(
          tableData, [
            { prop: 'diameter', type: 'number' },
            { prop: 'name', type: 'string' }],
        )}
        columns={columns}
        updateItem={(row, field, value) => dispatch(
          setFandrillBitDiametersNamesValue(row, field, value),
        )}
      />
      <Footer
        isDirty={isFandrillBitDiametersNamesDirty}
        discard={() => dispatch(resetFandrillBitDiametersNames())}
        sendData={() => dispatch(updateFandrillBitDiametersNames(fandrillBitDiametersNames, true))}
      />
    </div>
  );
};

FandrillBitDiameterNameTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FandrillBitDiameterNameTab);

import React, { useEffect, useCallback } from 'react';
import { withStyles } from '@material-ui/core';
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { ZendeskAPI } from 'react-zendesk';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PF2MMenu from '~/components/PF2MMenu';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import PF2MScrollbar from '~/components/PF2MScrollbar';
import { setAlertDialog } from '~/store/manager/actions';
import Codes from './Codes';
import Systems from './Systems';
import MaintenanceTurn from './Turn';
import DailyParts from './DailyParts';
import Main from '../Main';
import styles from './styles';
import Manager from './Manager';

const Maintenance = ({
  classes,
  match,
  history,
}) => {
  const dispatch = useDispatch();
  const alertDialog = useSelector(state => state.manager.alertDialog);
  const isCodeGroupsDirty = useSelector(state => state.maintenance.isCodeGroupsDirty);
  const isCodesDirty = useSelector(state => state.maintenance.isCodesDirty);
  const isSystemsDirty = useSelector(state => state.maintenance.isSystemsDirty);
  const isSystemItemsDirty = useSelector(state => state.maintenance.isSystemItemsDirty);
  const isFailureModesDirty = useSelector(state => state.maintenance.isFailureModesDirty);
  const { t: translate } = useTranslation();

  const validateNavigation = useCallback(({ pathname = '' }) => {
    if ((isCodeGroupsDirty || isCodesDirty) && !pathname.includes('codes')) {
      dispatch(setAlertDialog());
      return false;
    }
    if ((isSystemsDirty || isSystemItemsDirty || isFailureModesDirty) && !pathname.includes('systems')) {
      dispatch(setAlertDialog());
      return false;
    }
    return true;
  }, [
    isCodeGroupsDirty,
    isCodesDirty,
    isSystemsDirty,
    isSystemItemsDirty,
    isFailureModesDirty,
    dispatch,
  ]);

  const handleNavigation = (e, pathname = '') => {
    if (!validateNavigation(pathname)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (history.location && history.location.length > 0) {
      validateNavigation(history.location);
    }
  }, [history.location, validateNavigation]);

  useEffect(() => {
    const timer = setTimeout(() => {
      ZendeskAPI('webWidget', 'hide');
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Main>
      <div className={classes.container}>
        <PF2MScrollbar style={{ width: 150, padding: 10 }}>
          <PF2MMenu
            handleNavigation={handleNavigation}
            moduleName="maintenance"
          />
        </PF2MScrollbar>
        <div style={{ flex: 1 }}>
          <Switch>
            <Route exact path={`${match.path}/management`} component={Manager} />
            <Route exact path={`${match.path}/codes`} component={Codes} />
            <Route exact path={`${match.path}/systems`} component={Systems} />
            <Route exact path={`${match.path}/dailyparts`} component={DailyParts} />
            <Route exact path={`${match.path}/turn`} component={MaintenanceTurn} />
            <Redirect from={`${match.path}`} to={`${match.path}/codes`} />
          </Switch>
        </div>
      </div>
      <PF2MAlertDialog
        hasCancel={false}
        confirmText={translate('common:Understood')}
        description={translate('validation:YouHaveUnsavedData')}
        open={alertDialog}
        onConfirm={() => dispatch(setAlertDialog())}
      />
    </Main>
  );
};

Maintenance.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

Maintenance.defaultProps = {};

export default withRouter(withStyles(styles)(Maintenance));

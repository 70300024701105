import bg1 from '~/assets/backgrounds/bg1.png';
import bg2 from '~/assets/backgrounds/bg2.png';
import bg3 from '~/assets/backgrounds/bg3.png';
import bg4 from '~/assets/backgrounds/bg4.png';
import bg5 from '~/assets/backgrounds/bg5.png';

const backgrounds = [bg1, bg2, bg3, bg4, bg5];

const getBackground = backgrounds[(Math.floor(Math.random() * backgrounds.length))];

const styles = () => ({
  customButton: {
    height: 50,
    marginTop: 10,
    marginBottom: 10,
  },
  signInButton: {
    color: '#ffc300',
    marginBottom: 15,
    marginTop: 15,
    width: '65%',
    backgroundColor: '#111110',
  },
  customHeader: {
    background: '#F2AA00 !important',
    textAlign: 'center',
  },
  textFieldPassword: {
    paddingRight: 14,
  },
  customAdornment: {
    paddingRight: 0,
  },
  customHeight: {
    height: '1.1875em',
  },
  formSignin: {
    width: '100%',
    margin: '250px',
  },
  background: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: '#2E3841',
    backgroundImage: `url(${getBackground})`,
    height: '100vh',
  },
});

export default styles;

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  link: {
    color: '#FFF',
    minWidth: 150,
    '&:hover': {
      underline: 'none',
      color: '#FFF',
    },
    textAlign: 'center',
  },
  noBadge: {
    display: 'flex',
    flexDirection: 'column',
  },
  badge: {
    display: 'flex',
    flexDirection: 'column',
    '& .MuiBadge-badge': {
      width: 25,
      height: 25,
      padding: '5px 0px 0px 15px',
      top: 4,
      right: 4,
      borderRadius: 20,
      backgroundColor: '#FFC300',
      color: '#111110',
    },
  },
  badgeDisabled: {
    '& .MuiBadge-badge': {
      backgroundColor: '#B3B3B3',
      padding: '15px 0px 0px 20px',
      '& .MuiSvgIcon-root': {
        fontSize: 35,
      },
    },
  },
};

export default styles;

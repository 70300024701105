import React, { useState, useEffect } from 'react';
import {
  withStyles, FormControl,
  FormLabel,
  FormGroup,
  Fab,
  Typography,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import CreateIcon from '@material-ui/icons/Create';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import PF2MButton from '~/components/PF2MButton';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import PF2MAddButton from '~/components/PF2MAddButton';
import {
  Card, CardBody, CardHeader, CardIcon,
} from '~/components/Card';
import {
  updateMaintenanceOperationItems,
  resetMaintenanceOperationItems,
  setMaintenanceOperationItems,
  createMaintenanceOperationItem,
  getFailureModesSystemItems,
  getEquipmentGroupsSystemItems,
} from '~/store/maintenance/actions';
import PF2MOutlinedInput from '~/components/PF2MOutlinedInput';
import PF2MAccordion from '~/components/PF2MAccordion';
import PF2MScrollbar from '~/components/PF2MScrollbar';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';

const MaintenanceDetails = ({ classes }) => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const equipmentTypes = useSelector(state => state.manager.equipmentTypes);
  const equipmentGroups = useSelector(state => state.manager.equipmentsGroups);
  const equipments = useSelector(state => state.manager.equipments);
  const operators = useSelector(state => state.manager.operators);

  const maintenanceOperation = useSelector(state => state.maintenance.maintenanceOperation);
  // eslint-disable-next-line max-len
  const maintenanceOperationItems = useSelector(state => state.maintenance.maintenanceOperationItems);
  const systems = useSelector(state => state.maintenance.systems);
  const systemItems = useSelector(state => state.maintenance.systemItems);
  const failureModes = useSelector(state => state.maintenance.failureModes);
  const failureModesSystemItems = useSelector(state => state.maintenance.failureModesSystemItems);
  // eslint-disable-next-line max-len
  const equipmentGroupsSystemItems = useSelector(state => state.maintenance.equipmentGroupsSystemItems);
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    dispatch(getFailureModesSystemItems());
  }, [dispatch]);

  useEffect(() => {
    const {
      equipament_groups_id: equipGroupId,
      system_maintenance_id: systemMaintenanceId,
    } = maintenanceOperation;

    if (equipGroupId > 0 && systemMaintenanceId > 0) {
      dispatch(getEquipmentGroupsSystemItems(equipGroupId, systemMaintenanceId));
    }
  }, [dispatch, maintenanceOperation]);

  const getNameFromList = (id, list) => {
    const item = list.filter(r => parseInt(r.id, 10) === parseInt(id, 10));
    return item.length
      ? item[0].name
      : '';
  };

  const generateNewMaintenanceItem = () => ({
    id: -parseInt((Math.random() * 10000), 10),
    maintenance_operation_report_id: maintenanceOperation.id,
    system_item_maintenance_id: 0,
    system_item_maintenance_name: '',
    failure_mode_maintenance_id: 0,
    failure_mode_maintenance_name: '',
    observation: '',
    uid: null,
    duration: 0,
    duration_percentage: 0,
    timestamp_usn: maintenanceOperation.timestamp_usn,
    start_timestamp: maintenanceOperation.timestamp_usn,
    tablet_id: null,
    start_date: maintenanceOperation.start_date,
    start_time: maintenanceOperation.start_time,
  });

  const modalHandleChange = (item, field, value) => {
    dispatch(setMaintenanceOperationItems(item, field, value));
  };

  const renderFormControl = (
    item,
    key,
    title,
    disabled = false,
    collection,
    fieldText = 'name',
    idProp = 'id',
  ) => (
    <FormControl style={{ marginRight: 10 }}>
      <FormLabel className={classes.formLabel}>
        {title}
      </FormLabel>
      <FormGroup>
        <PF2MSearchSelectOutlined
          className={classnames(classes.field)}
          value={item[key] || 0}
          disabled={disabled}
          onChange={e => modalHandleChange(item, key, e.target.value)}
        >
          {[{ value: 0, label: translate('common:Select') }]
            .concat(collection
              .map(e => ({ label: e[fieldText], value: (e[idProp] || '0') })))}
        </PF2MSearchSelectOutlined>
      </FormGroup>
    </FormControl>
  );

  const renderHeader = () => (
    <div>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
      >
        {translate('common:MaintenanceData')}
      </Typography>
      <div style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gridColumnGap: 10,
        gridRowGap: 10,
      }}
      >
        <div>
          <span className={classes.labelTypeSelector}>
            {`${translate('common:InitialDate')}:`}
          </span>
          <span style={{ color: '#647886' }}>
            {maintenanceOperation.start_date || translate('common:NotConnected')}
          </span>
        </div>
        <div>
          <span className={classes.labelTypeSelector}>
            {`${translate('common:FinalHour')}:`}
          </span>
          <span style={{ color: '#647886' }}>
            {maintenanceOperation.start_time || translate('common:NotConnected')}
          </span>
        </div>
        <div>
          <span className={classes.labelTypeSelector}>
            {`${translate('common:FinalDate')}:`}
          </span>
          <span style={{ color: '#647886' }}>
            {maintenanceOperation.end_date || translate('common:NotConnected')}
          </span>
        </div>
        <div>
          <span className={classes.labelTypeSelector}>
            {`${translate('common:FinalHour')}:`}
          </span>
          <span style={{ color: '#647886' }}>
            {maintenanceOperation.end_time || translate('common:NotConnected')}
          </span>
        </div>
        <div style={{ gridColumnStart: 1 }}>
          <span className={classes.labelTypeSelector}>
            {`${translate('common:EquipmentType')}:`}
          </span>
          <span style={{ color: '#647886' }}>
            {getNameFromList(maintenanceOperation.equipaments_id, equipmentTypes) || translate('common:NotConnected')}
          </span>
        </div>
        <div>
          <span className={classes.labelTypeSelector}>
            {`${translate('common:EquipmentGroup')}:`}
          </span>
          <span style={{ color: '#647886' }}>
            {getNameFromList(maintenanceOperation.equipament_groups_id, equipmentGroups) || translate('common:NotConnected')}
          </span>
        </div>
        <div>
          <span className={classes.labelTypeSelector}>
            {`${translate('common:Equipment')}:`}
          </span>
          <span style={{ color: '#647886' }}>
            {getNameFromList(maintenanceOperation.group_equipament_links_id, equipments) || translate('common:NotConnected')}
          </span>
        </div>
        <div>
          <span className={classes.labelTypeSelector}>
            {`${translate('common:Operator')}:`}
          </span>
          <span style={{ color: '#647886' }}>
            {getNameFromList(maintenanceOperation.operator_id, operators) || translate('common:NotConnected')}
          </span>
        </div>
      </div>
    </div>
  );

  const filterSystemItems = (maintenanceOp, collection) => {
    const allowedSystemItems = equipmentGroupsSystemItems
      .filter(r => r.active === 1)
      .filter(r => maintenanceOp.system_maintenance_id === r.system_maintenance_id)
      .map(r => r.system_item_maintenance_id);
    return collection.filter(r => allowedSystemItems.includes(r.id));
  };

  const filterFailureModes = (maintenanceItem, collection) => {
    const allowedFailureModes = failureModesSystemItems
      .filter(r => r.active === 1)
      .filter(r => maintenanceItem.system_item_maintenance_id === r.id_system_item_maintenance)
      .map(r => r.id_failure_mode_maintenance);
    return collection.filter(r => allowedFailureModes.includes(r.id));
  };

  const renderBody = (maintenanceOp, maintenanceItem) => (
    <>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      >
        {maintenanceOp.is_maintenance_correction && (
          <>
            {renderFormControl(maintenanceOp, 'system_maintenance_id', translate('common:System'), true, systems)}
            {renderFormControl(
              maintenanceItem, 'system_item_maintenance_id', translate('common:Item'), false,
              filterSystemItems(maintenanceOp, systemItems),
            )}
            {renderFormControl(
              maintenanceItem, 'failure_mode_maintenance_id', translate('common:FailureMode'), false,
              filterFailureModes(maintenanceItem, failureModes),
            )}
          </>
        )}
        <FormControl style={{ marginRight: 10 }}>
          <FormLabel className={classes.formLabel}>
            {translate('common:DurationPercentage')}
          </FormLabel>
          <FormGroup>
            <NumberFormat
              customInput={PF2MOutlinedInput}
              value={maintenanceItem.duration_percentage}
              onBlur={e => modalHandleChange(maintenanceItem, 'duration_percentage', parseFloat(e.target.value))}
              decimalSeparator={','}
              decimalScale={2}
              suffix="%"
              className={classes.field}
              allowNegative={false}
              isAllowed={(values) => {
                const { floatValue } = values;
                return floatValue <= 100;
              }}
            />
          </FormGroup>
        </FormControl>
      </div>
      <div style={{ marginTop: 10 }}>
        <div>
          <FormControl style={{
            display: 'block',
            marginTop: 10,
          }}
          >
            <FormGroup>
              <FormLabel className={classes.formLabel}>
                {`${translate('common:Comment')}:`}
              </FormLabel>
              <PF2MOutlinedInput
                className={classes.fieldCommentary}
                value={maintenanceItem.observation}
                onBlur={e => modalHandleChange(maintenanceItem, 'observation', e.target.value)}
                multiline
                rows={4}
              />
            </FormGroup>
          </FormControl>
        </div>
      </div>
    </>
  );

  const renderFooter = () => (
    <div style={{
      display: 'flex',
      marginTop: 10,
      alignItems: 'center',
      justifyContent: 'center',
    }}
    >
      <PF2MButton
        style={{
          marginRight: 'auto',
        }}
        color="primary"
        onClick={() => dispatch(resetMaintenanceOperationItems())}
      >
        <DeleteOutline className={classes.icon} />
        {translate('common:DiscardChanges')}
      </PF2MButton>
      <PF2MAddButton
        color="primary"
        onClick={() => dispatch(createMaintenanceOperationItem(generateNewMaintenanceItem()))}
      />
      <Fab
        variant="extended"
        size="medium"
        color="secondary"
        onClick={() => {
          const blankObservations = maintenanceOperationItems
            .some(r => r.observation.trim() === '');
          const blankDurations = maintenanceOperationItems
            .some(r => !r.duration_percentage);
          const blankSystemItem = maintenanceOperationItems
            .some(r => !r.system_item_maintenance_id);
          const blankFailureMode = maintenanceOperationItems
            .some(r => !r.failure_mode_maintenance_id);

          const isCorrection = maintenanceOperation.is_maintenance_correction;
          if (isCorrection) {
            if (blankObservations || blankDurations || blankSystemItem || blankFailureMode) {
              setAlertOpen(true);
              return;
            }
          } else if (blankObservations || blankDurations) {
            setAlertOpen(true);
            return;
          }

          dispatch(updateMaintenanceOperationItems(
            maintenanceOperationItems,
            maintenanceOperation.is_maintenance_correction,
          ));
        }}
        className={classes.saveButton}
        style={{ width: 200 }}
      >
        {translate('common:Save')}
      </Fab>
    </div>
  );

  return (
    <>
      <Card style={{ maxWidth: 1000 }}>
        <CardHeader icon>
          <>
            <CardIcon color="warning">
              <CreateIcon />
            </CardIcon>
            <CardIcon
              onClick={() => dispatch(resetMaintenanceOperationItems())}
              style={{
                float: 'right',
                cursor: 'pointer',
                background: 'darkgray',
                borderRadius: 50,
                boxShadow: '10px 10px 50px gray',
              }}
            >
              <CloseIcon />
            </CardIcon>
          </>
        </CardHeader>
        <CardBody>
          {renderHeader()}
          <hr />
          {
            maintenanceOperationItems.length
              ? (
                <PF2MScrollbar
                  className={classes.scrollbars}
                  style={{ maxHeight: 450, padding: 10 }}
                >
                  <PF2MAccordion items={maintenanceOperationItems.map((r, i) => ({
                    id: r.id,
                    head: `Item ${i + 1}`,
                    body: renderBody(maintenanceOperation, r),
                  }))}
                  />
                </PF2MScrollbar>
              )
              : <div style={{ textAlign: 'center' }}>{translate('validation:NoMaintenanceItems')}</div>
          }
          {renderFooter()}
        </CardBody>
      </Card>
      <PF2MAlertDialog
        hasCancel={false}
        confirmText={translate('common:Understood')}
        description={translate('validation:ThereAreBlankFields')}
        open={alertOpen}
        onConfirm={() => setAlertOpen(false)}
      />
    </>
  );
};

MaintenanceDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

MaintenanceDetails.defaultProps = {};

export default withStyles(styles)(MaintenanceDetails);

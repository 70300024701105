import React from 'react';
import {
  TableCell,
  TableRow,
  Fab,
  withStyles,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreIcon from '@material-ui/icons/Restore';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import { KeyboardDatePicker } from '@material-ui/pickers';
// import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import PF2MSearchSelect from '~/components/PF2MSearchSelect';
import PF2MMaskTextField from '~/components/PF2MMaskTextField';
import styles from './styles';
import {
  formatDateToServerFormat,
  parseDateToServerFormat,
} from '~/utils/moment';
import {
  setCurrentMaintenanceOperationItems,
} from '~/store/maintenance/actions';

const MAINTENANCE_TYPE = 2;

const ListRow = ({
  data,
  classes,
  onEdit,
  onDelete,
  onRestore,
}) => {
  const { t: translate } = useTranslation();
  const operatorsGroups = useSelector(state => state.manager.operatorsGroups
    .filter(s => s.name));
  const operators = useSelector(state => state.manager.operators
    .filter(s => s.name && s.type === MAINTENANCE_TYPE));
  const codeTypes = useSelector(state => state.maintenance.codeTypes);
  const codeGroups = useSelector(state => state.maintenance.codeGroups);
  const codes = useSelector(state => state.maintenance.codes);
  const systems = useSelector(state => state.maintenance.systems);
  const exceptionTypes = useSelector(state => state.manager.exceptionTypes);

  const teams = useSelector(state => state.manager.teams, data.team_id);
  const teamsGroups = useSelector(state => state.manager.teamGroups, data.team_group_id);
  const turns = useSelector(state => state.manager.turns);
  const turnsGroups = useSelector(state => state.manager.turnsGroups);
  const equipmentTypes = useSelector(state => state.manager.equipmentTypes);
  const equipmentGroups = useSelector(state => state.manager.equipmentsGroups);
  const equipments = useSelector(state => state.manager.equipments);

  const dispatch = useDispatch();

  const handleSelectRow = async () => {
    dispatch(setCurrentMaintenanceOperationItems(data));
  };

  // handle clearing outside if value can be changed outside of the component
  const handleMask = value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : []);

  const renderDatePicker = (d, attr) => (
    <KeyboardDatePicker
      clearable
      mask={handleMask}
      format={translate('date:DateFormat')}
      disableFuture
      autoOk
      onChange={e => onEdit(d, attr, formatDateToServerFormat(e))}
      value={d[attr] ? parseDateToServerFormat(d[attr]) : ''}
      invalidDateMessage="Data inválida"
    />
  );

  const renderTimePicker = (d, attr) => (
    <PF2MMaskTextField
      mask="99:99:99"
      value={d[attr]}
      onChange={e => onEdit(data, attr, e.target.value)}
    />
  );

  const renderExceptionType = (idExceptionType) => {
    const { name = '' } = exceptionTypes.find(e => e.id === idExceptionType) || {};
    return name;
  };

  return (
    <TableRow>
      <TableCell className={classes.tableCell}>
        {renderDatePicker(data, 'start_date')}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderTimePicker(data, 'start_time')}
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.equipaments_id}
          onChange={e => onEdit(data, 'equipaments_id', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {equipmentTypes.map(e => ({ value: e.id, label: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.equipament_groups_id}
          onChange={e => onEdit(data, 'equipament_groups_id', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {equipmentGroups
            .filter(e => data.equipaments_id === 0 || e.id_equipament === data.equipaments_id)
            .map(e => ({ value: e.id, label: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.group_equipament_links_id}
          onChange={e => onEdit(data, 'group_equipament_links_id', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {equipments
            .filter(e => data.equipament_groups_id === 0
              || e.id_group === data.equipament_groups_id)
            .map(e => ({ value: e.id, label: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.code_group_maintenance_id}
          onChange={e => onEdit(data, 'code_group_maintenance_id', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {codeGroups.map(e => ({ value: e.id, label: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.code_maintenance_id}
          onChange={e => onEdit(data, 'code_maintenance_id', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {codes
            .filter(e => data.code_group_maintenance_id === 0
              || Number(e.id_code_group_maintenance) === Number(data.code_group_maintenance_id))
            .map(e => ({ value: e.id, label: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.turn_group_id}
          onChange={e => onEdit(data, 'turn_group_id', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {turnsGroups.map(e => ({ value: e.id, label: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.turn_id}
          onChange={e => onEdit(data, 'turn_id', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {turns
            .filter(e => data.turn_group_id === 0 || e.id_turn_group === data.turn_group_id)
            .map(e => ({ value: e.id, label: e.name }))
          }
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.team_group_id}
          onChange={e => onEdit(data, 'team_group_id', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {teamsGroups.map(e => ({ value: e.id, label: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.team_id}
          onChange={e => onEdit(data, 'team_id', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {teams
            .filter(e => data.team_group_id === 0 || e.id_team_group === data.team_group_id)
            .map(e => ({ value: e.id, label: e.name }))
          }
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.operators_groups_id}
          onChange={e => onEdit(data, 'operators_groups_id', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {operatorsGroups.map(e => ({ value: e.id, label: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.operator_id}
          onChange={e => onEdit(data, 'operator_id', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {operators
            .filter(o => data.operators_groups_id === 0 || o.id_group === data.operators_groups_id)
            .map(e => ({ value: e.id_operator, label: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.code_type_maintenance_id}
          onChange={e => onEdit(data, 'code_type_maintenance_id', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {codeTypes.map(e => ({ value: e.id, label: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.code_group_maintenance_id}
          onChange={e => onEdit(data, 'code_group_maintenance_id', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {codeGroups
            .filter(c => data.code_type_maintenance_id === 0
              || c.id_code_type_maintenance === data.code_type_maintenance_id)
            .map(e => ({ value: e.id, label: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.code_maintenance_id}
          onChange={e => onEdit(data, 'code_maintenance_id', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {codes
            .filter(c => data.code_group_maintenance_id === 0
              || c.id_code_group_maintenance === data.code_group_maintenance_id)
            .map(e => ({ value: e.id, label: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.system_maintenance_id}
          onChange={e => onEdit(data, 'system_maintenance_id', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {systems
            .map(e => ({ value: e.id, label: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderExceptionType(data.exception_type)}
      </TableCell>
      <TableCell style={{
        position: 'sticky',
        right: 0,
        height: 48,
      }}
      >
        <div style={{ display: 'flex' }}>
          {(data.code_maintenance_islogout || data.has_items) && (
            <Fab
              size="small"
              color="primary"
              aria-label="Restore"
              onClick={handleSelectRow}
              style={{ marginRight: 10 }}
            >
              <EditIcon />
            </Fab>
          )}
          {data.isDirty ? (
            <Fab
              size="small"
              color="primary"
              aria-label="Restore"
              onClick={() => onRestore(data)}
            >
              <RestoreIcon />
            </Fab>
          ) : (
            <Fab
              size="small"
              color="primary"
              aria-label="Delete"
              onClick={() => onDelete(data)}
            >
              <DeleteIcon />
            </Fab>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};

ListRow.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onRestore: PropTypes.func,
  // handleEditModal: PropTypes.func,
};

ListRow.defaultProps = {
  data: {},
  onEdit: null,
  onDelete: null,
  onRestore: null,
  // handleEditModal: null,
};

export default React.memo(withStyles(styles)(ListRow));

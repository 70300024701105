import * as types from './types';
import { remapData } from '~/utils';

const INITIAL_STATE = {
  contents: [],
  originalContents: [],
  isContentsDirty: false,
  contentVariables: [],
  contentVariablesFlat: [],
  originalContentVariables: [],
  isContentVariablesDirty: false,
  stockClosings: [],
  stockClosingsFlat: [],
  originalStockClosings: [],
  isStockClosingsDirty: false,
};

export const adjustContentVariables = (items = [], state) => {
  const data = items.map((c) => {
    const {
      contents, flowchart_element_points: subElement, materials, ...rest
    } = c;
    const contentState = state.contents;
    const contentKeys = {};
    contents.forEach((content) => {
      const valueType = Number(!!contentState.find(i => i.id === content.content_id).value_type);
      contentKeys[`content_${content.content_id}_${valueType}`] = valueType === 1
        ? content.value_str
        : content.value;
    });
    return {
      ...rest,
      ...contentKeys,
    };
  });
  return data;
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_CONTENTS_LIST: {
      return {
        ...state,
        isContentsDirty: false,
        originalContents: action.payload,
        contents: action.payload,
      };
    }
    case types.RESET_CONTENTS: {
      return {
        ...state,
        contents: state.originalContents,
        isContentsDirty: false,
      };
    }
    case types.SET_CONTENT_VALUE: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.contents, newItem, 'id');
      const isContentsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isContentsDirty,
        contents: newData,
      };
    }
    case types.ADD_CONTENT: {
      const newItem = {
        ...action.payload,
        id: (0 - state.contents.length),
        update_status: 'I',
        active: 1,
        isDirty: true,
      };
      const newData = [newItem, ...state.contents];
      const isContentsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isContentsDirty,
        contents: newData,
      };
    }
    case types.SET_CONTENT_VARIABLES_LIST: {
      const data = adjustContentVariables(action.payload, state);

      return {
        ...state,
        isContentVariablesDirty: false,
        originalContentVariables: action.payload,
        contentVariables: action.payload,
        contentVariablesFlat: data,
      };
    }
    case types.RESET_CONTENT_VARIABLES: {
      const data = adjustContentVariables(state.originalContentVariables, state);
      return {
        ...state,
        contentVariables: state.originalContentVariables,
        contentVariablesFlat: data,
        isContentVariablesDirty: false,
      };
    }
    case types.SET_CONTENT_VARIABLE_VALUE: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.contentVariablesFlat, newItem, 'id');
      const isContentVariablesDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isContentVariablesDirty,
        contentVariablesFlat: newData,
      };
    }
    case types.ADD_CONTENT_VARIABLE: {
      const newItem = {
        ...action.payload,
        id: (0 - state.contentVariablesFlat.length),
        update_status: 'I',
        active: 1,
        isDirty: true,
      };
      const newData = [newItem, ...state.contentVariablesFlat];
      const isContentVariablesDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isContentVariablesDirty,
        contentVariablesFlat: newData,
      };
    }
    case types.SET_STOCK_CLOSINGS_LIST: {
      const data = adjustContentVariables(action.payload, state);

      return {
        ...state,
        isStockClosingsDirty: false,
        originalStockClosings: action.payload,
        stockClosings: action.payload,
        stockClosingsFlat: data,
      };
    }
    case types.RESET_STOCK_CLOSINGS: {
      const data = adjustContentVariables(state.originalStockClosings, state);
      return {
        ...state,
        stockClosings: state.originalStockClosings,
        stockClosingsFlat: data,
        isStockClosingsDirty: false,
      };
    }
    case types.SET_STOCK_CLOSING_VALUE: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.stockClosingsFlat, newItem, 'id');
      const isStockClosingsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isStockClosingsDirty,
        stockClosingsFlat: newData,
      };
    }
    case types.ADD_STOCK_CLOSING: {
      const newItem = {
        ...action.payload,
        id: (0 - state.stockClosingsFlat.length),
        update_status: 'I',
        active: 1,
        isDirty: true,
      };
      const newData = [newItem, ...state.stockClosingsFlat];
      const isStockClosingsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isStockClosingsDirty,
        stockClosingsFlat: newData,
      };
    }
    default: {
      return state;
    }
  }
}

/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import {
  Card, CardBody, CardHeader, CardIcon,
} from '~/components/Card';
import { Grid, Typography, withStyles } from '@material-ui/core';
import PF2MDataTable from '~/components/PF2MDataTable';
import PF2MDiscardChangesButton from '~/components/PF2MDiscardChangesButton';
import PF2MFinalizeButton from '~/components/PF2MFinalizeButton';
import { useDispatch, useSelector } from 'react-redux';
import { setRailroadValues } from '~/store/dailyparts/actions';
import usePlatformConfiguration from '~/services/platformConfiguration';
import { Build } from '@material-ui/icons';
import styles from '../../styles';

const ModalRailroad = ({ handleClose, railroadReport }) => {
  const { t: translate } = useTranslation();

  const getNextId = (registersToFindNextId) => {
    if (registersToFindNextId.length === 0) {
      return 1;
    }

    const registerWithMaxId = registersToFindNextId.reduce((regWithMaxId, register) => {
      if (register.id === undefined && regWithMaxId.id === undefined) return register;
      if (register.id === undefined) return regWithMaxId;
      if (regWithMaxId.id === undefined) return register;
      if (regWithMaxId.id < register.id) return register;
      return regWithMaxId;
    });

    if (registerWithMaxId?.id === undefined) {
      return 1;
    }
    return registerWithMaxId.id + 1;
  };

  const createInitialRailroaldMaterials = () => {
    const initialRairoladMaterials = railroadReport.materials.map(o => ({ ...o }));

    let nextId = getNextId(initialRairoladMaterials);
    initialRairoladMaterials.forEach((material) => {
      if (material.id === undefined) {
        material.isFakeId = true;
        // eslint-disable-next-line no-plusplus
        material.id = nextId++;
      }
    });
    return initialRairoladMaterials;
  };

  const dispatch = useDispatch();
  const [isModalApplyOpen, setIsModalApplyOpen] = useState(false);
  const [isModalAssociateMaterialOpen, setIsModalAssociateMaterialOpen] = useState(false);
  const [isModalDiscardOpen, setIsModalDiscardOpen] = useState(false);
  const materials = useSelector(state => state.manager.materials);
  const equipments = useSelector(state => state.manager.equipments);
  const [hasChanges, setHasChanges] = useState(false);
  const { value: editLoadDefault } = usePlatformConfiguration('edit_load_default');
  const [railroadMaterials, setRailroadMaterials] = useState(createInitialRailroaldMaterials);
  const loadDefault = useSelector(state => state.manager.loads);

  const isEditable = () => (parseInt(editLoadDefault) ? 'always' : 'never');

  const columns = [
    {
      editable: 'never',
      title: translate('common:Equipment'),
      field: 'id_equip',
      lookup: equipments.filter(a => a.id_equip === 32),
      validate: row => row.id_equip && row.id_equip !== '0',
    },
    {
      editable: 'always',
      title: translate('common:Material'),
      field: 'id_material',
      lookup: materials,
    },
    {
      editable: isEditable() ? 'never' : 'always',
      title: translate('common:StandardLoad'),
      field: 'load_manager',
    },
  ];

  const handleOnDelete = (railroadMaterialsToDelete) => {
    if (railroadMaterialsToDelete.length) {
      setHasChanges(true);
      setRailroadMaterials(currentMaterials => currentMaterials.filter((item) => {
        const itemDeleted = railroadMaterialsToDelete.find(deleted => deleted.id === item.id);
        if (!itemDeleted) return true;
        if (item.isFakeId) return false;

        item.update_status = 'D';
        return true;
      }));
    }
  };

  const handleOnAdd = (newRailroadMaterial) => {
    setHasChanges(true);
    setRailroadMaterials((currentMaterials) => {
      newRailroadMaterial.id = getNextId(currentMaterials);
      newRailroadMaterial.isFakeId = true;
      newRailroadMaterial.update_status = 'I';

      return [
        ...currentMaterials,
        { ...newRailroadMaterial },
      ];
    });
  };

  const onApply = (deleteFakeId) => {
    const filteredMaterials = railroadMaterials.map((material) => {
      if (material.isFakeId && deleteFakeId) {
        delete material.id;
        delete material.isFakeId;
      }
      return { ...material };
    });

    const newRailroadReport = {
      ...railroadReport,
      tableData: undefined,
      materials: filteredMaterials,
    };

    dispatch(setRailroadValues(newRailroadReport));
  };

  const handleOnApply = () => {
    onApply(true);
    handleClose();
  };

  const handleConfirmChanges = () => {
    setIsModalApplyOpen(false);
    if (hasChanges) {
      handleOnApply();
    }
  };

  const handleOnDiscardChanges = () => {
    if (hasChanges) {
      setIsModalDiscardOpen(true);
    }
  };

  const handleOnConfirmDiscard = () => {
    setRailroadMaterials(createInitialRailroaldMaterials);
    setIsModalDiscardOpen(false);
    setHasChanges(false);
  };

  const checkAssociateMaterial = () => {
    const check = railroadMaterials.find(element => element.id_material && element.id_material !== '');
    if (check === undefined) {
      setIsModalAssociateMaterialOpen(true);
    } else {
      setIsModalApplyOpen(true);
    }
  };

  const handleOnChange = (railroadMaterialsToChange) => {
    setRailroadMaterials((currentMaterials) => {
      railroadMaterials.forEach((material, index) => {
        railroadMaterialsToChange.forEach((updated) => {
          const { updateStatus } = updated;
          if (material.id === updated.id) {
            const ld = loadDefault.find(e => (
              parseInt(e.id_equip) === parseInt(currentMaterials[index].id_equip)
              && parseInt(e.id_material) === parseInt(updated.id_material)));
            const newUpdateStatus = material.isFakeId ? 'I' : 'U';
            currentMaterials[index] = {
              ...updated,
              update_status: updateStatus || newUpdateStatus,
              load_manager: ld ? ld.value : 0,
            };
          }
        });
      });
      return currentMaterials;
    });
    onApply(false);
    setHasChanges(true);
  };

  return (
    <div>
      <Card>
        <CardHeader icon>
          <CardIcon color="warning">
            <Build />
          </CardIcon>
          <CardIcon
            onClick={handleClose}
            style={{
              float: 'right',
              cursor: 'pointer',
              background: 'darkgray',
              borderRadius: 50,
              boxShadow: '10px 10px 50px gray',
            }}
          >
            <CloseIcon />
          </CardIcon>
        </CardHeader>
        <CardBody>
          <Typography
            variant="h4"
            align="center"
            gutterBottom
          >
            {translate('common:Materials')}
          </Typography>
          <PF2MDataTable
            options={{
              search: true,
              selection: true,
              hideDelete: true,
              hideAdd: true,
            }}
            onAdd={handleOnAdd}
            onChange={handleOnChange}
            onDiscard={handleOnDiscardChanges}
            onApply={() => setIsModalApplyOpen(true)}
            onDelete={handleOnDelete}
            initialFormData={
              {
                ...columns.reduce((acc, e) => ({ ...acc, [e.field]: '0' }), {}),
                usn_date: null,
                usn_time: null,
              }
            }
            columns={columns}
            data={railroadMaterials.length > 0
              ? railroadMaterials.filter(r => r.update_status !== 'D')
              : []
            }
          />
        </CardBody>

        <Grid item style={{ alignSelf: 'center' }}>
          <PF2MDiscardChangesButton onClick={handleOnDiscardChanges} />
          <PF2MFinalizeButton onClick={() => checkAssociateMaterial()} />
        </Grid>

      </Card>
      <PF2MAlertDialog
        hasCancel
        confirmText={translate(hasChanges ? 'common:Confirm' : 'common:Ok')}
        description={translate(hasChanges ? 'common:ConfirmChanges' : 'common:ThereAreNoChanges')}
        open={isModalApplyOpen}
        onClose={() => setIsModalApplyOpen(false)}
        onConfirm={handleConfirmChanges}
      />

      <PF2MAlertDialog
        hasCancel
        confirmText={translate('common:Discard')}
        description={translate('common:DiscardChanges')}
        open={isModalDiscardOpen}
        onClose={() => setIsModalDiscardOpen(false)}
        onConfirm={handleOnConfirmDiscard}
      />


      <PF2MAlertDialog
        hasCancel={false}
        confirmText={translate('common:Ok')}
        description={translate('common:Railroad_associate_material')}
        open={isModalAssociateMaterialOpen}
        onConfirm={() => setIsModalAssociateMaterialOpen(false)}
      />
    </div>
  );
};

ModalRailroad.propTypes = {
  handleClose: PropTypes.func,
  railroadReport: PropTypes.array.isRequired,
};

ModalRailroad.defaultProps = {
  handleClose: null,
};

export default withStyles(styles)(ModalRailroad);

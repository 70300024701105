import * as types from './types';
import { parseFloat as utilsParseFloat } from '~/utils';

const INITIAL_STATE = {
  dmtRanges: [],
  materialsCost: [],
  originalDmtRanges: [],
  workHourValues: [],
  isDmtRangesDirty: false,
};

const remapData = (items, newItem, key) => items.map((item) => {
  if (item[key] === newItem[key]) {
    return {
      ...newItem,
    };
  }
  return item;
});

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_DMT_RANGES_LIST: {
      return {
        ...state,
        isDmtRangesDirty: false,
        originalDmtRanges: action.payload,
        dmtRanges: action.payload,
      };
    }
    case types.SET_DMT_RANGES_MATERIAL_COST_LIST: {
      return {
        ...state,
        materialsCost: action.payload,
      };
    }
    case types.RESET_DMT_RANGES: {
      return {
        ...state,
        dmtRanges: state.originalDmtRanges,
        isDmtRangesDirty: false,
      };
    }
    case types.SET_DMT_RANGE_VALUE: {
      const newItemRangeEnd = utilsParseFloat(action.payload.range_end);
      const newItem = {
        ...action.payload,
        range_end: newItemRangeEnd,
        isDirty: !(action.payload.id < 1),
      };

      let newData = remapData(state.dmtRanges, newItem, 'id');

      const existingDmtRange = newData
        .find(d => d.id_equipment_type === newItem.id_equipment_type
        && d.id_equip_group === newItem.id_equip_group
        && d.id !== newItem.id
        && d.range_start < newItemRangeEnd
        && d.range_end > newItemRangeEnd);

      if (existingDmtRange) {
        existingDmtRange.range_start = newItemRangeEnd + 0.01;
        existingDmtRange.update_status = existingDmtRange.update_status === 'I' ? 'I' : 'U';
        newData = remapData(state.dmtRanges, newItem, 'id');
      }

      const isDmtRangesDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isDmtRangesDirty,
        dmtRanges: newData,
      };
    }
    case types.ADD_DMT_RANGE: {
      const newItem = {
        ...action.payload,
        id: (0 - state.dmtRanges.length),
        update_status: 'I',
        active: 1,
        isDirty: true,
      };
      const newData = [newItem, ...state.dmtRanges];
      const isDmtRangesDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isDmtRangesDirty,
        dmtRanges: newData,
      };
    }
    case types.SET_WORK_HOUR_VALUES_LIST: {
      return {
        ...state,
        workHourValues: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

const styles = {
  root: {
    width: '100%',
    overflowY: 'auto',
    height: 'calc(100vh - 52px - 52px - 220px)',
  },
  table: {
    minWidth: 700,
    position: 'relative',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#F9F9F9',
    },
    backgroundColor: 'white',
  },
  customAdornment: {
    paddingRight: 0,
  },
  select: {
    height: 40,
    width: 200,
    fontFamily: 'Roboto',
    fontWeight: 300,
    fontSize: 14,
    color: '#647886', // '#CDCDCD',
  },
  textField: {
    fontFamily: 'Roboto',
    fontWeight: 300,
    fontSize: 14,
    color: '#647886', // '#CDCDCD',
  },
  textFieldError: {
    borderColor: 'red',
  },
};

export default styles;

import { managerGridHeight } from '~/utils';

const styles = {
  iconStyle: {
    color: 'rgb(242 170 0)',
    fontSize: '2rem',
  },
  container: {
    display: 'flex',
    backgroundColor: '#F9F9F9',
    height: managerGridHeight,
    padding: 10,
  },
  icon: {
    marginRight: 10,
  },
  button: {
    color: '#FFF',
    margin: 5,
  },
  tabContainer: {
    borderRadius: 5,
    borderTopLeftRadius: 0,
    backgroundColor: 'white',
    padding: 10,
    height: 'calc(100vh - 120px)',
    width: 'calc(100vw - 160px)',
  },
  grid: {
    width: '100%',
    overflowY: 'auto',
    height: 'calc(100vh - 52px - 52px - 180px - 67px)',
  },
  containerTypeSelector: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'center',
    width: '100%',
    padding: 0,
  },
  labelTypeSelector: {
    color: '#647886',
    fontFamily: 'Barlow Condensed',
    fontWeight: 600,
    fontSize: 18,
    textTransform: 'uppercase',
    marginRight: 10,
  },
  typeSelector: {
    height: 40,
    fontFamily: 'Roboto',
    fontWeight: 300,
    fontSize: 14,
    color: '#CDCDCD',
  },
};

export default styles;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PF2MOutlinedInput from '~/components/PF2MOutlinedInput';

/**
 *
 * @param {function} delayedChange function useCallback
 * @returns component
 */
function DelayedInput({
  delay = 350, value, delayedChange, ...rest
}) {
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    // it updates the value after some time for performance reasons
    const id = setTimeout(() => delayedChange(currentValue), delay);
    return () => clearTimeout(id);
  }, [currentValue, delay, delayedChange]);

  return (
    <PF2MOutlinedInput
      onChange={e => setCurrentValue(e.target.value)}
      value={currentValue}
      {...rest}
    />
  );
}

DelayedInput.propTypes = {
  classes: PropTypes.objectOf({
    labelTypeSelector: PropTypes.string.isRequired,
    typeSelector: PropTypes.string.isRequired,
  }).isRequired,
  delay: PropTypes.number.isRequired,
  delayedChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default DelayedInput;

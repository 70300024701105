import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from '../styles';
import Header from '~/pages/Manager/Header';
import Footer from '~/pages/Manager/Footer';
import PF2MTable from '~/components/PF2MTable';
import { useTranslation } from 'react-i18next';
import {
  getOperatorsTemplate,
  exportOperators,
  importOperators,
  getOperators,
} from '~/store/manager/actions';

const OperatorsTab = ({
  classes,
  operators,
  getOperators: getAll,
  getOperatorsTemplate: getTemplateAction,
  exportOperators: exportAction,
  importOperators: importAction,
}) => {
  const [operatorsLoaded, setOperatorsLoaded] = useState(false);
  const { t: translate } = useTranslation();

  useEffect(() => {
    async function fetchOperators() {
      await getAll();
    }
    if (!operatorsLoaded) {
      fetchOperators();
      setOperatorsLoaded(true);
    }
  }, [operatorsLoaded, getAll]);

  const columns = [
    { field: 'id', title: translate('common:Registration') },
    {
      field: 'name',
      title: translate('common:Name'),
      width: '100%',
      wrap: true,
    },
    { field: 'type_name', title: translate('common:Type') },
    { field: 'password', title: translate('common:Password') },
    { field: 'active_name', title: 'STATUS' },
    { field: 'group_name', title: translate('common:Group') },
  ];

  return (
    <div className={classes.tabContainer}>
      <Header
        getTemplateAction={getTemplateAction}
        importAction={importAction}
        exportAction={exportAction}
      />
      <PF2MTable
        data={operators}
        columns={columns}
      />
      <Footer />
    </div>
  );
};

OperatorsTab.propTypes = {
  classes: PropTypes.object.isRequired,
  operators: PropTypes.array,
  getOperators: PropTypes.func,
  getOperatorsTemplate: PropTypes.func,
  exportOperators: PropTypes.func,
  importOperators: PropTypes.func,
};

OperatorsTab.defaultProps = {
  operators: [],
  getOperators: () => {},
  getOperatorsTemplate: () => {},
  exportOperators: () => {},
  importOperators: () => {},
};

const mapStateToProps = state => ({
  operators: state.manager.operators,
});

const mapDispatchToProps = {
  getOperators,
  getOperatorsTemplate,
  exportOperators,
  importOperators,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OperatorsTab));

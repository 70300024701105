import React from 'react';
import { Badge, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import PF2MButton from '../PF2MButton';
import styles from './styles';
import {
  handleDynamicClass, handleDynamicIcon, menu as menuObject,
} from '~/utils';
import PF2MTooltip from '../PF2MTooltip';

const Menu = ({
  classes,
  match,
  location,
  handleNavigation,
  moduleName,
  autoOperationTypeEnabled,
  userLevel,
  excludePaths = [],
  roles,
}) => {
  const menu = moduleName !== 'manager' ? menuObject[moduleName]
    : menuObject[moduleName].filter(
      el => (el.key !== '15' || userLevel <= 2),
    ).filter(
      el => (el.key !== '19' || autoOperationTypeEnabled),
    ).filter(
      el => (el.key !== '19' || userLevel <= 2),
    );
  const renderButton = d => (
    <Badge
      className={handleDynamicClass(d, roles, classes)}
      overlap="rectangular"
      badgeContent={handleDynamicIcon(d, roles)}
    >
      <PF2MButton
        color={location.pathname === `${match.path}/${d.path}` ? 'secondary' : 'primary'}
        disabled={d.disabled || false}
        className={classes.link}
        component={Link}
        key={d.key}
        to={`${match.path}/${d.path}`}
        onClick={e => handleNavigation(e, d.path)}
      >
        <span style={{ lineHeight: 1.2 }}>
          {d.label}
        </span>
      </PF2MButton>
    </Badge>
  );

  return (
    <div className={classes.container}>
      {menu.filter(m => !excludePaths.includes(m.path)).map(d => (
        <span style={{ display: 'contents' }} key={`${d.key}-tooltip`}>
          {d.tooltip && (
            <PF2MTooltip title={d.tooltip}>
              {renderButton(d)}
            </PF2MTooltip>
          )}
          {!d.tooltip && renderButton(d)}
        </span>
      ))}
    </div>
  );
};

Menu.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  handleNavigation: PropTypes.func,
  moduleName: PropTypes.string,
  excludePaths: PropTypes.arrayOf(PropTypes.string),
  userLevel: PropTypes.number,
  autoOperationTypeEnabled: PropTypes.bool,
  roles: PropTypes.array,
};

Menu.defaultProps = {
  handleNavigation: null,
  moduleName: 'manager',
  excludePaths: [],
  userLevel: 5,
  autoOperationTypeEnabled: false,
  roles: [],
};

export default withRouter(withStyles(styles)(Menu));

import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from '../styles';
import Header from '~/pages/Manager/Header';
import Footer from '~/pages/Manager/Footer';
import PF2MTable from '~/components/PF2MTable';
import { useTranslation } from 'react-i18next';
import {
  getMaterialsGroupsTemplate,
  exportMaterialsGroups,
  importMaterialsGroups,
  getMaterialsGroups,
} from '~/store/manager/actions';

const GroupsTab = ({
  classes,
  materialsGroups,
  getMaterialsGroupsTemplate: getTemplateAction,
  exportMaterialsGroups: exportAction,
  importMaterialsGroups: importAction,
  getMaterialsGroups: getAll,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const { t: translate } = useTranslation();

  useEffect(() => {
    async function fetchOperators() {
      await getAll();
    }
    if (!dataLoaded) {
      fetchOperators();
      setDataLoaded(true);
    }
  }, [dataLoaded, getAll]);

  const columns = [
    // {
    //   field: 'type_name', title: 'TIPO',
    // },
    {
      field: 'name', title: translate('common:Name'),
    },
    {
      field: 'description', title: translate('common:Description'), width: '100%',
    },
    {
      field: 'active_name', title: 'STATUS',
    },
  ];

  return (
    <div className={classes.tabContainer}>
      <Header
        getTemplateAction={getTemplateAction}
        importAction={importAction}
        exportAction={exportAction}
      />
      <PF2MTable
        data={materialsGroups}
        columns={columns}
      />
      <Footer />
    </div>
  );
};

GroupsTab.propTypes = {
  classes: PropTypes.object.isRequired,
  materialsGroups: PropTypes.array,
  getMaterialsGroupsTemplate: PropTypes.func,
  exportMaterialsGroups: PropTypes.func,
  importMaterialsGroups: PropTypes.func,
  getMaterialsGroups: PropTypes.func,
};

GroupsTab.defaultProps = {
  materialsGroups: [],
  getMaterialsGroupsTemplate: () => {},
  exportMaterialsGroups: () => {},
  importMaterialsGroups: () => {},
  getMaterialsGroups: () => {},
};

const mapStateToProps = state => ({
  materialsGroups: state.manager.materialsGroups,
});

const mapDispatchToProps = {
  getMaterialsGroupsTemplate,
  exportMaterialsGroups,
  importMaterialsGroups,
  getMaterialsGroups,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GroupsTab));

export default {
  field: {
    border: '1px solid #CDCDCD',
    borderRadius: 5,
    height: 40,
  },
  formLabel: {
    color: '#647886',
    fontSize: 12,
    fontFamily: 'Roboto',
    marginBottom: 5,
  },
  filterFormContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
};

import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './styles';
import truckEmpty from '~/assets/dispatch/truck_empty.png';
import truckFull from '~/assets/dispatch/truck_full.png';
import truckUnloading from '~/assets/dispatch/truck_unloading.png';
import excavator from '~/assets/dispatch/excavator.png';
import breaker from '~/assets/dispatch/breaker.png';
import combTruck from '~/assets/dispatch/comb_truck.png';
import tractor from '~/assets/dispatch/tractor.png';
import waterTruck from '~/assets/dispatch/water_truck.png';
import compactor from '~/assets/dispatch/compactor.png';
import perforator from '~/assets/dispatch/perforator.png';
import motorgrader from '~/assets/dispatch/motorgrader.png';
import warningNovis from '~/assets/dispatch/warning_novis.png';
import warningPause from '~/assets/dispatch/warning_pause.png';
import warningQuestion from '~/assets/dispatch/warning_question.png';
import warningSleep from '~/assets/dispatch/warning_sleep.png';
import warningSpeed from '~/assets/dispatch/warning_speed.png';
import warningSteering from '~/assets/dispatch/warning_steering.png';
import warningTime from '~/assets/dispatch/warning_time.png';
import warningTools from '~/assets/dispatch/warning_tools.png';
import warningAttention from '~/assets/dispatch/warning_attention.png';
import material from '~/assets/dispatch/material.png';
import loader from '~/assets/dispatch/loader.png';

const IMAGE_MAPPER = {
  excavator,
  truckEmpty,
  breaker,
  truckFull,
  tractor,
  motorgrader,
  waterTruck,
  truckUnloading,
  combTruck,
  compactor,
  perforator,
  material,
  loader,
};

const WARNING_IMAGE_MAPPER = {
  noVisibility: warningNovis,
  pause: warningPause,
  question: warningQuestion,
  sleep: warningSleep,
  speed: warningSpeed,
  steering: warningSteering,
  time: warningTime,
  maintenance: warningTools,
  attention: warningAttention,
};

const POSITION_MAPPER = {
  'top-left': { top: -5, left: -5 },
  'top-right': { top: -5, right: -5 },
  'bottom-left': { bottom: -5, left: -5 },
  'bottom-right': { bottom: -5, right: -5 },
};

const PF2MDispatchIconMaker = ({
  iconName, settings, classes, ...rest
}) => {
  const {
    iconSize, isBackground, reverse, alt, warnings,
  } = settings;
  const icon = IMAGE_MAPPER[iconName];

  const renderWarningIcon = () => {
    if (warnings) {
      return warnings.map(warning => (
        <img
          key={warning.icon + warning.position}
          className={classes.icon}
          src={WARNING_IMAGE_MAPPER[warning.icon] || warningAttention}
          alt={warning.alt || 'icon'}
          style={{
            width: 25,
            transform: reverse ? 'scaleX(-1)' : 'scaleX(1)',
            position: 'absolute',
            zIndex: 1,
            ...POSITION_MAPPER[warning.position || 'top-right'],
          }}
          {...rest}
        />
      ));
    }
    return null;
  };

  const renderIcon = () => {
    if (isBackground) {
      return (
        <>
          <div
            className={classes.icon}
            style={{
              backgroundImage: `url(${icon || truckEmpty})`,
              width: iconSize,
              height: 42,
              transform: reverse ? 'scaleX(-1)' : 'scaleX(1)',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center bottom',
            }}
            {...rest}
          />
        </>
      );
    }
    return (
      <img
        className={classes.icon}
        src={
          icon || truckEmpty
        }
        alt={alt || 'icon'}
        style={{ width: iconSize, transform: reverse ? 'scaleX(-1)' : 'scaleX(1)' }}
        {...rest}
      />
    );
  };

  return (
    <>
      {renderWarningIcon()}
      {renderIcon()}
    </>
  );
};

PF2MDispatchIconMaker.propTypes = {
  classes: PropTypes.object,
  iconName: PropTypes.oneOf(
    Object.keys(IMAGE_MAPPER),
  ),
  settings: PropTypes.shape({
    iconSize: PropTypes.number,
    isBackground: PropTypes.bool,
    reverse: PropTypes.bool,
    alt: PropTypes.string,
    warnings: PropTypes.oneOf(
      Object.keys(WARNING_IMAGE_MAPPER),
    ),
  }),
};

PF2MDispatchIconMaker.defaultProps = {
  classes: {},
  iconName: 'excavator',
  settings: {},
};

export default withStyles(styles)(PF2MDispatchIconMaker);

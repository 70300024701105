import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import ElementsTab from './ElementsTab';
import SubElementsTab from './SubElementsTab';
import PF2MTabs from '~/components/PF2MTabs';
import MaterialsTab from './MaterialsTab';
import ElementsTypesTab from './ElementsTypesTab';
import { setAlertDialog } from '~/store/manager/actions';

const Elements = ({
  setAlertDialog: setModal,
  isMaterialElementsDirty,
  isElementsTypesDirty,
  isElementsDirty,
  isSubElementsDirty,
}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { t: translate } = useTranslation();

  const tabs = [
    { key: 0, label: translate('common:Element') },
    { key: 1, label: translate('common:SubElement') },
    { key: 2, label: translate('common:MapElement') },
    { key: 3, label: translate('common:MapMaterial') },
  ];

  const handleChange = (event, value) => {
    let blockChange = false;

    switch (value) {
      case 0:
        blockChange = isMaterialElementsDirty || isSubElementsDirty || isElementsTypesDirty;
        break;
      case 1:
        blockChange = isMaterialElementsDirty || isElementsDirty || isElementsTypesDirty;
        break;
      case 2:
        blockChange = isMaterialElementsDirty || isElementsDirty || isSubElementsDirty;
        break;
      default:
        blockChange = isElementsTypesDirty || isElementsDirty || isSubElementsDirty;
        break;
    }

    if (blockChange) {
      event.preventDefault();
      setModal();
    } else {
      setSelectedTab(value);
    }
  };

  return (
    <>
      <PF2MTabs
        value={selectedTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        {tabs}
      </PF2MTabs>
      {selectedTab === 0 ? (<ElementsTab />) : null}
      {selectedTab === 1 ? (<SubElementsTab />) : null}
      {selectedTab === 2 ? (<ElementsTypesTab />) : null}
      {selectedTab === 3 ? (<MaterialsTab />) : null}
    </>
  );
};

Elements.propTypes = {
  setAlertDialog: PropTypes.func,
  isMaterialElementsDirty: PropTypes.bool,
  isElementsTypesDirty: PropTypes.bool,
  isElementsDirty: PropTypes.bool,
  isSubElementsDirty: PropTypes.bool,
};

Elements.defaultProps = {
  setAlertDialog: () => { },
  isMaterialElementsDirty: false,
  isElementsTypesDirty: false,
  isElementsDirty: false,
  isSubElementsDirty: false,
};

const mapStateToProps = state => ({
  isMaterialElementsDirty: state.manager.isMaterialElementsDirty,
  isElementsTypesDirty: state.manager.isElementsTypesDirty,
  isElementsDirty: state.manager.isElementsDirty,
  isSubElementsDirty: state.manager.isSubElementsDirty,
});

const mapDispatchToProps = {
  setAlertDialog,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Elements));

import React, { useState, useEffect } from 'react';
import {
  withStyles,
  Checkbox,
  ListItemText,
  FormLabel,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import PF2MScrollbar from '~/components/PF2MScrollbar';
import Footer from './Footer';
import { useTranslation } from 'react-i18next';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import styles from '../styles';

import {
  getLineupElements,
  getElementPrecedences,
  toggleSubElementPrecedence,
  deselectAllSubElementPrecedences,
  selectAllSubElementPrecedences,
  updateSubElementPrecedences,
  restoreSubElementPrecedences,
  getLineupSubelements,
  calculateSubElementPrecedences,
  getSubElementPrecedences,
  getElevationAssociations,
  getResources,
} from '~/store/lineup/actions';
import PF2MOutlinedInput from '~/components/PF2MOutlinedInput';

const SubElementsTab = ({
  classes,
}) => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const selectedLineup = useSelector(state => state.lineup.selectedLineup);
  const lineupElements = useSelector(state => state.lineup.lineupElements);
  const subElementPrecedences = useSelector(state => state.lineup.subElementPrecedences);
  const isSubElementPrecedencesDirty = useSelector(
    state => state.lineup.isSubElementPrecedencesDirty,
  );
  const [calculatePrecedencesAlertOpen, setCalculatePrecedencesAlertOpen] = useState(false);
  const [selectedElement, setSelectedElement] = useState(0);
  const [selectedSubElement, setSelectedSubElement] = useState(0);
  const [selectedElevation, setSelectedElevation] = useState(0);
  const [selectedPoolElevation, setSelectedPoolElevation] = useState(null);
  const [allSelected, setAllSelected] = useState(false);
  const [elementNameFilter, setElementNameFilter] = useState('');
  const elevationAssociation = useSelector(
    state => state.lineup.elevationAssociations
      .find(r => r.element_id === selectedElement && r.lineup_id === selectedLineup?.id),
  );
  const resources = useSelector(
    state => state.lineup.resources.filter(r => r.lineup_id === selectedLineup?.id),
  );

  useEffect(() => {
    if (selectedLineup) {
      dispatch(getLineupElements(selectedLineup));
      dispatch(getLineupSubelements(selectedLineup));
      dispatch(getElementPrecedences(selectedLineup));
      dispatch(getSubElementPrecedences(selectedLineup));
      dispatch(getElevationAssociations());
    }
  }, [selectedLineup, dispatch]);

  useEffect(() => {
    if (selectedLineup?.id && selectedElement) {
      dispatch(getResources(selectedLineup.id, selectedElement));
    }
  }, [selectedLineup, selectedElement, dispatch]);

  const renderElementPool = items => items
    .filter(r => (r.elevation === selectedElevation || r.elevation === (selectedElevation - elevationAssociation.interval)))
    .filter(r => (r.elevation === selectedPoolElevation || !selectedPoolElevation)
      && r.element_point_id !== selectedSubElement
      && r.element_point_name.toLowerCase().includes(elementNameFilter.toLowerCase()))
    .map(s => (
      <div key={s.element_point_id} style={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox
          checked={subElementPrecedences
            .filter(r => r.update_status !== 'D')
            .some(r => r.father_element_point_id === selectedSubElement
              && r.child_element_point_id === s.element_point_id)}
          color="primary"
          disabled={!selectedSubElement}
          name={s.element_point_id.toString()}
          onChange={() => {
            dispatch(toggleSubElementPrecedence(
              selectedSubElement, s.element_point_id, selectedLineup.id,
            ));
          }}
        />
        <ListItemText secondary={s.element_point_name} style={{ padding: 0 }} />
      </div>
    ));

  const renderBulkSelectButtons = () => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Checkbox
        checked={allSelected}
        color="primary"
        name="all"
        disabled={!selectedSubElement}
        onChange={() => {
          if (allSelected) {
            setAllSelected(false);
            dispatch(deselectAllSubElementPrecedences(
              selectedSubElement,
              resources
                .filter(r => (
                  r.elevation === selectedPoolElevation || selectedPoolElevation === null)
                  && r.element_point_id !== selectedSubElement
                  && r.element_point_name.toLowerCase().includes(elementNameFilter.toLowerCase()))
                .map(r => ({ element_point_id: r.element_point_id })),
              selectedLineup,
            ));
            return;
          }
          setAllSelected(true);
          dispatch(selectAllSubElementPrecedences(
            selectedSubElement,
            resources
              .filter(r => (r.elevation === selectedPoolElevation || selectedPoolElevation === null)
                && r.element_point_id !== selectedSubElement
                && r.element_point_name.toLowerCase().includes(elementNameFilter.toLowerCase()))
              .map(r => ({ element_point_id: r.element_point_id })),
            selectedLineup,
          ));
        }}
      />
      <FormLabel style={{ padding: 0, margin: 0 }}>
        {translate('common:SelectAll')}
      </FormLabel>
    </div>
  );

  const renderElementNameFilter = () => (
    <div>
      <PF2MOutlinedInput
        value={elementNameFilter}
        className={classes.textField}
        style={{ width: 300, maxHeight: 40, marginRight: 10 }}
        type={'text'}
        disabled={!selectedElement}
        placeholder={translate('common:FilterByName')}
        onChange={e => setElementNameFilter(e.target.value)}
      />
    </div>
  );

  const renderPoolElevationFilter = () => (
    <div style={{ width: 300, maxHeight: 40, marginRight: 10 }}>
      <PF2MSearchSelectOutlined
        value={selectedPoolElevation}
        placeholder={translate('common:FilterByElevation')}
        onChange={e => setSelectedPoolElevation(e.target.value)}
      >
        {[{ value: null, label: translate('common:FilterByElevation') }]
          .concat(...(elevationAssociation?.spread_elevations
            .filter(r => (r === selectedElevation || r === (selectedElevation - elevationAssociation.interval)))
            .map(e => ({ value: e, label: e })) || []))}
      </PF2MSearchSelectOutlined>
    </div>
  );

  const renderFilters = () => (
    <div style={{ display: 'flex', width: '100%' }}>
      <div style={{ marginBottom: 20, width: 300 }}>
        <span className={classes.label}>
          {`${translate('common:Element')}:`}
        </span>
        <PF2MSearchSelectOutlined
          value={selectedElement}
          placeholder={translate('common:Select')}
          onChange={e => setSelectedElement(e.target.value)}
        >
          {[{ value: 0, label: translate('common:Select') }]
            .concat(...lineupElements
              .map(e => ({ value: e.element_id, label: e.element_name })))}
        </PF2MSearchSelectOutlined>
      </div>
      <div style={{ marginBottom: 20, width: 300, marginLeft: 10 }}>
        <span className={classes.label}>
          {`${translate('common:Elevation')}:`}
        </span>
        <PF2MSearchSelectOutlined
          value={selectedElevation}
          placeholder={translate('common:Select')}
          onChange={e => setSelectedElevation(e.target.value)}
        >
          {[{ value: 0, label: translate('common:Select') }]
            .concat(...(elevationAssociation?.spread_elevations
              .map(e => ({ value: e, label: e })) || []))}
        </PF2MSearchSelectOutlined>
      </div>

      <div style={{ marginBottom: 20, width: 300, marginLeft: 10 }}>
        <span className={classes.label}>
          {`${translate('common:SubElement')}:`}
        </span>
        <PF2MSearchSelectOutlined
          value={selectedSubElement}
          placeholder={translate('common:Select')}
          onChange={e => setSelectedSubElement(e.target.value)}
        >
          {[{ value: 0, label: translate('common:Select') }]
            .concat(...resources
              .filter(r => (r.elevation === selectedElevation)
                && (r.element_id === selectedElement || !selectedElement))
              .map(e => ({ value: e.element_point_id, label: e.element_point_name })))}
        </PF2MSearchSelectOutlined>
      </div>
    </div>
  );

  return (
    <div className={classes.tabContainer}>
      {renderFilters()}
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        marginBottom: 10,
      }}
      >
        <span
          style={{ display: 'flex', alignItems: 'center' }}
          className={classes.label}
        >
          {`${translate('common:DependentSubElements')}:`}
        </span>
        <div style={{ display: 'flex' }}>
          {renderPoolElevationFilter()}
          {renderElementNameFilter()}
          {renderBulkSelectButtons()}
        </div>
      </div>
      <PF2MScrollbar className={classes.scrollbars} style={{ height: 'auto' }}>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)' }}>
          {renderElementPool(resources)}
        </div>
      </PF2MScrollbar>
      <Footer
        isDirty={isSubElementPrecedencesDirty}
        calculatePrecedences={() => setCalculatePrecedencesAlertOpen(true)}
        sendData={() => {
          dispatch(updateSubElementPrecedences(subElementPrecedences, selectedLineup));
          setAllSelected(false);
        }}
        discard={() => {
          dispatch(restoreSubElementPrecedences());
          setAllSelected(false);
        }}
      />
      <PF2MAlertDialog
        cancelText={translate('common:Cancel')}
        confirmText={translate('common:Yes')}
        description={translate('validation:PrecedenceCalculationWillOverrideCurrentConfiguration')}
        open={calculatePrecedencesAlertOpen}
        onClose={() => {
          setCalculatePrecedencesAlertOpen(false);
        }}
        onConfirm={() => {
          dispatch(calculateSubElementPrecedences(selectedLineup));
          setCalculatePrecedencesAlertOpen(false);
        }}
      />
    </div>
  );
};

SubElementsTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

SubElementsTab.defaultProps = {
};

export default withStyles(styles)(SubElementsTab);

const styles = {
  f2mLogo: {
    height: 40,
  },
  title: {
    fontFamily: 'Montserrat',
    color: '#FFF',
    '&:hover': {
      underline: 'none',
      color: '#FFF',
    },
  },
  secondaryTitle: {
    color: '#F2AA00',
  },
  separator: {
    marginLeft: 10,
    marginRight: 10,
  },
  active: {
    color: '#F2AA00',
  },
  disabled: {
    color: '#CDCDCD !important',
  },
  topBarContainer: {
    height: 48,
    backgroundColor: '#111110',
    backgroundSize: 'cover',
    display: 'flex',
    flexWrap: 'nowrap',
  },
  topBarModulesContainer: {
    flexGrow: 2,
    width: 600,
    position: 'relative',
  },
  topBarModulesScrollContent: {
    fontWeight: 600,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    'overflow-x': 'scroll',
  },
  topBarUserMenuContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export default styles;

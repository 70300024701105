import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import {
  addBeacon,
  getBeaconTypes,
  getBeacons,
  getEquipmentTypes,
  getEquipments,
  getEquipmentsGroups,
  resetBeacons,
  setBeaconsValues,
  updateBeacons,
} from '~/store/manager/actions';
import PF2MDataTable from '~/components/PF2MDataTable';

const CyclesAutomationTab = ({
  classes,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  const beacons = useSelector(state => state.manager.beacons);
  const beaconTypes = useSelector(state => state.manager.beaconTypes);
  const equipmentTypes = useSelector(state => state.manager.equipmentTypes);
  const equipmentsGroups = useSelector(state => state.manager.equipmentsGroups);
  const equipments = useSelector(state => state.manager.equipments);


  useEffect(() => {
    async function fetchData() {
      dispatch(getBeaconTypes());
      dispatch(getBeacons());
      dispatch(getEquipmentTypes());
      dispatch(getEquipmentsGroups());
      dispatch(getEquipments());
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch]);

  const columns = [
    {
      editable: 'always',
      title: translate('common:Parameter'),
      field: 'beacon_type_id',
      lookup: beaconTypes,
      customType: 'filterLookup',
    },
    {
      editable: 'always',
      title: translate('common:EquipmentType'),
      field: 'equip_type_id',
      customType: 'filterLookup',
      lookup: equipmentTypes.filter(a => [1, 2, 15].includes(a.id)),
    },
    {
      editable: 'always',
      title: translate('common:EquipmentGroup'),
      field: 'equip_group_id',
      customType: 'filterLookup',
      lookup: equipmentsGroups,
      lookupFilter: (opt, row) => String(opt.id_equipament) === String(row.equip_type_id),
    },
    {
      editable: 'always',
      title: translate('common:Equipment'),
      field: 'equip_id',
      customType: 'filterLookup',
      lookup: equipments,
      lookupFilter: (opt, row) => String(opt.id_group) === String(row.equip_group_id),
    },
    {
      editable: 'always',
      title: translate('common:Key'),
      field: 'uid',
    },
  ];

  return (
    <div className={classes.tabContainer}>
      <PF2MDataTable
        title={translate('common:EquipmentConfigurations')}
        options={{
          showTitle: true,
          search: true,
          selection: false,
          pageSize: 20,
          pageSizeOptions: [20, 50, 100],
          hideDelete: true,
          editableRowValidation: rowData => (rowData.id < 0 && rowData.update_status !== 'I'),
          maxBodyHeight: 'calc(100vh - 260px)',
        }}
        onAdd={newData => dispatch(addBeacon(newData))}
        onChange={(newData) => {
          newData.forEach((row) => {
            dispatch(setBeaconsValues(row));
          });
        }}
        onDiscard={() => dispatch(resetBeacons())}
        onApply={() => dispatch(updateBeacons(beacons))}
        columns={columns}
        data={beacons}
      />
    </div>
  );
};

CyclesAutomationTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

CyclesAutomationTab.defaultProps = {
};

export default (withStyles(styles)(CyclesAutomationTab));

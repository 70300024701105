import React, { useState, useEffect } from 'react';
import {
  withStyles, FormControl, FormLabel, FormGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import Footer from '~/pages/Manager/Footer';
import { useTranslation } from 'react-i18next';
import PF2MAddButton from '~/components/PF2MAddButton';
import Radio from '@material-ui/core/Radio';
import PF2MDatePickerOutlined from '~/components/PF2MDatePickerOutlined';
import PF2MTable from '~/components/PF2MTable';
import PF2MButton from '~/components/PF2MButton';
import classnames from 'classnames';
import {
  updateLineups,
  restoreLineups,
  addLineup,
  getLineups,
  setLineupValues,
  setSelectedLineup,
} from '~/store/lineup/actions';
import { newMomentDate } from '~/utils/moment';
import moment from 'moment';
import styles from '../styles';

const ScenariosTab = ({
  classes,
}) => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const lineups = useSelector(state => state.lineup.lineups);
  const isLineupsDirty = useSelector(state => state.lineup.isLineupsDirty);
  const selectedLineup = useSelector(state => state.lineup.selectedLineup);
  const [dateFilter, setDateFilter] = useState(newMomentDate());

  const renderRadioButton = (row) => {
    const parsedStartDate = moment(`${row.start_date} 00:00:00`, 'DD/MM/YYYY HH:mm:ss');
    const parsedEndDate = moment(`${row.end_date} 23:59:59`, 'DD/MM/YYYY HH:mm:ss');
    const now = newMomentDate();
    if (now.isBetween(parsedStartDate, parsedEndDate, 'second')) {
      return (
        <Radio
          checked={row.is_top_priority}
          disabled={row.id <= 0}
          onClick={() => dispatch(setLineupValues(row, 'is_top_priority', true))}
        />
      );
    }
    return null;
  };

  const columns = [
    {
      field: 'name',
      title: translate('common:Name'),
    },
    {
      field: 'start_date',
      title: translate('common:StartDate'),
      editable: false,
      datePicker: true,
    },
    {
      field: 'end_date',
      title: translate('common:EndDate'),
      editable: false,
      datePicker: true,
    },
    {
      field: 'creation_datetime',
      title: translate('common:Creation'),
      editable: false,
      datePicker: true,
    },
    {
      field: 'is_top_priority',
      title: translate('common:AppliedToAllocations'),
      renderCustomComponent: row => renderRadioButton(row),
    },
    {
      field: '',
      title: '',
      renderCustomComponent: row => (
        <PF2MButton
          color="primary"
          onClick={() => dispatch(setSelectedLineup(row.id))}
          disabled={row.id === selectedLineup.id || row.id <= 0}
        >
          {row.id === selectedLineup.id ? translate('common:Loaded') : translate('common:Configure')}
        </PF2MButton>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getLineups(dateFilter));
  }, [dispatch, dateFilter]);

  const renderFilter = () => (
    <div style={{
      marginTop: 30,
      display: 'flex',
      alignItems: 'baseline',
    }}
    >
      <p className={classes.labelTypeSelector}>
        {`${translate('common:FilterByDate')}:`}
      </p>
      <PF2MDatePickerOutlined
        helperText=""
        disableFuture={false}
        InputProps={{
          style: {
            minWidth: 150,
            height: 40,
          },
        }}
        value={dateFilter}
        onChange={(e) => {
          setDateFilter(e);
        }}
      />
    </div>
  );

  const renderHeader = () => (
    <Formik
      initialValues={{
        name: '',
        startDate: newMomentDate(),
        endDate: newMomentDate(),
        creationDate: newMomentDate(),
      }}
      validate={({ name, startDate, endDate }) => {
        const errors = {};
        if (!name) {
          errors.name = 'Required';
        }

        if (!startDate.isValid()) {
          errors.startDate = translate('validation:InitialDateRequired');
        }

        if (!endDate.isValid()) {
          errors.endDate = translate('validation:FinalDateRequired');
        }

        if (startDate.isValid() && endDate.isValid()) {
          const days = endDate.diff(startDate, 'days');
          if (days < 0) {
            errors.endDate = translate('validation:FinalDateSmallerThanInitialDate');
          }
        }
      }}
      onSubmit={async (values, { resetForm }) => {
        dispatch(addLineup(values));
        resetForm();
      }}
    >
      {({
        isSubmitting,
        errors,
        isValid,
      }) => (
        <Form style={{ display: 'flex', alignItems: 'center' }}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              {`${translate('common:StartDate')}:`}
            </FormLabel>
            <FormGroup>
              <Field
                name="startDate"
                render={({ field, form }) => (
                  <PF2MDatePickerOutlined
                    {...field}
                    helperText=""
                    disableFuture={false}
                    InputProps={{
                      style: {
                        minWidth: 150,
                        height: 40,
                      },
                    }}
                    onChange={(e) => {
                      form.setFieldValue('startDate', e);
                      form.setFieldValue('endDate', e);
                    }}
                  />
                )}
              />
            </FormGroup>
          </FormControl>
          <FormControl style={{ marginLeft: 10 }}>
            <FormLabel className={classes.formLabel}>
              {`${translate('common:EndDate')}:`}
            </FormLabel>
            <FormGroup>
              <Field
                name="endDate"
                render={({ field, form }) => (
                  <PF2MDatePickerOutlined
                    {...field}
                    helperText=""
                    disableFuture={false}
                    minDate={form.values.startDate}
                    InputProps={{
                      style: {
                        minWidth: 150,
                        height: 40,
                      },
                    }}
                    onChange={e => form.setFieldValue('endDate', e)}
                  />
                )}
              />
            </FormGroup>
          </FormControl>
          <FormControl style={{ flex: 1, marginLeft: 10 }}>
            <FormLabel className={classes.formLabel}>
              {translate('common:Name')}
            </FormLabel>
            <FormGroup>
              <Field
                type="text"
                name="name"
                className={classnames('form-control', errors.name ? classes.fieldError : classes.field)}
              />
            </FormGroup>
          </FormControl>
          <div style={{ marginTop: 15 }}>
            <PF2MAddButton disabled={isSubmitting || !isValid} />
          </div>
        </Form>
      )}
    </Formik>
  );

  return (
    <div className={classes.tabContainer}>
      {renderHeader()}
      {renderFilter()}
      <PF2MTable
        data={lineups}
        columns={columns}
        className={classes.grid}
      />
      <Footer
        isDirty={isLineupsDirty}
        sendData={() => dispatch(updateLineups(lineups, dateFilter))}
        discard={() => dispatch(restoreLineups())}
      />
    </div>
  );
};

ScenariosTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

ScenariosTab.defaultProps = {
};

export default withStyles(styles)(ScenariosTab);

import {
  GET_ERRORS,
  GET_GENERIC_ERROR,
  SET_UPLOAD_ERROR,
  CLEAN_MESSAGES,
  SET_UPLOAD_SUCCESS,
  SET_UPLOAD_SUCCESS_WARNING,
} from './types';

const INITIAL_STATE = {
  fileUploadError: '',
  fileUploadSuccess: '',
  errors: [],
  hasWarning: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ERRORS: {
      return {
        ...state,
        errors: [...action.payload],
      };
    }
    case CLEAN_MESSAGES: {
      return {
        ...INITIAL_STATE,
      };
    }
    case GET_GENERIC_ERROR: {
      return {
        ...state,
        errors: [{ code: 'EXCEPTION', message: action.payload }],
      };
    }
    case SET_UPLOAD_ERROR: {
      return {
        ...state,
        fileUploadError: action.payload,
      };
    }
    case SET_UPLOAD_SUCCESS: {
      return {
        ...state,
        fileUploadSuccess: action.payload,
      };
    }
    case SET_UPLOAD_SUCCESS_WARNING: {
      return {
        ...state,
        fileUploadSuccess: action.payload.success,
        fileUploadError: action.payload.error,
        hasWarning: true,
      };
    }
    default:
      return state;
  }
}

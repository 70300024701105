import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from '../styles';
import Header from '~/pages/Manager/Header';
import Footer from '~/pages/Manager/Footer';
import PF2MTable from '~/components/PF2MTable';
import { useTranslation } from 'react-i18next';

import {
  getEquipmentsGroups,
  getEquipmentsGroupsTemplate,
  exportEquipmentsGroups,
  importEquipmentsGroups,
} from '~/store/manager/actions';

const EquipmentsGroups = ({
  classes,
  equipmentsGroups,
  getEquipmentsGroups: getAll,
  getEquipmentsGroupsTemplate: getTemplateAction,
  exportEquipmentsGroups: exportAction,
  importEquipmentsGroups: importAction,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const { t: translate } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      await getAll();
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, getAll]);

  const columns = [
    { field: 'type_name', title: translate('common:Type') },
    { field: 'name', title: translate('common:Name') },
    { field: 'turn_group_name', title: translate('common:ShiftGroup') },
    {
      field: 'description',
      title: translate('common:Description'),
      width: '40%',
      wrap: true,
    },
    { field: 'mobile_workflow_number', title: translate('common:Workflow') },
    { field: 'velocity_limit', title: translate('common:VelocityLimit') },
    { field: 'active_name', title: 'STATUS' },
  ];

  return (
    <div className={classes.tabContainer}>
      <Header
        getTemplateAction={getTemplateAction}
        importAction={importAction}
        exportAction={exportAction}
      />
      <PF2MTable
        data={equipmentsGroups}
        columns={columns}
      />
      <Footer />
    </div>
  );
};

EquipmentsGroups.propTypes = {
  classes: PropTypes.object.isRequired,
  equipmentsGroups: PropTypes.array,
  getEquipmentsGroups: PropTypes.func,
  getEquipmentsGroupsTemplate: PropTypes.func,
  exportEquipmentsGroups: PropTypes.func,
  importEquipmentsGroups: PropTypes.func,
};

EquipmentsGroups.defaultProps = {
  equipmentsGroups: [],
  getEquipmentsGroups: () => {},
  getEquipmentsGroupsTemplate: () => {},
  exportEquipmentsGroups: () => {},
  importEquipmentsGroups: () => {},
};

const mapStateToProps = state => ({
  equipmentsGroups: state.manager.equipmentsGroups,
});

const mapDispatchToProps = {
  getEquipmentsGroups,
  getEquipmentsGroupsTemplate,
  exportEquipmentsGroups,
  importEquipmentsGroups,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EquipmentsGroups));

import React, { useState, useEffect } from 'react';
import {
  withStyles, FormControl, FormLabel, FormGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import Footer from '~/pages/Manager/Footer';
import PF2MTable from '~/components/PF2MTable';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import {
  getCodeTypes,
  getCodeGroups,
  getCodes,
  addCode,
  setCodeValue,
  updateCodes,
  resetCodes,
} from '~/store/maintenance/actions';
import PF2MAddButton from '~/components/PF2MAddButton';

function validateName(codes, codeGroup, name) {
  if (codes.some(c => c.id_code_group_maintenance === codeGroup
    && String(c.name).toLowerCase() === String(name).toLowerCase())) {
    return false;
  }
  return true;
}

function allowEdit(data) {
  return data.allow_edit;
}

const CodesTab = ({ classes }) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const isCodesDirty = useSelector(state => state.maintenance.isCodesDirty);
  const codeGroups = useSelector(state => state.maintenance.codeGroups);
  const codeTypes = useSelector(state => state.maintenance.codeTypes);
  const codes = useSelector(state => state.maintenance.codes);
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      dispatch(getCodes(true));
      dispatch(getCodeTypes());
      await dispatch(getCodeGroups());
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch]);

  const columns = [
    {
      field: 'code_type_maintenance_name', title: translate('common:TaskCodeType'),
    },
    {
      field: 'code_group_maintenance_name', title: translate('common:TaskCodeGroup'),
    },
    {
      field: 'name',
      title: translate('common:Task'),
      width: '100%',
      editFunc: allowEdit,
      editable: true,
      updateOnChange: true,
    },
    {
      field: 'is_logout',
      title: 'LOGOUT',
      editable: true,
    },
    {
      field: 'active',
      title: 'STATUS',
      editFunc: allowEdit,
      editable: true,
    },
  ];

  const renderHeader = () => (
    <Formik
      initialValues={{ name: '', codeGroup: 0, codeType: 1 }}
      validate={({ name, codeGroup }) => {
        const errors = {};
        if (!name) {
          errors.name = translate('validation:RequiredField');
        }

        if (codeGroup <= 0) {
          errors.codeGroup = translate('validation:RequiredField');
        }
        if (!validateName(codes, codeGroup, name)) {
          errors.name = translate('validation:AlreadyRegisteredName');
        }
        return errors;
      }}
      onSubmit={async ({ name, codeGroup }, { resetForm }) => {
        const selectedCodeGroup = codeGroups.find(t => t.id === codeGroup);
        const newItem = {
          name,
          id_code_group_maintenance: selectedCodeGroup.id,
          code_group_maintenance_name: selectedCodeGroup.name,
          id_code_type_maintenance: selectedCodeGroup.id_code_type_maintenance,
          code_type_maintenance_name: selectedCodeGroup.code_type_maintenance_name,
        };

        await dispatch(addCode(newItem));
        resetForm();
      }}
    >
      {({
        isSubmitting,
        errors,
        isValid,
        setFieldValue,
      }) => (
        <Form style={{ display: 'flex', minHeight: 75 }}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              {`${translate('common:TaskCodeType')}:`}
            </FormLabel>
            <FormGroup>
              <Field
                name="codeType"
                render={({ field }) => (
                  <PF2MSearchSelectOutlined
                    {...field}
                    placeholder={null}
                    className={classnames(classes.field)}
                    disabled
                  >
                    {[{ value: 0, label: translate('common:Select') }]
                      .concat(...codeTypes
                        .map(e => ({ value: e.id, label: e.name })))}
                  </PF2MSearchSelectOutlined>
                )}
              />
            </FormGroup>
          </FormControl>
          <FormControl style={{ marginLeft: 10 }}>
            <FormLabel className={classes.formLabel}>
              {`${translate('common:TaskCodeGroup')}:`}
            </FormLabel>
            <FormGroup>
              <Field
                name="codeGroup"
                render={({ field }) => (
                  <PF2MSearchSelectOutlined
                    {...field}
                    placeholder={null}
                    className={classnames(errors[field.name] ? classes.fieldError : classes.field)}
                    onChange={(e) => {
                      const type = codeGroups.find(c => c.id === e.target.value);
                      setFieldValue('codeType', type.id_code_type_maintenance);
                      setFieldValue('codeGroup', e.target.value);
                    }}
                  >
                    {[{ value: 0, label: translate('common:Select') }]
                      .concat(...codeGroups
                        .filter(e => e.allow_children)
                        .map(e => ({ value: e.id, label: e.name })))}
                  </PF2MSearchSelectOutlined>
                )}
              />
              <ErrorMessage
                name="codeGroup"
                component="span"
                className={classes.errorMessage}
              />
            </FormGroup>
          </FormControl>
          <FormControl className={classes.classGroupName}>
            <FormLabel className={classes.formLabel}>
              {`${translate('common:Task')}:`}
            </FormLabel>
            <FormGroup>
              <Field
                type="text"
                name="name"
                className={classnames('form-control', errors.name ? classes.fieldError : classes.field)}
              />
              <ErrorMessage
                name="name"
                component="span"
                className={classes.errorMessage}
              />
            </FormGroup>
          </FormControl>
          <div style={{ marginTop: 15 }}>
            <PF2MAddButton disabled={isSubmitting || !isValid} />
          </div>
        </Form>
      )}
    </Formik>
  );

  return (
    <div className={classes.tabContainer}>
      {renderHeader()}
      <PF2MTable
        data={codes}
        columns={columns}
        updateItem={(row, field, value) => dispatch(setCodeValue(row, field, value))}
        className={classes.table}
      />
      <Footer
        isDirty={isCodesDirty}
        discard={() => dispatch(resetCodes())}
        sendData={() => dispatch(updateCodes(codes))}
      />
    </div>
  );
};

CodesTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

CodesTab.defaultProps = {};

export default withStyles(styles)(CodesTab);

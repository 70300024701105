import React from 'react';
import { PropTypes } from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { CardHeader, CardIcon } from '~/components/Card';
import CreateIcon from '@material-ui/icons/Create';
import { Typography } from '@material-ui/core';

function ModalHeader({ closeModal, title }) {
  const titleComponent = title && (
    <Typography variant="h4" align="center" gutterBottom>
      {title}
    </Typography>
  );

  return (
    <>
      <CardHeader icon>
        <div>
          <CardIcon color="warning">
            <CreateIcon />
          </CardIcon>
          <CardIcon
            onClick={closeModal}
            style={{
              float: 'right',
              cursor: 'pointer',
              background: 'darkgray',
              borderRadius: 50,
              boxShadow: '10px 10px 50px gray',
            }}
          >
            <CloseIcon />
          </CardIcon>
        </div>
      </CardHeader>

      {titleComponent}
    </>
  );
}

ModalHeader.propTypes = {
  closeModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default ModalHeader;

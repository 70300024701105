import React, { useState } from 'react';
import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
} from '@material-ui/core';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import PropTypes from 'prop-types';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import PF2MSortButton from '~/components/PF2MSortButton';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import PF2MScrollbar from '~/components/PF2MScrollbar';
import {
  searchRefuelerList,
  deleteRefuelerItem,
  restoreRefuelerItem,
  setRefuelerValues,
  restoreOriginalRefueler,
  createRefuelerItem,
  updateRefuelerList,
  exportRefuelerReport,
} from '~/store/dailyparts/actions';
import { dailyPartsGridHeight } from '~/utils';
import { useTranslation } from 'react-i18next';
import Header from '../Header';
import styles from '../styles';
import ListRow from './ListRow';

const REFUELER_ID = 14;
const RefuelerList = ({ classes }) => {
  const { t: translate } = useTranslation();
  const titles = [
    { label: translate('common:StartDate'), field: 'start_time' },
    { label: translate('common:InitialTime'), field: 'start_time' },
    { label: translate('common:EndDate'), field: 'end_time' },
    { label: translate('common:EndTime'), field: 'end_time' },
    { label: translate('common:OperatorTeam'), field: 'operator_group_id' },
    { label: translate('common:RefuelOperatorRegistration'), field: 'operator_id' },
    { label: translate('common:RefuelEquipmentGroup'), field: 'equip_group_id' },
    { label: translate('common:RefuelEquipment'), field: 'equip_id' },
    { label: translate('common:RefueledEquipmentType'), field: 'equip_type_fueled' },
    { label: translate('common:RefueledEquipmentGroup'), field: 'equip_group_id_fueled' },
    { label: translate('common:RefueledEquipment'), field: 'equip_id_fueled' },
    { label: translate('common:RefueledOperatorGroup'), field: 'operator_group_id_fueled' },
    { label: translate('common:RefueledOperator'), field: 'operator_id_fueled' },
    { label: translate('common:RefuelInitialLatitude'), field: 'latitude_start' },
    { label: translate('common:RefuelInitialLongitude'), field: 'longitude_start' },
    { label: translate('common:RefuelInitialElement'), field: 'element_id_start' },
    { label: translate('common:RefuelInitialSubElement'), field: 'element_point_id_start' },
    { label: translate('common:RefuelFinalElement'), field: 'element_id_end' },
    { label: translate('common:RefuelFinalLatitude'), field: 'latitude_end' },
    { label: translate('common:RefuelFinalLongitude'), field: 'longitude_end' },
    { label: translate('common:RefuelFinalSubElement'), field: 'element_point_id_end' },
    { label: translate('common:RefueledHourmeterEquipment'), field: 'hourmeter_fueled' },
    { label: translate('common:RefueledEquipmentKilometer'), field: 'kilometer_fueled' },
    { label: translate('common:RefueledLiters'), field: 'liters_fueled' },
    { label: translate('common:StopOperationTime'), field: 'code_time' },
    { label: translate('common:RefuelTime'), field: 'refuel_time' },
    { label: translate('common:RefuelIntervalTime'), field: 'waiting_time' },
    { label: translate('common:RefuelIntervalDistance'), field: 'refuel_distance' },
    { label: translate('common:CycleType'), field: 'exception_type' },
  ];
  const refuelerList = useSelector(state => state.dailyParts.refuelerList);
  const filters = useSelector(state => state.dailyParts.filters);
  const page = useSelector(state => state.dailyParts.page);
  const limit = useSelector(state => state.dailyParts.limit);
  const { field, order } = useSelector(state => state.dailyParts.refuelerSortBy);
  const refuelerGroups = useSelector(state => state.manager.equipmentsGroups
    .filter(e => e.id_equipament === REFUELER_ID), shallowEqual);
  const refuelers = useSelector(state => state.manager.equipments
    .filter(e => e.id_equip === REFUELER_ID), shallowEqual);
  const operatorGroups = useSelector(state => state.manager.operatorsGroups
    .filter(o => o.name), shallowEqual);
  const operators = useSelector(state => state.manager.operators.filter(o => o.name), shallowEqual);
  const equipmentTypes = useSelector(state => state.manager.equipmentTypes);
  const equipmentsGroups = useSelector(state => state.manager.equipmentsGroups);
  const equipments = useSelector(state => state.manager.equipments);
  const exceptionTypes = useSelector(state => state.manager.exceptionTypes);
  const isRefuelerListDirty = useSelector(state => state.dailyParts.isRefuelerListDirty);

  const [sortBy, setSortBy] = useState({ field, order });
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const dispatch = useDispatch();

  const handleSort = (title) => {
    if (refuelerList.some(t => t.isDirty)) {
      setIsAlertOpen(true);
    } else {
      const newOrder = (sortBy.order === 'asc' && sortBy.field === title.field) ? 'desc' : 'asc';
      const newSortBy = { field: title.field, order: newOrder };
      setSortBy(newSortBy);
      dispatch(searchRefuelerList(filters, page, limit, newSortBy, true));
    }
  };

  const renderTableTitle = (title, key) => (
    <TableCell
      align="center"
      className={classes.tableCell}
      key={key}
      style={{
        position: 'sticky',
        top: 0,
        backgroundColor: 'white',
        zIndex: 1,
      }}
    >
      <PF2MSortButton
        label={title.label}
        field={title.field}
        sortField={sortBy.field}
        sortOrder={sortBy.order}
        onClick={() => handleSort(title)}
      />
    </TableCell>
  );

  const renderTableHeader = () => titles.map((t, i) => renderTableTitle(t, `${i}_${t}`));
  const renderRows = () => refuelerList
    .map(d => (
      <ListRow
        key={d.id}
        data={d}
        onDelete={data => dispatch(deleteRefuelerItem(data))}
        onRestore={data => dispatch(restoreRefuelerItem(data))}
        onEdit={(item, name, value) => dispatch(setRefuelerValues(item, name, value))}
        classes={classes}
        filters={filters}
        refuelerGroups={refuelerGroups}
        refuelers={refuelers}
        operatorGroups={operatorGroups}
        operators={operators}
        equipmentTypes={equipmentTypes}
        equipmentsGroups={equipmentsGroups}
        equipments={equipments}
        exceptionTypes={exceptionTypes}
      />
    ));

  const renderLoadMore = () => {
    if (refuelerList.length < limit) return null;
    if (refuelerList.filter(d => d.id > 0).length < 1) { return null; }
    return (
      <div style={{
        backgroundColor: '#F9F9F9',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 10,
        paddingBottom: 10,
      }}
      >
        <Button
          onClick={() => {
            dispatch(searchRefuelerList(filters, (page + 1), limit, sortBy, false));
          }}
        >
          <ArrowDownIcon />
          <Typography
            variant="h5"
          >

            {translate('common:SeeMore')}
          </Typography>
        </Button>
      </div>
    );
  };

  return (
    <>
      <Header
        updateData={(data, filter) => dispatch(updateRefuelerList(data, filter))}
        exportData={parameters => dispatch(exportRefuelerReport(parameters))}
        restoreData={() => dispatch(restoreOriginalRefueler())}
        createData={filter => dispatch(createRefuelerItem(filter))}
        data={refuelerList}
        filters={filters}
        isDirty={isRefuelerListDirty}
      />
      {refuelerList.length > 0 ? (
        <PF2MScrollbar style={{ backgroundColor: 'white', height: dailyPartsGridHeight }}>
          <>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {renderTableHeader()}
                </TableRow>
              </TableHead>
              <TableBody>
                {renderRows()}
              </TableBody>
            </Table>
            {renderLoadMore()}
            <PF2MAlertDialog
              hasCancel={false}
              confirmText={translate('common:Understood')}
              description={translate('validation:YouHaveUnsavedData')}
              open={isAlertOpen}
              onConfirm={() => setIsAlertOpen(false)}
            />
          </>
        </PF2MScrollbar>
      ) : (
        <div
          className="container"
          style={{
            backgroundColor: 'white',
            padding: 20,
          }}
        >
          <span>{translate('common:NoEntries')}</span>
        </div>
      )}
    </>
  );
};

RefuelerList.propTypes = {
  classes: PropTypes.object.isRequired,
};

RefuelerList.defaultProps = {};

export default withStyles(styles)(RefuelerList);

import React from 'react';
import {
  withStyles,
  FormControl,
  FormLabel,
  FormGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import PF2MAddButton from '~/components/PF2MAddButton';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';

function Header({
  classes,
  add,
  flowTypes,
}) {
  const { t: translate } = useTranslation();

  const INITIAL_VALUES = {
    name: '',
    description: '',
    flow_type_id: 0,
  };

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      validate={(values) => {
        const errors = {};

        if (values.name.length <= 0) {
          errors.name = 'Required';
        }

        if (values.description.length <= 0) {
          errors.description = 'Required';
        }

        if (values.flow_type_id <= 0) {
          errors.flow_type_id = 'Required';
        }

        return errors;
      }}
      onSubmit={async (values, { resetForm }) => {
        await add(values);
        resetForm();
      }}
    >
      {({
        isSubmitting,
        isValid,
        errors,
      }) => (
        <Form>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 10,
          }}
          >
            <FormControl>
              <FormLabel className={classes.formLabel}>
                {`${translate('common:Name')}:`}
              </FormLabel>
              <FormGroup>
                <Field
                  type="text"
                  name="name"
                  className={classnames('form-control', classes.textField, errors.name ? classes.fieldError : classes.field)}
                />
              </FormGroup>
            </FormControl>
            <FormControl style={{ flex: 1, marginLeft: 10, marginRight: 5 }}>
              <FormLabel className={classes.formLabel}>
                {`${translate('common:Description')}:`}
              </FormLabel>
              <FormGroup>
                <Field
                  type="text"
                  name="description"
                  className={classnames('form-control', classes.textField, errors.description ? classes.fieldError : classes.field)}
                />
              </FormGroup>
            </FormControl>
            <FormControl style={{ marginRight: 20 }}>
              <FormLabel className={classes.formLabel}>
                {`${translate('common:Flow')}:`}
              </FormLabel>
              <FormGroup>
                <Field
                  name="flow_type_id"
                  render={({ field, form }) => (
                    <PF2MSearchSelectOutlined
                      {...field}
                      onChange={(e) => {
                        form.setFieldValue('flow_type_id', e.target.value);
                      }}
                      className={classnames(
                        errors.flow_type_id ? classes.fieldError : classes.field,
                      )}
                    >
                      {[{ value: 0, label: translate('common:Select') }]
                        .concat(...flowTypes.map(e => ({ value: e.id, label: e.name })))}
                    </PF2MSearchSelectOutlined>
                  )}
                />
              </FormGroup>
            </FormControl>
            <PF2MAddButton
              disabled={isSubmitting || !isValid}
              style={{ marginTop: 20 }}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  add: PropTypes.func,
  flowTypes: PropTypes.array,
};

Header.defaultProps = {
  add: () => {},
  flowTypes: [],
};


export default withStyles(styles)(Header);

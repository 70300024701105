import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

function PF2MWarnFeatureFlag({
  featureName, flag, OffComponent, OnComponent, props,
}) {
  useEffect(() => {
    // console.warn to make it easy to find and remember feature flags tecnical debt to fix it
    // eslint-disable-next-line no-console
    console.warn(`Feature ${featureName} on flagging stage, remember to choose the oficial version`);
  }, [featureName]);

  return flag ? <OnComponent {...props} /> : <OffComponent {...props} />;
}

PF2MWarnFeatureFlag.propTypes = {
  featureName: PropTypes.string.isRequired,
  flag: PropTypes.bool.isRequired,
  OffComponent: PropTypes.elementType.isRequired,
  OnComponent: PropTypes.elementType.isRequired,
  props: PropTypes.object,
};

PF2MWarnFeatureFlag.defaultProps = {
  props: {},
};

export default PF2MWarnFeatureFlag;

import React, { useState, useEffect } from 'react';
import {
  withStyles,
  Checkbox,
  ListItemText,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import Footer from '~/pages/Manager/Footer';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import {
  getEquipmentTypes,
  getEquipmentsGroups,
  getChecklistMaps,
  getChecklist,
  updateChecklistMaps,
  resetChecklistMaps,
  setChecklistMapsValues,
} from '~/store/manager/actions';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import PF2MScrollbar from '~/components/PF2MScrollbar';

const ChecklistMapsTab = ({
  classes,
  checklistMaps,
  equipmentTypes,
  equipmentsGroups,
  checklist,
  getEquipmentTypes: loadEquipmentTypes,
  getEquipmentsGroups: loadEquipmentGroups,
  getChecklist: loadChecklist,
  getChecklistMaps: getAll,
  isChecklistMapsDirty,
  updateChecklistMaps: update,
  resetChecklistMaps: reset,
  setChecklistMapsValues: updateItem,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [equipmentType, setEquipmentType] = useState(0);
  const [equipmentGroup, setEquipmentGroup] = useState(0);
  const [groups, setGroups] = useState([]);
  const [checklistItens, setChecklistItens] = useState([]);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const { t: translate } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      getAll();
      await loadEquipmentTypes();
      await loadEquipmentGroups();
      await loadChecklist();
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, getAll, loadEquipmentTypes, loadEquipmentGroups, loadChecklist]);

  useEffect(() => {
    if (!isChecklistMapsDirty) { setEquipmentType(0); }
  }, [isChecklistMapsDirty]);

  useEffect(() => {
    if (equipmentType > 0 && equipmentsGroups.length > 0) {
      const egroups = equipmentsGroups.filter(e => e.id_equipament === equipmentType);
      setGroups(egroups);
      setEquipmentGroup(0);
    } else if (equipmentType <= 0) {
      setGroups([]);
      setEquipmentGroup(0);
    }
  }, [equipmentType, equipmentsGroups]);

  useEffect(() => {
    function refreshItens(items = []) {
      return checklist
        .map(s => (
          {
            id: s.id,
            name: s.name || translate('common:NoName'),
            checked: items.some(o => s.id === o) || false,
          }
        ));
    }

    if (equipmentType && equipmentGroup) {
      const selected = checklistMaps
        .find(m => m.eq_type === equipmentType && m.eq_group === equipmentGroup);
      setChecklistItens(refreshItens(selected ? selected.checklist : []));
    } else {
      setChecklistItens(refreshItens([]));
    }
  }, [equipmentType, equipmentGroup, checklistMaps, checklist, translate]);

  const handleEquipmentType = (e) => {
    const selectedMap = checklistMaps
      .find(m => m.eq_type === equipmentType && m.eq_group === equipmentGroup);
    if (e.target.value !== equipmentType && selectedMap && selectedMap.isDirty) {
      setIsAlertOpen(true);
    } else {
      setEquipmentType(e.target.value);
    }
  };

  const handleEquipmentGroup = (e) => {
    const selectedMap = checklistMaps
      .find(m => m.eq_type === equipmentType && m.eq_group === equipmentGroup);
    if (e.target.value !== equipmentType && selectedMap && selectedMap.isDirty) {
      setIsAlertOpen(true);
    } else {
      setEquipmentGroup(e.target.value);
    }
  };

  const handleCheck = (field, id, checked) => {
    updateItem(equipmentType, equipmentGroup, field, { id, checked });
  };

  const renderElements = (field, items) => items.map(s => (
    <div key={s.id} style={{ display: 'flex', alignItems: 'center' }}>
      <Checkbox
        checked={s.checked}
        color="primary"
        name={s.id.toString()}
        onChange={e => handleCheck(field, s.id, e.target.checked)}
      />
      <ListItemText secondary={s.name} style={{ padding: 0 }} />
    </div>
  ));

  const renderEquipmentTypes = () => [
    { value: 0, label: translate('common:Select') },
    ...equipmentTypes.map(e => ({ value: e.id, label: e.name })),
  ];

  const renderEquipmentGroups = () => [
    { value: 0, label: translate('common:Select') },
    ...groups.map(e => ({ value: e.id, label: e.name })),
  ];

  return (
    <div className={classes.tabContainer}>
      <div style={{ display: 'flex' }}>
        <div className={classes.containerTypeSelector}>
          <p className={classes.labelTypeSelector}>

            {translate('common:FilterByEquipmentType').toUpperCase()}
            :
          </p>
          <PF2MSearchSelectOutlined
            value={equipmentType}
            onChange={handleEquipmentType}
            className={classes.typeSelector}
          >
            {renderEquipmentTypes()}
          </PF2MSearchSelectOutlined>
        </div>
        <div className={classes.containerTypeSelector}>
          <p className={classes.labelTypeSelector}>

            {translate('common:FilterByEquipGroup').toUpperCase()}
            :
          </p>
          <PF2MSearchSelectOutlined
            value={equipmentGroup}
            onChange={handleEquipmentGroup}
            className={classes.typeSelector}
          >
            {renderEquipmentGroups()}
          </PF2MSearchSelectOutlined>
        </div>
      </div>
      <div className={classes.itens}>
        <span className={classes.label}>

          {`${translate('common:ChecklistItems')}:`}
        </span>
        <PF2MScrollbar className={classes.scrollbars} style={{ marginBottom: 5 }}>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
            {equipmentGroup > 0 && renderElements('item', checklistItens)}
          </div>
        </PF2MScrollbar>
      </div>
      <div style={{ marginTop: 30 }}>
        <Footer
          isDirty={isChecklistMapsDirty}
          discard={() => reset()}
          sendData={() => update(checklistMaps)}
          style={{ marginTop: 10 }}
        />
      </div>
      <PF2MAlertDialog
        hasCancel={false}
        confirmText={translate('common:Understood')}
        description={translate('validation:YouHaveUnsavedData')}
        open={isAlertOpen}
        onConfirm={() => setIsAlertOpen(false)}
      />
    </div>
  );
};

ChecklistMapsTab.propTypes = {
  classes: PropTypes.object.isRequired,
  checklistMaps: PropTypes.array,
  equipmentTypes: PropTypes.array,
  equipmentsGroups: PropTypes.array,
  checklist: PropTypes.array,
  getEquipmentTypes: PropTypes.func,
  getEquipmentsGroups: PropTypes.func,
  getChecklist: PropTypes.func,
  getChecklistMaps: PropTypes.func,
  isChecklistMapsDirty: PropTypes.bool,
  updateChecklistMaps: PropTypes.func,
  resetChecklistMaps: PropTypes.func,
  setChecklistMapsValues: PropTypes.func,
};

ChecklistMapsTab.defaultProps = {
  checklistMaps: [],
  equipmentTypes: [],
  equipmentsGroups: [],
  checklist: [],
  getEquipmentTypes: () => { },
  getEquipmentsGroups: () => { },
  getChecklist: () => { },
  getChecklistMaps: () => { },
  isChecklistMapsDirty: false,
  updateChecklistMaps: () => { },
  resetChecklistMaps: () => { },
  setChecklistMapsValues: () => { },
};

const mapStateToProps = state => ({
  equipmentTypes: state.manager.equipmentTypes,
  equipmentsGroups: state.manager.equipmentsGroups,
  checklist: state.manager.checklist,
  checklistMaps: state.manager.checklistMaps,
  isChecklistMapsDirty: state.manager.isChecklistMapsDirty,
});

const mapDispatchToProps = {
  getEquipmentTypes,
  getEquipmentsGroups,
  getChecklistMaps,
  getChecklist,
  updateChecklistMaps,
  resetChecklistMaps,
  setChecklistMapsValues,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ChecklistMapsTab));

import React from 'react';
import PropTypes from 'prop-types';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Done from '@material-ui/icons/Done';
import { withStyles } from '@material-ui/core';
import PF2MButton from '~/components/PF2MButton';
import { useTranslation } from 'react-i18next';
import styles from './styles';

const Footer = ({
  classes,
  isDirty,
  discard,
  sendData,
  setCurrentPage,
  currentPage,
  itemsPerPage,
  size,

}) => {
  const { t: translate } = useTranslation();

  const handleChangePage = (page) => {
    if (page >= 0 && page <= (size / itemsPerPage)) {
      setCurrentPage(page);
    }
  };
  const pageCount = Math.ceil(size / itemsPerPage) > 0 ? Math.ceil(size / itemsPerPage) : 1;

  return (
    <div style={{
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
    }}
    >

      <div style={{
        display: 'flex',
        justifyContent: 'flex-end',
        width: '50%',
      }}
      >
        {setCurrentPage && (
          <>
            <PF2MButton
              className={classes.pageButton}
              onClick={() => handleChangePage(currentPage - 1)}
            >
              <KeyboardArrowLeft className={classes.icon} />
            </PF2MButton>
            <p className={classes.currentPageLabel}>
              {`${translate('common:Page')} ${currentPage + 1} / ${pageCount}`}
            </p>
            <PF2MButton
              className={classes.pageButton}
              onClick={() => handleChangePage(currentPage + 1)}
            >
              <KeyboardArrowRight className={classes.icon} />
            </PF2MButton>
          </>
        )}
      </div>

      <div style={{
        display: 'flex',
        justifyContent: 'flex-end',
        width: '50%',
      }}
      >
        <PF2MButton
          color="primary"
          onClick={() => discard && discard()}
          disabled={!isDirty}
        >
          <DeleteOutline className={classes.icon} />
          {translate('common:DiscardChanges').toUpperCase()}
        </PF2MButton>
        <PF2MButton
          onClick={() => sendData && sendData()}
          disabled={!isDirty}
        >
          <Done className={classes.icon} />
          {translate('common:End').toUpperCase()}
        </PF2MButton>
      </div>
    </div>
  );
};

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  isDirty: PropTypes.bool,
  discard: PropTypes.func,
  sendData: PropTypes.func,
  setCurrentPage: PropTypes.func,
  currentPage: PropTypes.number,
  itemsPerPage: PropTypes.number,
  size: PropTypes.number,


};

Footer.defaultProps = {
  isDirty: false,
  discard: null,
  sendData: null,
  setCurrentPage: null,
  currentPage: 0,
  itemsPerPage: 0,
  size: 0,

};

export default withStyles(styles)(Footer);

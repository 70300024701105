import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core';


const PF2MTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    border: '1px solid #dadde9',
    fontSize: '15px',
  },
}))(Tooltip);

export default PF2MTooltip;

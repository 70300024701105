import React, { useState, useEffect } from 'react';
import {
  withStyles,
  Checkbox,
  ListItemText,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Footer from '~/pages/Manager/Footer';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import {
  getMaterials,
  getSubElements,
  getMaterialElements,
  updateMaterialElements,
  resetMaterialElements,
  setMaterialElementsValues,
  getElements,
} from '~/store/manager/actions';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import PF2MScrollbar from '~/components/PF2MScrollbar';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';

const MaterialsTab = ({
  classes,
  materialElements,
  materials,
  subElements,
  getMaterials: loadMaterials,
  getSubElements: loadSubElements,
  getElements: loadElements,
  getMaterialElements: getAll,
  isMaterialElementsDirty,
  updateMaterialElements: update,
  resetMaterialElements: reset,
  setMaterialElementsValues: updateItem,
  elements,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [material, setMaterial] = useState(0);
  const [origins, setOrigins] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [originElements, setOriginElements] = useState([]);
  const [destinationElements, setDestinationElements] = useState([]);
  const { t: translate } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      getAll();
      loadMaterials();
      loadElements();
      await loadSubElements();
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, getAll, loadMaterials, loadSubElements, loadElements]);

  useEffect(() => {
    if (!isMaterialElementsDirty) {
      setMaterial(0);
      setOriginElements([]);
      setDestinationElements([]);
    }
  }, [isMaterialElementsDirty]);

  useEffect(() => {
    function refreshSubElements(items = []) {
      return subElements.filter(i => i.active === 1)
        .map(s => (
          {
            id: s.id,
            name: s.name,
            checked: items.some(o => s.id === o) || false,
            id_element: s.id_element,
          }
        ));
    }

    if (material && subElements) {
      const selected = materialElements.find(m => m.id_material === material);
      setOrigins(refreshSubElements(selected ? selected.origins : []));
      setDestinations(refreshSubElements(selected ? selected.destinations : []));
    }
  }, [material, subElements, materialElements]);

  const handleCheck = (materialId, field, isChecked, name) => {
    updateItem(materialId, field, { id: Number(name), checked: isChecked });
  };

  const renderElements = (field, items, filterElements = []) => items
    .filter(i => filterElements.length <= 0 || filterElements.includes(i.id_element))
    .map(s => (
      <div key={s.id} style={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox
          checked={s.checked}
          color="primary"
          name={s.id.toString()}
          onChange={e => handleCheck(material, field, e.target.checked, e.target.name)}
        />
        <ListItemText secondary={s.name} style={{ padding: 0 }} />
      </div>
    ));

  const renderMaterials = () => [
    { value: 0, label: translate('common:Select') },
    ...materials.map(e => ({ value: e.id, label: e.name })),
  ];

  const handleChangeMaterial = (e) => {
    const selectedMaterial = materialElements.find(m => m.id_material === material);
    if (e.target.value !== material && selectedMaterial && selectedMaterial.isDirty) {
      setIsAlertOpen(true);
    } else {
      setMaterial(e.target.value);
    }
  };

  return (
    <div className={classes.tabContainer}>
      <div className={classes.containerTypeSelector}>
        <p className={classes.labelTypeSelector}>

          {translate('common:FilterByMaterial')}
        </p>
        <PF2MSearchSelectOutlined
          value={material}
          onChange={handleChangeMaterial}
          className={classes.typeSelector}
        >
          {renderMaterials()}
        </PF2MSearchSelectOutlined>
      </div>
      <div style={{
        display: 'flex', justifyContent: 'space-between',
      }}
      >
        <div style={{ width: '49%' }}>
          <div>
            <span className={classes.labelTypeSelector}>

              {translate('common:FilterOriginElements')}
            </span>
            <PF2MSearchSelectOutlined
              value={originElements}
              onChange={(e) => {
                setOriginElements([...e.target.value.map(r => r.value)]);
              }}
              multiple
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
              placeholder={translate('common:Select')}
            >
              {elements.map(e => ({ value: e.id, label: e.name }))}
            </PF2MSearchSelectOutlined>
          </div>
          <span className={classes.label}>

            {`${translate('common:OriginPlural')}:`}
          </span>
          <PF2MScrollbar className={classes.scrollbars} style={{ height: 'auto' }}>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}>
              {material > 0 && renderElements('origin', origins, originElements)}
            </div>
          </PF2MScrollbar>
        </div>
        <div style={{ width: '49%' }}>
          <div>
            <span className={classes.labelTypeSelector}>

              {translate('common:FilterDestinationElements')}
            </span>
            <PF2MSearchSelectOutlined
              value={destinationElements}
              onChange={(e) => {
                setDestinationElements([...e.target.value.map(r => r.value)]);
              }}
              multiple
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
              placeholder={translate('common:Select')}
            >
              {elements.map(e => ({ value: e.id, label: e.name }))}
            </PF2MSearchSelectOutlined>
          </div>
          <span className={classes.label}>

            {`${translate('common:DestinationPlural')}:`}
          </span>
          <PF2MScrollbar className={classes.scrollbars} style={{ height: 'auto' }}>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}>
              {material > 0 && renderElements('destination', destinations, destinationElements)}
            </div>
          </PF2MScrollbar>
        </div>
      </div>
      <div style={{ marginTop: 20 }}>
        <Footer
          isDirty={isMaterialElementsDirty}
          discard={() => reset()}
          sendData={() => update(materialElements)}
          style={{ marginTop: 10 }}
        />
      </div>
      <PF2MAlertDialog
        hasCancel={false}
        confirmText={translate('common:Understood')}
        description={translate('validation:YouHaveUnsavedData')}
        open={isAlertOpen}
        onConfirm={() => setIsAlertOpen(false)}
      />
    </div>
  );
};

MaterialsTab.propTypes = {
  classes: PropTypes.object.isRequired,
  materialElements: PropTypes.array,
  materials: PropTypes.array,
  subElements: PropTypes.array,
  getMaterialElements: PropTypes.func,
  getMaterials: PropTypes.func,
  getSubElements: PropTypes.func,
  isMaterialElementsDirty: PropTypes.bool,
  updateMaterialElements: PropTypes.func,
  resetMaterialElements: PropTypes.func,
  setMaterialElementsValues: PropTypes.func,
  getElements: PropTypes.func,
  elements: PropTypes.array,
};

MaterialsTab.defaultProps = {
  materialElements: [],
  materials: [],
  subElements: [],
  getMaterialElements: null,
  getMaterials: null,
  getSubElements: null,
  isMaterialElementsDirty: false,
  updateMaterialElements: null,
  resetMaterialElements: null,
  setMaterialElementsValues: null,
  getElements: null,
  elements: [],
};

const mapStateToProps = state => ({
  materialElements: state.manager.materialElements,
  materials: state.manager.materials,
  subElements: state.manager.subElements,
  isMaterialElementsDirty: state.manager.isMaterialElementsDirty,
  elements: state.manager.elements,
});

const mapDispatchToProps = {
  getMaterialElements,
  getMaterials,
  getSubElements,
  updateMaterialElements,
  resetMaterialElements,
  setMaterialElementsValues,
  getElements,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MaterialsTab));

import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import Header from '~/pages/Manager/Header';
import Footer from '~/pages/Manager/Footer';
import PF2MTable from '~/components/PF2MTable';

import {
  getOperatorsGroupsTemplate,
  exportOperatorsGroups,
  importOperatorsGroups,
  getOperatorsGroups,
} from '~/store/manager/actions';

const GroupsTab = ({
  classes,
  operatorsGroups,
  getOperatorsGroupsTemplate: getTemplateAction,
  exportOperatorsGroups: exportAction,
  importOperatorsGroups: importAction,
  getOperatorsGroups: getAll,
}) => {
  const [groupsLoaded, setGroupsLoaded] = useState(false);
  const { t: translate } = useTranslation();

  useEffect(() => {
    async function fetchOperators() {
      await getAll();
    }
    if (!groupsLoaded) {
      fetchOperators();
      setGroupsLoaded(true);
    }
  }, [groupsLoaded, getAll]);

  const columns = [
    {
      field: 'name', title: translate('common:Name'),
    },
    {
      field: 'description', title: translate('common:Description'), width: '100%',
    },
    {
      field: 'active_name', title: 'STATUS',
    },
  ];

  return (
    <div className={classes.tabContainer}>
      <Header
        getTemplateAction={getTemplateAction}
        importAction={importAction}
        exportAction={exportAction}
      />
      <PF2MTable
        data={operatorsGroups}
        columns={columns}
      />
      <Footer />
    </div>
  );
};

GroupsTab.propTypes = {
  classes: PropTypes.object.isRequired,
  operatorsGroups: PropTypes.array,
  getOperatorsGroupsTemplate: PropTypes.func,
  exportOperatorsGroups: PropTypes.func,
  importOperatorsGroups: PropTypes.func,
  getOperatorsGroups: PropTypes.func,
};

GroupsTab.defaultProps = {
  operatorsGroups: [],
  getOperatorsGroupsTemplate: () => {},
  exportOperatorsGroups: () => {},
  importOperatorsGroups: () => {},
  getOperatorsGroups: () => {},
};

const mapStateToProps = state => ({
  operatorsGroups: state.manager.operatorsGroups,
});

const mapDispatchToProps = {
  getOperatorsGroupsTemplate,
  exportOperatorsGroups,
  importOperatorsGroups,
  getOperatorsGroups,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GroupsTab));

import React, { useEffect, useMemo } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Card, CardBody, CardHeader, CardIcon,
} from '~/components/Card';
import {
  Fab, Typography, withStyles, CircularProgress,
} from '@material-ui/core';
import PF2MDataTable from '~/components/PF2MDataTable';
import { useDispatch, useSelector } from 'react-redux';

import { Clear, Done, LocalShipping } from '@material-ui/icons';
import styles from '../../styles';
import { getCodesRawRulesMatch, updateCodeMigrationRulesDailyParts } from '~/store/manager/actions';

const CodeMigrationsModal = ({ handleClose, classes }) => {
  const { t: translate } = useTranslation();

  const codesRawRulesMatchState = useSelector(state => state.manager.codesRawRulesMatch);
  const loadingMigrationCodeMatch = useSelector(state => state.manager.loadingMigrationCodeMatch);
  const filters = useSelector(state => state.dailyParts.filters);
  const equipmentGroups = useSelector(state => state.manager.equipmentsGroups);
  const equipments = useSelector(state => state.manager.equipments);
  const codes = useSelector(state => state.manager.codes);

  const codesRawRulesMatch = useMemo(
    () => codesRawRulesMatchState
      .filter(c => c.equip_type_id === filters.equipmentType)
      .map(c => ({
        ...c,
        start_timestamp: c.start_timestamp * 1000,
      })),
    [codesRawRulesMatchState, filters],
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCodesRawRulesMatch());
  }, [dispatch]);

  const renderAcceptOrDenyCell = rowData => (
    <div>
      <Fab
        size="small"
        color="secondary"
        aria-label="Check"
        className={classes.fab}
        onClick={() => {
          dispatch(updateCodeMigrationRulesDailyParts([{
            ...rowData,
            analisys_status: true,
            update_status: 'U',
          }]));
        }}
      >
        <Done />
      </Fab>
      <Fab
        size="small"
        color="secondary"
        aria-label="Deny"
        className={classes.fab}
        onClick={() => {
          dispatch(updateCodeMigrationRulesDailyParts([{
            ...rowData,
            analisys_status: false,
            update_status: 'U',
          }]));
        }}
      >
        <Clear />
      </Fab>
    </div>
  );

  const columns = [
    {
      field: 'accept',
      title: translate('common:Accept'),
      render: rowData => (
        !loadingMigrationCodeMatch
          ? renderAcceptOrDenyCell(rowData)
          : <CircularProgress color="secondary" size={24} />
      ),
    },
    {
      customType: 'filterLookup',
      title: translate('common:EquipmentGroupMatch'),
      field: 'equip_group_id',
      lookup: equipmentGroups?.map(e => ({ ...e, name: e.name })),
    },
    {
      title: translate('common:EquipmentMach'),
      customType: 'filterLookup',
      field: 'equip_id',
      lookup: equipments,
    },
    {
      editable: 'never',
      title: translate('common:DateMatch'),
      field: 'start_timestamp',
      customType: 'dateTimeByTimestamp',
    },
    {
      editable: 'never',
      title: translate('common:OriginCodeMatch'),
      field: 'origin_codes_id',
      customType: 'filterLookup',
      lookupKey: 'id_pk',
      lookup: codes,
    },
    {
      editable: 'never',
      title: translate('common:DurationMatch'),
      field: 'duration',
      render: rowData => `${(Math.abs((rowData.old_end_timestamp - (rowData.start_timestamp / 1000))) / 60).toFixed(2)} ${translate('common:Min')}`,
    },
    {
      editable: 'never',
      title: translate('common:NewCurrentDuration'),
      field: 'duration',
      render: rowData => `${((rowData.time_to_new_code) / 60).toFixed(2)} ${translate('common:Min')}`,
    },
    {
      editable: 'never',
      title: translate('common:DestinationCodeMatch'),
      field: 'destination_codes_id',
      customType: 'filterLookup',
      lookupKey: 'id_pk',
      lookup: codes,
    },
    {
      editable: 'never',
      title: translate('common:NewCodeDuration'),
      field: 'duration',
      render: rowData => `${(Math.abs((rowData.old_end_timestamp - (rowData.start_timestamp / 1000)) - rowData.time_to_new_code) / 60).toFixed(2)} ${translate('common:Min')}`,
    },
  ];

  return (
    <div>
      <Card>
        <CardHeader icon>
          <CardIcon color="warning">
            <LocalShipping />
          </CardIcon>
          <CardIcon
            onClick={handleClose}
            style={{
              float: 'right',
              cursor: 'pointer',
              background: 'darkgray',
              borderRadius: 50,
              boxShadow: '10px 10px 50px gray',
            }}
          >
            <CloseIcon />
          </CardIcon>
        </CardHeader>
        <CardBody>
          <Typography
            variant="h4"
            align="center"
            gutterBottom
          >
            {translate('common:CodesMigrations')}
          </Typography>
          <PF2MDataTable
            defaultEditable={false}
            options={{
              search: false,
              selection: false,
              hideDelete: true,
              hideCommit: true,
              hideReload: true,
              hideAdd: true,
            }}
            actions={[]}
            columns={columns}
            data={codesRawRulesMatch}
          />
        </CardBody>
      </Card>
    </div>
  );
};

CodeMigrationsModal.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func,
};

CodeMigrationsModal.defaultProps = {
  handleClose: null,
};

export default withStyles(styles)(CodeMigrationsModal);

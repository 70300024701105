const style = {
  modal: {
    width: '80vw',
    height: '80vh',
    overflow: 'visible',
    display: 'flex',
    flexDirection: 'column',
  },
  modalBody: {
    overflow: 'scroll',
  },
  modalImage: {
    width: '80%',
    maxWidth: '50vw',
    margin: 'auto',
    display: 'block',
  },
};

export default style;

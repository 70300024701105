import React from 'react';
import PropTypes from 'prop-types';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Done from '@material-ui/icons/Done';
import { withStyles } from '@material-ui/core';
import PF2MButton from '~/components/PF2MButton';
import { useTranslation } from 'react-i18next';
import styles from './styles';

const Footer = ({
  classes,
  isDirty,
  discard,
  sendData,
}) => {
  const { t: translate } = useTranslation();
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'flex-end',
    }}
    >
      <PF2MButton
        color="primary"
        onClick={() => discard && discard()}
        disabled={!isDirty}
      >
        <DeleteOutline className={classes.icon} />
        {translate('common:DiscardChanges')}
      </PF2MButton>
      <PF2MButton
        onClick={() => sendData && sendData()}
        disabled={!isDirty}
      >
        <Done className={classes.icon} />
        {translate('common:End')}
      </PF2MButton>
    </div>
  );
};

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  isDirty: PropTypes.bool,
  discard: PropTypes.func,
  sendData: PropTypes.func,
};

Footer.defaultProps = {
  isDirty: false,
  discard: null,
  sendData: null,
};

export default withStyles(styles)(Footer);

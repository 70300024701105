import React, { useState, useEffect } from 'react';
import {
  withStyles,
  Checkbox,
  ListItemText,
  FormLabel,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import PF2MScrollbar from '~/components/PF2MScrollbar';
import Footer from '~/pages/Manager/Footer';
import { useTranslation } from 'react-i18next';
import styles from '../styles';

import {
  getLineupElements,
  getElementPrecedences,
  toggleElementPrecedence,
  deselectAllElementPrecedences,
  selectAllElementPrecedences,
  updateElementPrecedences,
  restoreElementPrecedences,
} from '~/store/lineup/actions';
import PF2MOutlinedInput from '~/components/PF2MOutlinedInput';

const ElementsTab = ({
  classes,
}) => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const selectedLineup = useSelector(state => state.lineup.selectedLineup);
  const lineupElements = useSelector(state => state.lineup.lineupElements);
  const elementPrecedences = useSelector(state => state.lineup.elementPrecedences);
  const isElementPrecedencesDirty = useSelector(state => state.lineup.isElementPrecedencesDirty);
  const [selectedElement, setSelectedElement] = useState(0);
  const [allSelected, setAllSelected] = useState(false);
  const [elementNameFilter, setElementNameFilter] = useState('');

  useEffect(() => {
    if (selectedLineup) {
      dispatch(getLineupElements(selectedLineup));
      dispatch(getElementPrecedences(selectedLineup));
    }
  }, [selectedLineup, dispatch]);

  const renderElementPool = items => items
    .filter(r => r.element_id !== selectedElement
      && r.element_name.toLowerCase().includes(elementNameFilter.toLowerCase()))
    .map(s => (
      <div key={s.element_id} style={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox
          checked={elementPrecedences
            .filter(r => r.father_element_id === selectedElement && r.update_status !== 'D')
            .map(r => r.child_element_id)
            .includes(s.element_id)}
          color="primary"
          disabled={!selectedElement}
          name={s.element_id.toString()}
          onChange={() => {
            dispatch(toggleElementPrecedence(selectedElement, s.element_id, selectedLineup.id));
            if (allSelected) {
              setAllSelected(false);
            }
          }}
        />
        <ListItemText secondary={s.element_name} style={{ padding: 0 }} />
      </div>
    ));

  const renderBulkSelectButtons = () => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Checkbox
        checked={allSelected}
        color="primary"
        name="all"
        disabled={!selectedElement}
        onChange={() => {
          if (allSelected) {
            setAllSelected(false);
            dispatch(deselectAllElementPrecedences(
              selectedElement,
              lineupElements
                .filter(r => r.element_name.toLowerCase()
                  .includes(elementNameFilter.toLowerCase())),
              selectedLineup,
            ));
            return;
          }
          setAllSelected(true);
          dispatch(selectAllElementPrecedences(
            selectedElement,
            lineupElements
              .filter(r => r.element_name.toLowerCase().includes(elementNameFilter.toLowerCase())),
            selectedLineup,
          ));
        }}
      />
      <FormLabel style={{ padding: 0, margin: 0 }}>
        {translate('common:SelectAll')}
      </FormLabel>
    </div>
  );

  const renderElementNameFilter = () => (
    <div>
      <PF2MOutlinedInput
        value={elementNameFilter}
        className={classes.textField}
        style={{ width: 300, maxHeight: 40, marginRight: 10 }}
        type={'text'}
        disabled={!selectedElement}
        placeholder={translate('common:FilterByName')}
        onChange={e => setElementNameFilter(e.target.value)}
      />
    </div>
  );

  return (
    <div className={classes.tabContainer}>
      <div style={{ marginBottom: 20, width: 300 }}>
        <span className={classes.label}>
          {`${translate('common:Element')}:`}
        </span>
        <PF2MSearchSelectOutlined
          value={selectedElement}
          placeholder={translate('common:Select')}
          onChange={e => setSelectedElement(e.target.value)}
        >
          {[{ value: 0, label: translate('common:Select') }]
            .concat(...lineupElements
              .map(e => ({ value: e.element_id, label: e.element_name })))}
        </PF2MSearchSelectOutlined>
      </div>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        marginBottom: 10,
      }}
      >
        <span
          style={{ display: 'flex', alignItems: 'center' }}
          className={classes.label}
        >
          {`${translate('common:DependentElements')}:`}
        </span>
        <div style={{ display: 'flex' }}>
          {renderElementNameFilter()}
          {renderBulkSelectButtons()}
        </div>
      </div>
      <PF2MScrollbar className={classes.scrollbars} style={{ height: 'auto' }}>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)' }}>
          {renderElementPool(lineupElements)}
        </div>
      </PF2MScrollbar>
      <Footer
        isDirty={isElementPrecedencesDirty}
        sendData={() => {
          dispatch(updateElementPrecedences(elementPrecedences, selectedLineup));
          setAllSelected(false);
        }}
        discard={() => {
          dispatch(restoreElementPrecedences());
          setAllSelected(false);
        }}
      />
    </div>
  );
};

ElementsTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

ElementsTab.defaultProps = {
};

export default withStyles(styles)(ElementsTab);

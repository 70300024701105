import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Header from '~/pages/Maintenance/Header';
import PF2MTable from '~/components/PF2MTable';
import {
  getSystems,
  getFailureModeEquipmentGroupMaintenanceItemsTemplate,
  importFailureModeEquipmentGroupMaintenanceItems,
  exportFailureModeEquipmentGroupMaintenanceItems,
  getFailureModes,
  getSystemItems,
  getFailureModesSystemItems,
  getFailureModeEquipmentGroupMaintenanceItems,
} from '~/store/maintenance/actions';
import { getEquipmentsGroups, getEquipmentTypes } from '~/store/manager/actions';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import styles from '../styles';

const RegisterTab = ({
  classes,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const equipmentTypes = useSelector(state => state.manager.equipmentTypes);
  const equipmentsGroups = useSelector(state => state.manager.equipmentsGroups);
  const systems = useSelector(state => state.maintenance.systems);
  const [selecteds, setSelecteds] = useState({
    equipmentType: 0,
    equipmentGroup: 0,
    system: 0,
    has: false,
  });
  const setEquipType = (event) => {
    if (event.target.value === selecteds.equipmentType || event.target.value === 0) return;
    const changes = { equipmentType: event.target.value, equipmentGroup: 0, has: false };
    setSelecteds(st => ({ ...st, ...changes }));
  };
  const setEquipGroup = e => setSelecteds(st => ({
    ...st, equipmentGroup: e.target.value, has: e.target.value && st.system,
  }));
  const setSystem = (event) => {
    const changes = { system: event.target.value, systemItem: 0 };
    setSelecteds(st => ({ ...st, ...changes, has: event.target.value && st.equipmentGroup }));
  };
  const failureModeEquipmentGroup = useSelector(
    s => s.maintenance.failureModeEquipmentGroupFiltered,
  );

  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  useEffect(() => {
    if (!dataLoaded) {
      dispatch(getEquipmentTypes());
      dispatch(getEquipmentsGroups());
      dispatch(getSystems());
      dispatch(getSystemItems());
      dispatch(getFailureModes());
      dispatch(getFailureModesSystemItems());

      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch]);

  useEffect(() => {
    if (selecteds.equipmentGroup && selecteds.system) {
      dispatch(getFailureModeEquipmentGroupMaintenanceItems(selecteds));
    }
  }, [selecteds.equipmentGroup, selecteds.system, dispatch, selecteds]);

  const columns = [
    {
      field: 'equipment_group_name',
      title: translate('common:EquipmentGroup'),
    },
    {
      field: 'system_maintenance_name',
      title: translate('common:System'),
    },
    {
      field: 'system_item_maintenance_name',
      title: translate('common:SystemItems'),
    },
    {
      field: 'failure_mode_maintenance_name',
      title: translate('common:FailureModes'),
    },
  ];

  const renderHeader = () => (
    <div className={classes.tabContainer}>
      <div style={{ display: 'flex', alignItems: 'center', justifyItems: 'flex-start' }}>
        <div style={{ marginRight: 10 }}>
          <div className={classes.containerTypeSelector} style={{ flexDirection: 'column' }}>
            <p className={classes.formLabel}>
              {`${translate('common:FilterByEquipmentType')}:`}
            </p>
            <PF2MSearchSelectOutlined
              value={selecteds.equipmentType}
              onChange={setEquipType}
              fullWidth={false}
              className={classes.typeSelector}
            >
              {[{ value: 0, label: translate('common:Select') }]
                .concat(...equipmentTypes
                  .map(e => ({ value: e.id, label: e.name })))}
            </PF2MSearchSelectOutlined>
          </div>
        </div>
        <div style={{ marginRight: 10 }}>
          <div className={classes.containerTypeSelector} style={{ flexDirection: 'column' }}>
            <p className={classes.formLabel}>
              {`${translate('common:FilterByEquipmentGroup')}:`}
            </p>
            <PF2MSearchSelectOutlined
              value={selecteds.equipmentGroup}
              onChange={setEquipGroup}
              fullWidth={false}
              className={classes.typeSelector}
            >
              {[{ value: 0, label: translate('common:Select') }]
                .concat(...equipmentsGroups
                  .filter(e => selecteds.equipmentType === 0
                    || e.id_equipament === selecteds.equipmentType)
                  .map(e => ({ value: e.id, label: e.name })))}
            </PF2MSearchSelectOutlined>
          </div>
        </div>
        <div style={{ marginRight: 10 }}>
          <div className={classes.containerTypeSelector} style={{ flexDirection: 'column' }}>
            <p className={classes.formLabel}>
              {`${translate('common:FilterSystem')}:`}
            </p>
            <PF2MSearchSelectOutlined
              value={selecteds.system}
              onChange={setSystem}
              fullWidth={false}
              className={classes.typeSelector}
            >
              {[{ value: 0, label: translate('common:Select') }]
                .concat(...systems
                  .map(e => ({ value: e.id, label: e.name })))}
            </PF2MSearchSelectOutlined>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={classes.tabContainer}>
      {renderHeader()}
      <Header
        getTemplateAction={() => dispatch(getFailureModeEquipmentGroupMaintenanceItemsTemplate())}
        importAction={
          file => dispatch(importFailureModeEquipmentGroupMaintenanceItems(file, selecteds))
        }
        exportAction={selecteds.has
          ? (() => dispatch(exportFailureModeEquipmentGroupMaintenanceItems(selecteds)))
          : undefined
        }
      />
      <PF2MTable
        className={classes.table}
        data={selecteds.has ? failureModeEquipmentGroup.filter(item => item.active) : []}
        columns={columns}
        keyExtractor={item => `${item.id_failure_mode_system_item}-${item.equipment_group_id}`}
      />
    </div>
  );
};

RegisterTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

RegisterTab.defaultProps = {};

export default withStyles(styles)(RegisterTab);

import React, { useState } from 'react';
import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import Header from './Header';
import PF2MSortButton from '~/components/PF2MSortButton';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import PF2MScrollbar from '~/components/PF2MScrollbar';
import {
  setUndergroundValues,
  deleteUndergroundItem,
  restoreUndergroundItem,
  searchUndergroundList,
} from '~/store/dailyparts/actions';
import ListRow from './ListRow';
import { dailyPartsUndergroundGridHeight, dailyPartsUndergroundGridWidth } from '~/utils';

const PF2MUndergroundList = ({
  classes,
  undergroundList,
  deleteUndergroundItem: deleteAction,
  restoreUndergroundItem: restoreAction,
  setUndergroundValues: setItemAction,
  searchUndergroundList: searchAction,
  filters,
  page,
  limit,
  undergroundSortBy: { field, order },
}) => {
  const { t: translate } = useTranslation();
  const titles = [
    // { label: translate('common:Date'), field: 'date' },
    // { label: translate('common:Hour'), field: 'time' },
    { label: translate('common:StartDate'), field: 'start_date' },
    { label: translate('common:InitialTime'), field: 'start_time' },
    { label: translate('common:EndDate'), field: 'end_date' },
    { label: translate('common:EndTime'), field: 'end_time' },
    { label: translate('common:ProjectGroup'), field: 'id_project_group' },
    { label: translate('common:Element'), field: 'id_element' },
    { label: translate('common:SubElement'), field: 'id_element_point' },
    { label: translate('common:UnitOperationGroup'), field: 'id_unitary_operation_group' },
    { label: translate('common:UnitOperation'), field: 'id_unitary_operation' },
    { label: translate('common:UnitOperationTime'), field: 'unitary_operation_time' },
    { label: translate('common:CodeType'), field: 'id_code_type_ug' },
    { label: translate('common:CodeGroup'), field: 'id_code_group_ug' },
    { label: translate('common:Code'), field: 'id_code_ug' },
    { label: translate('common:CodeTime'), field: 'code_time' },
    { label: translate('common:EstimatedAdvance'), field: 'estimated_advance', advance: true },
    { label: translate('common:MeasuredAdvance'), field: 'measured_advance', advance: true },
    { label: translate('common:Cycle'), field: 'cicle' },
    { label: translate('common:EquipmentType'), field: 'id_equipment_type' },
    { label: translate('common:EquipmentGroup'), field: 'id_equipment_groups' },
    { label: translate('common:Equipment'), field: 'id_group_equipament_links' },
    { label: translate('common:Priority'), field: 'priority' },
    { label: translate('common:Comment'), field: 'comment' },
    { label: translate('common:Shift'), field: 'id_turn' },
    { label: translate('common:TeamGroup'), field: 'id_team_group' },
    { label: translate('common:Team'), field: 'id_team' },
    { label: translate('common:Pointer'), field: 'pointer' },
    { label: translate('common:Standby'), field: 'standby' },
    { label: translate('common:CycleType'), field: 'exception_type' },
  ];

  const [sortBy, setSortBy] = useState({ field, order });
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const handleSort = (title) => {
    if (undergroundList.some(t => t.isDirty)) {
      setIsAlertOpen(true);
    } else {
      const newOrder = (sortBy.order === 'asc' && sortBy.field === title.field) ? 'desc' : 'asc';
      const newSortBy = { field: title.field, order: newOrder };
      setSortBy(newSortBy);
      searchAction(filters, page, limit, newSortBy);
    }
  };

  const renderTableTitle = (title, key) => (
    <TableCell
      align="center"
      className={classes.tableCell}
      key={key}
      style={{
        position: 'sticky',
        top: 0,
        backgroundColor: 'white',
        zIndex: 1,
      }}
    >
      <PF2MSortButton
        label={title.label}
        field={title.field}
        sortField={sortBy.field}
        sortOrder={sortBy.order}
        onClick={() => handleSort(title)}
      />
    </TableCell>
  );

  const renderTableHeader = () => titles.map((t, i) => renderTableTitle(t, `${i}_${t}`));

  const renderRows = () => undergroundList
    .map(d => (
      <ListRow
        key={d.id}
        data={d}
        onDelete={deleteAction}
        onRestore={restoreAction}
        onEdit={setItemAction}
        classes={classes}
      />
    ));

  const renderLoadMore = () => {
    if (undergroundList.length < limit) return null;
    if (undergroundList.filter(d => d.id > 0).length < 1) { return null; }
    return (
      <div style={{
        backgroundColor: '#F9F9F9',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 10,
        paddingBottom: 10,
      }}
      >
        <Button
          onClick={() => searchAction(filters, (page + 1), limit, sortBy, false)}
        >
          <ArrowDownIcon />
          <Typography
            variant="h5"
          >

            {translate('common:SeeMore')}
          </Typography>
        </Button>
      </div>
    );
  };

  return (
    <>
      <Header />
      {undergroundList.length > 0 ? (
        <PF2MScrollbar style={{
          backgroundColor: 'white',
          marginTop: 10,
          paddingLeft: 10,
          height: dailyPartsUndergroundGridHeight,
          width: dailyPartsUndergroundGridWidth,
        }}
        >
          <>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {renderTableHeader()}
                </TableRow>
              </TableHead>
              <TableBody>
                {renderRows()}
              </TableBody>
            </Table>
            {renderLoadMore()}
            <PF2MAlertDialog
              hasCancel={false}
              confirmText={translate('common:Understood')}
              description={translate('validation:YouHaveUnsavedData')}
              open={isAlertOpen}
              onConfirm={() => setIsAlertOpen(false)}
            />
          </>
        </PF2MScrollbar>
      ) : (
        <div
          className="container"
          style={{
            backgroundColor: 'white',
            padding: 20,
          }}
        >
          <span>{translate('common:NoEntries')}</span>
        </div>
      )}

    </>
  );
};

PF2MUndergroundList.propTypes = {
  classes: PropTypes.object,
  undergroundList: PropTypes.array,
  setUndergroundValues: PropTypes.func,
  deleteUndergroundItem: PropTypes.func,
  restoreUndergroundItem: PropTypes.func,
  searchUndergroundList: PropTypes.func,
  undergroundSortBy: PropTypes.object,
  filters: PropTypes.object,
  page: PropTypes.number,
  limit: PropTypes.number,
};

PF2MUndergroundList.defaultProps = {
  classes: {},
  undergroundList: [],
  setUndergroundValues: null,
  deleteUndergroundItem: null,
  restoreUndergroundItem: null,
  searchUndergroundList: null,
  filters: {},
  page: 1,
  limit: 10,
  undergroundSortBy: {},
};

const mapStateToProps = state => ({
  undergroundList: state.dailyParts.undergroundList,
  filters: state.dailyParts.filters,
  page: state.dailyParts.page,
  limit: state.dailyParts.limit,
  undergroundSortBy: state.dailyParts.undergroundSortBy,
  subElements: state.manager.subElements,
});

export default connect(mapStateToProps, {
  setUndergroundValues,
  deleteUndergroundItem,
  restoreUndergroundItem,
  searchUndergroundList,
})(withStyles(styles)(PF2MUndergroundList));

const styles = {
  noBadge: {
    display: 'flex',
    flexDirection: 'column',
  },
  badge: {
    display: 'flex',
    flexDirection: 'column',
    // position: 'fixed',
    '& .MuiBadge-badge': {
      width: 25,
      height: 25,
      padding: '5px 0px 0px 15px',
      top: 4,
      right: 4,
      borderRadius: 20,
      backgroundColor: '#FFC300',
      color: '#111110',
    },
  },
  badgeDisabled: {
    '& .MuiBadge-badge': {
      backgroundColor: '#B3B3B3',
      padding: '15px 0px 0px 20px',
      '& .MuiSvgIcon-root': {
        fontSize: 35,
      },
    },
  },
  root: {
    flexGrow: 0,
    color: '#647886',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tabsIndicator: {
    backgroundColor: 'transparent',
  },
  tabRoot: {
    minWidth: 72,
    marginRight: 5,
    fontFamily: 'Barlow Condensed',
    fontWeight: 600,
    fontSize: 18,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    '&:hover': {
      color: '#3D5465',
    },
    color: 'white',
    backgroundColor: '#CDCDCD',
    '& .Mui-selected': {
      color: '#647886',
      backgroundColor: 'white',
      fontWeight: 600,
    },
  },
  tabsRoot: {
    '& .MuiTabs-flexContainer': {
      position: 'fixed',
    },
  },
  chatTabWithMessage: {
    minWidth: 72,
    marginRight: 5,
    fontFamily: 'Barlow Condensed',
    fontWeight: 600,
    fontSize: 18,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    '&:hover': {
      color: '#F2AA00',
    },
    color: 'white',
    backgroundColor: 'green',
  },
  tabSelected: {
    color: '#647886',
    backgroundColor: 'white',
    fontWeight: 600,
  },
};

export default styles;

// import { managerGridHeight } from '~/utils';

const styles = {
  modalContainer: {
    display: 'flex',
    // backgroundColor: 'red', // '#F9F9F9',
    // height: '100%', // managerGridHeight,
    padding: 10,
  },
  icon: {
    marginRight: 10,
  },
  button: {
    color: '#FFF',
    margin: 5,
  },
  tabContainer: {
    borderRadius: 5,
    borderTopLeftRadius: 0,
    backgroundColor: 'white',
    padding: 10,
  },
  field: {
    border: '1px solid #CDCDCD',
    borderRadius: 5,
    height: 40,
    color: '#647886',
    width: 200,
    borderWidth: 0,
  },
  fieldCommentary: {
    border: '1px solid #CDCDCD',
    borderRadius: 5,
    height: 40,
    color: '#647886',
    borderWidth: 0,
  },
  fieldError: {
    border: '1px solid red !important',
    borderRadius: 5,
    height: 40,
  },
  classGroupName: {
    flex: 1,
    minWidth: 290,
    marginLeft: 10,
    marginRight: 10,
  },
  formLabel: {
    color: '#647886',
    fontSize: 12,
    fontFamily: 'Roboto',
    marginBottom: 5,
  },
  errorMessage: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 12,
    textAlign: 'center',
    color: '#EC3337',
  },
  table: {
    height: 'calc(100vh - 260px)', // 'calc(100vh - 52px - 52px - 230px)',
  },
  textField: {
    fontFamily: 'Roboto',
    fontSize: 14,
    color: '#647886',
    height: 40,
    width: '100%',
  },
  textArea: {
    fontFamily: 'Roboto',
    fontSize: 14,
    color: '#647886',
    width: '100%',
  },
  saveButton: {
    color: 'white',
    marginBottom: 15,
    marginTop: 15,
    width: '100%',
    minWidth: 200,
  },
  labelTypeSelector: {
    color: '#647886',
    fontFamily: 'Barlow Condensed',
    fontWeight: 600,
    fontSize: 18,
    textTransform: 'uppercase',
    marginRight: 10,
  },
  fieldRoot: {
    height: 40,
    width: 200,
  },
};

export default styles;

import React, { useState, useEffect } from 'react';
import {
  withStyles,
  Checkbox,
  ListItemText,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { Scrollbars } from 'react-custom-scrollbars';
import Footer from '~/pages/Manager/Footer';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import {
  getElementsOd,
  updateElementsOd,
  resetElementsOd,
  setElementsOdValues,
  getElements,
} from '~/store/manager/actions';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import PF2MScrollbar from '~/components/PF2MScrollbar';

const ElementsTypesTab = ({
  classes,
  elementsOd,
  elements,
  getElements: loadElements,
  getElementsOd: getAll,
  isElementsOdDirty,
  updateElementsOd: update,
  resetElementsOd: reset,
  setElementsOdValues: updateItem,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const { t: translate } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      getAll();
      await loadElements();
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, getAll, loadElements]);

  const handleCheck = (field, isChecked, name) => {
    updateItem(Number(name), field, isChecked);
  };

  const renderElements = (field, filterElements = []) => elements
    .filter(e => e.active)
    .map((s) => {
      const current = filterElements.find(i => i.id === s.id);
      return (
        <div key={s.id} style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            checked={current ? current[field] : false}
            color="primary"
            name={s.id.toString()}
            onChange={e => handleCheck(field, e.target.checked, e.target.name)}
          />
          <ListItemText secondary={s.name} style={{ padding: 0 }} />
        </div>
      );
    });

  return (
    <div className={classes.tabContainer}>
      <div style={{
        display: 'flex', justifyContent: 'space-between',
      }}
      >
        <div style={{ width: '49%' }}>
          <span className={classes.label}>

            {`${translate('common:OriginPlural')}:`}
          </span>
          <PF2MScrollbar className={classes.scrollbars} style={{ height: 'auto' }}>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}>
              {renderElements('is_origin', elementsOd.filter(e => e.is_origin))}
            </div>
          </PF2MScrollbar>
        </div>
        <div style={{ width: '49%' }}>
          <span className={classes.label}>

            {`${translate('common:DestinationPlural')}:`}
          </span>
          <PF2MScrollbar className={classes.scrollbars} style={{ height: 'auto' }}>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}>
              {renderElements('is_destination', elementsOd.filter(e => e.is_destination))}
            </div>
          </PF2MScrollbar>
        </div>
      </div>
      <div style={{ marginTop: 20 }}>
        <Footer
          isDirty={isElementsOdDirty}
          discard={() => reset()}
          sendData={() => update(elementsOd)}
          style={{ marginTop: 10 }}
        />
      </div>
      <PF2MAlertDialog
        hasCancel={false}
        confirmText={translate('common:Understood')}
        description={translate('validation:YouHaveUnsavedData')}
        open={isAlertOpen}
        onConfirm={() => setIsAlertOpen(false)}
      />
    </div>
  );
};

ElementsTypesTab.propTypes = {
  classes: PropTypes.object.isRequired,
  elementsOd: PropTypes.array,
  elements: PropTypes.array,
  getElements: PropTypes.func,
  getElementsOd: PropTypes.func,
  updateElementsOd: PropTypes.func,
  resetElementsOd: PropTypes.func,
  setElementsOdValues: PropTypes.func,
  isElementsOdDirty: PropTypes.bool,
};

ElementsTypesTab.defaultProps = {
  elementsOd: [],
  elements: [],
  getElements: null,
  getElementsOd: null,
  updateElementsOd: null,
  resetElementsOd: null,
  setElementsOdValues: null,
  isElementsOdDirty: false,
};

const mapStateToProps = state => ({
  isElementsOdDirty: state.manager.isElementsOdDirty,
  elements: state.manager.elements,
  elementsOd: state.manager.elementsOd,
});

const mapDispatchToProps = {
  getElements,
  getElementsOd,
  updateElementsOd,
  resetElementsOd,
  setElementsOdValues,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ElementsTypesTab));

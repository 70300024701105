export const VERSION = '@pf2m-version';

export const getVersion = () => localStorage.getItem(VERSION);

export const setVersion = version => localStorage.setItem(VERSION, version);

export const normalizeVersions = ({ local, current }) => ({
  localVersion: Number(local.match(/\d+/g).join('')),
  currentVersion: Number(current.match(/\d+/g).join('')),
});

export const clearCache = () => {
  if ('caches' in window) {
    caches.keys().then((keys) => {
      keys.forEach(k => caches.delete(k));
    });
  }
};

export const validateVersion = ({ current }) => {
  const local = getVersion();
  if (!local) return clearCache();

  const { currentVersion, localVersion } = normalizeVersions({ current, local });

  if (currentVersion > localVersion) return clearCache();
  return null;
};

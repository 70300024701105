import React from 'react';
import { withStyles, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './styles';

const PF2MLoading = ({ classes, loading }) => (
  loading && (
  <div className={classes.container}>
    <CircularProgress color="secondary" size={48} />
  </div>
  )
);

PF2MLoading.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool,
};

PF2MLoading.defaultProps = {
  loading: false,
};

export default withStyles(styles)(PF2MLoading);

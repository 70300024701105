import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CreateIcon from '@material-ui/icons/Create';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import PropTypes from 'prop-types';
import PF2MDataTable from '~/components/PF2MDataTable';
import { Fab, Typography, withStyles } from '@material-ui/core';
import styles from '../styles';
import {
  Card, CardBody, CardHeader, CardIcon,
} from '~/components/Card';
import {
  getTabletConfiguration,
  updateTabletConfigurations,
  setTabletConfigurationsValues,
} from '~/store/manager/actions';

const TabletConfigModal = ({ classes, close }) => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const [dataLoaded, setDataLoaded] = useState(false);
  const tabletConfiguration = useSelector(state => state.manager.tabletConfiguration);
  const isTabletConfigurationDirty = useSelector(state => state.manager.isTabletConfigurationDirty);

  useEffect(() => {
    async function fetchData() {
      dispatch(getTabletConfiguration());
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch]);


  const columns = [
    {
      field: 'key',
      title: translate('common:Key'),
      defaultSort: 'asc',
      editable: 'never',
    },
    {
      field: 'value',
      title: translate('common:Value'),
    },
    {
      field: 'description',
      title: translate('common:Description'),
      editable: 'never',
    },
  ];

  return (
    <div className={classes.modalContainer}>
      <Card>
        <CardHeader icon>
          <>
            <CardIcon color="warning">
              <CreateIcon />
            </CardIcon>
            <CardIcon
              onClick={close}
              style={{
                float: 'right',
                cursor: 'pointer',
                background: 'darkgray',
                borderRadius: 50,
                boxShadow: '5px 5px 25px gray',
              }}
            >
              <CloseIcon />
            </CardIcon>
            <Typography className={classes.modalTitle} variant="h6" align="center" color="textPrimary">
              {translate('common:TabletConfiguration')}
            </Typography>
          </>
        </CardHeader>
        <CardBody>
          <div />
          <div>
            <PF2MDataTable
              columns={columns}
              data={tabletConfiguration}
              options={{
                toolbar: false,
                pageSize: 30,
                hideDelete: true,
                maxBodyHeight: 'calc(100vh - 300px)',
              }}
              onChange={newData => newData.forEach((row) => {
                dispatch(setTabletConfigurationsValues(row));
              })
              }
            />
          </div>
          <div style={{ width: '100%', textAlign: 'center', paddingTop: 20 }}>
            <Fab
              variant="extended"
              size="medium"
              color="secondary"
              disabled={!isTabletConfigurationDirty}
              onClick={() => {
                dispatch(updateTabletConfigurations(tabletConfiguration));
                close();
              }}
            >
              <SaveIcon />
              {translate('common:Save')}
            </Fab>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

TabletConfigModal.propTypes = {
  classes: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
};

export default withStyles(styles)(TabletConfigModal);

import React, { useState, useEffect } from 'react';
import {
  withStyles, FormControl, FormLabel, FormGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import styles from '../styles';
import Header from '~/pages/Manager/Header';
// import Footer from '~/pages/Manager/Footer';
import PF2MTable from '~/components/PF2MTable';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import { useTranslation } from 'react-i18next';

import {
  exportDmt,
  importDmt,
  getDmt,
  getElements,
  getSubElements,
} from '~/store/manager/actions';

const DMTsTab = ({
  classes,
  dmts,
  exportDmt: exportAction,
  importDmt: importAction,
  getElements: loadElements,
  elements,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [origin, setOrigin] = useState(0);
  const [destination, setDestination] = useState(0);
  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchOperators() {
      dispatch(getDmt(false, { id_origin: origin, id_dest: destination }));
      await dispatch(getElements(true));
      await dispatch(getSubElements(true));
    }
    if (!dataLoaded) {
      fetchOperators();
      setDataLoaded(true);
    } else {
      fetchOperators();
    }
  }, [dataLoaded, loadElements, origin, destination, dispatch]);

  const columns = [
    { field: 'mine_name', title: translate('common:OriginElement'), width: '200px' },
    { field: 'origin_name', title: translate('common:SubOriginElement'), width: '200px' },
    { field: 'dest_name', title: translate('common:DestinationElement'), width: '200px' },
    { field: 'point_name', title: translate('common:SubDestinationElement'), width: '200px' },
    {
      field: 'value',
      title: translate('common:DMTMeters'),
      width: '200px',
      decimal: true,
      separator: '.',
    },
  ];


  return (
    <div className={classes.tabContainer}>
      <Header
        importAction={importAction}
        exportAction={exportAction}
      />
      <div>
        <FormControl>
          <FormLabel className={classes.formLabel}>
            {`${translate('common:OriginElement')}:`}
          </FormLabel>
          <FormGroup>
            <PF2MSearchSelectOutlined
              placeholder={null}
              style={{
                color: '#647886',
                width: 500,
                borderWidth: 0,
              }}
              value={origin}
              onChange={e => setOrigin(e.target.value)}
            >
              {[{ value: 0, label: translate('common:Select') }]
                .concat(...elements
                  .map(e => ({ value: e.id, label: e.name })))}
            </PF2MSearchSelectOutlined>
          </FormGroup>
        </FormControl>
        <FormControl style={{ marginLeft: 10 }}>
          <FormLabel className={classes.formLabel}>
            {`${translate('common:DestinationElement')}:`}
          </FormLabel>
          <FormGroup>
            <PF2MSearchSelectOutlined
              placeholder={null}
              style={{
                color: '#647886',
                width: 500,
                borderWidth: 0,
              }}
              value={destination}
              onChange={e => setDestination(e.target.value)}
            >
              {[{ value: 0, label: translate('common:Select') }]
                .concat(...elements
                  .map(e => ({ value: e.id, label: e.name })))}
            </PF2MSearchSelectOutlined>
          </FormGroup>
        </FormControl>
      </div>
      <PF2MTable
        data={dmts.filter(d => (origin === 0 || d.id_mine === origin)
          && (destination === 0 || d.id_dest === destination))}
        keyExtractor={data => `${parseInt((Math.random() * 10000), 10)}_${data.id_link}_${parseInt((Math.random() * 10000), 10)}`}
        columns={columns}
        className={classes.grid}
      />
    </div>
  );
};

DMTsTab.propTypes = {
  classes: PropTypes.object.isRequired,
  dmts: PropTypes.array,
  exportDmt: PropTypes.func,
  importDmt: PropTypes.func,
  getElements: PropTypes.func,
  elements: PropTypes.array,
};

DMTsTab.defaultProps = {
  dmts: [],
  exportDmt: null,
  importDmt: null,
  getElements: null,
  elements: [],
};

const mapStateToProps = state => ({
  dmts: state.manager.dmts,
  elements: state.manager.elements,
});

const mapDispatchToProps = {
  exportDmt,
  importDmt,
  getDmt,
  getElements,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DMTsTab));

import React, { useState, useEffect } from 'react';
import {
  withStyles, FormControl, FormLabel, FormGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import Footer from '~/pages/Manager/Footer';
import { useTranslation } from 'react-i18next';
import PF2MAddButton from '~/components/PF2MAddButton';
import PF2MTable from '~/components/PF2MTable';
import NumberFormat from 'react-number-format';
import classnames from 'classnames';
import {
  updateElevationGroups,
  restoreElevationGroups,
  addElevationGroup,
  getElevationGroups,
} from '~/store/lineup/actions';
import styles from '../styles';
import { parseFloat as utilsParseFloat } from '~/utils';

const ElevationGroupsTab = ({
  classes,
}) => {
  const { t: translate } = useTranslation();
  const [dataLoaded, setDataLoaded] = useState(false);
  const dispatch = useDispatch();
  const elevationGroups = useSelector(state => state.lineup.elevationGroups);
  const isElevationGroupsDirty = useSelector(state => state.lineup.isElevationGroupsDirty);

  useEffect(() => {
    async function fetchData() {
      dispatch(getElevationGroups());
    }

    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch]);

  const columns = [
    {
      field: 'name',
      title: translate('common:Name'),
    },
    {
      field: 'initial_elevation',
      title: translate('common:InitialElevation'),
    },
    {
      field: 'final_elevation',
      title: translate('common:FinalElevation'),
    },
    {
      field: 'interval',
      title: translate('common:Interval'),
    },
    {
      field: 'active',
      title: 'STATUS',
      editable: false,
    },
  ];

  const renderHeader = () => (
    <Formik
      initialValues={{
        name: '',
        initialElevation: 0,
        finalElevation: 0,
        interval: 0,
      }}
      validate={({
        name, initialElevation, finalElevation, interval,
      }) => {
        const errors = {};
        if (!name) {
          errors.name = translate('validation:RequiredField');
        }
        if (initialElevation === '') errors.initialElevation = translate('validation:RequiredField');
        if (finalElevation === '') errors.finalElevation = translate('validation:RequiredField');
        if (!utilsParseFloat(interval)) errors.interval = translate('validation:RequiredField');

        if (utilsParseFloat(finalElevation) <= utilsParseFloat(initialElevation)) {
          errors.finalElevation = translate('validation:FinalElevationMustBeBiggerThanInitialElevation');
        }
        return errors;
      }}
      onSubmit={async (values, { resetForm }) => {
        dispatch(addElevationGroup(values));
        resetForm();
      }}
    >
      {({
        isSubmitting,
        errors,
        isValid,
      }) => (
        <Form style={{ display: 'flex', alignItems: 'center' }}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              {`${translate('common:Name')}:`}
              <ErrorMessage name="name" component="span" className={classes.errorMessage} />
            </FormLabel>
            <FormGroup>
              <Field
                type="text"
                name="name"
                className={classnames('form-control', errors.name ? classes.fieldError : classes.field)}
                style={{ width: '100%' }}
              />
            </FormGroup>
          </FormControl>
          <FormControl style={{ marginLeft: 10 }}>
            <FormLabel className={classes.formLabel}>
              {`${translate('common:InitialElevation')}:`}
              <ErrorMessage name="initialElevation" component="span" className={classes.errorMessage} />
            </FormLabel>
            <FormGroup>
              <Field
                name="initialElevation"
                render={({ field, form }) => (
                  <NumberFormat
                    {...field}
                    className={classnames('form-control', errors.initialElevation ? classes.fieldError : classes.field)}
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                    onChange={(e) => {
                      form.setFieldValue('initialElevation', e.target.value);
                    }}
                  />
                )}
              />
            </FormGroup>
          </FormControl>
          <FormControl style={{ marginLeft: 10 }}>
            <FormLabel className={classes.formLabel}>
              {`${translate('common:FinalElevation')}:`}
              <ErrorMessage name="finalElevation" component="span" className={classes.errorMessage} />
            </FormLabel>
            <FormGroup>
              <Field
                name="finalElevation"
                render={({ field, form }) => (
                  <NumberFormat
                    {...field}
                    className={classnames('form-control', errors.finalElevation ? classes.fieldError : classes.field)}
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                    onChange={(e) => {
                      form.setFieldValue('finalElevation', e.target.value);
                    }}
                  />
                )}
              />
            </FormGroup>
          </FormControl>
          <FormControl style={{ marginLeft: 10 }}>
            <FormLabel className={classes.formLabel}>
              {`${translate('common:Interval')}:`}
              <ErrorMessage name="interval" component="span" className={classes.errorMessage} />
            </FormLabel>
            <FormGroup>
              <Field
                name="interval"
                render={({ field, form }) => (
                  <NumberFormat
                    {...field}
                    className={classnames('form-control', errors.interval ? classes.fieldError : classes.field)}
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                    onChange={(e) => {
                      form.setFieldValue('interval', e.target.value);
                    }}
                  />
                )}
              />
            </FormGroup>
          </FormControl>
          <div style={{ marginTop: 15 }}>
            <PF2MAddButton disabled={isSubmitting || !isValid} />
          </div>
        </Form>
      )}
    </Formik>
  );

  return (
    <div className={classes.tabContainer}>
      {renderHeader()}
      <PF2MTable
        data={elevationGroups}
        columns={columns}
        className={classes.grid}
      />
      <Footer
        isDirty={isElevationGroupsDirty}
        sendData={() => dispatch(updateElevationGroups(elevationGroups))}
        discard={() => dispatch(restoreElevationGroups())}
      />
    </div>
  );
};

ElevationGroupsTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

ElevationGroupsTab.defaultProps = {
};

export default withStyles(styles)(ElevationGroupsTab);

export const SET_TERRAIN_MODEL_LIST = 'SET_TERRAIN_MODEL_LIST';
export const ADD_TERRAIN_MODEL = 'ADD_TERRAIN_MODEL';
export const SET_TERRAIN_MODEL_VALUES = 'SET_TERRAIN_MODEL_VALUES';
export const RESET_TERRAIN_MODELS = 'RESET_TERRAIN_MODELS';
export const SET_TERRAIN_MODEL_ITEM = 'SET_TERRAIN_MODEL_ITEM';

export const SET_TERRAIN_MODEL_UPLOAD_LIST = 'SET_TERRAIN_MODEL_UPLOAD_LIST';
export const ADD_TERRAIN_MODEL_UPLOAD = 'ADD_TERRAIN_MODEL_UPLOAD';
export const SET_TERRAIN_MODEL_UPLOAD_VALUES = 'SET_TERRAIN_MODEL_UPLOAD_VALUES';
export const SET_TERRAIN_MODEL_UPLOAD_ITEM = 'SET_TERRAIN_MODEL_UPLOAD_ITEM';
export const RESET_TERRAIN_MODEL_UPLOADS = 'RESET_TERRAIN_MODEL_UPLOADS';
export const CLEAR_TEMP_TERRAIN_MODEL_UPLOAD = 'CLEAR_TEMP_TERRAIN_MODEL_UPLOAD';

export const SET_TERRAIN_MODEL_UPLOAD_QUEUE = 'SET_TERRAIN_MODEL_UPLOAD_QUEUE';
export const ADD_TERRAIN_MODEL_UPLOAD_QUEUE = 'ADD_TERRAIN_MODEL_UPLOAD_QUEUE';
export const SET_TERRAIN_MODEL_UPLOAD_QUEUE_VALUES = 'SET_TERRAIN_MODEL_UPLOAD_QUEUE_VALUES';
export const SET_QUEUE_ITEM_DONE = 'SET_QUEUE_ITEM_DONE';

export const SET_TERRAIN_MODEL_UPLOAD_LAYERS_LIST = 'SET_TERRAIN_MODEL_UPLOAD_LAYERS_LIST';
export const ADD_TERRAIN_MODEL_UPLOAD_LAYER = 'ADD_TERRAIN_MODEL_UPLOAD_LAYER';
export const SET_TERRAIN_MODEL_UPLOAD_LAYER_VALUES = 'SET_TERRAIN_MODEL_UPLOAD_LAYER_VALUES';
export const RESET_TERRAIN_MODEL_UPLOAD_LAYERS = 'RESET_TERRAIN_MODEL_UPLOAD_LAYERS';

export const SET_POLYGON_LIST = 'SET_POLYGON_LIST';

export const SET_CLOUD_POINT_REPORT_LIST = 'SET_CLOUD_POINT_REPORT_LIST';

export const SET_TERRAIN_DEFAULT_LAYERS_LIST = 'SET_TERRAIN_DEFAULT_LAYERS_LIST';

import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import { Card, CardBody, CardHeader, CardIcon } from '~/components/Card';
import { Grid, Typography, withStyles } from '@material-ui/core';
import PF2MDataTable from '~/components/PF2MDataTable';
import PF2MDiscardChangesButton from '~/components/PF2MDiscardChangesButton';
import PF2MFinalizeButton from '~/components/PF2MFinalizeButton';
import styles from '../../styles';
import { useDispatch } from 'react-redux';
import { formatDate, formatDateToServerFormat, formatTime, newMomentDate } from '~/utils/moment';
import { setRailroadValues } from '~/store/dailyparts/actions';
import { LocalShipping } from '@material-ui/icons';

const LoadEquipmentNumberModal = ({ handleClose, railroadReport }) => {
    const { t: translate } = useTranslation();

    const getNextId = (registersToFindNextId) => {
      if(registersToFindNextId.length === 0) {
        return 1
      }

      const registerWithMaxId = registersToFindNextId.reduce((registerWithMaxId, register) => {
        if(register.id === undefined && registerWithMaxId.id === undefined) {
          return register
        } else if(register.id === undefined) {
          return registerWithMaxId
        } else if(registerWithMaxId.id === undefined) {
          return register
        }else if (registerWithMaxId.id < register.id ) {
          return register
        } else {
          return registerWithMaxId
        }
      })

      if(registerWithMaxId?.id === undefined) {
        return 1
      }
      return registerWithMaxId.id + 1
    }

    const createInitialRailroadEquipment = () => {
      const initialRairoladLoadEquips = railroadReport.load_equips.map(o => ({...o}))

      let nextId = getNextId(initialRairoladLoadEquips)
      initialRairoladLoadEquips.forEach((loadEquip) => {

        if(loadEquip.id === undefined) {
          loadEquip.isFakeId = true
          loadEquip.id = nextId++
        }
      })
      return initialRairoladLoadEquips
    }

    const dispatch = useDispatch();
    const [isModalApplyOpen, setIsModaApplylOpen] = useState(false);
    const [isModalDiscardOpen, setIsModalDiscardOpen] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [loadEquipmentData, setLoadEquipmentData] = useState(createInitialRailroadEquipment)

    const columns = [
        {
            editable: 'always',
            title: translate('common:Date'),
            field: 'op_date',
            type: 'date',
        },
        {
            editable: 'always',
            title: translate('common:Hour'),
            field: 'op_time',
            customType: 'time',
            // type: 'date',
        },
        {
            editable: 'always',
            title: translate('common:LoadEquipmentNumber'),
            field: 'n_equips',
        },
    ]

    const handleOnDelete = (railroadLoadEquipsToDelete) => {
      if (railroadLoadEquipsToDelete.length){
        setHasChanges(true)
        setLoadEquipmentData((currentLoadEquips) => {
          return currentLoadEquips.filter(item => {
            const itemDeleted = railroadLoadEquipsToDelete.find(deleted => deleted.id === item.id)
            if (!itemDeleted)
              return true
            if (item.isFakeId)
              return false

            item.update_status = "D"
            return true
          })
        })
      }
    }

    const handleOnAdd = (newRailroadLoadEquip) => {
      setHasChanges(true)
      setLoadEquipmentData((currentLoadEquips) => {
        newRailroadLoadEquip.id = getNextId(currentLoadEquips)
        newRailroadLoadEquip.isFakeId = true
        newRailroadLoadEquip.update_status = 'I'

        return [
          ...currentLoadEquips,
          { ...newRailroadLoadEquip }
        ]
      })
    }

    const handleOnChange = (loadEquipmentToChange) => {
      setLoadEquipmentData((currentLoadEquips) => {
        currentLoadEquips.forEach((loadEquip, index) => {
          loadEquipmentToChange.forEach((updated) => {
            const { update_status } = updated
            if (loadEquip.id === updated.id) {

              let {op_date, op_time } = updated
              if (op_date !== undefined) {
                op_date = formatDateToServerFormat(op_date);
              }
              if (op_time?.getDate !== undefined){
                op_time = formatTime(op_time);
              }

              currentLoadEquips[index] = {
                ...updated,
                update_status: update_status ? update_status : 'U',
                op_date: op_date ? op_date : '',
                op_time: op_time ? op_time : '',
              }
            }
          })
        })
        return currentLoadEquips
      })
      setHasChanges(true);
    }

    const handleOnApply = () => {
      let filteredLoadEquipments = loadEquipmentData.map((loadEquipment) => {
        if(loadEquipment.isFakeId){
          delete loadEquipment.id
          delete loadEquipment.isFakeId
        }
        return { ...loadEquipment }
      })

      const newRailroadReport = {
        ...railroadReport,
        tableData: undefined,
        load_equips: filteredLoadEquipments,
      };

      dispatch(setRailroadValues(newRailroadReport));
      handleClose();
    }

    const handleConfirmChanges = () => {
        setIsModaApplylOpen(false);
        if (hasChanges) {
          handleOnApply();
        }
    }

    const handleOnDiscardChanges = () => {
      if(hasChanges) {
        setIsModalDiscardOpen(true);
      }
    }

    const handleOnConfirmDiscard = () => {
      setLoadEquipmentData(createInitialRailroadEquipment)
      setIsModalDiscardOpen(false)
      setHasChanges(false)
    }

    return (
        <div>
            <Card>
                <CardHeader icon>
                    <CardIcon color="warning">
                      <LocalShipping />
                    </CardIcon>
                    <CardIcon
                        onClick={handleClose}
                        style={{
                            float: 'right',
                            cursor: 'pointer',
                            background: 'darkgray',
                            borderRadius: 50,
                            boxShadow: '10px 10px 50px gray',
                        }}
                    >
                        <CloseIcon />
                    </CardIcon>
                </CardHeader>
                <CardBody>
                    <Typography
                        variant="h4"
                        align="center"
                        gutterBottom
                    >
                        {translate('common:LoadingEquipment')}
                    </Typography>
                    <PF2MDataTable
                        options={{
                            search: true,
                            selection: true,
                            hideDelete: true,
                            hideAdd: true,
                        }}

                        onAdd={handleOnAdd}
                        onChange={handleOnChange}
                        onDiscard={handleOnDiscardChanges}
                        onApply={() => setIsModaApplylOpen(true)}
                        onDelete={handleOnDelete}
                        initialFormData={
                          {
                            ...columns.reduce((acc, e) => ({ ...acc, [e.field]: '0' }), {}),
                            op_date: formatDate(newMomentDate(), 'DD-MM-YYYY'),
                            op_time: formatTime(newMomentDate(), 'HH:mm:ss'),
                          }
                        }
                        columns={columns}
                        // data={loadEquipmentData.length > 0 ? loadEquipmentData : []}
                        data={loadEquipmentData.length > 0
                          ? loadEquipmentData.filter(r => r.update_status !== 'D')
                          : []
                        }
                    />
                </CardBody>

                <Grid item style={{ alignSelf: 'center' }}>
                    <PF2MDiscardChangesButton onClick={handleOnDiscardChanges}/>
                    <PF2MFinalizeButton onClick={() => setIsModaApplylOpen(true)}/>
                </Grid>

            </Card>
            <PF2MAlertDialog
                hasCancel={true}
                confirmText={translate(hasChanges ? 'common:Confirm' : 'common:Ok')}
                description={translate(hasChanges ? 'common:ConfirmChanges' : 'common:ThereAreNoChanges')}
                open={isModalApplyOpen}
                onClose={() => setIsModaApplylOpen(false)}
                onConfirm={handleConfirmChanges}
            />

            <PF2MAlertDialog
              hasCancel={true}
              confirmText={translate('common:Discard')}
              description={translate('common:DiscardChanges')}
              open={isModalDiscardOpen}
              onClose={() => setIsModalDiscardOpen(false)}
              onConfirm={handleOnConfirmDiscard}
            />
        </div>
    );
};

/*EquipmentStatusEditAllModal.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func,
  selectedItems: PropTypes.array,
};

EquipmentStatusEditAllModal.defaultProps = {
  handleClose: null,
  selectedItems: [],
};*/

export default withStyles(styles)(LoadEquipmentNumberModal);

import List from '@material-ui/icons/List';
import ControlCamera from '@material-ui/icons/ControlCamera';
import LocalGasStation from '@material-ui/icons/LocalGasStation';
import ListAlt from '@material-ui/icons/ListAlt';
import DataUsage from '@material-ui/icons/DataUsage';
import MapOutlined from '@material-ui/icons/MapOutlined';
import Warning from '@material-ui/icons/Warning';
import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@material-ui/core';
import { ReactComponent as ExcavatorIcon } from '../assets/modulesIcons/excavator.svg';
import { ReactComponent as TelemetryIcon } from '../assets/modulesIcons/telemetry.svg';
import { ReactComponent as Contracts } from '../assets/modulesIcons/contracts.svg';
import { ReactComponent as Maintenance } from '../assets/modulesIcons/maintenance.svg';
import { ReactComponent as Quality } from '../assets/modulesIcons/quality.svg';
import { ReactComponent as Dynamic } from '../assets/modulesIcons/dynamic.svg';
import { ReactComponent as DynamicOff } from '../assets/modulesIcons/dynamicOff.svg';
import { ReactComponent as OpenPit } from '../assets/modulesIcons/openpit.svg';
import { ReactComponent as Gantt } from '../assets/modulesIcons/gantt.svg';

const CustomIcon = ({ icon, ...props }) => (
  <SvgIcon
    viewBox="0 0 48 48"
    component={icon}
    {...props}
  />
);

CustomIcon.propTypes = {
  icon: PropTypes.elementType.isRequired,
};

const icons = {
  List,
  ControlCamera,
  LocalGasStation,
  ListAlt,
  DataUsage,
  MapOutlined,
  Warning,
  Excavator: React.forwardRef(
    (props, ref) => <CustomIcon {...props} ref={ref} icon={ExcavatorIcon} />,
  ),
  Telemetry: React.forwardRef(
    (props, ref) => <CustomIcon {...props} ref={ref} icon={TelemetryIcon} />,
  ),
  Contracts: React.forwardRef(
    (props, ref) => <CustomIcon {...props} ref={ref} icon={Contracts} />,
  ),
  Maintenance: React.forwardRef(
    (props, ref) => <CustomIcon {...props} ref={ref} icon={Maintenance} />,
  ),
  Quality: React.forwardRef(
    (props, ref) => <CustomIcon {...props} ref={ref} icon={Quality} />,
  ),
  OpenPit: React.forwardRef(
    (props, ref) => <CustomIcon {...props} ref={ref} icon={OpenPit} />,
  ),
  Gantt: React.forwardRef(
    (props, ref) => (
      <CustomIcon
        {...props}
        ref={ref}
        icon={Gantt}
        viewBox="0 0 24 24"
      />
    ),
  ),
  Dynamic: React.forwardRef(
    (props, ref) => <CustomIcon {...props} ref={ref} icon={Dynamic} />,
  ),
  DynamicOff: React.forwardRef(
    (props, ref) => <CustomIcon {...props} ref={ref} icon={DynamicOff} />,
  ),
};


export default icons;

/* eslint-disable camelcase */
import React from 'react';
import { withStyles } from '@material-ui/core';
import { useDrop } from 'react-dnd';
import PropTypes from 'prop-types';
import PF2MScrollbar from '../PF2MScrollbar';
import styles from './styles';
import PF2MEquipIcon from '../PF2MEquipIcon';
import { DispatchDragNDropTypes } from '~/utils';

const PF2MEquipmentPool = ({
  equips,
  headerText,
  onEquipClick,
  onItemDropped,
  classes,
  customStyles,
  truckItemType,
  loadItemType,
  perforatorItemType,
  acceptDrop,
  extraInfoKey = 'load_allocation_excavator_name',
  onContextMenu,
}) => {
  const itemTypes = {
    2: truckItemType,
    4: perforatorItemType,
  };
  const [{ item }, drop] = useDrop({
    accept: (acceptDrop ? [truckItemType, loadItemType] : []),
    drop: (monitor) => {
      if (monitor.obj.id) {
        onItemDropped(monitor.obj.id, monitor.type);
      }
    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
      item: monitor.getItem(),
    }),
  });

  return (
    <div
      style={{
        width: '100%',
        ...customStyles,
      }}
      ref={drop}
    >
      <div
        style={{
          background: '#CDCDCD',
          width: '100%',
          padding: 5,
          textAlign: 'center',
        }}
      >
        <span className={classes.labelTypeSelector}>
          {headerText}
          {' '}
          (
          {equips.length}
          )
        </span>
      </div>
      <PF2MScrollbar
        className={classes.scrollbars}
        style={{
          height: 180,
          width: '100%',
        }}
      >
        <div style={{
          height: '100%',
          width: '100%',
          padding: 10,
          display: 'grid',
          gridTemplateColumns: 'repeat(8, 1fr)',
          justifyContent: 'center',
        }}
        >
          {equips.map(r => (
            <PF2MEquipIcon
              extraInfo={r[extraInfoKey]}
              key={r.equip_id}
              item={r}
              onContextMenu={r.equip_type_id === 2
                ? onContextMenu.truck : onContextMenu.loader}
              itemType={itemTypes[r.equip_type_id] ? itemTypes[r.equip_type_id] : loadItemType}
              customStyles={{
                opacity: (item && item.obj?.equip_id === r.equip_id) ? 0 : 1,
              }}
              onClick={() => onEquipClick(r)}
            />
          ))}
        </div>
      </PF2MScrollbar>
    </div>
  );
};

PF2MEquipmentPool.propTypes = {
  classes: PropTypes.object.isRequired,
  customStyles: PropTypes.object,
  headerText: PropTypes.string,
  onEquipClick: PropTypes.func,
  equips: PropTypes.array,
  onItemDropped: PropTypes.func,
  truckItemType: PropTypes.string,
  loadItemType: PropTypes.string,
  perforatorItemType: PropTypes.string,
  acceptDrop: PropTypes.bool,
  extraInfoKey: PropTypes.string,
  onContextMenu: PropTypes.shape({
    truck: PropTypes.func,
    loader: PropTypes.func,
  }),
};

PF2MEquipmentPool.defaultProps = {
  equips: [],
  customStyles: {},
  headerText: '',
  onItemDropped: () => null,
  onEquipClick: () => null,
  truckItemType: DispatchDragNDropTypes.TRUCK,
  loadItemType: DispatchDragNDropTypes.LOAD,
  perforatorItemType: DispatchDragNDropTypes.PERFORATOR,
  acceptDrop: true,
  extraInfoKey: 'load_allocation_excavator_name',
  onContextMenu: {},
};

export default withStyles(styles)(PF2MEquipmentPool);

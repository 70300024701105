
import {
  CLEAN_MESSAGES,
  SET_UPLOAD_ERROR,
} from './types';

export const clearMessages = () => async dispatch => dispatch({ type: CLEAN_MESSAGES });

export const clearFileUploadError = () => async dispatch => dispatch({
  type: SET_UPLOAD_ERROR, payload: '',
});

export const SEARCH_DAILY_PARTS = 'SEARCH_DAILY_PARTS';
export const SET_FILTERS = 'SET_FILTERS';

export const SET_SEARCH_PARAMETER = 'SET_SEARCH_PARAMETER';

export const SET_TRANSPORT_LIST = 'SET_TRANSPORT_LIST';
export const SET_HOURMETER_LIST = 'SET_HOURMETER_LIST';
export const SET_CODE_LIST = 'SET_CODE_LIST';
export const SET_CODES_RAW_LIST = 'SET_CODES_RAW_LIST';
export const SET_HOUR_MACHINE_LIST = 'SET_HOUR_MACHINE_LIST';
export const SET_LOAD_LIST = 'SET_LOAD_LIST';
export const SET_RAILROAD_LIST = 'SET_RAILROAD_LIST';
export const SET_PERFORATOR_LIST = 'SET_PERFORATOR_LIST';
export const SET_REFUELER_LIST = 'SET_REFUELER_LIST';
export const SET_UNDERGROUND_LIST = 'SET_UNDERGROUND_LIST';
export const SET_TRACTOR_LIST = 'SET_TRACTOR_LIST';
export const SET_MAINTENANCE_LIST = 'SET_MAINTENANCE_LIST';
export const SET_INFRASTRUCTURE_LIST = 'SET_INFRASTRUCTURE_LIST';
export const SET_SHAFT_LIST = 'SET_SHAFT_LIST';
export const SET_FILLIN_LIST = 'SET_FILLIN_LIST';
export const SET_SCISSOR_LIFT_LIST = 'SET_SSCISSOR_LIFT_LIST';
export const SET_ACTIVITY_LIST = 'SET_ACTIVITY_LIST';
export const SET_CRUSHER_LIST = 'SET_CRUSHER_LIST';

export const SET_TRANSPORT_VALUES = 'SET_TRANSPORT_VALUES';
export const SET_HOURMETER_VALUES = 'SET_HOURMETER_VALUES';
export const SET_ACTIVITY_VALUES = 'SET_ACTIVITY_VALUES';
export const SET_CODE_VALUES = 'SET_CODE_VALUES';
export const SET_CODES_RAW_VALUES = 'SET_CODES_RAW_VALUES';
export const SET_HOUR_MACHINE_VALUES = 'SET_HOUR_MACHINE_VALUES';
export const SET_LOAD_VALUES = 'SET_LOAD_VALUES';
export const SET_RAILROAD_VALUES = 'SET_RAILROAD_VALUES';
export const SET_PERFORATOR_VALUES = 'SET_PERFORATOR_VALUES';
export const SET_REFUELER_VALUES = 'SET_REFUELER_VALUES';
export const SET_UNDERGROUND_VALUES = 'SET_UNDERGROUND_VALUES';
export const SET_TRACTOR_VALUES = 'SET_TRACTOR_VALUES';
export const SET_MAINTENANCE_VALUES = 'SET_MAINTENANCE_VALUES';
export const SET_INFRASTRUCTURE_VALUES = 'SET_INFRASTRUCTURE_VALUES';
export const SET_SHAFT_VALUES = 'SET_SHAFT_VALUES';
export const SET_FILLIN_VALUES = 'SET_FILLIN_VALUES';
export const SET_SCISSOR_LIFT_VALUES = 'SET_SCISSOR_LIFT_VALUES';
export const SET_CRUSHER_VALUES = 'SET_CRUSHER_VALUES';

export const RESTORE_TRANSPORT_ITEM = 'RESTORE_TRANSPORT_ITEM';
export const RESTORE_HOURMETER_ITEM = 'RESTORE_HOURMETER_ITEM';
export const RESTORE_CODE_ITEM = 'RESTORE_CODE_ITEM';
export const RESTORE_HOUR_MACHINE_ITEM = 'RESTORE_HOUR_MACHINE_ITEM';
export const RESTORE_LOAD_ITEM = 'RESTORE_LOAD_ITEM';
export const RESTORE_RAILROAD_ITEM = 'RESTORE_RAILROAD_ITEM';
export const RESTORE_PERFORATOR_ITEM = 'RESTORE_PERFORATOR_ITEM';
export const RESTORE_REFUELER_ITEM = 'RESTORE_REFUELER_ITEM';
export const RESTORE_UNDERGROUND_ITEM = 'RESTORE_UNDERGROUND_ITEM';
export const RESTORE_TRACTOR_ITEM = 'RESTORE_TRACTOR_ITEM';
export const RESTORE_MAINTENANCE_ITEM = 'RESTORE_MAINTENANCE_ITEM';
export const RESTORE_INFRASTRUCTURE_ITEM = 'RESTORE_INFRASTRUCTURE_ITEM';
export const RESTORE_SHAFT_ITEM = 'RESTORE_SHAFT_ITEM';
export const RESTORE_FILLIN_ITEM = 'RESTORE_FILLIN_ITEM';
export const RESTORE_SCISSOR_LIFT_ITEM = 'RESTORE_SCISSOR_LIFT_ITEM';
export const RESTORE_CRUSHER_ITEM = 'RESTORE_CRUSHER_ITEM';

export const RESTORE_ORIGINAL_TRANSPORT = 'RESTORE_ORIGINAL_TRANSPORT';
export const RESTORE_ORIGINAL_HOURMETER = 'RESTORE_ORIGINAL_HOURMETER';
export const RESTORE_ORIGINAL_CODE = 'RESTORE_ORIGINAL_CODE';
export const RESTORE_ORIGINAL_ACTIVITY = 'RESTORE_ORIGINAL_ACTIVITY';
export const RESTORE_ORIGINAL_CODES_RAW = 'RESTORE_ORIGINAL_CODES_RAW';
export const RESTORE_ORIGINAL_HOUR_MACHINE = 'RESTORE_ORIGINAL_HOUR_MACHINE';
export const RESTORE_ORIGINAL_LOAD = 'RESTORE_ORIGINAL_LOAD';
export const RESTORE_ORIGINAL_RAILROAD = 'RESTORE_ORIGINAL_RAILROAD';
export const RESTORE_ORIGINAL_PERFORATOR = 'RESTORE_ORIGINAL_PERFORATOR';
export const RESTORE_ORIGINAL_REFUELER = 'RESTORE_ORIGINAL_REFUELER';
export const RESTORE_ORIGINAL_UNDERGROUND = 'RESTORE_ORIGINAL_UNDERGROUND';
export const RESTORE_ORIGINAL_TRACTOR = 'RESTORE_ORIGINAL_TRACTOR';
export const RESTORE_ORIGINAL_MAINTENANCE = 'RESTORE_ORIGINAL_MAINTENANCE';
export const RESTORE_ORIGINAL_INFRASTRUCTURE = 'RESTORE_ORIGINAL_INFRASTRUCTURE';
export const RESTORE_ORIGINAL_SHAFT = 'RESTORE_ORIGINAL_SHAFT';
export const RESTORE_ORIGINAL_FILLIN = 'RESTORE_ORIGINAL_FILLIN';
export const CLEAN_UNDERGROUND_LIST = 'CLEAN_UNDERGROUND_LIST';
export const CLEAN_MAINTENANCE_LIST = 'CLEAN_MAINTENANCE_LIST';
export const RESTORE_ORIGINAL_SCISSOR_LIFT = 'RESTORE_ORIGINAL_SCISSOR_LIFT';
export const RESTORE_ORIGINAL_CRUSHER = 'RESTORE_ORIGINAL_CRUSHER';

export const CREATE_TRANSPORT_ITEM = 'CREATE_TRANSPORT_ITEM';
export const CREATE_HOURMETER_ITEM = 'CREATE_HOURMETER_ITEM';
export const CREATE_ACTIVITY_ITEM = 'CREATE_ACTIVITY_ITEM';
export const CREATE_CODE_ITEM = 'CREATE_CODE_ITEM';
export const CREATE_CODES_RAW_ITEM = 'CREATE_CODES_RAW_ITEM';
export const CREATE_HOUR_MACHINE_ITEM = 'CREATE_HOUR_MACHINE_ITEM';
export const CREATE_LOAD_ITEM = 'CREATE_LOAD_ITEM';
export const CREATE_RAILROAD_ITEM = 'CREATE_RAILROAD_ITEM';
export const CREATE_PERFORATOR_ITEM = 'CREATE_PERFORATOR_ITEM';
export const CREATE_REFUELER_ITEM = 'CREATE_REFUELER_ITEM';
export const CREATE_UNDERGROUND_ITEM = 'CREATE_UNDERGROUND_ITEM';
export const CREATE_TRACTOR_ITEM = 'CREATE_TRACTOR_ITEM';
export const CREATE_INFRASTRUCTURE_ITEM = 'CREATE_INFRASTRUCTURE_ITEM';
export const CREATE_SHAFT_ITEM = 'CREATE_SHAFT_ITEM';
export const CREATE_FILLIN_ITEM = 'CREATE_FILLIN_ITEM';
export const CREATE_MAINTENANCE_ITEM = 'CREATE_MAINTENANCE_ITEM';
export const CREATE_SCISSOR_LIFT_ITEM = 'CREATE_SCISSOR_LIFT_ITEM';
export const CREATE_CRUSHER_ITEM = 'CREATE_CRUSHER_ITEM';

export const DELETE_TRANSPORT_ITEM = 'DELETE_TRANSPORT_ITEM';
export const DELETE_HOURMETER_ITEM = 'DELETE_HOURMETER_ITEM';
export const DELETE_CODE_ITEM = 'DELETE_CODE_ITEM';
export const DELETE_CODES_RAW_ITEM = 'DELETE_CODES_RAW_ITEM';
export const DELETE_HOUR_MACHINE_ITEM = 'DELETE_HOUR_MACHINE_ITEM';
export const DELETE_LOAD_ITEM = 'DELETE_LOAD_ITEM';
export const DELETE_RAILROAD_ITEM = 'DELETE_RAILROAD_ITEM';
export const DELETE_PERFORATOR_ITEM = 'DELETE_PERFORATOR_ITEM';
export const DELETE_REFUELER_ITEM = 'DELETE_REFUELER_ITEM';
export const DELETE_UNDERGROUND_ITEM = 'DELETE_UNDERGROUND_ITEM';
export const DELETE_TRACTOR_ITEM = 'DELETE_TRACTOR_ITEM';
export const DELETE_INFRASTRUCTURE_ITEM = 'DELETE_INFRASTRUCTURE_ITEM';
export const DELETE_SHAFT_ITEM = 'DELETE_SHAFT_ITEM';
export const DELETE_FILLIN_ITEM = 'DELETE_FILLIN_ITEM';
export const DELETE_MAINTENANCE_ITEM = 'DELETE_MAINTENANCE_ITEM';
export const DELETE_SCISSOR_LIFT_ITEM = 'DELETE_SCISSOR_LIFT_ITEM';
export const DELETE_ACTIVITY_ITEM = 'DELETE_ACTIVITY_ITEM';
export const DELETE_CRUSHER_ITEM = 'DELETE_CRUSHER_ITEM';

export const SET_TRANSPORT_SORT_BY = 'SET_TRANSPORT_SORT_BY';
export const SET_HOURMETER_SORT_BY = 'SET_HOURMETER_SORT_BY';
export const SET_CODE_SORT_BY = 'SET_CODE_SORT_BY';
export const SET_HOUR_MACHINE_SORT_BY = 'SET_HOUR_MACHINE_SORT_BY';
export const SET_LOAD_SORT_BY = 'SET_LOAD_SORT_BY';
export const SET_PERFORATOR_SORT_BY = 'SET_PERFORATOR_SORT_BY';
export const SET_REFUELER_SORT_BY = 'SET_REFUELER_SORT_BY';
export const SET_UNDERGROUND_SORT_BY = 'SET_UNDERGROUND_SORT_BY';
export const SET_MAINTENANCE_SORT_BY = 'SET_MAINTENANCE_SORT_BY';
export const SET_TRACTOR_SORT_BY = 'SET_TRACTOR_SORT_BY';
export const SET_INFRASTRUCTURE_SORT_BY = 'SET_INFRASTRUCTURE_SORT_BY';
export const SET_SHAFT_SORT_BY = 'SET_SHAFT_SORT_BY';
export const SET_FILLIN_SORT_BY = 'SET_FILLIN_SORT_BY';
export const SET_SCISSOR_LIFT_SORT_BY = 'SET_SCISSOR_LIFT_SORT_BY';

export const SET_EQUIPMENT_TYPES_TABS = 'SET_EQUIPMENT_TYPES_TABS';
export const SET_DAILY_PARTS_TABS = 'SET_DAILY_PARTS_TABS';

export const SET_IS_SUBMITTING_DATA = 'SET_IS_SUBMITTING_DATA';
export const SET_IS_LOADING_LIST = 'SET_IS_LOADING_LIST';
export const SET_ACTIVITY_UPLOAD_LOADING = 'ACTIVITY_UPLOAD_LOAD';

export const SET_SELECTED_CODE_FOR_MIGRATIONS = 'SET_SELECTED_CODE_FOR_MIGRATIONS';

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import {
  Card, CardBody, CardHeader, CardIcon,
} from '~/components/Card';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { setTransportValues } from '~/store/dailyparts/actions';
import PF2MDataTable from '~/components/PF2MDataTable';
import PF2MDiscardChangesButton from '~/components/PF2MDiscardChangesButton';
import PF2MFinalizeButton from '~/components/PF2MFinalizeButton';
import {
  diffTwoDateTimeStr, formatDate,
  formatDateToServerFormat, formatTime, parseDate, parseTime, parseTimestamp,
} from '~/utils/moment';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import { useDispatch } from 'react-redux';
import { AccessTime } from '@material-ui/icons';
import styles from './styles';
import { convertFormatedDateStringToTimestamp } from '~/utils';

const TransportCycleTimesModal = (
  {
    handleClose,
    selectedTransportReport,
    onChangeSelectedTransportReport,
  },
) => {
  const { t: translate } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cycleEntryTimeData, setCycleEntryTimeData] = useState([]);
  const [isModalDiscardOpen, setIsModalDiscardOpen] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const dispatch = useDispatch();

  const getCodeTime = ({
    startDate,
    startTime,
    endDate,
    endTime,
    duration,
  }) => {
    if (!startDate || !startTime || !endDate || !endTime) {
      return 0;
    }

    const diff = diffTwoDateTimeStr(startDate, startTime, endDate, endTime);

    const codeTime = diff / 1000 / 60 - duration;

    return Number(codeTime.toFixed(2));
  };

  const requestDate = (item, preffix, suffix) => {
    const date = item[`${preffix}_${suffix}`];
    return date && parseTimestamp(date);
  };

  const resetCycleEntryTimeData = useCallback(() => {
    const prefixFieldsCycleEntryTime = [
      'empty',
      'load_queue',
      'load_maneuver',
      'load',
      'full',
      'unload_queue',
      'unload_maneuver',
      'unload',
    ];

    const newCycleEntryTimeData = prefixFieldsCycleEntryTime.map((prefix, index) => {
      const id = index;
      const step = translate(`common:transport_cycle_${prefix}`);
      const startDate = selectedTransportReport[`${prefix}_start_time`] && formatDate(requestDate(selectedTransportReport, prefix, 'start_time'));
      const startTime = formatTime(requestDate(selectedTransportReport, prefix, 'start_time'));
      const endDate = selectedTransportReport[`${prefix}_end_time`] && formatDate(requestDate(selectedTransportReport, prefix, 'end_time'));
      const endTime = formatTime(requestDate(selectedTransportReport, prefix, 'end_time'));
      const duration = selectedTransportReport[`${prefix}_time`];

      return {
        id, prefix, step, startDate, startTime, endDate, endTime, duration,
      };
    });

    setHasChanges(false);
    setCycleEntryTimeData(newCycleEntryTimeData);
  }, [selectedTransportReport, translate]);

  useEffect(() => resetCycleEntryTimeData(), [resetCycleEntryTimeData]);

  const columns = [
    {
      editable: 'never',
      title: translate('common:Step'),
      field: 'step',
    },
    {
      editable: 'always',
      title: translate('common:InitialDate'),
      field: 'startDate',
      type: 'date',
    },
    {
      editable: 'always',
      title: translate('common:InitialHour'),
      field: 'startTime',
      customType: 'time',
    },
    {
      editable: 'always',
      title: translate('common:FinalDate'),
      field: 'endDate',
      type: 'date',
    },
    {
      editable: 'always',
      title: translate('common:FinalHour'),
      field: 'endTime',
      customType: 'time',
    },
    {
      editable: 'always',
      title: translate('common:Duration'),
      field: 'duration',
      render: cycleStep => (Number(cycleStep.duration).toFixed(2) || 0),
    },
    {
      editable: 'never',
      title: translate('common:TotalTimeInCode'),
      field: 'codeTime',
      render: cycleStep => getCodeTime(cycleStep),
    },
  ];

  const handleOnChange = (cycleEntryTimeDataToChange) => {
    setCycleEntryTimeData((currentCycleEntryTimeData) => {
      const mapDataToChange = cycleEntryTimeDataToChange
        .reduce((acc, item) => ({ ...acc, [item.id]: item }), {});

      return currentCycleEntryTimeData.map((updated) => {
        const currentCycle = mapDataToChange[updated.id];
        if (!currentCycle) {
          return updated;
        }
        let { startDate, endDate } = currentCycle;
        if (startDate?.getDate !== undefined) {
          startDate = formatDateToServerFormat(startDate);
        }
        if (endDate?.getDate !== undefined) {
          endDate = formatDateToServerFormat(endDate);
        }
        return {
          ...currentCycle,
          start_date: startDate || '',
          end_date: endDate || '',
        };
      });
    });
    setHasChanges(true);
  };

  const handleOnApply = () => {
    const newTransportReport = {
      ...selectedTransportReport,
      tableData: undefined,
    };

    cycleEntryTimeData.forEach(({
      prefix, startDate, startTime, endDate, endTime, duration,
    }) => {
      newTransportReport[`${prefix}_start_time`] = convertFormatedDateStringToTimestamp(`${formatDate(parseDate(startDate))} ${formatTime(parseTime(startTime))}`) / 1000;
      newTransportReport[`${prefix}_end_time`] = convertFormatedDateStringToTimestamp(`${formatDate(parseDate(endDate))} ${formatTime(parseTime(endTime))}`) / 1000;
      newTransportReport[`${prefix}_time`] = duration;
    });

    dispatch(setTransportValues(newTransportReport));
    onChangeSelectedTransportReport(newTransportReport);
    handleClose();
  };

  const handleConfirmChanges = () => {
    setIsModalOpen(false);
    if (hasChanges) {
      handleOnApply();
    }
  };

  const handleOnDiscardChanges = () => {
    if (hasChanges) {
      setIsModalDiscardOpen(true);
    }
  };

  const handleConfirmDiscardChanges = () => {
    resetCycleEntryTimeData();
    setIsModalDiscardOpen(false);
    setHasChanges(false);
  };

  return (
    <div>
      <Card>
        <CardHeader icon>
          <CardIcon color="warning">
            <AccessTime />
          </CardIcon>
          <CardIcon
            onClick={handleClose}
            style={{
              float: 'right',
              cursor: 'pointer',
              background: 'darkgray',
              borderRadius: 50,
              boxShadow: '10px 10px 50px gray',
            }}
          >
            <CloseIcon />
          </CardIcon>
        </CardHeader>
        <CardBody>
          <Typography
            variant="h4"
            align="center"
            gutterBottom
          >
            {translate('common:CycleStageTimes')}
          </Typography>
          <PF2MDataTable
            options={{
              search: true,
              selection: true,
              hideDelete: true,
              hideAdd: true,
            }}
            onChange={handleOnChange}
            onDiscard={handleOnDiscardChanges}
            onApply={() => setIsModalOpen(true)}
            columns={columns}
            data={cycleEntryTimeData.length > 0 ? cycleEntryTimeData : []}
          />

        </CardBody>

        <Grid item style={{ alignSelf: 'center' }}>
          <PF2MDiscardChangesButton
            onClick={handleOnDiscardChanges}
          />
          <PF2MFinalizeButton onClick={() => setIsModalOpen(true)} />
        </Grid>

      </Card>

      <PF2MAlertDialog
        hasCancel
        confirmText={translate(hasChanges ? 'common:Confirm' : 'common:Ok')}
        description={translate(hasChanges ? 'common:ConfirmChanges' : 'common:ThereAreNoChanges')}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirmChanges}
      />

      <PF2MAlertDialog
        hasCancel
        confirmText={translate('common:Discard')}
        description={translate('common:DiscardChanges')}
        open={isModalDiscardOpen}
        onClose={() => setIsModalDiscardOpen(false)}
        onConfirm={handleConfirmDiscardChanges}
      />
    </div>
  );
};

TransportCycleTimesModal.propTypes = {
  handleClose: PropTypes.func,
  selectedTransportReport: PropTypes.object,
  onChangeSelectedTransportReport: PropTypes.func,
};

TransportCycleTimesModal.defaultProps = {
  handleClose: () => {},
  selectedTransportReport: {},
  onChangeSelectedTransportReport: () => {},
};

export default withStyles(styles)(TransportCycleTimesModal);

import io from 'socket.io-client';
import { getTenant } from '~/services/multiTenant';

const socketURL = process.env.REACT_APP_SOCKET_URL;
const tenant = getTenant();

const socketBuilder = (userToken, namespace = '/') => {
  let fullUrl;
  try {
    fullUrl = new URL(namespace, socketURL).toString();
  } catch (err) {
    console.error(`Invalid URL ${socketURL}`);
    return null;
  }
  return io(fullUrl, {
    reconnection: true,
    reconnectionDelayMax: 10000,
    query: {
      token: userToken,
    },
    extraHeaders: {
      'x-tenant-id': tenant,
    },
  });
};

export default socketBuilder;

export const defaultValue = {
  equip_group: '0',
  equip_id: '0',
  equip_type: '0',
  exception_type: '0',
  hourmeter_final: '0',
  hourmeter_final_3: '0',
  hourmeter_final_4: '0',
  hourmeter_final_5: '0',
  hourmeter_final_6: '0',
  hourmeter_final_7: '0',
  hourmeter_final_8: '0',
  hourmeter_final_9: '0',
  hourmeter_final_10: '0',
  hourmeter_start: '0',
  hourmeter_start_3: '0',
  hourmeter_start_4: '0',
  hourmeter_start_5: '0',
  hourmeter_start_6: '0',
  hourmeter_start_7: '0',
  hourmeter_start_8: '0',
  hourmeter_start_9: '0',
  hourmeter_start_10: '0',
  kilometer_final: '0',
  kilometer_start: '0',
  operator_group: '0',
  operator_id: '0',
  team_group: '0',
  team_id: '0',
  turn: '0',
  turn_group: '0',
};

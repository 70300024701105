import React, { useState, useEffect } from 'react';
import {
  TableCell,
  TableRow,
  Fab,
  withStyles,
  TextField,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreIcon from '@material-ui/icons/Restore';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { KeyboardDatePicker } from '@material-ui/pickers';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import PF2MSearchSelect from '~/components/PF2MSearchSelect';
import PF2MMaskTextField from '~/components/PF2MMaskTextField';
import styles from './styles';
import {
  formatDateToServerFormat,
  parseDateToServerFormat,
} from '~/utils/moment';
import { parseFloat } from '~/utils';

const ListRow = ({
  data,
  classes,
  onEdit,
  onDelete,
  onRestore,
}) => {
  const projectGroups = useSelector(state => state.underground.projectGroups);
  const codeTypes = useSelector(state => state.underground.codeTypes);
  const codeGroups = useSelector(state => state.underground.codeGroups);
  const codes = useSelector(state => state.underground.codes);
  const unitOperationGroups = useSelector(state => state.underground.unitOperationGroups);
  const unitOperations = useSelector(state => state.underground.unitOperations);
  const priorities = useSelector(state => state.underground.priorities);
  const elements = useSelector(state => state.manager.elements);
  const subElements = useSelector(state => state.manager.subElements);
  const exceptionTypes = useSelector(state => state.manager.exceptionTypes);
  const turns = useSelector(state => state.manager.turns);
  const teamGroups = useSelector(state => state.manager.teamGroups);
  const teams = useSelector(state => state.manager.teams);
  const equipmentTypes = useSelector(state => state.manager.equipmentTypes);
  const equipmentsGroups = useSelector(state => state.manager.equipmentsGroups);
  const equipments = useSelector(state => state.manager.equipments);
  // const user = useSelector(state => state.auth.user);
  const undergroundTurnGroup = useSelector(state => state.underground.turnGroup);
  const [filteredSubElements, setFilteredSubElements] = useState(subElements);
  const [filteredTeams, setFilteredTeams] = useState([]);
  const [idTeamGroup, setIdTeamGroup] = useState([]);
  const { t: translate } = useTranslation();

  useEffect(() => {
    const filteredTeamGroups = teamGroups.filter(t => t.id_turn_group === undergroundTurnGroup)
      .map(t => t.id);
    setFilteredTeams(teams.filter(t => filteredTeamGroups.includes(t.id_team_group)));

    setIdTeamGroup(() => {
      const tg = teams.find(t => t.id === data.id_team);
      return tg ? tg.id_team_group : 0;
    });
  }, [data.id_turn, teams, teamGroups, undergroundTurnGroup, data.id_team]);

  const renderExceptionType = (idExceptionType) => {
    const { name = '' } = exceptionTypes.find(e => e.id === idExceptionType) || {};
    return name;
  };

  const handleChange = (item = [], name = '') => (e) => {
    const value = parseFloat(e.target.value) || 0;
    onEdit(item, name, value);
  };

  const handleSelectChange = (item = [], name = '') => e => onEdit(item, name, e.target.value);

  const handleCheckboxChange = (value, name = '') => onEdit(value, name, !value[name]);


  const handleProjectGroup = (item = [], name = '') => (e) => {
    onEdit(item, name, e.target.value);
    if (e.target.value === 0) {
      setFilteredSubElements(subElements);
    } else {
      setFilteredSubElements(subElements.filter(s => s.id_project_group === e.target.value));
    }
  };

  const handleElement = (item = [], name = '') => (e) => {
    onEdit(item, name, e.target.value);
    if (e.target.value > 0) {
      setFilteredSubElements(subElements.filter(s => s.id_element === e.target.value));
    }
  };

  const renderNumberCell = (name, value = 0, item) => (
    <NumberFormat
      customInput={TextField}
      value={parseFloat(value)}
      InputProps={{ classes: { input: classes.input } }}
      onBlur={handleChange(item, name)}
      disabled={item.update_status === 'D'}
      decimalScale={2}
    />
  );

  const renderTextCell = (name, value = '', item) => (
    <TextField
      value={value}
      InputProps={{ classes: { input: classes.textInput } }}
      onChange={e => onEdit(item, name, e.target.value)}
      disabled={item.update_status === 'D'}
    />
  );

  // handle clearing outside if value can be changed outside of the component
  const handleMask = value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : []);

  const renderDatePicker = (d, attr) => (
    <KeyboardDatePicker
      clearable
      mask={handleMask}
      format={translate('date:DateFormat')}
      disableFuture
      autoOk
      onChange={e => onEdit(d, attr, formatDateToServerFormat(e, true))}
      value={d[attr] ? parseDateToServerFormat(d[attr], false, true) : ''}
      invalidDateMessage={translate('validation:InvalidDate')}
    />
  );

  return (
    <TableRow>
      <TableCell className={classes.tableCell}>
        {renderDatePicker(data, 'start_date')}
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MMaskTextField
          mask="99:99:99"
          value={data.start_time}
          onChange={e => onEdit(data, 'start_time', e.target.value)}
        />
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderDatePicker(data, 'end_date')}
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MMaskTextField
          mask="99:99:99"
          value={data.end_time}
          onChange={e => onEdit(data, 'end_time', e.target.value)}
        />
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.id_project_group}
          onChange={handleProjectGroup(data, 'id_project_group')}
          disabled={data.update_status === 'D'}
        >
          {projectGroups
            .map(e => ({ value: e.id, label: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.id_element}
          onChange={handleElement(data, 'id_element')}
          disabled={data.update_status === 'D'}
        >
          {elements
            .sort((i, k) => (i.name < k.name ? 1 : -1)) // alphanumeric sorting
            .sort(i => i.active * -1) // move inactive items to the end of the array
            .map(e => ({ value: e.id, label: `${e.name} ${!e.active ? `- ${translate('common:Inactive')}` : ''}` }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.id_element_point}
          onChange={handleSelectChange(data, 'id_element_point')}
          disabled={data.update_status === 'D'}
        >
          {filteredSubElements
            .sort((i, k) => (i.name < k.name ? 1 : -1)) // alphanumeric sorting
            .sort(i => i.active * -1) // move inactive items to the end of the array
            .map(e => ({ value: e.id, label: `${e.name} ${!e.active ? `- ${translate('common:Inactive')}` : ''}` }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.id_unitary_operation_group}
          onChange={handleSelectChange(data, 'id_unitary_operation_group')}
          disabled={data.update_status === 'D'}
        >
          {unitOperationGroups
            .map(e => ({ value: e.id, label: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.id_unitary_operation}
          onChange={handleSelectChange(data, 'id_unitary_operation')}
          disabled={data.update_status === 'D'}
        >
          {unitOperations
            .filter(d => d.id_unitary_operation_group === 0
              || d.id_unitary_operation_group === data.id_unitary_operation_group)
            .map(e => ({ value: e.id, label: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderNumberCell('unitary_operation_time', data.unitary_operation_time, data)}
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.id_code_type_ug}
          onChange={handleSelectChange(data, 'id_code_type_ug')}
          disabled={data.update_status === 'D'}
        >
          {codeTypes
            .map(e => ({ value: e.id, label: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.id_code_group_ug}
          onChange={handleSelectChange(data, 'id_code_group_ug')}
          disabled={data.update_status === 'D'}
        >
          {codeGroups
            .filter(d => data.id_code_type_ug === 0
              || d.id_code_type_ug === data.id_code_type_ug)
            .map(e => ({ value: e.id, label: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.id_code_ug}
          onChange={handleSelectChange(data, 'id_code_ug')}
          disabled={data.update_status === 'D'}
        >
          {codes
            .filter(d => data.id_code_group_ug === 0
              || d.id_code_group_ug === data.id_code_group_ug)
            .map(e => ({ value: e.id, label: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderNumberCell('code_time', data.code_time, data)}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderNumberCell('estimated_advance', data.estimated_advance, data)}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderNumberCell('measured_advance', data.measured_advance, data)}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderNumberCell('cicle', data.cicle, data)}
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.id_equipment_type}
          onChange={handleSelectChange(data, 'id_equipment_type')}
          disabled={data.update_status === 'D'}
        >
          {equipmentTypes
            .map(e => ({ value: e.id, label: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.id_equipment_groups}
          onChange={handleSelectChange(data, 'id_equipment_groups')}
          disabled={data.update_status === 'D'}
        >
          {equipmentsGroups
            .filter(e => data.id_equipment_type === 0
              || e.id_equipament === data.id_equipment_type)
            .map(e => ({ value: e.id, label: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.id_group_equipament_links}
          onChange={handleSelectChange(data, 'id_group_equipament_links')}
          disabled={data.update_status === 'D'}
        >
          {equipments
            .filter(e => data.id_equipment_groups === 0
              || e.id_group === data.id_equipment_groups)
            .map(e => ({ value: e.id, label: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.priority}
          onChange={handleSelectChange(data, 'priority')}
          disabled
        >
          {priorities
            .filter(e => data.id_element_point === e.id_element_point)
            .map(e => ({ value: e.id, label: e.priority }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderTextCell('comment', data.comment, data)}
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.id_turn}
          onChange={handleSelectChange(data, 'id_turn')}
        >
          {[...turns.filter(t => t.id_turn_group === undergroundTurnGroup)]
            .map(e => ({ value: e.id, label: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={idTeamGroup}
          onChange={handleSelectChange(data, 'id_team_group')}
        >
          {[...teamGroups.filter(t => t.id_turn_group === undergroundTurnGroup)]
            .map(e => ({ value: e.id, label: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.id_team}
          onChange={handleSelectChange(data, 'id_team')}
        >
          {[...filteredTeams.filter(t => !data.id_team_group
          || t.id_team_group === data.id_team_group)]
            .map(e => ({ value: e.id, label: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.pointer}
          onChange={handleSelectChange(data, 'pointer')}
        >
          {[{ id: data.pointer, name: data.pointer_name }]
            .map(e => ({ value: e.id, label: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <Checkbox
          checked={data.standby}
          onChange={() => handleCheckboxChange(data, 'standby')}
          disabled={data.standby === null || data.update_status === 'D'}
        />
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderExceptionType(data.exception_type)}
      </TableCell>
      <TableCell style={{
        position: 'sticky',
        right: 0,
        height: 48,
      }}
      >
        {data.isDirty ? (
          <Fab
            size="small"
            color="primary"
            aria-label="Restore"
            onClick={() => onRestore(data)}
          >
            <RestoreIcon />
          </Fab>
        ) : (
          <Fab
            size="small"
            color="primary"
            aria-label="Delete"
            onClick={() => onDelete(data)}
          >
            <DeleteIcon />
          </Fab>
        )}
      </TableCell>
    </TableRow>
  );
};

ListRow.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onRestore: PropTypes.func,
};

ListRow.defaultProps = {
  data: {},
  onEdit: null,
  onDelete: null,
  onRestore: null,
};

export default withStyles(styles)(ListRow);

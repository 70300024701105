import React, { useState } from 'react';
import PropTypes from 'prop-types';
import GetApp from '@material-ui/icons/GetApp';
import SaveAlt from '@material-ui/icons/SaveAlt';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import PF2MButton from '~/components/PF2MButton';
import { useTranslation } from 'react-i18next';
import styles from './styles';

const Header = ({
  classes,
  getTemplateAction,
  importAction,
  exportAction,
  fileUploadError,
}) => {
  const [fileUploadRef, setFileUploadRef] = useState(null);
  const { t: translate } = useTranslation();

  const handleChange = (event) => {
    const currentFile = event.target.files[0];
    importAction(currentFile);
    fileUploadRef.value = null;
  };

  return (
    <>
      <div style={{ paddingBottom: 10, display: 'flex' }}>
        {getTemplateAction && (
          <PF2MButton
            onClick={() => getTemplateAction()}
          >
            <GetApp className={classes.icon} />
            {translate('common:InsertNewEntries')}
          </PF2MButton>
        )}
        <div>
          {importAction && (
            <>
              <PF2MButton
                onClick={() => fileUploadRef.click()}
              >
                <SaveAlt className={classes.icon} />
                {translate('common:ImportSheet')}
              </PF2MButton>
              <input
                ref={ref => setFileUploadRef(ref)}
                type="file"
                style={{ display: 'none' }}
                onChange={handleChange}
              />
            </>
          )}
        </div>
        {exportAction && (
          <PF2MButton
            onClick={() => exportAction()}
          >
            <GetApp className={classes.icon} style={{ transform: 'rotate(180deg)' }} />
            {translate('common:EditEntries')}
          </PF2MButton>
        )}
      </div>
      <div style={{ paddingLeft: 250 }}>
        <p style={{
          color: '#EC3337',
          fontFamily: 'Roboto',
          fontSize: 12,
        }}
        >
          {fileUploadError}
        </p>
      </div>
    </>
  );
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  getTemplateAction: PropTypes.func,
  importAction: PropTypes.func,
  exportAction: PropTypes.func,
  fileUploadError: PropTypes.string,
};

Header.defaultProps = {
  getTemplateAction: null,
  importAction: null,
  exportAction: null,
  fileUploadError: '',
};


const mapStateToProps = state => ({
  fileUploadError: state.errors.fileUploadError,
});

export default connect(mapStateToProps, {})(withStyles(styles)(Header));

const styles = {
  grow: {
    flexGrow: 1,
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
  },
  f2mLogo: {
    padding: 5,
  },
  title: {
    color: '#FFC300',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: 34,
    '&:hover': {
      underline: 'none',
      color: '#FFC300',
    },
  },
  secondaryTitle: {
    color: '#F2AA00',
  },
  separator: {
    marginLeft: 10,
    marginRight: 10,
  },
  active: {
    color: '#F2AA00',
  },
  appBar: {
    backgroundColor: '#111110',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    overflowX: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
};

export default styles;

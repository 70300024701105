import React from 'react';
import { withStyles, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import ReactInputMask from 'react-input-mask';
import styles from './styles';

const PF2MMaskTextField = ({
  classes, mask, disabled, ...props
}) => (
  <ReactInputMask
    mask={mask}
    {...props}
  >
    {inputProps => (
      <TextField
        InputProps={{
          classes: { input: classes.inputMask },
          disabled,
        }}
        {...inputProps}
      />
    )}
  </ReactInputMask>
);

PF2MMaskTextField.propTypes = {
  classes: PropTypes.object.isRequired,
  mask: PropTypes.string,
  disabled: PropTypes.bool,
};

PF2MMaskTextField.defaultProps = {
  mask: '99:99:99',
  disabled: false,
};

export default withStyles(styles)(PF2MMaskTextField);

const styles = {
  blackLabel: {
    background: 'black',
  },
  brownLabel: {
    background: 'brown',
  },
  greenLabel: {
    background: 'green',
  },
  limeLabel: {
    background: 'lime',
  },
  redLabel: {
    background: 'red',
  },
  yellowLabel: {
    background: 'orange',
  },
  grayLabel: {
    background: 'grey',
  },
  purpleLabel: {
    background: 'purple',
  },
  blueLabel: {
    background: 'blue',
  },
  labelTypeSelector: {
    borderRadius: 5,
    color: 'white',
    fontFamily: 'Barlow Condensed',
    fontWeight: 600,
    fontSize: 16,
    textTransform: 'uppercase',
    width: '100%',
    textAlign: 'center',
  },
  warnImage: {
    position: 'absolute',
    top: 0,
    left: 48,
    height: 'auto',
  },
  image: {
    position: 'relative',
    top: 0,
    left: 0,
  },
  imageContainer: {
    position: 'relative',
    top: 0,
    left: 0,
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: 20,
    color: '#647886',
  },
  equipmentIconContainer: {
    width: 70,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    cursor: 'pointer',
  },
};

export default styles;

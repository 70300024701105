import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import PF2MTable from '~/components/PF2MTable';
import Footer from '~/pages/Manager/Footer';
import { useTranslation } from 'react-i18next';
import i18n from '~/i18n';
import styles from '../styles';
import {
  getMaterials,
  getEquipmentsGroups,
  getWeighings,
  resetWeighings,
  updateWeighings,
  getTurnsByEquipmentGroup,
  setWeighingValue,
  exportWeighings,
  importWeighings,
} from '~/store/manager/actions';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import Header from '~/pages/Manager/Header';

const INITIAL_COLUMNS = [
  {
    field: 'id_material_name',
    title: i18n.t('common:Material').toUpperCase(),
    width: '200px',
  },
  {
    field: 'eq_group_name',
    title: i18n.t('common:EquipmentGroup').toUpperCase(),
  },
  {
    field: 'percentual_period_aleatory',
    title: i18n.t('common:AleatoryDurationPercentage').toUpperCase(),
    editable: true,
    width: '100px',
    type: 'number',
  },
  {
    field: 'probability_in_aleatory',
    title: i18n.t('common:AleatoryProbabilityPercentage').toUpperCase(),
    editable: true,
    width: '100px',
    type: 'number',
  },
];

const WeighingsTab = ({ classes }) => {
  const dispatch = useDispatch();
  const weighings = useSelector(state => state.manager.weighings);
  const materials = useSelector(state => state.manager.materials);
  const equipmentsGroups = useSelector(state => state.manager.equipmentsGroups);
  const turns = useSelector(state => state.manager.turns);
  const isWeighingsDirty = useSelector(state => state.manager.isWeighingsDirty);

  const [dataLoaded, setDataLoaded] = useState(false);
  const [equipmentGroup, setEquipmentGroup] = useState(0);
  const [material, setMaterial] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [columns, setColumns] = useState([]);
  const { t: translate } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      dispatch(getEquipmentsGroups());
      await dispatch(getMaterials());
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch]);

  useEffect(() => {
    async function getData() {
      dispatch(getTurnsByEquipmentGroup(equipmentGroup));
      await dispatch(getWeighings(equipmentGroup, material > 0 ? material : null));
    }

    if (equipmentGroup > 0) {
      getData();
    } else {
      setColumns(INITIAL_COLUMNS);
      setFilteredData([]);
    }
  }, [equipmentGroup, dispatch, material]);

  useEffect(() => {
    if (equipmentGroup > 0) {
      const filtered = weighings.filter(e => (material === 0 || e.id_material === material)
        && (equipmentGroup === 0 || e.eq_group === equipmentGroup));

      if (turns.length > 0) {
        const newColumns = turns.map(s => ({
          field: `shiftScale_${s.id}`,
          title: s.name || `${translate('common:Shift')} ${s.id}`,
          editable: true,
          width: '100px',
          align: 'left',
          type: 'number',
        }));

        setColumns([...INITIAL_COLUMNS, ...newColumns]);
      } else {
        setColumns(INITIAL_COLUMNS);
      }
      setFilteredData(filtered);
    }
  }, [weighings, equipmentGroup, material, turns, translate]);

  return (
    <div className={classes.tabContainer}>
      <Header
        importAction={file => dispatch(importWeighings(file))}
        exportAction={() => dispatch(exportWeighings())}
      />
      <hr />
      <div className={classes.containerTypeSelector}>
        <div style={{ display: 'flex', alignItems: 'baseline', margin: 10 }}>
          <p className={classes.labelTypeSelector}>
            {`${translate('common:FilterByEquipmentGroup')}:`}
          </p>
          <PF2MSearchSelectOutlined
            value={equipmentGroup}
            onChange={e => setEquipmentGroup(e.target.value)}
            placeholder={null}
          >
            {[{ value: 0, label: translate('common:Select') }]
              .concat(...equipmentsGroups
                .filter(e => e.id_equipament === 2)
                .map(e => ({ value: e.id, label: e.name })))}
          </PF2MSearchSelectOutlined>
        </div>
        <div style={{ display: 'flex', alignItems: 'baseline', margin: 10 }}>
          <p className={classes.labelTypeSelector}>
            {`${translate('common:FilterByMaterial')}:`}
          </p>
          <PF2MSearchSelectOutlined
            value={material}
            onChange={e => setMaterial(e.target.value)}
            placeholder={null}
          >
            {[{ value: 0, label: translate('common:Select') }]
              .concat(...materials
                .map(e => ({ value: e.id, label: e.name })))}
          </PF2MSearchSelectOutlined>
        </div>
      </div>
      <PF2MTable
        data={filteredData}
        columns={columns}
        className={classes.grid}
        updateItem={(row, field, value) => dispatch(setWeighingValue(row, field, value))}
      />
      <Footer
        isDirty={isWeighingsDirty}
        discard={() => dispatch(resetWeighings())}
        sendData={() => {
          dispatch(updateWeighings(weighings));
          setMaterial(0);
          setEquipmentGroup(0);
        }}
      />
    </div>
  );
};

WeighingsTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(WeighingsTab);

import { managerGridHeight } from '~/utils';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#E6E7E8',
    height: managerGridHeight,
    padding: 10,
  },
  icon: {
    marginRight: 10,
  },
  button: {
    color: '#FFF',
    margin: 5,
  },
  tabContainer: {
    borderRadius: 5,
    borderTopLeftRadius: 0,
    backgroundColor: 'white',
    padding: 10,
  },
  grid: {
    width: '100%',
    overflowY: 'auto',
    height: 'calc(100vh - 52px - 52px - 180px - 68px)',
  },
  containerTypeSelector: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'center',
    width: '100%',
    padding: 0,
  },
  labelTypeSelector: {
    color: '#647886',
    fontFamily: 'Barlow Condensed',
    fontWeight: 600,
    fontSize: 18,
    textTransform: 'uppercase',
    marginRight: 10,
  },
  typeSelector: {
    height: 40,
    fontFamily: 'Roboto',
    fontWeight: 300,
    fontSize: 14,
    color: '#CDCDCD',
  },
  label: {
    color: '#647886',
    fontSize: 12,
    fontFamily: 'Roboto',
    marginBottom: 5,
  },
  itens: {
    width: '100%',
    height: 'calc(100vh - 280px)',
  },
  scrollbars: {
    border: '1px solid #CDCDCD',
    borderRadius: 4,
    boxSizing: 'border-box',
    minHeight: 260,
  },
};

export default styles;

/* eslint-disable camelcase */
import React from 'react';
import {
  withStyles,
  FormControl,
  FormLabel,
  FormGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import PF2MAddButton from '~/components/PF2MAddButton';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';

function Header({
  classes,
  add,
  levels,
  modules,
  adminModules,
  userLevel,
  webUsers,
}) {
  const { t: translate } = useTranslation();

  const reportsLevelManagement = webUsers.filter(x => x.reports_level === 1).length > 0;

  const INITIAL_VALUES = {
    email: '',
    username: '',
    status: 1,
    accessLevel: 0,
    accessReportsLevel: reportsLevelManagement ? 0 : 5,
    modules: [],
  };

  if (webUsers.length === 0) return <></>;

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      validate={(values) => {
        const errors = {};
        const {
          email,
          username,
          accessLevel,
          accessReportsLevel,
        } = values;

        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        re.test(String(email).toLowerCase());

        if (!email) {
          errors.email = 'Required';
        } else if (!re.test(String(email).toLowerCase())) {
          errors.email = 'Insert a valid email address';
        }

        if (accessLevel === 0 || accessReportsLevel === 0) {
          errors.accessLevel = 'Required';
        }
        if (username.length < 1) {
          errors.username = 'Required';
        }

        return errors;
      }}
      onSubmit={async (values, { resetForm }) => {
        const {
          username,
          email,
          accessLevel,
          accessReportsLevel,
        } = values;

        await add({
          username,
          email,
          level: accessLevel,
          reports_level: accessReportsLevel,
          active: 1,
          modules: values.modules,
          active_name: 'Ativo',
        });
        resetForm();
      }}
    >
      {({
        isSubmitting,
        errors,
        isValid,
      }) => (
        <Form>
          <div style={{
            display: 'flex',
            flexFlow: 'row nowrap',
            width: '100%',
          }}
          >
            <FormControl style={{ }}>
              <FormLabel className={classes.formLabel}>
                {`${translate('common:User')}:`}
              </FormLabel>
              <FormGroup>
                <Field
                  type="text"
                  name="username"
                  className={classnames('form-control', errors.username ? classes.fieldError : classes.field)}
                />
              </FormGroup>
            </FormControl>
            <FormControl style={{ flexGrow: 1, marginLeft: 20, marginRight: 20 }}>
              <FormLabel className={classes.formLabel}>
                {`${translate('common:Email')}:`}
              </FormLabel>
              <FormGroup>
                <Field
                  type="text"
                  name="email"
                  className={classnames('form-control', errors.email ? classes.fieldError : classes.field)}
                />
              </FormGroup>
            </FormControl>
            <FormControl style={{ }}>
              <FormLabel className={classes.formLabel}>
                {`${translate(`common:${ reportsLevelManagement ? 'PlatformLevel' : 'Level'}`)}:`}
              </FormLabel>
              <FormGroup>
                <Field
                  name="accessLevel"
                  render={({ field, form }) => (
                    <PF2MSearchSelectOutlined
                      {...field}
                      className={
                        classnames(errors.accessLevel ? classes.fieldError : classes.field)
                      }
                      onChange={e => form.setFieldValue('accessLevel', e.target.value)}
                    >
                      {[{ value: 0, label: translate('common:Select') }]
                        .concat(...levels
                          .filter(x => x.id < 4)
                          .map(e => ({ value: e.id, label: e.name })))}
                    </PF2MSearchSelectOutlined>
                  )}
                />
              </FormGroup>
            </FormControl>
            <FormControl style={reportsLevelManagement ? { marginLeft: 20 } : { display: 'none' }}>
              <FormLabel className={classes.formLabel}>
                {`${translate('common:ReportsLevel')}:`}
              </FormLabel>
              <FormGroup>
                <Field
                  name="accessReportsLevel"
                  render={({ field, form }) => (
                    <PF2MSearchSelectOutlined
                      {...field}
                      className={
                        classnames(errors.accessLevel ? classes.fieldError : classes.field)
                      }
                      onChange={e => form.setFieldValue('accessReportsLevel', e.target.value)}
                      value={reportsLevelManagement ? field.value : 5}
                    >
                      {[{ value: 0, label: translate('common:Select') }]
                        .concat(...levels
                          .map(e => ({ value: e.id, label: e.name })))}
                    </PF2MSearchSelectOutlined>
                  )}
                />
              </FormGroup>
            </FormControl>

          </div>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexFlow: 'row wrap',
            width: '100%',
          }}
          >
            <FormControl style={{ margin: '0 auto', marginTop: 20, flexGrow: 1 }}>
              <FormLabel className={classes.formLabel}>
                {`${translate((reportsLevelManagement ? 'PlatformModules' : 'Modules'))}:`}
              </FormLabel>
              <FormGroup>
                <Field
                  name="modules"
                  render={({ field, form }) => (
                    <PF2MSearchSelectOutlined
                      className={
                        classnames(errors.accessLevel ? classes.fieldError : classes.field)
                      }
                      onChange={(e) => {
                        form.setFieldValue('modules', [...e.target.value.map(r => r.value)]);
                      }}
                      value={field.value}
                      multiple
                      hideSelectedOptions={false}
                      closeMenuOnSelect={false}
                      placeholder={translate('common:Select')}
                    >
                      {
                        userLevel === 1
                          ? adminModules.map(e => ({ value: e.name, label: translate(`common:${e.caption}`) }))
                          : modules.map(e => ({ value: e.module_value, label: translate(`common:${e.module_name}`) }))
                      }
                    </PF2MSearchSelectOutlined>
                  )}
                />
              </FormGroup>
            </FormControl>

            <div style={{ marginTop: 40 }}>
              <PF2MAddButton disabled={isSubmitting || !isValid} />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  add: PropTypes.func,
  levels: PropTypes.array,
  modules: PropTypes.array,
  adminModules: PropTypes.array,
  userLevel: PropTypes.number,
  webUsers: PropTypes.array,
};

Header.defaultProps = {
  add: () => { },
  levels: [],
  modules: [],
  adminModules: [],
  userLevel: 0,
  webUsers: [],
};

const mapStateToProps = state => ({
  // eslint-disable-next-line no-underscore-dangle
  modules: state.auth.user._roles,
  adminModules: state.admin.webModules,
  userLevel: state.auth.user.level,
  webUsers: state.manager.webUsers,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Header));

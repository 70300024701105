import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './styles';
import { DispatchStatus, DispatchDragNDropTypes } from '~/utils';
import PF2MDispatchIconMaker from '../PF2MDispatchIconMaker';

const iconTypes = {
  stack: 'material',
  excavator: 'excavator',
  truck: 'truckEmpty',
};

const altForIconType = {
  stack: 'Destino',
  excavator: 'Excavadeira',
  truck: 'Caminhão',
};

const PF2MDispatchIcon = ({
  itemType, customStyles, classes, iconType, subtitle, warningType = null, ...rest
}) => (
  <div
    style={{
      width: 70,
      height: subtitle ? 90 : 70,
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      cursor: 'pointer',
      ...customStyles,
    }}
    {...rest}
  >
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      <div style={{ position: 'relative', top: 0, left: 0 }}>
        <PF2MDispatchIconMaker
          iconName={iconTypes[iconType]}
          settings={{
            iconSize: 70,
            alt: altForIconType[iconType],
            warnings: warningType ? [{
              icon: 'attention',
              position: 'top-right',
              alt: 'Atenção',
            }] : null,
          }}
        />
      </div>
      {subtitle
        && (
          <div
            className={classes.labelTypeSelector}
            style={{
              width: '100%', textAlign: 'center', fontSize: '14px', color: 'rgb(100, 120, 134)',
            }}
          >
            {subtitle}
          </div>
        )
      }
    </div>
  </div>
);

PF2MDispatchIcon.propTypes = {
  classes: PropTypes.object,
  customStyles: PropTypes.object,
  itemType: PropTypes.string,
  onClick: PropTypes.func,
  status: PropTypes.string,
  warningType: PropTypes.string,
  subtitle: PropTypes.string,
  iconType: PropTypes.string,
};

PF2MDispatchIcon.defaultProps = {
  classes: {},
  customStyles: {},
  status: DispatchStatus.OP,
  itemType: DispatchDragNDropTypes.TRUCK,
  onClick: () => null,
  warningType: '',
  subtitle: '',
  iconType: 'stack',
};

export default withStyles(styles)(PF2MDispatchIcon);

import {
  FormControl, FormGroup, FormLabel, Typography,
  withStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import classnames from 'classnames';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PF2MAddButton from '~/components/PF2MAddButton';
import PF2MButton from '~/components/PF2MButton';
import PF2MDatePickerOutlined from '~/components/PF2MDatePickerOutlined';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import {
  updateLineups, getLineups,
  setSelectedLineup,
} from '~/store/lineup/actions';
import { newMomentDate } from '~/utils/moment';
import {
  Card, CardBody, CardHeader, CardIcon,
} from '../../../components/Card';
import styles from '../styles';


const SearchForm = ({
  classes,
  isModal,
  onClose,
}) => {
  const formikRef = useRef();
  const { t: translate } = useTranslation();
  const [dataLoaded, setDataLoaded] = useState(false);
  const dispatch = useDispatch();
  const [registrationMode, setRegistrationMode] = useState(false);
  const lineups = useSelector(state => state.lineup.lineups);
  const [selectedLineupId, setSelectedLineupId] = useState(0);
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    async function fetchData() {
      dispatch(getLineups(true));
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch]);

  const renderRegistrationForm = () => (
    <Formik
      ref={formikRef}
      initialValues={{ name: '', startDate: newMomentDate(), endDate: newMomentDate() }}
      validate={(values) => {
        const errors = {};
        const {
          name,
          startDate = 0,
          endDate = 0,
        } = values;

        if (!name) {
          errors.name = 'Required';
        }

        if (!startDate.isValid()) {
          errors.startDate = translate('validation:InitialDateRequired');
        }

        if (!endDate.isValid()) {
          errors.endDate = translate('validation:FinalDateRequired');
        }

        if (startDate.isValid() && endDate.isValid()) {
          const days = endDate.diff(startDate, 'days');
          if (days < 0) {
            errors.endDate = translate('validation:FinalDateSmallerThanInitialDate');
          }
        }

        return errors;
      }}
      onSubmit={async (values, { resetForm }) => {
        dispatch(updateLineups(values));
        resetForm();
      }}
    >
      {({
        isSubmitting,
        errors,
        isValid,
        submitForm,
      }) => (
        <Form>
          <div style={{
            display: 'flex', alignItems: 'center',
          }}
          >
            <FormControl style={{ flex: 1 }}>
              <FormLabel className={classes.formLabel}>
                {`${translate('common:Name')}:`}
              </FormLabel>
              <FormGroup>
                <Field
                  type="text"
                  name="name"
                  className={classnames('form-control', errors.name ? classes.fieldError : classes.field)}
                />
              </FormGroup>
            </FormControl>
          </div>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 20,
          }}
          >
            <div>
              <FormControl style={{ marginRight: 20, width: 200 }}>
                <FormLabel className={classes.formLabel}>
                  {translate('common:InitialDate')}
                </FormLabel>
                <FormGroup>
                  <Field
                    name="startDate"
                    render={({ field, form }) => (
                      <PF2MDatePickerOutlined
                        {...field}
                        helperText=""
                        disableFuture={false}
                        InputProps={{
                          style: {
                            minWidth: 150,
                            height: 40,
                          },
                        }}
                        onChange={(e) => {
                          form.setFieldValue('startDate', e);
                          form.setFieldValue('endDate', e);
                        }}
                      />
                    )}
                  />
                </FormGroup>
              </FormControl>
              <FormControl style={{ marginRight: 20, width: 200 }}>
                <FormLabel className={classes.formLabel}>
                  {translate('common:FinalDate')}
                </FormLabel>
                <FormGroup>
                  <Field
                    name="endDate"
                    render={({ field, form }) => (
                      <PF2MDatePickerOutlined
                        {...field}
                        helperText=""
                        disableFuture={false}
                        minDate={form.values.startDate}
                        InputProps={{
                          style: {
                            minWidth: 150,
                            height: 40,
                          },
                        }}
                        onChange={e => form.setFieldValue('endDate', e)}
                      />
                    )}
                  />
                </FormGroup>
              </FormControl>
            </div>
            <div style={{ marginTop: 15 }}>
              <PF2MButton
                color="primary"
                onClick={() => setRegistrationMode(false)}
              >
                {translate('common:Cancel')}
              </PF2MButton>
              <PF2MAddButton type="button" disabled={isSubmitting || !isValid} onClick={() => setAlertOpen(true)} />
            </div>
          </div>
          <PF2MAlertDialog
            cancelText={translate('common:Cancel')}
            confirmText={translate('common:Save')}
            description={translate('validation:LineupScenariosInDateRangeWillBeOverriden')}
            open={alertOpen}
            onConfirm={() => {
              submitForm();
              setAlertOpen(false);
            }}
            onClose={() => setAlertOpen(false)}
          />
        </Form>
      )}
    </Formik>
  );

  const renderSearchForm = () => (
    <Form>
      <div style={{
        display: 'flex', alignItems: 'center',
      }}
      >
        <FormControl style={{ width: '100%' }}>
          <FormLabel className={classes.formLabel}>
            {`${translate('common:Scenarios')}:`}
          </FormLabel>
          <FormGroup>
            <PF2MSearchSelectOutlined
              value={selectedLineupId}
              onChange={e => setSelectedLineupId(e.target.value)}
              className={classes.typeSelector}
            >
              {[{ value: 0, label: translate('common:Select') }]
                .concat(...lineups.map(r => ({ value: r.id, label: r.name })))}
            </PF2MSearchSelectOutlined>
          </FormGroup>
        </FormControl>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 20,
        }}
      >
        <PF2MAddButton size="medium" onClick={() => setRegistrationMode(true)} />
        <PF2MButton
          color="primary"
          size="medium"
          disabled={!selectedLineupId}
          onClick={() => {
            dispatch(setSelectedLineup(selectedLineupId));
            onClose();
          }}
        >
          {translate('common:Load')}
        </PF2MButton>
      </div>
    </Form>
  );

  return (
    <Card style={{ width: 880 }}>
      <CardHeader icon>
        <>
          <CardIcon color="warning">
            <SearchIcon />
          </CardIcon>
          {isModal && (
            <CardIcon
              onClick={onClose}
              style={{
                float: 'right',
                cursor: 'pointer',
                background: 'darkgray',
                borderRadius: 50,
                boxShadow: '10px 10px 50px gray',
              }}
            >
              <CloseIcon />
            </CardIcon>
          )}
        </>
      </CardHeader>
      <CardBody>
        <Typography
          variant="h4"
          align="center"
          gutterBottom
        >
          {registrationMode ? translate('common:LineupNewSchedule') : translate('common:LineupPlanning')}
        </Typography>
        {registrationMode ? renderRegistrationForm() : renderSearchForm()}
      </CardBody>
    </Card>
  );
};

SearchForm.propTypes = {
  classes: PropTypes.object.isRequired,
  isModal: PropTypes.bool,
  onClose: PropTypes.func,
};

SearchForm.defaultProps = {
  isModal: false,
  onClose: null,
};


export default withStyles(styles)(SearchForm);

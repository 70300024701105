import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import GroupTab from './GroupTab';
import MaterialTab from './MaterialTab';
import DrillingTypeTab from './DrillingTypeTab';
import MaterialMovementTab from './MaterialMovementTab';
import PF2MTabs from '~/components/PF2MTabs';
import { setAlertDialog } from '~/store/manager/actions';

const MaterialGroup = ({
  setAlertDialog: setModal,
  isHandlingTypesDirty,
  isMaterialMovementsDirty,
}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { t: translate } = useTranslation();

  const tabs = [
    { key: 0, label: translate('common:MaterialsGroup') },
    { key: 1, label: translate('common:Material') },
    { key: 2, label: translate('common:MovementType') },
    { key: 3, label: translate('common:Movement') },
  ];

  const handleChange = (event, value) => {
    let blockChange = false;

    switch (value) {
      case 2:
        blockChange = isMaterialMovementsDirty;
        break;
      case 3:
        blockChange = isHandlingTypesDirty;
        break;
      default:
      case 1:
        blockChange = (isHandlingTypesDirty || isMaterialMovementsDirty);
        break;
    }

    if (blockChange) {
      event.preventDefault();
      setModal();
    } else {
      setSelectedTab(value);
    }
  };

  return (
    <>
      <PF2MTabs
        value={selectedTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        {tabs}
      </PF2MTabs>
      {selectedTab === 0 ? (<GroupTab />) : null}
      {selectedTab === 1 ? (<MaterialTab />) : null}
      {selectedTab === 2 ? (<DrillingTypeTab />) : null}
      {selectedTab === 3 ? (<MaterialMovementTab />) : null}
    </>
  );
};

MaterialGroup.propTypes = {
  setAlertDialog: PropTypes.func,
  isHandlingTypesDirty: PropTypes.bool,
  isMaterialMovementsDirty: PropTypes.bool,
};

MaterialGroup.defaultProps = {
  setAlertDialog: () => {},
  isHandlingTypesDirty: false,
  isMaterialMovementsDirty: false,
};

const mapStateToProps = state => ({
  isHandlingTypesDirty: state.manager.isHandlingTypesDirty,
  isMaterialMovementsDirty: state.manager.isMaterialMovementsDirty,
});

const mapDispatchToProps = {
  setAlertDialog,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(MaterialGroup));

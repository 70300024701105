import React, { useState, useEffect } from 'react';
import {
  withStyles, FormControl, FormLabel, FormGroup, Modal,
} from '@material-ui/core';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import PF2MAddButton from '~/components/PF2MAddButton';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { KeyboardTimePicker } from '@material-ui/pickers';
import moment from 'moment';
import styles from './styles';
import PF2MTable from '~/components/PF2MTable';
import Radio from '@material-ui/core/Radio';
import {
  getTerrainModels,
  getTerrainModelUploads,
  updateTerrainModel,
  addTerrainModelUpload,
  clearTempTerrainModelUpload,
  addTerrainModelUploadQueue,
  uploadTerrainModel,
  processFile,
  importHeightmap,
  approveTerrainModel,
  getTerrainModelLayers,
  getTerrainDefaultLayers,
  setTerrainModelUploadValue,
  updateTerrainModelUpload,
} from '~/store/terrain/actions';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import TerrainReviewModal from './TerrainReviewModal';
import {
  formatDateToServerFormat,
  formatTime,
  newMomentDate,
  parseTime,
} from '~/utils/moment';
import PF2MDatePickerOutlined from '~/components/PF2MDatePickerOutlined';

const TerrainModelUploadTab = ({
  classes,
  terrainModels,
  terrainModelUploads,
  reviewData,
  uploadQueue,
  addTerrainModelUploadQueue: addUploadQueue,
  addTerrainModelUpload: add,
  getTerrainModels: getAll,
  getTerrainModelUploads: getUploads,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [showAlert, setShowAlert] = useState({ row: {}, show: false });
  const [filter, setFilter] = useState(0);
  const [fileUploadRef, setFileUploadRef] = useState(null);
  const [selectingRow, setSelectingRow] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedModel, setSelectedModel] = useState(null);
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      await getAll();
      await getUploads();
      dispatch(getTerrainModelLayers());
      dispatch(getTerrainDefaultLayers());
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, getAll, getUploads, dispatch]);

  useEffect(() => {
    if (uploadQueue.length > 0) {
      const upload = uploadQueue.filter(item => item.status === 1)[0];
      dispatch(uploadTerrainModel(
        upload.file,
        upload.row,
      ));
      dispatch(setTerrainModelUploadValue(upload.row, 'status', 2));
    }
  }, [uploadQueue, dispatch]);

  useEffect(() => {
    if (terrainModelUploads.some(item => item.update_status === 'U' && item.is_default === 1)) {
      dispatch(updateTerrainModelUpload(terrainModelUploads.filter(item => item.update_status === 'U')));
    }
  }, [terrainModelUploads, dispatch]);

  const handleUpload = async (event) => {
    const currentFile = event.target.files[0];
    if (currentFile) {
      dispatch(setTerrainModelUploadValue(selectingRow, 'status', 1));
      addUploadQueue({ row: selectingRow, file: currentFile });
    } else {
      // eslint-disable-next-line no-console
      console.log('no file');
    }
  };

  const renderRadioButton = row => (
    <Radio
      checked={!!row.is_default}
      disabled={!row.status >= 5}
      onClick={() => { dispatch(setTerrainModelUploadValue(row, 'is_default', 1)); }}
    />
  );

  const columns = [
    {
      field: 'terrain_model_name', title: translate('common:ModelName'),
    },
    {
      field: 'plan_date',
      title: translate('common:PlanDate'),
      editable: false,
    },
    {
      field: 'plan_time',
      title: translate('common:PlanTime'),
      editable: false,
    },
    {
      field: 'status_name',
      title: translate('common:Status'),
      editable: false,
      width: '80%',
    },
    {
      field: 'is_project',
      title: translate('common:IsDefault'),
      renderCustomComponent: row => renderRadioButton(row),
    },
    {
      field: 'upload',
      title: translate('common:Upload'),
      button: true,
      onClick: (row) => { fileUploadRef.click(); setSelectingRow(row); },
      disabled: row => row.status !== 0,
    },
    {
      field: 'process',
      title: translate('common:Process'),
      button: true,
      onClick: row => dispatch(processFile(row)),
      disabled: row => row.status !== 3,
    },
    {
      field: 'review',
      title: translate('common:Review'),
      button: true,
      onClick: (row) => {
        dispatch(importHeightmap(row));
        setSelectedModel(row);
        setModalOpen(true);
      },
      disabled: row => row.status !== 5,
    },
  ];

  const renderDatePicker = (field, errors, disabled = false, onChange = null) => (
    <PF2MDatePickerOutlined
      {...field}
      disableFuture={false}
      className={errors[field.name] ? classes.fieldError : ''}
      disablePast
      helperText=""
      onChange={onChange || field.field.onChange}
      disabled={disabled}
    />
  );

  function handleMask(value) { return (value ? [/\d/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/] : []); }

  function renderTimePicker(field, values, onChange, disabled = false) {
    return (
      <KeyboardTimePicker
        {...field}
        value={parseTime(values[field.field.name] || '00:00:00')}
        autoOk
        ampm={false}
        inputVariant="outlined"
        format="HH:mm:ss"
        mask={handleMask}
        onChange={onChange}
        invalidDateMessage=""
        InputProps={{
          style: {
            height: 40,
            fontFamily: 'Roboto',
            fontWeight: 300,
            fontSize: 14,
            color: '#647886',
          },
        }}
        disabled={disabled}
      />
    );
  }

  const renderHeader = () => (
    <Formik
      initialValues={{
        terrain_model_id: '',
        plan_date: newMomentDate(),
        plan_time: moment('00:00:00', 'HH:mm:ss'),
      }}
      onSubmit={async (values, { resetForm }) => {
        resetForm();

        const newUpload = {
          terrain_model_id: values.terrain_model_id,
          terrain_model_name: terrainModels.find(
            t => t.id === values.terrain_model_id,
          ).name,
          plan_date: formatDateToServerFormat(values.plan_date),
          plan_time: formatTime(values.plan_time),
          status: 0,
        };

        await add(newUpload);
      }}
    >
      {({
        isSubmitting,
        isValid,
        values,
        setFieldValue,
      }) => (
        <Form style={{ display: 'flex', alignItems: 'center' }}>
          <FormControl className={classes.modelParent} style={{ marginRight: 32 }}>
            <FormLabel className={classes.formLabel}>
              {`${translate('common:Model')}:`}
            </FormLabel>
            <FormGroup>
              <Field
                name="terrain_model_id"
                render={({ field, form }) => (
                  <PF2MSearchSelectOutlined
                    {...field}
                    placeholder={null}
                    style={{
                      color: '#647886',
                      width: 290,
                      borderWidth: 0,
                    }}
                    onChange={e => form.setFieldValue('terrain_model_id', e.target.value)}
                  >
                    {[{ value: 0, label: translate('common:Select') }]
                      .concat(...terrainModels
                        .map(e => ({ value: e.id, label: e.name })))}
                  </PF2MSearchSelectOutlined>
                )}
              />
            </FormGroup>
          </FormControl>
          <FormControl style={{ marginRight: 32 }}>
            <FormLabel className={classes.formLabel}>
              {translate('common:PlanDate')}
              <ErrorMessage name="plan_date" component="span" className={classes.errorMessage} />
            </FormLabel>
            <FormGroup>
              <Field
                name="plan_date"
                render={field => renderDatePicker(field, isValid, false, (e) => {
                  setFieldValue('plan_date', e);
                })}
              />
            </FormGroup>
          </FormControl>
          <FormControl style={{ marginRight: 32 }}>
            <FormLabel className={classes.formLabel}>
              {translate('common:PlanTime')}
              <ErrorMessage name="plan_time" component="span" className={classes.errorMessage} />
            </FormLabel>
            <FormGroup>
              <Field
                name="plan_time"
                render={field => renderTimePicker(field, values, (e) => {
                  setFieldValue('plan_time', e);
                })}
              />
            </FormGroup>
          </FormControl>
          <div style={{ marginTop: 15 }}>
            <PF2MAddButton disabled={isSubmitting || !isValid} />
          </div>
        </Form>
      )}
    </Formik>
  );

  const renderFilter = () => (
    <div style={{
      display: 'flex',
      alignItems: 'baseline',
    }}
    >
      <p className={classes.labelTypeSelector}>
        {`${translate('common:FilterByModel')}:`}
      </p>
      <PF2MSearchSelectOutlined
        className={classes.filterSelector}
        placeholder={null}
        value={filter}
        onChange={e => setFilter(e.target.value)}
      >
        {[{ value: 0, label: translate('common:Select') }]
          .concat(...terrainModels
            .map(e => ({ value: e.id, label: e.name })))}
      </PF2MSearchSelectOutlined>
    </div>
  );

  return (
    <div className={classes.tabContainer}>
      {renderFilter()}
      {renderHeader()}
      <PF2MTable
        customStyles={{ height: 'calc(100vh - 230px)' }}
        data={terrainModelUploads.filter(e => !filter || e.terrain_model_id === filter)}
        columns={columns}
        enableUpload
      />
      <input
        ref={ref => setFileUploadRef(ref)}
        type="file"
        style={{ display: 'none' }}
        onChange={handleUpload}
        accept=".dxf"
      />
      <Modal
        open={modalOpen}
        onBackdropClick={() => setModalOpen(false)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div>
          <TerrainReviewModal
            handleClose={() => {
              setModalOpen(false);
            }}
            handleApprove={() => {
              dispatch(approveTerrainModel(reviewData.row));
            }}
            currentModel={selectedModel}
          />
        </div>
      </Modal>
      <PF2MAlertDialog
        confirmText={translate('common:Yes')}
        description={translate('validation:WarningTerrainModelDelete')}
        open={showAlert.show}
        onClose={() => setShowAlert({ show: false })}
        onConfirm={() => {
          setShowAlert({ show: false });
          // handleUpdateItem(showAlert.row, 'active', 0);
        }}
      />
    </div>
  );
};

TerrainModelUploadTab.propTypes = {
  classes: PropTypes.object.isRequired,
  terrainModels: PropTypes.array,
  terrainModelUploads: PropTypes.array,
  reviewData: PropTypes.object,
  uploadQueue: PropTypes.array,
  addTerrainModelUploadQueue: PropTypes.func,
  addTerrainModelUpload: PropTypes.func,
  getTerrainModels: PropTypes.func,
  getTerrainModelUploads: PropTypes.func,
};

TerrainModelUploadTab.defaultProps = {
  terrainModels: [],
  terrainModelUploads: [],
  reviewData: {},
  uploadQueue: [],
  addTerrainModelUploadQueue: null,
  addTerrainModelUpload: null,
  getTerrainModels: null,
  getTerrainModelUploads: null,
};

const mapStateToProps = state => ({
  terrainModels: state.terrain.terrainModels,
  reviewData: state.terrain.terrainReviewData,
  uploadQueue: state.terrain.terrainModelUploadQueue,
  terrainModelUploads: state.terrain.terrainModelUploads,
  isTerrainModelsDirty: state.terrain.isTerrainModelsDirty,
});

const mapDispatchToProps = {
  getTerrainModels,
  getTerrainModelUploads,
  updateTerrainModel,
  addTerrainModelUpload,
  clearTempTerrainModelUpload,
  addTerrainModelUploadQueue,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(TerrainModelUploadTab));

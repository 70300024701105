import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  withStyles,
  Modal,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import {
  getUnitOperationGroups,
  getUnitOperations,
  getCodeTypes,
  getCodeGroups,
  getCodes,
  setCurrentOperationControl,
  getPriorities,
  getProjectGroups,
  getInputUnits,
  getUndergroundOperationControls,
  setOriginalCurrentOperationControl,
} from '~/store/underground/actions';
import { getElements, getSubElements } from '~/store/manager/actions';
import OperationControlModalFilter from '../OperationControlModalFilter';
import OperationControlEditModal from '../OperationControlEditModal';
import PF2MDataTable, { icons } from '~/components/PF2MDataTable';

const OperationalControlTab = ({ classes }) => {
  const [filterModal, setFilterModal] = useState(false);
  const operationControls = useSelector(state => state.underground.operationControls);
  const isCurrentUpdated = useSelector(state => state.underground.isCurrentOperationControlUpdated);
  const currentFilter = useSelector(state => state.underground.currentOperationControlFilter);
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const { t: translate } = useTranslation();

  useEffect(() => {
    function fetchData() {
      dispatch(getUndergroundOperationControls(currentFilter));
    }
    fetchData();
    const interval = setInterval(() => fetchData(), process.env.REACT_APP_REFRESH_TIME || 60000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [currentFilter]);

  useEffect(() => {
    dispatch(getUnitOperationGroups());
    dispatch(getUnitOperations());
    dispatch(getCodeTypes());
    dispatch(getCodeGroups());
    dispatch(getCodes());
    dispatch(getPriorities());
    dispatch(getProjectGroups());
    dispatch(getElements());
    dispatch(getInputUnits());
    dispatch(getSubElements());
  }, [dispatch]);

  useEffect(() => {
    if (isCurrentUpdated && modalOpen) {
      setModalOpen(false);
      dispatch(setCurrentOperationControl({}));
    }
  }, [isCurrentUpdated, modalOpen, dispatch]);

  const columns = [
    {
      field: 'priority',
      title: translate('common:Priority'),
      defaultSort: 'desc',
    },
    {
      field: 'element_point_name',
      title: translate('common:SubElement'),
    },
    {
      field: 'unitary_operation_name',
      title: translate('common:UnitOperation'),
      defaultValue: ' ',
    },
    {
      field: 'unitary_operation_time',
      title: translate('common:UnitOperationTime'),
    },
    {
      field: 'code_group_ug_name',
      title: translate('common:CodeGroup'),
      defaultValue: ' ',
    },
    {
      field: 'code_ug_name',
      title: translate('common:Code'),
      defaultValue: ' ',
    },
    {
      field: 'code_time',
      title: translate('common:CodeTime'),
    },
    {
      field: 'actual_date',
      title: translate('common:Date'),
    },
    {
      field: 'actual_time',
      title: translate('common:Hour'),
    },
  ];

  const handleClose = () => {
    dispatch(setCurrentOperationControl(null));
    setModalOpen(false);
  };

  return (
    <div className={classes.tabContainer}>
      <OperationControlModalFilter
        modalData={filterModal}
        closeModal={() => setFilterModal(false)}
      />
      <PF2MDataTable
        options={{
          selection: false,
          hideAdd: true,
          hideDelete: true,
          hideCommit: true,
          hideReload: true,
        }}
        data={operationControls}
        columns={columns}
        defaultEditable={false}
        extraActions={[
          {
            icon: icons.Edit,
            tooltip: 'edit',
            isFreeAction: false,
            onClick: (_e, row) => {
              // get only fields to send
              const { tableData, ...cleanedRow } = row;
              dispatch(setCurrentOperationControl(cleanedRow));
              dispatch(setOriginalCurrentOperationControl(cleanedRow));
              setModalOpen(true);
            },
          },
          {
            isFreeAction: true,
            tooltip: 'filter',
            icon: icons.Filter,
            onClick: () => setFilterModal(true),
          },
        ]}
      />

      <Modal
        open={modalOpen}
        onClose={(_, type) => type === 'backdrop' && setModalOpen(false)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div>
          <OperationControlEditModal handleClose={handleClose} />
        </div>
      </Modal>
    </div>
  );
};

OperationalControlTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OperationalControlTab);

import React, { useState } from 'react';
import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import Header from './Header';
import PF2MSortButton from '~/components/PF2MSortButton';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import PF2MScrollbar from '~/components/PF2MScrollbar';
import {
  setMaintenanceValues,
  deleteMaintenanceItem,
  restoreMaintenanceItem,
  searchMaintenanceList,
} from '~/store/dailyparts/actions';
import ListRow from './ListRow';
import { dailyPartsMaintenanceGridHeight, dailyPartsMaintenanceGridWidth } from '~/utils';

const PF2MMaintenanceReport = ({
  classes,
  maintenanceList,
  deleteMaintenanceItem: deleteAction,
  restoreMaintenanceItem: restoreAction,
  setMaintenanceValues: setItemAction,
  searchMaintenanceList: searchAction,
  filters,
  page,
  limit,
  maintenanceSortBy: { field, order },
  // handleEditModal,
}) => {
  const { t: translate } = useTranslation();

  const titles = [
    { label: translate('common:InitialDate'), field: 'start_date' },
    { label: translate('common:InitialHour'), field: 'start_time' },
    { label: translate('common:EquipmentType'), field: 'equipaments_id' },
    { label: translate('common:EquipmentGroup'), field: 'equipament_groups_id' },
    { label: translate('common:Equipment'), field: 'group_equipament_links_id' },
    { label: translate('common:CodeGroup'), field: 'code_group_id' },
    { label: translate('common:Code'), field: 'code_id' },
    { label: translate('common:ShiftGroup'), field: 'turn_group_id' },
    { label: translate('common:Shift'), field: 'turn_id' },
    { label: translate('common:TeamGroup'), field: 'team_group_id' },
    { label: translate('common:Team'), field: 'team_id' },
    { label: translate('common:OperatorGroup'), field: 'operators_groups_id' },
    { label: translate('common:Operator'), field: 'operator_id' },
    { label: translate('common:TaskCodeType'), field: 'code_type_maintenance_id' },
    { label: translate('common:TaskCodeGroup'), field: 'code_group_maintenance_id' },
    { label: translate('common:Task'), field: 'code_maintenance_id' },
    { label: translate('common:System'), field: 'system_maintenance_id' },
    { label: translate('common:CycleType'), field: 'exception_type' },
  ];

  const [sortBy, setSortBy] = useState({ field, order });
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const handleSort = (title) => {
    if (maintenanceList.some(t => t.isDirty)) {
      setIsAlertOpen(true);
    } else {
      const newOrder = (sortBy.order === 'asc' && sortBy.field === title.field) ? 'desc' : 'asc';
      const newSortBy = { field: title.field, order: newOrder };
      setSortBy(newSortBy);
      searchAction(filters, page, limit, newSortBy);
    }
  };

  const renderTableTitle = (title, key) => (
    <TableCell
      align="center"
      className={classes.tableCell}
      key={key}
      style={{
        position: 'sticky',
        top: 0,
        backgroundColor: 'white',
        zIndex: 1,
      }}
    >
      <PF2MSortButton
        label={title.label}
        field={title.field}
        sortField={sortBy.field}
        sortOrder={sortBy.order}
        onClick={() => handleSort(title)}
      />
    </TableCell>
  );

  const renderTableHeader = () => titles.map((t, i) => renderTableTitle(t, `${i}_${t}`));

  const renderRows = () => maintenanceList
    .map(d => (
      <ListRow
        key={d.id}
        data={d}
        onDelete={deleteAction}
        onRestore={restoreAction}
        onEdit={setItemAction}
      // handleEditModal={(showModal, data) => handleEditModal(showModal, data)}
      />
    ));

  const renderLoadMore = () => {
    if (maintenanceList.length < limit) return null;
    if (maintenanceList.filter(d => d.id > 0).length < 1) { return null; }
    return (
      <div style={{
        backgroundColor: '#F9F9F9',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 10,
        paddingBottom: 10,
      }}
      >
        <Button
          onClick={() => searchAction(filters, (page + 1), limit, sortBy, false)}
        >
          <ArrowDownIcon />
          <Typography
            variant="h5"
          >
            {translate('common:SeeMore')}
          </Typography>
        </Button>
      </div>
    );
  };

  return (
    <>
      <Header />
      {maintenanceList.length > 0 ? (
        <PF2MScrollbar style={{
          backgroundColor: 'white',
          margin: 10,
          height: dailyPartsMaintenanceGridHeight,
          width: dailyPartsMaintenanceGridWidth,
        }}
        >
          <>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {renderTableHeader()}
                </TableRow>
              </TableHead>
              <TableBody>
                {renderRows()}
              </TableBody>
            </Table>
            {renderLoadMore()}
            <PF2MAlertDialog
              hasCancel={false}
              confirmText={translate('common:Understood')}
              description={translate('validations:YouHaveUnsavedData')}
              open={isAlertOpen}
              onConfirm={() => setIsAlertOpen(false)}
            />
          </>
        </PF2MScrollbar>
      ) : (
        <div
          style={{
            padding: '25px',
            textAlign: 'center',
            backgroundColor: 'white',
          }}
        >
          <span>{translate('common:NoEntries')}</span>
        </div>
      )}
    </>
  );
};

PF2MMaintenanceReport.propTypes = {
  classes: PropTypes.object,
  maintenanceList: PropTypes.array,
  setMaintenanceValues: PropTypes.func,
  deleteMaintenanceItem: PropTypes.func,
  restoreMaintenanceItem: PropTypes.func,
  searchMaintenanceList: PropTypes.func,
  maintenanceSortBy: PropTypes.object,
  filters: PropTypes.object,
  page: PropTypes.number,
  limit: PropTypes.number,
  // handleEditModal: PropTypes.func,
};

PF2MMaintenanceReport.defaultProps = {
  classes: {},
  maintenanceList: [],
  setMaintenanceValues: null,
  deleteMaintenanceItem: null,
  restoreMaintenanceItem: null,
  searchMaintenanceList: null,
  filters: {},
  page: 1,
  limit: 10,
  maintenanceSortBy: {},
  // handleEditModal: null,
};

const mapStateToProps = state => ({
  maintenanceList: state.dailyParts.maintenanceList,
  filters: state.dailyParts.filters,
  page: state.dailyParts.page,
  limit: state.dailyParts.limit,
  maintenanceSortBy: state.dailyParts.maintenanceSortBy,
  subElements: state.manager.subElements,
});

export default connect(mapStateToProps, {
  setMaintenanceValues,
  deleteMaintenanceItem,
  restoreMaintenanceItem,
  searchMaintenanceList,
})(withStyles(styles)(PF2MMaintenanceReport));

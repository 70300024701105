/* eslint-disable no-param-reassign */
import * as types from './types';
import api from '~/services/api';
import {
  exportData, importData, getTemplate, handleErrors,
} from '../common';
import { clearFileUploadError } from '../errors/actions';
import { parseFloat as utilsParseFloat, convertToUrlParameters } from '~/utils';

const getEndpoints = {
  dmtranges: {
    url: '/dmtranges',
    responseObject: 'dmt_ranges',
    typeAction: types.SET_DMT_RANGES_LIST,
  },
  dmtrangesmaterialcost: {
    url: '/dmtrangesmaterialcost',
    responseObject: 'dmt_ranges_material_cost',
    typeAction: types.SET_DMT_RANGES_MATERIAL_COST_LIST,
  },
  workhourvalues: {
    url: '/workhourvalues',
    responseObject: 'work_hour_values',
    typeAction: types.SET_WORK_HOUR_VALUES_LIST,
  },
};

export const getAll = (domain, clearUploadError = true, filters = {}) => async (dispatch) => {
  try {
    if (clearUploadError) {
      dispatch(clearFileUploadError());
    }
    const queryString = `?${convertToUrlParameters(filters)}`;

    const endpoint = getEndpoints[domain];
    const url = filters === {} ? endpoint.url : endpoint.url + queryString;
    const {
      data: {
        result: { [endpoint.responseObject]: data = [] },
      },
    } = await api.get(url);
    dispatch({
      type: endpoint.typeAction,
      payload: data,
    });
  } catch ({ response }) {
    if (response) {
      dispatch(handleErrors(response));
    }
  }
};

export const updateData = (domain, data) => async (dispatch) => {
  try {
    const items = data
      .filter(d => d.update_status)
      .map((d) => {
        const {
          isDirty, id, ...rest
        } = d;

        if (domain === 'dmtranges') {
          rest.range_start = utilsParseFloat(rest.range_start);
          rest.range_end = utilsParseFloat(rest.range_end);
        }

        if (rest.update_status !== 'I') return { id, ...rest };
        return rest;
      });
    const endpoint = getEndpoints[domain];

    const body = {
      [endpoint.responseObject]: items,
    };
    const { status } = await api.post(`${endpoint.url}`, body);
    if (status === 200) {
      dispatch(getAll(domain));
    }
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const addDmtRange = data => async dispatch => dispatch({
  type: types.ADD_DMT_RANGE,
  payload: data,
});

const setValues = (item, key, value, type) => (dispatch) => {
  let setType = '';
  switch (type) {
    default:
      setType = types.SET_DMT_RANGE_VALUE;
      break;
  }
  const updateStatus = item.update_status === 'I' ? 'I' : 'U';
  dispatch({
    type: setType,
    payload: {
      ...item,
      [key]: value,
      update_status: updateStatus,
    },
  });
};

export const getDmtRanges = () => async dispatch => dispatch(getAll('dmtranges'));
export const updateDmtRanges = data => async dispatch => dispatch(updateData('dmtranges', data));
export const setDmtRangesValue = (item, key, value) => async dispatch => dispatch(setValues(item, key, value, 'dmtranges'));
export const resetDmtRanges = () => async dispatch => dispatch({ type: types.RESET_DMT_RANGES });

export const getMaterialsCost = e => async dispatch => dispatch(getAll('dmtrangesmaterialcost', true, e));

export const getMaterialsCostTemplate = () => async (dispatch) => {
  dispatch(getTemplate('dmtrangesmaterialcost'));
};

export const exportMaterialsCost = e => async dispatch => dispatch(exportData('dmtrangesmaterialcost', e));

export const importMaterialsCost = file => async dispatch => dispatch(importData('dmtrangesmaterialcost', file, getAll));

export const getWorkHourValues = () => async dispatch => dispatch(getAll('workhourvalues'));

export const exportWorkHourValues = () => async dispatch => dispatch(exportData('workhourvalues'));

export const importWorkHourValues = file => async dispatch => dispatch(importData('workhourvalues', file, getAll));

import * as types from './types';
import { remapData, toggleDeleteOrInsert } from '~/utils';
import { SET_TURNS_GROUPS_LIST } from '../manager/types';

const INITIAL_STATE = {
  codeTypes: [],
  codeGroups: [],
  originalCodeGroups: [],
  isCodeGroupsDirty: false,
  codes: [],
  originalCodes: [],
  isCodesDirty: false,
  systems: [],
  originalSystems: [],
  isSystemsDirty: false,
  systemItems: [],
  originalSystemItems: [],
  isSystemItemsDirty: false,
  failureModes: [],
  originalFailureModes: [],
  isFailureModesDirty: false,
  isLogoutLimitDirty: false,
  logoutLimit: 0,
  originalLogoutLimit: 0,
  isFailureModesSystemItemsDirty: false,
  originalFailureModesSystemItems: [],
  failureModesSystemItems: [],
  isEquipmentGroupsSystemItemsDirty: false,
  originalEquipmentGroupsSystemItems: [],
  equipmentGroupsSystemItems: [],
  maintenanceOperation: {},
  maintenanceOperationItems: [],
  originalMaintenanceOperationItems: [],
  isMaintenanceOperationItemsDirty: false,
  isMaintenanceCorretion: false,
  showMaintenanceDetailsModal: false,
  isTurnGroupDirty: false,
  turnGroup: {},
  originalTurnGroup: {},
  isfailureModeEquipmentGroupDirty: false,
  originalfailureModeEquipmentGroup: [],
  failureModeEquipmentGroup: [],
  failureModeEquipmentGroupFiltered: [],
  maintenanceManagementFilter: {
    date: new Date(),
    equipTypes: [],
    equipGroups: [],
    equips: [],
  },
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_MAINTENANCE_CODE_TYPES_LIST: {
      return {
        ...state,
        codeTypes: action.payload,
      };
    }
    case types.SET_MAINTENANCE_CODE_GROUPS_LIST: {
      return {
        ...state,
        isCodeGroupsDirty: false,
        originalCodeGroups: action.payload,
        codeGroups: action.payload,
      };
    }
    case types.RESET_MAINTENANCE_CODE_GROUPS: {
      return {
        ...state,
        codeGroups: state.originalCodeGroups,
        isCodeGroupsDirty: false,
      };
    }
    case types.SET_MAINTENANCE_CODE_GROUP_VALUE: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.codeGroups, newItem, 'id');
      const isCodeGroupsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isCodeGroupsDirty,
        codeGroups: newData,
      };
    }
    case types.ADD_MAINTENANCE_CODE_GROUP: {
      const newItem = {
        ...action.payload,
        id: (0 - state.codeGroups.length),
        update_status: 'I',
        active: 1,
        isDirty: true,
      };
      const newData = [newItem, ...state.codeGroups];
      const isCodeGroupsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isCodeGroupsDirty,
        codeGroups: newData,
      };
    }
    case types.SET_MAINTENANCE_CODES_LIST: {
      return {
        ...state,
        isCodesDirty: false,
        originalCodes: action.payload,
        codes: action.payload,
      };
    }
    case types.RESET_MAINTENANCE_CODES: {
      return {
        ...state,
        codes: state.originalCodes,
        isCodesDirty: false,
      };
    }
    case types.SET_MAINTENANCE_CODE_VALUE: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.codes, newItem, 'id');
      const isCodesDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isCodesDirty,
        codes: newData,
      };
    }
    case types.ADD_MAINTENANCE_CODE: {
      const newItem = {
        ...action.payload,
        id: (0 - state.codes.length),
        update_status: 'I',
        active: 1,
        isDirty: true,
      };
      const newData = [newItem, ...state.codes];
      const isCodesDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isCodesDirty,
        codes: newData,
      };
    }
    case types.SET_MAINTENANCE_SYSTEMS_LIST: {
      return {
        ...state,
        isSystemsDirty: false,
        originalSystems: action.payload,
        systems: action.payload,
      };
    }
    case types.RESET_MAINTENANCE_SYSTEMS: {
      return {
        ...state,
        systems: state.originalSystems,
        isSystemsDirty: false,
      };
    }
    case types.SET_MAINTENANCE_SYSTEM_VALUE: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.systems, newItem, 'id');
      const isSystemsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isSystemsDirty,
        systems: newData,
      };
    }
    case types.ADD_MAINTENANCE_SYSTEM: {
      const newItem = {
        ...action.payload,
        id: (0 - state.systems.length),
        update_status: 'I',
        active: 1,
        isDirty: true,
      };
      const newData = [newItem, ...state.systems];
      const isSystemsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isSystemsDirty,
        systems: newData,
      };
    }
    case types.SET_MAINTENANCE_SYSTEM_ITEMS_LIST: {
      return {
        ...state,
        isSystemItemsDirty: false,
        originalSystemItems: action.payload,
        systemItems: action.payload,
      };
    }
    case types.RESET_MAINTENANCE_SYSTEM_ITEMS: {
      return {
        ...state,
        systemItems: state.originalSystemItems,
        isSystemItemsDirty: false,
      };
    }
    case types.SET_MAINTENANCE_SYSTEM_ITEM_VALUE: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.systemItems, newItem, 'id');
      const isSystemItemsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isSystemItemsDirty,
        systemItems: newData,
      };
    }
    case types.ADD_MAINTENANCE_SYSTEM_ITEM: {
      const newItem = {
        ...action.payload,
        id: (0 - state.systemItems.length),
        update_status: 'I',
        active: 1,
        isDirty: true,
      };
      const newData = [newItem, ...state.systemItems];
      const isSystemItemsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isSystemItemsDirty,
        systemItems: newData,
      };
    }
    case types.SET_MAINTENANCE_FAILURE_MODES_LIST: {
      return {
        ...state,
        isFailureModesDirty: false,
        originalFailureModes: action.payload,
        failureModes: action.payload,
      };
    }
    case types.RESET_MAINTENANCE_FAILURE_MODES: {
      return {
        ...state,
        failureModes: state.originalFailureModes,
        isFailureModesDirty: false,
      };
    }
    case types.SET_MAINTENANCE_FAILURE_MODE_VALUE: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.failureModes, newItem, 'id');
      const isFailureModesDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isFailureModesDirty,
        failureModes: newData,
      };
    }
    case types.ADD_MAINTENANCE_FAILURE_MODE: {
      const newItem = {
        ...action.payload,
        id: (0 - state.failureModes.length),
        update_status: 'I',
        active: 1,
        isDirty: true,
      };
      const newData = [newItem, ...state.failureModes];
      const isFailureModesDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isFailureModesDirty,
        failureModes: newData,

      };
    }
    case types.SET_LOGOUT_LIMIT: {
      return {
        ...state,
        isLogoutLimitDirty: false,
        logoutLimit: action.payload,
        originalLogoutLimit: action.payload,
      };
    }
    case types.SET_LOGOUT_LIMIT_VALUE: {
      return {
        ...state,
        isLogoutLimitDirty: true,
        logoutLimit: action.payload,
      };
    }
    case types.RESET_LOGOUT_LIMIT: {
      return {
        ...state,
        isLogoutLimitDirty: false,
        logoutLimit: state.originalLogoutLimit,
      };
    }
    case types.SET_FAILURE_MODES_SYSTEM_ITEMS_LIST: {
      return {
        ...state,
        isFailureModesSystemItemsDirty: false,
        originalFailureModesSystemItems: action.payload,
        failureModesSystemItems: action.payload,
      };
    }
    case types.RESET_FAILURE_MODE_SYSTEM_ITEMS: {
      return {
        ...state,
        failureModesSystemItems: state.originalFailureModesSystemItems,
        isFailureModesSystemItemsDirty: false,
      };
    }
    case types.SET_FAILURE_MODE_EQUIPMENT_GROUP_MAINTENANCE_LIST: {
      return {
        ...state,
        isfailureModeEquipmentGroupDirty: false,
        originalfailureModeEquipmentGroup: action.payload,
        failureModeEquipmentGroup: action.payload,
      };
    }
    case types.SET_FAILURE_MODE_EQUIPMENT_GROUP_MAINTENANCE_FILTERED: {
      return {
        ...state,
        isfailureModeEquipmentGroupFilteredDirty: false,
        originalfailureModeEquipmentGroupFiltered: action.payload,
        failureModeEquipmentGroupFiltered: action.payload,
      };
    }
    case types.RESET_FAILURE_MODE_EQUIPMENT_GROUPS: {
      return {
        ...state,
        failureModeEquipmentGroup: state.originalfailureModeEquipmentGroup,
        isfailureModeEquipmentGroupDirty: false,
      };
    }
    case types.SET_FAILURE_MODE_SYSTEM_ITEM_VALUE: {
      const {
        systemItemId, failureModeId, equipmentGroupId, checked,
      } = action.payload;

      let newData = [];
      let newDataEquipment = [];

      const currentItem = state.failureModesSystemItems
        .find(m => m.id_system_item_maintenance === systemItemId
          && m.id_failure_mode_maintenance === failureModeId);

      const newItemSystem = {
        id: Math.random(),
        ...currentItem,
        id_system_item_maintenance: systemItemId,
        id_failure_mode_maintenance: failureModeId,
        update_status: checked ? 'I' : 'D',
      };

      if (!currentItem) {
        newData = [...state.failureModesSystemItems, newItemSystem];
      } else {
        newData = remapData(state.failureModesSystemItems, newItemSystem, 'id');
      }

      const equipFailureItem = state.failureModeEquipmentGroup.filter(f => (
        f.equipment_group_id === equipmentGroupId
        && state.failureModesSystemItems.some(m => m.id_system_item_maintenance === systemItemId)
      ));

      const currentItemEquipment = state.failureModeEquipmentGroup
        .find(i => i.equipment_group_id === equipmentGroupId
          && currentItem?.id === i.id_failure_mode_system_item);

      const newItemEquipment = {
        id: Math.random(),
        ...currentItemEquipment,
        id_failure_mode_system_item: newItemSystem.id,
        equipment_group_id: equipmentGroupId,
        update_status: checked ? 'I' : 'D',
      };

      if (equipFailureItem.length > 0) {
        if (checked && !equipFailureItem.some(i => i.id === newItemEquipment.id)) {
          newDataEquipment = [...state.failureModeEquipmentGroup, newItemEquipment];
        } else {
          newDataEquipment = remapData(state.failureModeEquipmentGroup, newItemEquipment, 'id');
        }
      } else {
        let changeExists = false;
        let newFailureModesEquipments = state.failureModesSystemItems
          .filter(i => i.id_system_item_maintenance === systemItemId)
          .map((f) => {
            if (f.id_failure_mode_maintenance === failureModeId) {
              changeExists = true;
              return {
                id: Math.random(),
                id_failure_mode_system_item: f.id,
                equipment_group_id: equipmentGroupId,
                update_status: toggleDeleteOrInsert(f.update_status),
              };
            }
            return {
              id: Math.random(),
              id_failure_mode_system_item: f.id,
              equipment_group_id: equipmentGroupId,
              update_status: f.update_status || 'I',
            };
          });
        if (!changeExists) {
          newFailureModesEquipments = [...newFailureModesEquipments, newItemEquipment];
        }
        newDataEquipment = [...state.failureModeEquipmentGroup, ...newFailureModesEquipments];
      }

      const isFailureModesSystemItemsDirty = newData.some(d => d.update_status);
      const isfailureModeEquipmentGroupDirty = newDataEquipment.some(d => d.update_status);

      return {
        ...state,
        isFailureModesSystemItemsDirty,
        isfailureModeEquipmentGroupDirty,
        failureModesSystemItems: newData,
        failureModeEquipmentGroup: newDataEquipment,
      };
    }
    case types.SET_EQUIPMENT_GROUPS_SYSTEM_ITEMS_LIST: {
      return {
        ...state,
        isEquipmentGroupsSystemItemsDirty: false,
        originalEquipmentGroupsSystemItems: action.payload,
        equipmentGroupsSystemItems: action.payload,
      };
    }
    case types.RESET_EQUIPMENT_GROUPS_SYSTEM_ITEMS: {
      return {
        ...state,
        equipmentGroupsSystemItems: state.originalEquipmentGroupsSystemItems,
        isEquipmentGroupsSystemItemsDirty: false,
      };
    }
    case types.SET_EQUIPMENT_GROUP_SYSTEM_ITEM_VALUE: {
      const {
        equipmentGroup,
        system,
        systemItem,
        checked,
      } = action.payload;

      const currentItem = state.equipmentGroupsSystemItems
        .find(m => m.system_item_maintenance_id === Number(systemItem)
          && m.system_maintenance_id === system && m.equipment_group_id === equipmentGroup);

      const newItem = {
        ...currentItem,
        active: checked ? 1 : 0,
        update_status: 'U',
      };
      let newData = [];

      newData = remapData(state.equipmentGroupsSystemItems, newItem, 'system_item_maintenance_id');

      const isEquipmentGroupsSystemItemsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isEquipmentGroupsSystemItemsDirty,
        equipmentGroupsSystemItems: newData,
      };
    }
    case types.SET_ALL_EQUIPMENT_GROUP_SYSTEM_ITEM_VALUE: {
      const { system, equipmentGroup, checked } = action.payload;
      const newData = state.equipmentGroupsSystemItems
        .filter(e => e.equipment_group_id === equipmentGroup && e.system_maintenance_id === system)
        .map(e => ({
          ...e,
          active: checked ? 1 : 0,
          update_status: 'U',
        }));

      const isEquipmentGroupsSystemItemsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isEquipmentGroupsSystemItemsDirty,
        equipmentGroupsSystemItems: newData,
      };
    }
    case types.SET_MAINTENANCE_OPERATION_ITEMS_LIST: {
      return {
        ...state,
        isMaintenanceOperationItemsDirty: false,
        originalMaintenanceOperationItems: action.payload,
        maintenanceOperationItems: action.payload,
      };
    }
    case types.CREATE_MAINTENANCE_OPERATION_ITEM: {
      return {
        ...state,
        isMaintenanceOperationItemsDirty: true,
        maintenanceOperationItems: [
          ...state.maintenanceOperationItems,
          action.payload.item,
        ],
      };
    }
    case types.SET_MAINTENANCE_OPERATION_ITEMS: {
      const { item } = action.payload;
      return {
        ...state,
        isMaintenanceOperationItemsDirty: true,
        maintenanceOperationItems: state.maintenanceOperationItems
          .map(r => (r.id === item.id ? { ...item } : r)),
      };
    }
    case types.SET_CURRENT_MAINTENANCE_OPERATION_ITEMS: {
      const {
        isMaintenanceCorretion,
        maintenanceOperation,
        maintenanceOperationItems,
      } = action.payload;
      return {
        ...state,
        maintenanceOperation,
        maintenanceOperationItems,
        isMaintenanceCorretion,
        showMaintenanceDetailsModal: true,
        isMaintenanceOperationItemsDirty: false,
      };
    }
    case types.RESET_MAINTENANCE_OPERATION_ITEMS: {
      return {
        ...state,
        maintenanceOperationItems: [],
        isMaintenanceCorretion: false,
        showMaintenanceDetailsModal: false,
        isMaintenanceOperationItemsDirty: false,
      };
    }
    case SET_TURNS_GROUPS_LIST: {
      const { id = 0 } = action.payload.find(t => t.is_maintenance) || {};
      return {
        ...state,
        isTurnGroupDirty: false,
        turnGroup: id,
        originalTurnGroup: id,
      };
    }
    case types.SET_MAINTENANCE_TURN_GROUP_VALUE: {
      return {
        ...state,
        isTurnGroupDirty: true,
        turnGroup: action.payload,
      };
    }
    case types.RESET_MAINTENANCE_TURN_GROUP: {
      return {
        ...state,
        isTurnGroupDirty: false,
        turnGroup: state.originalTurnGroup,
      };
    }
    case types.SET_MAINTENANCE_MANAGEMENT_FILTER_ITEM: {
      const { name, value } = action.payload;
      return {
        ...state,
        maintenanceManagementFilter: {
          ...state.maintenanceManagementFilter,
          [name]: value,
        },
      };
    }
    case types.SET_MAINTENANCE_MANAGEMENT_FILTER: {
      return {
        ...state,
        maintenanceManagementFilter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

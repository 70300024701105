import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PF2MTable from '~/components/PF2MTable';
import Footer from '~/pages/Manager/Footer';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import {
  getEquipmentTypes,
  getEquipmentsGroups,
  getHandlingTypes,
} from '~/store/manager/actions';
import {
  getDmtRanges,
  updateDmtRanges,
  resetDmtRanges,
  addDmtRange,
  setDmtRangesValue,
} from '~/store/contracts/actions';
import Header from './Header';

const DmtRangesTab = ({
  classes,
  dmtRanges,
  equipmentsGroups,
  equipmentTypes,
  handlingTypes,
  getDmtRanges: getAll,
  getEquipmentTypes: loadEquipmentTypes,
  getEquipmentsGroups: loadEquipmentsGroups,
  getHandlingTypes: loadHandlingTypes,
  isDmtRangesDirty,
  updateDmtRanges: updateData,
  resetDmtRanges: reset,
  addDmtRange: add,
  setDmtRangesValue: updateItem,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const { t: translate } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      getAll();
      loadHandlingTypes(true);
      loadEquipmentsGroups();
      await loadEquipmentTypes();
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, getAll, loadEquipmentsGroups, loadEquipmentTypes, loadHandlingTypes]);

  const columns = [
    {
      field: 'id_equipment_type',
      title: translate('common:EquipmentType'),
      selectItems: equipmentTypes,
      editable: true,
      width: 200,
    },
    {
      field: 'id_equip_group',
      title: translate('common:EquipmentGroup'),
      selectItems: equipmentsGroups,
      editable: true,
      width: 200,
      filterKey: 'id_equipament',
      filterValue: 'id_equipment_type',
    },
    {
      field: 'handling_types_id',
      title: translate('common:MovementType'),
      selectItems: handlingTypes,
      editable: true,
      width: 200,
      comboDefaultLabel: translate('common:All'),
    },
    {
      field: 'name',
      title: translate('common:RangeName'),
      editable: true,
      width: '100%',
    },
    {
      field: 'range_start',
      title: translate('common:InitialM'),
      editable: true,
      type: 'decimal',
      width: 100,
      blocked: true,
    },
    {
      field: 'range_end',
      title: translate('common:FinalM'),
      editable: true,
      type: 'decimal',
      width: 100,
    },
    {
      field: 'active',
      title: 'STATUS',
      editable: true,
    },
  ];

  return (
    <div className={classes.tabContainer}>
      <Header
        equipmentTypes={equipmentTypes}
        equipmentGroups={equipmentsGroups}
        handlingTypes={handlingTypes}
        add={add}
        style={{ borderBottom: 15 }}
      />
      <PF2MTable
        data={dmtRanges}
        columns={columns}
        className={classes.grid}
        updateItem={updateItem}
      />
      <Footer
        isDirty={isDmtRangesDirty}
        sendData={() => updateData(dmtRanges)}
        discard={reset}
      />
    </div>
  );
};

DmtRangesTab.propTypes = {
  classes: PropTypes.object.isRequired,
  dmtRanges: PropTypes.array,
  equipmentTypes: PropTypes.array,
  equipmentsGroups: PropTypes.array,
  handlingTypes: PropTypes.array,
  getEquipmentTypes: PropTypes.func,
  getEquipmentsGroups: PropTypes.func,
  getDmtRanges: PropTypes.func,
  isDmtRangesDirty: PropTypes.bool,
  updateDmtRanges: PropTypes.func,
  resetDmtRanges: PropTypes.func,
  addDmtRange: PropTypes.func,
  setDmtRangesValue: PropTypes.func,
  getHandlingTypes: PropTypes.func,
};

DmtRangesTab.defaultProps = {
  dmtRanges: [],
  equipmentTypes: [],
  equipmentsGroups: [],
  handlingTypes: [],
  getEquipmentTypes: null,
  getEquipmentsGroups: null,
  getDmtRanges: null,
  isDmtRangesDirty: false,
  updateDmtRanges: null,
  resetDmtRanges: null,
  addDmtRange: null,
  setDmtRangesValue: null,
  getHandlingTypes: null,
};

const mapStateToProps = state => ({
  dmtRanges: state.contracts.dmtRanges,
  isDmtRangesDirty: state.contracts.isDmtRangesDirty,
  equipmentTypes: state.manager.equipmentTypes,
  equipmentsGroups: state.manager.equipmentsGroups,
  handlingTypes: state.manager.handlingTypes,
});

const mapDispatchToProps = {
  getEquipmentTypes,
  getEquipmentsGroups,
  getDmtRanges,
  updateDmtRanges,
  resetDmtRanges,
  addDmtRange,
  setDmtRangesValue,
  getHandlingTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DmtRangesTab));

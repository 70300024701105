import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import PF2MTopBar from '~/components/PF2MTopBar';
import { isAuthenticated, getToken, getConfig } from '~/services/authentication';
import 'react-notifications/lib/notifications.css';
import { clearMessages } from '~/store/errors/actions';
import { logoutUser, setCurrentUser } from '~/store/auth/actions';
import PF2MLoading from '~/components/PF2MLoading';
import { useTranslation } from 'react-i18next';
import PF2MTopBarMain from '~/components/PF2MTopBarMain';

const Main = ({
  children,
  errors,
  fileUploadSuccess,
  clearMessages: clearMessagesAction,
  auth,
  logoutUser: logout,
  loading,
  hasWarning,
  hasLoading,
  type,
}) => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated()) {
      dispatch(setCurrentUser(getToken(), getConfig()));
    }
  }, [auth.isAuthenticated, logout, dispatch]);

  useEffect(() => {
    if (errors.length > 0) {
      const messages = errors.map(e => e.message).join('-');
      NotificationManager.error(messages, translate('common:Error'), 15000, () => { });
    }
    if (fileUploadSuccess.length > 0 && hasWarning) {
      NotificationManager.warning(fileUploadSuccess, translate('common:Warning'), 3000, () => { });
    } else if (fileUploadSuccess.length > 0) {
      NotificationManager.success(fileUploadSuccess, translate('common:Success'), 3000, () => { });
    }
    const interval = setInterval(() => {
      if (fileUploadSuccess.length > 0 || hasWarning) {
        clearMessagesAction();
      }
    }, 3000);
    return () => clearInterval(interval);
  }, [errors, fileUploadSuccess, hasWarning, clearMessagesAction, translate]);

  return (
    <>
      <div data-testid="123">
        {isAuthenticated() && type === 'home' && <PF2MTopBarMain />}
        {isAuthenticated() && type !== 'home' && <PF2MTopBar />}
        {children}
      </div>
      {hasLoading && <PF2MLoading loading={loading} />}
      <NotificationContainer />
    </>
  );
};

Main.propTypes = {
  children: PropTypes.any.isRequired,
  errors: PropTypes.array,
  clearMessages: PropTypes.func,
  fileUploadSuccess: PropTypes.string,
  auth: PropTypes.object.isRequired,
  logoutUser: PropTypes.func,
  loading: PropTypes.bool,
  hasWarning: PropTypes.bool,
  hasLoading: PropTypes.bool,
  type: PropTypes.string,
};

Main.defaultProps = {
  errors: [],
  clearMessages: null,
  fileUploadSuccess: '',
  logoutUser: null,
  loading: false,
  hasWarning: false,
  hasLoading: true,
  type: '',
};

const mapStateToProps = state => ({
  errors: state.errors.errors,
  fileUploadSuccess: state.errors.fileUploadSuccess,
  auth: state.auth,
  loading: state.loadingReducer.loading,
  hasWarning: state.errors.hasWarning,
});

const mapDispatchToProps = {
  clearMessages,
  logoutUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);

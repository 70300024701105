import React from 'react';
import { Fab, withStyles } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './styles';

const PF2MClearFiltersButton = ({
  onClick,
  disabled,
  classes,
  size,
  ...props
}) => {
  const { t: translate } = useTranslation();

  return (
    <Fab
      variant="extended"
      color="primary"
      size={size}
      onClick={onClick}
      disabled={disabled}
      className={classes.button}
      {...props}
    >
      <FilterListIcon className={classes.icon} />
      {translate('common:ClearFilters').toUpperCase()}
    </Fab>
  );
};

PF2MClearFiltersButton.propTypes = {
  classes: PropTypes.object,
  disabled: PropTypes.bool,
  onClick: PropTypes.any,
  size: PropTypes.string,
};

PF2MClearFiltersButton.defaultProps = {
  classes: {},
  disabled: false,
  onClick: {},
  size: 'large',
};

export default withStyles(styles)(PF2MClearFiltersButton);

import React, { useEffect, useState } from 'react';
import {
  withStyles, FormControl, FormLabel, FormGroup, Checkbox,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import {
  Formik, ErrorMessage, Field, Form,
} from 'formik';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import Footer from '~/pages/Manager/Footer';
import PF2MTable from '~/components/PF2MTable';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import {
  getDrillholeDepths,
  setDrillholeDepthValue,
  resetDrillholeDepths,
  updateDrillholeDepths,
  addDrillholeDepth,
  getEquipmentTypes,
  getEquipmentsGroups,
  getEquipments,
} from '~/store/manager/actions';
import PF2MAddButton from '~/components/PF2MAddButton';
import { sortByProperties, parseFloat } from '~/utils';

const DEPTH_EQUIP_TYPES = [11, 16, 17, 18];
const DrillholeDepthTab = ({ classes }) => {
  const drillholeDepths = useSelector(state => state.manager.drillholeDepths);
  const isDrillholeDepthsDirty = useSelector(state => state.manager.isDrillholeDepthsDirty);
  const equipmentTypes = useSelector(state => state.manager.equipmentTypes);
  const equipmentGroups = useSelector(state => state.manager.equipmentsGroups);
  const equipments = useSelector(state => state.manager.equipments);
  const [selectedEquipmentType, setSelectedEquipmentType] = useState(0);
  const [selectedEquipmentGroup, setSelectedEquipmentGroup] = useState(0);
  // eslint-disable-next-line
  const [selectedEquipment, setSelectedEquipment] = useState(0);
  const [drillholeDepthsInactive, setDrillholeDepthsInactive] = useState(0);

  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      dispatch(getDrillholeDepths(drillholeDepthsInactive));
      await dispatch(getEquipmentTypes());
      await dispatch(getEquipmentsGroups());
      await dispatch(getEquipments());
    }
    fetchData();
  }, [dispatch, drillholeDepthsInactive]);

  const columns = [
    { field: 'id', title: 'ID', defaultValue: '-' },
    {
      field: 'group_equipament_links_name',
      title: translate('common:Equipment'),
    },
    {
      field: 'depth',
      title: translate('common:Footage'),
      editable: true,
      type: 'decimal',
      separator: ',',
      updateOnChange: true,
    },
    {
      field: 'active',
      title: 'STATUS',
      editable: true,
    },
  ];

  const isUniqueDepth = (values) => {
    const foundDepths = drillholeDepths
      .filter(r => r.group_equipament_links_id === values.equip
        && parseFloat(r.depth) === parseFloat(values.depth));

    return foundDepths.length <= 0;
  };

  const getEquipName = (id) => {
    const filteredEquips = equipments.filter(r => r.id === id);
    return filteredEquips.length
      ? filteredEquips[0].name : '';
  };

  const renderHeader = () => (
    <Formik
      initialValues={{ depth: '' }}
      validate={(values) => {
        const errors = {};
        const {
          depth, equipment, equipmentGroup, equipmentType,
        } = values;

        if (!equipment) {
          errors.equipment = translate('validation:RequiredField');
        }

        if (!equipmentGroup) {
          errors.equipmentGroup = translate('validation:RequiredField');
        }

        if (!equipmentType) {
          errors.equipmentType = translate('validation:RequiredField');
        }

        if (!depth) {
          errors.depth = translate('validation:RequiredField');
        }

        if (!isUniqueDepth(values)) {
          errors.depth = translate('validation:AlreadyHasFootage');
        }

        return errors;
      }}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        const newValues = {
          group_equipament_links_id: values.equipment,
          group_equipament_links_name: getEquipName(values.equipment),
          depth: values.depth,
          active: 1,
          update_status: 'I',
        };
        dispatch(addDrillholeDepth(newValues));
        resetForm();
        setSubmitting(false);
      }}
    >
      {({
        isSubmitting,
        errors,
        isValid,
      }) => (
        <Form>
          <div style={{ display: 'flex', marginBottom: 15, minHeight: 75 }}>
            <FormControl>
              <FormLabel className={classes.formLabel}>
                {`${translate('common:EquipmentType')}:`}
              </FormLabel>
              <FormGroup>
                <Field
                  name="equipmentType"
                  render={({ field, form }) => (
                    <PF2MSearchSelectOutlined
                      {...field}
                      placeholder={null}
                      className={classnames(
                        form.errors[field.name] ? classes.fieldError : classes.field,
                      )}
                      onChange={async (e) => {
                        form.setFieldValue('equipmentType', e.target.value);
                        setSelectedEquipmentType(e.target.value);
                      }}
                    >
                      {[{ value: 0, label: translate('common:Select') }]
                        .concat(...equipmentTypes
                          .filter(r => DEPTH_EQUIP_TYPES.includes(r.id))
                          .map(e => ({ value: e.id, label: e.name })))}
                    </PF2MSearchSelectOutlined>
                  )}
                />
                <ErrorMessage
                  name="equipmentType"
                  component="span"
                  className={classes.errorMessage}
                />
              </FormGroup>
            </FormControl>
            <FormControl style={{ marginLeft: 10 }}>
              <FormLabel className={classes.formLabel}>
                {`${translate('common:EquipmentGroup')}:`}
              </FormLabel>
              <FormGroup>
                <Field
                  name="equipmentGroup"
                  render={({ field, form }) => (
                    <PF2MSearchSelectOutlined
                      {...field}
                      placeholder={null}
                      className={classnames(
                        form.errors[field.name] ? classes.fieldError : classes.field,
                      )}
                      onChange={async (e) => {
                        form.setFieldValue('equipmentGroup', e.target.value);
                        setSelectedEquipmentGroup(e.target.value);
                      }}
                    >
                      {[{ value: 0, label: translate('common:Select') }]
                        .concat(...equipmentGroups
                          .filter(s => s.id_equipament === selectedEquipmentType)
                          .map(e => ({ value: e.id, label: e.name })))}
                    </PF2MSearchSelectOutlined>
                  )}
                />
                <ErrorMessage
                  name="equipmentGroup"
                  component="span"
                  className={classes.errorMessage}
                />
              </FormGroup>
            </FormControl>
            <FormControl style={{ marginLeft: 10 }}>
              <FormLabel className={classes.formLabel}>
                {`${translate('common:Equipment')}:`}
              </FormLabel>
              <FormGroup>
                <Field
                  name="equipment"
                  render={({ field, form }) => (
                    <PF2MSearchSelectOutlined
                      {...field}
                      placeholder={null}
                      className={classnames(
                        form.errors[field.name] ? classes.fieldError : classes.field,
                      )}
                      onChange={async (e) => {
                        form.setFieldValue('equipment', e.target.value);
                        setSelectedEquipment(e.target.value);
                      }}
                    >
                      {[{ value: 0, label: translate('common:Select') }]
                        .concat(equipments
                          .filter(s => s.id_group === selectedEquipmentGroup)
                          .map(e => ({ value: e.id, label: e.name })))}
                    </PF2MSearchSelectOutlined>
                  )}
                />
                <ErrorMessage
                  name="equipment"
                  component="span"
                  className={classes.errorMessage}
                />
              </FormGroup>
            </FormControl>
            <FormControl className={classes.classGroupName} style={{ marginLeft: 10 }}>
              <FormLabel className={classes.formLabel}>
                {`${translate('common:Footage')}:`}
              </FormLabel>
              <FormGroup>
                <Field
                  name="depth"
                  render={({ field }) => (
                    <NumberFormat
                      {...field}
                      className={classnames(
                        'form-control', errors.rangeStart ? classes.fieldError : classes.field,
                      )}
                      decimalSeparator=","
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  )}
                />
                <ErrorMessage
                  name="depth"
                  component="span"
                  className={classes.errorMessage}
                />
              </FormGroup>
            </FormControl>
            <div style={{ marginTop: 12 }}>
              <PF2MAddButton disabled={isSubmitting || !isValid} />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );


  return (
    <div className={classes.tabContainer}>
      {renderHeader()}
      <div style={{ display: 'flex', marginBottom: 1, minHeight: 5 }}>
        <div>
          <Checkbox
            checked={drillholeDepthsInactive}
            onChange={() => setDrillholeDepthsInactive(!drillholeDepthsInactive)}
          />
        </div>
        <div>
          <p>{translate('common:ShowInactive')}</p>
        </div>
      </div>
      <PF2MTable
        data={sortByProperties(
          drillholeDepths,
          [
            { prop: 'group_equipament_links_name', type: 'string' },
            { prop: 'depth', type: 'number' },
          ],
        )}
        keyExtractor={item => `${item.id}_${item.depth.toString().replace(',', '')}`}
        columns={columns}
        updateItem={(row, field, value) => dispatch(setDrillholeDepthValue(row, field, value))}
      />
      <Footer
        isDirty={isDrillholeDepthsDirty}
        discard={() => {
          dispatch(resetDrillholeDepths());
          setDrillholeDepthsInactive(!drillholeDepthsInactive);
        }}
        sendData={() => {
          dispatch(updateDrillholeDepths(drillholeDepths));
          setDrillholeDepthsInactive(!drillholeDepthsInactive);
        }}
      />
    </div>
  );
};

DrillholeDepthTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DrillholeDepthTab);

import React, { useEffect, useState, useRef } from 'react';
import {
  withStyles, FormControl, FormLabel, FormGroup, Checkbox,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import Footer from '~/pages/Manager/Footer';
import PF2MTable from '~/components/PF2MTable';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import {
  getEquipmentTypes,
  getEquipmentsGroups,
  getDrillholeTypes,
  addDrillholeType,
  setDrillholeTypeValue,
  resetDrillholeTypes,
  updateDrillholeTypes,
} from '~/store/manager/actions';
import PF2MAddButton from '~/components/PF2MAddButton';
import { sortByProperties } from '~/utils';

const DrillholeTypeTab = ({ classes }) => {
  const equipTypes = useSelector(state => state.manager.equipmentTypes);
  const equipGroups = useSelector(state => state.manager.equipmentsGroups);
  const drillholeTypes = useSelector(state => state.manager.drillholeTypes);
  const isDrillholeTypesDirty = useSelector(state => state.manager.isDrillholeTypesDirty);
  const [selectedEquipType, setSelectedEquipType] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [selectedEquipGroup, setSelectedEquipGroup] = useState(0);
  const [drillholeTypeInactive, setDrillholeTypeInactive] = useState(false);
  const dispatch = useDispatch();
  const formikRef = useRef();
  const { t: translate } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      dispatch(getDrillholeTypes(true));
      dispatch(getEquipmentTypes());
      dispatch(getEquipmentsGroups());
    }
    fetchData();
  }, [dispatch]);

  const columns = [
    {
      field: 'equipment_type_name',
      title: translate('common:EquipmentType'),
    },
    {
      field: 'equipment_group_name',
      title: translate('common:EquipmentGroup'),
    },
    {
      field: 'name',
      title: translate('common:Name'),
      width: '100%',
      editable: true,
    },
    {
      field: 'active',
      title: 'STATUS',
      editable: true,
    },
  ];

  const getNameById = (id, list) => {
    const { name = '' } = list.find(r => r.id === id) || {};
    return name;
  };

  const renderHeader = () => (
    <Formik
      ref={formikRef}
      initialValues={{ name: '', equipType: 0, equipGroup: 0 }}
      validate={(values) => {
        const errors = {};
        const { name, equipType, equipGroup } = values;

        if (!name) {
          errors.name = translate('validation:RequiredField');
        }

        if (!equipType) {
          errors.equipType = translate('validation:RequiredField');
        }

        if (!equipGroup) {
          errors.equipGroup = translate('validation:RequiredField');
        }

        const existingType = drillholeTypes
          .some(d => (
            String(d.name).toLowerCase() === String(name).toLowerCase()
            && d.equipment_group_id === equipGroup
          ));
        if (existingType) {
          errors.name = translate('validation:AlreadyRegisteredName');
        }

        return errors;
      }}
      onSubmit={async (values, { resetForm }) => {
        const newValues = {
          name: values.name,
          equipment_group_id: values.equipGroup,
          equipment_type_id: values.equipType,
          equipment_group_name: getNameById(values.equipGroup, equipGroups),
          equipment_type_name: getNameById(values.equipType, equipTypes),
        };

        dispatch(addDrillholeType(newValues));
        resetForm();
      }}
    >
      {({
        isSubmitting,
        errors,
        isValid,
      }) => (
        <Form>
          <div style={{ display: 'flex', marginBottom: 15, minHeight: 75 }}>
            <FormControl>
              <FormLabel className={classes.formLabel}>
                {`${translate('common:EquipmentType')}:`}
              </FormLabel>
              <FormGroup>
                <Field
                  name="equipType"
                  render={({ field, form }) => (
                    <PF2MSearchSelectOutlined
                      {...field}
                      placeholder={null}
                      className={classnames(
                        form.errors[field.name] ? classes.fieldError : classes.field,
                      )}
                      onChange={async (e) => {
                        form.setFieldValue('equipType', e.target.value);
                        setSelectedEquipType(e.target.value);
                      }}
                    >
                      {[{ value: 0, label: translate('common:Select') }]
                        .concat(...equipTypes
                          .filter(e => ([4, 11, 16, 17, 18, 19].includes(e.id)))
                          .map(e => ({ value: e.id, label: e.name })))}
                    </PF2MSearchSelectOutlined>
                  )}
                />
                <ErrorMessage
                  name="equipType"
                  component="span"
                  className={classes.errorMessage}
                />
              </FormGroup>
            </FormControl>
            <FormControl style={{ marginLeft: 10 }}>
              <FormLabel className={classes.formLabel}>
                {`${translate('common:EquipmentGroup')}`}
              </FormLabel>
              <FormGroup>
                <Field
                  name="equipGroup"
                  render={({ field, form }) => (
                    <PF2MSearchSelectOutlined
                      {...field}
                      placeholder={null}
                      className={classnames(
                        form.error
                          && form.error[classes.field] ? classes.fieldError : classes.field,
                      )}
                      onChange={async (e) => {
                        form.setFieldValue('equipGroup', e.target.value);
                        setSelectedEquipGroup(e.target.value);
                      }}
                    >
                      {[{ value: 0, label: translate('common:Select') }]
                        .concat(...equipGroups
                          .filter(s => s.id_equipament === selectedEquipType)
                          .map(e => ({ value: e.id, label: e.name })))}
                    </PF2MSearchSelectOutlined>
                  )}
                />
                <ErrorMessage
                  name="equipGroup"
                  component="span"
                  className={classes.errorMessage}
                />
              </FormGroup>
            </FormControl>
            <FormControl className={classes.classGroupName} style={{ marginLeft: 10 }}>
              <FormLabel className={classes.formLabel}>
                {`${translate('common:HoleType')}:`}
              </FormLabel>
              <FormGroup>
                <Field
                  type="text"
                  name="name"
                  className={
                    classnames('form-control', errors.name ? classes.fieldError : classes.field)
                  }
                  style={{ width: '100%' }}
                />
                <ErrorMessage
                  name="name"
                  component="span"
                  className={classes.errorMessage}
                />
              </FormGroup>
            </FormControl>
            <div style={{ marginTop: 12 }}>
              <PF2MAddButton disabled={isSubmitting || !isValid} />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );

  return (
    <div className={classes.tabContainer}>
      {renderHeader()}
      <div style={{ display: 'flex', marginBottom: 1, minHeight: 5 }}>
        <div>
          <Checkbox
            checked={drillholeTypeInactive}
            onChange={() => setDrillholeTypeInactive(!drillholeTypeInactive)}
          />
        </div>
        <div>
          <p>{translate('common:ShowInactive')}</p>
        </div>
      </div>
      <PF2MTable
        data={sortByProperties(
          drillholeTypes,
          [{ prop: 'name', type: 'string' }],
        ).filter(d => (drillholeTypeInactive || d.active))
        }
        columns={columns}
        updateItem={(row, field, value) => dispatch(setDrillholeTypeValue(row, field, value))}
      />
      <Footer
        isDirty={isDrillholeTypesDirty}
        discard={() => {
          dispatch(resetDrillholeTypes());

          if (formikRef) {
            formikRef.current.resetForm({ name: '' });
          }
        }}
        sendData={() => {
          dispatch(updateDrillholeTypes(drillholeTypes));
          setDrillholeTypeInactive(false);
        }}
      />
    </div>
  );
};

DrillholeTypeTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DrillholeTypeTab);

import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PF2MTable from '~/components/PF2MTable';
import Footer from '~/pages/Manager/Footer';
import { useTranslation } from 'react-i18next';
import styles from '../styles';

import {
  getEquipmentTypes,
  getEquipmentsGroups,
  getCodes,
} from '~/store/manager/actions';

import {
  getWorkHourValues,
  exportWorkHourValues,
  importWorkHourValues,
} from '~/store/contracts/actions';

import Header from '~/pages/Manager/Header';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';

const WorkHourValueTab = ({
  classes,
  workHourValues,
  equipmentsGroups,
  equipmentTypes,
  codes,
  getWorkHourValues: getAll,
  getEquipmentTypes: loadEquipmentTypes,
  getEquipmentsGroups: loadEquipmentsGroups,
  getCodes: loadCodes,
  exportWorkHourValues: exportAction,
  importWorkHourValues: importAction,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [equipmentType, setEquipmentType] = useState(0);
  const [equipmentGroup, setEquipmentGroup] = useState(0);
  const [code, setCode] = useState(0);
  const { t: translate } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      getAll();
      loadEquipmentsGroups();
      loadEquipmentTypes();
      await loadCodes();
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, getAll, loadEquipmentsGroups, loadEquipmentTypes, loadCodes]);

  const columns = [
    { field: 'equipment_type_name', title: translate('common:EquipmentType'), width: 200 },
    { field: 'equip_group_name', title: translate('common:EquipmentGroup'), width: 200 },
    { field: 'code_name', title: translate('common:Code') },
    { field: 'value', title: translate('common:ValueMoneyTime') },
  ];

  return (
    <div className={classes.tabContainer}>
      <Header
        importAction={importAction}
        exportAction={exportAction}
      />
      <hr />
      <div className={classes.containerTypeSelector}>
        <div style={{ display: 'flex', alignItems: 'baseline', margin: 10 }}>
          <p className={classes.labelTypeSelector}>
            {translate('common:EquipmentType').toUpperCase()}
            :
          </p>
          <PF2MSearchSelectOutlined
            value={equipmentType}
            onChange={(e) => {
              setEquipmentType(e.target.value);
              setEquipmentGroup(0);
              setCode(0);
            }}
            placeholder={null}
          >
            {[{ value: 0, label: translate('common:Select') }]
              .concat(...equipmentTypes
                .map(e => ({ value: e.id, label: e.name })))}
          </PF2MSearchSelectOutlined>
        </div>
        <div style={{ display: 'flex', alignItems: 'baseline', margin: 10 }}>
          <p className={classes.labelTypeSelector}>
            {translate('common:EquipmentGroup').toUpperCase()}
            :
          </p>
          <PF2MSearchSelectOutlined
            value={equipmentGroup}
            onChange={e => setEquipmentGroup(e.target.value)}
            placeholder={null}
          >
            {[{ value: 0, label: translate('common:Select') }]
              .concat(...equipmentsGroups
                .filter(e => equipmentType === 0 || e.id_equipament === equipmentType)
                .map(e => ({ value: e.id, label: e.name })))}
          </PF2MSearchSelectOutlined>
        </div>
        <div style={{ display: 'flex', alignItems: 'baseline', margin: 10 }}>
          <p className={classes.labelTypeSelector}>
            {translate('common:Code').toUpperCase()}
            :
          </p>
          <PF2MSearchSelectOutlined
            value={code}
            onChange={e => setCode(e.target.value)}
            placeholder={null}
          >
            {[{ value: 0, label: translate('common:Select') }]
              .concat(...codes
                .filter(c => c.id_equip === equipmentType
                  && c.code_type === 2
                  && [1, 13].includes(c.id_group))
                .map(e => ({ value: e.id, label: e.name })))}
          </PF2MSearchSelectOutlined>
        </div>
      </div>
      <PF2MTable
        data={workHourValues
          .filter(f => (equipmentGroup === 0 || f.id_equip_group === equipmentGroup)
            && (equipmentType === 0 || f.id_equipment_type === equipmentType)
            && (code === 0 || f.id_code === code))}
        keyExtractor={item => `${item.id_code}${item.id_equipment_type}${item.id_equip_group}`}
        columns={columns}
        className={classes.grid}
      />
      <Footer />
    </div>
  );
};

WorkHourValueTab.propTypes = {
  classes: PropTypes.object.isRequired,
  workHourValues: PropTypes.array,
  equipmentTypes: PropTypes.array,
  equipmentsGroups: PropTypes.array,
  codes: PropTypes.array,
  getEquipmentTypes: PropTypes.func,
  getEquipmentsGroups: PropTypes.func,
  getWorkHourValues: PropTypes.func,
  exportWorkHourValues: PropTypes.func,
  importWorkHourValues: PropTypes.func,
  getCodes: PropTypes.func,
};

WorkHourValueTab.defaultProps = {
  workHourValues: [],
  equipmentTypes: [],
  equipmentsGroups: [],
  codes: [],
  getEquipmentTypes: null,
  getEquipmentsGroups: null,
  getWorkHourValues: null,
  exportWorkHourValues: null,
  importWorkHourValues: null,
  getCodes: null,
};

const mapStateToProps = state => ({
  workHourValues: state.contracts.workHourValues,
  equipmentTypes: state.manager.equipmentTypes,
  equipmentsGroups: state.manager.equipmentsGroups,
  codes: state.manager.codes,
});

const mapDispatchToProps = {
  getEquipmentTypes,
  getEquipmentsGroups,
  getWorkHourValues,
  exportWorkHourValues,
  importWorkHourValues,
  getCodes,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(WorkHourValueTab));

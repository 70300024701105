import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './styles';
import PF2MMenu from '~/components/PF2MMenu';
import PF2MScrollbar from '~/components/PF2MScrollbar';
import Main from '../Main';
import RpmRanges from './RpmRanges';
import HourmeterLimits from './HourmeterLimits';
import { ZendeskAPI } from 'react-zendesk';

const Telemetry = ({ classes, match }) => {
  const validateNavigation = true;

  const handleNavigation = (e, pathname = '') => {
    if (!validateNavigation) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      ZendeskAPI('webWidget', 'hide');
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Main>
      <div className={classes.container}>
        <PF2MScrollbar style={{ width: 150, padding: 10 }}>
          <PF2MMenu
            handleNavigation={handleNavigation}
            moduleName="telemetry"
          />
        </PF2MScrollbar>
        <div style={{ flex: 1 }}>
          <Switch>
            <Route exact path={`${match.path}/rpmranges`} component={RpmRanges} />
            <Route exact path={`${match.path}/hourmeterlimits`} component={HourmeterLimits} />
            <Redirect from={`${match.path}`} to={`${match.path}/rpmranges`} />
          </Switch>
        </div>
      </div>
    </Main>
  );
};


Telemetry.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

Telemetry.defaultProps = {};

export default withRouter(withStyles(styles)(Telemetry));

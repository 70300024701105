import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

export const CustomTextField = withStyles({
  root: {
    borderColor: '#CDCDCD',
    '& label': {
      left: 10,
    },
    '& legend': {
      marginLeft: 10,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: 20,
      },
    },
  },
})(TextField);

const MCTextField = ({ value, ...props }) => {
  const [text, setText] = useState(value);

  useEffect(() => {
    setText(value);
  }, [value]);

  return (
    <CustomTextField
      value={text}
      fullWidth
      onChange={e => setText(e.target.value)}
      {...props}
    />
  );
};

MCTextField.propTypes = {
  value: PropTypes.any,
};

MCTextField.defaultProps = {
  value: '',
};

export default MCTextField;

const styles = {
  button: {
    margin: 5,
  },
  buttonWhiteText: {
    color: 'white',
    margin: 5,
  },
  outlinedInput: {
    color: 'red',
    fontSize: 12,
    height: 2,
  },
  tableRow: {
    padding: 4,
  },
  input: {
    padding: 10,
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 14,
    textAlign: 'center',
    maxWidth: 60,
  },
  tableCell: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
    minWidth: 150,
    padding: 5,
  },
  table: {
    position: 'relative',
  },
  fab: {
    background: 'none',
    boxShadow: 'none',
    color: '#F2AA00',
    '&:hover': {
      backgroundColor: '#F1F1F1',
    },
  },
};

export default styles;

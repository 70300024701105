import React from 'react';
import PropTypes from 'prop-types';
import ArrowUp from '~/assets/arrow_up.svg';
import ArrowUpSelected from '~/assets/arrow_up_black.svg';
import ArrowDown from '~/assets/arrow_down.svg';
import ArrowDownSelected from '~/assets/arrow_down_black.svg';

const PF2MSortButton = ({
  label,
  field,
  sortField,
  sortOrder,
  onClick,
}) => (
  <>
    <button
      style={{
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
      }}
      type="button"
      onClick={onClick}
    >
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      >
        {label}
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          padding: 5,
          justifyContent: 'space-between',
        }}
        >
          {field ? (
            <>
              <img
                src={(sortOrder === 'asc' && sortField === field) ? ArrowUpSelected : ArrowUp}
                alt=""
                style={{ marginBottom: 5 }}
              />
              <img
                src={(sortOrder === 'desc' && sortField === field) ? ArrowDownSelected : ArrowDown}
                alt=""
              />
            </>
          ) : null}
        </div>
      </div>
    </button>
  </>
);

PF2MSortButton.propTypes = {
  label: PropTypes.string,
  field: PropTypes.string,
  sortField: PropTypes.string,
  sortOrder: PropTypes.string,
  onClick: PropTypes.func,
};

PF2MSortButton.defaultProps = {
  label: '',
  field: '',
  sortField: '',
  sortOrder: '',
  onClick: () => {},
};

export default PF2MSortButton;

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {
  Avatar, Button, Divider, IconButton,
} from '@material-ui/core';
import { getUsernameInitials } from '~/utils';
import { connect, useDispatch } from 'react-redux';
import { ExitToApp, Settings } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom/cjs/react-router-dom';
import PropTypes from 'prop-types';
import { paperProps, styles } from './styles';
import PF2MAlertDialog from '../PF2MAlertDialog';
import { logoutUser } from '~/store/auth/actions';

const UserMenu = ({ classes, username, version }) => {
  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    setIsOpen(false);
    dispatch(logoutUser());
  };

  return (
    <div className={classes.avatarContainer}>
      <IconButton
        className={classes.button}
        aria-controls="user-menu"
        aria-haspopup="true"
        onClick={handleClick}
        variant="contained"
        color="primary"
      >
        <Avatar>
          {' '}
          {getUsernameInitials(username)}
          {' '}
        </Avatar>

      </IconButton>
      <Menu
        id="user-menu"
        className={classes.menu}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={paperProps}
        getContentAnchorEl={null}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <span className={classes.menuTitle}>{username}</span>
        <MenuItem onClick={handleClose}>
          <Button
            component={React.forwardRef((props, ref) => (<NavLink {...props} ref={ref} />))}
            exact
            to="/configurations"
            variant="outlined"
            endIcon={<Settings />}
          >
            {translate('common:Settings')}
          </Button>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Button
            variant="outlined"
            endIcon={<ExitToApp />}
            onClick={() => setIsOpen(true)}
          >
            {translate('common:Logout')}
          </Button>
          <PF2MAlertDialog
            description={translate('validation:WouldYouLikeToExit')}
            open={isOpen}
            onClose={() => setIsOpen(false)}
            onConfirm={() => handleLogout()}
          />
        </MenuItem>
        <Divider />
        <span className={classes.menuFooter}>
          {translate('common:Version')}
          {' '}
          {version}
        </span>
      </Menu>
    </div>
  );
};

UserMenu.propTypes = {
  classes: PropTypes.object,
  username: PropTypes.string,
  version: PropTypes.string,
};

UserMenu.defaultProps = {
  classes: {},
  username: 'User',
  version: '0.0.0',
};

const mapStateToProps = state => ({
  username: state.auth.user.username,
  version: state.auth.version,
});

export default connect(mapStateToProps, {})(withStyles(styles)(UserMenu));

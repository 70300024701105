import React, { useState, useEffect } from 'react';
import {
  withStyles, FormControl, FormLabel, FormGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import Footer from '~/pages/Manager/Footer';
import PF2MTable from '~/components/PF2MTable';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import {
  getCodeGroups,
  addCodeGroup,
  setCodeGroupValue,
  updateCodeGroups,
  resetCodeGroups,
  getCodeTypes,
} from '~/store/underground/actions';
import PF2MAddButton from '~/components/PF2MAddButton';

const validateName = (codeGroups, codeType, name) => {
  if (codeGroups.some(c => c.id_code_type === codeType && c.name === name)) {
    return false;
  }
  return true;
};

const TurnsTab = ({ classes }) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const isCodeGroupsDirty = useSelector(state => state.underground.isCodeGroupsDirty);
  const codeTypes = useSelector(state => state.underground.codeTypes);
  const codeGroups = useSelector(state => state.underground.codeGroups);
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      dispatch(getCodeTypes());
      await dispatch(getCodeGroups());
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch]);

  const columns = [
    {
      field: 'code_type_name', title: translate('common:CodeType'),
    },
    {
      field: 'name', title: translate('common:CodeGroups'), width: '100%', editable: true,
    },
    {
      field: 'category',
      title: translate('common:Category'),
      type: 'boolean',
      options: [
        { id: '0', name: translate('common:OperationalStop') },
        { id: '1', name: translate('common:Operation') },
      ],
      editable: true,
    },
    {
      field: 'active',
      title: 'STATUS',
      editable: true,
    },
  ];

  const renderHeader = () => (
    <Formik
      initialValues={{ name: '', codeType: 0 }}
      validate={(values) => {
        const errors = {};
        if (!values.name) {
          errors.name = translate('validation:RequiredField');
        }
        if (values.codeType <= 0) {
          errors.codeType = translate('validation:RequiredField');
        }
        if (!validateName(codeGroups, values.codeType, values.name)) {
          errors.name = translate('validation:AlreadyRegisteredName');
        }
        return errors;
      }}
      onSubmit={async (values, { resetForm }) => {
        const { codeType, name } = values;
        const selectedCodeType = codeTypes.find(t => t.id === codeType);
        const newItem = {
          name,
          id_code_type_ug: codeType,
          category: 0,
          code_type_name: selectedCodeType.name,
        };

        await dispatch(addCodeGroup(newItem));
        resetForm();
      }}
    >
      {({
        isSubmitting,
        errors,
        isValid,
      }) => (
        <Form style={{ display: 'flex', alignItems: 'center' }}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              {`${translate('common:CodeType')}:`}
            </FormLabel>
            <FormGroup>
              <Field
                name="codeType"
                render={({ field, form }) => (
                  <PF2MSearchSelectOutlined
                    {...field}
                    placeholder={null}
                    onChange={e => form.setFieldValue('codeType', e.target.value)}
                    className={classnames(
                      form.errors[field.name] ? classes.fieldError : classes.field,
                    )}
                  >
                    {[{ value: 0, label: translate('common:Select') }]
                      .concat(...codeTypes
                        .map(e => ({ value: e.id, label: e.name })))}
                  </PF2MSearchSelectOutlined>
                )}
              />
            </FormGroup>
          </FormControl>
          <FormControl className={classes.classGroupName}>
            <FormLabel className={classes.formLabel}>
              {`${translate('common:Name')}:`}
            </FormLabel>
            <FormGroup>
              <Field
                type="text"
                name="name"
                className={classnames('form-control', errors.name ? classes.fieldError : classes.field)}
              />
            </FormGroup>
          </FormControl>
          <div style={{ marginTop: 15 }}>
            <PF2MAddButton disabled={isSubmitting || !isValid} />
          </div>
        </Form>
      )}
    </Formik>
  );

  return (
    <div className={classes.tabContainer}>
      {renderHeader()}
      <PF2MTable
        data={codeGroups}
        columns={columns}
        updateItem={(row, field, value) => dispatch(setCodeGroupValue(row, field, value))}
      />
      <Footer
        isDirty={isCodeGroupsDirty}
        discard={() => dispatch(resetCodeGroups())}
        sendData={() => dispatch(updateCodeGroups(codeGroups))}
      />
    </div>
  );
};

TurnsTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

TurnsTab.defaultProps = {};

export default withStyles(styles)(TurnsTab);

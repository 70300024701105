import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import PF2MSelectOutlined from '~/components/PF2MSelectOutlined';
import PF2MOutlinedInput from '~/components/PF2MOutlinedInput';
import PF2MDatePickerOutlined from '~/components/PF2MDatePickerOutlined';

const colors = [
  '#C2EFF2',
  '#FFEDA0',
  '#F2A6A6',
  '#FAC382',
  '#CAF5AF',
  '#A5CCE8',
  '#D6C2FF',
  '#FCA8FE',
  '#EAEAEA',
  '#E4C4C4',
];

function Header({
  classes,
  setCalendarTurnsTeams,
  turnsGroups,
  teamGroups,
  handleModal,
  turnGroup,
  teamGroup,
  color,
  startDate,
  endDate,
  handleTurnGroup,
  handleTeamGroup,
  handleColor,
  handleStartDate,
  handleEndDate,
  isCalendarTurnsTeamsDirty,
  setAlertDialog,
}) {
  const { t: translate } = useTranslation();

  const renderTurnGroups = () => [
    { value: 0, label: translate('common:Select') },
    ...turnsGroups
      .map(e => ({ value: e.id, label: e.name })),
  ];

  const renderTeamGroups = () => [
    { value: 0, label: translate('common:Select') },
    ...teamGroups
      .map(e => ({ value: e.id, label: e.name })),
  ];

  const renderColors = () => [
    <MenuItem key={0} value="">-&nbsp;</MenuItem>,
    ...colors
      .map(e => (
        <MenuItem key={e} value={e}>
          <div
            style={{
              width: 20,
              backgroundColor: e,
            }}
          >
            &nbsp;
          </div>
        </MenuItem>
      )),
  ];

  const validateChange = (selectedColor = '', selectedStartDate = null, selectedEndDate = null) => {
    if (selectedColor && selectedStartDate.isValid() && selectedEndDate.isValid()) {
      setCalendarTurnsTeams({
        id_turn_group: turnGroup,
        id_team_group: teamGroup,
        color: selectedColor,
        start_date: selectedStartDate,
        end_date: selectedEndDate,
      });
    }
  };

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <span className={classes.labelTypeSelector}>
          {`${translate('common:FilterBy')}:`}
        </span>
        <FormControl style={{ margin: 5 }}>
          <FormLabel className={classes.formLabel}>
            {`${translate('common:ShiftsGroup')}:`}
          </FormLabel>
          <FormGroup>
            <PF2MSearchSelectOutlined
              outline="true"
              fullWidth={false}
              value={turnGroup}
              onChange={e => handleTurnGroup(e.target.value)}
              style={{ width: 150 }}
            >
              {renderTurnGroups()}
            </PF2MSearchSelectOutlined>
          </FormGroup>
        </FormControl>
        <FormControl>
          <FormLabel className={classes.formLabel}>
            {`${translate('common:TeamsGroup')}:`}
          </FormLabel>
          <FormGroup>
            <PF2MSearchSelectOutlined
              outline="true"
              fullWidth={false}
              value={teamGroup}
              onChange={(e) => {
                if (isCalendarTurnsTeamsDirty) {
                  e.preventDefault();
                  setAlertDialog();
                } else {
                  handleTeamGroup(e.target.value);
                }
              }}
            >
              {renderTeamGroups()}
            </PF2MSearchSelectOutlined>
          </FormGroup>
        </FormControl>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <FormControl>
          <FormLabel className={classes.formLabel}>
            {`${translate('common:Color')}:`}
          </FormLabel>
          <FormGroup>
            <PF2MSelectOutlined
              outline="true"
              fullWidth={false}
              input={<PF2MOutlinedInput />}
              style={{ width: 60 }}
              value={color}
              onChange={(e) => {
                handleColor(e.target.value);
                validateChange(e.target.value, startDate, endDate);
              }}
            >
              {renderColors()}
            </PF2MSelectOutlined>
          </FormGroup>
        </FormControl>
        <FormControl style={{ margin: 5 }}>
          <FormLabel className={classes.formLabel}>
            {`${translate('common:InitialDate')}:`}
          </FormLabel>
          <FormGroup>
            <PF2MDatePickerOutlined
              disableFuture={false}
              value={startDate}
              onChange={(e) => {
                handleStartDate(e);
                validateChange(color, e, endDate);
              }}
            />
          </FormGroup>
        </FormControl>
        <FormControl>
          <FormLabel className={classes.formLabel}>
            {`${translate('common:FinalDate')}:`}
          </FormLabel>
          <FormGroup>
            <PF2MDatePickerOutlined
              disableFuture={false}
              value={endDate}
              onChange={(e) => {
                handleEndDate(e);
                validateChange(color, startDate, e);
              }}
            />
          </FormGroup>
        </FormControl>
        <FormControl>
          <FormGroup>
            <Button
              color="secondary"
              style={{ marginTop: 14, marginLeft: 5 }}
              onClick={(e) => {
                e.preventDefault();
                handleModal(true);
              }}
              disabled={!turnGroup || !teamGroup}
            >
              {translate('common:AddEditPause')}
            </Button>
          </FormGroup>
        </FormControl>
      </div>
    </>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  setCalendarTurnsTeams: PropTypes.func,
  turnsGroups: PropTypes.array,
  teamGroups: PropTypes.array,
  handleTurnGroup: PropTypes.func,
  handleModal: PropTypes.func,
  turnGroup: PropTypes.number,
  teamGroup: PropTypes.number,
  color: PropTypes.any,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  handleTeamGroup: PropTypes.func,
  handleColor: PropTypes.func,
  handleStartDate: PropTypes.func,
  handleEndDate: PropTypes.func,
  isCalendarTurnsTeamsDirty: PropTypes.bool,
  setAlertDialog: PropTypes.func,
};

Header.defaultProps = {
  turnsGroups: [],
  teamGroups: [],
  setCalendarTurnsTeams: null,
  handleTurnGroup: null,
  handleModal: null,
  turnGroup: null,
  teamGroup: null,
  color: null,
  startDate: null,
  endDate: null,
  handleTeamGroup: null,
  handleColor: null,
  handleStartDate: null,
  handleEndDate: null,
  isCalendarTurnsTeamsDirty: false,
  setAlertDialog: null,
};


export default withStyles(styles)(Header);

import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PF2MTable from '~/components/PF2MTable';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import styles from '../styles';

import {
  getEquipmentTypes,
  getEquipmentsGroups,
  getMaterials,
} from '~/store/manager/actions';

import {
  getMaterialsCost,
  exportMaterialsCost,
  importMaterialsCost,
} from '~/store/contracts/actions';

import Header from '~/pages/Manager/Header';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';

const MaterialCostTab = ({
  classes,
  materialsCost,
  equipmentsGroups,
  equipmentTypes,
  materials,
  getMaterialsCost: getAll,
  getEquipmentTypes: loadEquipmentTypes,
  getEquipmentsGroups: loadEquipmentsGroups,
  getMaterials: loadMaterials,
  exportMaterialsCost: exportAction,
  importMaterialsCost: importAction,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [equipmentType, setEquipmentType] = useState(0);
  const [equipmentGroup, setEquipmentGroup] = useState(0);
  const [date, setDate] = useState(new Date());
  const [material, setMaterial] = useState(0);
  const { t: translate } = useTranslation();
  const handleMask = value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : []);

  useEffect(() => {
    async function fetchData() {
      await getAll({ date: `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}` });
      await loadEquipmentsGroups();
      await loadEquipmentTypes();
      await loadMaterials();
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
      fetchData();
    }
  }, [dataLoaded, getAll, loadEquipmentsGroups, loadEquipmentTypes, loadMaterials, date]);

  const dateCompareFilter = (valueStart, valueEnd) => {
    const valueStartArray = valueStart.split('/');
    const valueStartDate = new Date(valueStartArray[2],
      valueStartArray[1] - 1,
      valueStartArray[0],
      0,
      0,
      0);
    const valueEndArray = valueEnd.split('/');
    const valueEndDate = new Date(valueEndArray[2],
      valueEndArray[1] - 1,
      valueEndArray[0],
      23,
      59,
      59);
    return date >= valueStartDate && date <= valueEndDate;
  };

  const columns = [
    { field: 'start_date', title: translate('common:StartDate'), width: 200 },
    { field: 'end_date', title: translate('common:EndDate'), width: 200 },
    { field: 'equipment_type_name', title: translate('common:EquipmentType'), width: 200 },
    { field: 'equip_group_name', title: translate('common:EquipmentGroup'), width: 200 },
    { field: 'name', title: translate('common:Range') },
    { field: 'material_name', title: translate('common:Material') },
    { field: 'cost', title: translate('common:CostMoneyTime') },
  ];

  return (
    <div className={classes.tabContainer}>
      <Header
        importAction={(...args) => { importAction(...args); setDataLoaded(false); }}
        exportAction={() => exportAction({ date: `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}` })}
        getTemplateAction={() => exportAction({ template: true })}
      />
      <hr />
      <div className={classes.containerTypeSelector}>
        <div style={{ display: 'flex', alignItems: 'baseline', margin: 10 }}>
          <p className={classes.labelTypeSelector}>
            {translate('common:Date').toUpperCase()}
            :
          </p>
          <KeyboardDatePicker
            clearable
            mask={handleMask}
            format={translate('date:DateFormat')}
            name={'date'}
            value={date}
            // eslint-disable-next-line no-underscore-dangle
            onChange={(newDate) => { setDate(newDate._d); setDataLoaded(false); }}
            autoOk
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'baseline', margin: 10 }}>
          <p className={classes.labelTypeSelector}>
            {translate('common:EquipmentType').toUpperCase()}
            :
          </p>
          <PF2MSearchSelectOutlined
            value={equipmentType}
            onChange={e => setEquipmentType(e.target.value)}
            placeholder={null}
          >
            {[{ value: 0, label: translate('common:Select') }]
              .concat(...equipmentTypes
                .map(e => ({ value: e.id, label: e.name })))}
          </PF2MSearchSelectOutlined>
        </div>
        <div style={{ display: 'flex', alignItems: 'baseline', margin: 10 }}>
          <p className={classes.labelTypeSelector}>
            {translate('common:EquipmentGroup').toUpperCase()}
            :
          </p>
          <PF2MSearchSelectOutlined
            value={equipmentGroup}
            onChange={e => setEquipmentGroup(e.target.value)}
            placeholder={null}
          >
            {[{ value: 0, label: translate('common:Select') }]
              .concat(...equipmentsGroups
                .filter(e => equipmentType === 0 || e.id_equipament === equipmentType)
                .map(e => ({ value: e.id, label: e.name })))}
          </PF2MSearchSelectOutlined>
        </div>
        <div style={{ display: 'flex', alignItems: 'baseline', margin: 10 }}>
          <p className={classes.labelTypeSelector}>
            {translate('common:Material').toUpperCase()}
            :
          </p>
          <PF2MSearchSelectOutlined
            value={material}
            onChange={e => setMaterial(e.target.value)}
            placeholder={null}
          >
            {[{ value: 0, label: translate('common:Select') }]
              .concat(...materials
                .map(e => ({ value: e.id, label: e.name })))}
          </PF2MSearchSelectOutlined>
        </div>
      </div>
      <PF2MTable
        data={materialsCost
          .filter(f => (equipmentGroup === 0 || f.id_equip_group === equipmentGroup)
            && (equipmentType === 0 || f.id_equipment_type === equipmentType)
            && (material === 0 || f.id_material === material)
            && (dateCompareFilter(f.start_date, f.end_date)))}
        keyExtractor={item => `${item.id_dmt_ranges}${item.id_equip_group}${item.id_material}`}
        columns={columns}
        className={classes.grid}
      />

    </div>
  );
};

MaterialCostTab.propTypes = {
  classes: PropTypes.object.isRequired,
  materialsCost: PropTypes.array,
  equipmentTypes: PropTypes.array,
  equipmentsGroups: PropTypes.array,
  materials: PropTypes.array,
  getEquipmentTypes: PropTypes.func,
  getEquipmentsGroups: PropTypes.func,
  getMaterialsCost: PropTypes.func,
  exportMaterialsCost: PropTypes.func,
  importMaterialsCost: PropTypes.func,
  getMaterials: PropTypes.func,
};

MaterialCostTab.defaultProps = {
  materialsCost: [],
  equipmentTypes: [],
  equipmentsGroups: [],
  materials: [],
  getEquipmentTypes: null,
  getEquipmentsGroups: null,
  getMaterialsCost: null,
  exportMaterialsCost: null,
  importMaterialsCost: null,
  getMaterials: null,
};

const mapStateToProps = state => ({
  materialsCost: state.contracts.materialsCost,
  equipmentTypes: state.manager.equipmentTypes,
  equipmentsGroups: state.manager.equipmentsGroups,
  materials: state.manager.materials,
});

const mapDispatchToProps = {
  getEquipmentTypes,
  getEquipmentsGroups,
  getMaterialsCost,
  exportMaterialsCost,
  importMaterialsCost,
  getMaterials,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MaterialCostTab));

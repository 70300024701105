import React, { useState } from 'react';
import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  useSelector, useDispatch,
  shallowEqual,
} from 'react-redux';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import PF2MSortButton from '~/components/PF2MSortButton';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import PF2MScrollbar from '~/components/PF2MScrollbar';
import {
  setTractorValues,
  deleteTractorItem,
  restoreTractorItem,
  searchTractorList,
  updateTractorList,
  exportTractorReport,
  restoreOriginalTractor,
  createTractorItem,
} from '~/store/dailyparts/actions';
import { dailyPartsGridHeight } from '~/utils';
import { useTranslation } from 'react-i18next';
import Header from '../Header';
import styles from '../styles';
import ListRow from './ListRow';

const TRACTOR = 13;
const TractorList = ({ classes }) => {
  const { t: translate } = useTranslation();
  const titles = [
    { label: translate('common:CycleInitialDate'), field: 'start_date' },
    { label: translate('common:CycleInitialTime'), field: 'start_time' },
    { label: translate('common:CycleFinalDate'), field: 'end_date' },
    { label: translate('common:CycleFinalTime'), field: 'end_time' },
    { label: translate('common:EquipmentGroup'), field: 'equip_group_id' },
    { label: translate('common:Equipment'), field: 'equip_id' },
    { label: translate('common:OperatorGroup'), field: 'operator_group_id' },
    { label: translate('common:Operator'), field: 'operator_id' },
    { label: translate('common:ReturnDate'), field: 'return_date' }, // tempo de retorno
    { label: translate('common:ReturnTime'), field: 'return_time' }, // tempo de retorno
    { label: translate('common:ReturnLatitude'), field: 'return_latitude' },
    { label: translate('common:ReturnLongitude'), field: 'return_longitude' },
    { label: translate('common:ReturnAltitude'), field: 'return_altitude' },
    { label: translate('common:ReturnElement'), field: 'return_gps_area' },
    { label: translate('common:ReturnSubElement'), field: 'return_gps_subarea' },
    { label: translate('common:ReturnDistance'), field: 'dist_return' },
    { label: translate('common:ReturnDuration'), field: 'return_duration' },
    { label: translate('common:CutDate'), field: 'cut_date' }, // tempo de corte
    { label: translate('common:CutTime'), field: 'cut_time' }, // tempo de corte
    { label: translate('common:CutLatitude'), field: 'cut_latitude' },
    { label: translate('common:CutLongitude'), field: 'cut_longitude' },
    { label: translate('common:CutAltitude'), field: 'cut_altitude' },
    { label: translate('common:CutElement'), field: 'cut_gps_area' },
    { label: translate('common:CutSubElement'), field: 'cut_gps_subarea' },
    { label: translate('common:Material'), field: 'material' },
    { label: translate('common:OperationType'), field: 'cut_type' },
    { label: translate('common:Productivity'), field: 'prod' },
    { label: translate('common:CutDistance'), field: 'dist_cut' },
    { label: translate('common:CutDuration'), field: 'cut_duration' },
    { label: translate('common:CodeTime'), field: 'code_time' },
    { label: translate('common:Shift'), field: 'turn' },
    { label: translate('common:CycleDuration'), field: 'duration' },
  ];

  const filters = useSelector(state => state.dailyParts.filters);
  const page = useSelector(state => state.dailyParts.page);
  const limit = useSelector(state => state.dailyParts.limit);
  const { field, order } = useSelector(state => state.dailyParts.tractorSortBy);

  const tractorList = useSelector(state => state.dailyParts.tractorList);
  const turns = useSelector(state => state.manager.turns);
  const equipmentsGroups = useSelector(state => state.manager.equipmentsGroups
    .filter(e => e.id_equipament === TRACTOR), shallowEqual);
  const equipments = useSelector(state => state.manager.equipments
    .filter(e => e.id_equip === TRACTOR), shallowEqual);
  const operatorsGroups = useSelector(state => state.manager.operatorsGroups
    .filter(o => o.name), shallowEqual);
  const operators = useSelector(state => state.manager.operators.filter(o => o.name), shallowEqual);
  const elements = useSelector(state => state.manager.elements);
  const subElements = useSelector(state => state.manager.subElements);
  const materials = useSelector(state => state.manager.materials);
  const isTractorListDirty = useSelector(state => state.dailyParts.isTractorListDirty);

  const [sortBy, setSortBy] = useState({ field, order });
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const dispatch = useDispatch();

  const handleSort = (title) => {
    if (tractorList.some(t => t.isDirty)) {
      setIsAlertOpen(true);
    } else {
      const newOrder = (sortBy.order === 'asc' && sortBy.field === title.field) ? 'desc' : 'asc';
      const newSortBy = { field: title.field, order: newOrder };
      setSortBy(newSortBy);
      dispatch(searchTractorList(filters, page, limit, newSortBy));
    }
  };

  const renderTableTitle = (title, key) => (
    <TableCell
      align="center"
      className={classes.tableCell}
      key={key}
      style={{
        position: 'sticky',
        top: 0,
        backgroundColor: 'white',
        zIndex: 1,
      }}
    >
      <PF2MSortButton
        label={title.label}
        field={title.field}
        sortField={sortBy.field}
        sortOrder={sortBy.order}
        onClick={() => handleSort(title)}
      />
    </TableCell>
  );

  const renderTableHeader = () => titles.map((t, i) => renderTableTitle(t, `${i}_${t}`));

  const renderRows = () => tractorList
    .map(d => (
      <ListRow
        key={d.id}
        data={d}
        onDelete={data => dispatch(deleteTractorItem(data))}
        onRestore={data => dispatch(restoreTractorItem(data))}
        onEdit={(item, name, value) => dispatch(setTractorValues(item, name, value))}
        classes={classes}
        turns={turns}
        equipmentsGroups={equipmentsGroups}
        equipments={equipments}
        operatorsGroups={operatorsGroups}
        operators={operators}
        elements={elements}
        subElements={subElements}
        materials={materials}
      />
    ));

  const renderLoadMore = () => {
    if (tractorList.length < limit) return null;
    if (tractorList.filter(d => d.id > 0).length < 1) { return null; }
    return (
      <div style={{
        backgroundColor: '#F9F9F9',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 10,
        paddingBottom: 10,
      }}
      >
        <Button
          onClick={() => dispatch(searchTractorList(filters, (page + 1), limit, sortBy, false))}
        >
          <ArrowDownIcon />
          <Typography
            variant="h5"
          >
            {translate('common:SeeMore')}
          </Typography>
        </Button>
      </div>
    );
  };

  return (
    <>
      <Header
        updateData={(data, filter) => dispatch(updateTractorList(data, filter))}
        exportData={parameters => dispatch(exportTractorReport(parameters))}
        restoreData={() => dispatch(restoreOriginalTractor())}
        createData={filter => dispatch(createTractorItem(filter))}
        data={tractorList}
        filters={filters}
        isDirty={isTractorListDirty}
      />
      {tractorList.length > 0 ? (
        <PF2MScrollbar style={{ backgroundColor: 'white', height: dailyPartsGridHeight }}>
          <>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {renderTableHeader()}
                </TableRow>
              </TableHead>
              <TableBody>
                {renderRows()}
              </TableBody>
            </Table>
            {renderLoadMore()}
            <PF2MAlertDialog
              hasCancel={false}
              confirmText={translate('common:Understood')}
              description={translate('validation:YouHaveUnsavedData')}
              open={isAlertOpen}
              onConfirm={() => setIsAlertOpen(false)}
            />
          </>
        </PF2MScrollbar>
      ) : (
        <div
          className="container"
          style={{
            backgroundColor: 'white',
            padding: 20,
          }}
        >
          <span>{translate('common:NoEntries')}</span>
        </div>
      )}

    </>
  );
};

TractorList.propTypes = {
  classes: PropTypes.object.isRequired,
};

TractorList.defaultProps = {};

export default withStyles(styles)(TractorList);

import React, { useState, useEffect } from 'react';
import {
  withStyles, FormControl, FormLabel, FormGroup, Fab,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import { useTranslation } from 'react-i18next';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Clear, Done } from '@material-ui/icons';
import { MTableBodyRow } from 'material-table';
import styles from '../styles';
import {
  setMaintenanceManagementFilter,
} from '~/store/maintenance/actions';
import {
  getAnomalyLogs, removeAllAnomalyLogsFromList, removeAnomalyLog, updateAnomalyLogs,
} from '~/store/dispatch/actions';
import {
  getCodeGroups, getCodeTypes, getCodes, getEquipmentTypes, getEquipments,
  getEquipmentsGroups, getOperators, getOperatorsGroups,
} from '~/store/manager/actions';
import PF2MDataTable, { icons } from '~/components/PF2MDataTable';
import { getFilters, saveFilters } from '~/services/cache';
import PF2MSearchButton from '~/components/PF2MSearchButton';
import PF2MAutocomplete from '~/components/PF2MAutocomplete';
import { formatDurationAsTime } from '~/utils/moment';

function allowEdit(data) {
  return data.allow_edit;
}

const formatAnomalyLogs = aLog => ({
  ...aLog,
  timestamp: aLog.timestamp * 1000,
  timestamp_usn: aLog.timestamp_usn * 1000,
});

const MaintenanceManagerTab = ({ classes }) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const anomalyLogs = useSelector(state => state.dispatch.anomalyLogs);
  const equipmentTypes = useSelector(state => state.manager.equipmentTypes);
  const equipments = useSelector(state => state.manager.equipments);
  const equipmentsGroups = useSelector(state => state.manager.equipmentsGroups);
  const maintenanceManagementFilter = useSelector(
    state => state.maintenance.maintenanceManagementFilter,
  );
  const codeTypes = useSelector(state => state.manager.codeTypes);
  const codeGroups = useSelector(state => state.manager.codeGroups);
  const codes = useSelector(state => state.manager.codes);
  const operatorsGroups = useSelector(state => state.manager.operatorsGroups);
  const operators = useSelector(state => state.manager.operators);
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      const savedFilters = getFilters('MaintenanceManagerTab', 'user');
      const formattedDate = new Date();
      if (savedFilters) {
        dispatch(setMaintenanceManagementFilter({
          ...savedFilters, date: formattedDate,
        }));
      }
      dispatch(getEquipmentTypes());
      dispatch(getEquipmentsGroups());
      dispatch(getEquipments());
      dispatch(getCodeTypes());
      dispatch(getCodeGroups());
      dispatch(getCodes());
      dispatch(getOperatorsGroups());
      dispatch(getOperators());
      await dispatch(getAnomalyLogs({ equips: savedFilters?.equips.map(e => e.value) ?? '', date: formattedDate }));
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch]);

  const handleChangeFilter = (name, value) => {
    dispatch(setMaintenanceManagementFilter({ name, value }));
    saveFilters('MaintenanceManagerTab', { ...maintenanceManagementFilter, [name]: value }, 'user');
  };

  const renderAcceptOrDenyCell = rowData => (
    <div className={classes.acceptOrDenyCell}>
      <Fab
        size="small"
        color="secondary"
        aria-label="Check"
        className={classes.fab}
        onClick={() => {
          dispatch(removeAnomalyLog({
            ...rowData,
            timestamp: rowData.timestamp / 1000,
            exception_type: 15,
          }));
        }}
      >
        <Done />
      </Fab>
      <Fab
        size="small"
        color="secondary"
        aria-label="Deny"
        className={classes.fab}
        onClick={() => {
          dispatch(removeAnomalyLog({
            ...rowData,
            timestamp: rowData.timestamp / 1000,
            exception_type: 16,
          }));
        }}
      >
        <Clear />
      </Fab>
    </div>
  );

  const columns = [
    {
      field: 'accept',
      title: translate('common:Accept'),
      render: (rowData, column) => renderAcceptOrDenyCell(rowData, column),
    },
    {
      field: 'anomaly_type_name',
      title: translate('common:Issue'),
      render: rowData => translate(`common:${rowData.anomaly_type_name}`),
    },
    {
      field: 'timestamp',
      title: translate('common:Date'),
      editable: 'never',
      updateOnChange: true,
      customType: 'dateTimeByTimestamp',
    },
    {
      field: 'timestamp_usn',
      title: translate('common:EndDate'),
      editable: 'never',
      updateOnChange: true,
      customType: 'dateTimeByTimestamp',
    },
    {
      field: 'duration',
      title: translate('common:Duration'),
      editable: 'never',
      updateOnChange: true,
      render: rowData => `${formatDurationAsTime(rowData.timestamp_usn - rowData.timestamp)}`,
    },
    {
      field: 'code_type',
      title: translate('common:CodeType'),
      editable: 'never',
      updateOnChange: true,
      lookup: codeTypes.map(cType => ({
        id: cType.id, name: translate(`common:${cType.name}`),
      })),
    },
    {
      field: 'code_group',
      title: translate('common:CodeGroup'),
      editable: 'never',
      lookup: codeGroups,
    },
    {
      field: 'code_id',
      title: translate('common:Code'),
      editable: 'never',
      lookup: codes,
    },
    {
      field: 'equipment_groups_id',
      title: translate('common:EquipmentGroup'),
      editable: 'never',
      lookup: equipmentsGroups,
    },
    {
      field: 'id_equipment',
      title: translate('common:Equipment'),
      editable: 'never',
      lookup: equipments,
    },
    {
      field: 'operator_group_id',
      title: translate('common:OperatorGroup'),
      editable: 'never',
      lookup: operatorsGroups,
    },
    {
      field: 'operator_id',
      title: translate('common:Operator'),
      editable: 'never',
      lookup: operators,
      lookupKey: 'id_operator',
    },
    {
      field: 'status',
      title: 'Status',
      editFunc: allowEdit,
      editable: 'always',
      customType: 'boolean',
    },
  ];

  const renderHeader = () => (
    <Formik
      onSubmit={async () => {
        // const selectedCodeGroup = codeGroups.find(t => t.id === codeGroup);
        await dispatch(getAnomalyLogs({
          equips: maintenanceManagementFilter.equips.map(e => e.value),
          date: maintenanceManagementFilter.date,
        }));
      }}
    >
      {({
        submitForm,
      }) => (
        <Form style={{ display: 'flex', minHeight: 75 }}>
          <FormControl className={classes.formControl}>
            <FormLabel className={classes.formLabel}>
              {`${translate('common:Date')}:`}
            </FormLabel>
            <FormGroup>
              <Field
                name="date"
                render={({ field }) => (
                  <KeyboardDatePicker
                    {...field}
                    clearable
                    inputVariant="outlined"
                    InputProps={{
                      style: {
                        height: 40,
                        width: 200,
                        color: '#647886',
                      },
                    }}
                    value={maintenanceManagementFilter.date}
                    format={translate('date:DateFormat')}
                    autoOk
                    onChange={(e) => {
                      handleChangeFilter('date', e);
                    }}
                    invalidDateMessage={translate('validation:InvalidDate')}
                  />
                )}
              />
            </FormGroup>
          </FormControl>
          <FormControl className={classes.formControl}>
            <FormLabel className={classes.formLabel}>
              {`${translate('common:EquipmentType')}:`}
            </FormLabel>
            <FormGroup>
              <Field
                name="equipTypes"
                render={() => (
                  <PF2MAutocomplete
                    options={
                      equipmentTypes
                        .map(e => ({ value: e.id, label: e.name }))
                    }
                    onChange={(_, v) => {
                      handleChangeFilter('equipTypes', v);
                    }}
                    value={maintenanceManagementFilter.equipTypes}
                    limitTags={1}
                  />
                )}
              />
              <ErrorMessage
                name="equipType"
                component="span"
                className={classes.errorMessage}
              />
            </FormGroup>
          </FormControl>
          <FormControl className={classes.formControl}>
            <FormLabel className={classes.formLabel}>
              {`${translate('common:EquipmentGroup')}:`}
            </FormLabel>
            <FormGroup>
              <Field
                name="equipGroups"
                render={() => (
                  <PF2MAutocomplete
                    options={
                      equipmentsGroups
                        .filter(e => maintenanceManagementFilter.equipTypes.some(
                          t => t.value === e.id_equipament,
                        ))
                        .map(e => ({ value: e.id, label: e.name }))
                    }
                    onChange={(_, v) => {
                      handleChangeFilter('equipGroups', v);
                    }}
                    value={maintenanceManagementFilter.equipGroups}
                    limitTags={1}
                  />
                )}
              />
              <ErrorMessage
                name="equipGroup"
                component="span"
                className={classes.errorMessage}
              />
            </FormGroup>
          </FormControl>
          <FormControl style={{ marginLeft: 10 }} className={classes.formControl}>
            <FormLabel className={classes.formLabel}>
              {`${translate('common:Equipment')}:`}
            </FormLabel>
            <FormGroup>
              <Field
                name="equips"
                render={() => (
                  <PF2MAutocomplete
                    options={
                      equipments
                        .filter(e => maintenanceManagementFilter.equipGroups.some(
                          t => t.value === e.id_group,
                        ))
                        .map(e => ({ value: e.id, label: e.name }))
                    }
                    onChange={(_, v) => {
                      handleChangeFilter('equips', v);
                    }}
                    value={maintenanceManagementFilter.equips}
                    limitTags={1}
                  />
                )}
              />
              <ErrorMessage
                name="equip"
                component="span"
                className={classes.errorMessage}
              />
            </FormGroup>
          </FormControl>
          <div style={{ marginTop: 15 }}>
            <PF2MSearchButton
              className={classes.searchButton}
              onClick={submitForm}
            />
          </div>
        </Form>
      )}
    </Formik>
  );

  return (
    <div className={classes.tabContainer}>
      {renderHeader()}
      <PF2MDataTable
        data={anomalyLogs.map(formatAnomalyLogs)}
        columns={columns}
        defaultEditable={false}
        options={{
          maxBodyHeight: 'calc(100vh - 350px)',
          minBodyHeight: 'calc(100vh - 350px)',
        }}
        actions={[
          {
            icon: icons.CheckCircle,
            isFreeAction: true,
            tooltip: translate('common:Commit Changes'),
            onClick: () => {
              dispatch(updateAnomalyLogs(anomalyLogs));
            },
          },
          {
            icon: icons.Check,
            isFreeAction: true,
            tooltip: translate('common:Accept All'),
            onClick: () => {
              dispatch(removeAllAnomalyLogsFromList(anomalyLogs, 15));
            },
          },
          {
            icon: icons.Clear,
            isFreeAction: true,
            tooltip: translate('common:Reject All'),
            onClick: () => {
              dispatch(removeAllAnomalyLogsFromList(anomalyLogs, 16));
            },
          },
        ]}
        components={{
          Row: (props) => {
            // eslint-disable-next-line react/prop-types
            const { data } = props;
            // eslint-disable-next-line react/prop-types
            const { exception_type } = data;
            const rowStyles = { 15: classes.activeRow, 16: classes.inactiveRow };
            return (
              <MTableBodyRow
                {...props}
                className={rowStyles[exception_type]}
              />
            );
          },
        }}
      />
    </div>
  );
};

MaintenanceManagerTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

MaintenanceManagerTab.defaultProps = {};

export default withStyles(styles)(MaintenanceManagerTab);

/* eslint-disable no-bitwise */
/* eslint-disable no-param-reassign */
import { MapControls } from 'three/examples/jsm/controls/OrbitControls';
import Stats from 'three/examples/jsm/libs/stats.module';
import * as THREE from 'three';
import * as helvetiker from '~/assets/helvetiker_regular.typeface.json';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import {
  computeHeight, createLayerLines, createPolygon, getHeight,
} from '../utils';
import { getModel, loadModels } from '../models';

export default class TerrainCanvas {
  #TRUCK_TYPE_ID = 2;

  #EXCAVATOR_TYPE_ID = 1;

  constructor(scale) {
    this.containerDom = null;
    this.heightMap = null;
    this.camera = null;
    this.scale = scale;

    this.loader = new FontLoader();
    this.gltfLoader = new GLTFLoader();

    this.scene = new THREE.Scene();
    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.canvasDom = null;
    this.controls = null;
    this.terrainObject = null;
    this.terrainGeometry = null;
    this.terrainUniforms = null;
    this.defaultModel = null;
    this.boxSize = null;
    this.box = null;

    this.pointObject = null;
    this.onClick = this.onClick.bind(this);

    this.last = performance.now();
    this.pointer = new THREE.Vector2();
    this.pickingTexture = new THREE.WebGLRenderTarget(1, 1);
    this.pixelBuffer = [];

    this.hmscale = 1024;
    this.ppuLatLng = { x: 0, y: 0 };
  }

    setupScene = (containerDom) => {
      this.containerDom = containerDom;
      this.camera = new THREE.PerspectiveCamera(
        60,
        containerDom.clientWidth / containerDom.clientHeight,
        1,
        1000000,
      );
      this.renderer.setSize(this.containerDom.clientWidth, this.containerDom.clientHeight);
      this.camera.position.set(0, 5, 8).setLength(500);
      this.canvasDom = this.renderer.domElement;
      this.containerDom.appendChild(this.canvasDom);

      this.controls = new MapControls(this.camera, this.canvasDom);
      this.controls.maxPolarAngle = 1.4;
      this.controls.maxDistance = 1500;

      const light = new THREE.DirectionalLight(0xffffff, 1.25);
      light.position.set(1, 1, 0);
      this.scene.add(light, new THREE.AmbientLight(0xffffff, 0.25));

      this.scene.fog = new THREE.Fog(0x000000, 1000, 8000);

      const arrow = new THREE.ArrowHelper(
        new THREE.Vector3(-1, 0, 0),
        new THREE.Vector3(0, 5, 0),
        800, 0x00ff00, 10, 10,
      );
      this.scene.add(arrow);
    };

    setupTerrainPlane = (heightmap) => {
      this.heightMap = heightmap;
      const terrainGeometry = computeHeight(this.heightMap, this.scale);
      this.terrainGeometry = terrainGeometry;
      const terrainUniforms = {
        min: { value: new THREE.Vector3() },
        max: { value: new THREE.Vector3() },
        showPositionColors: { value: false },
        lineThickness: { value: 1 },
      };
      const terrainMaterial = new THREE.MeshLambertMaterial({
        color: 0x7D6747,
        wireframe: false,
        side: THREE.DoubleSide,
        onBeforeCompile: (shader) => {
          shader.uniforms.boxMin = terrainUniforms.min;
          shader.uniforms.boxMax = terrainUniforms.max;
          shader.uniforms.lineThickness = terrainUniforms.lineThickness;
          shader.uniforms.showPositionColors = terrainUniforms.showPositionColors;
          shader.vertexShader = `varying vec3 vPos;
                ${shader.vertexShader}
            `.replace('#include <begin_vertex>', `
                #include <begin_vertex>
                vPos = transformed;
              `);
          shader.fragmentShader = `
                uniform vec3 boxMin;
                uniform vec3 boxMax;
                uniform float showPositionColors;
                uniform float lineThickness;
                varying vec3 vPos;
                ${shader.fragmentShader}
            `.replace('#include <dithering_fragment>', `
                vec3 col = vec3(0);
                col = (vPos - boxMin) / (boxMax - boxMin);
                col = clamp(col, 0., 1.);
                if (showPositionColors < 0.375) {
                    float coord = vPos.y / 4.;
                    float grid = abs(fract(coord - 0.1) - 0.1) / fwidth(coord) / lineThickness;
                    float line = min(grid, 1.0);
                    vec3 lineCol = mix(vec3(1, 0, 0), vec3(0, 1, 0), col.y);
                    col = mix(lineCol, gl_FragColor.rgb, line);
                }
                gl_FragColor = vec4( col, opacity);
            `);
        },
      });
      terrainMaterial.defines = { USE_UV: '' };
      terrainMaterial.extensions = { derivatives: true };
      this.terrainObject = new THREE.Mesh(terrainGeometry, terrainMaterial);
      this.terrainObject.layers.enable(1);
      this.scene.add(this.terrainObject);

      const planeGeometry = new THREE.PlaneBufferGeometry(10000, 10000, 1, 1);
      const planeMaterial = new THREE.MeshBasicMaterial({
        color: 0x2b2419,
      });
      const plane = new THREE.Mesh(planeGeometry, planeMaterial);
      plane.position.setY(0.8);
      plane.rotation.x = -Math.PI / 2;
      this.scene.add(plane);

      const box = new THREE.Box3().setFromObject(this.terrainObject);
      const boxSize = new THREE.Vector3();
      box.getSize(boxSize);
      terrainUniforms.min.value.copy(box.min);
      terrainUniforms.max.value.copy(box.max);

      this.boxSize = boxSize;
      this.box = box;
      this.terrainUniforms = terrainUniforms;

      const { bottom, top } = this.defaultModel.boards;
      // Defines the pixel per unit in latitude and longitude and the object margin in the 3d space
      this.ppuLatLng.x = this.hmscale / (top - bottom);
    };

    init = () => {
      const stats = new Stats();
      this.containerDom.appendChild(stats.dom);

      this.renderer.setAnimationLoop(() => {
        this.renderer.render(this.scene, this.camera);
        stats.update();
      });

      this.canvasDom.addEventListener('pointermove', this.onPointerMove);
      this.canvasDom.addEventListener('click', this.onClick);
    }

    setup = (containerDom, heightMap, defaultModel) => {
      this.defaultModel = defaultModel;
      const [bottom, right] = defaultModel.bottom_right_lat_lng;
      const [top, left] = defaultModel.top_left_lat_lng;
      const proportion = (bottom - top) / (right - left);
      this.defaultModel.boards = {
        bottom, left, right, top, proportion,
      };
      this.setupScene(containerDom);
      this.setupTerrainPlane(heightMap);
      this.init();
    };

    setCameraControl = (mode) => {
      const cameraOptions = {
        top: () => {
          this.camera.position.set(0, 10, 0).setLength(500);
          this.controls.target.set(0, 0, 0);
          this.controls.enableRotate = false;
          this.controls.update();
        },
        iso: () => {
          this.controls.target.set(0, 0, 0);
          this.camera.position.set(-10, 10, -10).setLength(500);
          this.controls.update();
          this.controls.enableRotate = true;
        },
      };
      if (cameraOptions[mode]) {
        cameraOptions[mode]();
      }
    }

    latLngtoXY = (lat, lng) => {
      const { bottom: originLat, right: originLng, proportion } = this.defaultModel.boards;
      const x = ((originLat - lat) * this.ppuLatLng.x) + (this.hmscale / 2);
      const y = ((originLng - lng) * this.ppuLatLng.x) + (this.hmscale / proportion / 2);
      return [x, y];
    };

    resetModel = () => {
      this.terrainObject.geometry = this.terrainGeometry;
      this.scene.remove(this.scene.getObjectByName('compare'));
    }

    setLayerVisibility = (layerName, visible) => {
      const layerGroup = this.scene.getObjectByName(layerName);
      if (layerGroup) {
        layerGroup.visible = visible;
      }
    }

    setPolygonVisibility = (polygonName, visible) => {
      const polygonGroup = this.scene.getObjectByName(polygonName);
      if (polygonGroup) {
        polygonGroup.visible = visible;
      }
    }

    setEquipmentVisibility = () => {
    }

    pick = (pointer) => {
      const zoom = ((window.outerWidth - 10) / window.innerWidth);
      this.camera.setViewOffset(
        this.canvasDom.clientWidth,
        this.canvasDom.clientHeight,
        (pointer.x / zoom) * window.devicePixelRatio | 0,
        (pointer.y / zoom) * window.devicePixelRatio | 0,
        1, 1,
      );
      this.renderer.setRenderTarget(this.pickingTexture);
      this.camera.layers.set(1);
      this.terrainUniforms.showPositionColors.value = true;
      this.renderer.render(this.scene, this.camera);
      this.camera.clearViewOffset();
      this.pixelBuffer = new Uint8Array(4);

      this.renderer.readRenderTargetPixels(this.pickingTexture, 0, 0, 1, 1, this.pixelBuffer);

      this.renderer.setRenderTarget(null);
      this.terrainUniforms.showPositionColors.value = false;
      this.camera.layers.set(0);
      const resultPos = new THREE.Vector3();
      resultPos.fromArray(this.pixelBuffer);
      resultPos.divideScalar(255).multiply(this.boxSize).add(this.box.min);
      return resultPos;
    };

    updateEquipmentsPositions = (points) => {
      const eqpGroup = this.scene.getObjectByName('equipments');
      const txtGroup = this.scene.getObjectByName('texts');
      if (eqpGroup) {
        eqpGroup.children.forEach((obj) => {
          const point = points.find(p => p.equip_id === obj.equip_id);
          if (point) {
            const coords = this.latLngtoXY(point.x, point.y);
            const height = getHeight(coords[0], coords[1], this.terrainGeometry);
            obj.position.set(coords[0], height + 2, coords[1]);
          } else {
            // eslint-disable-next-line no-console
            console.error('Point not found for eqpGroup:', obj.equip_id);
          }
        });
      }
      if (txtGroup) {
        txtGroup.children.forEach((obj) => {
          const point = points.find(p => p.equip_id === obj.equip_id);
          if (point) {
            const coords = this.latLngtoXY(point.x, point.y);
            const height = getHeight(coords[0], coords[1], this.terrainGeometry);
            obj.position.set(coords[0] - 6, height + 10, coords[1]);
          } else {
            // eslint-disable-next-line no-console
            console.error('Point not found for txtGroup:', obj.equip_id);
          }
        });
        return true;
      }
      return false;
    }

    createEquipments = (points, setEquipmentStatusModalData) => {
      const font = this.loader.parse(helvetiker.default);
      const eqpGroup = new THREE.Group();
      eqpGroup.name = 'equipments';
      const txtGroup = new THREE.Group();
      txtGroup.name = 'texts';

      loadModels(this.gltfLoader, (models) => {
        points.forEach((point) => {
          const coords = this.latLngtoXY(point.x, point.y);
          const height = getHeight(coords[0], coords[1], this.terrainGeometry);
          const textMesh = new THREE.Mesh(
            new TextGeometry(point.equip_name !== null ? point.equip_name : '', {
              font,
              size: 3,
              bevelEnabled: false,
              height: 0.1,
              curveSegments: 4,
            }),
            new THREE.MeshBasicMaterial({
              color: 0xffffff,
            }),
          );
          textMesh.equip_id = point.equip_id;
          textMesh.position.set(coords[0] - 6, height + 10, coords[1]);

          const currModel = getModel(models, point.equip_type).clone();

          currModel.equip_id = point.equip_id;
          currModel.position.set(coords[0], height + 2, coords[1]);
          const {
            x, y, equip_name: ename, ...cleanPoint
          } = point;
          currModel.userData = {
            lat: x,
            lng: y,
            name: ename,
            ...cleanPoint,
          };

          if ([this.#TRUCK_TYPE_ID, this.#EXCAVATOR_TYPE_ID]
            .includes(currModel.userData.equip_type)) {
            currModel.onClick = () => {
              setEquipmentStatusModalData({
                equip_type: currModel.userData.equip_type,
                equip_id: currModel.userData.equip_id,
              });
            };
          }
          currModel.scale.set(1, 1, 1);

          eqpGroup.visible = true;
          txtGroup.add(textMesh);
          eqpGroup.add(currModel);
        });
        this.scene.add(txtGroup);
        this.scene.add(eqpGroup);
      });
    };

    timeHasPassed = (time) => {
      const now = performance.now();
      const delta = now - this.last;
      if (delta > time) {
        this.last = now;
        return true;
      }
      return false;
    };

    findNearbyObject = (objpos) => {
      const eqpGroup = this.scene.getObjectByName('equipments');
      let newPointObj = null;
      if (eqpGroup) {
        eqpGroup.children.forEach((obj) => {
          const dist = obj.position.distanceTo(objpos);
          if (dist < 500) {
            obj.rotation.y = Math.atan2(objpos.x - obj.position.x, objpos.z - obj.position.z);
            if (dist < 15) {
              newPointObj = obj;
            }
          }
        });
      }
      this.pointObject = newPointObj;
      return newPointObj;
    };

    onClick() {
      if (this.pointObject?.onClick) this.pointObject.onClick(this.canvasDom);
    }

    onPointerMove = (event, callback) => {
      callback = callback || (() => {});
      this.pointer.x = event.clientX - this.canvasDom.getBoundingClientRect().left;
      this.pointer.y = event.clientY - this.canvasDom.getBoundingClientRect().top;
      if (this.timeHasPassed(15)) {
        const pointerPos = this.pick(this.pointer);
        this.findNearbyObject(pointerPos);
        callback(pointerPos);
      }
    }

    setEquipments = (equipments, setEquipmentStatusModalData) => {
      if (this.updateEquipmentsPositions(equipments)) {
        return;
      }
      this.createEquipments(equipments, setEquipmentStatusModalData);
    }

    setLayers = (terrainModelLayers) => {
      terrainModelLayers.forEach((layer) => {
        const grp = createLayerLines(
          layer.default_name,
          terrainModelLayers,
          this.defaultModel.origin_point,
          this.defaultModel.pixels_per_unit,
          this.terrainGeometry,
        );
        this.scene.add(grp);
      });
    }

    setPolygons = (terrainModelPolygons) => {
      Object.keys(terrainModelPolygons).forEach((key) => {
        const polygonGroup = createPolygon(
          terrainModelPolygons[key].points,
          key,
          this.defaultModel.origin_point,
          this.defaultModel.pixels_per_unit,
          this.terrainGeometry,
        );
        this.scene.add(polygonGroup);
      });
    }
}

import React from 'react';

function preCell(Component, {
  bulkEdit, val, options, editing, field, ...rest
}) {
  return p => (
    <Component
      {...p}
      options={options}
      bulkEdit={bulkEdit}
      value={editing ? val(p) : p[field]}
      editing={editing}
      field={field}
      {...rest}
    />
  );
}

export default preCell;

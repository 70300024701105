import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Fab from '@material-ui/core/Fab';
import styles from './styles';

const PF2MButton = ({
  onClick,
  disabled,
  classes,
  children,
  className,
  ...props
}) => (
  <Fab
    variant="extended"
    color="secondary"
    size="medium"
    onClick={onClick}
    disabled={disabled}
    className={classNames(classes.button, className)}
    {...props}
  >
    {children}
  </Fab>
);

PF2MButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  children: PropTypes.any,
  className: PropTypes.string,
};

PF2MButton.defaultProps = {
  onClick: () => {},
  disabled: false,
  children: {},
  className: '',
};

export default withStyles(styles)(PF2MButton);

import React from 'react';

export const filterCodeGroups = (u, ces) => {
  const {
    code_type: codeType,
    equip_type: equipType,
  } = ces;

  return equipType === u.id_equip
    && (codeType === -1 || u.code_type === codeType);
};

export const filterCodes = (c, ces) => {
  const {
    id_code_group: codeGroup,
    equip_type: equipType,
    code_type: codeType,
  } = ces;

  return codeType === c.code_type
    && equipType === c.id_equip
    && (codeGroup === 0 || c.id_group === codeGroup);
};

export const filterSubElements = (c, ces) => {
  const { element_id: element } = ces;
  return element === 0 || c.id_element === element;
};

export const filterOperators = (c, ces) => {
  const { id_operator_group: group } = ces;
  return group === 0 || c.id_group === group;
};

/**
 * Hook, that runs until ref.current be assigned to true in passed function
 * @param {Function(ref, params) -> cleanFunction} func
 */
export function useOnceEffect(func) {
  const ref = React.useRef(false);
  React.useEffect(() => {
    if (!ref.current) {
      ref.current = Boolean(func());
    }
  }, [func]);
}

export function getNameInListById(codeTypeId, codeTypes, field) {
  if (codeTypeId === null) return '';
  return codeTypes.find(r => r[field] === codeTypeId)?.name;
}

export const SET_LINEUP_LIST = 'SET_LINEUP_LIST';
export const SET_SELECTED_LINEUP = 'SET_SELECTED_LINEUP';
export const SET_ELEVATION_GROUPS_LIST = 'SET_ELEVATION_GROUPS_LIST';
export const SET_LINEUP_ELEMENTS_LIST = 'SET_LINEUP_ELEMENTS_LIST';
export const SET_LINEUP_SUBELEMENTS_LIST = 'SET_LINEUP_SUBELEMENTS_LIST';

export const TOGGLE_LINEUP_ELEMENT_ASSOCIATION = 'TOGGLE_LINEUP_ELEMENT_ASSOCIATION';
export const TOGGLE_LINEUP_SUBELEMENT_ASSOCIATION = 'TOGGLE_LINEUP_SUBELEMENT_ASSOCIATION';

export const RESTORE_LINEUPS = 'RESTORE_LINEUPS';
export const RESTORE_LINEUP_ELEMENTS = 'RESTORE_LINEUP_ELEMENTS';
export const RESTORE_LINEUP_SUBELEMENTS = 'RESTORE_LINEUP_SUBELEMENTS';
export const RESTORE_ELEVATION_GROUPS = 'RESTORE_ELEVATION_GROUPS';

export const BULK_SELECT_LINEUP_SUBELEMENTS = 'BULK_SELECT_LINEUP_SUBELEMENTS';
export const BULK_DESELECT_LINEUP_SUBELEMENTS = 'BULK_DESELECT_LINEUP_SUBELEMENTS';

export const SET_LINEUP_VALUES = 'SET_LINEUP_VALUES';
export const ADD_LINEUP = 'ADD_LINEUP';
export const ADD_ELEVATION_GROUP = 'ADD_ELEVATION_GROUP';

export const SET_ELEVATION_ASSOCIATIONS_LIST = 'SET_ELEVATION_ASSOCIATIONS_LIST';
export const RESTORE_ELEVATION_ASSOCIATIONS = 'RESTORE_ELEVATION_ASSOCIATIONS';
export const ADD_ELEVATION_ASSOCIATION = 'ADD_ELEVATION_ASSOCIATION';
export const TOGGLE_ELEVATION_ASSOCIATION = 'TOGGLE_ELEVATION_ASSOCIATION';
export const CREATE_ELEVATION_ASSOCIATION = 'CREATE_ELEVATION_ASSOCIATION';
export const SELECT_ALL_ELEVATIONS = 'SELECT_ALL_ELEVATIONS';
export const DESELECT_ALL_ELEVATIONS = 'DESELECT_ALL_ELEVATIONS';

export const SET_ELEMENT_PRECEDENCE_LIST = 'SET_ELEMENT_PRECEDENCE_LIST';
export const RESTORE_ELEMENT_PRECEDENCES = 'RESTORE_ELEMENT_PRECEDENCES';
export const ADD_ELEMENT_PRECEDENCE = 'ADD_ELEMENT_PRECEDENCE';
export const TOGGLE_ELEMENT_PRECEDENCE = 'TOGGLE_ELEMENT_PRECEDENCE';
export const SELECT_ALL_ELEMENT_PRECEDENCES = 'SELECT_ALL_ELEMENT_PRECEDENCES';
export const DESELECT_ALL_ELEMENT_PRECEDENCES = 'DESELECT_ALL_ELEMENT_PRECEDENCES';

export const SET_SUBELEMENT_PRECEDENCE_LIST = 'SET_SUBELEMENT_PRECEDENCE_LIST';
export const RESTORE_SUBELEMENT_PRECEDENCES = 'RESTORE_SUBELEMENT_PRECEDENCES';
export const ADD_SUBELEMENT_PRECEDENCE = 'ADD_SUBELEMENT_PRECEDENCE';
export const TOGGLE_SUBELEMENT_PRECEDENCE = 'TOGGLE_SUBELEMENT_PRECEDENCE';
export const SELECT_ALL_SUBELEMENT_PRECEDENCES = 'SELECT_ALL_SUBELEMENT_PRECEDENCES';
export const DESELECT_ALL_SUBELEMENT_PRECEDENCES = 'DESELECT_ALL_SUBELEMENT_PRECEDENCES';

export const SET_RESOURCES_LIST = 'SET_RESOURCES_LIST';
export const RESTORE_RESOURCES = 'RESTORE_RESOURCES';
export const SET_RESOURCE_VALUE = 'SET_RESOURCE_VALUE';

export const SET_PRIORITIES_LIST = 'SET_PRIORITIES_LIST';
export const RESTORE_PRORITIES = 'RESTORE_PRORITIES';
export const SET_LINEUP_PRIORITY_VALUE = 'SET_LINEUP_PRIORITY_VALUE';
export const ADD_PRIORITY = 'ADD_PRIORITY';
export const SET_LOAD_ALLOCATIONS_LIST = 'SET_LOAD_ALLOCATIONS_LIST';

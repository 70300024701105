/* eslint-disable camelcase */
import React from 'react';
import {
  withStyles,
  FormControl,
  FormLabel,
  FormGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import classnames from 'classnames';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import { parseFloat as utilsParseFloat } from '~/utils';
import PF2MAddButton from '~/components/PF2MAddButton';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';

function Header({
  classes,
  add,
  equipmentGroups,
  equipmentTypes,
  handlingTypes,
  dmtRanges,
}) {
  const { t: translate } = useTranslation();

  const INITIAL_VALUES = {
    equipmentType: -1,
    equipmentGroup: -1,
    name: '',
    rangeStart: 0,
    rangeEnd: 0,
  };

  const applyDmtRangeRule = async (equipmentType, equipmentGroup, handlingType) => {
    const normalizedHandlingType = handlingType === 0 ? null : handlingType;
    const filteredRanges = dmtRanges.filter(d => d.id_equip_group === equipmentGroup
      && d.id_equipment_type === equipmentType && d.handling_types_id === normalizedHandlingType);
    let rangeStart = 0.00;

    if (filteredRanges && filteredRanges.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      rangeStart = filteredRanges.sort((a, b) => b.range_end - a.range_start)[0].range_end;
      rangeStart = utilsParseFloat(rangeStart) + 0.01;
    }
    return rangeStart;
  };

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      validate={(values) => {
        const errors = {};
        const {
          equipmentType,
          equipmentGroup,
          name,
          rangeStart = 0,
          rangeEnd = 0,
        } = values;

        if (equipmentType < 0) {
          errors.equipmentType = 'Required';
        }

        if (equipmentGroup < 0) {
          errors.equipmentGroup = 'Required';
        }

        if (!name) {
          errors.name = 'Required';
        }
        const start = utilsParseFloat(rangeStart);
        const end = utilsParseFloat(rangeEnd);

        if (end <= 0 || end <= start) {
          errors.rangeEnd = 'Required';
        }

        return errors;
      }}
      onSubmit={async (values, { resetForm }) => {
        const {
          name,
          equipmentType: id_equipment_type,
          equipmentGroup: id_equip_group,
          handlingType: handling_types_id,
          rangeStart,
          rangeEnd,
        } = values;

        const parsedRangeStart = utilsParseFloat(rangeStart);
        const parsedRangeEnd = utilsParseFloat(rangeEnd);

        await add({
          name,
          id_equipment_type,
          id_equip_group,
          handling_types_id,
          range_start: parsedRangeStart,
          range_end: parsedRangeEnd,
        });
        resetForm();
      }}
    >
      {({
        isSubmitting,
        errors,
        isValid,
        values,
      }) => (
        <Form>
          <div style={{
            display: 'flex', alignItems: 'center',
          }}
          >
            <div>
              <FormControl style={{ marginRight: 20 }}>
                <FormLabel className={classes.formLabel}>
                  {`${translate('common:EquipmentType')}:`}
                </FormLabel>
                <FormGroup>
                  <Field
                    name="equipmentType"
                    render={({ field, form }) => (
                      <PF2MSearchSelectOutlined
                        {...field}
                        onChange={(e) => {
                          form.setFieldValue('equipmentType', e.target.value);
                          form.setFieldValue('equipmentGroup', -1);
                          form.setFieldValue('handlingType', 0);
                        }}
                        className={classnames(
                          form.errors[field.name] ? classes.fieldError : classes.field,
                        )}
                      >
                        {[{ value: -1, label: translate('common:Select') }]
                          .concat(...equipmentTypes.map(e => ({ value: e.id, label: e.name })))}
                      </PF2MSearchSelectOutlined>
                    )}
                  />
                </FormGroup>
              </FormControl>
              <FormControl style={{ marginRight: 20 }}>
                <FormLabel className={classes.formLabel}>
                  {`${translate('common:EquipmentGroup')}:`}
                </FormLabel>
                <FormGroup>
                  <Field
                    name="equipmentGroup"
                    render={({ field, form }) => (
                      <PF2MSearchSelectOutlined
                        {...field}
                        className={classnames(
                          form.errors[field.name] ? classes.fieldError : classes.field,
                        )}
                        onChange={async (e) => {
                          form.setFieldValue('equipmentGroup', e.target.value);
                          const range = await applyDmtRangeRule(
                            values.equipmentType,
                            e.target.value,
                            values.handlingType,
                          );
                          form.setFieldValue('rangeStart', range, false);
                        }}
                      >
                        {[{ value: -1, label: translate('common:Select') }]
                          .concat(...equipmentGroups
                            .filter(e => e.id_equipament === values.equipmentType)
                            .map(e => ({ value: e.id, label: e.name })))}
                      </PF2MSearchSelectOutlined>
                    )}
                  />
                </FormGroup>
              </FormControl>
              <FormControl style={{ marginRight: 20 }}>
                <FormLabel className={classes.formLabel}>
                  {`${translate('common:MovementType')}:`}
                </FormLabel>
                <FormGroup>
                  <Field
                    name="handlingType"
                    render={({ field, form }) => (
                      <PF2MSearchSelectOutlined
                        {...field}
                        className={classnames(
                          form.errors[field.name] ? classes.fieldError : classes.field,
                        )}
                        onChange={async (e) => {
                          form.setFieldValue('handlingType', e.target.value);
                          const range = await applyDmtRangeRule(
                            values.equipmentType,
                            values.equipmentGroup,
                            e.target.value,
                          );
                          form.setFieldValue('rangeStart', range, false);
                        }}
                      >
                        {[{ value: 0, label: translate('common:All') }]
                          .concat(...handlingTypes.map(e => ({ value: e.id, label: e.name })))}
                      </PF2MSearchSelectOutlined>
                    )}
                  />
                </FormGroup>
              </FormControl>
            </div>
            <FormControl style={{ flex: 1 }}>
              <FormLabel className={classes.formLabel}>
                {`${translate('common:RangeName')}:`}
              </FormLabel>
              <FormGroup>
                <Field
                  type="text"
                  name="name"
                  className={classnames('form-control', errors.name ? classes.fieldError : classes.field)}
                />
              </FormGroup>
            </FormControl>
          </div>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          >
            <div>
              <FormControl style={{ marginRight: 20, width: 200 }}>
                <FormLabel className={classes.formLabel}>
                  {translate('common:InitialRangeM')}
                </FormLabel>
                <FormGroup>
                  <Field
                    name="rangeStart"
                    render={({ field }) => (
                      <NumberFormat
                        {...field}
                        className={classnames('form-control', errors.rangeStart ? classes.fieldError : classes.field)}
                        disabled
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale
                      />
                    )}
                  />
                </FormGroup>
              </FormControl>
              <FormControl style={{ marginRight: 20, width: 200 }}>
                <FormLabel className={classes.formLabel}>
                  {translate('common:FinalRangeM')}
                </FormLabel>
                <FormGroup>
                  <Field
                    name="rangeEnd"
                    render={({ field }) => (
                      <NumberFormat
                        {...field}
                        className={classnames('form-control', errors.rangeEnd ? classes.fieldError : classes.field)}
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale
                      />
                    )}
                  />
                </FormGroup>
              </FormControl>
            </div>
            <div style={{ marginTop: 15 }}>
              <PF2MAddButton disabled={isSubmitting || !isValid} />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  add: PropTypes.func,
  equipmentTypes: PropTypes.array,
  equipmentGroups: PropTypes.array,
  dmtRanges: PropTypes.array,
  handlingTypes: PropTypes.array,
};

Header.defaultProps = {
  add: () => { },
  equipmentTypes: [],
  equipmentGroups: [],
  dmtRanges: [],
  handlingTypes: [],
};

const mapStateToProps = state => ({
  dmtRanges: state.contracts.dmtRanges,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Header));

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, withStyles } from '@material-ui/core';
import { ModalHeader, withModal } from '~/components/PF2MModal';
import { ModalPropTypes } from '~/components/PF2MModal/withModal';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import { CardBody } from '~/components/Card';
import PF2MButton from '~/components/PF2MButton';
import { getActivityListPictureURL } from '~/store/dailyparts/actions';

const PictureModal = React.forwardRef(({
  closeModal, modalData, uploadImage, classes,
}, _ref) => {
  const { t: translate } = useTranslation();
  const activityUploadLoading = useSelector(st => st.dailyParts.activityUploadLoading);
  const [image, setImage] = useState({
    file: null,
    src: getActivityListPictureURL(modalData.picture_name),
  });

  const onSelectFile = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = e => setImage(old => ({
        ...old,
        file,
        src: e.target.result,
      }));
      reader.readAsDataURL(file);
    }
  };

  return (
    <Card className={classes.modal}>
      <ModalHeader title={translate('common:Upload')} closeModal={closeModal} />

      <CardBody className={classes.modalBody}>
        <div>
          <input
            accept="image/jpeg"
            type="file"
            onChange={onSelectFile}
          />

          <PF2MButton
            disabled={!image.file || activityUploadLoading}
            onClick={() => uploadImage({
              ...modalData,
              picture_name: image.file.name,
              picture: image.file,
            })}
          >
            {translate('common:Upload')}
          </PF2MButton>
        </div>

        <div style={{ margin: 40 }} />

        <div>
          <img
            className={classes.modalImage}
            src={image.src}
            alt="activity"
          />
        </div>
      </CardBody>
    </Card>
  );
});

PictureModal.propTypes = {
  ...ModalPropTypes,
};

export default withModal(withStyles(styles)(PictureModal));

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { connect, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { Badge } from '@material-ui/core';
import styles from './styles';
import PF2MAlertDialog from '../PF2MAlertDialog';
import { syncData } from '~/store/manager/actions';
import { checkModulePermission, handleDynamicClass, handleDynamicIcon } from '~/utils';

const CustomTabs = ({
  classes,
  children,
  syncData: sync,
  hideSyncButton,
  chatIndex,
  readStatus,
  roles,
  ...props
}) => {
  const [confirmSyncData, setConfirmSyncData] = useState(false);
  const { t: translate } = useTranslation();
  const loggedUser = useSelector(state => state.auth.user);

  const handleAlertconfirmation = async () => {
    await sync();
    setConfirmSyncData(false);
  };

  const handleDisabled = (c) => {
    if (!checkModulePermission(roles, 'DYNAMIC') && c.dynamic) {
      return true;
    } return c.disabled || false;
  };

  return (
    <div className={classes.root}>
      <Tabs
        classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
        {...props}
      >
        {children
          .filter(r => !r.allowedUserLevels || r.allowedUserLevels.includes(loggedUser?.level))
          .map((c, i) => (chatIndex !== i
            ? (

              <Tab
                hidden={c.hidden}
                key={c.key}
                disableRipple
                classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                disabled={handleDisabled(c)}
                label={(
                  <Badge
                    className={handleDynamicClass(c, roles, classes)}
                    overlap="rectangular"
                    badgeContent={handleDynamicIcon(c, roles)}
                  >
                    {c.label}
                  </Badge>
                )}
              />
            ) : (
              <Tab
                hidden={c.hidden}
                key={c.key}
                disableRipple
                classes={{
                  root: readStatus ? classes.tabRoot : classes.chatTabWithMessage,
                  selected: classes.tabSelected,
                }}
                label={c.label}
                disabled={c.disabled || false}
              />
            )
          ))}
      </Tabs>
      {!hideSyncButton && (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setConfirmSyncData(true)}
        >
          {translate('common:UpdateApps')}
        </Button>
      )}
      <PF2MAlertDialog
        cancelText={translate('common:No')}
        confirmText={translate('common:Yes')}
        description={translate('validation:WouldYouLikeToUpdateApps')}
        open={confirmSyncData}
        onConfirm={handleAlertconfirmation}
        onClose={() => setConfirmSyncData(false)}
      />
    </div>
  );
};

CustomTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      key: PropTypes.number.isRequired,
      hidden: PropTypes.bool,
    }),
  ).isRequired,
  syncData: PropTypes.func,
  hideSyncButton: PropTypes.bool,
  chatIndex: PropTypes.number,
  readStatus: PropTypes.bool,
  roles: PropTypes.array,
};

CustomTabs.defaultProps = {
  syncData: null,
  hideSyncButton: false,
  chatIndex: null,
  readStatus: true,
  roles: [],
};

const mapDispatchToProps = {
  syncData,
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(CustomTabs));

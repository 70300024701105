import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  withStyles,
  FormControl,
  FormLabel,
  FormGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import styles from '../../styles';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import PF2MSearchButton from '~/components/PF2MSearchButton';
import { getElements } from '~/store/manager/actions';


const SubElementsFilter = ({ classes, setCurrentFilter }) => {
  const elements = useSelector(state => state.manager.elements);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [currentLocalFilter, setCurrentLocalFilter] = useState({ name: '', id_element: 0, active: -1 });
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      dispatch(getElements());
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch]);

  const renderFormControl = () => (
    <Formik
      initialValues={{ name: '', id_element: 0, active: -1 }}
      onSubmit={async (_, { setSubmitting }) => {
        const filter = { active: currentLocalFilter.active };
        if (currentLocalFilter.id_element) filter.id_element = currentLocalFilter.id_element;
        if (currentLocalFilter.name) filter.name = currentLocalFilter.name;
        setCurrentFilter(filter);
        setSubmitting(false);
      }}
    >
      {({
        isSubmitting,
        initialValues,
        errors,
      }) => (
        <Form style={{ display: 'flex', alignItems: 'center' }}>
          <FormControl className={classes.nameField}>
            <FormLabel className={classes.labelTypeSelector}>
              {translate('common:Name')}
            </FormLabel>
            <FormGroup>
              <Field
                type="text"
                name="name"
                value={currentLocalFilter.name || initialValues.name}
                className={classnames('form-control', errors.name ? classes.fieldError : classes.field)}
                onChange={e => setCurrentLocalFilter(
                  {
                    ...currentLocalFilter,
                    name: e.target.value,
                  },
                )}
              />
            </FormGroup>
          </FormControl>
          <FormControl className={classes.typeSelector} style={{ width: 435 }}>
            <FormLabel className={classes.labelTypeSelector}>
              {translate('common:Elements')}
            </FormLabel>
            <FormGroup>
              <PF2MSearchSelectOutlined
                placeholder={null}
                value={currentLocalFilter.id_element}
                onChange={e => setCurrentLocalFilter(
                  {
                    ...currentLocalFilter,
                    id_element: e.target.value,
                  },
                )}
              >
                {elements.map(e => ({
                  value: e.id,
                  label: e.name,
                }))}
              </PF2MSearchSelectOutlined>
            </FormGroup>
          </FormControl>
          <FormControl className={classes.typeSelector} style={{ width: 435 }}>
            <FormLabel className={classes.labelTypeSelector}>
              {translate('common:Active')}
            </FormLabel>
            <FormGroup>
              <PF2MSearchSelectOutlined
                placeholder={null}
                value={currentLocalFilter.active}
                clearedValue={-1}
                onChange={e => setCurrentLocalFilter(
                  {
                    ...currentLocalFilter,
                    active: e.target.value,
                  },
                )}
              >
                {[
                  { value: -1, label: translate('common:All') },
                  { value: 0, label: translate('common:Inactive') },
                  { value: 1, label: translate('common:Active') }]}
              </PF2MSearchSelectOutlined>
            </FormGroup>
          </FormControl>
          <div style={{ marginTop: 25 }}>
            <PF2MSearchButton type="submit" disabled={isSubmitting} />
          </div>
        </Form>
      )}
    </Formik>
  );

  return (
    <div className={classes.tabContainer}>
      {renderFormControl()}
    </div>
  );
};

SubElementsFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  setCurrentFilter: PropTypes.func,
};
SubElementsFilter.defaultProps = {
  setCurrentFilter: null,
};

export default withStyles(styles)(SubElementsFilter);

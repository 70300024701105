import React, { useState, useEffect } from 'react';
import {
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import Footer from '~/pages/Manager/Footer';
import PF2MTable from '~/components/PF2MTable';
import {
  getUnitOperationGroups,
  getUnitOperations,
  setUnitOperationValue,
  updateUnitOperations,
  resetUnitOperations,
  getInputUnits,
} from '~/store/underground/actions';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';

const UnitOperationTab = ({ classes }) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const unitOperations = useSelector(state => state.underground.unitOperations);
  const inputUnits = useSelector(state => state.underground.inputUnits);
  // eslint-disable-next-line max-len
  const isUnitOperationsDirty = useSelector(state => state.underground.isUnitOperationsDirty);
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      dispatch(getUnitOperationGroups());
      dispatch(getUnitOperations());
      dispatch(getInputUnits());
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dispatch, dataLoaded]);

  const isValidName = (operationGroup, name) => {
    const existingItem = unitOperations
      .some(p => p.id_unitary_operation_group === operationGroup
        && p.name === name);
    return !existingItem;
  };

  const isValidOrder = (operationGroup, order) => {
    const existingItem = unitOperations
      .some(c => c.id_unitary_operation_group === operationGroup
        && c.order === order);
    return !existingItem;
  };

  const columns = [
    {
      field: 'unitary_operation_group_name', title: translate('common:UnitOperationGroup'),
    },
    {
      field: 'name',
      title: translate('common:Name'),
      width: '100%',
      // editable: true,
      // updateOnChange: true,
    },
    {
      field: 'order',
      title: translate('common:Order'),
      editable: true,
      updateOnChange: true,
    },
    {
      field: 'input',
      title: translate('common:Input'),
      editable: true,
      updateOnChange: true,
    },
    {
      field: 'underground_input_unit_id',
      title: translate('common:Unit'),
      selectItems: inputUnits,
      editable: true,
      width: 200,
    },
    {
      field: 'active',
      title: 'STATUS',
      editable: true,
    },
  ];

  return (
    <div className={classes.tabContainer}>
      <PF2MTable
        data={unitOperations
          .sort((a, b) => a.order - b.order)
          .sort((a, b) => a.id_unitary_operation_group - b.id_unitary_operation_group)}
        columns={columns}
        updateItem={(row, field, value) => {
          let existing = false;

          if (field === 'order') {
            existing = !isValidOrder(row.id_unitary_operation_group, Number(value));
            setErrorMessage(translate('validation:SameOrderInSameGroupNotAllowed'));
          }

          if (field === 'name') {
            existing = !isValidName(row.id_unitary_operation_group, value);
            setErrorMessage(translate('validation:SameNameInSameGroupNotAllowed'));
          }

          if (existing) {
            setIsAlertOpen(true);
          } else {
            dispatch(setUnitOperationValue(row, field, value));
          }
        }}
      />
      <Footer
        isDirty={isUnitOperationsDirty}
        discard={() => dispatch(resetUnitOperations())}
        sendData={() => dispatch(updateUnitOperations(unitOperations))}
      />
      <PF2MAlertDialog
        hasCancel={false}
        confirmText={translate('common:Understood')}
        description={errorMessage}
        open={isAlertOpen}
        onConfirm={() => setIsAlertOpen(false)}
      />
    </div>
  );
};

UnitOperationTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

UnitOperationTab.defaultProps = {};

export default withStyles(styles)(UnitOperationTab);

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import TerrainTab from './TerrainTab';
import PF2MTabs from '~/components/PF2MTabs';
import TerrainModelTab from './TerrainModelTab';
import TerrainModelUploadTab from './TerrainModelUploadTab';
import { getTabletConfiguration } from '~/store/manager/actions';

const Terrain = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const dispatch = useDispatch();
  const tabs = [

    { key: 0, label: 'Models' },
    { key: 1, label: 'Upload' },
    { key: 2, label: 'Terrain' },
  ];

  const handleChange = (event, value) => setSelectedTab(value);
  useEffect(() => {
    dispatch(getTabletConfiguration());
  }, [dispatch]);

  return (
    <>
      <PF2MTabs
        value={selectedTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        hideSyncButton
      >
        {tabs}
      </PF2MTabs>
      {selectedTab === 0 ? (<TerrainModelTab />) : null}
      {selectedTab === 1 ? (<TerrainModelUploadTab />) : null}
      {selectedTab === 2 ? (<TerrainTab setSelectedTab={setSelectedTab} />) : null}
    </>
  );
};

export default withStyles(styles)(Terrain);

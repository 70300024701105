import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import DmtRanges from './DMTRanges';
import MaterialCost from './MaterialCost';
import WorkHourValues from './WorkHourValues';
import PF2MTabs from '~/components/PF2MTabs';
import Main from '../Main';
import { ZendeskAPI } from 'react-zendesk';

const Contracts = ({ classes }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { t: translate } = useTranslation();
  const tabs = [
    { key: 0, label: translate('common:DMTRangesRegister') },
    { key: 1, label: translate('common:RangeCostRegister') },
    { key: 2, label: translate('common:ManageMoneyPerWorkedHour') },
  ];

  const handleChange = (event, value) => {
    setSelectedTab(value);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      ZendeskAPI('webWidget', 'hide');
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Main>
        <div className={classes.container}>
          <PF2MTabs
            value={selectedTab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
          >
            {tabs}
          </PF2MTabs>
          {selectedTab === 0 ? (<DmtRanges />) : null}
          {selectedTab === 1 ? (<MaterialCost />) : null}
          {selectedTab === 2 ? (<WorkHourValues />) : null}
        </div>
      </Main>
    </>
  );
};

Contracts.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Contracts);

// eslint-disable-next-line import/prefer-default-export

export const getTenant = () => (
  (
    (process.env.REACT_APP_MULTITENANT_ENABLED === 'true')
    && window.location.host.split('.')[1]
  )
    ? window.location.host.split('.')[0]
    : 'main'
);

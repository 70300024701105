import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';

export { default as PF2MMenuItem } from './MenuItem';

export default function ContextMenu({
  open, close, children, pos, item = {}, container = null,
}) {
  return (
    <Menu
      onContextMenu={e => e.preventDefault()}
      open={open}
      onClose={close}
      anchorReference="anchorPosition"
      anchorPosition={{ top: pos.y, left: pos.x }}
      container={container || document.body}
    >
      {React.Children.map(children, child => React.cloneElement(child, {
        onClick: (e) => {
          if (child.props.onClick) {
            child.props.onClick(e, item);
          }
          if (child.props.autoClose || child.props.autoClose === undefined) {
            close();
          }
        },
      }))}
    </Menu>
  );
}

export function useContextMenu() {
  const [data, setData] = useState({ open: false, item: null, pos: { x: 0, y: 0 } });
  const openContextMenu = (event, item) => {
    event.preventDefault();
    setData({ open: true, item, pos: { x: event.clientX, y: event.clientY } });
  };
  const closeContextMenu = () => setData({
    open: false, item: null, pos: { x: 0, y: 0 },
  });
  return [data, openContextMenu, closeContextMenu];
}

ContextMenu.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  item: PropTypes.any,
  pos: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }).isRequired,
  container: PropTypes.element,
};

ContextMenu.defaultProps = {
  item: {},
  container: null,
};

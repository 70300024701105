import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import CodeTypesTab from './CodeTypesTab';
import CodeGroupsTab from './CodeGroupsTab';
import CodesTab from './CodesTab';
import PF2MTabs from '~/components/PF2MTabs';
import { setAlertDialog } from '~/store/manager/actions';

const Projects = () => {
  const isCodeTypesDirty = useSelector(state => state.underground.isCodeTypesDirty);
  const isCodeGroupsDirty = useSelector(state => state.underground.isCodeGroupsDirty);
  const isCodesDirty = useSelector(state => state.underground.isCodesDirty);

  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(0);
  const { t: translate } = useTranslation();

  const tabs = [
    { key: 0, label: translate('common:CodeTypes') },
    { key: 1, label: translate('common:CodeGroups') },
    { key: 2, label: translate('common:Codes') },
  ];

  const handleChange = (event, value) => {
    let blockChange = false;

    switch (value) {
      case 1:
        blockChange = isCodeTypesDirty || isCodesDirty;
        break;
      case 2:
        blockChange = isCodeTypesDirty || isCodeGroupsDirty;
        break;
      default:
        blockChange = isCodeGroupsDirty || isCodesDirty;
        break;
    }

    if (blockChange) {
      event.preventDefault();
      dispatch(setAlertDialog());
    } else {
      setSelectedTab(value);
    }
  };

  return (
    <>
      <PF2MTabs
        value={selectedTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        {tabs}
      </PF2MTabs>
      {selectedTab === 0 ? (<CodeTypesTab />) : null}
      {selectedTab === 1 ? (<CodeGroupsTab />) : null}
      {selectedTab === 2 ? (<CodesTab />) : null}
    </>
  );
};

export default withStyles(styles)(Projects);

import { managerGridHeight } from '~/utils';

const styles = {
  container: {
    display: 'flex',
    backgroundColor: '#F9F9F9',
    height: managerGridHeight,
    padding: 10,
  },
  icon: {
    marginRight: 10,
  },
  button: {
    color: '#FFF',
    margin: 5,
  },
  tabContainer: {
    borderRadius: 5,
    borderTopLeftRadius: 0,
    backgroundColor: 'white',
    padding: 10,
  },
  field: {
    border: '1px solid #CDCDCD',
    borderRadius: 5,
    maxHeight: 200,
    height: 40,
  },
  fieldSelect: {
    borderRadius: 5,
    height: 40,
    color: '#647886',
    width: '100%',
    borderWidth: 0,
  },
  fieldError: {
    border: '1px solid red !important',
  },
  classObservation: {
    flex: 1,
    minWidth: 290,
    marginRight: 10,
  },
  formLabel: {
    color: '#647886',
    fontSize: 12,
    fontFamily: 'Roboto',
    marginBottom: 5,
  },
  errorMessage: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 12,
    textAlign: 'center',
    color: '#EC3337',
    marginLeft: 10,
  },
  table: {
    height: 'calc(100vh - 52px - 52px - 360px)',
    width: 'calc(100vw - 200px)',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export default styles;

import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import cardBodyStyle from './CardBodyStyle';

function CardBody({ ...props }) {
  const {
    classes, className, children, plain, profile, ...rest
  } = props;
  const cardBodyClasses = classNames({
    [classes.cardBody]: true,
    [classes.cardBodyPlain]: plain,
    [classes.cardBodyProfile]: profile,
    [className]: className !== undefined,
  });
  return (
    <div className={cardBodyClasses} {...rest}>
      {children}
    </div>
  );
}

CardBody.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  children: PropTypes.array.isRequired,
  plain: PropTypes.bool,
  profile: PropTypes.bool,
};

CardBody.defaultProps = {
  className: '',
  plain: false,
  profile: false,
};

export default withStyles(cardBodyStyle)(CardBody);

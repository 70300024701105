import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import Footer from '~/pages/Manager/Footer';
import PF2MTable from '~/components/PF2MTable';
import { getElements, getSubElements } from '~/store/manager/actions';
import {
  getProjectGroups,
  getMonthlyAdvances,
  setMonthlyAdvanceValues,
  updateMonthlyAdvances,
  addMonthlyAdvance,
  resetMonthlyAdvances,
} from '~/store/underground/actions';
import Header from './Header';

const MonthlyAdvanceTab = ({ classes }) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [filters, setFilters] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    projectGroup: 0,
    element: 0,
    subElement: 0,
  });
  const dispatch = useDispatch();
  const elements = useSelector(state => state.manager.elements);
  const subElements = useSelector(state => state.manager.subElements);
  const projectGroups = useSelector(state => state.underground.projectGroups);
  const monthlyAdvances = useSelector(state => state.underground.monthlyAdvances);
  const isMonthlyAdvancesDirty = useSelector(state => state.underground.isMonthlyAdvancesDirty);
  const { t: translate } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      dispatch(getMonthlyAdvances());
      dispatch(getProjectGroups());
      dispatch(getElements());
      dispatch(getSubElements());
    }

    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch]);

  const columns = [
    { field: 'year', title: translate('common:Year') },
    { field: 'month', title: translate('common:Month') },
    { field: 'project_group_name', title: translate('common:ProjectGroup') },
    { field: 'element_name', title: translate('common:Element') },
    { field: 'element_point_name', title: translate('common:SubElement') },
    {
      field: 'advance',
      title: translate('common:MonthlyAdvanceMeters'),
      editable: true,
      type: 'decimal',
    },
    {
      field: 'active',
      title: 'STATUS',
      editable: true,
      align: 'left',
    },
  ];

  const applyFilters = r => (
    (r.year === filters.year)
    && (r.month === filters.month || filters.month === 0)
    && (r.id_project_group === filters.projectGroup || filters.projectGroup === 0)
    && (r.id_element === filters.element || filters.element === 0)
    && (r.id_element_point === filters.subElement || filters.subElement === 0)
  );

  return (
    <div className={classes.tabContainer}>
      <Header
        add={values => dispatch(addMonthlyAdvance(values))}
        projectGroups={projectGroups}
        elements={elements}
        subElements={subElements}
        monthlyAdvances={monthlyAdvances}
        setFilters={setFilters}
      />
      <PF2MTable
        data={monthlyAdvances.filter(applyFilters)}
        columns={columns}
        updateItem={(row, field, value) => dispatch(setMonthlyAdvanceValues(row, field, value))}
        className={classes.advanceTable}
      />
      <Footer
        isDirty={isMonthlyAdvancesDirty}
        discard={() => dispatch(resetMonthlyAdvances())}
        sendData={() => dispatch(updateMonthlyAdvances(monthlyAdvances))}
      />
    </div>
  );
};

MonthlyAdvanceTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

MonthlyAdvanceTab.defaultProps = {};

export default withStyles(styles)(MonthlyAdvanceTab);

/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import {
  withStyles, FormControl, FormLabel, FormGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Footer from '~/pages/Manager/Footer';
import { useTranslation } from 'react-i18next';
import {
  getResources,
  importResources,
  updateResources,
  restoreResources,
  setResourceValue,
  getElevationGroups,
  getElevationAssociations,
  getResourcesTemplate,
} from '~/store/lineup/actions';
import styles from '../styles';
import { getElements, getMaterials, getSubElements } from '~/store/manager/actions';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import Header from '~/pages/Manager/Header';
import PF2MTable from '~/components/PF2MTable';
import { getContents } from '~/store/quality/actions';

const ResourcesTab = ({
  classes,
}) => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [selectedElement, setSelectedElement] = useState(0);
  const [columns, setColumns] = useState([]);
  const selectedLineup = useSelector(state => state.lineup.selectedLineup);
  const elements = useSelector(state => state.manager.elements);
  const subElements = useSelector(state => state.manager.subElements);
  const materials = useSelector(state => state.manager.materials);
  const contents = useSelector(state => state.quality.contents);
  const resourcesFlat = useSelector(state => state.lineup.resourcesFlat);
  const isResourcesDirty = useSelector(state => state.lineup.isResourcesDirty);
  const currentElevationAssociation = useSelector(
    state => state.lineup.elevationAssociations.find(r => r.element_id === selectedElement),
  );

  useEffect(() => {
    async function fetchData() {
      dispatch(getElements());
      dispatch(getContents());
      dispatch(getElements());
      dispatch(getSubElements());
      dispatch(getMaterials());
      dispatch(getElevationGroups());
      dispatch(getElevationAssociations());
    }

    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch]);

  useEffect(() => {
    if (selectedLineup) {
      dispatch(getResources(selectedLineup.id, selectedElement));
    }
  }, [selectedLineup, selectedElement, dispatch]);

  useEffect(() => {
    const newColumns = contents
      .filter(c => c.active)
      .map(c => ({
        field: `content_${c.id}`,
        title: `${c.name}`,
        editable: true,
        type: 'decimal',
        decimalPrecision: 5,
        width: '100px',
      }));
    const initialColumns = [
      {
        field: 'element_id',
        title: translate('common:Element'),
        selectItems: elements,
        editFunc: () => false,
      },
      {
        field: 'element_point_id',
        title: translate('common:SubElement'),
        selectItems: subElements,
        editFunc: () => false,
      },
      {
        field: 'elevation',
        title: translate('common:Elevation'),
        selectItems: currentElevationAssociation?.spread_elevations
          .map(r => ({ id: r, name: r })) || [],
      },
      {
        field: 'material_id',
        title: translate('common:Material'),
        selectItems: materials,
      },
      {
        field: 'date',
        title: translate('common:MineAvailability'),
        datePicker: true,
        disableFuture: false,
        editable: true,
      },
      {
        field: 'mass',
        title: translate('common:AvailableMass'),
        type: 'decimal',
      },
    ];
    setColumns([...initialColumns, ...newColumns]);
  }, [currentElevationAssociation, elements, subElements, materials, contents, translate]);

  const renderFilters = () => (
    <div style={{
      display: 'grid',
      gridTemplateColumns: 'repeat(5, 200px)',
      gridColumnGap: 10,
      gridRowGap: 10,
      margin: '10px 0',
    }}
    >
      <FormControl>
        <FormLabel className={classes.formLabel}>
          {translate('common:Element')}
        </FormLabel>
        <FormGroup>
          <PF2MSearchSelectOutlined
            value={selectedElement}
            onChange={(e) => {
              setSelectedElement(e.target.value);
            }}
            className={classes.fieldSelect}
          >
            {[{ value: 0, label: translate('common:Select') }]
              .concat(...elements
                .map(e => ({ value: e.id, label: e.name })))}
          </PF2MSearchSelectOutlined>
        </FormGroup>
      </FormControl>
    </div>
  );

  return (
    <div className={classes.tabContainer}>
      <Header
        importAction={file => dispatch(importResources(file))}
        getTemplateAction={() => dispatch(getResourcesTemplate())}
      />
      {renderFilters()}
      <PF2MTable
        data={resourcesFlat}
        columns={columns}
        updateItem={(row, field, value) => {
          dispatch(setResourceValue(row, field, value));
        }}
      />
      <Footer
        isDirty={isResourcesDirty}
        sendData={() => dispatch(updateResources(
          resourcesFlat,
          { lineup_id: selectedLineup.id, element_id: selectedElement },
        ))}
        discard={() => dispatch(restoreResources())}
      />
    </div>
  );
};

ResourcesTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

ResourcesTab.defaultProps = {
};

export default withStyles(styles)(ResourcesTab);

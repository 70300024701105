import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import PF2MEquipIcon from '~/components/PF2MEquipIcon';
import { DispatchDragNDropTypes } from '~/utils';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import { FormControl, FormLabel, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import CenterSpan from './CenterSpan';
import ProcessedTime from './ProcessedTime';

const indicators = {
  stop_time: { order: 'desc' },
  load_queue_time: { order: 'desc' },
  load_manager: { order: 'desc' },
};

const EQUIPLIST_BLANK_FILTERS = {
  indicator: 0,
  frequency: 0,
};

const LoaderIndicatorIcon = withStyles(styles)(({
  equipment, classes, filters, equipmentsInfo,
}) => (
  <div key={equipment.loader_id} className={classes.equipmentItem}>
    <PF2MEquipIcon
      header={<CenterSpan>{(equipment[filters.indicator] ?? 0).toFixed(2)}</CenterSpan>}
      item={equipment}
      itemType={DispatchDragNDropTypes.ORIGIN_TRUCK}
      height="100%"
      footer={(
        <CenterSpan>
          {equipmentsInfo[equipment.loader_id]?.name || null}
        </CenterSpan>
      )}
    />
  </div>
));

function LoadersEquipmentList({ equipmentFilter, classes, equipmentsInfo }) {
  const { t: translate } = useTranslation();
  const loadersIndicators = useSelector(state => state.dispatch.loaderIndicators);
  const [filters, setFilters] = useState(EQUIPLIST_BLANK_FILTERS);
  const setIndicatorFilter = value => setFilters(f => ({ ...f, indicator: value }));
  const setFrequencyFilter = value => setFilters(f => ({ ...f, frequency: value }));

  if (loadersIndicators.list.length === 0) return <h2>No equipment info</h2>;
  const isFiltered = Boolean(filters.frequency && filters.indicator);
  const getEquipInfo = ({ loader_id }) => equipmentsInfo[loader_id];

  function orderEquipments(equipA, equipB) {
    const diff = equipA[filters.indicator] - equipB[filters.indicator];
    const indicator = indicators[filters.indicator];
    if (diff === 0) {
      const eA = getEquipInfo(equipA);
      const eB = getEquipInfo(equipB);
      return eA.name.localeCompare(eB.name);
    }
    return indicator.order === 'desc' ? -diff : diff;
  }
  function filterEquipment(equipIndicator) {
    const equipInfo = getEquipInfo(equipIndicator);
    if (!equipInfo) return false;
    if (equipInfo.id_equip !== equipmentFilter.equipmentTypeId) {
      return false;
    }
    if (
      equipmentFilter.equipmentGroupId
      && equipInfo.id_group !== equipmentFilter.equipmentGroupId
    ) {
      return false;
    }
    if (equipIndicator.frequency_type !== filters.frequency) {
      return false;
    }
    return true;
  }


  return (
    <>
      <FormControl>
        <FormLabel className={classes.formLabel}>
          {translate('common:Indicators')}
        </FormLabel>
        <PF2MSearchSelectOutlined
          type="text"
          onChange={event => setIndicatorFilter(event.target.value)}
          value={filters.indicator}
          name="indicator"
          clearedValue="0"
        >
          {[{ value: 0, label: translate('common:Select') }].concat(
            (loadersIndicators.indicators || [])
              .map(indicator => ({ label: translate(`indicators:${indicator}`), value: indicator })),
          )}
        </PF2MSearchSelectOutlined>
      </FormControl>
      <FormControl>
        <FormLabel className={classes.formLabel}>
          {translate('common:Frequency')}
        </FormLabel>
        <PF2MSearchSelectOutlined
          type="text"
          onChange={event => setFrequencyFilter(event.target.value)}
          value={filters.frequency}
          name="frequency"
          clearedValue="0"
        >
          {[{ value: 0, label: translate('common:Select') }].concat(
            loadersIndicators.frequencyTypes
              .map(fType => ({ label: translate(`indicators:${fType}`), value: fType })),
          )}
        </PF2MSearchSelectOutlined>
      </FormControl>
      <ProcessedTime timestamp={loadersIndicators.parseTimestamp} />
      <div className={classes.equipmentList}>
        {isFiltered && loadersIndicators.list
          .filter(filterEquipment)
          .sort(orderEquipments)
          .map(equipment => (
            <LoaderIndicatorIcon
              key={equipment.loader_id}
              equipment={equipment}
              filters={filters}
              equipmentsInfo={equipmentsInfo}
            />
          ))
        }
      </div>
    </>
  );
}

LoadersEquipmentList.propTypes = {
  equipmentFilter: PropTypes.shape({
    equipmentTypeId: PropTypes.number.isRequired,
    equipmentGroupId: PropTypes.number.isRequired,
  }).isRequired,
  equipmentsInfo: PropTypes.object.isRequired, // mapper id -> equipment info
  classes: PropTypes.shape({
    formLabel: PropTypes.string.isRequired,
    equipmentList: PropTypes.string.isRequired,
    equipmentItem: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles)(LoadersEquipmentList);

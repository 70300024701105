import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import Footer from '~/pages/Manager/Footer';
import PF2MTable from '~/components/PF2MTable';
import {
  getShiftScales,
  setShiftScaleValues,
  updateShiftScales,
  addShiftScale,
  resetShiftScales,
  getTurnsGroups,
  getTurns,
} from '~/store/manager/actions';
import Header from './Header';

const ShiftScaleTab = ({
  classes,
  shiftScales,
  turnsGroups,
  turns,
  getTurnsGroups: loadTurnsGroups,
  getTurns: loadTurns,
  getShiftScales: getAll,
  setShiftScaleValues: updateItem,
  updateShiftScales: update,
  addShiftScale: add,
  resetShiftScales: reset,
  isShiftScalesDirty,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [turnGroup, setTurnGroup] = useState(0);
  const { t: translate } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      getAll();
      loadTurns();
      await loadTurnsGroups();
    }

    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, getAll, loadTurnsGroups, loadTurns]);

  const columns = [
    { field: 'name', title: translate('common:Interval') },
    {
      field: 'mon',
      title: translate('date:Mon').toUpperCase(),
      width: '100px',
      selectItems: turns,
      comboDefaultLabel: translate('common:Inactive'),
      filterKey: 'id_turn_group',
      filterValue: 'turn_group',
    },
    {
      field: 'tue',
      title: translate('date:Tue').toUpperCase(),
      width: '100px',
      selectItems: turns,
      comboDefaultLabel: translate('common:Inactive'),
      filterKey: 'id_turn_group',
      filterValue: 'turn_group',
    },
    {
      field: 'wed',
      title: translate('date:Wed').toUpperCase(),
      width: '100px',
      selectItems: turns,
      comboDefaultLabel: translate('common:Inactive'),
      filterKey: 'id_turn_group',
      filterValue: 'turn_group',
    },
    {
      field: 'thu',
      title: translate('date:Thu').toUpperCase(),
      width: '100px',
      selectItems: turns,
      comboDefaultLabel: translate('common:Inactive'),
      filterKey: 'id_turn_group',
      filterValue: 'turn_group',
    },
    {
      field: 'fri',
      title: translate('date:Fri').toUpperCase(),
      width: '100px',
      selectItems: turns,
      comboDefaultLabel: translate('common:Inactive'),
      filterKey: 'id_turn_group',
      filterValue: 'turn_group',
    },
    {
      field: 'sat',
      title: translate('date:Sat').toUpperCase(),
      width: '100px',
      selectItems: turns,
      comboDefaultLabel: translate('common:Inactive'),
      filterKey: 'id_turn_group',
      filterValue: 'turn_group',
    },
    {
      field: 'sun',
      title: translate('date:Sun').toUpperCase(),
      width: '100px',
      selectItems: turns,
      comboDefaultLabel: translate('common:Inactive'),
      filterKey: 'id_turn_group',
      filterValue: 'turn_group',
    },
    {
      field: 'start_turn',
      title: translate('common:Start'),
      width: '100px',
      timePicker: true,
      align: 'center',
    },
    {
      field: 'end_turn',
      title: translate('common:Finish'),
      width: '100px',
      editable: true,
      timePicker: true,
    },
  ];

  return (
    <div className={classes.tabContainer}>
      <Header add={add} turnsGroups={turnsGroups} handleTurnGroup={setTurnGroup} />
      <PF2MTable
        data={shiftScales.filter(s => s.turn_group === turnGroup)}
        columns={columns}
        updateItem={updateItem}
      />
      <Footer
        isDirty={isShiftScalesDirty}
        discard={() => reset()}
        sendData={() => update(shiftScales)}
      />
    </div>
  );
};

ShiftScaleTab.propTypes = {
  classes: PropTypes.object.isRequired,
  shiftScales: PropTypes.array,
  turnsGroups: PropTypes.array,
  turns: PropTypes.array,
  getShiftScales: PropTypes.func,
  setShiftScaleValues: PropTypes.func,
  updateShiftScales: PropTypes.func,
  addShiftScale: PropTypes.func,
  resetShiftScales: PropTypes.func,
  isShiftScalesDirty: PropTypes.bool,
  getTurnsGroups: PropTypes.func,
  getTurns: PropTypes.func,
};

ShiftScaleTab.defaultProps = {
  shiftScales: [],
  turnsGroups: [],
  turns: [],
  getShiftScales: null,
  setShiftScaleValues: null,
  updateShiftScales: null,
  addShiftScale: null,
  resetShiftScales: null,
  isShiftScalesDirty: false,
  getTurnsGroups: null,
  getTurns: null,
};

const mapStateToProps = state => ({
  shiftScales: state.manager.shiftScales,
  turnsGroups: state.manager.turnsGroups,
  turns: state.manager.turns,
  isShiftScalesDirty: state.manager.isShiftScalesDirty,
});

const mapDispatchToProps = {
  getShiftScales,
  setShiftScaleValues,
  updateShiftScales,
  addShiftScale,
  resetShiftScales,
  getTurnsGroups,
  getTurns,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ShiftScaleTab));


const styles = {
  perfectScrollbar: {
    '& div.ps__thumb-x': {
      height: 10,
    },
    '& div.ps__thumb-y': {
      width: 10,
    },
    '& div.ps__rail-y': {
      zIndex: 2,
    },
  },
};

export default styles;

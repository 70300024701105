const styles = {
    icon: {
      marginRight: 10,
    },
    button: {
      color: '#FFF',
      margin: 5,
    },
  };
  
  export default styles;
  
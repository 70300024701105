import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import TeamGroupTab from './TeamGroupTab';
import TeamTab from './TeamTab';
import TeamListingTab from './TeamListingTab';
import TeamAssociationsTab from './TeamAssociationsTab';
import PF2MTabs from '~/components/PF2MTabs';
import { setAlertDialog } from '~/store/manager/actions';

const ClassGroup = () => {
  const isTeamGroupsDirty = useSelector(state => state.manager.isTeamGroupsDirty);
  const isTeamsDirty = useSelector(state => state.manager.isTeamsDirty);
  const isTeamsListingDirty = useSelector(state => state.manager.isTeamsListingDirty);
  const isCalendarTurnsTeamsDirty = useSelector(state => state.manager.isCalendarTurnsTeamsDirty);
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(0);
  const { t: translate } = useTranslation();

  const tabs = [
    { key: 0, label: translate('common:TeamGroups') },
    { key: 1, label: translate('common:Teams') },
    { key: 2, label: translate('common:TeamsListing') },
    { key: 3, label: translate('common:Associations') },
  ];

  const handleChange = (event, value) => {
    let blockChange = false;

    switch (value) {
      case 1:
        blockChange = (isTeamGroupsDirty || isTeamsListingDirty || isCalendarTurnsTeamsDirty);
        break;
      case 2:
        blockChange = (isTeamGroupsDirty || isTeamsDirty || isCalendarTurnsTeamsDirty);
        break;
      case 3:
        blockChange = (isTeamsDirty || isTeamsListingDirty || isTeamGroupsDirty);
        break;
      default:
        blockChange = (isTeamsDirty || isTeamsListingDirty || isCalendarTurnsTeamsDirty);
        break;
    }

    if (blockChange) {
      event.preventDefault();
      dispatch(setAlertDialog());
    } else {
      setSelectedTab(value);
    }
  };

  return (
    <>
      <PF2MTabs
        value={selectedTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        {tabs}
      </PF2MTabs>
      {selectedTab === 0 ? (<TeamGroupTab />) : null}
      {selectedTab === 1 ? (<TeamTab />) : null}
      {selectedTab === 2 ? (<TeamListingTab />) : null}
      {selectedTab === 3 ? (<TeamAssociationsTab />) : null}

    </>
  );
};

export default withStyles(styles)(ClassGroup);

import React, { useEffect, useCallback } from 'react';
import { withStyles } from '@material-ui/core';
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import PF2MMenu from '~/components/PF2MMenu';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import PF2MScrollbar from '~/components/PF2MScrollbar';
import Main from '../Main';
import { setAlertDialog } from '~/store/manager/actions';
import Content from './Content';
import { ZendeskAPI } from 'react-zendesk';


const Quality = ({ classes, match, history }) => {
  const dispatch = useDispatch();
  const alertDialog = useSelector(state => state.manager.alertDialog);
  const isContentsDirty = useSelector(state => state.dispatch.isContentsDirty);
  const { t: translate } = useTranslation();

  const validateNavigation = useCallback(({ pathname = '' }) => {
    if ((isContentsDirty) && !pathname.includes('content')) {
      dispatch(setAlertDialog());
      return false;
    }
    return true;
  }, [isContentsDirty, dispatch]);

  const handleNavigation = (e, pathname = '') => {
    if (!validateNavigation(pathname)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (history.location && history.location.length > 0) {
      validateNavigation(history.location);
    }
  }, [history.location, validateNavigation]);

  useEffect(() => {
    const timer = setTimeout(() => {
      ZendeskAPI('webWidget', 'hide');
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Main>
      <div className={classes.container}>
        <PF2MScrollbar style={{ width: 150, padding: 10 }}>
          <PF2MMenu
            handleNavigation={handleNavigation}
            moduleName="quality"
          />
        </PF2MScrollbar>
        <div style={{ flex: 1 }}>
          <Switch>
            <Route exact path={`${match.path}/content`} component={Content} />
            <Redirect from={`${match.path}`} to={`${match.path}/content`} />
          </Switch>
        </div>
      </div>
      <PF2MAlertDialog
        hasCancel={false}
        confirmText={translate('common:Understood')}
        description={translate('validation:YouHaveUnsavedData')}
        open={alertDialog}
        onConfirm={() => dispatch(setAlertDialog())}
      />
    </Main>
  );
};

Quality.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

Quality.defaultProps = {};

export default withRouter(withStyles(styles)(Quality));

import * as types from './types';
import api from '~/services/api';
import { clearFileUploadError } from '../errors/actions';
import {
  exportData, importData, handleErrors, getTemplate,
} from '../common';

import { parseFloat } from '../../utils/index';

const getEndpoints = {
  contents: {
    url: '/quality/content',
    responseObject: 'content',
    typeAction: types.SET_CONTENTS_LIST,
  },
  contentvariable: {
    url: '/quality/contentvariable',
    responseObject: 'content_variable',
    typeAction: types.SET_CONTENT_VARIABLES_LIST,
  },
  stockclosings: {
    url: '/quality/stockclosings',
    responseObject: 'stock_closings',
    typeAction: types.SET_STOCK_CLOSINGS_LIST,
  },
};

export const getAll = ({ domain, clearUploadError = true }) => async (dispatch) => {
  try {
    if (clearUploadError) {
      dispatch(clearFileUploadError());
    }
    const endpoint = getEndpoints[domain];
    const {
      data: {
        result: { [endpoint.responseObject]: data = [] },
      },
    } = await api.get(endpoint.url);
    dispatch({
      type: endpoint.typeAction,
      payload: data,
    });
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const updateData = (domain, data) => async (dispatch) => {
  try {
    const items = data
      .filter(d => d.update_status)
      .map((d) => {
        const { isDirty, id, ...rest } = d;

        if (rest.update_status === 'U') return { id, ...rest };
        return rest;
      });
    const endpoint = getEndpoints[domain];

    const body = {
      [endpoint.responseObject]: items,
    };
    const { status } = await api.post(`${endpoint.url}`, body);
    if (status === 200) {
      dispatch(getAll({ domain }));
    }
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

const setValues = (item, key, value, type) => (dispatch) => {
  const updateStatus = item.update_status === 'I' ? 'I' : 'U';
  dispatch({
    type,
    payload: {
      ...item,
      [key]: value,
      update_status: updateStatus,
    },
  });
};

export const getContents = () => async dispatch => dispatch(getAll({ domain: 'contents' }));

export const updateContents = data => async dispatch => dispatch(updateData('contents', data));

export const setContentValue = (item, key, value) => (dispatch) => {
  dispatch(setValues(item, key, value, types.SET_CONTENT_VALUE));
};

export const resetContents = () => (dispatch) => {
  dispatch({ type: types.RESET_CONTENTS });
};

export const addContent = data => dispatch => dispatch({
  type: types.ADD_CONTENT,
  payload: data,
});

export const getContentVariables = () => async dispatch => dispatch(getAll({ domain: 'contentvariable' }));

export const updateContentVariables = (data, originalData) => async (dispatch) => {
  const items = data
    .filter(i => i.update_status)
    .map((d) => {
      const {
        element_id: element,
        element_point_id: subElement,
        isDirty,
        end_date: endDate,
        end_time: endTime,
        id = 0,
        material_id: material,
        start_date: startDate,
        start_time: startTime,
        update_status: updateStatus,
        active,
      } = d;

      const contents = Object.keys(d)
        .filter(k => k.startsWith('content_'))
        .map((attrs) => {
          const [, contentId, valueType] = attrs.split('_');
          const valueTypeId = Number(valueType);
          if (updateStatus === 'U') {
            const { contents: originalContents = [] } = originalData.find(
              o => o.id === id,
            );
            const existingContentVariable = originalContents.find(
              c => c.content_id === Number(contentId),
            );
            if (existingContentVariable) {
              return {
                id: existingContentVariable.id,
                content_id: Number(contentId),
                value: valueTypeId === 0 ? parseFloat(d[attrs] || 0) : null,
                value_str: valueTypeId === 1 ? d[attrs] : null,
                update_status: 'U',
                active: existingContentVariable.active,
              };
            }
          }
          return {
            content_id: Number(contentId),
            value: valueTypeId === 0 ? parseFloat(d[attrs] || 0) : null,
            value_str: valueTypeId === 1 ? d[attrs] : null,
            update_status: 'I',
          };
        });
      return {
        element_id: element,
        element_point_id: subElement,
        isDirty,
        end_date: endDate,
        end_time: endTime,
        id,
        material_id: material,
        start_date: startDate,
        start_time: startTime,
        update_status: updateStatus,
        active,
        contents,
      };
    });
  dispatch(updateData('contentvariable', items));
};

export const setContentVariableValue = (item, key, value) => (dispatch) => {
  const formattedValue = key.includes('_date')
    ? value.format('DD/MM/YYYY')
    : value;
  dispatch(
    setValues(item, key, formattedValue, types.SET_CONTENT_VARIABLE_VALUE),
  );
};

export const resetContentVariables = () => (dispatch) => {
  dispatch({ type: types.RESET_CONTENT_VARIABLES });
};

export const addContentVariable = data => (dispatch) => {
  dispatch({
    type: types.ADD_CONTENT_VARIABLE,
    payload: data,
  });
};

export const getContentVariablesTemplate = () => async dispatch => dispatch(getTemplate('quality/contentvariable'));
export const importContentVariables = file => async dispatch => dispatch(
  importData(
    'quality/contentvariable',
    file,
    () => getAll({ domain: 'contentvariable' }),
    'quality/contentvariable',
  ),
);
export const exportContentVariables = () => async dispatch => dispatch(exportData('quality/contentvariable'));

export const getStockClosings = () => async dispatch => dispatch(getAll({ domain: 'stockclosings' }));

export const updateStockClosings = (data, originalData) => async (dispatch) => {
  const items = data
    .filter(i => i.update_status)
    .map((d) => {
      const {
        id = 0,
        date,
        time,
        material_id: material,
        element_id: element,
        element_point_id: subElement,
        isDirty,
        mass,
        update_status: updateStatus,
        active,
      } = d;

      const contents = Object.keys(d)
        .filter(k => k.startsWith('content_'))
        .map((attrs) => {
          const [, contentId, valueType] = attrs.split('_');
          const valueTypeId = Number(valueType);
          if (updateStatus === 'U') {
            const { contents: originalContents = [] } = originalData.find(
              o => o.id === id,
            );
            const existingStockClosing = originalContents.find(
              c => c.content_id === Number(contentId),
            );
            if (existingStockClosing) {
              return {
                id: existingStockClosing.id,
                content_id: Number(contentId),
                value: valueTypeId === 0 ? parseFloat(d[attrs] || 0) : null,
                value_str: valueTypeId === 1 ? d[attrs] : null,
                update_status: 'U',
                active: existingStockClosing.active,
              };
            }
          }
          return {
            content_id: Number(contentId),
            value: valueTypeId === 0 ? parseFloat(d[attrs] || 0) : null,
            value_str: valueTypeId === 1 ? d[attrs] : null,
            update_status: 'I',
          };
        });
      return {
        id,
        contents,
        element_id: element,
        element_point_id: subElement,
        material_id: material,
        date,
        time,
        mass,
        update_status: updateStatus,
        active,
        isDirty,
      };
    });
  dispatch(updateData('stockclosings', items));
};

export const setStockClosingValue = (item, key, value) => (dispatch) => {
  const formattedValue = key === 'date' ? value.format('DD/MM/YYYY') : value;
  dispatch(setValues(item, key, formattedValue, types.SET_STOCK_CLOSING_VALUE));
};

export const resetStockClosings = () => (dispatch) => {
  dispatch({ type: types.RESET_STOCK_CLOSINGS });
};

export const addStockClosing = data => (dispatch) => {
  dispatch({
    type: types.ADD_STOCK_CLOSING,
    payload: data,
  });
};

export const getStockClosingsTemplate = () => async dispatch => dispatch(getTemplate('quality/stockclosings'));
export const importStockClosings = file => async dispatch => dispatch(importData('stockclosings', file, getAll, 'quality/stockclosings'));
export const exportStockClosings = () => async dispatch => dispatch(exportData('quality/stockclosings'));

import { formatDate, newMomentDate, formatTime } from '~/utils/moment';

export const defaultItem = {
  start_date: formatDate(newMomentDate(), true),
  end_date: formatDate(newMomentDate(), true),
  start_time: formatTime(newMomentDate(), 'HH:mm:ss'),
  end_time: formatTime(newMomentDate(), 'HH:mm:ss'),
  drilling_start_date: formatDate(newMomentDate(), true),
  drilling_start_time: formatTime(newMomentDate(), 'HH:mm:ss'),
  duration: 0.0,
  turn: 0,
  team_id: 0,
  fandrill_bit_diameter_id: 0,
  fandrill_bit_diameter_name_id: null,
  equip_group_id: 0,
  equip_id: 0,
  operator_group_id: 0,
  operator_id: 0,
  origin_id: 0,
  utm_x: null,
  utm_y: null,
  utm_zone_id: null,
  timestamp_usn: null,
  uid: null,
  tablet_id: 0,
  fandrill_fan_id: 0,
  rod_position: 0,
  rod_number: 0,
  rod_depth: 0,
  point_id: 0,
  material_id: 0,
  fire_id: 0,
  drilling_type: 0,
  n_drillholes: 0,
  hole_depth: 0,
  idle_time: 0,
  drilling_time: 0,
  operation_type_id: 0,
  hole_id: 0,
  X: 0,
  Y: 0,
  Z: 0,
};

export const filterPerforatorList = (perforatorList) => {
  let copyPerforatorList = [...perforatorList]
  return copyPerforatorList.map((item, index) => {
    Object.keys(item).forEach(key => {
      if ((item[key] === '' || item[key] === null)) {
        delete copyPerforatorList[index][key];
      }
    })

    if (Number.isNaN(parseInt(item.fandrill_bit_diameter_name_id))) {
      delete (
        copyPerforatorList[index]
          .fandrill_bit_diameter_name_id
      );
    } else {
      copyPerforatorList[index]
        .fandrill_bit_diameter_name_id =
        parseInt(item.fandrill_bit_diameter_name_id)
    }
    return item;
  });
}
import React, { useState, useEffect } from 'react';
import {
  Grid, withStyles, Switch, FormGroup, FormControlLabel, Modal,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import Footer from '~/pages/Manager/Footer';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from '../styles';
import PF2MButton from '~/components/PF2MButton';
import { clearCache, setCacheEnabled, isCacheEnabled } from '~/services/cache';
import {
  setLogoutLimitValue,
  resetLogoutLimit,
  updateLogoutLimit,
  getLogoutLimit,
} from '~/store/maintenance/actions';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import TabletConfigModal from '../TabletConfigModal';
import PlatformConfigModal from '../PlatformConfigModal';

const LogoutTab = ({ classes }) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const authLevel = useSelector(state => state.auth.user.level);
  const logoutLimit = useSelector(state => state.maintenance.logoutLimit);
  const isLogoutLimitDirty = useSelector(state => state.maintenance.isLogoutLimitDirty);
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const [isCacheEnabledState, setIsCacheEnabledState] = useState(isCacheEnabled());
  const [tabletOpen, setTabletOpen] = useState(false);
  const [platformOpen, setPlatformOpen] = useState(false);

  const entities = [
    { id: 1, name: translate('common:CodeType') },
    { id: 2, name: translate('common:CodeGroup') },
    { id: 3, name: translate('common:Code') },
  ];

  useEffect(() => {
    setCacheEnabled(isCacheEnabledState);
  }, [isCacheEnabledState]);

  useEffect(() => {
    async function fetchData() {
      dispatch(getLogoutLimit());
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch]);

  return (
    <>
      <Grid container className={classes.container}>
        {authLevel === 1 && (
          <>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <div className={classes.buttonContainer}>
                <Link to="/admin" className={classes.button}>
                  <PF2MButton style={{ width: 450 }}>{translate('common:AndroidAppVersion')}</PF2MButton>
                </Link>
              </div>
            </Grid>
            <Grid container style={{ justifyContent: 'center' }}>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <div className={classes.buttonContainer}>
                  <PF2MButton
                    style={{ width: 450 }}
                    onClick={() => setTabletOpen(true)}
                  >
                    {translate('common:TabletConfiguration')}
                  </PF2MButton>
                </div>
              </Grid>
            </Grid>
            <Grid container style={{ justifyContent: 'center' }}>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <div className={classes.buttonContainer}>
                  <PF2MButton
                    style={{ width: 450 }}
                    onClick={() => setPlatformOpen(true)}
                  >
                    {translate('common:PlatformConfiguration')}
                  </PF2MButton>
                </div>
              </Grid>
            </Grid>
          </>
        )}
        <Grid container style={{ justifyContent: 'center' }}>
          <Grid item xs={2} style={{ textAlign: 'center' }}>
            <div className={classes.buttonContainer}>
              <PF2MButton
                onClick={() => clearCache()}
              >
                {translate('common:ClearCache')}

              </PF2MButton>
            </div>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'left' }}>
            <FormGroup>
              <FormControlLabel
                control={(
                  <Switch
                    onClick={() => { setIsCacheEnabledState(!isCacheEnabledState); }}
                    checked={!isCacheEnabledState}
                  />
                )}
                label={translate('common:DisableCache')}
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Grid container style={{ justifyContent: 'center' }}>
          <Grid item xs={2}>
            <span className={classes.labelTypeSelector}>
              {`${translate('common:SelectMaintenanceLogoutLevel')}:`}
            </span>
          </Grid>
          <Grid item xs={6}>
            <PF2MSearchSelectOutlined
              value={logoutLimit}
              onChange={e => dispatch(setLogoutLimitValue(e.target.value))}
            >
              {[{ value: 0, label: translate('common:NotConnected') }]
                .concat(...entities
                  .map(e => ({ value: e.id, label: e.name })))}
            </PF2MSearchSelectOutlined>
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.footer}>
        <Footer
          isDirty={isLogoutLimitDirty}
          discard={() => dispatch(resetLogoutLimit())}
          sendData={() => dispatch(updateLogoutLimit(logoutLimit))}
        />
      </div>
      <PF2MAlertDialog
        hasCancel={false}
        confirmText={translate('common:Understood')}
        description={translate('validation:YouHaveUnsavedData')}
        open={isAlertOpen}
        onConfirm={() => setIsAlertOpen(false)}
      />
      <Modal
        open={tabletOpen}
        onBackdropClick={() => setTabletOpen(false)}
      >
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        >
          <TabletConfigModal
            tabletOpen={tabletOpen}
            close={() => setTabletOpen(false)}
          />
        </div>
      </Modal>
      <Modal
        open={platformOpen}
        onBackdropClick={() => setPlatformOpen(false)}
      >
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        >
          <PlatformConfigModal
            platformOpen={platformOpen}
            close={() => setPlatformOpen(false)}
          />
        </div>
      </Modal>
    </>
  );
};

LogoutTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

LogoutTab.defaultProps = {};

export default withStyles(styles)(LogoutTab);

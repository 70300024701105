import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import TurnGroupTab from './TurnGroupTab';
import TurnTab from './TurnTab';
import ShiftScaleTab from './ShiftScaleTab';
import PF2MTabs from '~/components/PF2MTabs';
import EquipmentsGroupsTurns from './EquipmentsGroupsTurns';
import { setAlertDialog } from '~/store/manager/actions';

const TurnGroup = ({
  setAlertDialog: setModal,
  isShiftScalesDirty,
  isTurnsGroupsDirty,
  isTurnsDirty,
  isEquipmentTurnGroupsDirty,
}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { t: translate } = useTranslation();

  const tabs = [
    { key: 0, label: translate('common:ShiftGroup') },
    { key: 1, label: translate('common:ShiftRegistration') },
    { key: 2, label: translate('common:ShiftSchedule') },
    { key: 3, label: translate('common:EquipmentGroup') },
  ];

  const handleChange = (event, value) => {
    let blockChange = false;

    switch (value) {
      case 1:
        blockChange = (isTurnsGroupsDirty || isEquipmentTurnGroupsDirty || isShiftScalesDirty);
        break;
      case 2:
        blockChange = (isTurnsGroupsDirty || isEquipmentTurnGroupsDirty || isTurnsDirty);
        break;
      case 3:
        blockChange = (isTurnsGroupsDirty || isShiftScalesDirty || isTurnsDirty);
        break;
      default:
        blockChange = (isShiftScalesDirty || isEquipmentTurnGroupsDirty || isTurnsDirty);
        break;
    }

    if (blockChange) {
      event.preventDefault();
      setModal();
    } else {
      setSelectedTab(value);
    }
  };

  return (
    <>
      <PF2MTabs
        value={selectedTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        {tabs}
      </PF2MTabs>
      {selectedTab === 0 ? (<TurnGroupTab />) : null}
      {selectedTab === 1 ? (<TurnTab />) : null}
      {selectedTab === 2 ? (<ShiftScaleTab />) : null}
      {selectedTab === 3 ? (<EquipmentsGroupsTurns />) : null}
    </>
  );
};

TurnGroup.propTypes = {
  setAlertDialog: PropTypes.func,
  isShiftScalesDirty: PropTypes.bool,
  isTurnsGroupsDirty: PropTypes.bool,
  isEquipmentTurnGroupsDirty: PropTypes.bool,
  isTurnsDirty: PropTypes.bool,
};

TurnGroup.defaultProps = {
  setAlertDialog: () => {},
  isShiftScalesDirty: false,
  isTurnsGroupsDirty: false,
  isEquipmentTurnGroupsDirty: false,
  isTurnsDirty: false,
};

const mapStateToProps = state => ({
  isTurnsGroupsDirty: state.manager.isTurnsGroupsDirty,
  isShiftScalesDirty: state.manager.isShiftScalesDirty,
  isEquipmentTurnGroupsDirty: state.manager.isEquipmentTurnGroupsDirty,
  isTurnsDirty: state.manager.isTurnsDirty,
});

const mapDispatchToProps = {
  setAlertDialog,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(TurnGroup));

import React, { useState, useEffect } from 'react';
import {
  withStyles, FormControl, FormLabel, FormGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Footer from '~/pages/Manager/Footer';
import PF2MTable from '~/components/PF2MTable';
import {
  setStockClosingValue,
  updateStockClosings,
  resetStockClosings,
  getStockClosings,
  getContents,
  exportStockClosings,
} from '~/store/quality/actions';
import { TimePicker } from '@material-ui/pickers';
import {
  getElements,
  getSubElements,
  getMaterials,
} from '~/store/manager/actions';
import Header from './Header';
import ImportExportHeader from '~/pages/Quality/Header';
import styles from '~/pages/Quality/styles';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import { newMomentDate, parseDate, parseTime } from '~/utils/moment';
import PF2MDatePickerOutlined from '~/components/PF2MDatePickerOutlined';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';

function handleMask(value) { return (value ? [/\d/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/] : []); }
const StockClosingTab = ({ classes }) => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const [showAlert, setShowAlert] = useState(false);

  const [dataLoaded, setDataLoaded] = useState(false);
  const [columnCreated, setColumnCreated] = useState(false);
  const [date, setDate] = useState(newMomentDate());
  const [time, setTime] = useState('00:00:00');
  const [element, setElement] = useState(0);
  const [subElement, setSubElement] = useState(0);
  const [material, setMaterial] = useState(0);

  const elements = useSelector(state => state.manager.elements.filter(r => r.is_destination));
  const subElements = useSelector(state => state.manager.subElements);
  const materials = useSelector(state => state.manager.materials);
  const contents = useSelector(state => state.quality.contents);
  const stockClosings = useSelector(state => state.quality.stockClosings);
  const stockClosingsFlat = useSelector(state => state.quality.stockClosingsFlat);
  const isStockClosingsDirty = useSelector(state => state.quality.isStockClosingsDirty);

  const [columns, setColumns] = useState([]);
  useEffect(() => {
    async function fetchData() {
      await Promise.all([
        dispatch(getContents()),
        dispatch(getElements()),
        dispatch(getSubElements()),
        dispatch(getMaterials()),
        dispatch(getStockClosings()),
      ]);
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch]);

  useEffect(() => {
    if (!columnCreated
      && contents.length > 0
      && elements.length > 0
      && subElements.length > 0
      && materials.length > 0) {
      const newColumns = contents
        .filter(c => c.active)
        .map(c => ({
          field: `content_${c.id}_${Number(!!c.value_type)}`,
          title: `${c.name}`,
          editable: true,
          type: c.value_type === 1 ? 'text' : 'decimal',
          decimalPrecision: 5,
          width: '100px',
        }));
      const initialColumns = [
        {
          field: 'date',
          title: translate('common:Date'),
          editable: true,
          updateOnChange: true,
          width: '150px',
          datePicker: true,
        },
        {
          field: 'time',
          title: translate('common:Time'),
          editable: true,
          updateOnChange: true,
          width: '150px',
          timePicker: true,
          timePickerFormat: 'HH:mm:ss',
          handleMask,
        },
        {
          field: 'element_id',
          title: translate('common:Element'),
          editable: true,
          selectItems: elements,
          width: '160px',
        },
        {
          field: 'element_point_id',
          title: translate('common:SubElement'),
          editable: true,
          selectItems: subElements,
          filterKey: 'id_element',
          filterValue: 'element_id',
          width: '160px',
        },
        {
          field: 'material_id',
          title: translate('common:Material'),
          editable: true,
          selectItems: materials,
          width: '160px',
        },
        {
          field: 'mass',
          title: translate('common:MassT'),
          editable: true,
          width: '160px',
        },
      ];
      setColumns([...initialColumns, ...newColumns]);
      setColumnCreated(true);
    }
  }, [columnCreated, contents, elements, subElements, materials, translate]);

  const renderTimePicker = (value, onChange) => (
    <TimePicker
      value={parseTime(value || '00:00:00')}
      keyboard
      autoOk
      ampm={false}
      inputVariant="outlined"
      format="HH:mm:ss"
      mask={handleMask}
      onChange={onChange}
      invalidDateMessage=""
      InputProps={{
        style: {
          height: 40,
          fontFamily: 'Roboto',
          fontWeight: 300,
          fontSize: 14,
          color: '#647886',
        },
      }}
    />
  );

  const renderDatePicker = (value, setAction) => (
    <PF2MDatePickerOutlined
      value={value}
      disableFuture
      helperText=""
      InputProps={{
        style: {
          minWidth: 150,
          height: 40,
        },
      }}
      onChange={setAction}
    />
  );

  const renderItems = (items = []) => [{ value: 0, label: translate('common:Select') }]
    .concat(...items
      .map(e => ({ value: e.id, label: e.name })));

  const renderFilters = () => (
    <div style={{
      display: 'grid',
      gridTemplateColumns: 'repeat(5, 200px)',
      gridColumnGap: 10,
      gridRowGap: 10,
      margin: '10px 0',
    }}
    >
      <FormControl>
        <FormLabel className={classes.formLabel}>
          {translate('common:Date')}
        </FormLabel>
        <FormGroup>
          {renderDatePicker(date, setDate)}
        </FormGroup>
      </FormControl>
      <FormControl>
        <FormLabel className={classes.formLabel}>
          {translate('common:Time')}
        </FormLabel>
        <FormGroup>
          {renderTimePicker(time, setTime)}
        </FormGroup>
      </FormControl>
      <FormControl>
        <FormLabel className={classes.formLabel}>
          {translate('common:Element')}
        </FormLabel>
        <FormGroup>
          <PF2MSearchSelectOutlined
            value={element}
            onChange={e => setElement(e.target.value)}
            className={classes.fieldSelect}
          >
            {renderItems(elements)}
          </PF2MSearchSelectOutlined>
        </FormGroup>
      </FormControl>
      <FormControl>
        <FormLabel className={classes.formLabel}>
          {translate('common:SubElement')}
        </FormLabel>
        <FormGroup>
          <PF2MSearchSelectOutlined
            value={subElement}
            onChange={e => setSubElement(e.target.value)}
            className={classes.fieldSelect}
          >
            {renderItems(subElements.filter(s => s.id_element === element))}
          </PF2MSearchSelectOutlined>
        </FormGroup>
      </FormControl>
      <FormControl>
        <FormLabel className={classes.formLabel}>
          {translate('common:Material')}
        </FormLabel>
        <FormGroup>
          <PF2MSearchSelectOutlined
            value={material}
            onChange={e => setMaterial(e.target.value)}
            className={classes.fieldSelect}
          >
            {renderItems(materials)}
          </PF2MSearchSelectOutlined>
        </FormGroup>
      </FormControl>
    </div>
  );

  return (
    <div className={classes.tabContainer}>
      <ImportExportHeader
        exportAction={() => dispatch(exportStockClosings())}
      />
      <Header />
      <div style={{
        border: '1px solid #CDCDCD',
        borderRadius: 5,
        padding: 10,
        margin: '10px 0',
      }}
      >
        <span className={classes.labelTypeSelector}>
          {translate('common:ClosingsList')}
        </span>
        {renderFilters()}
        <PF2MTable
          data={stockClosingsFlat
            .filter(c => (element === 0 || c.element_id === element)
              && (subElement === 0 || c.element_point_id === subElement)
              && (material === 0 || c.material_id === material)
              && parseDate(c.date, true).isSameOrAfter(date, 'day'))}
          columns={columns}
          updateItem={(row, field, value) => {
            dispatch(setStockClosingValue(row, field, value));
          }}
          className={classes.table}
        />
      </div>
      <Footer
        isDirty={isStockClosingsDirty}
        discard={() => dispatch(resetStockClosings())}
        sendData={() => dispatch(updateStockClosings(stockClosingsFlat, stockClosings))}
      />
      <PF2MAlertDialog
        confirmText={translate('common:Understood')}
        description={translate('validation:WarningScheduleChange')}
        open={showAlert}
        onClose={() => setShowAlert(false)}
        onConfirm={() => {
          setShowAlert(false);
          dispatch(updateStockClosings(stockClosings));
        }}
      />
    </div>
  );
};

StockClosingTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

StockClosingTab.defaultProps = {};

export default withStyles(styles)(StockClosingTab);

/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { FormControl, Checkbox } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import styles from './styles';

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      error={props.error}
      helperText={props.error}
      fullWidth={true}
      variant="outlined"
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.isMulti && <Checkbox style={{ marginRight: 5 }} checked={props.isSelected} />}
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
      noWrap
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

const PF2MSearchSelectOutlined = ({
  children,
  classes,
  theme,
  onChange,
  value,
  disabled,
  placeholder,
  multiple,
  error,
  clearedValue,
  ...rest
}) => {
  const { t: translate } = useTranslation();

  const calculateValue = () => {
    if (multiple) {
      const foundChildren = children.filter(r => value?.includes(r.value));
      return !children.length ? undefined : foundChildren;
    }
    const foundChild = children.find(r => r.value === value);
    return !foundChild ? { label: '', value: 0 } : foundChild;
  }

  return (
    <Select
      classes={classes}
      options={children}
      components={components}
      menuPlacement='auto'
      minMenuHeight={200}
      value={calculateValue()}
      onChange={e => {
        return multiple
          ? onChange({
            target: {
              value: e || [],
              label: ''
            }
          })
          : onChange({
            target: {
              value: e ? e.value : 0,
              label: e ? e.label : '',
            },
          })
      }}
      placeholder={placeholder}
      isClearable
      noOptionsMessage={() => translate('common:NoOptions')}
      isDisabled={disabled}
      isMulti={multiple}
      {...rest}
    />
  );
};

PF2MSearchSelectOutlined.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  error: PropTypes.string,
  clearedValue: PropTypes.any,
};

PF2MSearchSelectOutlined.defaultProps = {
  value: 0,
  onChange: () => null,
  disabled: false,
  children: [],
  placeholder: '',
  multiple: false,
  error: '',
  clearedValue: 0,
};

export default withStyles(styles, { withTheme: true })(PF2MSearchSelectOutlined);

/* eslint max-len:0 */
import React, { useState, useEffect } from 'react';
import {
  withStyles,
  Checkbox,
  ListItemText,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import Footer from '~/pages/Manager/Footer';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import {
  getSystems,
  getSystemItems,
  getEquipmentGroupsSystemItems,
  setEquipmentGroupSystemItemValue,
  updateEquipmentGroupsSystemItems,
  resetEquipmentGroupsSystemItems,
  checkEquipmentGroupSystemItems,
} from '~/store/maintenance/actions';
import {
  getEquipmentTypes,
  getEquipmentsGroups,
} from '~/store/manager/actions';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import PF2MScrollbar from '~/components/PF2MScrollbar';

const EquipmentGroupsSystemItemsTab = ({ classes }) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [selectedEquipmentType, setSelectedEquipmentType] = useState(0);
  const [selectedEquipmentGroup, setSelectedEquipmentGroup] = useState(0);
  const [selectedSystem, setSelectedSystem] = useState(0);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const isEquipmentGroupsSystemItemsDirty = useSelector(state => state.maintenance.isEquipmentGroupsSystemItemsDirty);
  const equipmentTypes = useSelector(state => state.manager.equipmentTypes);
  const equipmentsGroups = useSelector(state => state.manager.equipmentsGroups);
  const systems = useSelector(state => state.maintenance.systems);
  const equipmentGroupsSystemItems = useSelector(state => state.maintenance.equipmentGroupsSystemItems);

  useEffect(() => {
    async function fetchData() {
      dispatch(getSystemItems());
      dispatch(getSystems());
      dispatch(getEquipmentTypes());
      dispatch(getEquipmentsGroups());
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch]);

  useEffect(() => {
    if (selectedEquipmentGroup && selectedSystem) {
      dispatch(getEquipmentGroupsSystemItems(selectedEquipmentGroup, selectedSystem));
    }
  }, [selectedEquipmentGroup, selectedSystem, dispatch]);

  useEffect(() => {
    const hasUnchecked = equipmentGroupsSystemItems.some(e => !e.active);
    setSelectAll(equipmentGroupsSystemItems.length > 0 && !hasUnchecked);
  }, [equipmentGroupsSystemItems]);

  // const renderEquipmentGroupsSystemItems = () => systemItems
  //   .map(s => (
  //     <div key={s.id} style={{ display: 'flex', alignItems: 'center', gridColumnStart: '1' }}>
  //       <Checkbox
  //         checked={equipmentGroupsSystemItems
  //           .some(e => e.system_item_maintenance_id === s.system_item_maintenance_id
  //             && e.system_maintenance_id === selectedSystem
  //             && e.equipment_group_id === selectedEquipmentGroup
  //             && e.active)}
  //         color="primary"
  //         name={s.id.toString()}
  //         onChange={(e) => {
  //           dispatch(setEquipmentGroupSystemItemValue(selectedEquipmentGroup, selectedSystem, e.target.name, e.target.checked));
  //         }}
  //       />
  //       <ListItemText secondary={s.name} style={{ padding: 0 }} />
  //     </div>
  //   ));

  const renderEquipmentGroupsSystemItems = () => equipmentGroupsSystemItems
    .map(s => (
      <div key={s.system_item_maintenance_id} style={{ display: 'flex', alignItems: 'center', gridColumnStart: '1' }}>
        <Checkbox
          checked={s.active}
          color="primary"
          name={s.system_item_maintenance_id.toString()}
          onChange={(e) => {
            dispatch(setEquipmentGroupSystemItemValue(selectedEquipmentGroup, selectedSystem, e.target.name, e.target.checked));
          }}
        />
        <ListItemText secondary={s.system_item_maintenance_name} style={{ padding: 0 }} />
      </div>
    ));

  const renderSelectAll = () => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Checkbox
        checked={selectAll}
        color="primary"
        name="all"
        onChange={(e) => {
          dispatch(checkEquipmentGroupSystemItems(selectedEquipmentGroup, selectedSystem, e.target.checked));
          setSelectAll(!selectAll);
        }}
      />
      <ListItemText secondary={translate('common:SelectAll')} style={{ padding: 0 }} />
    </div>
  );

  const handleSystemChange = (e) => {
    const selected = equipmentGroupsSystemItems.some(m => m.update_status);
    if (selected) {
      setIsAlertOpen(true);
    } else {
      setSelectedSystem(e.target.value);
    }
  };

  const handleEquipmentType = (e) => {
    const selected = equipmentGroupsSystemItems.some(m => m.update_status);
    if (selected) {
      setIsAlertOpen(true);
    } else {
      setSelectedEquipmentType(e.target.value);
    }
  };

  const handleEquipmentGroup = (e) => {
    const selected = equipmentGroupsSystemItems.some(m => m.update_status);
    if (selected) {
      setIsAlertOpen(true);
    } else {
      setSelectedEquipmentGroup(e.target.value);
    }
  };

  return (
    <div className={classes.tabContainer}>
      <div style={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>
        <div style={{ marginRight: 10 }}>
          <div className={classes.formLabel}>
            <span>{translate('common:EquipmentType')}</span>
          </div>
          <PF2MSearchSelectOutlined
            value={selectedEquipmentType}
            onChange={handleEquipmentType}
            fullWidth={false}
            className={classes.typeSelector}
          >
            {[{ value: 0, label: translate('common:Select') }]
              .concat(...equipmentTypes
                .map(e => ({ value: e.id, label: e.name })))}
          </PF2MSearchSelectOutlined>
        </div>
        <div style={{ marginRight: 10 }}>
          <div className={classes.formLabel}>
            <span>{translate('common:EquipmentGroup')}</span>
          </div>
          <PF2MSearchSelectOutlined
            value={selectedEquipmentGroup}
            onChange={handleEquipmentGroup}
            fullWidth={false}
            className={classes.typeSelector}
          >
            {[{ value: 0, label: translate('common:Select') }]
              .concat(...equipmentsGroups
                .filter(e => selectedEquipmentType === 0 || e.id_equipament === selectedEquipmentType)
                .map(e => ({ value: e.id, label: e.name })))}
          </PF2MSearchSelectOutlined>
        </div>
        <div style={{ marginRight: 10 }}>
          <div className={classes.formLabel}>
            <span>{translate('common:System')}</span>
          </div>
          <PF2MSearchSelectOutlined
            value={selectedSystem}
            onChange={handleSystemChange}
            fullWidth={false}
            className={classes.typeSelector}
          >
            {[{ value: 0, label: translate('common:Select') }]
              .concat(...systems
                .map(e => ({ value: e.id, label: e.name })))}
          </PF2MSearchSelectOutlined>
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '100%' }}>
          <span className={classes.formLabel}>
            {translate('common:SystemItems')}
          </span>
          {renderSelectAll()}
          <PF2MScrollbar className={classes.scrollbars} style={{ height: 'auto' }}>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
              {renderEquipmentGroupsSystemItems()}
            </div>
          </PF2MScrollbar>
        </div>
      </div>
      <div style={{ marginTop: 20 }}>
        <Footer
          isDirty={isEquipmentGroupsSystemItemsDirty}
          discard={() => {
            dispatch(resetEquipmentGroupsSystemItems());
            setSelectAll(false);
          }}
          sendData={() => {
            dispatch(updateEquipmentGroupsSystemItems(equipmentGroupsSystemItems, selectedEquipmentGroup, selectedSystem));
            setSelectedEquipmentType(0);
            setSelectedEquipmentGroup(0);
            setSelectedSystem(0);
            setSelectAll(false);
          }}
          style={{ marginTop: 10 }}
        />
      </div>
      <PF2MAlertDialog
        hasCancel={false}
        confirmText="ENTENDI"
        description="Você possui dados alterados que precisam ser salvos."
        open={isAlertOpen}
        onConfirm={() => setIsAlertOpen(false)}
      />
    </div>
  );
};

EquipmentGroupsSystemItemsTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

EquipmentGroupsSystemItemsTab.defaultProps = {};

export default withStyles(styles)(EquipmentGroupsSystemItemsTab);

import { formatDate, newMomentDate, formatTime } from '~/utils/moment';

export const defaultItem = {
  start_date: formatDate(newMomentDate(), true),
  end_date: formatDate(newMomentDate(), true),
  start_time: formatTime(newMomentDate(), 'HH:mm:ss'),
  end_time: formatTime(newMomentDate(), 'HH:mm:ss'),
  update_status: 'I',
  exception_type: 14,
  equip_type: 25,
};

/* eslint-disable no-param-reassign */
import * as types from './types';
import api from '~/services/api';
import { clearFileUploadError } from '../errors/actions';
import { convertToUrlParameters, parseFloat as utilsParseFloat } from '~/utils';
import { formatDateToServerFormat } from '~/utils/moment';
import { SET_UPLOAD_SUCCESS } from '../errors/types';
import i18n from '~/i18n';
import {
  exportData, importData, handleErrors, getTemplate,
} from '../common';


const getEndpoints = {
  lineupList: {
    url: '/lineup/list',
    responseObject: 'lineups',
    typeAction: types.SET_LINEUP_LIST,
  },
  lineupElements: {
    url: '/lineup/elements',
    responseObject: 'lineup_elements',
    typeAction: types.SET_LINEUP_ELEMENTS_LIST,
  },
  lineupSubelements: {
    url: '/lineup/elementpoints',
    responseObject: 'lineup_element_points',
    typeAction: types.SET_LINEUP_SUBELEMENTS_LIST,
  },
  elevationGroups: {
    url: '/lineup/elevationgroups',
    responseObject: 'elevation_groups',
    typeAction: types.SET_ELEVATION_GROUPS_LIST,
  },
  elevationAssociations: {
    url: '/lineup/elevationassociations',
    responseObject: 'elevation_associations',
    typeAction: types.SET_ELEVATION_ASSOCIATIONS_LIST,
  },
  elementPrecedences: {
    url: '/lineup/elementprecedences',
    responseObject: 'element_precedences',
    typeAction: types.SET_ELEMENT_PRECEDENCE_LIST,
  },
  subElementPrecedences: {
    url: '/lineup/subelementprecedences',
    responseObject: 'subelement_precedences',
    typeAction: types.SET_SUBELEMENT_PRECEDENCE_LIST,
  },
  calculateSubelementPrecedences: {
    url: '/lineup/calculatesubelementprecedences',
    responseObject: 'subelement_precedences',
    typeAction: types.SET_SUBELEMENT_PRECEDENCE_LIST,
  },
  resources: {
    url: '/lineup/resources',
    responseObject: 'lineup_resources',
    typeAction: types.SET_RESOURCES_LIST,
  },
  priorities: {
    url: '/lineup/priorities',
    responseObject: 'lineup_priority',
    typeAction: types.SET_PRIORITIES_LIST,
  },
  load_allocations: {
    url: '/lineup/loadallocations',
    responseObject: 'lineup_load_allocations',
    typeAction: types.SET_LOAD_ALLOCATIONS_LIST,
  },
};

export const getAll = ({
  domain, clearUploadError = true, all = false, filters = {},
}) => async (dispatch) => {
  try {
    if (clearUploadError) {
      dispatch(clearFileUploadError());
    }
    const endpoint = getEndpoints[domain];
    if (all) filters.all = true;
    const queryString = convertToUrlParameters(filters);
    const endpointUrl = `${endpoint.url}?${queryString}`;

    const {
      data: {
        result: { [endpoint.responseObject]: data = [] },
      },
    } = await api.get(endpointUrl);

    dispatch({
      type: endpoint.typeAction,
      payload: data,
    });
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const updateData = (domain, data, filters) => async (dispatch) => {
  try {
    const items = data
      .filter(d => d.update_status)
      .map((d) => {
        const {
          isDirty, id, ...rest
        } = d;

        if (rest.update_status === 'U') return { id, ...rest };
        return rest;
      });
    const endpoint = getEndpoints[domain];

    const body = {
      [endpoint.responseObject]: items,
    };
    const { status } = await api.post(`${endpoint.url}`, body);
    if (status === 200) {
      dispatch(getAll({ domain, filters }));
    }
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

const setValues = (item, key, value, type) => (dispatch) => {
  const updateStatus = item.update_status === 'I' ? 'I' : 'U';
  dispatch({
    type,
    payload: {
      item: {
        ...item,
        [key]: value,
        update_status: updateStatus,
      },
      key,
    },
  });
};

export const setLineupValues = (item, key, value) => (dispatch) => {
  dispatch(setValues(item, key, value, 'lineups'));
};

export const addLineup = data => async (dispatch) => {
  const newItem = {
    name: data.name,
    start_date: data.startDate,
    end_date: data.endDate,
    creation_datetime: data.creationDate,
  };
  dispatch({
    type: types.ADD_LINEUP,
    payload: newItem,
  });
};


export const getLineups = dateFilter => async (dispatch) => {
  const date = formatDateToServerFormat(dateFilter);
  dispatch(getAll({ domain: 'lineupList', filters: { date } }));
};

export const setSelectedLineup = lineup => (dispatch) => {
  dispatch({ type: types.SET_SELECTED_LINEUP, payload: lineup });
};

export const updateLineups = (lineups, dateFilter) => async (dispatch) => {
  try {
    const endpoint = getEndpoints.lineupList;

    const mappedLineups = lineups.filter(r => r.update_status).map(r => ({
      start_date: formatDateToServerFormat(r.start_date),
      end_date: formatDateToServerFormat(r.end_date),
      name: r.name,
      id: r.id || null,
      is_top_priority: r.is_top_priority,
      update_status: r.update_status,
      active: r.active,
    }));

    const body = {
      lineups: mappedLineups,
    };

    const { status } = await api.post(`${endpoint.url}`, body);

    if (status === 200) {
      dispatch(getLineups(dateFilter));
      dispatch({ type: SET_UPLOAD_SUCCESS, payload: i18n.t('validation:UpdateSentSuccessfully') });
    }
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const getLineupElements = lineup => (dispatch) => {
  const params = { domain: 'lineupElements', filters: {} };
  if (lineup && lineup.id) {
    params.filters = { lineup_id: lineup.id || null };
  }
  dispatch(getAll(params));
};

export const getLineupSubelements = lineup => (dispatch) => {
  const params = { domain: 'lineupSubelements', filters: {} };
  if (lineup && lineup.id) {
    params.filters = { lineup_id: lineup.id || null };
  }
  dispatch(getAll(params));
};

export const toggleLineupElementAssociation = elementId => (dispatch) => {
  dispatch({
    type: types.TOGGLE_LINEUP_ELEMENT_ASSOCIATION,
    payload: elementId,
  });
};

export const toggleLineupSubelementAssociation = subelementId => (dispatch) => {
  dispatch({
    type: types.TOGGLE_LINEUP_SUBELEMENT_ASSOCIATION,
    payload: subelementId,
  });
};

export const restoreLineups = () => (dispatch) => {
  dispatch({ type: types.RESTORE_LINEUPS });
};

export const restoreLineupElements = () => (dispatch) => {
  dispatch({ type: types.RESTORE_LINEUP_ELEMENTS });
};

export const restoreLineupSubelements = () => (dispatch) => {
  dispatch({ type: types.RESTORE_LINEUP_SUBELEMENTS });
};

export const updateLineupElements = (lineupElements, selectedLineup) => async (dispatch) => {
  try {
    const endpoint = getEndpoints.lineupElements;

    const body = {
      [endpoint.responseObject]: lineupElements.filter(r => r.update_status),
    };

    const { status } = await api.post(`${endpoint.url}`, body);

    if (status === 200) {
      dispatch(getLineupElements(selectedLineup));
    }
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const updateLineupSubelements = (lineupSubelements, selectedLineup) => async (dispatch) => {
  try {
    const endpoint = getEndpoints.lineupSubelements;

    const body = {
      [endpoint.responseObject]: lineupSubelements.filter(r => r.update_status),
    };

    const { status } = await api.post(`${endpoint.url}`, body);

    if (status === 200) {
      dispatch(getLineupSubelements(selectedLineup));
    }
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const selectAllLineupSubelements = (subelementIds, lineup) => (dispatch) => {
  dispatch({
    type: types.BULK_SELECT_LINEUP_SUBELEMENTS,
    payload: {
      subelementIds,
      lineup,
    },
  });
};

export const deselectAllLineupSubelements = (subelementIds, lineup) => (dispatch) => {
  dispatch({
    type: types.BULK_DESELECT_LINEUP_SUBELEMENTS,
    payload: {
      subelementIds,
      lineup,
    },
  });
};

export const getElevationGroups = () => async (dispatch) => {
  dispatch(getAll({ domain: 'elevationGroups' }));
};

export const restoreElevationGroups = () => (dispatch) => {
  dispatch({ type: types.RESTORE_ELEVATION_GROUPS });
};

export const addElevationGroup = data => async (dispatch) => {
  const newItem = {
    name: data.name,
    initial_elevation: utilsParseFloat(data.initialElevation),
    final_elevation: utilsParseFloat(data.finalElevation),
    interval: utilsParseFloat(data.interval),
    active: true,
  };
  dispatch({
    type: types.ADD_ELEVATION_GROUP,
    payload: newItem,
  });
};

export const updateElevationGroups = elevationGroups => async (dispatch) => {
  try {
    const endpoint = getEndpoints.elevationGroups;

    const mappedElevationGroups = elevationGroups.filter(r => r.update_status).map(r => ({
      name: r.name,
      id: r.id || null,
      update_status: r.update_status,
      active: r.active,
      initial_elevation: r.initial_elevation,
      final_elevation: r.final_elevation,
      interval: r.interval,
    }));

    const body = {
      elevation_groups: mappedElevationGroups,
    };

    const { status } = await api.post(`${endpoint.url}`, body);

    if (status === 200) {
      dispatch(getElevationGroups());
      dispatch({ type: SET_UPLOAD_SUCCESS, payload: i18n.t('validation:UpdateSentSuccessfully') });
    }
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const getElevationAssociations = () => async (dispatch) => {
  dispatch(getAll({
    domain: 'elevationAssociations',
  }));
};

export const restoreElevationAssociations = () => (dispatch) => {
  dispatch({ type: types.RESTORE_ELEVATION_ASSOCIATIONS });
};

export const createElevationAssociation = (
  elementId, elevationGroupId, lineupId,
) => (dispatch) => {
  dispatch({
    type: types.CREATE_ELEVATION_ASSOCIATION,
    payload: {
      elementId,
      elevationGroupId,
      lineupId,
    },
  });
};

export const toggleElevationAssociation = (
  selectedElement, selectedElevationGroup, selectedLineup,
  elevation,
) => (dispatch) => {
  dispatch({
    type: types.TOGGLE_ELEVATION_ASSOCIATION,
    payload: {
      selectedElement,
      selectedElevationGroup,
      selectedLineup,
      elevation,
    },
  });
};

export const selectAllElevations = (
  selectedElement, selectedElevationGroup, selectedLineup,
) => (dispatch) => {
  dispatch({
    type: types.SELECT_ALL_ELEVATIONS,
    payload: {
      elementId: selectedElement,
      elevationGroupId: selectedElevationGroup,
      lineupId: selectedLineup,
    },
  });
};

export const deselectAllElevations = (
  selectedElement, selectedElevationGroup, selectedLineup,
) => (dispatch) => {
  dispatch({
    type: types.DESELECT_ALL_ELEVATIONS,
    payload: {
      elementId: selectedElement,
      elevationGroupId: selectedElevationGroup,
      lineupId: selectedLineup,
    },
  });
};

export const updateElevationAssociations = (
  elevAssociations, elementId, lineup,
) => async (dispatch) => {
  try {
    const endpoint = getEndpoints.elevationAssociations;

    const mappedElevationAssocs = elevAssociations
      .filter(r => r.update_status)
      .map(r => ({
        id: r.id || null,
        element_id: r.element_id || elementId,
        lineup_id: lineup.id,
        elevation_group_id: r.elevation_group_id,
        excluded_elevations: r.excluded_elevations.join(';'),
        update_status: r.update_status,
      }));

    const body = {
      elevation_associations: mappedElevationAssocs,
    };

    const { status } = await api.post(`${endpoint.url}`, body);

    if (status === 200) {
      dispatch(getElevationAssociations());
      dispatch({ type: SET_UPLOAD_SUCCESS, payload: i18n.t('validation:UpdateSentSuccessfully') });
    }
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const getElementPrecedences = lineup => async (dispatch) => {
  const params = { domain: 'elementPrecedences', filters: {} };
  if (lineup && lineup.id) {
    params.filters = { lineup_id: lineup.id || null };
  }
  dispatch(getAll(params));
};

export const restoreElementPrecedences = () => (dispatch) => {
  dispatch({ type: types.RESTORE_ELEMENT_PRECEDENCES });
};

export const toggleElementPrecedence = (
  fatherElementId, childElementId, lineupId,
) => (dispatch) => {
  dispatch({
    type: types.TOGGLE_ELEMENT_PRECEDENCE,
    payload: {
      fatherElementId,
      childElementId,
      lineupId,
    },
  });
};

export const selectAllElementPrecedences = (
  fatherElementId, elements, lineup,
) => (dispatch) => {
  dispatch({
    type: types.SELECT_ALL_ELEMENT_PRECEDENCES,
    payload: {
      fatherElementId,
      elements,
      lineupId: lineup.id,
    },
  });
};

export const deselectAllElementPrecedences = (
  fatherElementId, elements, lineup,
) => (dispatch) => {
  dispatch({
    type: types.DESELECT_ALL_ELEMENT_PRECEDENCES,
    payload: {
      fatherElementId,
      elements,
      lineupId: lineup.id,
    },
  });
};

export const updateElementPrecedences = (
  elementPrecedences, lineup,
) => async (dispatch) => {
  try {
    const endpoint = getEndpoints.elementPrecedences;

    const mappedElementPrecedences = elementPrecedences
      .filter(r => r.update_status)
      .map(r => ({
        id: r.id || null,
        father_element_id: r.father_element_id,
        child_element_id: r.child_element_id,
        lineup_id: lineup.id,
        update_status: r.update_status,
      }));

    const body = {
      element_precedences: mappedElementPrecedences,
    };

    const { status } = await api.post(`${endpoint.url}`, body);

    if (status === 200) {
      dispatch(getElementPrecedences(lineup));
      dispatch({ type: SET_UPLOAD_SUCCESS, payload: i18n.t('validation:UpdateSentSuccessfully') });
    }
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const getSubElementPrecedences = lineup => async (dispatch) => {
  const params = { domain: 'subElementPrecedences', filters: {} };
  if (lineup && lineup.id) {
    params.filters = { lineup_id: lineup.id || null };
  }
  dispatch(getAll(params));
};

export const restoreSubElementPrecedences = () => (dispatch) => {
  dispatch({ type: types.RESTORE_SUBELEMENT_PRECEDENCES });
};

export const toggleSubElementPrecedence = (
  fatherSubElementId, childSubElementId, lineupId,
) => (dispatch) => {
  dispatch({
    type: types.TOGGLE_SUBELEMENT_PRECEDENCE,
    payload: {
      fatherSubElementId,
      childSubElementId,
      lineupId,
    },
  });
};

export const selectAllSubElementPrecedences = (
  fatherSubElementId, subElements, lineup,
) => (dispatch) => {
  dispatch({
    type: types.SELECT_ALL_SUBELEMENT_PRECEDENCES,
    payload: {
      fatherSubElementId,
      subElements,
      lineupId: lineup.id,
    },
  });
};

export const deselectAllSubElementPrecedences = (
  fatherSubElementId, subElements, lineup,
) => (dispatch) => {
  dispatch({
    type: types.DESELECT_ALL_SUBELEMENT_PRECEDENCES,
    payload: {
      fatherSubElementId,
      subElements,
      lineupId: lineup.id,
    },
  });
};

export const updateSubElementPrecedences = (
  subElementPrecedences, lineup,
) => async (dispatch) => {
  try {
    const endpoint = getEndpoints.subElementPrecedences;

    const mappedSubElementPrecedences = subElementPrecedences
      .filter(r => r.update_status)
      .map(r => ({
        id: r.id || null,
        father_element_point_id: r.father_element_point_id,
        child_element_point_id: r.child_element_point_id,
        lineup_id: lineup.id,
        update_status: r.update_status,
      }));

    const body = {
      subelement_precedences: mappedSubElementPrecedences,
    };

    const { status } = await api.post(`${endpoint.url}`, body);

    if (status === 200) {
      dispatch(getSubElementPrecedences(lineup));
      dispatch({ type: SET_UPLOAD_SUCCESS, payload: i18n.t('validation:UpdateSentSuccessfully') });
    }
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const calculateSubElementPrecedences = lineup => async (dispatch) => {
  try {
    const endpoint = getEndpoints.calculateSubelementPrecedences;

    const { status } = await api.post(`${endpoint.url}`, { lineup_id: lineup?.id });

    if (status === 200) {
      dispatch(getSubElementPrecedences(lineup));
      dispatch({ type: SET_UPLOAD_SUCCESS, payload: i18n.t('validation:UpdateSentSuccessfully') });
    }
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const getResources = (lineupId, elementId) => async (dispatch) => {
  dispatch(getAll({
    domain: 'resources',
    filters: { element_id: elementId, lineup_id: lineupId },
  }));
};

export const restoreResources = () => (dispatch) => {
  dispatch({ type: types.RESTORE_RESOURCES });
};

export const updateResources = (data, filters = {}) => async (dispatch) => {
  const items = data
    .filter(i => i.update_status)
    .map((d) => {
      const contents = Object.keys(d)
        .filter(k => k.startsWith('content_'))
        .map((attrs) => {
          const [, contentId] = attrs.split('_');
          return {
            content_id: Number(contentId),
            value: parseFloat(d[attrs] || 0),
            update_status: 'D',
          };
        });
      return {
        date: formatDateToServerFormat(d.date),
        element_point_id: d.element_point_id,
        elevation: d.elevation,
        id: d.id,
        mass: d.mass,
        lineup_id: d.lineup_id,
        material_id: d.material_id,
        update_status: d.update_status,
        available_contents: contents,
      };
    });
  dispatch(updateData('resources', items, filters));
};

export const setResourceValue = (item, key, value) => (dispatch) => {
  const formattedValue = key.includes('_date') ? value.format('DD/MM/YYYY') : value;
  dispatch(setValues(item, key, formattedValue, types.SET_RESOURCE_VALUE));
};
export const importResources = file => async dispatch => dispatch(importData('lineup/resources', file, null, null));
export const exportResources = filters => async dispatch => dispatch(exportData('lineup/resources', filters));
export const getResourcesTemplate = () => async dispatch => dispatch(getTemplate('lineup/resources'));

export const getPriorities = (lineupId, elementId) => async (dispatch) => {
  dispatch(getAll({
    domain: 'priorities',
    filters: { element_id: elementId, lineup_id: lineupId },
  }));
};
export const restorePriorities = () => (dispatch) => {
  dispatch({ type: types.RESTORE_PRORITIES });
};

export const setPriorityValue = (item, key, value) => async dispatch => (dispatch({
  payload: {
    item,
    key,
    value,
  },
  type: types.SET_LINEUP_PRIORITY_VALUE,
}));
export const addPriority = data => async (dispatch) => {
  const newItem = {
    element_point_id: data.element_point_id,
    element_id: data.element_id,
    priority: data.priority,
    lineup_id: data.lineup_id,
  };
  dispatch({
    type: types.ADD_PRIORITY,
    payload: newItem,
  });
};
export const updatePriorities = (data, filters = {}) => async (dispatch) => {
  dispatch(updateData('priorities', data, filters));
};
export const getLoadAllocations = (lineupId, startDate) => async (dispatch) => {
  dispatch(getAll({
    domain: 'load_allocations',
    filters: { lineup_id: lineupId, start_date: startDate },
  }));
};
export const getLoadAllocationTemplate = () => async dispatch => dispatch(getTemplate('lineup/loadallocations'));
export const getLoadAllocationEdit = (lineup, date) => async dispatch => dispatch(getTemplate(`lineup/loadallocations/${lineup}/${date}`));
export const importLoadAllocation = file => async dispatch => dispatch(importData('lineup/loadallocations', file, null, null));

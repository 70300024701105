import React, { useState, useEffect } from 'react';
import {
  withStyles, FormControl, FormLabel, FormGroup, Fab, Typography, TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import CreateIcon from '@material-ui/icons/Create';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import NumberFormat from 'react-number-format';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import PF2MButton from '~/components/PF2MButton';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import {
  Card, CardBody, CardHeader, CardIcon,
} from '~/components/Card';
import PF2MOutlinedInput from '~/components/PF2MOutlinedInput';
import {
  updateUndergroundOperationControls,
  setCurrentOperationControl,
  setOriginalCurrentOperationControl,
} from '~/store/underground/actions';


const OperationControlEditModal = ({ classes, handleClose }) => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const currentOperationControl = useSelector(state => state.underground.currentOperationControl);
  // eslint-disable-next-line max-len
  const filters = useSelector(state => state.underground.currentOperationControlFilter);
  const originalOperationControl = useSelector(
    state => state.underground.originalCurrentOperationControl,
  );
  const unitOperationGroups = useSelector(state => state.underground.unitOperationGroups);
  const unitOperations = useSelector(state => state.underground.unitOperations);
  const codeTypes = useSelector(state => state.underground.codeTypes);
  const codeGroups = useSelector(state => state.underground.codeGroups);
  const codes = useSelector(state => state.underground.codes);
  const inputUnits = useSelector(state => state.underground.inputUnits);
  const [inputUnitName, setInputUnitName] = useState(null);
  const [inputName, setInputName] = useState(null);
  const [isNewCycle, setIsNewCycle] = useState(false);

  useEffect(() => {
    const unitOp = unitOperations
      .find(u => u.id === currentOperationControl.id_unitary_operation);
    if (unitOp) {
      const inputUnit = inputUnits.find(i => i.id === unitOp.underground_input_unit_id);
      setInputUnitName(inputUnit ? inputUnit.name : null);
      setInputName(unitOp.input ? unitOp.input : null);
      return;
    }
    setInputUnitName(null);
    setInputName(null);
  }, [unitOperations, inputUnits, currentOperationControl.id_unitary_operation]);

  const renderHeader = () => (
    <div>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
      >
        {`${translate('common:SubElement').toUpperCase()}: ${currentOperationControl.element_point_name || translate('common:NoName')}`}
      </Typography>
      <div style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridColumnGap: 10,
        gridRowGap: 10,
      }}
      >
        <div>
          <span className={classes.labelTypeSelector}>
            {`${translate('common:ProjectGroup')}:`}
          </span>
          <span style={{ color: '#647886' }}>
            {currentOperationControl.project_group_name || translate('common:NotConnected')}
          </span>
        </div>
        <div>
          <span className={classes.labelTypeSelector}>
            {`${translate('common:Priority')}:`}
          </span>
          <span style={{ color: '#647886' }}>
            {currentOperationControl.priority || '0'}
          </span>
        </div>
        <div>
          <span className={classes.labelTypeSelector}>
            {`${translate('common:Element')}:`}
          </span>
          <span style={{ color: '#647886' }}>
            {currentOperationControl.element_name || translate('common:NoElement')}
          </span>
        </div>
        <div>
          <span className={classes.labelTypeSelector}>
            {translate('common:CycleId')}
          </span>
          <span style={{ color: '#647886' }}>
            {currentOperationControl.cicle || '0'}
          </span>
        </div>
      </div>
    </div>
  );

  const filterUnitOperations = (u) => {
    const { id_unitary_operation_group: group } = currentOperationControl;
    return group === 0 || u.id_unitary_operation_group === group;
  };

  const filterCodeGroups = (c) => {
    const { id_code_type_ug: codeType } = currentOperationControl;
    return codeType === 0 || c.id_code_type_ug === codeType;
  };

  const filterCodes = (c) => {
    const { id_code_group_ug: codeGroup } = currentOperationControl;
    return codeGroup === 0 || c.id_code_group_ug === codeGroup;
  };

  const modalHandleChange = (field, value) => {
    if (field === 'id_unitary_operation') {
      if (currentOperationControl.id_unitary_operation !== value) {
        currentOperationControl.input_efective = 0;
        currentOperationControl.input_rework = 0;
      }
      const [firstUnitOp] = unitOperations.filter(filterUnitOperations);
      const newCycle = firstUnitOp && firstUnitOp.id === value;
      setIsNewCycle(newCycle);

      if (newCycle) {
        const newCurrentOperationControl = {
          ...currentOperationControl,
          estimated_advance: 0,
          measured_advance: 0,
        };
        dispatch(setCurrentOperationControl(newCurrentOperationControl, field, value));
        return;
      }
    }

    dispatch(setCurrentOperationControl(currentOperationControl, field, value));
  };

  const renderFormControl = (
    item,
    key,
    title,
    collection,
    initialValue = 0,
  ) => (
    <FormControl>
      <FormLabel className={classes.formLabel}>
        {title}
      </FormLabel>
      <FormGroup>
        <PF2MSearchSelectOutlined
          placeholder={null}
          className={classnames(classes.field)}
          value={item[key] || 0}
          onChange={e => modalHandleChange(key, e.target.value)}
        >
          {[{ value: initialValue, label: translate('common:Select') }]
            .concat(collection
              .map(e => ({ value: e.id, label: e.name || '0' })))}
        </PF2MSearchSelectOutlined>
      </FormGroup>
    </FormControl>
  );

  return (
    <div>
      <Card>
        <CardHeader icon>
          <CardIcon color="warning">
            <CreateIcon />
          </CardIcon>
        </CardHeader>
        <CardBody>
          {renderHeader()}
          <hr />
          <div style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gridColumnGap: 10,
            gridRowGap: 10,
          }}
          >
            {renderFormControl(currentOperationControl, 'id_unitary_operation_group', translate('common:UnitOperationGroup'), unitOperationGroups)}
            <div style={{ gridColumnStart: 2, gridColumnEnd: 4 }}>
              {renderFormControl(currentOperationControl, 'id_unitary_operation', translate('common:UnitOperation'), unitOperations.filter(filterUnitOperations))}
            </div>
            {renderFormControl(currentOperationControl, 'id_code_type_ug', translate('common:CodeType'), codeTypes)}
            {renderFormControl(currentOperationControl, 'id_code_group_ug', translate('common:CodeGroup'), codeGroups.filter(filterCodeGroups))}
            {renderFormControl(currentOperationControl, 'id_code_ug', translate('common:Code'), codes.filter(filterCodes))}
            <FormControl>
              <FormLabel className={classes.formLabel}>
                {`${translate('common:EstimatedAdvance')}:`}
              </FormLabel>
              <FormGroup>
                <NumberFormat
                  customInput={TextField}
                  variant="outlined"
                  InputProps={{
                    classes: {
                      root: classes.fieldRoot,
                      input: classes.textField,
                    },
                  }}
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale
                  disabled={
                    !isNewCycle && Boolean(parseFloat(originalOperationControl.estimated_advance))
                  }
                  value={currentOperationControl.estimated_advance}
                  onBlur={e => modalHandleChange('estimated_advance', e.target.value)}
                />
              </FormGroup>
            </FormControl>
            <FormControl>
              <FormLabel className={classes.formLabel}>
                {`${translate('common:MeasuredAdvance')}:`}
              </FormLabel>
              <FormGroup>
                <NumberFormat
                  customInput={TextField}
                  variant="outlined"
                  InputProps={{
                    classes: {
                      root: classes.fieldRoot,
                      input: classes.textField,
                    },
                  }}
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale
                  value={currentOperationControl.measured_advance}
                  onBlur={e => modalHandleChange('measured_advance', e.target.value)}
                />
              </FormGroup>
            </FormControl>
            {(inputUnitName && currentOperationControl.input) && (
              <FormControl style={{ gridColumnStart: 1 }}>
                <FormLabel className={classes.formLabel}>
                  {`${inputName} (${inputUnitName}) - ${translate('common:InputEffective')}:`}
                </FormLabel>
                <FormGroup>
                  <NumberFormat
                    customInput={TextField}
                    variant="outlined"
                    InputProps={{
                      classes: {
                        root: classes.fieldRoot,
                        input: classes.textField,
                      },
                    }}
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                    value={currentOperationControl.input_efective}
                    onBlur={e => modalHandleChange('input_efective', e.target.value)}
                  />
                </FormGroup>
              </FormControl>
            )}
            {(inputUnitName && currentOperationControl.input) && (
              <FormControl>
                <FormLabel className={classes.formLabel}>
                  {`${inputName} (${inputUnitName}) - ${translate('common:InputRework')}:`}
                </FormLabel>
                <FormGroup>
                  <NumberFormat
                    customInput={TextField}
                    variant="outlined"
                    InputProps={{
                      classes: {
                        root: classes.fieldRoot,
                        input: classes.textField,
                      },
                    }}
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                    value={currentOperationControl.input_rework}
                    onBlur={e => modalHandleChange('input_rework', e.target.value)}
                  />
                </FormGroup>
              </FormControl>
            )}
          </div>
          <div style={{ display: 'flex', marginTop: 10 }}>
            <FormControl style={{ flex: 1 }}>
              <FormGroup>
                <FormLabel className={classes.formLabel}>
                  {`${translate('common:Comment')}:`}
                </FormLabel>
                <PF2MOutlinedInput
                  className={classes.fieldCommentary}
                  value={currentOperationControl.comment || ''}
                  onBlur={e => modalHandleChange('comment', e.target.value)}
                />
              </FormGroup>
            </FormControl>
          </div>
          <div style={{
            display: 'flex',
            marginTop: 10,
            alignItems: 'center',
            justifyContent: 'center',
          }}
          >
            <PF2MButton
              color="primary"
              onClick={() => {
                dispatch(setCurrentOperationControl(null));
                dispatch(setOriginalCurrentOperationControl(null));
                setIsNewCycle(false);
                handleClose(false);
              }}
            >
              <DeleteOutline className={classes.icon} />
              {translate('common:DiscardChanges')}
            </PF2MButton>
            <Fab
              variant="extended"
              size="medium"
              color="secondary"
              onClick={() => {
                dispatch(updateUndergroundOperationControls(currentOperationControl, filters));
              }}
              className={classes.saveButton}
            >
              {isNewCycle
                ? translate('common:SaveDataAndStartNewCycle')
                : translate('common:SaveData')}
            </Fab>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

OperationControlEditModal.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func,
};

OperationControlEditModal.defaultProps = {
  handleClose: null,
};

export default withStyles(styles)(OperationControlEditModal);

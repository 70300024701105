/* eslint-disable no-param-reassign */
import * as types from './types';
import { SET_UPLOAD_SUCCESS } from '../errors/types';
import api from '~/services/api';
import { requestCache } from '~/services/cache';
import { parseFloat } from '~/utils';
import { formatTime, formatDate } from '~/utils/moment';
import { clearFileUploadError } from '../errors/actions';
import {
  exportData, importData, getTemplate, handleErrors,
} from '../common';

import i18n from '~/i18n';

export const getEndpoints = {
  tabletconfiguration: {
    url: '/tabletconfiguration',
    responseObject: 'tablet_configuration',
    typeAction: types.SET_TABLET_CONFIGURATION_LIST,
  },
  platformconfiguration: {
    url: '/platformconfiguration',
    responseObject: 'platform_configuration',
    typeAction: types.SET_PLATFORM_CONFIGURATION_LIST,
  },
  operators: {
    url: '/operators',
    responseObject: 'operators',
    typeAction: types.SET_OPERATORS_LIST,
    tableName: 'operators',
  },
  operatorsgroups: {
    url: '/operatorsgroups',
    responseObject: 'operators_groups',
    typeAction: types.SET_OPERATORS_GROUPS_LIST,
    tableName: 'operators_groups',
  },
  equipments: {
    url: '/equipments',
    responseObject: 'equipments',
    typeAction: types.SET_EQUIPMENTS_LIST,
    tableName: 'group_equipament_links',
  },
  equipmentsgroups: {
    url: '/equipmentsgroups',
    responseObject: 'equipments_groups',
    typeAction: types.SET_EQUIPMENTS_GROUPS_LIST,
    tableName: 'equipament_groups',
  },
  materials: {
    url: '/materials',
    responseObject: 'materials',
    typeAction: types.SET_MATERIALS_LIST,
    tableName: 'materials',
  },
  materialsgroups: {
    url: '/materialsgroups',
    responseObject: 'materials_groups',
    typeAction: types.SET_MATERIALS_GROUPS_LIST,
    tableName: 'material_groups',
  },
  turnsgroups: {
    url: '/turnsgroups',
    responseObject: 'turns_groups',
    typeAction: types.SET_TURNS_GROUPS_LIST,
  },
  shiftscales: {
    url: '/shiftscales',
    responseObject: 'shifts_scales',
    typeAction: types.SET_SHIFT_SCALES_LIST,
  },
  equipmentturngroups: {
    url: '/equipmentturngroups',
    responseObject: 'equipment_turn_groups',
    typeAction: types.SET_EQUIPMENT_TURN_GROUPS_LIST,
  },
  handlingtypes: {
    url: '/handlingtypes',
    responseObject: 'handling_types',
    typeAction: types.SET_HANDLING_TYPES_LIST,
    tableName: 'handling_types',
  },
  elements: {
    url: '/elements',
    responseObject: 'elements',
    typeAction: types.SET_ELEMENTS_LIST,
    tableName: 'flowchart_elements',
  },
  subelements: {
    url: '/subelements',
    responseObject: 'subelements',
    typeAction: types.SET_SUBELEMENTS_LIST,
    tableName: 'flowchart_element_points',
  },
  materialmovements: {
    url: '/materialmovements',
    responseObject: 'material_movements',
    typeAction: types.SET_MATERIAL_MOVEMENTS_LIST,
    tableName: 'material_movements',
  },
  materialelements: {
    url: '/materialelements',
    responseObject: 'material_elements',
    typeAction: types.SET_MATERIAL_ELEMENTS_LIST,
  },
  codes: {
    url: '/codes',
    responseObject: 'codes',
    typeAction: types.SET_CODES_LIST,
    tableName: 'codes',
  },
  hour_machine: {
    url: '/hour_machine',
    responseObject: 'hour_machine',
    typeAction: types.SET_HOUR_MACHINE_LIST,
  },
  codetypes: {
    url: '/codetypes',
    responseObject: 'code_types',
    typeAction: types.SET_CODE_TYPES_LIST,
    tableName: 'code_types',
  },
  codegroups: {
    url: '/codegroups',
    responseObject: 'code_groups',
    typeAction: types.SET_CODE_GROUPS_LIST,
    tableName: 'code_groups',
  },
  controltypes: {
    url: '/controltypes',
    responseObject: 'control_types',
    typeAction: types.SET_CONTROL_TYPES_LIST,
    tableName: 'control_types',
  },
  equipmenttypes: {
    url: '/equipmenttypes',
    responseObject: 'equipment_types',
    typeAction: types.SET_EQUIPMENT_TYPES_LIST,
    tableName: 'equipment_types',
  },
  weighings: {
    url: '/weighings',
    responseObject: 'weighings',
    typeAction: types.SET_WEIGHINGS_LIST,
  },
  weighingspost: {
    url: '/weighings/get_weighings',
    responseObject: 'weighings',
    typeAction: types.SET_WEIGHINGS_LIST,
  },
  checklist: {
    url: '/checklist',
    responseObject: 'checklist',
    typeAction: types.SET_CHECKLIST_LIST,
  },
  checklistmaps: {
    url: '/checklistmaps',
    responseObject: 'checklist_maps',
    typeAction: types.SET_CHECKLIST_MAPS_LIST,
  },
  turns: {
    url: '/turns',
    responseObject: 'turns',
    typeAction: types.SET_TURNS_LIST,
    tableName: 'turns',
  },
  dmt: {
    url: '/dmt',
    responseObject: 'dmt',
    typeAction: types.SET_DMTS_LIST,
  },
  loads: {
    url: '/loads/get_load_defaults',
    responseObject: 'loads',
    typeAction: types.SET_LOADS_LIST,
  },
  teamgroups: {
    url: '/teamgroups',
    responseObject: 'team_groups',
    typeAction: types.SET_TEAM_GROUPS_LIST,
    tableName: 'team_group',
  },
  team: {
    url: '/team',
    responseObject: 'teams',
    typeAction: types.SET_TEAMS_LIST,
    tableName: 'team',
  },
  teamslisting: {
    url: '/teamlisting/get_team_listing',
    responseObject: 'teams_listing',
    typeAction: types.SET_TEAMS_LISTING_LIST,
  },
  teamslistingpost: {
    url: '/teamlisting',
    responseObject: 'teams_listing',
  },
  fireid: {
    url: '/fireid',
    responseObject: 'fire_ids',
    typeAction: types.SET_FIRE_IDS_LIST,
  },
  drillholetype: {
    url: '/drillholetype',
    responseObject: 'drillhole_types',
    typeAction: types.SET_DRILLHOLE_TYPES_LIST,
    tableName: 'drillhole_types',
  },
  drillholedepth: {
    url: '/drillholedepth',
    responseObject: 'drillhole_depths',
    typeAction: types.SET_DRILLHOLE_DEPTHS_LIST,
  },
  turnspost: {
    url: '/turns/get_turns',
    responseObject: 'turns',
    typeAction: types.SET_TURNS_LIST,
  },
  calendarturnsteams: {
    url: '/calendarturnsteams/get_calendar_turns_teams',
    responseObject: 'calendar_turns_teams',
    typeAction: types.SET_CALENDAR_TURNS_TEAMS,
  },
  calendarturnsteamspost: {
    url: '/calendarturnsteams',
    responseObject: 'calendar_turns_teams',
  },
  elementsod: {
    url: '/elementsod',
    responseObject: 'elements_od',
    typeAction: types.SET_ELEMENTS_OD_LIST,
  },
  originsubelements: {
    url: '/subelements/origins',
    responseObject: 'subelements',
    typeAction: types.SET_SUBELEMENTS_LIST,
  },
  operationtype: {
    url: '/operationtype',
    responseObject: 'operation_type',
    typeAction: types.SET_OPERATION_TYPES_LIST,
    tableName: 'operation_type',
  },
  operationtypeactivity: {
    url: '/operationtypeactivity',
    responseObject: 'operation_type_activity',
    typeAction: types.SET_OPERATION_TYPE_ACTIVITIES_LIST,
    tableName: 'operation_type_activity',
  },
  transportoperationtype: {
    url: '/transportoperationtype',
    responseObject: 'transport_operation_type',
    typeAction: types.SET_TRANSPORT_OPERATION_TYPES_LIST,
    tableName: 'transport_operation_type',
  },
  tractorproductivity: {
    url: '/tractorproductivity',
    responseObject: 'tractor_productivity',
    typeAction: types.SET_TRACTOR_PRODUCTIVITIES_LIST,
  },
  exceptiontype: {
    url: '/exceptiontype',
    responseObject: 'exception_type',
    typeAction: types.SET_EXCEPTION_TYPES_LIST,
  },
  sleeplevel: {
    url: '/sleeplevel',
    responseObject: 'sleep_level',
    typeAction: types.SET_SLEEP_LEVEL_LIST,
  },
  fandrill_fan: {
    url: '/fandrillfan',
    responseObject: 'fandrill_fan',
    typeAction: types.SET_FANDRILL_FAN_LIST,
  },
  fandrillbitdiameter: {
    url: '/fandrillbitdiameter',
    responseObject: 'fandrill_bit_diameter',
    typeAction: types.SET_FANDRILL_BIT_DIAMETERS_LIST,
  },
  fandrillbitdiametername: {
    url: '/fandrillbitdiametername',
    responseObject: 'fandrill_bit_diameter_name',
    typeAction: types.SET_FANDRILL_BIT_DIAMETERS_NAMES_LIST,
  },
  hourmetername: {
    url: '/hourmetername',
    responseObject: 'hourmeter_name',
    typeAction: types.SET_HOURMETER_NAMES_LIST,
  },
  loadfactor: {
    url: '/loadfactor',
    responseObject: 'load_factor',
    typeAction: types.SET_LOAD_FACTOR_LIST,
  },
  subelementtypes: {
    url: '/subelementtypes',
    responseObject: 'sub_element_types',
    typeAction: types.SET_SUBELEMENT_TYPES_LIST,
  },
  flowmovements: {
    url: '/flowmovements',
    responseObject: 'flow_movements',
    typeAction: types.SET_FLOW_MOVEMENTS_LIST,
  },
  flowtypes: {
    url: '/flowtypes',
    responseObject: 'flow_types',
    typeAction: types.SET_FLOW_TYPES_LIST,
  },
  webusers: {
    url: '/webusers',
    responseObject: 'web_users',
    typeAction: types.WEB_USERS_LIST,
  },
  contents: {
    url: '/quality/content',
    responseObject: 'content',
    typeAction: types.SET_CONTENTS_LIST,
    tableName: 'content',
  },
  contentvariable: {
    url: '/quality/contentvariable',
    responseObject: 'content_variable',
    typeAction: types.SET_CONTENT_VARIABLES_LIST,
    tableName: 'content_variable',
  },
  unitaryoperationgroup: {
    url: '/unitaryoperationgroup',
    responseObject: 'unitary_operation_groups',
    typeAction: types.SET_UNIT_OPERATION_GROUPS_LIST,
    tableName: 'unitary_operation_group',
  },
  unitaryoperation: {
    url: '/unitaryoperation',
    responseObject: 'unitary_operation',
    typeAction: types.SET_UNIT_OPERATIONS_LIST,
    tableName: 'unitary_operation',
  },
  activitytypechecklist: {
    url: '/activitytypechecklist',
    responseObject: 'activity_types_checklist',
    typeAction: types.SET_ACTIVITY_TYPES_CHECKLIST_LIST,
    tableName: 'activity_type_checklist',
  },
  activityitemchecklist: {
    url: '/activityitemchecklist',
    responseObject: 'activity_items_checklist',
    typeAction: types.SET_ACTIVITY_ITEMS_CHECKLIST_LIST,
    tableName: 'activity_item_checklist',
  },
  goalindicators: {
    url: '/goal/goalindicator',
    responseObject: 'goal_indicators',
    typeAction: types.SET_GOAL_INDICATORS_LIST,
    tableName: 'goal_indicators',
  },
  origintypes: {
    url: '/origintypes',
    responseObject: 'origin_types',
    typeAction: types.SET_ORIGIN_TYPE_LIST,
    tableName: 'origin_types',
  },
  boomreasons: {
    url: '/usepartialboomreason',
    responseObject: 'use_partial_boom_reason',
    typeAction: types.SET_BOOM_REASON_LIST,
    tableName: 'use_partial_boom_reason',
  },
  codemigrationrules: {
    url: '/codes/migration_rules',
    responseObject: 'migration_rules',
    typeAction: types.SET_MIGRATION_RULES_LIST,
    tableName: 'code_migration_rules',
  },
  codes_raw_rules_match: {
    url: '/partes_diarias/codes_raw_rules_match',
    responseObject: 'migration_match',
    typeAction: types.SET_CODES_RAW_RULES_MATCH,
  },
  beacontypes: {
    url: '/beacontype',
    responseObject: 'beacon_type',
    tableName: 'beacon_type',
    typeAction: types.SET_BEACON_TYPES_LIST,
  },
  beacons: {
    url: '/beacon',
    responseObject: 'beacon',
    typeAction: types.SET_BEACONS_LIST,
  },
};

export const getAll = ({
  domain,
  clearUploadError = true,
  all = false,
  filters = {},
}) => async (dispatch) => {
  try {
    if (clearUploadError) {
      dispatch(clearFileUploadError());
    }
    const endpoint = getEndpoints[domain];
    if (all) filters.all = true;
    const { active, ...noActive } = filters;
    const newFilters = active === -1 ? noActive : filters;
    const request = () => api.get(endpoint.url, { params: newFilters });
    const {
      data: {
        result: { [endpoint.responseObject]: data = [] },
      },
    } = await requestCache(
      domain,
      request,
      filters,
      endpoint.tableName ? endpoint.tableName : false,
    );
    dispatch({
      type: endpoint.typeAction,
      payload: data,
    });
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const updateData = (
  domain,
  data,
  refreshData = true,
  all = false,
  filters = {},
) => async (dispatch) => {
  try {
    const items = data
      .filter(d => d.update_status)
      .map((d) => {
        const {
          isDirty,
          id,
          start_turn: start,
          end_turn: end,
          blocked,
          ...rest
        } = d;
        if (start && end) {
          rest.start_turn = formatTime(start, 'HH:mm');
          rest.end_turn = formatTime(end, 'HH:mm');
        }

        if (rest.update_status !== 'I') return { id, ...rest };
        return rest;
      });
    const endpoint = getEndpoints[domain];
    const body = {
      [endpoint.responseObject]: items,
    };
    const { status } = await api.post(`${endpoint.url}`, body);
    if (status === 200 && refreshData) {
      dispatch(getAll({ domain, all, filters }));
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    if (err.response) {
      dispatch(handleErrors(err.response));
    }
  }
};

export const getCodesRawRulesMatch = (
  all = false, filters = {},
) => async dispatch => dispatch(getAll({
  domain: 'codes_raw_rules_match',
  all,
  filters,
}));
export const getOperators = (all = false) => async dispatch => dispatch(getAll({ domain: 'operators', all }));
export const getOperatorsGroups = (all = false) => async dispatch => dispatch(getAll({ domain: 'operatorsgroups', all }));
export const getEquipments = (all = false) => async dispatch => dispatch(getAll({ domain: 'equipments', all }));
export const getEquipmentsGroups = (all = true) => async dispatch => dispatch(getAll({ domain: 'equipmentsgroups', all }));
export const getMaterials = (all = false) => async dispatch => dispatch(getAll({ domain: 'materials', all }));
export const getMaterialsGroups = (all = false) => async dispatch => dispatch(getAll({ domain: 'materialsgroups', all }));
export const getTurnsGroups = (all = false) => async dispatch => dispatch(getAll({ domain: 'turnsgroups', all }));
export const getBoomReasons = (all = false) => async dispatch => dispatch(getAll({ domain: 'boomreasons', all }));
export const getShiftScales = (all = false) => async dispatch => dispatch(getAll({ domain: 'shiftscales', all }));
export const getEquipmentTurnGroups = (all = false) => async dispatch => dispatch(getAll({ domain: 'equipmentturngroups', all }));
export const getHandlingTypes = (all = false) => async dispatch => dispatch(getAll({ domain: 'handlingtypes', all }));
export const getElements = (all = false, filters = {}) => async dispatch => dispatch(getAll({ domain: 'elements', all, filters }));
export const getSubElements = (all = false, filters = {}) => async dispatch => dispatch(getAll({ domain: 'subelements', all, filters }));
export const getSubElementTypes = (all = false) => async dispatch => dispatch(getAll({ domain: 'subelementtypes', all }));
export const getFlowTypes = (all = false) => async dispatch => dispatch(getAll({ domain: 'flowtypes', all }));
export const getMaterialMovements = (all = false) => async dispatch => dispatch(getAll({ domain: 'materialmovements', all }));
export const getMaterialElements = (all = false) => async dispatch => dispatch(getAll({ domain: 'materialelements', all }));
export const getCodes = (all = false) => async dispatch => dispatch(getAll({ domain: 'codes', all }));
export const getCodesMigrationRules = (all = false) => async dispatch => dispatch(getAll({ domain: 'codemigrationrules', all }));
export const getHourMachine = (all = false) => async dispatch => dispatch(getAll({ domain: 'hour_machine', all }));
export const getCodeTypes = (all = false) => async dispatch => dispatch(getAll({ domain: 'codetypes', all }));
export const getCodeGroups = (all = false) => async dispatch => dispatch(getAll({ domain: 'codegroups', all }));
export const getEquipmentTypes = (all = false) => async dispatch => dispatch(getAll({ domain: 'equipmenttypes', all }));
export const getChecklist = (all = false) => async dispatch => dispatch(getAll({ domain: 'checklist', all }));
export const getChecklistMaps = (all = false) => async dispatch => dispatch(getAll({ domain: 'checklistmaps', all }));
export const getDmt = (all = false, filters = {}) => async dispatch => dispatch(getAll({ domain: 'dmt', all, filters }));
export const getTurns = (all = false) => async dispatch => dispatch(getAll({ domain: 'turns', all, filters: { cache: 'false' } }));
export const getTeamGroups = (all = false) => async dispatch => dispatch(getAll({ domain: 'teamgroups', all }));
export const getTeams = (all = false) => async dispatch => dispatch(getAll({ domain: 'team', all }));
export const getFireIds = (all = false) => async dispatch => dispatch(getAll({ domain: 'fireid', all }));
export const getDrillholeTypes = (all = false) => async dispatch => dispatch(getAll({ domain: 'drillholetype', all }));
export const getDrillholeDepths = (all = false) => async dispatch => dispatch(getAll({ domain: 'drillholedepth', all }));
export const getFandrillFans = (all = false) => async dispatch => dispatch(getAll({ domain: 'fandrill_fan', all }));
export const getElementsOd = (all = false) => async dispatch => dispatch(getAll({ domain: 'elementsod', all }));
export const getOperationTypes = (all = false) => async dispatch => dispatch(getAll({ domain: 'operationtype', all }));
export const getTransportOperationTypes = (all = false) => async dispatch => dispatch(getAll({ domain: 'transportoperationtype', all }));
export const getOperationTypeActivities = (all = false) => async dispatch => dispatch(getAll({ domain: 'operationtypeactivity', all }));
export const getTractorProductivities = (all = false) => async dispatch => dispatch(getAll({ domain: 'tractorproductivity', all }));
export const getExceptionTypes = (all = false) => async dispatch => dispatch(getAll({ domain: 'exceptiontype', all }));
export const getSleepLevels = (all = false) => async dispatch => dispatch(getAll({ domain: 'sleeplevel', all }));
export const getFandrillBitDiameters = (all = true) => async dispatch => dispatch(getAll({ domain: 'fandrillbitdiameter', all }));
export const getFandrillBitDiametersNames = (all = true) => async dispatch => dispatch(getAll({ domain: 'fandrillbitdiametername', all }));
export const getHourmeterNames = () => async dispatch => dispatch(getAll({ domain: 'hourmetername' }));
export const getTabletConfiguration = () => async dispatch => dispatch(getAll({ domain: 'tabletconfiguration' }));
export const getPlatformConfiguration = () => async dispatch => dispatch(getAll({ domain: 'platformconfiguration' }));
export const getFlowMovements = () => async dispatch => dispatch(getAll({ domain: 'flowmovements' }));
export const getWebUsers = (all = false) => async dispatch => dispatch(getAll({ domain: 'webusers', all }));
export const getActivityTypesChecklist = (all = false) => getAll({ domain: 'activitytypechecklist', all });
export const getActivityItemChecklist = (all = false) => getAll({ domain: 'activityitemchecklist', all });
export const getOriginTypes = (all = false) => getAll({ domain: 'origintypes', all });
export const getControlTypes = (all = false) => getAll({ domain: 'controltypes', all });
export const getBeaconTypes = () => async dispatch => dispatch(getAll({ domain: 'beacontypes' }));
export const getBeacons = () => async dispatch => dispatch(getAll({ domain: 'beacons' }));

export const get = (endpoint, params) => async (dispatch) => {
  try {
    dispatch(clearFileUploadError());
    const {
      data: {
        result: { [endpoint.responseObject]: data = [] },
      },
    } = await api.post(endpoint.url, params);
    dispatch({
      type: endpoint.typeAction,
      payload: data,
    });
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const getLoads = (
  equipmentType,
  equipmentGroup = null,
  material = null,
) => async (dispatch) => {
  const params = {
    id_mine: null,
    id_material: material,
    id_equip_group: equipmentGroup,
    id_equip_type: equipmentType,
  };

  const endpoint = getEndpoints.loads;
  return dispatch(get(endpoint, params));
};

export const getTeamsListing = (turnGroup, teamGroup, all = false) => async (dispatch) => {
  const endpoint = getEndpoints.teamslisting;
  const params = {
    all,
    id_turn_group: turnGroup,
    id_team_group: teamGroup,
  };
  return dispatch(get(endpoint, params));
};

export const getWeighings = (
  equipmentGroup,
  material = null,
) => async (dispatch) => {
  const endpoint = getEndpoints.weighingspost;
  const params = {
    id_equip_group: equipmentGroup,
    id_material: material,
  };
  return dispatch(get(endpoint, params));
};

export const getTurnsByEquipmentGroup = equipmentGroup => async (dispatch) => {
  const endpoint = getEndpoints.turnspost;
  const params = { id_equipment_group: equipmentGroup };
  return dispatch(get(endpoint, params));
};

export const getCalendarTurnsTeams = (
  turnGroup,
  teamGroup = null,
) => async (dispatch) => {
  const endpoint = getEndpoints.calendarturnsteams;
  const params = {
    id_turn_group: turnGroup,
    id_team_group: teamGroup,
  };
  return dispatch(get(endpoint, params));
};

export const getOriginSubElements = (
  projectGroup = 0,
  element = 0,
  returnElement = false,
  includeInactives = true,
) => async (dispatch) => {
  const params = {
    id_project_group: projectGroup === 0 ? null : projectGroup,
    id_element: element === 0 ? null : element,
    return_element: returnElement,
    include_inactives: includeInactives,
  };
  const endpoint = getEndpoints.originsubelements;

  return dispatch(get(endpoint, params));
};

const setValues = (item, key, value, type) => (dispatch) => {
  let setType = '';
  switch (type) {
    case 'codes_raw_rules_match':
      setType = types.SET_CODES_RAW_RULES_MATCH_VALUES;
      break;
    case 'tabletconfiguration':
      setType = types.SET_TABLET_CONFIGURATION_VALUES;
      break;
    case 'platformconfiguration':
      setType = types.SET_PLATFORM_CONFIGURATION_VALUES;
      break;
    case 'materialelements':
      setType = types.SET_MATERIAL_ELEMENTS_VALUES;
      break;
    case 'handlingtype':
      setType = types.SET_HANDLING_TYPE_VALUES;
      break;
    case 'materialmovement':
      setType = types.SET_MATERIAL_MOVEMENT_VALUES;
      break;
    case 'equipmentturngroup':
      setType = types.SET_EQUIPMENT_TURN_GROUP_VALUES;
      break;
    case 'shiftscale':
      setType = types.SET_SHIFT_SCALE_VALUES;
      break;
    case 'weighing':
      setType = types.SET_WEIGHING_VALUES;
      value = value || 0;
      break;
    case 'turngroup':
      setType = types.SET_TURN_GROUP_VALUES;
      break;
    case 'teamgroups':
      setType = types.SET_TEAM_GROUP_VALUES;
      break;
    case 'team':
      setType = types.SET_TEAM_VALUES;
      break;
    case 'teamslisting':
      setType = types.SET_TEAM_LISTING_VALUES;
      break;
    case 'fireid':
      setType = types.SET_FIRE_ID_VALUES;
      break;
    case 'drillholetype':
      setType = types.SET_DRILLHOLE_TYPE_VALUES;
      break;
    case 'drillholedepth':
      setType = types.SET_DRILLHOLE_DEPTH_VALUES;
      break;
    case 'elementsod':
      setType = types.SET_ELEMENTS_OD_VALUES;
      break;
    case 'elements':
      setType = types.SET_ELEMENT_VALUES;
      break;
    case 'subelements':
      setType = types.SET_SUBELEMENT_VALUES;
      break;
    case 'codes':
      setType = types.SET_CODES_VALUES;
      break;
    case 'operationtype':
      setType = types.SET_OPERATION_TYPE_VALUES;
      break;
    case 'tractorproductivity':
      setType = types.SET_TRACTOR_PRODUCTIVITY_VALUES;
      break;
    case 'transportoperationtype':
      setType = types.SET_TRANSPORT_OPERATION_TYPE_VALUES;
      break;
    case 'sleepLevel':
      setType = types.SET_SLEEP_LEVEL_VALUES;
      break;
    case 'fandrill_fan':
      setType = types.SET_FANDRILL_FAN_VALUES;
      break;
    case 'fandrillbitdiameter':
      setType = types.SET_FANDRILL_BIT_DIAMETER_VALUES;
      break;
    case 'fandrillbitdiametername':
      setType = types.SET_FANDRILL_BIT_DIAMETER_NAME_VALUES;
      break;
    case 'hourmeternames':
      setType = types.SET_HOURMETER_NAMES_VALUES;
      break;
    case 'loadfactor':
      setType = types.SET_LOAD_FACTOR_VALUE;
      break;
    case 'flowmovements':
      setType = types.SET_FLOW_MOVEMENT_VALUES;
      break;
    case 'webusers':
      setType = types.SET_WEB_USERS_VALUES;
      break;
    case 'activitytypechecklist':
      setType = types.SET_ACTIVITY_TYPES_CHECKLIST_VALUES;
      break;
    case 'activityitemchecklist':
      setType = types.SET_ACTIVITY_ITEMS_CHECKLIST_VALUES;
      break;
    case 'boomreasons':
      setType = types.SET_BOOM_REASON_VALUES;
      break;
    case 'codemigrationrules':
      setType = types.SET_MIGRATION_RULES_VALUES;
      break;
    case 'beacons':
      setType = types.SET_BEACONS_VALUES;
      break;
    case 'turn':
    default:
      setType = types.SET_TURN_VALUES;
      break;
  }
  const updateStatus = item.update_status || 'U';
  dispatch({
    type: setType,
    payload: {
      ...item,
      [key]: value,
      update_status: updateStatus,
    },
  });
};

export const getOperatorsTemplate = () => async dispatch => dispatch(getTemplate('operators'));
export const getOperatorsGroupsTemplate = () => async dispatch => dispatch(getTemplate('operatorsgroups'));
export const getEquipmentsTemplate = () => async dispatch => dispatch(getTemplate('equipments'));
export const getEquipmentsGroupsTemplate = () => async dispatch => dispatch(getTemplate('equipmentsgroups'));
export const getMaterialsTemplate = () => async dispatch => dispatch(getTemplate('materials'));
export const getMaterialsGroupsTemplate = () => async dispatch => dispatch(getTemplate('materialsgroups'));
export const getElementsTemplate = () => async dispatch => dispatch(getTemplate('elements'));
export const getSubElementsTemplate = () => async dispatch => dispatch(getTemplate('subelements'));
export const getCodesTemplate = () => async dispatch => dispatch(getTemplate('codes'));
export const getLoadsTemplate = () => async dispatch => dispatch(getTemplate('loads'));
export const getChecklistTemplate = () => async dispatch => dispatch(getTemplate('checklist'));
export const getWeighingsTemplate = () => async dispatch => dispatch(getTemplate('weighings'));
export const getDmtTemplate = () => async dispatch => dispatch(getTemplate('dmt'));
export const getActivityItemChecklistTemplate = () => getTemplate('activityitemchecklist');

export const exportOperators = () => async dispatch => dispatch(exportData('operators'));
export const exportOperatorsGroups = () => async dispatch => dispatch(exportData('operatorsgroups'));
export const exportEquipments = () => async dispatch => dispatch(exportData('equipments'));
export const exportEquipmentsGroups = () => async dispatch => dispatch(exportData('equipmentsgroups'));
export const exportMaterials = () => async dispatch => dispatch(exportData('materials'));
export const exportMaterialsGroups = () => async dispatch => dispatch(exportData('materialsgroups'));
export const exportElements = filters => async dispatch => dispatch(exportData('elements', filters));
export const exportSubElements = filters => async dispatch => dispatch(exportData('subelements', filters));
export const exportCodes = (filters, admin) => async dispatch => dispatch(exportData('codes', {}, admin));
export const exportLoads = ({ equipmentType, equipmentGroup, material }) => exportData(
  'loads', { id_equip_type: equipmentType, id_equip_group: equipmentGroup, id_material: material },
);
export const exportChecklist = () => async dispatch => dispatch(exportData('checklist'));
export const exportWeighings = () => async dispatch => dispatch(exportData('weighings'));
export const exportDmt = () => async dispatch => dispatch(exportData('dmt'));

export const importOperators = file => async dispatch => dispatch(importData('operators', file, getAll));
export const importOperatorsGroups = file => async dispatch => dispatch(importData('operatorsgroups', file, getAll));
export const importEquipments = file => async dispatch => dispatch(importData('equipments', file, getAll));
export const importEquipmentsGroups = file => async dispatch => dispatch(importData('equipmentsgroups', file, getAll));
export const importMaterials = file => async dispatch => dispatch(importData('materials', file, getAll));
export const importMaterialsGroups = file => async dispatch => dispatch(importData('materialsgroups', file, getAll));
export const importElements = (file, filters = {}) => async dispatch => dispatch(importData('elements', file, getAll, filters));
export const importSubElements = (file, filters = {}) => async dispatch => dispatch(importData('subelements', file, getAll, filters));
export const importCodes = file => async dispatch => dispatch(importData('codes', file, getAll));
export const importChecklist = file => async dispatch => dispatch(importData('checklist', file, getAll));
export const importWeighings = file => async dispatch => dispatch(importData('weighings', file, getAll));
export const importLoads = file => async dispatch => dispatch(importData('loads', file));
export const importDmt = file => async dispatch => dispatch(importData('dmt', file));
export const importActivityItemChecklist = file => importData('activityitemchecklist', file, getAll);

export const setTurnGroupValue = (item, key, value) => async dispatch => dispatch(setValues(item, key, value, 'turngroup'));
export const setBoomReasonValue = (item, key, value) => async dispatch => dispatch(setValues(item, key, value, 'boomreasons'));

export const setCodesValues = (item, key, value) => async dispatch => dispatch(setValues(item, key, value, 'codes'));
export const setCodeMigrationRulesValues = (item, key, value) => async dispatch => dispatch(setValues(item, key, value, 'codemigrationrules'));
export const setCodeMigrationRulesMatchValues = (item, key, value) => async dispatch => dispatch(setValues(item, key, value, 'codes_raw_rules_match'));

export const setBeaconsValues = (item, key, value) => async dispatch => dispatch(setValues(item, key, value, 'beacons'));
export const updateBeacons = data => async dispatch => dispatch(updateData('beacons', data));

export const updateTurnsGroups = data => async dispatch => dispatch(updateData('turnsgroups', data, true, true));
export const updateBoomReason = data => async dispatch => dispatch(updateData('boomreasons', data, true, true));
export const updateCodeMigrationRules = data => async dispatch => dispatch(updateData('codemigrationrules', data, true, true));

export const changeAllCodeMigrationRulesMatchAnalysis = (value, data) => async (dispatch) => {
  const newData = data.map(d => ({
    ...d,
    update_status: 'U',
    analisys_status: value,
    start_timestamp: d.start_timestamp / 1000,
    old_end_timestamp: d.old_end_timestamp / 1000,
    time_to_new_code: d.time_to_new_code / 1000,
  }));
  dispatch({
    type: types.SET_CODES_RAW_RULES_MATCH,
    payload: newData,
  });
};

export const updateCodes = data => async dispatch => dispatch(updateData('codes', data.map(d => ({
  ...d,
  is_logout: d.is_logout * 1,
  is_visible: d.is_visible * 1,
  hour_machine: d.hour_machine * 1,
}))));

export const addTurnsGroup = data => async dispatch => dispatch({
  type: types.ADD_TURN_GROUP,
  payload: data,
});

export const addBoomReason = data => async dispatch => dispatch({
  type: types.ADD_BOOM_REASON,
  payload: data,
});

export const resetTurnsGroups = () => async dispatch => dispatch({
  type: types.RESET_TURNS_GROUPS,
});

export const resetBoomReasons = () => async dispatch => dispatch({
  type: types.RESET_BOOM_REASONS,
});

export const resetCodeMigrationRules = () => async dispatch => dispatch({
  type: types.RESET_MIGRATION_RULES,
});

export const resetCodes = () => async dispatch => dispatch({
  type: types.RESET_CODES,
});

export const resetBeacons = () => async dispatch => dispatch({
  type: types.RESET_BEACONS,
});

export const addCode = data => async dispatch => dispatch({
  type: types.ADD_CODE,
  payload: data,
});

export const addCodeMigrationRules = data => async dispatch => dispatch(
  {
    type: types.ADD_MIGRATION_RULE,
    payload: data,
  },
);

export const addBeacon = data => async dispatch => dispatch({
  type: types.ADD_BEACON,
  payload: data,
});

export const resetActivitTypesChecklist = () => ({ type: types.RESET_ACTIVITY_TYPES_CHECKLIST });
export const resetActivitItemsChecklist = () => ({ type: types.RESET_ACTIVITY_ITEMS_CHECKLIST });
export const setActivityTypesChecklist = (item, key, value) => (
  setValues(item, key, value, 'activitytypechecklist')
);
export const setActivityItemsChecklist = (item, key, value) => (
  setValues(item, key, value, 'activityitemchecklist')
);
export const addActivityTypeChecklist = values => (
  { type: types.ADD_ACTIVITY_TYPES_CHECKLIST, payload: values }
);
export const updateActivityTypeChecklist = () => (dispatch, getState) => {
  const state = getState();
  dispatch(updateData('activitytypechecklist', state.manager.activityTypesChecklist, true, true));
};
export const addActivityItemsChecklist = values => (
  { type: types.ADD_ACTIVITY_ITEMS_CHECKLIST, payload: values }
);
export const updateActivityItemsChecklist = () => (dispatch, getState) => {
  const state = getState();
  dispatch(updateData('activityitemchecklist', state.manager.activityItemsChecklist, true, true));
};
export const setShiftScaleValues = (item, key, value) => async dispatch => dispatch(setValues(item, key, value, 'shiftscale'));
export const updateShiftScales = data => async dispatch => dispatch(updateData('shiftscales', data));

export const disableLoadingMigrationCode = desiredState => ({
  type: types.TOGGLE_LOADING_MIGRATION_CODE_MATCH,
  payload: { state: desiredState },
});

export const updateCodeMigrationRulesDailyParts = data => async (dispatch) => {
  dispatch(disableLoadingMigrationCode(true));
  const endpoint = getEndpoints.codes_raw_rules_match;
  const body = { [endpoint.responseObject]: data };
  await api.post(`${endpoint.url}`, body)
    .catch(err => dispatch(handleErrors(err.response)))
    .finally(() => dispatch(disableLoadingMigrationCode(false)));
  dispatch(getCodesRawRulesMatch());
};

export const addShiftScale = data => async dispatch => dispatch({
  type: types.ADD_SHIFT_SCALE,
  payload: data,
});

export const resetShiftScales = () => async dispatch => dispatch({
  type: types.RESET_SHIFT_SCALES,
});

export const setEquipmentTurnGroupValues = (
  item,
  key,
  value,
) => async dispatch => dispatch(setValues(item, key, value, 'equipmentturngroup'));

export const updateEquipmentTurnGroups = data => async dispatch => dispatch(updateData('equipmentturngroups', data));

export const addEquipmentTurnGroup = data => async dispatch => dispatch({
  type: types.ADD_EQUIPMENT_TURN_GROUP,
  payload: data,
});

export const resetEquipmentTurnGroups = () => async dispatch => dispatch({
  type: types.RESET_EQUIPMENT_TURN_GROUPS,
});

export const setHandlingTypeValues = (item, key, value) => async dispatch => dispatch(setValues(item, key, value, 'handlingtype'));

export const updateHandlingTypes = data => async dispatch => dispatch(updateData('handlingtypes', data));

export const addHandlingType = data => async dispatch => dispatch({
  type: types.ADD_HANDLING_TYPE,
  payload: data,
});

export const resetHandlingTypes = () => async dispatch => dispatch({
  type: types.RESET_HANDLING_TYPES,
});

export const updateTabletConfigurations = data => async dispatch => dispatch(updateData('tabletconfiguration', data));
export const setTabletConfigurationsValues = (item, key, value) => async dispatch => dispatch(setValues(item, key, value, 'tabletconfiguration'));
export const resetTabletConfigurations = () => async dispatch => dispatch({
  type: types.RESET_TABLET_CONFIGURATIONS,
});

export const updatePlatformConfigurations = data => async dispatch => dispatch(updateData('platformconfiguration', data));
export const setPlatformConfigurationsValues = (item, key, value) => async dispatch => dispatch(setValues(item, key, value, 'platformconfiguration'));
export const resetPlatformConfigurations = () => async dispatch => dispatch({
  type: types.RESET_PLATFORM_CONFIGURATIONS,
});

export const setMaterialMovementValues = (item, key, value) => async dispatch => dispatch(setValues(item, key, value, 'materialmovement'));
export const updateMaterialMovements = data => async dispatch => dispatch(updateData('materialmovements', data));

export const addMaterialMovement = data => async dispatch => dispatch({
  type: types.ADD_MATERIAL_MOVEMENT,
  payload: data,
});

export const updateFlowMovements = data => async dispatch => dispatch(updateData('flowmovements', data));
export const setFlowMovementValues = (item, key, value) => async dispatch => dispatch(setValues(item, key, value, 'flowmovements'));
export const addFlowMovement = data => async dispatch => dispatch({
  type: types.ADD_FLOW_MOVEMENT,
  payload: data,
});

export const resetFlowMovements = () => async dispatch => dispatch({
  type: types.RESET_FLOW_MOVEMENTS,
});

export const resetMaterialMovement = () => async dispatch => dispatch({
  type: types.RESET_MATERIAL_MOVEMENTS,
});

export const resetMaterialElements = () => async dispatch => dispatch({
  type: types.RESET_MATERIAL_ELEMENTS,
});

export const setMaterialElementsValues = (
  materialId,
  key,
  value,
) => async dispatch => dispatch({
  type: types.SET_MATERIAL_ELEMENTS_VALUES,
  payload: {
    materialId,
    [key]: value,
  },
});

export const updateMaterialElements = data => async dispatch => dispatch(updateData('materialelements', data));
export const setAlertDialog = () => async dispatch => dispatch({
  type: types.SET_ALERT_DIALOG,
});

export const setWeighingValue = (item, key, value) => async dispatch => dispatch(setValues(item, key, value, 'weighing'));
export const updateWeighings = data => async (dispatch) => {
  const items = data
    .filter(d => d.update_status === 'U')
    .map((d) => {
      const shiftScales = Object.keys(d)
        .filter(s => s.includes('shiftScale'))
        .map((s) => {
          const item = {
            id: Number(s.split('_')[1]),
            value: Number(d[s]),
          };
          delete d[s];
          return item;
        });
      d.shift_scales = shiftScales;
      return d;
    });
  return dispatch(updateData('weighings', items));
};

export const resetWeighings = () => async dispatch => dispatch({
  type: types.RESET_WEIGHINGS_LIST,
});

export const resetChecklistMaps = () => async dispatch => dispatch({
  type: types.RESET_CHECKLIST_MAPS,
});

export const setChecklistMapsValues = (
  equipmentType,
  equipmentGroup,
  key,
  value,
) => async (dispatch) => {
  dispatch({
    type: types.SET_CHECKLIST_MAP_VALUES,
    payload: {
      equipmentType,
      equipmentGroup,
      [key]: value,
    },
  });
};

export const updateChecklistMaps = data => async dispatch => dispatch(updateData('checklistmaps', data));

export const syncData = () => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/syncdata`;
    await api.get(url);
    dispatch({
      type: SET_UPLOAD_SUCCESS,
      payload: i18n.t('validation:UpdateSentSuccessfully'),
    });
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const setTurnValue = (item, key, value) => async dispatch => dispatch(setValues(item, key, value, 'turn'));
export const updateTurns = data => async dispatch => dispatch(updateData('turns', data, true, true, { cache: false }));
export const addTurn = data => async dispatch => dispatch({
  type: types.ADD_TURN,
  payload: data,
});

export const resetTurns = () => async dispatch => dispatch({
  type: types.RESET_TURNS,
});

export const addTeamGroup = data => async dispatch => dispatch({
  type: types.ADD_TEAM_GROUP,
  payload: data,
});
export const resetTeamGroups = () => async dispatch => dispatch({
  type: types.RESET_TEAM_GROUPS,
});
export const setTeamGroupValue = (item, key, value) => async dispatch => dispatch(setValues(item, key, value, 'teamgroups'));
export const updateTeamGroups = data => async dispatch => dispatch(updateData('teamgroups', data, true, true));

export const addTeam = data => async dispatch => dispatch({
  type: types.ADD_TEAM,
  payload: data,
});
export const resetTeams = () => async dispatch => dispatch({
  type: types.RESET_TEAMS,
});

export const setTeamValue = (item, key, value) => async dispatch => dispatch(setValues(item, key, value, 'team'));
export const updateTeams = data => async dispatch => dispatch(updateData('team', data, true, true));

export const addTeamListing = data => async dispatch => dispatch({
  type: types.ADD_TEAM_LISTING,
  payload: data,
});
export const resetTeamsListing = () => async dispatch => dispatch({
  type: types.RESET_TEAMS_LISTING,
});

export const setTeamListingValue = (
  item,
  key,
  value,
  turnGroup,
  teamGroup,
) => async (dispatch) => {
  const updateStatus = item.update_status === 'I' ? 'I' : 'U';
  dispatch({
    type: types.SET_TEAM_LISTING_VALUES,
    payload: {
      ...item,
      [key]: value,
      update_status: updateStatus,
      turnGroup,
      teamGroup,
    },
  });
};

const createTeamListing = (item, idTeamListing = 0) => Object.keys(item)
  .filter(i => i.startsWith('turn_'))
  .map((k) => {
    const listingItem = {
      id_team_listing: idTeamListing,
      id_turn: Number(k.split('_')[1]),
      id_team: item[k],
      is_free_turn: Number(k.split('_')[1]) === 0,
    };
    if (item[`listing_${k.split('_')[1]}`]) {
      listingItem.id = Number(item[`listing_${k.split('_')[1]}`]);
    }
    return listingItem;
  });

export const updateTeamsListing = (
  data,
  turnGroup,
  teamGroup,
) => async (dispatch) => {
  const newData = data.map((d) => {
    const {
      id,
      active,
      isDirty,
      day_number: dayNumber,
      id_turn_group: idTurnGroup,
      id_team_group: idTeamGroup,
      update_status: updateStatus,
    } = d;
    return {
      id,
      active,
      isDirty,
      id_turn_group: idTurnGroup,
      id_team_group: idTeamGroup,
      day_number: dayNumber,
      update_status: updateStatus,
      listing: createTeamListing(d, id),
    };
  });
  await dispatch(updateData('teamslistingpost', newData, false));
  dispatch(getTeamsListing(turnGroup, teamGroup));
};

export const removeTeamListing = data => async (dispatch) => {
  dispatch({
    type: types.REMOVE_TEAM_LISTING_DAY,
    payload: data,
  });
};

export const addFireId = data => async dispatch => dispatch({
  type: types.ADD_FIRE_ID,
  payload: data,
});

export const resetFireIds = () => async dispatch => dispatch({
  type: types.RESET_FIRE_IDS,
});

export const setFireIdValue = (item, key, value) => async dispatch => dispatch(setValues(item, key, value, 'fireid'));
export const updateFireIds = data => async dispatch => dispatch(updateData('fireid', data));

export const addDrillholeType = data => async dispatch => dispatch({
  type: types.ADD_DRILLHOLE_TYPE,
  payload: data,
});

export const resetDrillholeTypes = () => async dispatch => dispatch({
  type: types.RESET_DRILLHOLE_TYPES,
});

export const setDrillholeTypeValue = (item, key, value) => async dispatch => dispatch(setValues(item, key, value, 'drillholetype'));
export const updateDrillholeTypes = data => async (dispatch) => {
  dispatch(updateData('drillholetype', data));
};

export const addFandrillFan = data => async dispatch => dispatch({
  type: types.ADD_FANDRILL_FAN,
  payload: data,
});

export const resetFandrillFans = () => async dispatch => dispatch({
  type: types.RESET_FANDRILL_FANS,
});

export const setFandrillFanValue = (item, key, value) => async dispatch => dispatch(setValues(item, key, value, 'fandrill_fan'));
export const updateFandrillFans = data => async (dispatch) => {
  dispatch(updateData('fandrill_fan', data));
};

export const addHourmeterNames = data => async dispatch => dispatch({
  type: types.ADD_HOURMETER_NAME,
  payload: data,
});

export const setHourmeterNamesValue = (item, key, value) => async dispatch => dispatch(setValues(item, key, value, 'hourmeternames'));

export const updateHourmeterNames = data => async (dispatch) => {
  const newData = [{
    ...data,
    update_status: data.id > 0 ? 'U' : 'I',
  }];
  dispatch(updateData('hourmetername', newData));
};

export const resetHourmeterNames = () => async dispatch => dispatch({
  type: types.RESET_HOURMETER_NAMES,
});

export const resetFandrillBitDiameters = () => async dispatch => dispatch({
  type: types.RESET_FANDRILL_BIT_DIAMETERS,
});

export const setFandrillBitDiameterValue = (item, key, value) => async dispatch => dispatch(setValues(item, key, value, 'fandrillbitdiameter'));

export const updateFandrillBitDiameters = (data, all = false) => async (dispatch) => {
  const newData = data.map((d) => {
    const { diameter, id } = d;
    const newObj = {
      ...d,
      diameter: parseFloat(diameter),
    };
    if (newObj.update_status && newObj.update_status !== 'I') newObj.id = id;
    return newObj;
  });
  dispatch(updateData('fandrillbitdiameter', newData, true, all));
};

export const addFandrillBitDiameter = data => async dispatch => dispatch({
  type: types.ADD_FANDRILL_BIT_DIAMETER,
  payload: data,
});

export const addFandrillBitDiameterName = data => async dispatch => dispatch({
  type: types.ADD_FANDRILL_BIT_DIAMETER_NAME,
  payload: data,
});

export const resetFandrillBitDiametersNames = () => async dispatch => dispatch({
  type: types.RESET_FANDRILL_BIT_DIAMETERS_NAMES,
});

export const setFandrillBitDiametersNamesValue = (item, key, value) => async (dispatch) => {
  dispatch(setValues(item, key, value, 'fandrillbitdiametername'));
};
export const updateFandrillBitDiametersNames = (data, all = false) => async (dispatch) => {
  dispatch(updateData('fandrillbitdiametername', data, true, all));
};

export const resetDrillholeDepths = () => async dispatch => dispatch({
  type: types.RESET_DRILLHOLE_DEPTHS,
});

export const setDrillholeDepthValue = (item, key, value) => async dispatch => dispatch(setValues(item, key, value, 'drillholedepth'));
export const updateDrillholeDepths = data => async (dispatch) => {
  const newData = data.map((d) => {
    const { depth, id } = d;
    const newObj = {
      ...d,
      depth: parseFloat(depth),
    };
    if (newObj.update_status && newObj.update_status !== 'I') newObj.id = id;
    return newObj;
  });
  dispatch(updateData('drillholedepth', newData));
};

export const addDrillholeDepth = data => async dispatch => dispatch({
  type: types.ADD_DRILLHOLE_DEPTH,
  payload: data,
});

export const updateCalendarTurnsTeams = data => async (dispatch) => {
  data.forEach((d) => {
    d.start_date = formatDate(d.start_date, true);
    d.end_date = formatDate(d.end_date, true);
  });
  dispatch(updateData('calendarturnsteamspost', data, false));
};

export const resetCalendarTurnsTeams = () => async dispatch => dispatch({
  type: types.RESET_CALENDAR_TURNS_TEAMS,
});

export const setCalendarTurnsTeams = values => async dispatch => dispatch({
  type: types.SET_CALENDAR_TURN_TEAM,
  payload: values,
});

export const updateDaysOff = values => async (dispatch) => {
  dispatch({
    type: types.SET_DAYS_OFF,
    payload: values,
  });
};
export const resetElementsOd = () => async dispatch => dispatch({ type: types.RESET_ELEMENTS_OD });

export const setElementsOdValues = (id, key, value) => ({
  type: types.SET_ELEMENTS_OD_VALUES,
  payload: {
    id,
    [key]: value,
  },
});

export const addOperationType = data => async dispatch => dispatch({
  type: types.ADD_OPERATION_TYPE,
  payload: data,
});

export const resetOperationTypes = () => async dispatch => dispatch({
  type: types.RESET_OPERATION_TYPES,
});

export const setOperationTypeValue = (item, key, value) => async dispatch => dispatch(setValues(item, key, value, 'operationtype'));
export const updateOperationTypes = (data, { refreshData = true, all = false }) => (
  updateData('operationtype', data, refreshData, all)
);

export const addTransportOperationType = data => async dispatch => dispatch({
  type: types.ADD_TRANSPORT_OPERATION_TYPE,
  payload: data,
});

export const resetTransportOperationTypes = () => async dispatch => dispatch({
  type: types.RESET_TRANSPORT_OPERATION_TYPES,
});

export const setTransportOperationTypeValue = (item, key, value) => async dispatch => dispatch(
  setValues(item, key, value, 'transportoperationtype'),
);

export const updateTransportOperationTypes = (data, { refreshData = true, all = false }) => (
  updateData('transportoperationtype', data, refreshData, all)
);

export const addTractorProductivity = data => async dispatch => dispatch({
  type: types.ADD_TRACTOR_PRODUCTIVITY,
  payload: data,
});

export const resetTractorProductivities = () => async dispatch => dispatch({
  type: types.RESET_TRACTOR_PRODUCTIVITIES,
});

export const setTractorProductivityValues = (
  item,
  key,
  value,
) => async dispatch => dispatch(setValues(item, key, value, 'tractorproductivity'));

export const updateTractorProductivities = data => async (dispatch) => {
  const newData = data.map(d => ({
    ...d,
    value: parseFloat(d.value),
  }));
  dispatch(updateData('tractorproductivity', newData));
};

export const updateElementsOd = data => async dispatch => dispatch(updateData('elementsod', data));

export const setElementValue = (item, key, value) => async dispatch => dispatch(setValues(item, key, value, 'elements'));

export const updateElements = data => async (dispatch) => {
  const newData = data.map(
    ({
      id, name, active, update_status: updateStatus,
    }) => ({
      id,
      name,
      active,
      update_status: updateStatus,
    }),
  );
  return dispatch(updateData('elements', newData));
};

export const resetElements = () => async dispatch => dispatch({
  type: types.RESET_ELEMENTS,
});

export const setSubElementValue = (item, key, value) => async dispatch => dispatch(setValues(item, key, value, 'subelements'));

export const updateSubElements = (data, filters) => async (dispatch) => {
  const newData = data.map(
    ({
      id, name, active, status, sub_element_type_id: subElementTypeId, update_status: updateStatus,
    }) => ({
      id,
      name,
      active,
      status,
      sub_element_type_id: subElementTypeId,
      update_status: updateStatus,
    }),
  );
  dispatch(updateData('subelements', newData, true, false, filters));
};

export const resetSubElements = () => async dispatch => dispatch({
  type: types.RESET_SUBELEMENTS,
});

export const getLoadFactors = (all = false) => async dispatch => dispatch(getAll({ domain: 'loadfactor', all }));
export const setLoadFactorValues = (item, key, value) => dispatch => dispatch(setValues(item, key, value, 'loadfactor'));
export const resetLoadFactors = () => async (dispatch) => {
  dispatch({ type: types.RESET_LOAD_FACTOR });
};

export const updateLoadFactors = data => async (dispatch) => {
  const newData = data.map((d) => {
    d.value = parseFloat(d.value);
    return d;
  });
  dispatch(updateData('loadfactor', newData));
};

export const addLoadFactor = data => async dispatch => dispatch({
  type: types.ADD_LOAD_FACTOR, payload: data,
});

export const setWebUsersValues = (item, key, value) => dispatch => dispatch(setValues(item, key, value, 'webusers'));
export const resetWebUsers = () => async (dispatch) => {
  dispatch({ type: types.RESET_WEB_USERS });
};
export const updateWebUsers = data => async (dispatch) => {
  dispatch(updateData('webusers', data));
};

export const addWebUser = data => async dispatch => dispatch({
  type: types.ADD_WEB_USERS, payload: { ...data, update_status: 'I', isDirty: true },
});

export const resetWebUserPassword = user => async (dispatch) => {
  try {
    const endpoint = '/webusers/reset_password';
    const endpointUrl = `${endpoint}?user_id=${user.id}`;
    const {
      data: {
        result,
      },
    } = await api.get(endpointUrl);
    dispatch({
      type: types.RESET_WEB_USERS_PASSWORD,
      payload: { new_password: result.new_password, ...user },
    });
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const setFileUploadErrors = data => async dispatch => dispatch({
  type: types.SET_FILE_UPLOAD_ERRORS, payload: { data },
});

export const resetFileUploadErrors = () => async dispatch => dispatch({
  type: types.RESET_FILE_UPLOAD_ERRORS,
});

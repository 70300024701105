import React from 'react';
import { useTranslation } from 'react-i18next';

const DispatchLabels = () => {
  const { t: translate } = useTranslation();

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <div style={{
          backgroundColor: 'gray', height: '10px', width: '10px', margin: '5px',
        }}
        />
        <span>{translate('common:Empty')}</span>
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{
          backgroundColor: 'black', height: '10px', width: '10px', margin: '5px',
        }}
        />
        <span>{translate('common:Full')}</span>
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{
          backgroundColor: 'brown', height: '10px', width: '10px', margin: '5px',
        }}
        />
        <span>{translate('common:Queue')}</span>
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{
          backgroundColor: 'blue', height: '10px', width: '10px', margin: '5px',
        }}
        />
        <span>{translate('common:Maneuver')}</span>
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{
          backgroundColor: 'lime', height: '10px', width: '10px', margin: '5px',
        }}
        />
        <span>{translate('common:Load')}</span>
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{
          backgroundColor: 'green', height: '10px', width: '10px', margin: '5px',
        }}
        />
        <span>{translate('common:Dump')}</span>
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{
          backgroundColor: 'yellow', height: '10px', width: '10px', margin: '5px',
        }}
        />
        <span>{translate('common:Stop')}</span>
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{
          backgroundColor: 'red', height: '10px', width: '10px', margin: '5px',
        }}
        />
        <span>{translate('common:Maintenance')}</span>
      </div>
    </div>
  );
};
export default (DispatchLabels);

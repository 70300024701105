import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styles from './styles';
import OriginsTab from './OriginsTab';
import DestinationsTab from './DestinationsTab';
import PF2MTabs from '~/components/PF2MTabs';
import PF2MCustomDragLayer from '~/components/PF2MCustomDragLayer';
import AnomalyLogsTab from './AnomalyLogsTab';
import ChatTab from './ChatTab';

const DispatchControl = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { t: translate } = useTranslation();

  const chatList = useSelector(state => (state.chat?.chatList));
  const chatStatus = useSelector(
    state => state.manager.platformConfiguration.reduce((acc, e) => (e.key === 'enable_chat' ? e : acc), { value: '0' }),
  );

  const tabs = [
    { key: 0, label: translate('common:Origins') },
    { key: 1, label: translate('common:Destinations') },
    { key: 2, label: translate('common:AnomalyLogs') },
  ];
  if (chatStatus.value === '1') {
    tabs.push({ key: 3, label: translate('common:ChatBeta') });
  }
  const handleChange = (event, value) => setSelectedTab(value);

  return (
    <>
      <PF2MTabs
        value={selectedTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        chatIndex={3}
        readStatus={
          chatList ? Object
            .values(chatList)
            .reduce((acc, e) => (e.isReaded ? acc : false), true) : true
        }
        hideSyncButton
      >
        {tabs}
      </PF2MTabs>

      {selectedTab === 0 ? (
        <OriginsTab />
      ) : null}
      {selectedTab === 1 ? (
        <DestinationsTab />
      ) : null}

      <PF2MCustomDragLayer />
      {selectedTab === 2 ? (
        <AnomalyLogsTab />
      ) : null}
      {selectedTab === 3 ? (
        <ChatTab />
      ) : null}
    </>
  );
};

export default withStyles(styles)(DispatchControl);

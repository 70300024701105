import { managerGridHeight } from '~/utils';

const styles = {
  container: {
    display: 'flex',
    backgroundColor: '#F9F9F9',
    height: managerGridHeight,
    padding: 10,
  },
  icon: {
    marginRight: 10,
  },
  button: {
    color: '#FFF',
    margin: 5,
  },
  tabContainer: {
    borderRadius: 5,
    borderTopLeftRadius: 0,
    backgroundColor: 'white',
    padding: 10,
  },
};

export default styles;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Fab from '@material-ui/core/Fab';
import {
  Settings, Layers, ControlCamera, FormatShapes,
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core';
import TerrainSettingsMenu from '../TerrainSettingsMenu';
import styles from './styles';

const Toolbar = ({
  classes,
  terrainModelLayers,
  terrainModelUploads,
  defaultModel,
  polygonList,
  handleLayerVisibility,
  handlePolygonVisibility,
  resetDefaultModel,
  handleCompareModel,
  setCameraControl,
}) => {
  const [menuOpen, setMenuOpen] = useState(0);

  const handleMenuOpen = (index) => {
    if (menuOpen === index) {
      setMenuOpen(0);
    } else {
      setMenuOpen(index);
    }
  };

  const ToolbarButton = ({ icon, index }) => (
    <Fab
      className={classes.toolbarButton}
      color="primary"
      onClick={() => handleMenuOpen(index)}
      size="small"
    >
      {icon}
    </Fab>
  );
  ToolbarButton.propTypes = {
    icon: PropTypes.element.isRequired,
    index: PropTypes.number.isRequired,
  };


  return (
    <>
      <TerrainSettingsMenu
        visible={menuOpen !== 0}
        menuIndex={menuOpen}
        style={{ top: `${(menuOpen - 1) * 70}px` }}
        layerList={terrainModelLayers}
        modelList={
                terrainModelUploads
                  .filter(m => m.terrain_model_id === defaultModel.id
                && m.id !== defaultModel.terrain_model_upload_id)
            }
        polygonList={polygonList}
        showLayer={handleLayerVisibility}
        showPolygon={handlePolygonVisibility}
        compareModel={handleCompareModel}
        resetDefaultModel={resetDefaultModel}
        setCameraControl={setCameraControl}
      />
      <ToolbarButton
        icon={<Layers />}
        index={1}
      />
      <ToolbarButton
        icon={<Settings />}
        index={2}
      />
      <ToolbarButton
        icon={<ControlCamera />}
        index={3}
      />
      <ToolbarButton
        icon={<FormatShapes />}
        index={4}
      />

    </>
  );
};

Toolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  terrainModelLayers: PropTypes.array,
  terrainModelUploads: PropTypes.array,
  defaultModel: PropTypes.object,
  polygonList: PropTypes.array,
  handleLayerVisibility: PropTypes.func,
  handlePolygonVisibility: PropTypes.func,
  resetDefaultModel: PropTypes.func,
  handleCompareModel: PropTypes.func,
  setCameraControl: PropTypes.func,
};

Toolbar.defaultProps = {
  terrainModelLayers: [],
  terrainModelUploads: [],
  defaultModel: {},
  polygonList: [],
  handleLayerVisibility: () => {},
  handlePolygonVisibility: () => {},
  resetDefaultModel: () => {},
  handleCompareModel: () => {},
  setCameraControl: () => {},
};

export default withStyles(styles)(Toolbar);

import React, { useRef } from 'react';

function useUploadFile({ onFileChange }) {
  const ref = useRef();

  const onSelectFile = (event) => {
    const file = event.target.files[0];
    if (file) {
      onFileChange(file);
      ref.current.value = null;
    }
    return file;
  };

  const input = (
    <input
      ref={ref}
      type="file"
      style={{ display: 'none' }}
      onChange={onSelectFile}
    />
  );
  const choseFile = () => ref.current.click();
  return [input, choseFile];
}

export default useUploadFile;

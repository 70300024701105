import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import PropTypes from 'prop-types';

export const CustomOutlinedInput = withStyles({
  root: {
    borderColor: '#CDCDCD',
  },
})(OutlinedInput);

const PF2MOutlinedInput = ({ value, ...props }) => {
  const [text, setText] = useState(value);

  useEffect(() => {
    setText(value);
  }, [value]);

  return (
    <CustomOutlinedInput
      value={text}
      fullWidth
      labelWidth={0}
      onChange={e => setText(e.target.value)}
      {...props}
    />
  );
};

PF2MOutlinedInput.propTypes = {
  value: PropTypes.any,
};

PF2MOutlinedInput.defaultProps = {
  value: '',
};

export default PF2MOutlinedInput;

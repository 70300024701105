import { managerGridHeight } from '~/utils';

const styles = {
  container: {
    display: 'flex',
    backgroundColor: '#F9F9F9',
    height: managerGridHeight,
    padding: 10,
  },
  icon: {
    marginRight: 10,
  },
  button: {
    marginTop: 20,
  },
  tabContainer: {
    borderRadius: 5,
    borderTopLeftRadius: 0,
    backgroundColor: 'white',
    padding: 10,
    height: 'calc(100vh - 118px)',
    display: 'flex',
    flexFlow: 'column',
  },
  grid: {
    width: '100%',
    overflowY: 'auto',
    height: 'calc(100vh - 52px - 52px - 180px - 67px)',
  },
  containerTypeSelector: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'center',
    width: '100%',
    padding: '10px 0px',
  },
  filterMenu: {
    height: 65,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'flex-start',
    margin: 15,
  },
  child: {
    margin: '0 !important',
    paddingLeft: 55,
    backgroundColor: 'white',
    boxShadow: 'none',
  },
  labelTypeSelector: {
    color: '#647886',
    fontFamily: 'Barlow Condensed',
    fontWeight: 600,
    fontSize: 18,
    textTransform: 'uppercase',
    marginRight: 10,
  },
  typeSelector: {
    height: 40,
    fontFamily: 'Roboto',
    fontWeight: 300,
    fontSize: 14,
    color: '#CDCDCD',
    padding: '0px 10px',
    zIndex: 12,
  },
  invalidCell: {
    backgroundColor: '#FFCDD2',
    borderColor: '#ffa7b0',
    padding: 5,
    display: 'flex',
    justifyContent: 'space-between',
  },
  validCell: {
    cursor: 'grab',
    padding: 5,
    display: 'flex',
    justifyContent: 'space-between',
  },
  typeSelectorInput: {
    minWidth: 200,
    maxWidth: 200,
  },
  transition: {
    transition: 'all 0.3s ease-in-out',
  },
  goalCard: {
    width: 400,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    transition: 'all 0.3s ease-in-out',
    position: 'absolute',
    height: '100%',
    zIndex: 11,
  },
  goalSection: {
    width: '47%',
    padding: 15,
    display: 'flex',
    flexFlow: 'column',
    gap: 10,
  },
};

export default styles;

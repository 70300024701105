import PropTypes from 'prop-types';

const ProcessedTime = ({ timestamp = null }) => {
  if (!timestamp) return null;

  const processedTime = new Date(timestamp * 1000);
  return processedTime.toLocaleString();
};

ProcessedTime.propTypes = {
  timestamp: PropTypes.number,
};

ProcessedTime.defaultProps = {
  timestamp: null,
};

export default ProcessedTime;

import React from 'react';
import { useSelector } from 'react-redux';
import {
  withStyles,
  FormControl,
  FormLabel,
  FormGroup,
} from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import PF2MButton from '~/components/PF2MButton';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';

function Header({
  classes,
  add,
  materialsGroups,
  handlingTypes,
  subElementTypes,
}) {
  const { t: translate } = useTranslation();
  const flowMovements = useSelector(state => state.manager.flowMovements);

  const INITIAL_VALUES = {
    material_groups_id: 0,
    origin_sub_element_type_id: 0,
    destination_sub_element_type_id: 0,
    handling_type_id: 0,
  };

  const renderItems = data => [
    { value: 0, label: translate('common:Select') },
    ...data.map(e => ({ value: e.id, label: e.name })),
  ];

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      validate={(values) => {
        const errors = {};

        // Verifica se existe um movimento com as informações fornecidas
        const hasRegistered = flowMovements.some(f => (
          f.material_groups_id === values.material_groups_id
          && f.origin_sub_element_type_id === values.origin_sub_element_type_id
          && f.destination_sub_element_type_id === values.destination_sub_element_type_id
        ));

        if (hasRegistered) {
          errors.already_registered = translate('common:MovementAlreadyRegistered');
          errors.material_groups_id = 'Required';
          errors.origin_sub_element_type_id = 'Required';
          errors.destination_sub_element_type_id = 'Required';
          errors.handling_type_id = 'Required';
        }


        if (values.material_groups_id <= 0) {
          errors.material_groups_id = 'Required';
        }
        if (values.origin_sub_element_type_id === 0) {
          errors.origin_sub_element_type_id = 'Required';
        }
        if (values.destination_sub_element_type_id === 0) {
          errors.destination_sub_element_type_id = 'Required';
        }
        if (values.handling_type_id <= 0) {
          errors.handling_type_id = 'Required';
        }
        return errors;
      }}
      onSubmit={async (values, { resetForm }) => {
        await add(values);
        resetForm();
      }}
    >
      {({
        isSubmitting,
        isValid,
        errors,
      }) => (
        <Form>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 5,
          }}
          >
            <FormControl>
              <FormLabel className={classes.formLabel}>
                {`${translate('common:MaterialGroup')}:`}
              </FormLabel>
              <FormGroup>
                <Field
                  name="material_groups_id"
                  render={({ field, form }) => (
                    <PF2MSearchSelectOutlined
                      {...field}
                      value={(field.value || 0)}
                      outlined="true"
                      fullWidth={false}
                      onChange={
                        e => form.setFieldValue('material_groups_id', e.target.value)
                      }
                      className={classnames(classes.typeSelector, form.errors[field.name]
                        ? classes.fieldError : classes.typeSelector)}
                    >
                      {renderItems(materialsGroups)}
                    </PF2MSearchSelectOutlined>
                  )}
                />
              </FormGroup>
            </FormControl>
            <FormControl>
              <FormLabel className={classes.formLabel}>
                {`${translate('common:OriginType')}:`}
              </FormLabel>
              <FormGroup>
                <Field
                  name="origin_sub_element_type_id"
                  render={({ field, form }) => (
                    <PF2MSearchSelectOutlined
                      {...field}
                      value={(field.value || 0)}
                      outlined="true"
                      fullWidth={false}
                      onChange={
                        e => form.setFieldValue('origin_sub_element_type_id', e.target.value)
                      }
                      className={classnames(classes.typeSelector, form.errors[field.name]
                        ? classes.fieldError : classes.typeSelector)}
                    >
                      {renderItems(subElementTypes)}
                    </PF2MSearchSelectOutlined>
                  )}
                />
              </FormGroup>
            </FormControl>
            <FormControl>
              <FormLabel className={classes.formLabel}>
                {`${translate('common:DestinationType')}:`}
              </FormLabel>
              <FormGroup>
                <Field
                  name="destination_sub_element_type_id"
                  render={({ field, form }) => (
                    <PF2MSearchSelectOutlined
                      {...field}
                      value={(field.value || 0)}
                      outlined="true"
                      fullWidth={false}
                      onChange={e => form.setFieldValue('destination_sub_element_type_id', e.target.value)
                      }
                      className={classnames(classes.typeSelector, form.errors[field.name]
                        ? classes.fieldError : classes.typeSelector)}
                    >
                      {renderItems(subElementTypes)}
                    </PF2MSearchSelectOutlined>
                  )}
                />
              </FormGroup>
            </FormControl>
            <FormControl>
              <FormLabel className={classes.formLabel}>
                {`${translate('common:MovementType')}:`}
              </FormLabel>
              <FormGroup>
                <Field
                  name="handling_type_id"
                  render={({ field, form }) => (
                    <PF2MSearchSelectOutlined
                      {...field}
                      value={(field.value || 0)}
                      outlined="true"
                      fullWidth={false}
                      onChange={e => form.setFieldValue('handling_type_id', e.target.value)}
                      className={classnames(classes.typeSelector, form.errors[field.name]
                        ? classes.fieldError : classes.typeSelector)}
                    >
                      {renderItems(handlingTypes)}
                    </PF2MSearchSelectOutlined>
                  )}
                />
              </FormGroup>
            </FormControl>
            <PF2MButton
              type="submit"
              disabled={isSubmitting || !isValid}
              style={{ marginTop: 20 }}
            >
              <Add className={classes.icon} />
              {translate('common:Register')}
            </PF2MButton>
          </div>
          <div style={{ paddingLeft: 300 }}>
            <p style={{
              color: '#EC3337',
              fontFamily: 'Roboto',
              fontSize: 12,
            }}
            >
              {errors.already_registered}
            </p>
          </div>
        </Form>
      )}
    </Formik>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  add: PropTypes.func,
  materialsGroups: PropTypes.array,
  elements: PropTypes.array,
  handlingTypes: PropTypes.array,
  subElementTypes: PropTypes.array,
};

Header.defaultProps = {
  add: () => { },
  materialsGroups: [],
  elements: [],
  handlingTypes: [],
  subElementTypes: [],
};


export default withStyles(styles)(Header);

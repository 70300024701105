import React, { useState } from 'react';
import {
  withStyles,
  FormControl,
  FormLabel,
  FormGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PF2MOutlinedInput from '~/components/PF2MOutlinedInput';
import PF2MSearchButton from '~/components/PF2MSearchButton';
import styles from '../styles';
import { newMomentDate } from '~/utils/moment';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { getAutoAllocationLogs } from '~/store/dispatch/actions';

const AutoAllocationLogs = ({ classes }) => {
  const [startDate, setStartDate] = useState(newMomentDate());
  const [endDate, setEndDate] = useState(newMomentDate());
  const dispatch = useDispatch();
  const autoAllocationLogs = useSelector(state => state.dispatch.autoAllocationLogs);
  const { t: translate } = useTranslation();

  const renderDatePicker = (value, key, label, onChange) => (
    <FormControl style={{ marginRight: '10px', width: 200 }}>
      <FormLabel className={classes.formLabel}>
        {label}
      </FormLabel>
      <FormGroup>
        <KeyboardDatePicker
          clearable
          inputVariant="outlined"
          InputProps={{
            style: {
              height: 40,
              width: 200,
              color: '#647886',
            },
          }}
          format={translate('date:DateFormat')}
          autoOk
          maxDate={
            key === 'startDate'
              ? endDate
              : newMomentDate().add(90, 'days')
          }
          minDate={
            key === 'endDate'
              ? startDate
              : newMomentDate().subtract(90, 'days')
          }
          onChange={(e) => { onChange(e); }}
          value={value}
          invalidDateMessage={translate('validation:InvalidDate')}
        />
      </FormGroup>
    </FormControl>
  );

  const renderHeader = () => (
    <div style={{ display: 'flex' }}>
      {renderDatePicker(startDate, 'startDate', translate('common:StartDate'), setStartDate)}
      {renderDatePicker(endDate, 'endDate', translate('common:EndDate'), setEndDate)}
      <div style={{ marginTop: 12 }}>
        <PF2MSearchButton size="medium" onClick={() => dispatch(getAutoAllocationLogs(startDate, endDate))} />
      </div>
    </div>
  );

  return (
    <div className={classes.tabContainer}>
      {renderHeader()}
      <PF2MOutlinedInput
        style={{ marginTop: 20 }}
        className={classes.textArea}
        value={autoAllocationLogs.toString() || ''}
        multiline
        rows={24}
      />
    </div>
  );
};

AutoAllocationLogs.propTypes = {
  classes: PropTypes.object.isRequired,
};

AutoAllocationLogs.defaultProps = {};

export default withStyles(styles)(AutoAllocationLogs);

/* eslint-disable import/no-extraneous-dependencies */
import _ from 'lodash';
import * as types from './types';
import api from '~/services/api';
import {
  exportData, importData, getTemplate, handleErrors,
} from '../common';
import { clearFileUploadError } from '../errors/actions';
import { SET_UPLOAD_SUCCESS } from '../errors/types';
import { getTurnsGroups } from '../manager/actions';
import i18n from '~/i18n';

const getEndpoints = {
  codegroups: {
    url: '/maintenance/codegroups',
    responseObject: 'code_group_maintenance',
    typeAction: types.SET_MAINTENANCE_CODE_GROUPS_LIST,
  },
  codetypes: {
    url: '/maintenance/codetypes',
    responseObject: 'code_type_maintenance',
    typeAction: types.SET_MAINTENANCE_CODE_TYPES_LIST,
  },
  systems: {
    url: '/maintenance/systems',
    responseObject: 'system_maintenance',
    typeAction: types.SET_MAINTENANCE_SYSTEMS_LIST,
  },
  systemitems: {
    url: '/maintenance/systemitems',
    responseObject: 'system_item_maintenance',
    typeAction: types.SET_MAINTENANCE_SYSTEM_ITEMS_LIST,
  },
  failuremodes: {
    url: '/maintenance/failuremodes',
    responseObject: 'failure_mode_maintenance',
    typeAction: types.SET_MAINTENANCE_FAILURE_MODES_LIST,
  },
  codes: {
    url: '/maintenance/codes',
    responseObject: 'code_maintenance',
    typeAction: types.SET_MAINTENANCE_CODES_LIST,
  },
  logoutlimit: {
    url: '/maintenance/logoutlimit',
    responseObject: 'maintenance_logout_limit',
    typeAction: types.SET_LOGOUT_LIMIT,
  },
  failuremodesystemitemlink: {
    url: '/maintenance/failuremodesystemitemlink',
    responseObject: 'failure_mode_system_item_maintenance_link',
    typeAction: types.SET_FAILURE_MODES_SYSTEM_ITEMS_LIST,
  },

  failuremodeequipmentgroupmaintenancelink: {
    url: '/maintenance/failuremodeequipmentgroupmaintenancelink',
    responseObject: 'failure_mode_equipment_group_maintenance_link',
    typeAction: types.SET_FAILURE_MODE_EQUIPMENT_GROUP_MAINTENANCE_LIST,
  },

  failuremodeequipmentgroupmaintenancelink_filter: {
    url: '/maintenance/failuremodeequipmentgroupmaintenancelink/get_by_filter',
    responseObject: 'failure_mode_equipment_group_maintenance_link',
    typeAction: types.SET_FAILURE_MODE_EQUIPMENT_GROUP_MAINTENANCE_FILTERED,
  },

  systemitemmaintenanceequipmentgroup: {
    url: '/maintenance/systemitemmaintenanceequipmentgroup/get_by_filter',
    responseObject: 'system_item_maintenance_equipment_group',
    typeAction: types.SET_EQUIPMENT_GROUPS_SYSTEM_ITEMS_LIST,
  },
  maintenanceoperationitems: {
    url: '/maintenance/itemoperationreportraw/get_by_filter',
    responseObject: 'maintenance_item_operation_report_raw',
    typeAction: types.SET_MAINTENANCE_OPERATION_ITEMS_LIST,
  },
};

export const getAll = ({ domain, clearUploadError = true, all = false }) => async (dispatch) => {
  try {
    if (clearUploadError) {
      dispatch(clearFileUploadError());
    }

    const endpoint = getEndpoints[domain.replace('maintenance/', '')];
    let { url } = endpoint;

    if (all) {
      url += '?all';
    }

    const {
      data: {
        result: { [endpoint.responseObject]: data = [] },
      },
    } = await api.get(url);
    dispatch({
      type: endpoint.typeAction,
      payload: data,
    });
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const get = (endpoint, params) => async (dispatch) => {
  try {
    dispatch(clearFileUploadError());
    const {
      data: {
        result: { [endpoint.responseObject]: data = [] },
      },
    } = await api.post(endpoint.url, params);
    dispatch({
      type: endpoint.typeAction,
      payload: data,
    });
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const updateData = (domain, data) => async (dispatch) => {
  try {
    const items = data
      .filter(d => d.update_status)
      .map((d) => {
        const {
          isDirty, id, ...rest
        } = d;

        if (rest.update_status !== 'I') return { id, ...rest };
        return rest;
      });
    const endpoint = getEndpoints[domain];

    const body = {
      [endpoint.responseObject]: items,
    };
    const { status } = await api.post(`${endpoint.url}`, body);
    if (status === 200) {
      dispatch(getAll({ domain }));
    }
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

const setValues = (item, key, value, type) => (dispatch) => {
  const updateStatus = item.update_status === 'I' ? 'I' : 'U';
  dispatch({
    type,
    payload: {
      ...item,
      [key]: value,
      update_status: updateStatus,
    },
  });
};

export const getCodeGroups = () => async dispatch => dispatch(getAll({ domain: 'codegroups' }));
export const getCodeTypes = () => async dispatch => dispatch(getAll({ domain: 'codetypes' }));
export const updateCodeGroups = data => async dispatch => dispatch(updateData('codegroups', data));
export const setCodeGroupValue = (item, key, value) => (dispatch) => {
  dispatch(setValues(item, key, value, types.SET_MAINTENANCE_CODE_GROUP_VALUE));
};
export const resetCodeGroups = () => (dispatch) => {
  dispatch({ type: types.RESET_MAINTENANCE_CODE_GROUPS });
};

export const addCodeGroup = data => dispatch => dispatch({
  type: types.ADD_MAINTENANCE_CODE_GROUP,
  payload: data,
});

export const getSystems = () => async dispatch => dispatch(getAll({ domain: 'systems' }));
export const updateSystems = data => async dispatch => dispatch(updateData('systems', data));
export const setSystemValue = (item, key, value) => (dispatch) => {
  dispatch(setValues(item, key, value, types.SET_MAINTENANCE_SYSTEM_VALUE));
};
export const resetSystems = () => (dispatch) => {
  dispatch({ type: types.RESET_MAINTENANCE_SYSTEMS });
};
export const addSystem = data => dispatch => dispatch({
  type: types.ADD_MAINTENANCE_SYSTEM,
  payload: data,
});

export const getSystemItems = () => async dispatch => dispatch(getAll({ domain: 'systemitems' }));
export const updateSystemItems = data => async dispatch => dispatch(updateData('systemitems', data));
export const setSystemItemValue = (item, key, value) => (dispatch) => {
  dispatch(setValues(item, key, value, types.SET_MAINTENANCE_SYSTEM_ITEM_VALUE));
};
export const resetSystemItems = () => (dispatch) => {
  dispatch({ type: types.RESET_MAINTENANCE_SYSTEM_ITEMS });
};
export const addSystemItem = data => dispatch => dispatch({
  type: types.ADD_MAINTENANCE_SYSTEM_ITEM,
  payload: data,
});

export const getFailureModes = () => async dispatch => dispatch(getAll({ domain: 'failuremodes' }));
export const updateFailureModes = data => async dispatch => dispatch(updateData('failuremodes', data));
export const setFailureModeValue = (item, key, value) => (dispatch) => {
  dispatch(setValues(item, key, value, types.SET_MAINTENANCE_FAILURE_MODE_VALUE));
};
export const resetFailureModes = () => (dispatch) => {
  dispatch({ type: types.RESET_MAINTENANCE_FAILURE_MODES });
};
export const addFailureMode = data => dispatch => dispatch({
  type: types.ADD_MAINTENANCE_FAILURE_MODE,
  payload: data,
});

export const addCode = data => dispatch => dispatch({
  type: types.ADD_MAINTENANCE_CODE,
  payload: data,
});

export const getCodes = (all = false) => async dispatch => dispatch(getAll({ domain: 'codes', all }));
export const updateCodes = data => async (dispatch) => {
  const newData = data.map((d) => {
    // eslint-disable-next-line no-param-reassign
    delete d.id_code_type_maintenance;
    // eslint-disable-next-line no-param-reassign
    delete d.code_type_maintenance_name;
    return d;
  });
  dispatch(updateData('codes', newData));
};
export const setCodeValue = (item, key, value) => (dispatch) => {
  dispatch(setValues(item, key, value, types.SET_MAINTENANCE_CODE_VALUE));
};
export const resetCodes = () => (dispatch) => {
  dispatch({ type: types.RESET_MAINTENANCE_CODES });
};

export const getLogoutLimit = () => async dispatch => dispatch(getAll({ domain: 'logoutlimit' }));

export const setLogoutLimitValue = value => (dispatch) => {
  dispatch({
    type: types.SET_LOGOUT_LIMIT_VALUE,
    payload: value,
  });
};

export const updateLogoutLimit = logoutLimit => async (dispatch) => {
  try {
    const endpoint = getEndpoints.logoutlimit;

    const body = {
      [endpoint.responseObject]: logoutLimit,
    };
    const { status } = await api.post(`${endpoint.url}`, body);
    if (status === 200) {
      dispatch(getLogoutLimit());
      dispatch({ type: SET_UPLOAD_SUCCESS, payload: i18n.t('validation:SavedSuccess') });
    }
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const getFailureModeEquipmentGroupMaintenanceItems = (filters) => {
  if (!filters) return getAll({ domain: 'failuremodeequipmentgroupmaintenancelink' });

  const params = {
    equipment_group_id: filters.equipmentGroup,
    id_system: filters.system,
  };
  const endpoint = getEndpoints.failuremodeequipmentgroupmaintenancelink_filter;
  return get(endpoint, params);
};

export const getFailureModesSystemItems = () => getAll({ domain: 'failuremodesystemitemlink' });

export function updateFailureModesSystemItems(failureModesSystemItems, failureModeEquipmentGroup) {
  return async (dispatch) => {
    try {
      const endpointSystemItems = getEndpoints.failuremodesystemitemlink;
      const equipGroupByItemFailure = _.groupBy(
        failureModeEquipmentGroup.filter(i => i.update_status),
        i => i.id_failure_mode_system_item,
      );

      const failItems = failureModesSystemItems
        .map(i => ({ ...i, equipments_groups: equipGroupByItemFailure[i.id] || [] }))
        .filter(d => d.update_status || d.equipments_groups.length > 0);

      const bodySystemItems = {
        [endpointSystemItems.responseObject]: failItems,
      };
      const { status } = await api.post(`${endpointSystemItems.url}`, bodySystemItems);
      if (status === 200) {
        dispatch(getAll({ domain: 'failuremodeequipmentgroupmaintenancelink' }));
        dispatch(getAll({ domain: 'failuremodesystemitemlink' }));
      }
    } catch ({ response }) {
      dispatch(handleErrors(response));
    }
  };
}

export const resetLogoutLimit = () => (dispatch) => {
  dispatch({ type: types.RESET_LOGOUT_LIMIT });
};
// eslint-disable-next-line max-len
export const setFailureModeSystemItemValue = (
  systemItemId, failureModeId, equipmentGroupId, checked,
) => ({
  type: types.SET_FAILURE_MODE_SYSTEM_ITEM_VALUE,
  payload: {
    systemItemId,
    failureModeId,
    equipmentGroupId,
    checked,
  },
});


export const resetFailureModesSystemItems = () => (dispatch) => {
  dispatch({ type: types.RESET_FAILURE_MODE_SYSTEM_ITEMS });
  dispatch({ type: types.RESET_FAILURE_MODE_EQUIPMENT_GROUPS });
};

export const getSystemsTemplate = () => async dispatch => dispatch(getTemplate('maintenance/systems'));
export const importSystems = file => async dispatch => dispatch(importData('maintenance/systems', file, getAll));
export const exportSystems = () => async dispatch => dispatch(exportData('maintenance/systems'));

export const getFailureModeEquipmentGroupMaintenanceItemsTemplate = () => async dispatch => (
  dispatch(getTemplate('maintenance/failuremodeequipmentgroupmaintenancelink')));
export const importFailureModeEquipmentGroupMaintenanceItems = (file, filters) => (
  importData(
    'maintenance/failuremodeequipmentgroupmaintenancelink',
    file,
    () => get(
      getEndpoints.failuremodeequipmentgroupmaintenancelink_filter,
      { equipment_group_id: filters.equipmentGroup, id_system: filters.system },
    ),
  )
);
export const exportFailureModeEquipmentGroupMaintenanceItems = ({ equipmentGroup, system }) => (
  exportData(
    'maintenance/failuremodeequipmentgroupmaintenancelink',
    { equipment_group_id: equipmentGroup, id_system: system },
  )
);

export const getSystemItemsTemplate = () => async dispatch => dispatch(getTemplate('maintenance/systemitems'));
export const importSystemItems = file => async dispatch => dispatch(importData('maintenance/systemitems', file, getAll));
export const exportSystemItems = () => async dispatch => dispatch(exportData('maintenance/systemitems'));

export const getFailureModesTemplate = () => async dispatch => dispatch(getTemplate('maintenance/failuremodes'));
export const importFailureModes = file => async dispatch => dispatch(importData('maintenance/failuremodes', file, getAll));
export const exportFailureModes = () => async dispatch => dispatch(exportData('maintenance/failuremodes'));

export const getEquipmentGroupsSystemItems = (
  equipmentGroup = 0,
  system = 0,
) => async (dispatch) => {
  const params = {
    equipment_group_id: equipmentGroup,
    system_maintenance_id: system,
  };
  const endpoint = getEndpoints.systemitemmaintenanceequipmentgroup;
  return dispatch(get(endpoint, params));
};

export const getMaintenanceOperationItems = (maintenanceReportId = 0) => async (dispatch) => {
  const params = { maintenance_operation_report_id: maintenanceReportId };
  const endpoint = getEndpoints.maintenanceoperationitems;
  return dispatch(get(endpoint, params));
};

// eslint-disable-next-line max-len
export const setEquipmentGroupSystemItemValue = (equipmentGroup, system, systemItem, checked) => (dispatch) => {
  dispatch({
    type: types.SET_EQUIPMENT_GROUP_SYSTEM_ITEM_VALUE,
    payload: {
      equipmentGroup,
      system,
      systemItem,
      checked,
    },
  });
};

export const checkEquipmentGroupSystemItems = (equipmentGroup, system, checked) => (dispatch) => {
  dispatch({
    type: types.SET_ALL_EQUIPMENT_GROUP_SYSTEM_ITEM_VALUE,
    payload: {
      system,
      equipmentGroup,
      checked,
    },
  });
};

export const resetEquipmentGroupsSystemItems = () => (dispatch) => {
  dispatch({ type: types.RESET_EQUIPMENT_GROUPS_SYSTEM_ITEMS });
};

// eslint-disable-next-line max-len
export const updateEquipmentGroupsSystemItems = data => async (dispatch) => {
  try {
    const items = data
      .filter(d => d.update_status)
      .map(i => ({
        ...i,
        update_status: i.id > 0 ? 'U' : 'I',
      }));

    const body = {
      system_item_maintenance_equipment_group: items,
    };

    const { status } = await api.post('/maintenance/systemitemmaintenanceequipmentgroup', body);
    if (status === 200) {
      dispatch(getEquipmentGroupsSystemItems());
    }
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const createMaintenanceOperationItem = item => async (dispatch) => {
  dispatch({
    type: types.CREATE_MAINTENANCE_OPERATION_ITEM,
    payload: {
      item,
    },
  });
};

export const setMaintenanceOperationItems = (item, key = null, value = null) => (dispatch) => {
  const newItem = { ...item };
  if (key) {
    newItem[key] = value;
  }
  if (key === 'system_item_maintenance_id') {
    newItem.failure_mode_maintenance_id = 0;
  }
  dispatch({
    type: types.SET_MAINTENANCE_OPERATION_ITEMS,
    payload: {
      item: newItem,
    },
  });
};

export const resetMaintenanceOperationItems = () => (dispatch) => {
  dispatch({ type: types.RESET_MAINTENANCE_OPERATION_ITEMS });
};

export const updateMaintenanceOperationItems = (items, isCorrection) => async (dispatch) => {
  try {
    const endpoint = getEndpoints.maintenanceoperationitems;

    const body = {
      [endpoint.responseObject]: items.map(r => ({
        ...r,
        failure_mode_maintenance_id: isCorrection ? r.failure_mode_maintenance_id : null,
        system_item_maintenance_id: isCorrection ? r.system_item_maintenance_id : null,
        update_status: r.id > 0 ? 'U' : 'I',
      })),
    };
    const { status } = await api.post('/maintenance/itemoperationreportraw', body);
    if (status === 200) {
      dispatch(resetMaintenanceOperationItems());
    }
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const setCurrentMaintenanceOperationItems = maintenanceReport => async (dispatch) => {
  const {
    id,
    is_maintenance_correction: isMaintenanceCorretion,
  } = maintenanceReport;

  const params = { maintenance_operation_report_id: id };
  const endpoint = getEndpoints.maintenanceoperationitems;

  dispatch(getFailureModesSystemItems());

  try {
    dispatch(clearFileUploadError());
    const {
      data: {
        result: { [endpoint.responseObject]: data = [] },
      },
    } = await api.post(endpoint.url, params);

    const { group_equipament_links_id: equipmentId, system_maintenance_id: systemId } = data;

    if (equipmentId > 0 && systemId > 0) {
      dispatch(getEquipmentGroupsSystemItems(equipmentId, systemId));
    }

    dispatch({
      type: types.SET_CURRENT_MAINTENANCE_OPERATION_ITEMS,
      payload: {
        maintenanceOperation: maintenanceReport,
        maintenanceOperationItems: data,
        isMaintenanceCorretion,
      },
    });
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const setMaintenanceTurnGroupValue = value => (dispatch) => {
  dispatch({
    type: types.SET_MAINTENANCE_TURN_GROUP_VALUE,
    payload: value,
  });
};

export const updateMaintenanceTurnGroup = idTurnGroup => async (dispatch) => {
  try {
    const body = { id_turn_group: idTurnGroup };
    const { status } = await api.post('/turnsgroups/set_maintenance', body);

    if (status === 200) {
      dispatch(getTurnsGroups());
      dispatch({ type: SET_UPLOAD_SUCCESS, payload: i18n.t('validation:SavedSuccess') });
    }
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const resetMaintenanceTurnGroup = () => (dispatch) => {
  dispatch({ type: types.RESET_MAINTENANCE_TURN_GROUP });
};

export const setMaintenanceManagementFilter = filter => (dispatch) => {
  const { name, value } = filter;
  if (value === undefined || value === null) {
    dispatch({
      type: types.SET_MAINTENANCE_MANAGEMENT_FILTER,
      payload: filter,
    });
  } else {
    dispatch({
      type: types.SET_MAINTENANCE_MANAGEMENT_FILTER_ITEM,
      payload: {
        name, value,
      },
    });
  }
};

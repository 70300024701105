import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import ChecklistTab from './ChecklistTab';
import MapsTab from './MapsTab';
import PF2MTabs from '~/components/PF2MTabs';
import { setAlertDialog } from '~/store/manager/actions';

const Checklist = ({
  setAlertDialog: setModal,
  isChecklistMapsDirty,
}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { t: translate } = useTranslation();

  const tabs = [
    { key: 0, label: translate('common:CheckListItem') },
    { key: 1, label: translate('common:ConnectChecklist') },
  ];

  const handleChange = (event, value) => {
    const blockChange = value === 0 && isChecklistMapsDirty;
    if (blockChange) {
      event.preventDefault();
      setModal();
    } else {
      setSelectedTab(value);
    }
  };

  return (
    <>
      <PF2MTabs
        value={selectedTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        {tabs}
      </PF2MTabs>
      {selectedTab === 0 ? (<ChecklistTab />) : null}
      {selectedTab === 1 ? (<MapsTab />) : null}
    </>
  );
};


Checklist.propTypes = {
  setAlertDialog: PropTypes.func,
  isChecklistMapsDirty: PropTypes.bool,
};

Checklist.defaultProps = {
  setAlertDialog: () => { },
  isChecklistMapsDirty: false,
};

const mapStateToProps = state => ({
  isChecklistMapsDirty: state.manager.isChecklistMapsDirty,
});

const mapDispatchToProps = {
  setAlertDialog,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Checklist));

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import {
  withStyles, FormLabel, FormGroup, Typography,
} from '@material-ui/core';
import styles from './styles';

const PF2MCheckboxSelect = ({
  inputLabel,
  selectedValue,
  classes,
  checkboxs,
  initialState = {},
  options,
  filter,
  onChange,
  name,
  map,
  headOptions = [],
  clearedValue = '',
}) => {
  const [checkeds, setCheckeds] = React.useState(initialState);
  const toogleCheckbox = (checked, field) => setCheckeds(f => ({ ...f, [field]: checked }));
  const validOptions = options.filter(c => filter(checkeds, c)).map(map);

  return (
    <FormGroup>
      <FormLabel className={classes.formLabel}>
        {inputLabel}
      </FormLabel>
      <PF2MSearchSelectOutlined
        {...classes.field}
        name={name}
        className={classnames(classes.field)}
        placeholder={null}
        value={selectedValue}
        onChange={onChange}
        clearedValue={clearedValue}
      >
        {headOptions.concat(...validOptions)}
      </PF2MSearchSelectOutlined>
      <div>
        {
          checkboxs.map(({ label, field }) => (
            <FormControlLabel
              key={field}
              style={{ marginLeft: 0 }}
              control={(
                <Checkbox
                  checked={checkeds[field]}
                  onChange={e => toogleCheckbox(e.target.checked, field)}
                />
              )}
              label={(
                <Typography style={styles.checkboxLabel}>
                  {label}
                </Typography>
              )}
            />
          ))
        }
      </div>
    </FormGroup>
  );
};

PF2MCheckboxSelect.propTypes = {
  inputLabel: PropTypes.string.isRequired,
  selectedValue: PropTypes.any.isRequired,
  classes: PropTypes.shape({
    formLabel: PropTypes.string,
    field: PropTypes.string,
  }).isRequired,
  checkboxs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
    }),
  ).isRequired,
  initialState: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  filter: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  name: PropTypes.string,
  map: PropTypes.func,
  headOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
    }),
  ),
  clearedValue: PropTypes.any,
};

PF2MCheckboxSelect.defaultProps = {
  map: item => item,
  clearedValue: '',
  name: '',
  onChange: () => { },
  headOptions: [],
};

export default withStyles(styles)(PF2MCheckboxSelect);

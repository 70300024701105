import React, { useState, useEffect, useCallback } from 'react';
import {
  withStyles,
  Modal,
  Typography,
  Fab,
} from '@material-ui/core';
import Done from '@material-ui/icons/Done';
import Add from '@material-ui/icons/Add';
import DeleteForever from '@material-ui/icons/DeleteForever';
import PropTypes from 'prop-types';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import PF2MButton from '~/components/PF2MButton';
import { formatDate, parseDate } from '~/utils/moment';

const handleMask = value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : []);

function DayOffModal({
  classes,
  modalOpen,
  setModalOpen,
  daysOff,
  updateDaysOff,
  startDate,
  endDate,
  teamGroup,
  turnGroup,
}) {
  const [daysOffList, setDaysOffList] = useState([]);
  const [modalLoaded, setModalLoaded] = useState(false);
  const { t: translate } = useTranslation();

  const handleAddDay = useCallback(({ existingItems = [], userAction = true }) => {
    const newItem = {
      id: (daysOffList.length - 1),
      id_turn_group: turnGroup,
      id_team_group: teamGroup,
      day_off: true,
      start_date: startDate,
      end_date: endDate,
    };
    if (userAction) { newItem.update_status = 'I'; }
    const newData = [
      ...existingItems,
      ...daysOffList,
      newItem];

    setDaysOffList(newData);
  }, [daysOffList, endDate, startDate, teamGroup, turnGroup]);

  const handleDeleteDay = (index) => {
    const currentItem = daysOffList[index];
    if (currentItem.id > 0) {
      currentItem.update_status = 'D';
    } else {
      daysOffList.splice(index, 1);
    }
    setDaysOffList([...daysOffList]);
  };

  useEffect(() => {
    if (!modalLoaded) {
      handleAddDay({ existingItems: daysOff, userAction: false });
    }
    setModalLoaded(true);
  }, [daysOff, modalLoaded, handleAddDay]);

  const handleDateChange = (field, date, index) => {
    daysOffList[index][field] = date;
    daysOffList[index].update_status = 'I';
    const newData = [...daysOffList];
    setDaysOffList(newData);
  };

  const renderRow = (item, index, isLast) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 10,
      }}
      key={index}
    >
      <Typography variant="h6" className={classes.dayOffTitle}>
        {`${translate('common:Pause')} ${index + 1}:`}
      </Typography>
      <KeyboardDatePicker
        clearable
        mask={handleMask}
        format={translate('date:DateFormat')}
        style={{ marginLeft: 25 }}
        variant="standard"
        value={parseDate(item.start_date)}
        minDate={startDate}
        maxDate={endDate}
        autoOk
        InputProps={{
          style: {
            height: 40,
            width: 150,
          },
        }}
        helperText=""
        onChange={date => handleDateChange('start_date', date, index)}
        disabled={item.update_status === 'D'}
      />
      <KeyboardDatePicker
        clearable
        mask={handleMask}
        format={translate('date:DateFormat')}
        style={{ marginLeft: 25 }}
        variant="standard"
        value={parseDate(item.end_date)}
        minDate={startDate}
        maxDate={endDate}
        autoOk
        InputProps={{
          style: {
            height: 40,
            width: 150,
          },
        }}
        helperText=""
        onChange={date => handleDateChange('end_date', date, index)}
        disabled={item.update_status === 'D'}
      />
      <Fab
        style={{
          width: 34, height: 25, marginLeft: 25,
        }}
        color="primary"
        variant="round"
        onClick={() => {
          if (isLast) {
            handleAddDay({});
          } else {
            handleDeleteDay(index);
          }
        }}
      >
        {isLast ? <Add /> : <DeleteForever />}
      </Fab>
    </div>
  );

  const renderRows = (items = []) => items.map((item, index) => {
    const isLast = index === items.length - 1;
    return renderRow(item, index, isLast);
  });

  return (
    <Modal
      open={modalOpen}
      onBackdropClick={() => setModalOpen && setModalOpen(false)}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <div className={classes.paper}>
        <Typography
          variant="h6"
          style={{
            fontFamily: 'Barlow Condensed',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: 48,
            textTransform: 'uppercase',
            color: '#2E3841',
          }}
        >
          {translate('common:AddPause')}
        </Typography>
        <Typography
          variant="h6"
          style={{
            fontFamily: 'Barlow Condensed',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: 18,
            textTransform: 'uppercase',
            color: '#647886',
          }}
        >
          {`${translate('common:Period')}: ${formatDate(startDate)} ${translate('common:Until').toLowerCase()} ${formatDate(endDate)}`}
        </Typography>
        <div
          style={{
            marginTop: 50,
            marginBottom: 50,
          }}
        >
          {renderRows(daysOffList)}
        </div>
        <PF2MButton
          onClick={() => {
            updateDaysOff(daysOffList);
            return setModalOpen && setModalOpen(false);
          }}
        >
          <Done style={{ marginRight: 10 }} />
          {translate('common:End')}
        </PF2MButton>
      </div>
    </Modal>
  );
}

DayOffModal.propTypes = {
  classes: PropTypes.object.isRequired,
  modalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  daysOff: PropTypes.array,
  updateDaysOff: PropTypes.func,
  teamGroup: PropTypes.number,
  turnGroup: PropTypes.number,
};

DayOffModal.defaultProps = {
  modalOpen: false,
  setModalOpen: null,
  startDate: null,
  endDate: null,
  daysOff: [],
  updateDaysOff: null,
  teamGroup: null,
  turnGroup: null,
};

export default withStyles(styles)(DayOffModal);

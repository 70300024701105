const SET_DOMAINS = 'SET_DOMAINS';
const EXCEPTION_TYPES = {
  1: 'COMPLETO',
  9: 'SOBRECARGA',
  10: 'INTERROMPIDO',
  11: 'SOBRECARGA',
  12: 'CHECKLIST',
  13: 'ADD_MANUAL',
  14: 'COMMON',
  15: 'EDIT_MANUAL',
  16: 'EDIT_DELETE',
  17: 'DESCARTADO',
};

export default {
  SET_DOMAINS,
  EXCEPTION_TYPES,
};

import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  getCodeGroups,
  getCodeTypes,
  getEquipmentTypes,
  getActivityTypesChecklist,
  resetActivitTypesChecklist,
  setActivityTypesChecklist,
  addActivityTypeChecklist,
  updateActivityTypeChecklist,
} from '~/store/manager/actions';
import PF2MDataTable from '~/components/PF2MDataTable';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import RequestModal from './RequestModal';
import styles from '../styles';

const TasksTab = ({ classes }) => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const [requestModalData, setRequestModalData] = useState(null);
  const activityTypesChecklist = useSelector(st => st.manager.activityTypesChecklist);

  useEffect(() => {
    dispatch(getCodeGroups(true));
    dispatch(getCodeTypes(true));
    dispatch(getEquipmentTypes(true));

    dispatch(getActivityTypesChecklist(true));
  }, [dispatch]);

  const columns = [
    {
      field: 'name',
      title: translate('common:TaskCodeType'),
      width: '200px',
      validate: item => Boolean(item.name),
    },
    {
      title: translate('common:RequestByCode'),
      render: colData => (
        <FormatListBulletedIcon
          className={classes.tableIcon}
          onClick={() => setRequestModalData(colData)}
        />
      ),
    },
    { field: 'timer', title: translate('common:ReminderByTimeMinutes') },
    {
      field: 'active',
      title: translate('common:Active'),
      customType: 'boolean',
    },
    { field: 'description', title: translate('common:Description') },
  ];

  return (
    <div className={classes.tabContainer}>
      <PF2MDataTable
        onDiscard={() => dispatch(resetActivitTypesChecklist())}
        onInactivateAll={(newData) => {
          newData.forEach((row) => {
            dispatch(setActivityTypesChecklist({ ...row, active: 0 }));
          });
        }}
        onChange={(newData) => {
          newData.forEach((row) => {
            dispatch(setActivityTypesChecklist(row));
          });
        }}
        onAdd={newData => dispatch(addActivityTypeChecklist(newData))}
        onApply={() => dispatch(updateActivityTypeChecklist())}
        options={{ selection: true, hideDelete: true }}
        data={activityTypesChecklist}
        columns={columns}
        initialFormData={{
          active: true,
          name: '',
          description: '',
          timer: 0,
          associated_codes: [],
          id: -activityTypesChecklist.length,
        }}
      />
      <RequestModal
        modalData={requestModalData}
        closeModal={() => setRequestModalData(null)}
        classes={classes}
      />
    </div>
  );
};

TasksTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TasksTab);

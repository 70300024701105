// import { managerGridHeight } from '~/utils';

const styles = {
  modalContainer: {
    display: 'flex',
    // backgroundColor: 'red', // '#F9F9F9',
    // height: '100%', // managerGridHeight,
    padding: 10,
  },
  icon: {
    marginRight: 10,
  },
  button: {
    color: '#FFF',
    margin: 5,
  },
  tabContainer: {
    borderRadius: 5,
    borderTopLeftRadius: 0,
    backgroundColor: 'white',
    padding: 10,
  },
  field: {
    border: '1px solid #CDCDCD',
    borderRadius: 5,
    height: 40,
    color: '#647886',
    width: 200,
    borderWidth: 0,
  },
  fieldCommentary: {
    border: '1px solid #CDCDCD',
    borderRadius: 5,
    height: 40,
    color: '#647886',
    borderWidth: 0,
  },
  fieldError: {
    border: '1px solid red !important',
    borderRadius: 5,
    height: 40,
  },
  classGroupName: {
    minWidth: 290,
    marginLeft: 10,
  },
  formLabel: {
    color: '#647886',
    fontSize: 12,
    fontFamily: 'Roboto',
    marginBottom: 5,
  },
  errorMessage: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 12,
    textAlign: 'center',
    color: '#EC3337',
  },
  table: {
    height: 'calc(100vh - 260px)', // 'calc(100vh - 52px - 52px - 230px)',
  },
  textField: {
    fontFamily: 'Roboto',
    fontSize: 14,
    color: '#647886',
    height: 40,
    width: '100%',
  },
  saveButton: {
    color: 'white',
    marginBottom: 15,
    marginTop: 15,
    width: '100%',
    minWidth: 200,
  },
  labelTypeSelector: {
    color: '#647886',
    fontFamily: 'Barlow Condensed',
    fontWeight: 600,
    fontSize: 18,
    textTransform: 'uppercase',
  },
  fieldRoot: {
    height: 40,
    width: 200,
  },
  scrollbars: {
    border: '1px solid #CDCDCD',
    borderRadius: '0 0 4 4',
    boxSizing: 'border-box',
  },
  tabsIndicator: {
    backgroundColor: 'transparent',
  },
  tabRoot: {
    minWidth: 72,
    marginRight: 5,
    fontFamily: 'Barlow Condensed',
    fontWeight: 600,
    fontSize: 18,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    '&:hover': {
      color: '#3D5465',
    },
    color: 'white',
    backgroundColor: '#CDCDCD',
  },
  tabSelected: {
    color: '#647886',
    backgroundColor: 'white',
    fontWeight: 600,
    borderBottom: '2px solid #F2AA00',
  },
  noBadge: {
    display: 'flex',
    flexDirection: 'column',
  },
  badge: {
    display: 'flex',
    flexDirection: 'column',
    '& .MuiBadge-badge': {
      width: 25,
      height: 25,
      padding: '5px 0px 0px 15px',
      top: 4,
      right: 4,
      borderRadius: 20,
      backgroundColor: '#FFC300',
      color: '#111110',
    },
  },
  badgeDisabled: {
    '& .MuiBadge-badge': {
      backgroundColor: '#B3B3B3',
      padding: '15px 0px 0px 20px',
      '& .MuiSvgIcon-root': {
        fontSize: 35,
      },
    },
  },
};

export default styles;

const styles = {
  scrollbars: {
    border: '1px solid #CDCDCD',
    borderRadius: '0 0 4 4',
    boxSizing: 'border-box',
  },
  labelTypeSelector: {
    color: '#647886',
    fontFamily: 'Barlow Condensed',
    fontWeight: 600,
    fontSize: 18,
    textTransform: 'uppercase',
    marginRight: 10,
  },
};

export default styles;

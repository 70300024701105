import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import UnitOperationTab from './UnitOperationTab';
import PF2MTabs from '~/components/PF2MTabs';
import { setAlertDialog } from '~/store/manager/actions';
import InputUnitTab from './InputUnitTab';

const Operation = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  // eslint-disable-next-line max-len
  const isUnitOperationsDirty = useSelector(state => state.underground.isUnitOperationsDirty);
  const isInputUnitsDirty = useSelector(state => state.underground.isInputUnitsDirty);

  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const tabs = [
    // { key: 0, label: translate('common:UnitOperationGroup') },
    { key: 0, label: translate('common:UnitOperation') },
    { key: 1, label: translate('common:InputUnit') },
  ];

  const handleChange = (event, value) => {
    let blockChange = false;

    switch (value) {
      case 1:
        blockChange = isUnitOperationsDirty;
        break;
      default:
        blockChange = isInputUnitsDirty;
        break;
    }

    if (blockChange) {
      event.preventDefault();
      dispatch(setAlertDialog());
    } else {
      setSelectedTab(value);
    }
  };

  return (
    <>
      <PF2MTabs
        value={selectedTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        {tabs}
      </PF2MTabs>
      {selectedTab === 0 ? (<UnitOperationTab />) : null}
      {selectedTab === 1 ? (<InputUnitTab />) : null}
      {/* {selectedTab === 0 ? (<UnitOperationGroupTab />) : null} */}
    </>
  );
};

export default withStyles(styles)(Operation);

import React, { useState, useEffect } from 'react';
import { withStyles, Modal } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import styles from '../styles';
import Footer from '~/pages/Manager/Footer';
import PF2MTable from '~/components/PF2MTable';
import PF2MButton from '~/components/PF2MButton';
import PF2MActionConfirmation from '~/components/PF2MActionConfirmation';
import { useTranslation } from 'react-i18next';
import Header from './Header';
import {
  getWebUsers,
  updateWebUsers,
  resetWebUsers,
  addWebUser,
  setWebUsersValues,
  resetWebUserPassword,
} from '~/store/manager/actions';
import PF2MAutocomplete from '~/components/PF2MAutocomplete';
import { getWebModules } from '~/store/admin/actions';

const WebUsersTab = ({
  classes,
  webUsers,
  getWebUsers: getAll,
  setWebUsersValues: setValues,
  isWebUsersDirty: isDirty,
  // eslint-disable-next-line no-unused-vars
  modules,
  user,
}) => {
  const [webUsersLoaded, setWebUsersLoaded] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [passwordUser, setPasswordUser] = useState({});
  const { t: translate } = useTranslation();

  const webModules = useSelector(state => state.admin.webModules);

  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchWebUsers() {
      await getAll();
      dispatch(getWebModules());
    }
    if (!webUsersLoaded) {
      fetchWebUsers();
      setWebUsersLoaded(true);
    }
  // eslint-disable-next-line
  }, [webUsersLoaded, getAll]);

  let LEVELS = [
    { id: 2, name: 'Admin Local' },
    { id: 3, name: 'Normal User' },
    { id: 4, name: 'Guest User' },
    { id: 5, name: 'No Access' },
  ];
  if (user.level === 1) {
    LEVELS = [{ id: 1, name: 'Admin Fast2Mine' }, ...LEVELS];
  }

  const renderModules = (row) => {
    const options = user.level === 1
      ? webModules.map(e => ({ value: e.name, label: translate(`common:${e.caption}`) }))
      : modules.map(e => ({ value: e.module_value, label: translate(`common:${e.module_name}`) }));

    return (
      <PF2MAutocomplete
        options={options}
        onChange={(e, v) => {
          setValues(row, 'modules', [...v.map(r => r.value)]);
        }}
        value={options.filter(e => row.modules.includes(e.value))}
        limitTags={1}
      />
    );
  };

  const reportsLevelManagement = webUsers.filter(x => x.reports_level === 1).length > 0;

  const columns = [
    { field: 'email', title: 'E-mail', editable: true },
    { field: 'username', title: translate('common:User'), editable: true },
    { field: 'active', title: 'STATUS', editable: true },
    {
      field: 'level',
      title: translate(
        `common:${ reportsLevelManagement ? 'PlatformLevel' : 'Level'}`,
      ),
      selectItems: LEVELS.filter(x => x.id < 4),
      editable: true,
      key: 'id',
    },
    {
      field: 'reports_level',
      title: translate('common:ReportsLevel'),
      selectItems: LEVELS,
      editable: true,
      key: 'id',
    },
    {
      field: 'modules',
      title: translate(
        `common:${ reportsLevelManagement ? 'PlatformModules' : 'Modules'}`,
      ),
      renderCustomComponent: row => renderModules(row),
      width: 740,
    },
    {
      field: 'password',
      title: '',
      renderCustomComponent: row => (row.new_password !== undefined
        ? <p>{row.new_password}</p>
        : (
          <PF2MButton
            disabled={row.id === undefined}
            onClick={() => { setModalOpen(true); setPasswordUser(row); }}
          >
            {translate('common:ResetPassword')}
          </PF2MButton>
        )),
    },
  ];

  return (
    <div className={classes.tabContainer}>
      <Header
        add={e => dispatch(addWebUser(e))}
        levels={LEVELS}
        style={{ borderBottom: 15 }}
      />
      <div style={{ backgroundColor: 'white', maxWidth: 1700 }}>
        <PF2MTable
          style={{ width: '100%', height: '100%' }}
          data={webUsers.filter(e => user.level === 1 || e.level !== 1)
            .sort(e => (e.id ? e.id : -1))}
          columns={
            reportsLevelManagement
              ? columns
              : columns.filter(x => x.field !== 'reports_level')
          }
          updateItem={(e, f, g) => { setValues(e, f, g); }}
        />
      </div>
      <Footer
        isDirty={isDirty}
        discard={() => {
          dispatch(resetWebUsers());
        }}
        sendData={() => dispatch(updateWebUsers(webUsers))}
      />
      <Modal
        open={modalOpen}
        onBackdropClick={() => setModalOpen(false)}
      >
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
        >
          <PF2MActionConfirmation
            onSuccess={() => {
              dispatch(resetWebUserPassword(passwordUser, { user_id: passwordUser.id }));
              setModalOpen(false);
            }}
            onCancel={() => setModalOpen(false)}
            confirmationText={`${translate('common:ResetPasswordDisclaimer')}`}
          />
        </div>
      </Modal>
    </div>
  );
};

WebUsersTab.propTypes = {
  classes: PropTypes.object.isRequired,
  webUsers: PropTypes.array,
  isWebUsersDirty: PropTypes.bool,
  getWebUsers: PropTypes.func,
  setWebUsersValues: PropTypes.func,
  modules: PropTypes.array,
  user: PropTypes.object,
};

WebUsersTab.defaultProps = {
  webUsers: [],
  getWebUsers: () => { },
  setWebUsersValues: () => { },
  isWebUsersDirty: false,
  modules: [],
  user: {},
};

const mapStateToProps = state => ({
  webUsers: state.manager.webUsers,
  isWebUsersDirty: state.manager.isWebUsersDirty,
  // eslint-disable-next-line no-underscore-dangle
  modules: state.auth.user._roles,
  user: state.auth.user,
});

const mapDispatchToProps = {
  getWebUsers,
  setWebUsersValues,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(WebUsersTab));

import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import Footer from '~/pages/Manager/Footer';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import {
  setUndergroundTurnGroupValue,
  resetUndergroundTurnGroup,
  updateUndergroundTurnGroup,
} from '~/store/underground/actions';
import { getTurnsGroups } from '~/store/manager/actions';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';

const TurnTab = ({ classes }) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [enableOpenPit, setEnableOpenPit] = useState(false);
  const turnsGroups = useSelector(state => state.manager.turnsGroups);
  const turnGroup = useSelector(state => state.underground.turnGroup);
  const config = useSelector(state => state.auth.user.config);
  const isTurnGroupDirty = useSelector(state => state.underground.isTurnGroupDirty);
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      dispatch(getTurnsGroups());
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch]);

  useEffect(() => {
    if (config) {
      setEnableOpenPit(config.find(c => c.key === 'enable_openpit' && c.value === '1'));
    }
  }, [config]);

  return (
    <div className={classes.tabContainer}>
      <div style={{
        flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center',
      }}
      >
        <div className={classes.containerTypeSelector}>
          <div>
            <span className={classes.labelTypeSelector}>
              { enableOpenPit
                ? `${translate('common:ShiftGroupConnectedToOpenPitModule')}:`
                : `${translate('common:ShiftGroupConnectedToUndergroundModule')}:`
              }
            </span>
            <PF2MSearchSelectOutlined
              value={turnGroup}
              onChange={e => dispatch(setUndergroundTurnGroupValue(e.target.value))}
            >
              {[{ value: 0, label: translate('common:NotConnected') }]
                .concat(...turnsGroups
                  .map(e => ({ value: e.id, label: e.name })))}
            </PF2MSearchSelectOutlined>
          </div>
        </div>
      </div>
      <Footer
        isDirty={isTurnGroupDirty}
        discard={() => dispatch(resetUndergroundTurnGroup())}
        sendData={() => dispatch(updateUndergroundTurnGroup(turnGroup))}
      />
      <PF2MAlertDialog
        hasCancel={false}
        confirmText={translate('common:Understood')}
        description={translate('validation:YouHaveUnsavedData')}
        open={isAlertOpen}
        onConfirm={() => setIsAlertOpen(false)}
      />
    </div>
  );
};

TurnTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

TurnTab.defaultProps = {};

export default withStyles(styles)(TurnTab);

export const SET_PROJECT_GROUPS_LIST = 'SET_PROJECT_GROUPS_LIST';
export const SET_PROJECT_GROUP_VALUE = 'SET_PROJECT_GROUP_VALUE';
export const RESET_PROJECT_GROUPS = 'RESET_PROJECT_GROUPS';
export const ADD_PROJECT_GROUP = 'ADD_PROJECT_GROUP';

export const SET_PROJECT_SUBELEMENTS_LIST = 'SET_PROJECT_SUBELEMENTS_LIST';
export const SET_PROJECT_SUBELEMENT_VALUE = 'SET_PROJECT_SUBELEMENT_VALUE';
export const RESET_PROJECT_SUBELEMENTS = 'RESET_PROJECT_SUBELEMENTS';

export const SET_CODE_TYPES_UG_LIST = 'SET_CODE_TYPES_UG_LIST';
export const SET_CODE_TYPE_UG_VALUE = 'SET_CODE_TYPE_UG_VALUE';
export const RESET_CODE_TYPES_UG = 'RESET_CODE_TYPES_UG';
export const ADD_CODE_TYPE_UG = 'ADD_CODE_TYPE_UG';

export const SET_CODE_GROUPS_UG_LIST = 'SET_CODE_GROUPS_UG_LIST';
export const SET_CODE_GROUP_UG_VALUE = 'SET_CODE_GROUP_UG_VALUE';
export const RESET_CODE_GROUPS_UG = 'RESET_CODE_GROUPS_UG';
export const ADD_CODE_GROUP_UG = 'ADD_CODE_GROUP_UG';

export const SET_CODES_UG_LIST = 'SET_CODES_UG_LIST';
export const SET_CODE_UG_VALUE = 'SET_CODE_UG_VALUE';
export const RESET_CODES_UG = 'RESET_CODES_UG';
export const ADD_CODE_UG = 'ADD_CODE_UG';

export const SET_PRIORITIES_LIST = 'SET_PRIORITIES_LIST';
export const SET_PRIORITY_VALUE = 'SET_PRIORITY_VALUE';
export const RESET_PRIORITIES = 'RESET_PRIORITIES';

export const SET_MONTHLY_ADVANCE_LIST = 'SET_MONTHLY_ADVANCE_LIST';
export const SET_MONTHLY_ADVANCE_VALUE = 'SET_MONTHLY_ADVANCE_VALUE';
export const RESET_MONTHLY_ADVANCE = 'RESET_MONTHLY_ADVANCE';
export const ADD_MONTHLY_ADVANCE = 'ADD_MONTHLY_ADVANCE';
export const SET_UNIT_OPERATION_GROUPS_LIST = 'SET_UNIT_OPERATION_GROUPS_LIST';
export const SET_UNIT_OPERATION_GROUP_VALUES = 'SET_UNIT_OPERATION_GROUP_VALUES';
export const RESET_UNIT_OPERATION_GROUPS = 'RESET_UNIT_OPERATION_GROUPS';
export const ADD_UNIT_OPERATION_GROUP = 'ADD_UNIT_OPERATION_GROUP';

export const SET_UNIT_OPERATIONS_LIST = 'SET_UNIT_OPERATIONS_LIST';
export const SET_UNIT_OPERATION_VALUES = 'SET_UNIT_OPERATION_VALUES';
export const RESET_UNIT_OPERATIONS = 'RESET_UNIT_OPERATIONS';
export const ADD_UNIT_OPERATION = 'ADD_UNIT_OPERATION';

export const SET_UNDERGROUND_DAILY_PARTS_LIST = 'SET_UNDERGROUND_DAILY_PARTS_LIST';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_OPERATION_CONTROLS_LIST = 'SET_OPERATIONAL_CONTROLS_LIST';
export const SET_OPERATION_CONTROL_ITEM = 'SET_OPERATION_CONTROL_ITEM';
export const SET_ORIGINAL_OPERATION_CONTROL_ITEM = 'SET_ORIGINAL_OPERATION_CONTROL_ITEM';
export const RESTORE_OPERATION_CONTROL_ITEM = 'RESTORE_OPERATION_CONTROL_ITEM';
export const SET_UNDERGROUND_OPERATION_CONTROL_SAVED = 'SET_UNDERGROUND_OPERATION_CONTROL_SAVED';

export const RESTORE_OPERATION_CONTROL_FILTER = 'RESTORE_OPERATION_CONTROL_FILTER';
export const SET_OPERATION_CONTROL_FILTER = 'SET_UNDERGROUND_OPERATION_CONTROL_FILTER';

export const SET_UNDERGROUND_TURN_GROUP = 'SET_UNDERGROUND_TURN_GROUP';
export const SET_UNDERGROUND_TURN_GROUP_VALUE = 'SET_UNDERGROUND_TURN_GROUP_VALUE';
export const RESET_UNDERGROUND_TURN_GROUP = 'RESET_UNDERGROUND_TURN_GROUP';

export const SET_INPUT_UNITS_LIST = 'SET_INPUT_UNITS_LIST';
export const SET_INPUT_UNIT_VALUES = 'SET_INPUT_UNIT_VALUES';
export const ADD_INPUT_UNIT = 'ADD_INPUT_UNIT';
export const RESET_INPUT_UNITS = 'RESET_INPUT_UNITS';

export const SET_UNDERGROUND_CICLE_ADVANCE = 'SET_UNDERGROUND_CICLE_ADVANCE';
export const ADD_UNDERGROUND_CICLE_ADVANCE = 'ADD_UNDERGROUND_CICLE_ADVANCE';
export const DELETE_UNDERGROUND_CICLE_ADVANCE = 'DELETE_UNDERGROUND_CICLE_ADVANCE';
export const CHANGE_UNDERGROUND_CICLE_ADVANCE = 'CHANGE_UNDERGROUND_CICLE_ADVANCE';
export const RESTORE_CICLE_ADVANCE = 'RESTORE_CICLE_ADVANCE';

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import PF2MTable from '~/components/PF2MTable';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import PF2MButton from '~/components/PF2MButton';
import { useTranslation } from 'react-i18next';
import {
  getTabletControlSync, setValuesControlSync, resetControlSync, postTabletControlSync,
} from '~/store/admin/actions';


const ControlSyncModal = ({ tabletId, onSend }) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const controlSync = useSelector(state => state.admin.tabletControlSync);
  const isTabletControlSyncDirty = useSelector(state => state.admin.istabletControlSyncDirty);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getTabletControlSync(tabletId));
      setDataLoaded(true);
    };
    if (!dataLoaded) {
      fetchData();
    }
  }, [tabletId, dataLoaded, dispatch]);

  const columns = [
    {
      field: 'db_table',
      title: translate('common:Table').toUpperCase(),
      width: '42%',
      editable: false,
    },

    {
      field: 'last_usn',
      title: translate('common:UpdateTimestamp').toUpperCase(),
      width: '42%',
      editable: true,
      type: 'number',
    },
  ];

  return (
    <div style={{
      width: '80%', padding: 50, zIndex: 100, backgroundColor: 'white', margin: 'auto', marginTop: 30,
    }}
    >
      <PF2MTable
        data={controlSync}
        columns={columns}
        updateItem={(item, key, value) => {
          if (item[key] !== parseInt(value, 10)) {
            dispatch(setValuesControlSync(item, key, parseInt(value, 10)));
          }
        }}
      />
      <div>
        <PF2MButton
          color="primary"
          onClick={() => { dispatch(resetControlSync()); }}
          disabled={!isTabletControlSyncDirty}
        >
          <DeleteOutline />
          {translate('common:DiscardChanges')}
        </PF2MButton>
        <PF2MButton
          onClick={() => {
            dispatch(postTabletControlSync(controlSync));
            setDataLoaded(false);
            onSend();
          }}
          disabled={!isTabletControlSyncDirty}
        >
          {translate('common:UpdateTablets')}
        </PF2MButton>
      </div>
    </div>
  );
};

ControlSyncModal.propTypes = {
  tabletId: PropTypes.string.isRequired,
  onSend: PropTypes.func.isRequired,
};

export default ControlSyncModal;

/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import {
  withStyles, FormControl, FormLabel, FormGroup,
  Checkbox, ListItemText,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Footer from '~/pages/Manager/Footer';
import { useTranslation } from 'react-i18next';
import {
  updateElevationAssociations,
  restoreElevationAssociations,
  getElevationGroups,
  createElevationAssociation,
  getElevationAssociations,
  toggleElevationAssociation,
  selectAllElevations,
  deselectAllElevations,
} from '~/store/lineup/actions';
import styles from '../styles';
import { getElements } from '~/store/manager/actions';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import PF2MScrollbar from '~/components/PF2MScrollbar';

const ElementLimitsTab = ({
  classes,
}) => {
  const { t: translate } = useTranslation();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const dispatch = useDispatch();
  const selectedLineup = useSelector(state => state.lineup.selectedLineup);
  const [selectedElement, setSelectedElement] = useState(0);
  const [selectedElevationGroup, setSelectedElevationGroup] = useState(0);
  const elements = useSelector(state => state.manager.elements);
  const elevationGroups = useSelector(state => state.lineup.elevationGroups);
  const elevationAssociations = useSelector(state => state.lineup.elevationAssociations);
  const isElevationAssociationsDirty = useSelector(
    state => state.lineup.isElevationAssociationsDirty,
  );
  const selectedAssociation = useSelector(state => state.lineup.elevationAssociations
    .find(r => r.lineup_id === selectedLineup?.id
      && r.elevation_group_id === selectedElevationGroup
      && r.element_id === selectedElement));

  useEffect(() => {
    async function fetchData() {
      dispatch(getElevationGroups());
      dispatch(getElements());
      dispatch(getElevationAssociations());
    }

    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch]);

  const renderSelectAll = () => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Checkbox
        checked={allSelected}
        color="primary"
        name="all"
        onChange={() => {
          if (!selectedAssociation) return;
          if (allSelected) {
            dispatch(deselectAllElevations(
              selectedAssociation.element_id,
              selectedAssociation.elevation_group_id,
              selectedAssociation.lineup_id,
            ));
          } else {
            dispatch(selectAllElevations(
              selectedAssociation.element_id,
              selectedAssociation.elevation_group_id,
              selectedAssociation.lineup_id,
            ));
          }

          setAllSelected(!allSelected);
        }}
      />
      <FormLabel style={{ padding: 0, margin: 0 }}>
        {translate('common:SelectAll')}
      </FormLabel>
    </div>
  );

  const renderItems = (items = []) => [{ value: 0, label: translate('common:Select') }]
    .concat(...items
      .map(e => ({ value: e.id, label: e.name })));

  const renderFilters = () => (
    <div style={{
      display: 'grid',
      gridTemplateColumns: 'repeat(5, 200px)',
      gridColumnGap: 10,
      gridRowGap: 10,
      margin: '10px 0',
    }}
    >
      <FormControl>
        <FormLabel className={classes.formLabel}>
          {translate('common:Element')}
        </FormLabel>
        <FormGroup>
          <PF2MSearchSelectOutlined
            value={selectedElement}
            onChange={(e) => {
              setSelectedElement(e.target.value);
              setSelectedElevationGroup(0);
              setAllSelected(false);
            }}
            className={classes.fieldSelect}
          >
            {renderItems(elements)}
          </PF2MSearchSelectOutlined>
        </FormGroup>
      </FormControl>
      <FormControl>
        <FormLabel className={classes.formLabel}>
          {translate('common:ElevationGroup')}
        </FormLabel>
        <FormGroup>
          <PF2MSearchSelectOutlined
            disabled={!selectedElement}
            value={selectedElevationGroup}
            onChange={(e) => {
              setSelectedElevationGroup(e.target.value);
              if (e.target.value) {
                dispatch(createElevationAssociation(
                  selectedElement, e.target.value, selectedLineup.id,
                ));
              }
            }}
            className={classes.fieldSelect}
          >
            {renderItems(elevationGroups)}
          </PF2MSearchSelectOutlined>
        </FormGroup>
      </FormControl>
    </div>
  );

  const renderElevations = (elevationGroup) => {
    if (!elevationGroup) return null;
    const elevationCheckboxes = [];
    const {
      initial_elevation: initialElevation,
      final_elevation: finalElevation,
      interval,
    } = elevationGroup;

    for (let i = initialElevation; i <= finalElevation; i += interval) {
      elevationCheckboxes.push(
        <div key={i} style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            checked={!selectedAssociation?.excluded_elevations?.includes(i)}
            color="primary"
            name={i.toString()}
            onChange={() => dispatch(toggleElevationAssociation(
              selectedElement, selectedElevationGroup, selectedLineup, i,
            ))}
          />
          <ListItemText secondary={i.toString()} style={{ padding: 0 }} />
        </div>,
      );
    }

    return elevationCheckboxes;
  };

  return (
    <div className={classes.tabContainer}>
      {renderFilters()}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span className={classes.label}>
          {`${translate('common:ElevationsAssociatedToTheElement')}:`}
        </span>
        {renderSelectAll()}
      </div>
      <PF2MScrollbar className={classes.scrollbars} style={{ height: 'auto' }}>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(8, 1fr)' }}>
          {renderElevations(elevationGroups.find(r => r.id === selectedElevationGroup))}
        </div>
      </PF2MScrollbar>
      <Footer
        isDirty={isElevationAssociationsDirty}
        sendData={() => dispatch(updateElevationAssociations(
          elevationAssociations,
          selectedElement,
          selectedLineup,
        ))}
        discard={() => dispatch(restoreElevationAssociations())}
      />
    </div>
  );
};

ElementLimitsTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

ElementLimitsTab.defaultProps = {
};

export default withStyles(styles)(ElementLimitsTab);

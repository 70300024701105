import React from 'react';
import Switch from '@material-ui/core/Switch';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import styles from './styles';

const PF2MSwitch = ({
  classes, checked, disabled, onChange, rest,
}) => (
  <Switch
    className={classes.switch}
    checked={checked}
    disabled={disabled}
    onChange={onChange}
    {...rest}
  />
);

PF2MSwitch.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  rest: PropTypes.object,
};

PF2MSwitch.defaultProps = {
  onChange: () => null,
  checked: false,
  disabled: false,
  rest: {},
};

export default withStyles(styles)(PF2MSwitch);

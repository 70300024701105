import React, { useState, useEffect } from 'react';
import { withStyles, Modal } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styles from './styles';
import SearchHeader from '../SearchHeader';
import UndergroundReport from '../UndergroundReport';
import SearchForm from '../SearchForm';
import {
  restoreOriginalUnderground,
} from '~/store/dailyparts/actions';

const DailyParts = ({ classes }) => {
  const [modalOpen, setModalOpen] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => () => {
    dispatch(restoreOriginalUnderground(true));
  }, [dispatch]);

  return (
    <div className={classes.container}>
      <SearchHeader handleModal={setModalOpen} />
      <UndergroundReport />
      <Modal
        open={modalOpen}
        onBackdropClick={() => setModalOpen(false)}
      >
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        >
          <SearchForm onSearch={() => setModalOpen(false)} onClose={() => setModalOpen(false)} />
        </div>
      </Modal>
    </div>
  );
};

DailyParts.propTypes = {
  classes: PropTypes.object.isRequired,
};
DailyParts.defaultProps = {};

export default withStyles(styles)(DailyParts);

import React from 'react';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import 'moment/locale/pt-br';

// moment.locale(i18n.t('date:Locale'));

const PF2MDatePickerOutlined = ({
  field,
  form,
  ...other
}) => {
  const { t: translate } = useTranslation();
  const currentError = form && form.errors && field && form.errors[field.name];
  return (
    <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
      <KeyboardDatePicker
        allowKeyboardControl
        clearable
        format={translate('date:DateFormat')}
        disableFuture
        name={field.name}
        value={field.value}
        helperText={currentError}
        error={Boolean(currentError)}
        onChange={date => form.setFieldValue(field.name, date, true)}
        autoOk
        inputVariant="outlined"
        inputProps={{
          style: {
            minWidth: 100,
            fontFamily: 'Roboto',
            padding: '12px 14px',
            fontWeight: 300,
            fontSize: 14,
            color: '#647886',
          },
        }}
        {...other}
      />
    </MuiPickersUtilsProvider>
  );
};


PF2MDatePickerOutlined.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
};

PF2MDatePickerOutlined.defaultProps = {
  field: {},
  form: {},
};

export default withStyles({})(PF2MDatePickerOutlined);

import React, { useState } from 'react';
import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
} from '@material-ui/core';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import PropTypes from 'prop-types';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import PF2MSortButton from '~/components/PF2MSortButton';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import PF2MScrollbar from '~/components/PF2MScrollbar';
import {
  searchShaftList,
  deleteShaftItem,
  restoreShaftItem,
  setShaftValues,
  restoreOriginalShaft,
  createShaftItem,
  updateShaftList,
  exportShaftReport,
} from '~/store/dailyparts/actions';
import { dailyPartsGridHeight } from '~/utils';
import { useTranslation } from 'react-i18next';
import Header from '../Header';
import styles from '../styles';
import ListRow from './ListRow';

const SHAFT_EQUIP_TYPES = [20];

const ShaftList = ({ classes }) => {
  const { t: translate } = useTranslation();

  const titles = [
    { label: translate('common:InitialDate'), field: 'start_date' },
    { label: translate('common:FinalDate'), field: 'end_date' },
    { label: translate('common:InitialHour'), field: 'start_time' },
    { label: translate('common:FinalHour'), field: 'end_time' },
    { label: translate('common:Duration'), field: 'duration' },
    { label: translate('common:Shift'), field: 'turn_id' },
    { label: translate('common:Team'), field: 'team_id' },
    { label: translate('common:OperationType'), field: 'id_operation_type' },
    { label: translate('common:OperatorGroup'), field: 'operator_group_id' },
    { label: translate('common:Operator'), field: 'operator_id' },
    { label: translate('common:Material'), field: 'material_id' },
    { label: translate('common:EquipmentGroup'), field: 'equipament_groups_id' },
    { label: translate('common:Equipment'), field: 'group_equipament_links_id' },
    { label: translate('common:Origin'), field: 'id_mine' },
    { label: translate('common:OriginPoint'), field: 'id_mine_origin' },
    { label: translate('common:LoadManager'), field: 'load_manager' },
    { label: translate('common:LoadTime'), field: 'load_time' },
    { label: translate('common:IdleTime'), field: 'idle_time' },
    { label: translate('common:CodeTime'), field: 'code_time' },
    { label: translate('common:EntryType'), field: 'exception_type' },
  ];

  const shaftList = useSelector(state => state.dailyParts.shaftList);
  const filters = useSelector(state => state.dailyParts.filters);
  const page = useSelector(state => state.dailyParts.page);
  const limit = useSelector(state => state.dailyParts.limit);
  const { field, order } = useSelector(state => state.dailyParts.shaftSortBy);
  const turns = useSelector(state => state.manager.turns);
  const teams = useSelector(state => state.manager.teams);
  const shaftGroups = useSelector(
    state => state.manager.equipmentsGroups
      .filter(r => SHAFT_EQUIP_TYPES.includes(r.id_equipament)), shallowEqual,
  );
  const shaftEquips = useSelector(
    state => state.manager.equipments
      .filter(r => SHAFT_EQUIP_TYPES.includes(r.id_equip)), shallowEqual,
  );
  const elements = useSelector(state => state.manager.elements);
  const subElements = useSelector(state => state.manager.subElements);
  const operatorsGroups = useSelector(state => state.manager.operatorsGroups
    .filter(o => o.name), shallowEqual);
  const operators = useSelector(state => state.manager.operators.filter(o => o.name), shallowEqual);
  const materials = useSelector(state => state.manager.materials);
  const exceptionTypes = useSelector(state => state.manager.exceptionTypes);
  const operationTypes = useSelector(state => state.manager.operationTypes);
  const isShaftListDirty = useSelector(
    state => state.dailyParts.isShaftListDirty,
  );

  const [sortBy, setSortBy] = useState({ field, order });
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const dispatch = useDispatch();

  const handleSort = (title) => {
    if (shaftList.some(t => t.isDirty)) {
      setIsAlertOpen(true);
    } else {
      const newOrder = (sortBy.order === 'asc' && sortBy.field === title.field) ? 'desc' : 'asc';
      const newSortBy = { field: title.field, order: newOrder };
      setSortBy(newSortBy);
      dispatch(searchShaftList(filters, page, limit, newSortBy, true));
    }
  };

  const renderTableTitle = (title, key) => (
    <TableCell
      align="center"
      className={classes.tableCell}
      key={key}
      style={{
        position: 'sticky',
        top: 0,
        backgroundColor: 'white',
        zIndex: 1,
      }}
    >
      <PF2MSortButton
        label={title.label}
        field={title.field}
        sortField={sortBy.field}
        sortOrder={sortBy.order}
        onClick={() => handleSort(title)}
      />
    </TableCell>
  );

  const renderTableHeader = () => {
    const titleList = titles;
    return titleList.map((t, i) => renderTableTitle(t, `${i}_${t}`));
  };

  const renderRows = () => shaftList
    .map(d => (
      <ListRow
        key={d.id}
        data={d}
        onDelete={data => dispatch(deleteShaftItem(data))}
        onRestore={data => dispatch(restoreShaftItem(data))}
        onEdit={(item, name, value) => dispatch(setShaftValues(item, name, value))}
        classes={classes}
        turns={turns}
        filters={filters}
        teams={teams}
        equipGroups={shaftGroups
          .filter(e => e.id_equipament === filters.equipmentType)}
        equipments={shaftEquips
          .filter(e => e.id_equip === filters.equipmentType)}
        elements={elements}
        subElements={subElements}
        materials={materials}
        operatorGroups={operatorsGroups}
        operators={operators}
        exceptionTypes={exceptionTypes}
        operationTypes={operationTypes.filter(r => r.id_equipment_type === 20)}
      />
    ));

  const renderLoadMore = () => {
    if (shaftList.length < limit) return null;
    if (shaftList.filter(d => d.id > 0).length < 1) { return null; }
    return (
      <div style={{
        backgroundColor: '#F9F9F9',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 10,
        paddingBottom: 10,
      }}
      >
        <Button
          onClick={() => {
            dispatch(searchShaftList(filters, (page + 1), limit, sortBy, false));
          }}
        >
          <ArrowDownIcon />
          <Typography
            variant="h5"
          >

            {translate('common:SeeMore')}
          </Typography>
        </Button>
      </div>
    );
  };

  return (
    <>
      <Header
        updateData={(data, filter) => dispatch(updateShaftList(data, filter))}
        exportData={parameters => dispatch(exportShaftReport(parameters))}
        restoreData={() => dispatch(restoreOriginalShaft())}
        createData={filter => dispatch(createShaftItem(filter))}
        data={shaftList}
        filters={filters}
        isDirty={isShaftListDirty}
      />
      {shaftList.length > 0 ? (
        <PF2MScrollbar style={{ backgroundColor: 'white', height: dailyPartsGridHeight }}>
          <>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {renderTableHeader()}
                </TableRow>
              </TableHead>
              <TableBody>
                {renderRows()}
              </TableBody>
            </Table>
            {renderLoadMore()}
            <PF2MAlertDialog
              hasCancel={false}
              confirmText={translate('common:Understood')}
              description={translate('validation:YouHaveUnsavedData')}
              open={isAlertOpen}
              onConfirm={() => setIsAlertOpen(false)}
            />
          </>
        </PF2MScrollbar>
      ) : (
        <div
          className="container"
          style={{
            backgroundColor: 'white',
            padding: 20,
          }}
        >
          <span>{translate('common:NoEntries')}</span>
        </div>
      )}
    </>
  );
};

ShaftList.propTypes = {
  classes: PropTypes.object.isRequired,
};

ShaftList.defaultProps = {};

export default withStyles(styles)(ShaftList);

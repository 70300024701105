import React, { useState } from 'react';
import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
} from '@material-ui/core';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import PropTypes from 'prop-types';
import {
  useSelector, useDispatch,
  shallowEqual,
} from 'react-redux';
import PF2MSortButton from '~/components/PF2MSortButton';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import PF2MScrollbar from '~/components/PF2MScrollbar';
import {
  searchInfrastructureList,
  deleteInfrastructureItem,
  restoreInfrastructureItem,
  setInfrastructureValues,
  restoreOriginalInfrastructure,
  createInfrastructureItem,
  updateInfrastructureList,
  exportInfrastructureReport,
} from '~/store/dailyparts/actions';
import { dailyPartsGridHeight } from '~/utils';
import { useTranslation } from 'react-i18next';
import Header from '../Header';
import styles from '../styles';
import ListRow from './ListRow';

const INFRASTRUCTURE_EQUIP_TYPES = [21, 22, 23];
const MIXER = 21;
const PASTEFILL = 23;

const InfrastructureList = ({ classes }) => {
  const { t: translate } = useTranslation();

  const titles = [
    { label: translate('common:InitialDate') }, // field: 'start_date' },
    { label: translate('common:FinalDate') }, // field: 'end_date' },
    { label: translate('common:InitialHour'), field: 'start_time' },
    { label: translate('common:FinalHour'), field: 'end_time' },
    { label: translate('common:Duration'), field: 'duration' },
    { label: translate('common:Shift'), field: 'turn_id' },
    { label: translate('common:Team'), field: 'team_id' },
    { label: translate('common:OperatorGroup'), field: 'oeprator_group_id' },
    { label: translate('common:Operator'), field: 'operator_id' },
    { label: translate('common:EquipmentGroup'), field: 'equipament_groups_id' },
    { label: translate('common:Equipment'), field: 'group_equipament_links_id' },
    { label: translate('common:Material'), field: 'material_id' },
    { label: translate('common:LoadManager'), field: 'load_manager' },
    { label: translate('common:LoadTime'), field: 'load_time' },
    { label: translate('common:IdleTime'), field: 'idle_time' },
    { label: translate('common:CodeTime'), field: 'code_time' },
    { label: translate('common:Latitude'), field: 'load_lat' },
    { label: translate('common:Longitude'), field: 'load_lon' },
    { label: translate('common:Altitude'), field: 'load_alt' },
    { label: translate('common:EntryType'), field: 'exception_type' },
  ];

  const mixerSpecificTitles = [
    { label: translate('common:Origin'), field: 'id_mine' },
    { label: translate('common:OriginPoint'), field: 'id_mine_origin' },
  ];

  const pastefillSpecificTitles = [
    { label: translate('common:Destination'), field: 'id_destination' },
    { label: translate('common:DestinationPoint'), field: 'id_destination_point' },
  ];

  const infrastructureList = useSelector(state => state.dailyParts.infrastructureList);
  const filters = useSelector(state => state.dailyParts.filters);
  const page = useSelector(state => state.dailyParts.page);
  const limit = useSelector(state => state.dailyParts.limit);
  const { field, order } = useSelector(state => state.dailyParts.infrastructureSortBy);
  const turns = useSelector(state => state.manager.turns);
  const teams = useSelector(state => state.manager.teams);
  const infrastructureGroups = useSelector(
    state => state.manager.equipmentsGroups
      .filter(r => INFRASTRUCTURE_EQUIP_TYPES.includes(r.id_equipament)), shallowEqual,
  );
  const infrastructureEquips = useSelector(
    state => state.manager.equipments
      .filter(r => INFRASTRUCTURE_EQUIP_TYPES.includes(r.id_equip)), shallowEqual,
  );
  const elements = useSelector(state => state.manager.elements);
  const subElements = useSelector(state => state.manager.subElements);
  const operatorsGroups = useSelector(state => state.manager.operatorsGroups
    .filter(o => o.name), shallowEqual);
  const operators = useSelector(state => state.manager.operators.filter(o => o.name), shallowEqual);
  const materials = useSelector(state => state.manager.materials);
  const exceptionTypes = useSelector(state => state.manager.exceptionTypes);
  const isInfrastructureListDirty = useSelector(
    state => state.dailyParts.isInfrastructureListDirty,
  );

  const [sortBy, setSortBy] = useState({ field, order });
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const dispatch = useDispatch();

  const handleSort = (title) => {
    if (infrastructureList.some(t => t.isDirty)) {
      setIsAlertOpen(true);
    } else {
      const newOrder = (sortBy.order === 'asc' && sortBy.field === title.field) ? 'desc' : 'asc';
      const newSortBy = { field: title.field, order: newOrder };
      setSortBy(newSortBy);
      dispatch(searchInfrastructureList(filters, page, limit, newSortBy, true));
    }
  };

  const renderTableTitle = (title, key) => (
    <TableCell
      align="center"
      className={classes.tableCell}
      key={key}
      style={{
        position: 'sticky',
        top: 0,
        backgroundColor: 'white',
        zIndex: 1,
      }}
    >
      <PF2MSortButton
        label={title.label}
        field={title.field}
        sortField={sortBy.field}
        sortOrder={sortBy.order}
        onClick={() => handleSort(title)}
      />
    </TableCell>
  );

  const renderTableHeader = () => {
    const titleList = titles;

    if (filters.equipmentType === MIXER) {
      titleList.splice(12, 0, ...mixerSpecificTitles);
    }

    if (filters.equipmentType === PASTEFILL) {
      titleList.splice(12, 0, ...pastefillSpecificTitles);
    }

    return titleList.map((t, i) => renderTableTitle(t, `${i}_${t}`));
  };

  const renderRows = () => infrastructureList
    .map(d => (
      <ListRow
        key={d.id}
        data={d}
        filters={filters}
        onDelete={data => dispatch(deleteInfrastructureItem(data))}
        onRestore={data => dispatch(restoreInfrastructureItem(data))}
        onEdit={(item, name, value) => dispatch(setInfrastructureValues(item, name, value))}
        classes={classes}
        turns={turns}
        teams={teams}
        equipGroups={infrastructureGroups
          .filter(e => e.id_equipament === filters.equipmentType)}
        equipments={infrastructureEquips
          .filter(e => e.id_equip === filters.equipmentType)}
        elements={elements}
        subElements={subElements}
        materials={materials}
        operatorGroups={operatorsGroups}
        operators={operators}
        exceptionTypes={exceptionTypes}
      />
    ));

  const renderLoadMore = () => {
    if (infrastructureList.length < limit) return null;
    if (infrastructureList.filter(d => d.id > 0).length < 1) { return null; }
    return (
      <div style={{
        backgroundColor: '#F9F9F9',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 10,
        paddingBottom: 10,
      }}
      >
        <Button
          onClick={() => {
            dispatch(searchInfrastructureList(filters, (page + 1), limit, sortBy, false));
          }}
        >
          <ArrowDownIcon />
          <Typography
            variant="h5"
          >

            {translate('common:SeeMore')}
          </Typography>
        </Button>
      </div>
    );
  };

  return (
    <>
      <Header
        updateData={(data, filter) => dispatch(updateInfrastructureList(data, filter))}
        exportData={parameters => dispatch(exportInfrastructureReport(parameters))}
        restoreData={() => dispatch(restoreOriginalInfrastructure())}
        createData={filter => dispatch(createInfrastructureItem(filter))}
        data={infrastructureList}
        filters={filters}
        isDirty={isInfrastructureListDirty}
      />
      {infrastructureList.length > 0 ? (
        <PF2MScrollbar style={{ backgroundColor: 'white', height: dailyPartsGridHeight }}>
          <>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {renderTableHeader()}
                </TableRow>
              </TableHead>
              <TableBody>
                {renderRows()}
              </TableBody>
            </Table>
            {renderLoadMore()}
            <PF2MAlertDialog
              hasCancel={false}
              confirmText={translate('common:Understood')}
              description={translate('validation:YouHaveUnsavedData')}
              open={isAlertOpen}
              onConfirm={() => setIsAlertOpen(false)}
            />
          </>
        </PF2MScrollbar>
      ) : (
        <div
          className="container"
          style={{
            backgroundColor: 'white',
            padding: 20,
          }}
        >
          <span>{translate('common:NoEntries')}</span>
        </div>
      )}
    </>
  );
};

InfrastructureList.propTypes = {
  classes: PropTypes.object.isRequired,
};

InfrastructureList.defaultProps = {};

export default withStyles(styles)(InfrastructureList);

import React, {
  useMemo, useState, useRef, useEffect,
} from 'react';
import {
  withStyles,
  FormControl,
  FormLabel,
  FormGroup,
  Typography,
  FormControlLabel,
  Switch,
  InputAdornment,
} from '@material-ui/core';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Add from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Formik, Form, Field,
} from 'formik';
import CreateIcon from '@material-ui/icons/Create';
import {
  Card, CardBody, CardHeader, CardIcon,
} from '~/components/Card';
import { useTranslation } from 'react-i18next';
import PF2MButton from '~/components/PF2MButton';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import styles from './styles';
import PF2MOutlinedInput from '../PF2MOutlinedInput';
import { getAlerts, setAlerts, updateAlerts } from '~/store/dispatch/actions';

const RainModeModal = ({
  classes, setModalOpen,
}) => {
  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  const formikRef = useRef();

  const [showAlert, setShowAlert] = useState(false);
  const alertsState = useSelector(state => state.dispatch.alerts);
  const rainModeAlert = useMemo(() => alertsState.RAIN_ALERT, [alertsState]);

  const toggleRainMode = () => {
    dispatch(setAlerts({
      ...alertsState,
      RAIN_ALERT: {
        ...alertsState.RAIN_ALERT,
        alert_type: 'RAIN_ALERT',
        alert_message: translate('common:RainMode'),
        alert_status: !rainModeAlert?.alert_status ?? false,
      },
    }));
  };

  const setAlertSpeedLimit = (speed) => {
    dispatch(updateAlerts({
      ...alertsState.RAIN_ALERT,
      alert_type: 'RAIN_ALERT',
      speed_limit: speed,
    }));
    setModalOpen(false);
  };

  useEffect(() => {
    dispatch(getAlerts());
  }, [dispatch]);

  const renderHeader = () => (
    <div>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
      >
        {translate('common:RainMode')}
      </Typography>
    </div>
  );

  return (
    <div>
      <Card>
        <CardHeader icon>
          <CardIcon color="warning">
            <CreateIcon />
          </CardIcon>
        </CardHeader>
        <CardBody>
          {renderHeader()}
          <hr />
          <Formik
            enableReinitialize
            ref={formikRef}
            initialValues={{
              speedlimit: rainModeAlert?.speed_limit ?? 0,
            }}
            onSubmit={async (values, { resetForm }) => {
              setAlertSpeedLimit(parseInt(values.speedlimit));
              setModalOpen(false);
              resetForm();
            }}
          >
            {({
              isSubmitting,
              setFieldValue,
              resetForm,
            }) => (
              <Form>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <FormControl
                    className={classes.rainModeTrigger}
                    style={{ marginRight: 10 }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={(
                          <Switch
                            onClick={() => { toggleRainMode(); }}
                            checked={rainModeAlert?.alert_status ?? false}
                          />
                        )}
                        label={translate('common:ToggleRainMode')}
                      />
                    </FormGroup>
                  </FormControl>
                  <FormControl className={classes.classObservation}>
                    <FormLabel className={classes.formLabel}>
                      {`${translate('common:SpeedLimit')}:`}
                    </FormLabel>
                    <FormGroup>
                      <Field
                        name="speedlimit"
                        render={({ field }) => (
                          <PF2MOutlinedInput
                            className={classes.speedField}
                            {...field}
                            endAdornment={<InputAdornment>Km/h</InputAdornment>}
                            onChange={e => setFieldValue('speedlimit', e.target.value)}
                            type="number"
                          />
                        )}
                      />
                    </FormGroup>
                  </FormControl>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}>
                  <PF2MButton
                    variant="extended"
                    size="medium"
                    color="primary"
                    onClick={() => {
                      resetForm();
                      setModalOpen(false);
                    }}
                    className={classes.buttonWhiteText}
                  >
                    <DeleteOutline />
                    {translate('common:DiscardChanges')}
                  </PF2MButton>
                  <PF2MButton
                    type="submit"
                    className={classes.buttonWhiteText}
                    disabled={isSubmitting}
                  >
                    <Add className={classes.icon} />
                    {translate('common:Register')}
                  </PF2MButton>
                </div>
              </Form>
            )}
          </Formik>
        </CardBody>
        <PF2MAlertDialog
          hasCancel={false}
          confirmText={translate('common:Understood')}
          description={translate('validation:ExistingSchedule')}
          open={showAlert}
          onConfirm={() => setShowAlert(false)}
        />
      </Card>
    </div>
  );
};

RainModeModal.propTypes = {
  classes: PropTypes.object.isRequired,
  setModalOpen: PropTypes.func,
  fixed: PropTypes.object,
  settings: PropTypes.object,
};

RainModeModal.defaultProps = {
  setModalOpen: () => null,
  fixed: {},
  settings: {},
};

export default withStyles(styles)(RainModeModal);

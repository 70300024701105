export const GET_ALL_TABLETS = 'GET_ALL_TABLETS';
export const GET_APK_VERSION = 'GET_APK_VERSION';
export const GET_TABLET_UPDATE = 'GET_TABLET_UPDATE';
export const POST_TABLET_UPDATE = 'POST_TABLET_UPDATE';
export const GET_TABLET_CONTROL_SYNC = 'GET_TABLET_CONTROL_SYNC';
export const SET_TABLET_CONTROL_SYNC = 'SET_TABLET_CONTROL_SYNC';
export const RESET_TABLET_CONTROL_SYNC = 'RESET_TABLET_CONTROL_SYNC';
export const POST_TABLET_CONTROL_SYNC = 'POST_TABLET_CONTROL_SYNC';

export const GET_WEB_MODULES = 'GET_WEB_MODULES';

import { findEquivalentItem, remapData } from '~/utils';
import * as types from './types';

const INITIAL_STATE = {
  goalTypes: [],
  goalTypesLevels: [],
  goalTable: [],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_GOAL_TYPES_LIST: {
      return {
        ...state,
        goalTypes: action.payload,
      };
    }
    case types.SET_GOAL_TYPES_LEVELS_LIST: {
      return {
        ...state,
        goalTypesLevels: action.payload,
      };
    }
    case types.SET_GOAL_TABLE_LIST: {
      if (action.payload.length > 0) {
        return {
          ...state,
          goalTable: action.payload,
        };
      }
      return { ...state };
    }

    case types.REMAP_GOAL_TABLE_LIST: {
      let newItems = state.goalTable;
      action.payload.forEach((item) => {
        const eqItem = findEquivalentItem(
          state.goalTable,
          item,
          ['level1_value', 'level2_value', 'level3_value', 'level4_value', 'level5_value'],
        );
        newItems = [...newItems.filter(i => i.id !== eqItem.id), item];
      });

      if (action.payload.length > 0) {
        return {
          ...state,
          goalTable: newItems,
        };
      }
      return { ...state };
    }

    case types.SET_GOAL_TABLE_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.goalTable, newItem, 'id');
      return {
        ...state,
        goalTable: newData,
      };
    }

    default:
      return state;
  }
}

import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from '~/pages/Manager/Header';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import PF2MTable from '~/components/PF2MTable';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import {
  exportLoads,
  importLoads,
  getEquipmentTypes,
  getEquipmentsGroups,
  getMaterials,
  getLoads,
} from '~/store/manager/actions';

const LoadTab = ({
  classes,
  exportLoads: exportAction,
  importLoads: importAction,
  getLoads: getAll,
  getEquipmentTypes: loadEquipmentTypes,
  getEquipmentsGroups: loadEquipmentGroups,
  getMaterials: loadMaterials,
  loads,
  equipmentTypes,
  equipmentGroups,
  materials,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [equipmentType, setEquipmentType] = useState(0);
  const [equipmentGroup, setEquipmentGroup] = useState(0);
  const [material, setMaterial] = useState(0);
  const { t: translate } = useTranslation();

  const transportAndLoadEquips = [1, 2, 15, 29, 32];

  useEffect(() => {
    async function fetchData() {
      await Promise.all([
        loadEquipmentTypes(),
        loadEquipmentGroups(),
        loadMaterials(),
      ]);
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [
    dataLoaded,
    loadEquipmentTypes,
    loadEquipmentGroups,
    loadMaterials]);

  useEffect(() => {
    async function fetchData() {
      await getAll(equipmentType, equipmentGroup, material);
    }
    if (equipmentType > 0 || equipmentGroup > 0 || (material > 0 || equipmentType === 29)) {
      fetchData();
    }
  }, [getAll, equipmentType, equipmentGroup, material]);

  const columns = [
    { field: 'equip_group_name', title: translate('common:EquipmentGroup') },
    { field: 'equip_name', title: translate('common:Equipment') },
    { field: 'material_name', title: translate('common:Material') },
    { field: 'operation_type_name', title: translate('common:OperationType') },
    { field: 'value', title: translate('common:AverageLoad') },
  ];

  const renderItems = (items = []) => [{ value: 0, label: translate('common:Select') }]
    .concat(...items
      .map(e => ({ value: e.id, label: e.name })));

  return (
    <div className={classes.tabContainer}>
      <Header
        importAction={importAction}
        exportAction={Boolean(equipmentType)
          && (() => exportAction({ equipmentType, equipmentGroup, material }))
        }
      />
      <div className={classes.containerTypeSelector}>
        <div style={{
          display: 'flex', alignItems: 'center', justifyContent: 'center', width: '33%',
        }}
        >
          <span className={classes.labelTypeSelector}>
            {`${translate('common:EquipmentType')}:`}
          </span>
          <PF2MSearchSelectOutlined
            value={equipmentType}
            onChange={(e) => {
              setEquipmentType(e.target.value);
              setEquipmentGroup(0);
            }}
            className={classes.typeSelector}
          >
            {renderItems(equipmentTypes.filter(et => transportAndLoadEquips.includes(et.id)))}
          </PF2MSearchSelectOutlined>
        </div>
        <div style={{
          display: 'flex', alignItems: 'center', justifyContent: 'right', width: '33%',
        }}
        >
          <span className={classes.labelTypeSelector}>
            {`${translate('common:EquipmentGroup')}:`}
          </span>
          <PF2MSearchSelectOutlined
            value={equipmentGroup}
            onChange={e => setEquipmentGroup(e.target.value)}
            className={classes.typeSelector}
          >
            {renderItems(equipmentGroups.filter(e => e.id_equipament === equipmentType))}
          </PF2MSearchSelectOutlined>
        </div>
        <div style={{
          display: 'flex', alignItems: 'center', justifyContent: 'center', width: '33%',
        }}
        >
          <span className={classes.labelTypeSelector}>
            {translate('common:Materials')}
          </span>
          <PF2MSearchSelectOutlined
            value={material}
            onChange={e => setMaterial(e.target.value)}
            className={classes.typeSelector}
          >
            {renderItems(materials)}
          </PF2MSearchSelectOutlined>
        </div>
      </div>
      <PF2MTable
        data={loads.filter(e => equipmentType !== 29 || (e.id_material === '' && e.operation_type_id === ''))}
        dataKey="id_pk"
        columns={columns.filter(e => equipmentType !== 29 || (e.field !== 'material_name' && e.field !== 'operation_type_name'))}
        className={classes.grid}
      />
    </div>
  );
};

LoadTab.propTypes = {
  classes: PropTypes.object.isRequired,
  loads: PropTypes.array,
  equipmentTypes: PropTypes.array,
  equipmentGroups: PropTypes.array,
  materials: PropTypes.array,
  exportLoads: PropTypes.func,
  importLoads: PropTypes.func,
  getEquipmentTypes: PropTypes.func,
  getEquipmentsGroups: PropTypes.func,
  getMaterials: PropTypes.func,
  getLoads: PropTypes.func,
};

LoadTab.defaultProps = {
  loads: [],
  equipmentTypes: [],
  equipmentGroups: [],
  materials: [],
  exportLoads: null,
  importLoads: null,
  getEquipmentTypes: null,
  getEquipmentsGroups: null,
  getMaterials: null,
  getLoads: null,
};

const mapStateToProps = state => ({
  equipmentTypes: state.manager.equipmentTypes,
  equipmentGroups: state.manager.equipmentsGroups,
  materials: state.manager.materials,
  loads: state.manager.loads,
});

const mapDispatchToProps = {
  exportLoads,
  importLoads,
  getEquipmentTypes,
  getEquipmentsGroups,
  getMaterials,
  getLoads,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LoadTab));

import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles';
import PF2MTooltip from '../PF2MTooltip';

const IconLabel = ({
  text, icon, tooltip, condition, classes, ...props
}) => (
  <span
    {...props}
    className={classNames(condition && classes.label, props.className)}
  >
    <Typography className={classes.text}>
      {text}
    </Typography>
    {condition && (
      <PF2MTooltip title={tooltip}>
        {icon}
      </PF2MTooltip>
    )}
  </span>
);
IconLabel.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.object,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  tooltip: PropTypes.string,
  condition: PropTypes.bool,
};
IconLabel.defaultProps = {
  text: '',
  icon: () => {},
  className: '',
  tooltip: '',
  condition: false,
};

export default withStyles(styles)(IconLabel);

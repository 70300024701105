import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import DMTTab from './DMTTab';
import PF2MTabs from '~/components/PF2MTabs';

const DMT = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const tabs = [
    { key: 0, label: 'DMT' },
  ];
  const handleChange = (event, value) => {
    setSelectedTab(value);
  };

  return (
    <>
      <PF2MTabs
        value={selectedTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        {tabs}
      </PF2MTabs>
      {selectedTab === 0 ? (<DMTTab />) : null}
    </>
  );
};

export default withStyles(styles)(DMT);

import React, { useState, useEffect } from 'react';
import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import PF2MSortButton from '~/components/PF2MSortButton';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import PF2MScrollbar from '~/components/PF2MScrollbar';
import {
  setFillinValues,
  deleteFillinItem,
  restoreFillinItem,
  searchFillinList,
  updateFillinList,
  exportFillinReport,
  restoreOriginalFillin,
  createFillinItem,
} from '~/store/dailyparts/actions';
import { getLoads, getEquipmentsGroups } from '~/store/manager/actions';
import { dailyPartsGridHeight } from '~/utils';
import { useTranslation } from 'react-i18next';
import Header from '../Header';
import styles from '../styles';
import ListRow from './ListRow';

const FillinList = ({ classes }) => {
  const { t: translate } = useTranslation();

  const titles = [
    { label: translate('common:InitialDate'), field: 'start_timestamp' },
    { label: translate('common:FinalDate'), field: 'end_timestamp' },
    { label: translate('common:InitialHour'), field: 'start_timestamp' },
    { label: translate('common:FinalHour'), field: 'end_timestamp' },
    { label: translate('common:Duration'), field: 'duration' },
    { label: translate('common:Team'), field: 'team_id' }, // id
    { label: translate('common:Shift'), field: 'turn_id' },
    { label: translate('common:EquipmentGroup'), field: 'equip_group_id' },
    { label: translate('common:Equipment'), field: 'equip_id' },
    { label: translate('common:OperatorGroup'), field: 'operator_group_id' },
    { label: translate('common:Operator'), field: 'operator_id' },
    { label: translate('common:TruckEquipment'), field: 'truck_id' },
    { label: translate('common:Batch'), field: 'batch_number' },
    { label: `${translate('common:LoadDefault')} (t)`, field: 'load_manager' },
    { label: translate('common:StopTime'), field: 'stop_time' },
    { label: translate('common:LoadTime'), field: 'load_time' },
    { label: translate('common:CodeTime'), field: 'code_time' },
    { label: translate('common:CycleType'), field: 'exception_type' },
  ];

  const filters = useSelector(state => state.dailyParts.filters);
  const page = useSelector(state => state.dailyParts.page);
  const limit = useSelector(state => state.dailyParts.limit);
  const { field, order } = useSelector(state => state.dailyParts.transportSortBy);

  const fillinList = useSelector(state => state.dailyParts.fillinList);
  const turns = useSelector(state => state.manager.turns);
  const teams = useSelector(state => state.manager.teams);
  const equipmentTypes = useSelector(state => state.manager.equipmentTypes);
  const equipmentsGroups = useSelector(state => state.manager.equipmentsGroups);
  const equipments = useSelector(state => state.manager.equipments);

  const operatorsGroups = useSelector(state => state.manager.operatorsGroups
    .filter(o => o.name), shallowEqual);

  const operators = useSelector(state => state.manager.operators
    .filter(o => o.name), shallowEqual);

  const exceptionTypes = useSelector(state => state.manager.exceptionTypes);
  const isFillinListDirty = useSelector(state => state.dailyParts.isFillinListDirty);

  const [sortBy, setSortBy] = useState({ field, order });
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    async function loadData() {
      await Promise.all([
        dispatch(getLoads(filters.equipmentType)),
        dispatch(getEquipmentsGroups(true)),
      ]);
    }

    if (filters.equipmentType) {
      loadData();
    }
  }, [filters.equipmentType, dispatch]);

  const handleSort = (title) => {
    if (fillinList.some(t => t.isDirty)) {
      setIsAlertOpen(true);
    } else {
      const newOrder = (sortBy.order === 'asc' && sortBy.field === title.field) ? 'desc' : 'asc';
      const newSortBy = { field: title.field, order: newOrder };
      setSortBy(newSortBy);
      dispatch(searchFillinList(filters, page, limit, newSortBy));
    }
  };

  const renderTableTitle = (title, key) => (
    <TableCell
      align="center"
      className={classes.tableCell}
      key={key}
      style={{
        position: 'sticky',
        top: 0,
        backgroundColor: 'white',
        zIndex: 1,
      }}
    >
      <PF2MSortButton
        label={title.label}
        field={title.field}
        sortField={sortBy.field}
        sortOrder={sortBy.order}
        onClick={() => handleSort(title)}
      />
    </TableCell>
  );

  const renderTableHeader = () => titles.map((t, i) => renderTableTitle(t, `${i}_${t}`));

  const renderRows = () => fillinList
    .map(d => (
      <ListRow
        key={d.id}
        data={d}
        onDelete={data => dispatch(deleteFillinItem(data))}
        onRestore={data => dispatch(restoreFillinItem(data))}
        onEdit={(item, name, value) => dispatch(setFillinValues(item, name, value))}
        classes={classes}
        equipmentTypes={equipmentTypes}
        equipmentsGroups={equipmentsGroups}
        equipments={equipments}
        turns={turns}
        teams={teams}
        operatorsGroups={operatorsGroups}
        operators={operators}
        exceptionTypes={exceptionTypes}
      />
    ));

  const renderLoadMore = () => {
    if (fillinList.length < limit) return null;
    if (fillinList.filter(d => d.id > 0).length < 1) { return null; }
    return (
      <div style={{
        backgroundColor: '#F9F9F9',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 10,
        paddingBottom: 10,
      }}
      >
        <Button
          onClick={() => dispatch(searchFillinList(filters, (page + 1), limit, sortBy, false))}
        >
          <ArrowDownIcon />
          <Typography
            variant="h5"
          >
            {translate('common:SeeMore')}
          </Typography>
        </Button>
      </div>
    );
  };

  return (
    <>
      <Header
        updateData={(data, filter) => dispatch(updateFillinList(data, filter))}
        exportData={parameters => dispatch(exportFillinReport(parameters))}
        restoreData={() => dispatch(restoreOriginalFillin())}
        createData={filter => dispatch(createFillinItem(filter))}
        data={fillinList}
        filters={filters}
        isDirty={isFillinListDirty}
      />
      {fillinList.length > 0 ? (
        <PF2MScrollbar style={{ backgroundColor: 'white', height: dailyPartsGridHeight }}>
          <>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {renderTableHeader()}
                </TableRow>
              </TableHead>
              <TableBody>
                {renderRows()}
              </TableBody>
            </Table>
            {renderLoadMore()}
            <PF2MAlertDialog
              hasCancel={false}
              confirmText={translate('common:Understood')}
              description={translate('validation:YouHaveUnsavedData')}
              open={isAlertOpen}
              onConfirm={() => setIsAlertOpen(false)}
            />
          </>
        </PF2MScrollbar>
      ) : (
        <div
          className="container"
          style={{
            backgroundColor: 'white',
            padding: 20,
          }}
        >
          <span>{translate('common:NoEntries')}</span>
        </div>
      )}

    </>
  );
};

FillinList.propTypes = {
  classes: PropTypes.object.isRequired,
};

FillinList.defaultProps = {};

export default withStyles(styles)(FillinList);

import React from 'react';
import {
  withStyles, Grid, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import PF2MSearchButton from '~/components/PF2MSearchButton';

function PF2MSearchHeader({ classes, handleModal }) {
  const filters = useSelector(state => state.dailyParts.filters);
  const { t: translate } = useTranslation();

  const {
    startDate = moment(new Date()),
    endDate = moment(new Date()),
    turn,
    operator,
    equipment,
    code,
  } = filters;

  return (
    <div>
      <Grid container className={classes.container}>
        <Grid item container xs className={classes.headerData}>
          <Grid item>
            <Typography variant="caption">
              {`${translate('common:ResultsFor')}:`}
            </Typography>
          </Grid>
          <Grid item container>
            <Typography className={classes.text}>
              <span style={styles.dataValueMargin0}>{`${startDate.format('DD/MM/YYYY')} - ${endDate.format('DD/MM/YYYY')}`}</span>
              <span>{turn ? `, ${translate('common:Turn')}` : null}</span>
              <span style={styles.dataValue}>{turn ? turn.name : null}</span>
              <span>{operator ? `, ${translate('common:Operator')}` : null}</span>
              <span style={styles.dataValue}>{operator ? operator.name : null}</span>
              <span>{equipment ? `, ${translate('common:Equipment')}` : null}</span>
              <span style={styles.dataValue}>{equipment ? equipment.name : null}</span>
              <span>{code ? `, ${translate('common:MaintenanceCode')}` : null}</span>
              <span style={styles.dataValue}>{code ? code.name : null}</span>
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <PF2MSearchButton size="medium" onClick={() => handleModal(true)} />
        </Grid>
      </Grid>
    </div>
  );
}

PF2MSearchHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  handleModal: PropTypes.func,
};

PF2MSearchHeader.defaultProps = {
  handleModal: null,
};

export default withStyles(styles)(PF2MSearchHeader);

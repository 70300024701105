import { remapData } from '~/utils';
import * as types from './types';

const INITIAL_STATE = {
  terrainModels: [],
  terrainModelUploads: [],
  terrainModelLayers: [],
  originalTerrainModelUploads: [],
  originalTerrainModels: [],
  terrainModelUploadQueue: [],
  terrainDefaultLayers: [],
  cloudPointReport: [],
  polygons: [],
  terrainDisplayData: {},
  isTerrainModelsDirty: false,
  isTerrainModelUploadsDirty: false,
  isTerrainModelLayersDirty: false,
};

const MODEL_STATUS_MAP = {
  0: 'no file',
  1: 'queue',
  2: 'uploading',
  3: 'uploaded',
  4: 'processing',
  5: 'processed',
  6: 'approved',
  7: 'rejected',
  8: 'error',
};


export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_TERRAIN_MODEL_UPLOAD_LAYERS_LIST: {
      return {
        ...state,
        terrainModelLayers: action.payload,
        isTerrainModelLayersDirty: false,
      };
    }
    case types.SET_TERRAIN_DEFAULT_LAYERS_LIST: {
      return {
        ...state,
        terrainDefaultLayers: action.payload,
      };
    }
    case types.SET_CLOUD_POINT_REPORT_LIST: {
      return {
        ...state,
        cloudPointReport: action.payload,
      };
    }
    case types.SET_POLYGON_LIST: {
      return {
        ...state,
        polygons: action.payload,
      };
    }
    case types.ADD_TERRAIN_MODEL_UPLOAD_LAYER: {
      const newItem = {
        ...action.payload,
        id: 0 - state.terrainModelLayers.length,
        update_status: 'I',
        active: 1,
        isDirty: true,
      };
      const newData = [newItem, ...state.terrainModelLayers];
      const isTerrainModelLayersDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isTerrainModelLayersDirty,
        terrainModelLayers: newData,
      };
    }
    case types.SET_TERRAIN_MODEL_UPLOAD_LAYER_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.terrainModelLayers, newItem, 'id');
      const isTerrainModelLayersDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isTerrainModelLayersDirty,
        terrainModelLayers: newData,
      };
    }
    case types.SET_TERRAIN_MODEL_LIST: {
      return {
        ...state,
        terrainModels: action.payload.map(i => ({
          ...i,
          status: !i.status ? 0 : i.status,
          status_name: MODEL_STATUS_MAP[!i.status ? 0 : i.status],
          top_left_lat_lng: i.top_left_lat_lng ? JSON.parse(i.top_left_lat_lng) : [0, 0],
          bottom_right_lat_lng:
          i.bottom_right_lat_lng
            ? JSON.parse(i.bottom_right_lat_lng)
            : [0, 0],
          origin_point: i.origin_point ? JSON.parse(i.origin_point) : [0, 0],
        })),
        isTerrainModelsDirty: false,
      };
    }
    case types.SET_TERRAIN_MODEL_ITEM: {
      const newItem = {
        ...action.payload,
        status_name: MODEL_STATUS_MAP[action.payload.status],
      };
      const newData = remapData(state.terrainModels, newItem, 'id');
      return {
        ...state,
        terrainModels: newData,
      };
    }
    case types.SET_TERRAIN_MODEL_UPLOAD_ITEM: {
      const newItem = {
        ...action.payload,
        status_name: MODEL_STATUS_MAP[action.payload.status],
      };
      const newData = [
        ...state.terrainModelUploads.filter(i => i.id !== action.row_id),
        newItem,
      ];
      return {
        ...state,
        terrainModelUploads: newData,
      };
    }
    case types.SET_TERRAIN_MODEL_UPLOAD_LIST: {
      return {
        ...state,
        terrainModelUploads: action.payload.map(i => ({
          ...i,
          status: !i.status ? 0 : i.status,
          status_name: MODEL_STATUS_MAP[!i.status ? 0 : i.status],
        })),
        isTerrainModelUploadsDirty: false,
      };
    }
    case types.ADD_TERRAIN_MODEL: {
      const newItem = {
        ...action.payload,
        id: 0 - state.terrainModels.length,
        update_status: 'I',
        active: 1,
        isDirty: true,
      };
      const newData = [newItem, ...state.terrainModels];
      const isTerrainModelsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isTerrainModelsDirty,
        terrainModels: newData,
      };
    }
    case types.ADD_TERRAIN_MODEL_UPLOAD: {
      const newItem = {
        ...action.payload,
        id: 0 - state.terrainModelUploads.length,
        status_name: MODEL_STATUS_MAP[action.payload.status],
        update_status: 'I',
        active: 1,
        isDirty: true,
      };
      const newData = [newItem, ...state.terrainModelUploads];
      const isTerrainModelUploadsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isTerrainModelUploadsDirty,
        terrainModelUploads: newData,
      };
    }
    case types.ADD_TERRAIN_MODEL_UPLOAD_QUEUE: {
      const newItem = {
        ...action.payload,
        id: state.terrainModelUploadQueue.length,
        status: 1,
      };
      const newData = [...state.terrainModelUploadQueue, newItem];
      return {
        ...state,
        terrainModelUploadQueue: newData,
      };
    }
    case types.CLEAR_TEMP_TERRAIN_MODEL_UPLOAD: {
      const item = action.payload;
      const newData = state.terrainModelUploads.filter(d => d.id !== item.id);
      return {
        ...state,
        terrainModelUploads: newData,
      };
    }
    case types.SET_TERRAIN_MODEL_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const current = action.payload.is_project === 1
        ? state.terrainModels.map(m => ({
          ...m,
          update_status: m.is_project === 1 ? 'U' : null,
          is_project: 0,
        }))
        : state.terrainModels;
      const newData = remapData(current, newItem, 'id');
      const isTerrainModelsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isTerrainModelsDirty,
        terrainModels: newData,
      };
    }
    case types.SET_TERRAIN_MODEL_UPLOAD_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const current = action.payload.is_default === 1
        ? state.terrainModelUploads.map(m => ({
          ...m,
          update_status: m.is_default === 1 ? 'U' : null,
          is_default: 0,
        }))
        : state.terrainModelUploads;
      const newData = remapData(current, newItem, 'id')
        .map(i => ({ ...i, status_name: MODEL_STATUS_MAP[i.status] }));
      const isTerrainModelUploadsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isTerrainModelUploadsDirty,
        terrainModelUploads: newData,
      };
    }
    case types.SET_TERRAIN_MODEL_UPLOAD_QUEUE_VALUES: {
      const newItem = {
        ...action.payload,
      };
      const newData = remapData(state.terrainModelUploadQueue, newItem, 'id');
      return {
        ...state,
        terrainModelUploadQueue: newData,
      };
    }
    case types.SET_QUEUE_ITEM_DONE: {
      const itemId = action.payload;
      const newData = state.terrainModelUploadQueue.filter(d => d.row.id !== itemId);
      return {
        ...state,
        terrainModelUploadQueue: newData,
      };
    }
    default: {
      return state;
    }
  }
}

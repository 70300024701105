import React, { useState } from 'react';
import {
  withStyles,
  FormControl,
  FormLabel,
  FormGroup,
} from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PF2MButton from '~/components/PF2MButton';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import { addStockClosing } from '~/store/quality/actions';
import PF2MDatePickerOutlined from '~/components/PF2MDatePickerOutlined';
import {
  newMomentDate, formatDateToServerFormat, formatTime, parseTime,
} from '~/utils/moment';
import styles from '~/pages/Quality/styles';
import NumberFormat from 'react-number-format';
import PF2MOutlinedInput from '~/components/PF2MOutlinedInput';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import { TimePicker } from '@material-ui/pickers';

const Header = ({ classes }) => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const elements = useSelector(state => state.manager.elements.filter(r => r.is_destination));
  const subElements = useSelector(state => state.manager.subElements);
  const materials = useSelector(state => state.manager.materials);
  const contents = useSelector(state => state.quality.contents);
  const [date, setDate] = useState(newMomentDate());
  const [time, setTime] = useState('00:00:00');
  const [element, setElement] = useState(0);
  const [subElement, setSubElement] = useState(0);
  const [material, setMaterial] = useState(0);
  const [mass, setMass] = useState(0);
  const [fieldContents, setFieldContents] = useState({});
  const [showAlert, setShowAlert] = useState(false);

  const resetForm = () => {
    setDate(newMomentDate());
    setTime('00:00:00');
    setElement(0);
    setSubElement(0);
    setMaterial(0);
    setMass(0);
    setFieldContents({});
  };

  const renderContents = () => (
    contents.map((c, i) => (
      <FormControl
        key={c.id}
        style={{ gridColumnStart: i === 0 ? 1 : '' }}
      >
        <FormLabel className={classes.formLabel}>
          {`${c.name}`}
        </FormLabel>
        <FormGroup>
          {c.value_type !== 1 && (
            <NumberFormat
              value={fieldContents[`content_${c.id}_${Number(!!c.value_type)}`] || ''}
              customInput={PF2MOutlinedInput}
              min={0}
              decimalSeparator={','}
              decimalScale={5}
              className={classes.textField}
              style={{ minWidth: 150, height: 40 }}
              onBlur={(e) => {
                const newValues = {
                  ...fieldContents,
                  [`content_${c.id}_${Number(!!c.value_type)}`]: e.target.value,
                };
                setFieldContents(newValues);
              }}
            />
          )}
          {c.value_type === 1 && (
            <PF2MOutlinedInput
              value={fieldContents[`content_${c.id}_${Number(!!c.value_type)}`] || ''}
              className={classes.textField}
              style={{ minWidth: 150, height: 40 }}
              onBlur={(e) => {
                const newValues = {
                  ...fieldContents,
                  [`content_${c.id}_${Number(!!c.value_type)}`]: e.target.value,
                };
                setFieldContents(newValues);
              }}
            />
          )}
        </FormGroup>
      </FormControl>
    ))
  );

  const renderItems = (items = []) => [{ value: 0, label: translate('common:Select') }]
    .concat(...items
      .map(e => ({ value: e.id, label: e.name })));

  const formSubmit = (e) => {
    e.preventDefault();

    if (!subElement || !mass || mass <= 0) {
      setShowAlert(true);
      return;
    }

    const data = {
      date: formatDateToServerFormat(date),
      time: formatTime(time),
      element_id: element,
      element_point_id: subElement,
      material_id: material,
      mass,
      ...fieldContents,
    };
    dispatch(addStockClosing(data));

    resetForm();
  };

  const renderDatePicker = (value, setAction) => (
    <PF2MDatePickerOutlined
      value={value}
      disableFuture
      helperText=""
      InputProps={{
        style: {
          minWidth: 150,
          height: 40,
        },
      }}
      onChange={setAction}
    />
  );

  const handleMask = value => (value ? [/\d/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/] : []);
  const renderTimePicker = (value, onChange) => (
    <TimePicker
      value={parseTime(value || '00:00:00')}
      keyboard
      autoOk
      ampm={false}
      inputVariant="outlined"
      format="HH:mm:ss"
      mask={handleMask}
      onChange={onChange}
      invalidDateMessage=""
      InputProps={{
        style: {
          height: 40,
          fontFamily: 'Roboto',
          fontWeight: 300,
          fontSize: 14,
          color: '#647886',
        },
      }}
    />
  );

  return (
    <>
      <div style={{
        border: '1px solid #CDCDCD',
        borderRadius: 5,
        padding: 10,
      }}
      >
        <span className={classes.labelTypeSelector}>
          {translate('common:Closings')}
        </span>
        <div style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(6, 200px)',
          gridColumnGap: 10,
          gridRowGap: 10,
          margin: '10px 0',
        }}
        >
          <FormControl>
            <FormLabel className={classes.formLabel}>
              {translate('common:Date')}
            </FormLabel>
            <FormGroup>
              {renderDatePicker(date, setDate)}
            </FormGroup>
          </FormControl>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              {translate('common:Time')}
            </FormLabel>
            <FormGroup>
              {renderTimePicker(time, setTime)}
            </FormGroup>
          </FormControl>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              {translate('common:Element')}
            </FormLabel>
            <FormGroup>
              <PF2MSearchSelectOutlined
                value={element}
                onChange={e => setElement(e.target.value)}
                className={classes.fieldSelect}
              >
                {renderItems(elements)}
              </PF2MSearchSelectOutlined>
            </FormGroup>
          </FormControl>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              {translate('common:SubElement')}
            </FormLabel>
            <FormGroup>
              <PF2MSearchSelectOutlined
                value={subElement}
                onChange={e => setSubElement(e.target.value)}
                className={classes.fieldSelect}
              >
                {renderItems(subElements.filter(s => s.id_element === element))}
              </PF2MSearchSelectOutlined>
            </FormGroup>
          </FormControl>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              {translate('common:Material')}
            </FormLabel>
            <FormGroup>
              <PF2MSearchSelectOutlined
                value={material}
                onChange={e => setMaterial(e.target.value)}
                className={classes.fieldSelect}
              >
                {renderItems(materials)}
              </PF2MSearchSelectOutlined>
            </FormGroup>
          </FormControl>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              {translate('common:MassT')}
            </FormLabel>
            <FormGroup>
              <NumberFormat
                value={mass || 0}
                customInput={PF2MOutlinedInput}
                min={0}
                decimalSeparator={','}
                decimalScale={2}
                className={classes.textField}
                style={{ minWidth: 150, height: 40 }}
                onBlur={(e) => {
                  setMass(e.target.value);
                }}
              />
            </FormGroup>
          </FormControl>
          {renderContents()}
        </div>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
        >
          <PF2MButton
            style={{ marginTop: 15 }}
            onClick={formSubmit}
          >
            <Add className={classes.icon} />
            {translate('common:Register')}
          </PF2MButton>
        </div>
      </div>
      <PF2MAlertDialog
        hasCancel={false}
        confirmText={translate('common:Understood')}
        description={translate('validation:FormWasNotFilledCorrectly')}
        open={showAlert}
        onConfirm={() => setShowAlert(false)}
      />
    </>
  );
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Header);

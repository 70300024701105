/* eslint-disable no-param-reassign */
import * as types from './types';
import api from '~/services/api';

import {
  exportData, importData, handleErrors,
} from '../common';
import { clearFileUploadError } from '../errors/actions';

const getEndpoints = {
  equipmentfuel: {
    url: '/equipmentfuel',
    responseObject: 'equipment_fuel',
    typeAction: types.SET_FUEL_LIST,
  },
  fuelremainingalert: {
    url: '/fuelremainingalert',
    responseObject: 'fuel_remaining_alert',
    typeAction: types.SET_FUEL_REMAINING_ALERT,
  },
  codeequipmentlink: {
    url: '/codeequipmentlink',
    responseObject: 'code_equipment_link',
    typeAction: types.SET_CODES_EQUIPMENT_LINK_MAPS_LIST,
  },
};

export const getAll = ({ domain, clearUploadError = true, all = false }) => async (dispatch) => {
  try {
    if (clearUploadError) {
      dispatch(clearFileUploadError());
    }
    const endpoint = getEndpoints[domain];
    const endpointUrl = `${endpoint.url}${all ? '?all' : ''}`;
    const {
      data: {
        result: { [endpoint.responseObject]: data = [] },
      },
    } = await api.get(endpointUrl);
    dispatch({
      type: endpoint.typeAction,
      payload: data,
    });
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const updateData = (domain, data) => async (dispatch) => {
  try {
    const items = data
      .filter(d => d.update_status)
      .map((d) => {
        const {
          isDirty, id, ...rest
        } = d;

        if (rest.update_status !== 'I') return { id, ...rest };
        return rest;
      });
    const endpoint = getEndpoints[domain];

    let body = {
      [endpoint.responseObject]: items,
    };

    if (domain === 'fuelremainingalert') {
      const {
        green, yellow, red, purple,
      } = items[0];

      body = {
        [endpoint.responseObject]: {
          green, yellow, red, purple,
        },
      };
    }
    const { status } = await api.post(`${endpoint.url}`, body);
    if (status === 200) {
      dispatch(getAll({ domain }));
    }
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

const setValues = (item, key, value, type) => (dispatch) => {
  let setType = '';
  switch (type) {
    case 'codequipmentlink':
      setType = types.SET_CODES_EQUIPMENT_LINK_MAP_VALUES;
      break;
    case 'fuelremainingalert':
    default:
      setType = types.SET_FUEL_REMAINING_ALERT_VALUES;
      value = Number(value) || 0;
      break;
  }
  const updateStatus = item.update_status === 'I' ? 'I' : 'U';
  dispatch({
    type: setType,
    payload: {
      ...item,
      [key]: value,
      update_status: updateStatus,
    },
  });
};

export const getFuels = () => async dispatch => dispatch(getAll({ domain: 'equipmentfuel' }));
export const getFuelRemainingAlerts = (all = false) => async dispatch => dispatch(getAll({ domain: 'fuelremainingalert', all }));
export const getCodesEquipmentMaps = (all = false) => async dispatch => dispatch(getAll({ domain: 'codeequipmentlink', all }));

export const exportFuels = () => async dispatch => dispatch(exportData('equipmentfuel'));
export const importFuels = file => async dispatch => dispatch(importData('equipmentfuel', file, getAll));
export const updateFuelRemainingAlerts = data => async dispatch => dispatch(updateData('fuelremainingalert', data));
export const setFuelRemainingAlertsValue = (item, key, value) => async dispatch => dispatch(setValues(item, key, value, 'fuelremainingalert'));
export const resetFuelRemainingAlerts = () => async dispatch => dispatch(
  { type: types.RESET_FUEL_REMAINING_ALERTS },
);

export const resetCodesEquipmentMaps = () => async dispatch => dispatch(
  { type: types.RESET_CODES_EQUIPMENT_LINK_MAPS },
);

export const setCodesEquipmentMapValues = (
  equipmentGroup,
  key,
  value,
) => async (dispatch) => {
  dispatch({
    type: types.SET_CODES_EQUIPMENT_LINK_MAP_VALUES,
    payload: {
      equipmentGroup,
      [key]: value,
    },
  });
};

export const updateCodesEquipmentMaps = data => async dispatch => dispatch(updateData('codeequipmentlink', data));

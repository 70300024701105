import React, { useState } from 'react';
import {
  withStyles, Modal, Typography, Tab, Tabs, AppBar,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CreateIcon from '@material-ui/icons/Create';
import CloseIcon from '@material-ui/icons/Close';
import { DispatchOperationTypeName } from '~/utils';
import {
  Card, CardBody, CardHeader, CardIcon,
} from '~/components/Card';
import {
  setSelectedElementDestination,
  setSelectedTruckDestination,
} from '~/store/dispatch/actions';
import styles from '../../styles';


const TruckModal = ({
  isOpen,
  close,
  classes,
  container,
}) => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const selectedTruckDestination = useSelector(state => state.dispatch.selectedTruckDestination);
  const codeTypes = useSelector(state => state.manager.codeTypes.map(e => ({ ...e, name: translate(`common:${e.name}`) })));
  const [selectedModalTab, setSelectedModalTab] = useState(0);

  const handleClose = () => {
    dispatch(setSelectedElementDestination(null));
    dispatch(setSelectedTruckDestination(null));
    close();
  };

  const getCodeTypeName = (codeTypeId) => {
    if (codeTypeId == null) return '';
    return codeTypes.find(r => r.id === codeTypeId)?.name;
  };

  const renderDisplayField = (label = '', value = '-') => (
    <div>
      <div className={classes.labelTypeSelector}>
        {`${label}:`}
      </div>
      <div style={{ color: '#647886' }}>
        {value}
      </div>
    </div>
  );

  const renderOperationsTab = () => (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridColumnGap: 10,
        gridRowGap: 10,
      }}
    >
      {renderDisplayField(translate('common:OperatorGroup'), selectedTruckDestination.operator_group_name)}
      {renderDisplayField(translate('common:Operator'), selectedTruckDestination.operator_name)}
      {renderDisplayField(translate('common:CurrentElement'), selectedTruckDestination.current_element_name)}
      {renderDisplayField(translate('common:CurrentElementPoint'), selectedTruckDestination.current_element_point_name)}
      {renderDisplayField(translate('common:CurrentCycleStep'), DispatchOperationTypeName[selectedTruckDestination.operation_type])}
      {renderDisplayField(translate('common:AllocatedTo'), selectedTruckDestination.destination_point_name)}
      {renderDisplayField(translate('common:Material'), selectedTruckDestination.current_material_name)}
      {renderDisplayField(translate('common:DestinationSubElement'), selectedTruckDestination.current_Destination_point_name)}
      {renderDisplayField(translate('common:DestinationSubElement'), selectedTruckDestination.current_destination_point_name)}
      {renderDisplayField(translate('common:Velocity'), (`${selectedTruckDestination.velocity ? selectedTruckDestination.velocity.toFixed(2) : '0.00'}km/h`))}
    </div>
  );

  const renderStatusTab = () => (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridColumnGap: 10,
        gridRowGap: 10,
      }}
    >
      {renderDisplayField(translate('common:CodeType'), getCodeTypeName(selectedTruckDestination.code_type_id))}
      {renderDisplayField(translate('common:CodeGroup'), selectedTruckDestination.code_group_name)}
      {renderDisplayField(translate('common:Code'), selectedTruckDestination.code_name)}
      {renderDisplayField(translate('common:CurrentElement'), selectedTruckDestination.current_element_name)}
      {renderDisplayField(translate('common:CurrentElementPoint'), selectedTruckDestination.current_element_point_name)}
      {renderDisplayField(translate('common:OperatorGroup'), selectedTruckDestination.operator_group_name)}
      {renderDisplayField(translate('common:Operator'), selectedTruckDestination.operator_name)}
    </div>
  );

  const renderHeader = () => (
    <div>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
      >
        {`${translate('common:Equipment')}: ${selectedTruckDestination.equip_name}`}
      </Typography>
      <div style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridColumnGap: 10,
        gridRowGap: 10,
      }}
      >
        {renderDisplayField(translate('common:EquipmentType'), selectedTruckDestination.equip_type_name)}
        {renderDisplayField(translate('common:EquipmentGroup'), selectedTruckDestination.equip_group_name)}
        {renderDisplayField(translate('common:Equipment'), selectedTruckDestination.equip_name)}
        {renderDisplayField(translate('common:LastModified'), selectedTruckDestination.last_modified)}
      </div>
    </div>
  );

  const renderTabBar = () => (
    <AppBar
      position="static"
      style={{
        backgroundColor: 'white',
        boxShadow: 'none',
      }}
    >
      <div>
        <Tabs
          value={selectedModalTab}
          onChange={(event, value) => setSelectedModalTab(value)}
          classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
        >
          <Tab
            label={'Status'}
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
          />
          <Tab
            label={'Operação'}
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
          />
        </Tabs>
      </div>
    </AppBar>
  );

  return (
    <Modal
      container={container || document.body}
      open={isOpen}
      onBackdropClick={() => handleClose()}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div style={{ width: 800, height: 600 }}>
        <Card style={{ height: '100%' }}>
          <CardHeader icon>
            <>
              <CardIcon color="warning">
                <CreateIcon />
              </CardIcon>
              <CardIcon
                onClick={handleClose}
                style={{
                  float: 'right',
                  cursor: 'pointer',
                  background: 'darkgray',
                  borderRadius: 50,
                  boxShadow: '10px 10px 50px gray',
                }}
              >
                <CloseIcon />
              </CardIcon>
            </>
          </CardHeader>
          <CardBody>
            <div />
            <div>
              {renderHeader()}
              <hr />
              <div>
                {renderTabBar()}
                <div style={{ padding: 20 }}>
                  {selectedModalTab === 0 && renderStatusTab()}
                  {selectedModalTab === 1 && renderOperationsTab()}
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
};

TruckModal.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  container: PropTypes.element,
};

TruckModal.defaultProps = {
  isOpen: false,
  close: () => null,
  container: null,
};

const DestinationTruckModal = withStyles(styles)(TruckModal);
export default DestinationTruckModal;

const ElementModal = ({
  isOpen,
  close,
  classes,
  container,
}) => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const selectedElementDestination = useSelector(
    state => state.dispatch.selectedElementDestination,
  );

  const handleClose = () => {
    dispatch(setSelectedElementDestination(null));
    dispatch(setSelectedTruckDestination(null));
    close();
  };

  const renderDisplayField = (label = '', value = '-') => (
    <div>
      <div className={classes.labelTypeSelector}>
        {`${label}:`}
      </div>
      <div style={{ color: '#647886' }}>
        {value}
      </div>
    </div>
  );

  const renderHeader = () => (
    <div>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
      >
        {`${translate('common:SubElement')}: ${selectedElementDestination.name}`}
      </Typography>
      <div style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridColumnGap: 10,
        gridRowGap: 10,
      }}
      >
        {renderDisplayField(translate('common:Element'), selectedElementDestination.element_name)}
        {renderDisplayField(translate('common:SubElement'), selectedElementDestination.name)}
      </div>
    </div>
  );

  return (
    <Modal
      container={container || document.body}
      open={isOpen}
      onBackdropClick={() => handleClose()}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div style={{ width: 800, height: 300 }}>
        <Card style={{ height: '100%' }}>
          <CardHeader icon>
            <>
              <CardIcon color="warning">
                <CreateIcon />
              </CardIcon>
              <CardIcon
                onClick={handleClose}
                style={{
                  float: 'right',
                  cursor: 'pointer',
                  background: 'darkgray',
                  borderRadius: 50,
                  boxShadow: '10px 10px 50px gray',
                }}
              >
                <CloseIcon />
              </CardIcon>
            </>
          </CardHeader>
          <CardBody>
            <div />
            <div>
              {renderHeader()}
            </div>
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
};

ElementModal.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  container: PropTypes.element,
};

ElementModal.defaultProps = {
  isOpen: false,
  close: () => null,
  container: null,
};

export const DestinationElementModal = withStyles(styles)(ElementModal);

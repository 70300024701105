const styles = {
  greenLabel: {
    background: 'green',
    borderRadius: 5,
  },
  redLabel: {
    background: 'red',
    borderRadius: 5,
  },
  yellowLabel: {
    background: 'orange',
    borderRadius: 5,
  },
  grayLabel: {
    background: 'grey',
    borderRadius: 5,
  },
  labelTypeSelector: {
    color: 'white',
    fontFamily: 'Barlow Condensed',
    fontWeight: 600,
    fontSize: 16,
    textTransform: 'uppercase',
    lineHeight: 1,
  },
};

export default styles;

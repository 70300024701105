/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import { createBrowserHistory } from 'history';
import { SET_CURRENT_USER } from './types';
import { convertToUrlParameters } from '~/utils/index';
import { GET_ERRORS, GET_GENERIC_ERROR, CLEAN_MESSAGES } from '../errors/types';
import { login, logout, passwordValidation } from '~/services/authentication';
import api from '~/services/api';
import i18n from '~/i18n';
import { validateVersion } from '~/services/version';

export const setCurrentUser = (token = '', config) => {
  const payload = {};
  if (token) {
    Object.assign(payload, { ...jwt_decode(token), token });
  }
  if (config) {
    Object.assign(payload, { config });
  }
  return {
    type: SET_CURRENT_USER,
    payload,
  };
};

export const logoutUser = (expired = false, returnUrl = '') => async (dispatch) => {
  await logout();
  dispatch(setCurrentUser());
  const history = createBrowserHistory({ forceRefresh: true });

  const queryString = {};
  if (expired) queryString.expired = true;
  if (returnUrl) queryString.returnUrl = returnUrl;

  const paramsUrl = convertToUrlParameters(queryString);
  const loginUrl = `/login?${paramsUrl}`;
  history.push(loginUrl);
};

export const loginUser = user => async (dispatch) => {
  try {
    const {
      data: {
        result: {
          token, password_is_valid, config, version,
        },
      },
    } = await api.post('/login', user);

    validateVersion({ current: version });
    passwordValidation(password_is_valid);
    login(token, config, version);
    await dispatch({ type: CLEAN_MESSAGES });
    dispatch(setCurrentUser(token, config));
  } catch ({ response }) {
    if (response && response.data) {
      dispatch({
        type: GET_ERRORS,
        payload: response.data.errors,
      });
    } else {
      dispatch({
        type: GET_GENERIC_ERROR,
        payload: i18n.t('common:ServerError'),
      });
    }
  }
};
export const resetPassword = data => async (dispatch) => {
  try {
    const body = {
      passwords: {
        password: data.password,
        password_confirm: data.passwordConfirm,
      },
    };
    const response = await api.post('/webusers/reset_password', body);

    if (response.data.result.status === undefined && response.data.result.error.length > 0) {
      dispatch({
        type: GET_ERRORS,
        payload: response.data.result.error,
      });
    } else {
      dispatch(logoutUser());
    }
  } catch ({ response }) {
    if (response && response.data) {
      dispatch({
        type: GET_ERRORS,
        payload: response.data.errors,
      });
    } else {
      dispatch({
        type: GET_GENERIC_ERROR,
        payload: i18n.t('common:ServerError'),
      });
    }
  }
};

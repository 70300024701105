import { SET_CURRENT_USER } from './types';
import { isEmpty } from '~/utils';

const INITIAL_STATE = {
  isAuthenticated: false,
  user: {},
  version: '',
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
        version: action.payload.version || '',
      };
    default:
      return state;
  }
}

import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import Header from '~/pages/Manager/Header';
import Footer from '~/pages/Manager/Footer';
import PF2MTable from '~/components/PF2MTable';

import {
  getChecklistTemplate,
  exportChecklist,
  importChecklist,
  getChecklist,
} from '~/store/manager/actions';

const ChecklistTab = ({
  classes,
  checklist,
  getChecklist: getAll,
  getChecklistTemplate: getTemplateAction,
  exportChecklist: exportAction,
  importChecklist: importAction,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const { t: translate } = useTranslation();

  useEffect(() => {
    async function fetchOperators() {
      await getAll();
    }
    if (!dataLoaded) {
      fetchOperators();
      setDataLoaded(true);
    }
  }, [dataLoaded, getAll]);

  const columns = [
    {
      field: 'name',
      title: translate('common:Name').toUpperCase(),
    },
    {
      field: 'description',
      title: translate('common:Description').toUpperCase(),
      width: '100%',
      wrap: true,
    },
    { field: 'checked_name', title: translate('common:ObligatoryStop').toUpperCase() },
  ];

  return (
    <div className={classes.tabContainer}>
      <Header
        getTemplateAction={getTemplateAction}
        importAction={importAction}
        exportAction={exportAction}
      />
      <PF2MTable
        data={checklist}
        columns={columns}
      />
      <Footer />
    </div>
  );
};

ChecklistTab.propTypes = {
  classes: PropTypes.object.isRequired,
  checklist: PropTypes.array,
  getChecklist: PropTypes.func,
  getChecklistTemplate: PropTypes.func,
  exportChecklist: PropTypes.func,
  importChecklist: PropTypes.func,
};

ChecklistTab.defaultProps = {
  checklist: [],
  getChecklist: () => { },
  getChecklistTemplate: () => { },
  exportChecklist: () => { },
  importChecklist: () => { },
};

const mapStateToProps = state => ({
  checklist: state.manager.checklist,
});

const mapDispatchToProps = {
  getChecklist,
  getChecklistTemplate,
  exportChecklist,
  importChecklist,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ChecklistTab));

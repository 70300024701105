import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Add from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import PF2MButton from '../PF2MButton';
import styles from './styles';

const PF2MAddButton = ({
  classes, disabled, text, ...rest
}) => {
  const { t: translate } = useTranslation();
  return (
    <PF2MButton type="submit" disabled={disabled} {...rest}>
      <Add className={classes.icon} />
      {text || translate('common:Register')}
    </PF2MButton>
  );
};

PF2MAddButton.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  text: PropTypes.string,
};

PF2MAddButton.defaultProps = {
  disabled: false,
  text: '',
};

export default withStyles(styles)(PF2MAddButton);

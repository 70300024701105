import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Tabs, Tab, AppBar } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import PF2MAlertDialog from '../PF2MAlertDialog';

const PF2MTabsWithAppBar = ({ classes, tabs }) => {
  const [value, setValue] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isTransportListDirty = useSelector(state => state.dailyParts.isTransportListDirty);
  const isHourmeterListDirty = useSelector(state => state.dailyParts.isHourmeterListDirty);
  const isCodeListDirty = useSelector(state => state.dailyParts.isCodeListDirty);
  const { t: translate } = useTranslation();

  const handleChange = (event, selectedTab) => {
    if (isCodeListDirty || isHourmeterListDirty || isTransportListDirty) {
      setIsModalOpen(true);
    } else {
      setValue(selectedTab);
    }
  };

  const renderActiveTab = () => {
    const activeTab = tabs.find(t => t.key === value);
    return activeTab ? activeTab.component : null;
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        style={{
          backgroundColor: '#CDCDCD',
        }}
      >
        <div className="container">
          <Tabs
            value={value}
            onChange={handleChange}
            classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
          >
            {tabs.map(t => (
              <Tab
                label={t.label}
                key={t.key}
                disabled={t.disabled}
                classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
              />
            ))}
          </Tabs>
        </div>
      </AppBar>
      <div style={{ backgroundColor: 'white' }}>
        {renderActiveTab()}
      </div>
      <PF2MAlertDialog
        hasCancel={false}
        confirmText={translate('common:Understood')}
        description={translate('validation:YouHaveUnsavedData')}
        open={isModalOpen}
        onConfirm={() => setIsModalOpen(false)}
      />
    </div>
  );
};

PF2MTabsWithAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  tabs: PropTypes.array,
};

PF2MTabsWithAppBar.defaultProps = {
  tabs: [],
};

export default withStyles(styles)(PF2MTabsWithAppBar);

import * as types from './types';
import * as managerTypes from '../manager/types';

const INITIAL_STATE = {
  chatList: null,
  connectionStatus: {},
  socketConnection: null,
  socketConnectionStatus: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.RECEIVE_NEW_MESSAGE: {
      const { sender, message, type } = action.payload;
      const chat = state.chatList[sender.room];
      const newMessageList = [
        ...state.chatList[sender.room].messages,
        {
          message,
          type,
          sender,
        },
      ];
      return {
        ...state,
        chatList: {
          ...state.chatList,
          [sender.room]: {
            ...chat,
            messages: newMessageList,
            isReaded: type === 'sended',
          },
        },
      };
    }
    case types.READ_CHAT: {
      const room = action.payload;
      return {
        ...state,
        chatList: {
          ...state.chatList,
          [room]: { ...state.chatList[room], isReaded: true },
        },
      };
    }
    case types.SET_CONNECTION_STATUS: {
      return {
        ...state,
        connectionStatus: action.payload,
      };
    }
    case types.SET_SOCKET_CONNECTION: {
      return {
        ...state,
        socketConnection: action.payload,
      };
    }
    case managerTypes.SET_EQUIPMENTS_LIST: {
      return {
        ...state,
        chatList: action.payload.reduce((acc, equipment) => {
          acc[equipment.id] = {
            name: equipment.name,
            id: equipment.id,
            messages: [],
            isReaded: true,
            lastTimeReceived: null,
            id_equip: equipment.id_equip,
          };
          return acc;
        }, {}),
      };
    }
    default:
      return state;
  }
}

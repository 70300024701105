const styles = {
  modalContainer: {
    width: 'calc(100vw - 60px)',
    height: 'calc(100vh - 60px)',
  },
  modalTitle: {
    paddingTop: 20,
  },
  container: {
    alignSelf: 'center',
    justifyContent: 'center',
    '& > *': {
      paddingTop: 40,
      textAlign: 'center',
    },
  },
  icon: {
    marginRight: 10,
  },
  footer: {
    position: 'absolute',
    bottom: 15,
    right: 15,
    width: '100%',
  },
  button: {
    color: '#FFF',
    margin: 5,
  },
  tabContainer: {
    borderRadius: 5,
    borderTopLeftRadius: 0,
    backgroundColor: 'white',
    padding: 10,
    height: 'calc(100vh - 120px)',
    display: 'flex',
    flexDirection: 'column',
  },
  containerTypeSelector: {
    maxWidth: 280,
  },
  labelTypeSelector: {
    color: '#647886',
    fontFamily: 'Barlow Condensed',
    fontWeight: 600,
    fontSize: 18,
    textTransform: 'uppercase',
    marginRight: 10,
  },
  typeSelector: {
    height: 40,
    fontFamily: 'Roboto',
    fontWeight: 300,
    fontSize: 14,
    color: '#CDCDCD',
  },
  nameLabel: {
    flex: 1,
    minWidth: 290,
    marginLeft: 10,
    marginRight: 10,
  },
  formLabel: {
    color: '#647886',
    fontSize: 12,
    fontFamily: 'Roboto',
    marginBottom: 5,
  },
  headerCombo: {
    color: '#647886',
    width: 150,
    borderWidth: 0,
    marginRight: 5,
  },
  advanceTable: {
    height: 'calc(100vh - 52px - 52px - 230px)',
  },
  errorMessage: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 12,
    textAlign: 'center',
    color: '#EC3337',
  },
  field: {
    border: '1px solid #CDCDCD',
    borderRadius: 5,
    height: 40,
  },
  fieldError: {
    border: '1px solid red !important',
    borderRadius: 5,
    height: 40,
  },
};

export default styles;

// import { managerGridHeight } from '~/utils';

const styles = {
  formLabel: {
    color: '#647886',
    fontSize: 12,
    fontFamily: 'Roboto',
    marginBottom: 5,
  },
  field: {
    border: '1px solid #CDCDCD',
    borderRadius: 5,
    height: 40,
    color: '#647886',
    borderWidth: 0,
    zIndex: 11,
  },
  checkboxLabel: {
    fontWeight: 300,
    fontSize: 14,
    color: '#647886',
  },
};

export default styles;

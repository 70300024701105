import React, { useState, useEffect } from 'react';
import {
  withStyles, FormControl, FormLabel, FormGroup, Radio,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import Footer from '~/pages/Manager/Footer';
import PF2MTable from '~/components/PF2MTable';
import {
  getTerrainModels,
  setTerrainModelValue,
  updateTerrainModel,
  addTerrainModel,
  getTerrainModelUploads,
} from '~/store/terrain/actions';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import PF2MAddButton from '~/components/PF2MAddButton';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';

const TerrainModelTab = ({
  classes,
  terrainModels,
  terrainModelUploads,
  addTerrainModel: add,
  getTerrainModels: getAll,
  getTerrainModelUploads: getUploads,
  updateTerrainModel: update,
  isTerrainModelsDirty,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [showAlert, setShowAlert] = useState({ row: {}, show: false });
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      await getAll();
      await getUploads();
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, getAll, getUploads]);

  const handleUpdateItem = (row, field, value) => {
    if (field === 'is_project' && value === 1) {
      terrainModels.filter(i => i.is_project).forEach((item) => {
        dispatch(setTerrainModelValue(item, field, 0));
      });
    }
    dispatch(setTerrainModelValue(row, field, value));
    // dispatch(updateTerrainModel(id, field, value));
  };

  const renderRadioButton = row => (
    <Radio
      checked={!!row.is_project}
      disabled={!terrainModelUploads.some(
        u => u.terrain_model_id === row.id && u.status >= 5,
      )}
      onClick={() => { dispatch(setTerrainModelValue(row, 'is_project', 1)); }}
    />
  );

  const columns = [
    {
      field: 'name', title: translate('common:Name'),
    },
    {
      field: 'description',
      title: translate('common:Description'),
      width: '100%',
      editable: true,
      updateOnChange: true,
    },
    {
      field: 'parent_id',
      title: translate('common:Parent'),
      editable: true,
      selectItems: terrainModels,
    },
    {
      field: 'is_project',
      title: translate('common:IsDefault'),
      renderCustomComponent: row => renderRadioButton(row),
    },
  ];
  const renderHeader = () => (
    <Formik
      initialValues={{ name: '', description: '', parent_id: 0 }}
      validate={(values) => {
        const errors = {};
        if (!values.name) {
          errors.name = 'Required';
        }
        if (values.description.length > 255) {
          errors.description = 'Max 255 characters';
        }
        return errors;
      }}
      onSubmit={async (values, { resetForm }) => {
        resetForm();
        await add(values);
      }}
    >
      {({
        isSubmitting,
        errors,
        isValid,
      }) => (
        <Form style={{ display: 'flex', alignItems: 'center' }}>

          <FormControl className={classes.modelName}>
            <FormLabel className={classes.formLabel}>
              {translate('common:Name')}
            </FormLabel>
            <FormGroup>
              <Field
                type="text"
                name="name"
                className={classnames('form-control', errors.name ? classes.fieldError : classes.field)}
              />
            </FormGroup>
          </FormControl>

          <FormControl className={classes.modelDescription}>
            <FormLabel className={classes.formLabel}>
              {translate('common:Description')}
            </FormLabel>
            <FormGroup>
              <Field
                type="text"
                name="description"
                className={classnames('form-control', errors.description ? classes.fieldError : classes.field)}
              />
            </FormGroup>
          </FormControl>

          <FormControl className={classes.modelParent}>
            <FormLabel className={classes.formLabel}>
              {`${translate('common:Parent')}:`}
            </FormLabel>
            <FormGroup>
              <Field
                name="parent_id"
                render={({ field, form }) => (
                  <PF2MSearchSelectOutlined
                    {...field}
                    placeholder={null}
                    style={{
                      color: '#647886',
                      width: 290,
                      borderWidth: 0,
                    }}
                    onChange={e => form.setFieldValue('parent_id', e.target.value)}
                  >
                    {[{ value: 0, label: translate('common:Select') }]
                      .concat(...terrainModels
                        .map(e => ({ value: e.id, label: e.name })))}
                  </PF2MSearchSelectOutlined>
                )}
              />
            </FormGroup>
          </FormControl>
          <div style={{ marginTop: 15 }}>
            <PF2MAddButton disabled={isSubmitting || !isValid} />
          </div>
        </Form>
      )}
    </Formik>
  );

  return (
    <div className={classes.tabContainer}>
      {renderHeader()}
      <PF2MTable
        data={terrainModels.filter(e => e.active)}
        columns={columns}
        updateItem={(row, field, val) => handleUpdateItem(row, field, val)}
        removeItem={row => setShowAlert({ row, show: true })}
      />
      <Footer
        isDirty={isTerrainModelsDirty}
        discard={() => {}}
        sendData={() => update(terrainModels)}
      />
      <PF2MAlertDialog
        confirmText={translate('common:Yes')}
        description={translate('validation:WarningTerrainModelDelete')}
        open={showAlert.show}
        onClose={() => setShowAlert({ show: false })}
        onConfirm={() => {
          setShowAlert({ show: false });
          handleUpdateItem(showAlert.row, 'active', 0);
        }}
      />
    </div>
  );
};

TerrainModelTab.propTypes = {
  classes: PropTypes.object.isRequired,
  terrainModels: PropTypes.array,
  terrainModelUploads: PropTypes.array,
  getTerrainModels: PropTypes.func,
  getTerrainModelUploads: PropTypes.func,
  isTerrainModelsDirty: PropTypes.bool,
  updateTerrainModel: PropTypes.func,
  addTerrainModel: PropTypes.func,
};

TerrainModelTab.defaultProps = {
  terrainModels: [],
  terrainModelUploads: [],
  getTerrainModels: null,
  getTerrainModelUploads: null,
  isTerrainModelsDirty: false,
  updateTerrainModel: null,
  addTerrainModel: null,
};

const mapStateToProps = state => ({
  terrainModels: state.terrain.terrainModels,
  terrainModelUploads: state.terrain.terrainModelUploads,
  isTerrainModelsDirty: state.terrain.isTerrainModelsDirty,
});

const mapDispatchToProps = {
  getTerrainModels,
  getTerrainModelUploads,
  updateTerrainModel,
  addTerrainModel,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TerrainModelTab));

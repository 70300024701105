export const SET_MAINTENANCE_CODE_GROUPS_LIST = 'SET_MAINTENANCE_CODE_GROUPS_LIST';
export const SET_MAINTENANCE_CODE_GROUP_VALUE = 'SET_MAINTENANCE_CODE_GROUP_VALUE';
export const RESET_MAINTENANCE_CODE_GROUPS = 'RESET_MAINTENANCE_CODE_GROUPS';
export const ADD_MAINTENANCE_CODE_GROUP = 'ADD_MAINTENANCE_CODE_GROUP';
export const SET_MAINTENANCE_CODE_TYPES_LIST = 'SET_MAINTENANCE_CODE_TYPES_LIST';
export const SET_MAINTENANCE_SYSTEMS_LIST = 'SET_MAINTENANCE_SYSTEMS_LIST';
export const SET_MAINTENANCE_SYSTEM_VALUE = 'SET_MAINTENANCE_SYSTEM_VALUE';
export const RESET_MAINTENANCE_SYSTEMS = 'RESET_MAINTENANCE_SYSTEMS';
export const ADD_MAINTENANCE_SYSTEM = 'ADD_MAINTENANCE_SYSTEM';
export const SET_MAINTENANCE_SYSTEM_ITEMS_LIST = 'SET_MAINTENANCE_SYSTEM_ITEMS_LIST';
export const SET_MAINTENANCE_SYSTEM_ITEM_VALUE = 'SET_MAINTENANCE_SYSTEM_ITEM_VALUE';
export const RESET_MAINTENANCE_SYSTEM_ITEMS = 'RESET_MAINTENANCE_SYSTEM_ITEMS';
export const ADD_MAINTENANCE_SYSTEM_ITEM = 'ADD_MAINTENANCE_SYSTEM_ITEM';
export const SET_MAINTENANCE_FAILURE_MODES_LIST = 'SET_MAINTENANCE_FAILURE_MODES_LIST';
export const SET_MAINTENANCE_FAILURE_MODE_VALUE = 'SET_MAINTENANCE_FAILURE_MODE_VALUE';
export const RESET_MAINTENANCE_FAILURE_MODES = 'RESET_MAINTENANCE_FAILURE_MODES';
export const ADD_MAINTENANCE_FAILURE_MODE = 'ADD_MAINTENANCE_FAILURE_MODE';
export const SET_MAINTENANCE_CODES_LIST = 'SET_MAINTENANCE_CODES_LIST';
export const SET_MAINTENANCE_CODE_VALUE = 'SET_MAINTENANCE_CODE_VALUE';
export const RESET_MAINTENANCE_CODES = 'RESET_MAINTENANCE_CODES';
export const ADD_MAINTENANCE_CODE = 'ADD_MAINTENANCE_CODE';

export const SET_LOGOUT_LIMIT = 'SET_LOGOUT_LIMIT';
export const SET_LOGOUT_LIMIT_VALUE = 'SET_LOGOUT_LIMIT_VALUE';
export const RESET_LOGOUT_LIMIT = 'RESET_LOGOUT_LIMIT';
export const SET_FAILURE_MODES_SYSTEM_ITEMS_LIST = 'SET_FAILURE_MODES_SYSTEM_ITEMS_LIST';
export const SET_FAILURE_MODE_SYSTEM_ITEM_VALUE = 'SET_FAILURE_MODE_SYSTEM_ITEM_VALUE';
export const RESET_FAILURE_MODE_SYSTEM_ITEMS = 'RESET_FAILURE_MODE_SYSTEM_ITEMS';

export const SET_EQUIPMENT_GROUPS_SYSTEM_ITEMS_LIST = 'SET_EQUIPMENT_GROUPS_SYSTEM_ITEMS_LIST';
export const SET_EQUIPMENT_GROUP_SYSTEM_ITEM_VALUE = 'SET_EQUIPMENT_GROUP_SYSTEM_ITEM_VALUE';
export const RESET_EQUIPMENT_GROUPS_SYSTEM_ITEMS = 'RESET_EQUIPMENT_GROUPS_SYSTEM_ITEMS';

export const SET_ALL_EQUIPMENT_GROUP_SYSTEM_ITEM_VALUE = 'SET_ALL_EQUIPMENT_GROUP_SYSTEM_ITEM_VALUE';

export const SET_MAINTENANCE_OPERATION_ITEMS_LIST = 'SET_MAINTENANCE_OPERATION_ITEMS_LIST';
export const SET_MAINTENANCE_OPERATION_ITEMS = 'SET_MAINTENANCE_OPERATION_ITEMS';
export const RESET_MAINTENANCE_OPERATION_ITEMS = 'RESET_MAINTENANCE_OPERATION_ITEMS';
export const SET_CURRENT_MAINTENANCE_OPERATION_ITEMS = 'SET_CURRENT_MAINTENANCE_OPERATION_ITEMS';
export const CREATE_MAINTENANCE_OPERATION_ITEM = 'CREATE_MAINTENANCE_OPERATION_ITEM';

export const SET_MAINTENANCE_TURN_GROUP_VALUE = 'SET_MAINTENANCE_TURN_GROUP_VALUE';
export const RESET_MAINTENANCE_TURN_GROUP = 'RESET_MAINTENANCE_TURN_GROUP';

export const SET_FAILURE_MODE_EQUIPMENT_GROUP_MAINTENANCE_LIST = 'SET_FAILURE_MODE_EQUIPMENT_GROUP_MAINTENANCE_LIST';
export const SET_FAILURE_MODE_EQUIPMENT_GROUP_MAINTENANCE_FILTERED = 'SET_FAILURE_MODE_EQUIPMENT_GROUP_MAINTENANCE_FILTERED';
export const RESET_FAILURE_MODE_EQUIPMENT_GROUPS = 'RESET_FAILURE_MODE_EQUIPMENT_GROUPS';

export const SET_MAINTENANCE_MANAGEMENT_FILTER_ITEM = 'SET_MAINTENANCE_MANAGEMENT_FILTER_ITEM';
export const SET_MAINTENANCE_MANAGEMENT_FILTER = 'SET_MAINTENANCE_MANAGEMENT_FILTER';

import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';

const PF2MAccordion = ({ items, headerStyle = {}, bodyStyle = {} }) => {
  const [expanded, setExpanded] = React.useState(0);

  const accordionList = items.map((item, i) => (
    <Accordion
      key={item.id}
      expanded={expanded === i}
      onChange={() => setExpanded(i)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        style={headerStyle}
      >
        {item.head}
      </AccordionSummary>
      <AccordionDetails style={{ display: 'block', ...bodyStyle }}>
        {item.body}
      </AccordionDetails>
    </Accordion>
  ));

  return (
    <div>
      {accordionList}
    </div>
  );
};

PF2MAccordion.propTypes = {
  items: PropTypes.array,
  headerStyle: PropTypes.object,
  bodyStyle: PropTypes.object,
};

PF2MAccordion.defaultProps = {
  items: [],
  headerStyle: {},
  bodyStyle: {},
};

export default PF2MAccordion;

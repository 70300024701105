import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from '~/components/Card';
import PF2MButton from '~/components/PF2MButton';

const TerrainSettingsMenu = ({
  showLayer,
  showPolygon,
  compareModel,
  resetDefaultModel,
  setCameraControl,
  layerList,
  modelList,
  polygonList,
  visible,
  menuIndex,
  style,
  ...props
}) => {
  const LayerList = () => (
    <table>
      <thead><tr><th>Layers</th></tr></thead>
      <tbody>
        {layerList.map(layer => (
          <tr key={layer.name}>
            <td>
              <label htmlFor={`${layer.name}-checkbox`}>
                <input
                  style={{ marginRight: '10px' }}
                  id={`${layer.name}-checkbox`}
                  type="checkbox"
                  onChange={e => showLayer(layer.default_name, e.target.checked)}
                />
                {layer.default_name}
              </label>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const ModelList = () => (
    <table>
      <thead><tr><th>Planned Models</th></tr></thead>
      <tbody>
        <tr>
          <PF2MButton
            style={{
              padding: '4px',
              height: '30px',
            }}
            onClick={() => resetDefaultModel()}
          >
            Reset
          </PF2MButton>
        </tr>
        {modelList.map(model => (
          <tr key={model.timestamp_plan}>
            <td>
              <PF2MButton
                style={{
                  padding: '4px',
                  height: '30px',
                }}
                onClick={() => compareModel(model)}
              >
                Compare
              </PF2MButton>
              {`${model.plan_date} - ${model.plan_time}`}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
  const CameraControl = () => (
    <table>
      <thead><tr><th>Camera Control</th></tr></thead>
      <tbody>
        <tr>
          <td>
            <PF2MButton
              onClick={() => setCameraControl('top')}
            >
              Top View
            </PF2MButton>
            <PF2MButton
              onClick={() => setCameraControl('iso')}
            >
              Isometric View
            </PF2MButton>
          </td>
        </tr>
      </tbody>
    </table>
  );
  const PolygonsList = () => (
    <table>
      <thead><tr><th>Polygons</th></tr></thead>
      <tbody>
        {Object.keys(polygonList[0]).map(poly => (
          <tr key={poly}>
            <td>
              <label htmlFor={`${poly}-checkbox`}>
                <input
                  style={{ marginRight: '10px' }}
                  id={`${poly}-checkbox`}
                  type="checkbox"
                  onChange={e => showPolygon(poly, e.target.checked)}
                />
                {polygonList[0][poly].data.name}
              </label>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <div>
      <Card
        {...props}
        style={{
          left: '80px',
          width: '300px',
          visibility: visible ? 'visible' : 'hidden',
          opacity: visible ? 1 : 0,
          position: 'absolute',
          backgroundColor: '#e0e0e0',
          marginTop: '12px',
          transition: 'all 150ms ease 0s',
          ...style,
        }}
      >
        <div />
        <CardBody>
          <div />
          {menuIndex === 1 && <LayerList />}
          {menuIndex === 2 && <ModelList />}
          {menuIndex === 3 && <CameraControl />}
          {menuIndex === 4 && <PolygonsList />}
        </CardBody>
      </Card>
    </div>
  );
};

TerrainSettingsMenu.propTypes = {
  showLayer: PropTypes.func,
  showPolygon: PropTypes.func,
  compareModel: PropTypes.func,
  resetDefaultModel: PropTypes.func,
  setCameraControl: PropTypes.func,
  layerList: PropTypes.array,
  modelList: PropTypes.array,
  polygonList: PropTypes.array,
  visible: PropTypes.bool,
  menuIndex: PropTypes.number,
  style: PropTypes.object,
};

TerrainSettingsMenu.defaultProps = {
  showLayer: () => {},
  showPolygon: () => {},
  compareModel: () => {},
  resetDefaultModel: () => {},
  setCameraControl: () => {},
  layerList: [],
  modelList: [],
  polygonList: [],
  visible: false,
  menuIndex: 0,
  style: {},
};

export default TerrainSettingsMenu;

import React from 'react';
import {
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import PF2MButton from '~/components/PF2MButton';
import styles from './styles';
import { setPriorityValue, addPriority } from '~/store/lineup/actions';

const subElementCard = ({
  subElement,
  filters,
  dispatch,
}) => {
  const handleChange = (value) => {
    if (!filters.element_id && !filters.lineup_id) { dispatch(setPriorityValue(subElement.id, 'priority', value)); } else {
      dispatch(addPriority({ ...filters, element_point_id: subElement.id, priority: value }));
    }
  };
  return (
    <div style={{
      height: 120,
      margin: '0 5px',
      color: '#fff',
      backgroundColor: '#f2aa00',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderRadius: 5,
      border: '2px solid #3d5465',
    }}
    >
      <div>
        <h5>{subElement.name}</h5>
      </div>
      <div style={{
        display: 'flex', flexDirection: 'row nowrap', width: 250, justifyContent: 'space-evenly',
      }}
      >
        <PF2MButton color="primary" type="button" onClick={() => { handleChange(1); }}>Alta</PF2MButton>
        <PF2MButton color="primary" type="button" onClick={() => { handleChange(2); }}>Média</PF2MButton>
        <PF2MButton color="primary" type="button" onClick={() => { handleChange(3); }}>Baixa</PF2MButton>
      </div>
    </div>
  );
};

subElementCard.propTypes = {
  subElement: PropTypes.object.isRequired,
  filters: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
};

subElementCard.defaultProps = {
  filters: {},
};

export default withStyles(styles)(subElementCard);

const styles = {
  outlinedInput: {
    color: 'white',
    fontSize: 12,
    height: 2,
  },
  tableRow: {
    padding: 4,
  },
  button: {
    margin: 5,
  },
  buttonWhiteText: {
    color: 'white',
    margin: 5,
  },
  input: {
    padding: 10,
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 14,
    textAlign: 'center',
    maxWidth: 60,
    height: 30,
  },
  textInput: {
    padding: 10,
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 14,
    textAlign: 'center',
    maxWidth: 200,
    height: 30,
  },
  tableCell: {
    padding: 5,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    // maxWidth: 200,
    minWidth: 150,
  },
  table: {
    position: 'relative',
  },
};

export default styles;

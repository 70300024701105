import React from 'react';
import {
  MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDateTimePicker, DatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

// handle clearing outside if value can be changed outside of the component

const getComponent = ({ dateWithoutKeyboard, isDateTime }) => {
  if (dateWithoutKeyboard) return DatePicker;
  if (isDateTime) return KeyboardDateTimePicker;
  return KeyboardDatePicker;
};

const PF2MDatepicker = ({
  field,
  form,
  isDateTime,
  format,
  dateWithoutKeyboard,
  ...other
}) => {
  const { t: translate } = useTranslation();
  const currentError = form?.errors[field.name];
  const Component = getComponent({ dateWithoutKeyboard, isDateTime });

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
      <Component
        allowKeyboardControl
        clearable
        format={format || translate('date:DateFormat')}
        disableFuture
        name={field.name}
        value={field.value}
        helperText={currentError}
        error={Boolean(currentError)}
        onChange={date => form.setFieldValue(field.name, date, true)}
        autoOk
        {...other}
      />
    </MuiPickersUtilsProvider>
  );
};


PF2MDatepicker.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
};

PF2MDatepicker.defaultProps = {
  field: {},
  form: {},
};

export default withStyles({})(PF2MDatepicker);

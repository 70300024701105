import React from 'react';
import { useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PF2MButton from '~/components/PF2MButton';
import PropTypes from 'prop-types';
import styles from '../styles';

const LineupMask = ({ children, classes }) => {
  const selectedLineup = useSelector(state => state.lineup.selectedLineup);
  const { t: translate } = useTranslation();

  if (!selectedLineup.id) {
    return (
      <div className={classes.tabContainer}>
        <div style={{
          flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center',
        }}
        >
          <div className={classes.containerTypeSelector}>
            <div>
              <PF2MButton
                color="primary"
                style={{ width: 400 }}
                onClick={() => { window.location.href = '/lineup/scenarios'; }}
              >
                {`${translate('common:LoadScenarioToContinue')}`}
              </PF2MButton>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      {children}
    </div>
  );
};

LineupMask.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.object,
};

LineupMask.defaultProps = {
  classes: {},
  children: {},
};

export default withStyles(styles)(LineupMask);

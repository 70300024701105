import { managerGridHeight } from '~/utils';

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: '#E6E7E8',
    height: managerGridHeight,
    padding: 10,
  },
  scenarioInfoHeader: {
    width: '100%',
    letterSpacing: '1px',
    margin: '10px 0 10px 20px',
    color: '#647886',
    fontFamily: 'Barlow Condensed',
    fontWeight: 600,
    fontSize: 18,
    textTransform: 'uppercase',
  },
  icon: {
    marginRight: 10,
  },
  button: {
    color: '#FFF',
    margin: 5,
  },
  containerTypeSelector: {
    display: 'flex',
    justifyContent: 'center',
    padding: 0,
    maxWidth: 280,
  },
  labelTypeSelector: {
    color: '#647886',
    fontFamily: 'Barlow Condensed',
    fontWeight: 600,
    fontSize: 18,
    textTransform: 'uppercase',
    marginRight: 10,
  },
  formLabel: {
    color: '#647886',
    fontSize: 12,
    fontFamily: 'Roboto',
    marginBottom: 5,
  },
  tabContainer: {
    borderRadius: 5,
    borderTopLeftRadius: 0,
    backgroundColor: 'white',
    padding: 10,
  },
};

export default styles;

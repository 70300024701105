const styles = {
  noLabel: {
    marginTop: 0,
  },
  placeholder: {
    color: '#CDCDCD',
  },
  typeSelector: {
    height: 40,
    width: 200,
    fontFamily: 'Roboto',
    fontWeight: 300,
    fontSize: 14,
    color: '#647886',
  },
};

export default styles;

import React, { useState, useEffect, useRef } from 'react';
import {
  withStyles, FormControl, FormLabel, FormGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import Footer from '~/pages/Maintenance/Footer';
import Header from '~/pages/Maintenance/Header';
import PF2MTable from '~/components/PF2MTable';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import {
  getSystemItems,
  addSystemItem,
  setSystemItemValue,
  updateSystemItems,
  resetSystemItems,
  getSystems,
  getSystemItemsTemplate,
  exportSystemItems,
  importSystemItems,
} from '~/store/maintenance/actions';
import PF2MAddButton from '~/components/PF2MAddButton';

const validateName = (items, system, name) => {
  if (items.some(c => c.id_system_maintenance === system
    && String(c.name).toLowerCase() === String(name).toLowerCase())) {
    return false;
  }
  return true;
};

const SystemItemsTab = ({ classes }) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const isSystemItemsDirty = useSelector(state => state.maintenance.isSystemItemsDirty);
  const systems = useSelector(state => state.maintenance.systems);
  const systemItems = useSelector(state => state.maintenance.systemItems);
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  const { t: translate } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      dispatch(getSystems());
      await dispatch(getSystemItems());
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch]);

  const columns = [
    {
      field: 'system_maintenance_name', title: translate('common:System'),
    },
    {
      field: 'name', title: translate('common:Name'), width: '100%', editable: true, updateOnChange: true,
    },
    {
      field: 'active',
      title: 'STATUS',
      editable: true,
    },
  ];

  const renderHeader = () => (
    <Formik
      ref={formikRef}
      initialValues={{ name: '', system: 0 }}
      validate={(values) => {
        const errors = {};
        if (!values.name) {
          errors.name = translate('validation:RequiredField');
        }
        if (values.system <= 0) {
          errors.system = translate('validation:RequiredField');
        }
        if (!validateName(systemItems, values.system, values.name)) {
          errors.name = translate('validation:AlreadyRegisteredName');
        }
        return errors;
      }}
      onSubmit={async (values, { resetForm }) => {
        const { system, name } = values;
        const selectedSystem = systems.find(t => t.id === system);
        const newItem = {
          name,
          id_system_maintenance: system,
          system_maintenance_name: selectedSystem.name,
        };

        await dispatch(addSystemItem(newItem));
        resetForm();
      }}
    >
      {({
        isSubmitting,
        errors,
        isValid,
      }) => (
        <Form style={{ display: 'flex', minHeight: 75 }}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              {`${translate('common:System')}:`}
            </FormLabel>
            <FormGroup>
              <Field
                name="system"
                render={({ field, form }) => (
                  <PF2MSearchSelectOutlined
                    {...field}
                    placeholder={null}
                    onChange={e => form.setFieldValue('system', e.target.value)}
                    className={classnames(
                      form.errors[field.name] ? classes.fieldError : classes.field,
                    )}
                  >
                    {[{ value: 0, label: translate('common:Select') }]
                      .concat(...systems
                        .map(e => ({ value: e.id, label: e.name })))}
                  </PF2MSearchSelectOutlined>
                )}
              />
            </FormGroup>
          </FormControl>
          <FormControl className={classes.fieldDescription}>
            <FormLabel className={classes.formLabel}>
              {`${translate('common:Name')}:`}
            </FormLabel>
            <FormGroup>
              <Field
                type="text"
                name="name"
                className={classnames('form-control', errors.name ? classes.fieldError : classes.field)}
              />
              <ErrorMessage
                name="name"
                component="span"
                className={classes.errorMessage}
              />
            </FormGroup>
          </FormControl>
          <div style={{ marginTop: 15 }}>
            <PF2MAddButton disabled={isSubmitting || !isValid} />
          </div>
        </Form>
      )}
    </Formik>
  );

  return (
    <div className={classes.tabContainer}>
      <Header
        getTemplateAction={() => dispatch(getSystemItemsTemplate())}
        importAction={file => dispatch(importSystemItems(file))}
        exportAction={() => dispatch(exportSystemItems())}
      />
      {renderHeader()}
      <PF2MTable
        className={classes.table}
        data={systemItems}
        columns={columns}
        updateItem={(row, field, value) => dispatch(setSystemItemValue(row, field, value))}
      />
      <Footer
        isDirty={isSystemItemsDirty}
        discard={() => dispatch(resetSystemItems())}
        sendData={() => dispatch(updateSystemItems(systemItems))}
      />
    </div>
  );
};

SystemItemsTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

SystemItemsTab.defaultProps = {};

export default withStyles(styles)(SystemItemsTab);

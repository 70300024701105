import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import Footer from '~/pages/Manager/Footer';
import PF2MTable from '~/components/PF2MTable';
import {
  getFlowTypes,
  getHandlingTypes,
  setHandlingTypeValues,
  updateHandlingTypes,
  addHandlingType,
  resetHandlingTypes,
} from '~/store/manager/actions';
import Header from './Header';

const DrillingTypesTab = ({
  classes,
  handlingTypes,
  flowTypes,
  getHandlingTypes: getAll,
  setHandlingTypeValues: updateItem,
  updateHandlingTypes: update,
  addHandlingType: add,
  resetHandlingTypes: reset,
  isHandlingTypesDirty,
}) => {
  const dispatch = useDispatch();
  const [dataLoaded, setDataLoaded] = useState(false);
  const { t: translate } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      dispatch(getFlowTypes());
      await getAll();
    }

    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, getAll, dispatch]);

  const columns = [
    {
      field: 'name',
      title: translate('common:Name'),
      editable: true,
    },
    {
      field: 'description',
      title: translate('common:Description'),
      width: '100%',
      editable: true,
    },
    {
      field: 'flow_type_id',
      title: translate('common:Flow'),
      editable: true,
      selectItems: flowTypes,
    },
    {
      field: 'active',
      title: 'STATUS',
      editable: true,
    },
  ];

  return (
    <div className={classes.tabContainer}>
      <Header add={add} flowTypes={flowTypes} />
      <PF2MTable
        data={handlingTypes}
        columns={columns}
        updateItem={updateItem}
      />
      <Footer
        isDirty={isHandlingTypesDirty}
        discard={() => reset()}
        sendData={() => update(handlingTypes)}
      />
    </div>
  );
};

DrillingTypesTab.propTypes = {
  classes: PropTypes.object.isRequired,
  handlingTypes: PropTypes.array,
  flowTypes: PropTypes.array,
  getHandlingTypes: PropTypes.func,
  setHandlingTypeValues: PropTypes.func,
  updateHandlingTypes: PropTypes.func,
  addHandlingType: PropTypes.func,
  resetHandlingTypes: PropTypes.func,
  isHandlingTypesDirty: PropTypes.bool,
};

DrillingTypesTab.defaultProps = {
  handlingTypes: [],
  flowTypes: [],
  getHandlingTypes: () => {},
  setHandlingTypeValues: () => {},
  updateHandlingTypes: () => {},
  addHandlingType: () => {},
  resetHandlingTypes: () => {},
  isHandlingTypesDirty: false,
};

const mapStateToProps = state => ({
  handlingTypes: state.manager.handlingTypes,
  flowTypes: state.manager.flowTypes,
  isHandlingTypesDirty: state.manager.isHandlingTypesDirty,
});

const mapDispatchToProps = {
  getHandlingTypes,
  setHandlingTypeValues,
  updateHandlingTypes,
  addHandlingType,
  resetHandlingTypes,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(DrillingTypesTab));

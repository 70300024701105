const styles = {
  head: {
    backgroundColor: 'white',
    color: '#647886',
    fontFamily: 'Barlow Condensed',
    fontWeight: 600,
    fontSize: 16,
  },
  body: {
    fontSize: 14,
    color: '#647886',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWight: 300,
  },
};

export default styles;

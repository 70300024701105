import React, {
  useEffect, useRef, useState, useMemo,
} from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import Footer from '~/pages/Manager/Footer';
import PF2MTable from '~/components/PF2MTable';
import Header from './Header';
import {
  getOperationTypes,
  setOperationTypeValue,
  resetOperationTypes,
  updateOperationTypes,
  getEquipmentTypes,
  getTransportOperationTypes,
  setTransportOperationTypeValue,
  resetTransportOperationTypes,
  updateTransportOperationTypes,
} from '~/store/manager/actions';

const OperationTypeTab = ({ classes }) => {
  const operationTypes = useSelector(state => state.manager.operationTypes);
  const transportOperationTypes = useSelector(
    state => state.manager.transportOperationTypes,
  );
  const isOperationTypesDirty = useSelector(
    state => state.manager.isOperationTypesDirty,
  );
  const isTransportOperationTypesDirty = useSelector(
    state => state.manager.isTransportOperationTypesDirty,
  );
  const [selectedEquipamentType, setSelectedEquipamentType] = useState(0);
  const equipmentTypes = useSelector(state => state.manager.equipmentTypes);
  const dispatch = useDispatch();
  const formikRef = useRef();
  const { t: translate } = useTranslation();

  const [
    operationTypesItems,
    setOpTypesValue,
    resetOpTypes,
    updateOpTypes,
    isOpTypesDirty,
  ] = useMemo(() => {
    if (selectedEquipamentType === 2) {
      return [
        transportOperationTypes,
        setTransportOperationTypeValue,
        resetTransportOperationTypes,
        updateTransportOperationTypes,
        isTransportOperationTypesDirty,
      ];
    }
    return [
      operationTypes,
      setOperationTypeValue,
      resetOperationTypes,
      updateOperationTypes,
      isOperationTypesDirty,
    ];
  }, [
    selectedEquipamentType,
    operationTypes,
    transportOperationTypes,
    isOperationTypesDirty,
    isTransportOperationTypesDirty,
  ]);

  useEffect(() => {
    async function fetchData() {
      dispatch(getOperationTypes(true));
      dispatch(getTransportOperationTypes(true));
      dispatch(getEquipmentTypes());
    }
    fetchData();
  }, [dispatch]);

  const columns = [
    {
      field: 'name',
      title: translate('common:OperationType'),
      editable: true,
      width: '50%',
    },
    {
      field: 'equipment_type_name',
      title: translate('common:Equipament'),
      width: '50%',
    },
    { field: 'active', title: 'STATUS', editable: true },
  ];

  return (
    <div className={classes.tabContainer}>
      <Header
        equipmentTypes={equipmentTypes}
        update={setSelectedEquipamentType}
      />
      <PF2MTable
        data={operationTypesItems.filter(
          ot => ot.id_equipment_type === selectedEquipamentType,
        )}
        columns={columns}
        updateItem={(row, field, value) => {
          dispatch(setOpTypesValue(row, field, value));
        }}
      />
      <Footer
        isDirty={isOpTypesDirty}
        discard={() => {
          dispatch(resetOpTypes());

          if (formikRef && formikRef.current) {
            formikRef.current.resetForm({ name: '' });
          }
        }}
        sendData={() => dispatch(updateOpTypes(operationTypesItems, { all: true }))
        }
      />
    </div>
  );
};

OperationTypeTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OperationTypeTab);

/* eslint-disable camelcase */
import React, { useEffect, useRef } from 'react';
import {
  withStyles, FormControl, FormLabel, FormGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from '~/pages/Quality/styles';
import Footer from '~/pages/Manager/Footer';
import PF2MTable from '~/components/PF2MTable';
import {
  getContents,
  addContent,
  setContentValue,
  resetContents,
  updateContents,
} from '~/store/quality/actions';
import PF2MAddButton from '~/components/PF2MAddButton';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';

const ContentTab = ({ classes }) => {
  const contents = useSelector(state => state.quality.contents);
  const isContentsDirty = useSelector(state => state.quality.isContentsDirty);
  const dispatch = useDispatch();
  const formikRef = useRef();
  const { t: translate } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      dispatch(getContents());
    }
    fetchData();
  }, [dispatch]);

  const columns = [
    {
      field: 'name',
      title: translate('common:Name'),
      width: '100%',
      editable: true,
    },
    {
      field: 'value_type',
      title: translate('common:ValueType'),
      type: 'boolean',
      disabled: true,
      options: [
        { id: 0, name: translate('common:Number') },
        { id: 1, name: translate('common:Text') },
      ],
    },
    {
      field: 'active',
      title: 'STATUS',
      editable: true,
    },
  ];

  const renderHeader = () => (
    <Formik
      ref={formikRef}
      initialValues={{ name: '', value_type: 0 }}
      validate={(values) => {
        const errors = {};
        const { name, value_type } = values;

        if (!name) {
          errors.name = translate('validation:RequiredField');
        }

        if (value_type !== 0 && value_type !== 1) {
          errors.value_type = translate('validation:RequiredField');
        }

        return errors;
      }}
      onSubmit={async (values, { resetForm }) => {
        dispatch(addContent(values));
        resetForm();
      }}
    >
      {({
        isSubmitting,
        errors,
        isValid,
        values,
        setFieldValue,
      }) => (
        <Form>
          <div style={{ display: 'flex', marginBottom: 15, minHeight: 75 }}>
            <FormControl className={classes.classGroupName} style={{ marginLeft: 0 }}>
              <FormLabel className={classes.formLabel}>
                {`${translate('common:Name')}:`}
              </FormLabel>
              <FormGroup>
                <Field
                  type="text"
                  name="name"
                  className={classnames('form-control', errors.name ? classes.fieldError : classes.field)}
                  style={{ width: '100%' }}
                />
                <ErrorMessage
                  name="name"
                  component="span"
                  className={classes.errorMessage}
                />
              </FormGroup>
            </FormControl>
            <FormControl className={classes.classGroupName} style={{ marginLeft: 0 }}>
              <FormLabel className={classes.formLabel}>
                {`${translate('common:ValueType')}:`}
              </FormLabel>
              <FormGroup>
                <PF2MSearchSelectOutlined
                  name="value_type"
                  onChange={e => setFieldValue('value_type', e.target.value)}
                  className={classnames(errors.value_type ? classes.fieldError : classes.field)}
                  value={values.value_type}
                >
                  {[
                    { value: 0, label: translate('common:Number') },
                    { value: 1, label: translate('common:Text') },
                  ]}
                </PF2MSearchSelectOutlined>
                <ErrorMessage
                  name="value_type"
                  component="span"
                  className={classes.errorMessage}
                />
              </FormGroup>
            </FormControl>
            <div style={{ marginTop: 12 }}>
              <PF2MAddButton disabled={isSubmitting || !isValid} />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );

  return (
    <div className={classes.tabContainer}>
      {renderHeader()}
      <PF2MTable
        data={contents}
        columns={columns}
        updateItem={(row, field, value) => dispatch(setContentValue(row, field, value))}
      />
      <Footer
        isDirty={isContentsDirty}
        discard={() => {
          dispatch(resetContents());

          if (formikRef) {
            formikRef.current.resetForm({ name: '' });
          }
        }}
        sendData={() => dispatch(updateContents(contents))}
      />
    </div>
  );
};

ContentTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ContentTab);

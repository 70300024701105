import React, { useState, useEffect } from 'react';
import {
  withStyles, FormControl, FormLabel, FormGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import Footer from '~/pages/Manager/Footer';
import PF2MTable from '~/components/PF2MTable';
import {
  getInputUnits,
  setInputUnitValue,
  updateInputUnits,
  addInputUnit,
  resetInputUnits,
} from '~/store/underground/actions';
import PF2MAddButton from '~/components/PF2MAddButton';

const isValidName = (name, items) => {
  const existingItem = items.find(c => c.name === name);
  return !existingItem;
};

const InputUnitTab = ({ classes }) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const dispatch = useDispatch();
  const isInputUnitsDirty = useSelector(state => state.underground.isInputUnitsDirty);
  const inputUnits = useSelector(state => state.underground.inputUnits);
  const { t: translate } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      await dispatch(getInputUnits());
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch]);

  const columns = [
    {
      field: 'name', title: translate('common:Unit'), editable: true, width: '100%', updateOnChange: true,
    },
    {
      field: 'active', title: 'STATUS', editable: true,
    },
  ];

  const renderHeader = () => (
    <Formik
      initialValues={{ name: '' }}
      validate={({ name }) => {
        const errors = {};
        if (!name) {
          errors.name = translate('validation:RequiredField');
        }
        if (name) {
          if (!isValidName(name, inputUnits)) {
            errors.name = translate('validation:AlreadyRegisteredUnit');
          }
        }

        return errors;
      }}
      onSubmit={async (values, { resetForm }) => {
        await dispatch(addInputUnit(values));
        resetForm();
      }}
    >
      {({
        isSubmitting,
        errors,
        isValid,
      }) => (
        <Form style={{ display: 'flex', marginBottom: 15, minHeight: 75 }}>
          <FormControl className={classes.classGroupName}>
            <FormLabel className={classes.formLabel}>
              {`${translate('common:Unit')}:`}
            </FormLabel>
            <FormGroup>
              <Field type="text" name="name" className={classnames('form-control', errors.name ? classes.fieldError : classes.field)} />
              <ErrorMessage
                name="name"
                component="span"
                className={classes.errorMessage}
              />
            </FormGroup>
          </FormControl>
          <div style={{ marginTop: 10 }}>
            <PF2MAddButton disabled={isSubmitting || !isValid} />
          </div>
        </Form>
      )}
    </Formik>
  );

  return (
    <div className={classes.tabContainer}>
      {renderHeader()}
      <PF2MTable
        data={inputUnits}
        columns={columns}
        updateItem={(row, field, value) => dispatch(setInputUnitValue(row, field, value))}
      />
      <Footer
        isDirty={isInputUnitsDirty}
        discard={() => dispatch(resetInputUnits())}
        sendData={() => dispatch(updateInputUnits(inputUnits))}
      />
    </div>
  );
};

InputUnitTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

InputUnitTab.defaultProps = {};

export default withStyles(styles)(InputUnitTab);

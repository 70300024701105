import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PF2MTable from '~/components/PF2MTable';
import Footer from '~/pages/Manager/Footer';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import { getEquipmentTypes, getEquipmentsGroups } from '~/store/manager/actions';
import { getFuels, exportFuels, importFuels } from '~/store/fuel/actions';
import Header from '~/pages/Manager/Header';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';

const FuelTab = ({
  classes,
  fuels,
  equipmentsGroups,
  equipmentTypes,
  getFuels: getAll,
  getEquipmentTypes: loadEquipmentTypes,
  getEquipmentsGroups: loadEquipmentsGroups,
  exportFuels: exportAction,
  importFuels: importAction,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [equipmentType, setEquipmentType] = useState(0);
  const [equipmentGroup, setEquipmentGroup] = useState(0);
  const { t: translate } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      getAll();
      loadEquipmentsGroups();
      await loadEquipmentTypes();
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, getAll, loadEquipmentsGroups, loadEquipmentTypes]);

  const columns = [
    { field: 'equipment_name', title: translate('common:Equipment') },
    { field: 'fueltank_capacity', title: translate('common:TankCapacityLiters') },
    { field: 'fuel_consumption', title: translate('common:LitersPerHourConsumption') },
  ];

  return (
    <div className={classes.tabContainer}>
      <Header
        importAction={importAction}
        exportAction={exportAction}
      />
      <hr />
      <div className={classes.containerTypeSelector}>
        <div style={{ display: 'flex', alignItems: 'baseline', margin: 10 }}>
          <p className={classes.labelTypeSelector}>
            {`${translate('common:EquipmentType')}:`}
          </p>
          <PF2MSearchSelectOutlined
            value={equipmentType}
            onChange={e => setEquipmentType(e.target.value)}
            placeholder={null}
          >
            {[{ value: 0, label: translate('common:Select') }]
              .concat(...equipmentTypes
                .map(e => ({ value: e.id, label: e.name })))}
          </PF2MSearchSelectOutlined>
        </div>
        <div style={{ display: 'flex', alignItems: 'baseline', margin: 10 }}>
          <p className={classes.labelTypeSelector}>
            {`${translate('common:EquipmentGroup')}:`}
          </p>
          <PF2MSearchSelectOutlined
            value={equipmentGroup}
            onChange={e => setEquipmentGroup(e.target.value)}
            placeholder={null}
          >
            {[{ value: 0, label: translate('common:Select') }]
              .concat(...equipmentsGroups
                .filter(e => equipmentType === 0 || e.id_equipament === equipmentType)
                .map(e => ({ value: e.id, label: e.name })))}
          </PF2MSearchSelectOutlined>
        </div>
      </div>
      <PF2MTable
        data={fuels
          .filter(f => (equipmentGroup === 0 || f.equipment_group_id === equipmentGroup)
            && (equipmentType === 0 || f.equipment_type_id === equipmentType))}
        keyExtractor={item => `${item.id}${item.equipment_id}`}
        columns={columns}
        className={classes.grid}
      />
      <Footer />
    </div>
  );
};

FuelTab.propTypes = {
  classes: PropTypes.object.isRequired,
  fuels: PropTypes.array,
  equipmentTypes: PropTypes.array,
  equipmentsGroups: PropTypes.array,
  getEquipmentTypes: PropTypes.func,
  getEquipmentsGroups: PropTypes.func,
  getFuels: PropTypes.func,
  exportFuels: PropTypes.func,
  importFuels: PropTypes.func,
};

FuelTab.defaultProps = {
  fuels: [],
  equipmentTypes: [],
  equipmentsGroups: [],
  getEquipmentTypes: null,
  getEquipmentsGroups: null,
  getFuels: null,
  exportFuels: null,
  importFuels: null,
};

const mapStateToProps = state => ({
  fuels: state.fuel.fuels,
  equipmentTypes: state.manager.equipmentTypes,
  equipmentsGroups: state.manager.equipmentsGroups,
});

const mapDispatchToProps = {
  getEquipmentTypes,
  getEquipmentsGroups,
  getFuels,
  exportFuels,
  importFuels,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FuelTab));

import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import styles from './styles';

function PF2MScrollbar({ classes, children, ...rest }) {
  return (
    <PerfectScrollbar className={classes.perfectScrollbar} {...rest}>
      {children}
    </PerfectScrollbar>
  );
}

PF2MScrollbar.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,
};

PF2MScrollbar.defaultProps = {};
export default withStyles(styles)(PF2MScrollbar);

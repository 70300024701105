import React from 'react';
import Add from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Done from '@material-ui/icons/Done';
import { withStyles, Typography, Fab } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './styles';

const Header = ({
  classes,
  filters,
  isDirty,
  data,
  updateData,
  massEdit,
  massEditEnable,
  restoreData,
  createData,
}) => {
  const { t: translate } = useTranslation();

  return (
    <div
      className="container"
      style={{
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography variant="h4">
        {translate('common:Cycles')}
      </Typography>
      <div>
        <Fab
          variant="extended"
          color="primary"
          size="medium"
          onClick={() => createData(filters)}
          className={classes.button}
          disabled={!createData}
        >
          <Add />
          {translate('common:Add')}
        </Fab>
        { massEdit
        && (
        <Fab
          variant="extended"
          size="medium"
          color="primary"
          onClick={() => massEdit(filters)}
          className={classes.button}
          disabled={!massEditEnable}
        >
          <EditIcon />
          {translate('common:MassEdit')}
        </Fab>
        )
        }
        <Fab
          variant="extended"
          size="medium"
          color="primary"
          onClick={() => restoreData()}
          className={classes.buttonWhiteText}
          disabled={!isDirty}
        >
          <DeleteOutline />
          {translate('common:DiscardChanges')}
        </Fab>
        <Fab
          variant="extended"
          size="medium"
          color="secondary"
          onClick={() => updateData(data, filters)}
          className={classes.buttonWhiteText}
          disabled={!isDirty}
        >
          <Done />
          {translate('common:End')}
        </Fab>
      </div>
    </div>
  );
};

Header.propTypes = {
  classes: PropTypes.object,
  isDirty: PropTypes.bool,
  filters: PropTypes.object,
  data: PropTypes.array,
  updateData: PropTypes.func,
  massEdit: PropTypes.func,
  massEditEnable: PropTypes.bool,
  restoreData: PropTypes.func,
  createData: PropTypes.func,
};

Header.defaultProps = {
  classes: {},
  isDirty: false,
  filters: {},
  data: [],
  updateData: null,
  massEdit: null,
  massEditEnable: false,
  restoreData: null,
  createData: null,
};

export default withStyles(styles)(Header);

import React, { useEffect, useState } from 'react';
import {
  withStyles, Typography, Grid, FormControl, FormLabel, FormGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import CreateIcon from '@material-ui/icons/Create';
import { useTranslation } from 'react-i18next';
import styles from '../../styles';
import PF2MButton from '~/components/PF2MButton';
import { Formik, Form, Field } from 'formik';
import {
  Card, CardBody, CardHeader, CardIcon,
} from '~/components/Card';
import {
  addTerrainModelUploadLayer,
  updateTerrainModelUploadLayer,
  updateTerrainModelUpload,
  processFile,
  getTerrainModels,
} from '~/store/terrain/actions';
import PF2MTable from '~/components/PF2MTable';
import PF2MAddButton from '~/components/PF2MAddButton';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';

const TerrainReviewModal = ({
  classes, handleClose, currentModel,
}) => {
  const { t: translate } = useTranslation();
  const [reviewData, setReviewData] = useState({});
  const terrainModels = useSelector(state => state.terrain.terrainModels);
  const terrainModelUploads = useSelector(state => state.terrain.terrainModelUploads);
  const modelLayers = useSelector(state => state.terrain.terrainModelLayers);
  const defaultLayers = useSelector(state => state.terrain.terrainDefaultLayers);
  const [reviewDataLayers, setReviewDataLayers] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (reviewData.layers) {
      const layers = reviewData.layers
        .replace(/\[|\]|'| /g, '')
        .split(',')
        .map(l => ({ id: l, name: l }));
      setReviewDataLayers(layers);
    }
  }, [reviewData]);

  useEffect(() => {
    setReviewData(terrainModelUploads.find(m => m.id === currentModel.id));
  }, [currentModel, terrainModels, terrainModelUploads]);

  const columns = [
    // todo: add translation
    {
      field: 'default_name', title: translate('common:LayerType'),
    },
    {
      field: 'name',
      title: translate('common:LayerName'),
      editable: true,
      selectItems: reviewDataLayers,
    },
  ];

  // eslint-disable-next-line no-unused-vars
  const handleSetUploadDefault = () => {
    dispatch(updateTerrainModelUpload([{ ...reviewData, is_default: true }]));
    handleClose();
  };

  const handleApprove = () => {
    dispatch(updateTerrainModelUploadLayer(modelLayers));
    dispatch(processFile({ ...currentModel, processLayers: modelLayers }));
    handleClose();
  };

  const handleReject = () => {
    dispatch(updateTerrainModelUpload(
      [{
        ...reviewData, status: 0, active: 0, update_status: 'U',
      }],
    ));
    dispatch(getTerrainModels());
    handleClose();
  };

  const renderHeader = () => (
    <div style={{ width: 350 }}>
      {reviewData.heightmap && (
      <Typography
        variant="h5"
        align="center"
        gutterBottom
      >
        {`${translate('common:TerrainViewer')}`}
        <br />
        {`${translate('common:Name')}: ${currentModel.terrain_model_name} `}
        <br />
        <img className={classes.terrainImg} src={reviewData.heightmap} alt={'Terrain heightmap'} width={350} />
      </Typography>
      )}
      {!reviewData.heightmap && (
        <Typography
          variant="h5"
          align="center"
          gutterBottom
        >
          Loading image...
        </Typography>
      )}
    </div>
  );

  const renderForm = () => (
    <Formik
      initialValues={{ terrain_default_layers_id: '', name: '' }}
      validate={(values) => {
        const errors = {};
        if (!values.terrain_default_layers_id) {
          errors.name = 'Required';
        }
        return errors;
      }}
      onSubmit={async (values, { resetForm }) => {
        resetForm();
        const newValues = {
          ...values,
          default_name: defaultLayers.find(l => l.id === values.terrain_default_layers_id).name,
          terrain_model_upload_id: currentModel.id,
          active: true,
        };
        dispatch(addTerrainModelUploadLayer(newValues));
      }}
    >
      {({
        isSubmitting,
        isValid,
      }) => (
        <Form style={{ display: 'flex', alignItems: 'center' }}>

          <FormControl className={classes.modelParent}>
            <FormLabel className={classes.formLabel}>
              {translate('common:LayerType')}
            </FormLabel>
            <FormGroup>
              <Field
                name="terrain_default_layers_id"
                render={({ field, form }) => (
                  <PF2MSearchSelectOutlined
                    {...field}
                    placeholder={null}
                    style={{
                      color: '#647886',
                      borderWidth: 0,
                      maxWidth: '127px',
                    }}
                    onChange={e => form.setFieldValue('terrain_default_layers_id', e.target.value)}
                  >
                    {[{ value: 0, label: translate('common:Select') }]
                      .concat(...defaultLayers
                        .filter(l => !modelLayers
                          .some(ml => ml.terrain_default_layers_id === l.id
                            && ml.terrain_model_upload_id === currentModel.terrain_model_upload_id))
                        .map(e => ({ value: e.id, label: e.name })))}
                  </PF2MSearchSelectOutlined>
                )}
              />
            </FormGroup>
          </FormControl>

          <FormControl className={classes.modelParent}>
            <FormLabel className={classes.formLabel}>
              {translate('common:LayerName')}
            </FormLabel>
            <FormGroup>
              <Field
                name="name"
                render={({ field, form }) => (
                  <PF2MSearchSelectOutlined
                    {...field}
                    placeholder={null}
                    style={{
                      color: '#647886',
                      borderWidth: 0,
                    }}
                    onChange={e => form.setFieldValue('name', e.target.value)}
                  >
                    {[{ value: 0, label: translate('common:Select') }]
                      .concat(...reviewDataLayers
                        .map(e => ({ value: e.id, label: e.name })))}
                  </PF2MSearchSelectOutlined>
                )}
              />
            </FormGroup>
          </FormControl>
          <div style={{ marginTop: 15 }}>
            <PF2MAddButton disabled={isSubmitting || !isValid} />
          </div>
        </Form>
      )}
    </Formik>
  );

  const renderTable = () => (
    <div>
      {renderForm()}
      <PF2MTable
        columns={columns}
        data={currentModel
          ? modelLayers.filter(
            l => l.terrain_model_upload_id === currentModel.id,
          )
          : []}
        customStyles={{
          height: '400px',
          table: {
            minWidth: '400px',
          },
        }
      }
      />
    </div>
  );

  return (
    <div>
      {currentModel && (
        <Card>
          <CardHeader icon>
            <CardIcon color="warning">
              <CreateIcon />
            </CardIcon>
          </CardHeader>
          <CardBody>
            <Grid container style={{ alignSelf: 'center' }}>
              <Grid item xs={5}>
                {renderHeader()}
              </Grid>
              <Grid item xs={7}>
                {renderTable()}
              </Grid>
            </Grid>
            <Grid container style={{ justifyContent: 'center' }}>
              <PF2MButton
                color="primary"
                onClick={handleApprove}
              >
                {translate('common:Approve')}
              </PF2MButton>
              <PF2MButton
                color="primary"
                onClick={handleReject}
              >
                {translate('common:Delete')}
              </PF2MButton>
            </Grid>
          </CardBody>
        </Card>
      )}
    </div>
  );
};

TerrainReviewModal.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func,
  currentModel: PropTypes.object,
};

TerrainReviewModal.defaultProps = {
  handleClose: null,
  currentModel: null,
};

export default withStyles(styles)(TerrainReviewModal);

/* eslint-disable no-restricted-globals */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable no-bitwise */
import React, {
  useRef, useEffect, useState, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  importHeightmap,
  getTerrainModels,
  getTerrainModelLayers,
  getTerrainModelUploads,
  getCloudPointReport,
  getPolygons,
} from '~/store/terrain/actions';
import * as THREE from 'three';
import styles from '../../DispatchControl/styles';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import PF2MTooltip from '~/components/PF2MTooltip';
import TerrainToolbar from './TerrainToolbar';
import { ExcavatorModal, TruckModal } from '~/components/EquipmentStatusModals';
import { getDispatchOriginsExcavators, getDispatchOriginsTrucks } from '~/store/dispatch/actions';
import TerrainCanvas from './TerrainCanvas';

const SCALE = 120;
// const HMSCALE = 1024;
const TRUCK_TYPE_ID = 2;
const EXCAVATOR_TYPE_ID = 1;
// eslint-disable-next-line no-unused-vars
const LOADER_TYPE_ID = 15;
const terrainCanvas = new TerrainCanvas(SCALE);

const TerrainTab = ({ classes, setSelectedTab }) => {
  const dispatch = useDispatch();
  const terrainModels = useSelector(state => state.terrain.terrainModels);
  const terrainModelLayers = useSelector(state => state.terrain.terrainModelLayers);
  const terrainModelUploads = useSelector(state => state.terrain.terrainModelUploads);
  const polygons = useSelector(state => state.terrain.polygons);
  const cloudPoints = useSelector(state => state.terrain.cloudPointReport);
  const [isLoading, setIsLoading] = useState(false);
  const [defaultModel, setDefaultModel] = useState({});
  const [showAlert, setShowAlert] = useState(false);

  const tabletConfiguration = useSelector(state => state.manager.tabletConfiguration);
  const time = tabletConfiguration.find(k => k.key === 'dispatch_update_time')?.value || 5;

  // eslint-disable-next-line no-unused-vars
  const [tooltip, _setTooltip] = useState(
    { show: false, data: { lat: 0, lng: 0 }, polygonData: {} },
  );
  const canvasBody = useRef();
  const [equipmentStatusModalData, setEquipmentStatusModalData] = useState(null);
  const getModalInfo = type => (
    equipmentStatusModalData && type === equipmentStatusModalData.equip_type
      ? equipmentStatusModalData.equip_id
      : null
  );

  const { t: translate } = useTranslation();

  // const terrainCanvas = new TerrainCanvas(SCALE);

  const dispatchCustomEvent = (state, options) => {
    canvasBody.current.dispatchEvent(new CustomEvent('statechange', {
      detail: {
        state,
        ...options,
      },
      bubbles: true,
    }));
  };

  useEffect(() => {
    if (Object.keys(polygons).length > 0) {
      terrainCanvas.setPolygons(polygons);
    }
  }, [polygons]);

  useEffect(() => {
    if (terrainModelLayers.length > 0) {
      terrainCanvas.setLayers(terrainModelLayers);
    }
  }, [terrainModelLayers]);

  const possibleEquipmentsTypes = [1, 2, 5, 15];
  useEffect(() => {
    if (cloudPoints.length > 0) {
      terrainCanvas.setEquipments(
        cloudPoints.filter(c => possibleEquipmentsTypes.includes(c.equip_type)),
        setEquipmentStatusModalData,
      );
    }
  }, [cloudPoints]);

  useEffect(() => {
    const canvas = canvasBody.current;
    return () => {
      const child = Array.from(canvas.children).find(c => c.localName === 'canvas');
      if (child) {
        canvas.removeChild(child);
      }
    };
  }, []);

  const newDefaultModel = useMemo(() => terrainModels.find(
    model => model.is_project === 1,
  ), [terrainModels]);

  const refreshCloudPoints = () => {
    dispatch({ type: 'SKIP_LOADING', payload: true });
    dispatch(getCloudPointReport({
      start_lat: newDefaultModel.top_left_lat_lng[0],
      start_lng: newDefaultModel.top_left_lat_lng[1],
      end_lat: newDefaultModel.bottom_right_lat_lng[0],
      end_lng: newDefaultModel.bottom_right_lat_lng[1],
    }));
    dispatch({ type: 'SKIP_LOADING', payload: false });
  };


  useEffect(() => {
    if (!newDefaultModel) {
      setShowAlert(true);
    } else if ((!terrainModels && !newDefaultModel) || !newDefaultModel.path) {
      dispatch(getTerrainModels({ default: true }));
      dispatch(getDispatchOriginsTrucks());
      dispatch(getDispatchOriginsExcavators());
    } else if (!isLoading) {
      setDefaultModel(newDefaultModel);
      dispatch(importHeightmap(newDefaultModel, 'heightmap'));
      setIsLoading(true);
    } else if (newDefaultModel.heightmap) {
      setDefaultModel(newDefaultModel);
      dispatch(getTerrainModelLayers(true, newDefaultModel.terrain_model_upload_id));
      dispatch(getTerrainModelUploads());
      dispatch(getPolygons({
        start_lat: newDefaultModel.top_left_lat_lng[0],
        start_lng: newDefaultModel.top_left_lat_lng[1],
        end_lat: newDefaultModel.bottom_right_lat_lng[0],
        end_lng: newDefaultModel.bottom_right_lat_lng[1],
      }));
      if (time) {
        refreshCloudPoints();
        const interval = setInterval(refreshCloudPoints, time * 1000);
        return () => clearInterval(interval);
      }
    }

    return () => {};
  }, [newDefaultModel, dispatch, isLoading, time]);

  useEffect(() => {
    if (defaultModel.heightmap
      && !Array.from(canvasBody.current.children).some(c => c.localName === 'canvas')) {
      new THREE.TextureLoader().load(defaultModel.heightmap, (hm) => {
        terrainCanvas.setup(canvasBody.current, hm, defaultModel);
      });
    }
  // eslint-disable-next-line
  }, [canvasBody, defaultModel, terrainCanvas]);

  const handleCompareModel = (model) => {
    dispatch(importHeightmap(model, 'heightmap', (url) => {
      new THREE.TextureLoader().load(url, (hm) => {
        dispatchCustomEvent('comparemodel', { heightmap: hm });
      });
    }));
  };

  const handleLayerVisibility = (layer, visible) => terrainCanvas
    .setLayerVisibility(layer, visible);
  const handlePolygonVisibility = (polygon, visible) => terrainCanvas
    .setPolygonVisibility(polygon, visible);
  const resetDefaultModel = () => terrainCanvas.resetModel();
  // const setCameraControl = mode => terrainCanvas.setCameraControl(mode);

  return (
    <div className={classes.tabContainer}>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '82vh',
        }}
        ref={canvasBody}
      >
        <div style={{ position: 'relative', width: 0, height: 0 }}>
          <TerrainToolbar
            terrainModelLayers={terrainModelLayers}
            terrainModelUploads={terrainModelUploads}
            defaultModel={defaultModel}
            polygonList={[polygons]}
            handleCompareModel={handleCompareModel}
            handleLayerVisibility={handleLayerVisibility}
            handlePolygonVisibility={handlePolygonVisibility}
            resetDefaultModel={resetDefaultModel}
            setCameraControl={terrainCanvas.setCameraControl}
          />
        </div>
      </div>
      <PF2MTooltip
        title={(
          <div>
            lat:
            {tooltip.data.lat}
            <br />
            lng:
            {tooltip.data.lng}
            <br />
            name:
            {tooltip.data.name}
          </div>
        )}
        open={tooltip.show}
      >
        <div />
      </PF2MTooltip>
      <PF2MAlertDialog
        confirmText={translate('common:Ok')}
        description={translate('validation:WarningNoDefaultModelSelected')}
        open={showAlert}
        onClose={() => {}}
        hasCancel={false}
        onConfirm={() => {
          setShowAlert(false);
          setSelectedTab(0);
        }}
      />
      <TruckModal
        modalData={getModalInfo(TRUCK_TYPE_ID)}
        closeModal={() => setEquipmentStatusModalData(null)}
        classes={classes}
      />
      <ExcavatorModal
        modalData={getModalInfo(EXCAVATOR_TYPE_ID)}
        closeModal={() => setEquipmentStatusModalData(null)}
        classes={classes}
      />
      {/* <LoaderModal
        modalData={getModalInfo(LOADER_TYPE_ID)}
        closeModal={() => setEquipmentStatusModalData(null)}
        classes={classes}
      /> */}
    </div>
  );
};

TerrainTab.propTypes = {
  classes: PropTypes.object.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
};

TerrainTab.defaultProps = {};

export default withStyles(styles)(TerrainTab);

import types from './types';

const INITIAL_STATE = {
  codeTypes: [],
  equipmentTypes: [],
  excavatorGroups: [],
  elements: [],
  subElements: [],
  materials: [],
  operatorGroups: [],
  operators: [],
  turns: [],
  domainsLoaded: false,
  exceptionTypes: types.EXCEPTION_TYPES,
  codeGroups: [],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_DOMAINS: {
      return {
        ...state,
        ...action.payload,
        domainsLoaded: true,
      };
    }
    default:
      return state;
  }
}

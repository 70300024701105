import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styles from './styles';
import CodesTab from './CodesTab';
import MigrationCodesRulesTab from './MigrationCodesRulesTab';
import PF2MTabs from '~/components/PF2MTabs';

const Codes = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { t: translate } = useTranslation();
  // eslint-disable-next-line no-underscore-dangle
  const roles = useSelector(state => state.auth.user._roles);

  const tabs = [
    { key: 0, label: translate('common:Codes') },
    { key: 1, label: translate('common:CodeMigrationRules'), dynamic: false },
  ];

  const handleChange = (event, value) => {
    setSelectedTab(value);
  };

  return (
    <>
      <PF2MTabs
        value={selectedTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        roles={roles}
      >
        {tabs}
      </PF2MTabs>
      {selectedTab === 0 ? (<CodesTab />) : null}
      {selectedTab === 1 ? (<MigrationCodesRulesTab />) : null}
    </>
  );
};

export default withStyles(styles)(Codes);

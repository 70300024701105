import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import RpmRangesTab from './RpmRangesTab';
import PF2MTabs from '~/components/PF2MTabs';

const RpmRanges = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { t: translate } = useTranslation();
  const tabs = [
    { key: 0, label: translate('common:RpmRanges') },
  ];

  const handleChange = (event, value) => {
    setSelectedTab(value);
  };

  return (
    <>
      <PF2MTabs
        value={selectedTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        {tabs}
      </PF2MTabs>
      {selectedTab === 0 ? (<RpmRangesTab />) : null}
    </>
  );
};

export default withStyles(styles)(RpmRanges);

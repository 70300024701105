import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import ContentTab from './ContentTab';
import ContentVariablesTab from './ContentVariablesTab';
import StockClosingTab from './StockClosingTab';
import PF2MTabs from '~/components/PF2MTabs';

const Content = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { t: translate } = useTranslation();

  const tabs = [
    { key: 0, label: translate('common:Content') },
    { key: 1, label: translate('common:ContentVariables') },
    { key: 2, label: translate('common:StockClosings') },
  ];

  const handleChange = (event, value) => setSelectedTab(value);

  return (
    <>
      <PF2MTabs
        value={selectedTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        {tabs}
      </PF2MTabs>
      {selectedTab === 0 ? (<ContentTab />) : null}
      {selectedTab === 1 ? (<ContentVariablesTab />) : null}
      {selectedTab === 2 ? (<StockClosingTab />) : null}
    </>
  );
};

export default withStyles(styles)(Content);

import { managerGridHeight } from '~/utils';

const styles = {
  container: {
    display: 'flex',
    backgroundColor: '#E6E7E8',
    height: managerGridHeight,
    padding: 10,
  },
  icon: {
    marginRight: 10,
  },
  button: {
    color: '#FFF',
    margin: 5,
  },
};

export default styles;

import React, { useEffect, useState, useRef } from 'react';
import {
  withStyles, FormControl, FormLabel, FormGroup, Checkbox,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import classnames from 'classnames';
import Add from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import Footer from '~/pages/Manager/Footer';
import PF2MTable from '~/components/PF2MTable';
import PF2MButton from '~/components/PF2MButton';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import {
  getEquipmentTypes,
  getEquipmentsGroups,
  getFandrillFans,
  addFandrillFan,
  setFandrillFanValue,
  resetFandrillFans,
  updateFandrillFans,
} from '~/store/manager/actions';
import { sortByProperties } from '~/utils';

const EQUIP_TYPES = [16, 17];

const FandrillFanTab = ({ classes }) => {
  const equipTypes = useSelector(state => state.manager.equipmentTypes);
  const equipGroups = useSelector(state => state.manager.equipmentsGroups);
  const fandrillFans = useSelector(state => state.manager.fandrillFans);
  const isFandrillFansDirty = useSelector(state => state.manager.isFandrillFansDirty);
  const [selectedEquipmentType, setSelectedEquipmentType] = useState(0);
  // eslint-disable-next-line
  const [selectedEquipmentGroup, setSelectedEquipmentGroup] = useState(0);
  const [fandrillFansInactive, setFandrillFansInactive] = useState(false);
  const dispatch = useDispatch();
  const formikRef = useRef();
  const { t: translate } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      dispatch(getFandrillFans(fandrillFansInactive));
      dispatch(getEquipmentTypes());
      dispatch(getEquipmentsGroups());
    }
    fetchData();
  }, [dispatch, fandrillFansInactive]);

  const columns = [
    {
      field: 'equipment_type_name',
      title: translate('common:EquipmentType'),
    },
    {
      field: 'equipment_group_name',
      title: translate('common:EquipmentGroup'),
    },
    {
      field: 'name',
      title: translate('common:Name'),
      width: '100%',
      editable: true,
      updateOnChange: true,
    },
    { field: 'active', title: 'STATUS', editable: true },
  ];

  const getNameById = (id, list) => {
    const { name = '' } = list.find(r => r.id === id) || {};
    return name;
  };

  const renderHeader = () => (
    <Formik
      ref={formikRef}
      initialValues={{ name: '', equipmentType: 0, equipmentGroup: 0 }}
      validate={(values) => {
        const errors = {};
        const { name, equipmentType, equipmentGroup } = values;

        if (!name) {
          errors.name = translate('validation:RequiredField');
        }

        const existingFan = fandrillFans
          .some(d => (
            String(d.name).toLowerCase() === String(name).toLowerCase()
            && d.equipment_group_id === equipmentGroup
          ));
        if (existingFan) {
          errors.name = translate('validation:AlreadyRegisteredName');
        }

        if (!equipmentType) {
          errors.equipmentType = translate('validation:RequiredField');
        }
        if (!equipmentGroup) {
          errors.equipmentGroup = translate('validation:RequiredField');
        }

        return errors;
      }}
      onSubmit={async (values, { resetForm }) => {
        const newValues = {
          name: values.name,
          equipment_group_id: values.equipmentGroup,
          equipment_type_id: values.equipmentType,
          equipment_group_name: getNameById(values.equipmentGroup, equipGroups),
          equipment_type_name: getNameById(values.equipmentType, equipTypes),
        };
        dispatch(addFandrillFan(newValues));
        resetForm();
      }}
    >
      {({
        isSubmitting,
        errors,
        isValid,
      }) => (
        <Form>
          <div style={{ display: 'flex', marginBottom: 15, minHeight: 75 }}>
            <FormControl>
              <FormLabel className={classes.formLabel}>
                {`${translate('common:EquipmentType')}`}
              </FormLabel>
              <FormGroup>
                <Field
                  name="equipmentType"
                  render={({ field, form }) => (
                    <PF2MSearchSelectOutlined
                      {...field}
                      placeholder={null}
                      className={classnames(
                        form.errors[field.name] ? classes.fieldError : classes.field,
                      )}
                      onChange={async (e) => {
                        form.setFieldValue('equipmentType', e.target.value);
                        setSelectedEquipmentType(e.target.value);
                      }}
                    >
                      {[{ value: 0, label: translate('common:Select') }]
                        .concat(...equipTypes
                          .filter(r => EQUIP_TYPES.includes(r.id))
                          .map(e => ({ value: e.id, label: e.name })))}
                    </PF2MSearchSelectOutlined>
                  )}
                />
                <ErrorMessage
                  name="equipmentType"
                  component="span"
                  className={classes.errorMessage}
                />
              </FormGroup>
            </FormControl>
            <FormControl style={{ marginLeft: 10 }}>
              <FormLabel className={classes.formLabel}>
                {`${translate('common:EquipmentGroup')}:`}
              </FormLabel>
              <FormGroup>
                <Field
                  name="equipmentGroup"
                  render={({ field, form }) => (
                    <PF2MSearchSelectOutlined
                      {...field}
                      placeholder={null}
                      className={classnames(
                        form.errors[field.name] ? classes.fieldError : classes.field,
                      )}
                      onChange={async (e) => {
                        form.setFieldValue('equipmentGroup', e.target.value);
                        setSelectedEquipmentGroup(e.target.value);
                      }}
                    >
                      {[{ value: 0, label: translate('common:Select') }]
                        .concat(...equipGroups
                          .filter(s => s.id_equipament === selectedEquipmentType)
                          .map(e => ({ value: e.id, label: e.name })))}
                    </PF2MSearchSelectOutlined>
                  )}
                />
                <ErrorMessage
                  name="equipmentGroup"
                  component="span"
                  className={classes.errorMessage}
                />
              </FormGroup>
            </FormControl>
            <FormControl className={classes.classGroupName} style={{ marginLeft: 10 }}>
              <FormLabel className={classes.formLabel}>
                {translate('common:FanName')}
              </FormLabel>
              <FormGroup>
                <Field
                  type="text"
                  name="name"
                  className={classnames(
                    'form-control', errors.name ? classes.fieldError : classes.field,
                  )}
                  style={{ width: '100%' }}
                />
                <ErrorMessage
                  name="name"
                  component="span"
                  className={classes.errorMessage}
                />
              </FormGroup>
            </FormControl>
            <div style={{ marginTop: 12 }}>
              <PF2MButton type="submit" disabled={isSubmitting || !isValid}>
                <Add className={classes.icon} />
                {`${translate('common:Register')}:`}
              </PF2MButton>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );

  return (
    <div className={classes.tabContainer}>
      {renderHeader()}
      <div style={{ display: 'flex', marginBottom: 1, minHeight: 5 }}>
        <div>
          <Checkbox
            checked={fandrillFansInactive}
            onChange={() => setFandrillFansInactive(!fandrillFansInactive)}
          />
        </div>
        <div>
          <p>{translate('common:ShowInactive')}</p>
        </div>
      </div>
      <PF2MTable
        data={sortByProperties(
          fandrillFans, [{ prop: 'name', type: 'string' }],
        )}
        columns={columns}
        updateItem={(row, field, value) => dispatch(setFandrillFanValue(row, field, value))}
      />
      <Footer
        isDirty={isFandrillFansDirty}
        discard={() => {
          dispatch(resetFandrillFans());

          if (formikRef) {
            formikRef.current.resetForm({ name: '' });
          }
        }}
        sendData={() => {
          dispatch(updateFandrillFans(fandrillFans));
          setFandrillFansInactive(!fandrillFansInactive);
        }}
      />
    </div>
  );
};

FandrillFanTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FandrillFanTab);

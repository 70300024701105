import api from '~/services/api';

export const setCachedTable = (table, data) => sessionStorage.setItem(table, data);

export const getCachedTable = table => sessionStorage.getItem(table);

export const clearCachedTable = table => sessionStorage.removeItem(table);

export const getLastUpdated = tableName => api.get(`/lastupdatetable?table_name=${tableName}`);

export const clearCache = () => sessionStorage.clear();

export const setCacheEnabled = cacheEnabled => localStorage.setItem('cacheEnabled', cacheEnabled);

export const isCacheEnabled = () => !localStorage.getItem('cacheEnabled') || localStorage.getItem('cacheEnabled') === 'true';

export const setColumnOrder = (tableName, order, user) => localStorage.setItem(`${tableName}:${user}`, JSON.stringify(order));

export const getColumnOrder = (tableName, user) => JSON.parse(localStorage.getItem(`${tableName}:${user}`));

export const saveFilters = (pageName, filter, user) => localStorage.setItem(`${pageName}:${user}:filter`, JSON.stringify(filter));

export const getFilters = (pageName, user) => JSON.parse(localStorage.getItem(`${pageName}:${user}:filter`));

const hasFilters = filters => Object.keys(filters).length > 0
|| (Object.keys(filters).length === 1 && filters.all === true);

export const requestCache = async (endpoint, request, filters = false, tableName = false) => {
  const cached = getCachedTable(endpoint);

  if (!isCacheEnabled()) {
    return request().then(data => data);
  }

  if (cached && !hasFilters(filters) && !!tableName) {
    return getLastUpdated(tableName).then((res) => {
      const { timestamp } = res.data.result[tableName];
      const parsed = JSON.parse(cached);
      if (timestamp === parsed.timestamp) {
        return parsed;
      }
      return request().then((data) => {
        setCachedTable(endpoint, JSON.stringify({ ...data, timestamp }));
        return data;
      });
    });
  } if (hasFilters(filters)) {
    return request().then(data => data);
  } if (tableName) {
    getLastUpdated(tableName).then((res) => {
      const { timestamp } = res.data.result[tableName];
      return request().then((data) => {
        setCachedTable(endpoint, JSON.stringify({ ...data, timestamp }));
        return data;
      });
    });
  }
  return request().then(data => data);
};

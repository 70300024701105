import React from 'react';
import api from '~/services/api';
import partesDiariasImage from '~/assets/partesdiarias.png';
import managerImage from '~/assets/gerenciamento.png';
import qualidadeImage from '~/assets/qualidade.png';
import combustivelImage from '~/assets/combustivel.png';
import despachoImage from '~/assets/despacho.png';
import telemetriaImage from '~/assets/telemetria.png';
import undergroundImage from '~/assets/underground.png';
import manutencaoImage from '~/assets/manutencao.png';
import classNames from 'classnames';
import i18n from '../i18n';
import icons from './modulesIcons';

// Create an function to validate if an value (param1) is
// in one of the keys (param2) of the passed obj
export function validatorOfOneOfObjKey(obj) {
  return (value, keys) => keys
    .map(key => obj[key])
    .filter(a => a !== undefined && a !== null)
    .includes(value);
}

export const getUsernameInitials = (username) => {
  const names = username.split(' ');
  return names.map(name => name.charAt(0)).slice(0, 2).join('');
};

export const checkModulePermission = (modules, moduleName) => {
  if (!modules) return false;
  const module = modules.find(m => m.module_value === moduleName);
  return !!module;
};

export const handleDynamicIcon = (item, roles) => {
  if (item.dynamic) {
    return checkModulePermission(roles, 'DYNAMIC')
      ? <icons.Dynamic />
      : <icons.DynamicOff />;
  } return null;
};

export const handleDynamicClass = (item, roles, classes) => {
  if (item.dynamic) {
    return classNames(classes.badge, checkModulePermission(roles, 'DYNAMIC')
      ? ''
      : classes.badgeDisabled);
  } return classes.noBadge;
};

export const downloadAxios = async (url, filename) => {
  const response = await api.get(url, { responseType: 'blob' });
  const url2Download = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.target = '_blank';
  link.href = url2Download;
  link.setAttribute('download', filename); // or any other extension
  document.body.appendChild(link);
  link.click();
};

export const isEmpty = value => (
  value === undefined
  || value === null
  || (typeof value === 'object' && Object.keys(value).length === 0)
  || (typeof value === 'string' && value.trim().length === 0)
);

export const modules = {
  DYNAMIC: {
    url: '/dynaimc', icon: icons.Dynamic, dynamic: true, disabled: true,
  },
  PARTES_DIARIAS: { url: '/parts', icon: icons.List, dynamic: true },
  MANAGER: { url: '/manager', icon: icons.ControlCamera },
  CONTRATOS: { url: '/contracts', icon: icons.Contracts },
  COMBUSTIVEL: { url: '/fuel', icon: icons.LocalGasStation },
  UNDERGROUND: { url: '/underground', icon: icons.OpenPit },
  CONTROLE_QUALIDADE: { url: '/quality', icon: icons.Quality },
  TELEMETRIA: { url: '/telemetry', icon: icons.Telemetry },
  MANUTENÇÃO: { url: '/maintenance', icon: icons.Maintenance, dynamic: true },
  DESPACHO: { url: '/dispatch', icon: icons.Excavator, dynamic: true },
  LINEUP: { url: '/lineup', icon: icons.Gantt },
  REPORTS: { url: '/report' },
};

export const modulesImages = {
  PARTES_DIARIAS: partesDiariasImage,
  MANAGER: managerImage,
  CONTRATOS: telemetriaImage,
  COMBUSTIVEL: combustivelImage,
  UNDERGROUND: undergroundImage,
  CONTROLE_QUALIDADE: qualidadeImage,
  TELEMETRIA: telemetriaImage,
  MANUTENÇÃO: manutencaoImage,
  DESPACHO: despachoImage,
  LINEUP: despachoImage,
  REPORTS: partesDiariasImage,
};

export const dailyPartsGridHeight = 'calc(100vh - 52px - 60px - 48px - 60px)';
export const dailyPartsUndergroundGridHeight = 'calc(100vh - 52px - 60px - 48px - 70px)';
export const dailyPartsUndergroundGridWidth = 'calc(100vw - 200px)';
export const dailyPartsMaintenanceGridHeight = 'calc((((100vh - 52px) - 60px) - 48px) - 100px)';
export const dailyPartsMaintenanceGridWidth = 'calc(100vw - 190px)';

export const managerGridHeight = 'calc(100vh - 48px)';

export const menu = {
  manager: [
    {
      key: '1',
      label: i18n.t('common:Operator'),
      path: 'operators',
    },
    {
      key: '2',
      label: i18n.t('common:Shift'),
      path: 'turns',
    },
    {
      key: '3',
      label: i18n.t('common:Team'),
      path: 'teams',
    },
    {
      key: '4',
      label: i18n.t('common:Equipment'),
      path: 'equipments',
    },
    {
      key: '5',
      label: i18n.t('common:Material'),
      path: 'materials',
    },
    {
      key: '6',
      label: i18n.t('common:Element'),
      path: 'elements',
    },
    {
      key: '7',
      label: i18n.t('common:Code'),
      path: 'codes',
      dynamic: true,
    },
    {
      key: '8',
      label: i18n.t('common:AverageLoad'),
      path: 'loads',
    },
    {
      key: '9',
      label: i18n.t('common:Weighing'),
      path: 'weighings',
    },
    {
      key: '10',
      label: 'CHECKLIST',
      path: 'checklist',
    },
    {
      key: '11',
      label: 'DMT',
      path: 'dmt',
    },
    {
      key: '12',
      label: i18n.t('common:Drilling'),
      path: 'drilling',
    },
    {
      key: '13',
      label: i18n.t('common:OperationType'),
      path: 'operationType',
    },
    {
      key: '14',
      label: i18n.t('common:Tractor'),
      path: 'tractor',
    },
    {
      key: '15',
      label: i18n.t('common:HourmeterTab'),
      path: 'hourmeterManager',
    },
    {
      key: '16',
      label: i18n.t('common:TaskCodesAndAlerts'),
      path: 'tasks',
    },
    {
      key: '17',
      label: i18n.t('common:GoalsBETA'),
      path: 'goals',
      tooltip: i18n.t('common:FeatureInDevelopment'),
    },
    {
      key: '18',
      label: i18n.t('common:User'),
      path: 'webusers',
    },
    {
      key: '19',
      label: i18n.t('common:CyclesAutomation'),
      path: 'cycleAutomation',
    },
  ],
  underground: [
    {
      key: '1',
      label: i18n.t('common:Control'),
      path: 'operationalcontrol',
    },
    {
      key: '2',
      label: i18n.t('common:DailyParts'),
      path: 'dailyparts',
    },
    {
      key: '3',
      label: i18n.t('common:Projects'),
      path: 'projectgroups',
    },
    {
      key: '4',
      label: i18n.t('common:Codes'),
      path: 'undergroundcodes',
    },
    {
      key: '5',
      label: i18n.t('common:UnitOp'),
      path: 'operation',
    },
    {
      key: '6',
      label: i18n.t('common:Priorities'),
      path: 'priority',
    },
    {
      key: '7',
      label: i18n.t('common:Advance'),
      path: 'monthlyadvance',
    },
    {
      key: '8',
      label: i18n.t('common:Shift'),
      path: 'turn',
    },
  ],
  maintenance: [
    {
      key: '1',
      label: i18n.t('common:Manager'),
      path: 'management',
    },
    {
      key: '2',
      label: i18n.t('common:TaskCodes'),
      path: 'codes',
    },
    {
      key: '3',
      label: i18n.t('common:System'),
      path: 'systems',
    },
    {
      key: '4',
      label: i18n.t('common:DailyParts'),
      path: 'dailyparts',
    },
    {
      key: '5',
      label: i18n.t('common:Shift'),
      path: 'turn',
    },
    // {
    //   key: '3',
    //   label: i18n.t('common:Configuration'),
    //   path: 'configurations',
    // },
  ],
  dispatch: [
    {
      key: '1',
      label: i18n.t('common:EquipScheduling'),
      path: 'equipmentprogram',
    },
    {
      key: '2',
      label: i18n.t('common:EquipmentStatusAbbreviated'),
      path: 'equipmentstatus',
    },
    {
      key: '3',
      label: i18n.t('common:LoadSchedules'),
      path: 'loadschedules',
      dynamic: true,
    },
    {
      key: '4',
      label: i18n.t('common:PerforatorSchedules'),
      path: 'perforatorschedules',
    },
    {
      key: '5',
      label: i18n.t('common:Dispatch'),
      path: 'dispatchcontrol',
    },
    {
      key: '6',
      label: i18n.t('common:OperationalControl'),
      path: 'operationalcontrol',
    },
    // {
    //   key: '6',
    //   label: 'Terrain',
    //   path: 'terrain',
    // },
  ],
  lineup: [
    {
      key: '1',
      label: i18n.t('common:Scenarios'),
      path: 'scenarios',
    },
    {
      key: '2',
      label: i18n.t('common:Elements'),
      path: 'elements',
    },
    {
      key: '3',
      label: i18n.t('common:Elevations'),
      path: 'elevations',
    },
    {
      key: '4',
      label: i18n.t('common:MineAvailability'),
      path: 'mineavailability',
    },
    {
      key: '5',
      label: i18n.t('common:Precedences'),
      path: 'precedences',
    },
    {
      key: '6',
      label: i18n.t('common:Priorities'),
      path: 'Priorities',
    },
    {
      key: '7',
      label: i18n.t('common:LoadAllocation'),
      path: 'loadallocations',
    },
  ],
  quality: [
    {
      key: '1',
      label: i18n.t('common:Content'),
      path: 'content',
    },
  ],
  telemetry: [
    {
      key: '1',
      label: i18n.t('common:RpmRanges'),
      path: 'rpmranges',
    },
    {
      key: '2',
      label: i18n.t('common:HourmeterLimits'),
      path: 'hourmeterlimits',
    },
  ],
};

// Estado atual dos equipamentos na tela de programação de carga do despacho
// Dados vem da code_report (situação de equipamento)
export const DispatchStatus = {
  OP: 'OP',
  PO: 'PO',
  MA: 'MA',
  IN: 'IN',
};

export const validateDispatchStatus = validatorOfOneOfObjKey(DispatchStatus);

// Estado atual dos equipamentos no ciclo para tela de origens do despacho
// Refere-se a tabela operation_type
export const DispatchOperationType = {
  IDLE: 0,
  LOAD_TRAFFIC: 1,
  LOAD_QUEUE: 2,
  LOAD: 4,
  UNLOAD_TRAFFIC: 5,
  UNLOAD_QUEUE: 6,
  UNLOAD: 7,
  OPEN: 11,
  LOAD_MANEUVER: 17,
  UNLOAD_MANEUVER: 18,
  RELEASED_EQUIPMENT: 20,
  SUPPLY: 24,
};

/**
 * Faz um
 * @param {string} value Coluna a comparar
 * @param {string[]} keys Lista de chaves de DispatchOerationType
 * @returns
 */
const objMapIncludesOnKeys = (obj, value, keys) => keys
  .map(key => DispatchOperationType[key])
  .filter(item => typeof item !== 'undefined')
  .includes(value);

export const dispatchOperationTypeIncludes = (value, ...keys) => objMapIncludesOnKeys(
  DispatchOperationType, value.operation_type, keys,
);

// "pretty names" para cada operation type
export const DispatchOperationTypeName = {
  5: 'Trânsito cheio',
  6: 'Fila cheio',
  18: 'Manobra cheio',
  7: 'Basculamento',
  1: 'Trânsito vazio',
  2: 'Fila vazio',
  17: 'Manobra vazio',
  4: 'Carregamento',
  0: 'Ocioso',
};

// Utilizado para o drag'n'drop nas telas de despacho
export const DispatchDragNDropTypes = {
  TRUCK: 'truck',
  LOAD: 'load',
  ORIGIN_TRUCK: 'origin_truck',
  ORIGIN_LOAD: 'origin_load',
  DESTINATION_TRUCK: 'destination_truck',
  POOL_TRUCK: 'pool_truck',
  POOL_LOAD: 'pool_load',
  PERFORATOR: 'perforator',
  FRONT: 'front',
};

export const parseFloat = (value) => {
  if (typeof value === 'number') {
    return value;
  }

  if (value.includes(',')) {
    return Number.parseFloat(value.replace(',', '.'));
  }

  return Number.parseFloat(value);
};

export const months = [
  { id: 1, name: i18n.t('date:January') },
  { id: 2, name: i18n.t('date:February') },
  { id: 3, name: i18n.t('date:March') },
  { id: 4, name: i18n.t('date:April') },
  { id: 5, name: i18n.t('date:May') },
  { id: 6, name: i18n.t('date:June') },
  { id: 7, name: i18n.t('date:July') },
  { id: 8, name: i18n.t('date:August') },
  { id: 9, name: i18n.t('date:September') },
  { id: 10, name: i18n.t('date:October') },
  { id: 11, name: i18n.t('date:November') },
  { id: 12, name: i18n.t('date:December') },
];

export const remapData = (items, newItem, key) => items.map((item) => {
  if (item[key] === newItem[key]) {
    return {
      ...newItem,
    };
  }
  return item;
});


export const remapDataByMultiKeys = (items, newItem, keys) => items.map((item) => {
  let keysMatch = true;
  keys.forEach((key) => {
    if (item[key] !== newItem[key]) {
      keysMatch = false;
    }
  });
  return keysMatch ? { ...newItem } : item;
});

export const findEquivalentItem = (items, newItem, keys) => items.find((item) => {
  let keysMatch = true;
  keys.forEach((key) => {
    if (item[key] !== newItem[key] && !(item[key] === undefined && newItem[key] === 0)) {
      keysMatch = false;
    }
  });
  return keysMatch;
});

// Orders list by properties, in the order in which they appear in the 'propsToCompare' arg
// Ex.: sortByProperties(list,
//        [{ prop: 'equipmentType', type: 'string' },
//        { prop: 'equipmentGroup', type: 'string' }])
// Will order 'list' by the properties 'equipmentType' and 'equipmentGroup'
export const sortByProperties = (list, propsToCompare) => {
  const comparerList = [];
  propsToCompare.forEach((arg) => {
    if (arg.type === 'number') {
      comparerList.push((a, b) => parseInt(a[arg.prop], 10) - parseInt(b[arg.prop], 10));
    } else {
      comparerList.push((a, b) => {
        if (a[arg.prop].toLowerCase() < b[arg.prop].toLowerCase()) return -1;
        if (a[arg.prop].toLowerCase() > b[arg.prop].toLowerCase()) return 1;
        return 0;
      });
    }
  });

  return list.sort((a, b) => {
    let ret = false;
    comparerList.forEach((comparer) => {
      ret = ret || comparer(a, b);
    });

    return ret;
  });
};

export const removeEmptyValuesFromObj = obj => Object.keys(obj)
  .filter(key => ![-1, 0, '0', ''].includes(obj[key]))
  .reduce((out, key) => {
    out[key] = obj[key]; // eslint-disable-line no-param-reassign
    return out;
  }, {});

export const convertToUrlParameters = (obj = null) => {
  if (!obj) return '';
  return Object.entries(obj).map(([key, val]) => `${key}=${val}`).join('&');
};

export const padNumber = (num, size) => {
  let newNum = num.toString();
  while (newNum.length < size) newNum = `0${newNum}`;
  return newNum;
};

export const toggleDeleteOrInsert = value => (value === 'D' ? 'I' : 'D');

export const convertFormatedDateStringToTimestamp = (str) => {
  /**
   * Receives a formatted date string and converts to timestamp
   * @param  {[str]} string formatted date string of the format dd/mm/yyy HH:MM:SS
   * @return {[number]}     Timestamp of that date
  */
  const dateParts = str.split(' ');
  const date = dateParts[0].split('/');
  const time = dateParts[1].split(':');

  return new Date(date[2], date[1] - 1, date[0], time[0], time[1], time[2]).getTime();
};

import React from 'react';
import PropTypes from 'prop-types';
import PF2MDatepicker from '~/components/PF2MDatepicker';
import { formatDate, parseDate } from '~/utils/moment';

const DateCell = ({
  format, isDateTime, noParseDate, dateWithoutKeyboard, views, ...p
}) => {
  const errors = {
    [p.columnDef.field]: p.error
      ? (p.helperText || true) // red line with empty message
      : p.helperText, // normal message
  };

  return (
    <PF2MDatepicker
      field={{
        name: p.columnDef.field,
        value: noParseDate
          ? p.value
          : parseDate(p.value, true),
      }}
      form={{
        errors,
      }}
      onChange={(e) => {
        p.bulkEdit(noParseDate ? e : formatDate(e, true), p);
      }}
      views={views}
      format={format}
      isDateTime={isDateTime}
      dateWithoutKeyboard={dateWithoutKeyboard}
    />
  );
};

DateCell.propTypes = {
  format: PropTypes.string.isRequired,
  isDateTime: PropTypes.bool,
  noParseDate: PropTypes.bool,
  dateWithoutKeyboard: PropTypes.bool,
  views: PropTypes.arrayOf(
    PropTypes.oneOf(['hours', 'minutes', 'seconds']),
  ),
};

DateCell.defaultProps = {
  isDateTime: false,
  noParseDate: false,
  dateWithoutKeyboard: false,
  views: undefined,
};

export default DateCell;

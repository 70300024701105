const styles = {

  scrollbar: {
    height: 'calc(100vh - 255px);',
  },

  card: {
    borderRadius: 15,
    textAlign: 'center',
    padding: 5,
    margin: 5,
    border: '4px solid #0095ff',
    cursor: 'pointer',
    transition: 'background-color .25s',
    '&:hover': {
      backgroundColor: '#F0F0F0',
    },

  },

  cardTitle: {
    color: '#0095ff',
    fontSize: 18,
    fontWeight: 600,
  },


  title: {
    whiteSpace: 'nowrap',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontFamily: 'Barlow Condensed',
    fontWeight: 600,
    marginBottom: 20,
    dontSize: 20,
  },

  danger: {
    color: 'red',
  },

  disabled: {
    color: 'gray',
    backgroundColor: 'lightgray',
    textDecoration: 'line-through',
    transition: 'background-color .25s',
    '&:hover, &:hover $cardTitle, &:hover $unfocused': {
      backgroundColor: '#c0c0c0',
    },
  },

  highlight: {
    backgroundColor: '#0095ff',
    color: 'white',
    transition: 'background-color .25s',
    '&:hover, &:hover $cardTitle, &:hover $unfocused': {
      backgroundColor: '#0081dd',
    },
    '& $unfocused': {
      color: '#003e8d',
    },
  },

  unfocused: {
    color: 'gray',
    fontSize: 12,
  },


};


export default styles;

import { getVersion, normalizeVersions, VERSION } from './version';

export const TOKEN_KEY = '@pf2m-token';
export const PASSWORD_STATUS_KEY = '@pf2m-password-status';
export const CONFIG = '@pf2m-config';

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

export const isSessionValid = async (api, version) => {
  const local = getVersion();

  if (!local) return false;

  const { currentVersion, localVersion } = normalizeVersions(
    { local, current: version },
  );

  if (currentVersion > localVersion) {
    return false;
  }

  try {
    const url = `${process.env.REACT_APP_API_URL}/validate_token?version=${version}`;
    await api.get(url);
    return true;
  } catch ({ response }) {
    // If 401, assume session as invalid (expired)
    return response && response.status !== 401;
  }
};

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const setToken = token => localStorage.setItem(TOKEN_KEY, token);

export const getConfig = () => JSON.parse(localStorage.getItem(CONFIG));

export const setConfig = config => localStorage.setItem(CONFIG, JSON.stringify(config));

export const login = (token, config, version) => {
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(CONFIG, JSON.stringify([...config]));
  localStorage.setItem(VERSION, version);
};

export const passwordValidation = (passwordStatus) => {
  localStorage.setItem(PASSWORD_STATUS_KEY, passwordStatus ? 'valid' : '');
};

export const getPasswordStatus = () => localStorage.getItem(PASSWORD_STATUS_KEY) === 'valid';

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(PASSWORD_STATUS_KEY);
  localStorage.removeItem(CONFIG);
};

import React, { useState, useEffect } from 'react';
import {
  withStyles, TextField, Fab, Typography, IconButton, InputAdornment,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import { Card, CardBody, CardHeader } from '~/components/Card';
import logo from '~/assets/logo.svg';
import { resetPassword } from '~/store/auth/actions';
import { ZendeskAPI } from 'react-zendesk';

const PasswordRedefiniton = ({ classes, errors }) => {
  const [fields, setFields] = useState({ password: '', passwordConfirm: '' });
  const [showPassword, setShowPassword] = useState(false);
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();

  const handleInputChange = name => (e) => {
    setFields({ ...fields, [name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(resetPassword(fields));
  };

  useEffect(() => {
    ZendeskAPI('webWidget', 'show');
    return () => {
      ZendeskAPI('webWidget', 'hide');
    };
  }, []);

  return (
    <>
      <div className={classes.background}>
        <form
          className={classes.formSignin}
          onSubmit={handleSubmit}
        >
          <Card>
            <CardHeader
              color="primary"
              classes={{
                cardHeader: classes.customHeader,
              }}
            >
              <img src={logo} alt="" />
            </CardHeader>
            <CardBody>
              <Typography
                variant="h4"
                align="center"
                gutterBottom
              >
                {translate('common:PasswordRedefiniton').toUpperCase()}
              </Typography>

              <TextField
                label={translate('common:Password')}
                fullWidth
                type={showPassword ? 'text' : 'password'}
                onChange={handleInputChange('password')}
                value={fields.password}
                margin="normal"
                variant="outlined"
                error={errors.length > 0}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        classes={{
                          root: classes.textFieldPassword,
                        }}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>),
                  className: classes.customHeight,
                }}
              />

              <TextField
                label={translate('common:ConfirmPassword')}
                fullWidth
                type={showPassword ? 'text' : 'password'}
                onChange={handleInputChange('passwordConfirm')}
                value={fields.passwordConfirm}
                margin="normal"
                variant="outlined"
                error={errors.length > 0}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        classes={{
                          root: classes.textFieldPassword,
                        }}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>),
                  className: classes.customHeight,
                }}
              />
              {errors.length > 0 ? (
                <Typography
                  variant="body2"
                  align="center"
                  gutterBottom
                  color="error"
                >
                  {translate(`common:${errors[0].message}`)}
                </Typography>
              ) : null}

              <Fab
                variant="extended"
                color="secondary"
                size="large"
                className={classes.signInButton}
                disabled={
                  fields.password !== fields.passwordConfirm
                  || fields.password.length < 1
                  || fields.passwordConfirm.length < 1
                }
                type="submit"
              >
                {translate('common:Confirm').toUpperCase()}
              </Fab>
            </CardBody>
          </Card>
        </form>
      </div>
    </>
  );
};

PasswordRedefiniton.propTypes = {
  classes: PropTypes.object.isRequired,
  errors: PropTypes.array,
};

PasswordRedefiniton.defaultProps = {
  errors: [],
};

const mapStateToProps = state => ({
  errors: state.errors.errors,
});

export default connect(
  mapStateToProps,
)(
  withStyles(styles)(
    (PasswordRedefiniton),
  ),
);

import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';
import PF2MTooltip from '~/components/PF2MTooltip';
import { useTranslation } from 'react-i18next';

const RenderDistanceManagerField = ({ transportReport }) => {
  const { t: translate } = useTranslation();

  const {
    automation_end_loading_was_auto: automationEndLoadingwasauto,
    automation_end_tipping_was_auto: automationEndTippingWasAuto,
    automation_loading_was_auto: automationLoadingWasAuto,
    automation_tipping_was_auto: automationTippingWasAuto,
    automation_toggle_is_disable: automationToggleIsDisable,
  } = transportReport;

  const automationToggleIsEnable = !automationToggleIsDisable;

  const hasData = automationToggleIsDisable === true || automationToggleIsDisable === false;

  let automationSteps = [
    {
      data: automationToggleIsEnable,
      tooltipTitle: translate('common:LocalAutomationEnable'),
      title: translate('common:LAE'),
    },
    {
      data: automationLoadingWasAuto,
      tooltipTitle: translate('common:LoadStart'),
      title: translate('common:LS'),
    },
    {
      data: automationEndLoadingwasauto,
      tooltipTitle: translate('common:LoadEnd'),
      title: translate('common:LE'),
    },
    {
      data: automationTippingWasAuto,
      tooltipTitle: translate('common:UnloadStart'),
      title: translate('common:US'),
    },
    {
      data: automationEndTippingWasAuto,
      tooltipTitle: translate('common:UnloadEnd'),
      title: translate('common:UE'),
    },
  ];

  automationSteps = automationSteps.map(({ data, title, tooltipTitle }, index) => {
    const type = (
      index === 0
        ? translate(`common:${data ? 'Yes' : 'No'}`)
        : translate(`common:${data ? 'Automatic' : 'Manual'}`)
    );

    const newTooltipTitle = `${tooltipTitle} ${type}`;

    return { data, title, tooltipTitle: newTooltipTitle };
  });

  return (
    !hasData
      ? null
      : (
        <>
          <div className="RenderAutomationSteps">
            {automationSteps.map(({ data, tooltipTitle, title }) => (
              <PF2MTooltip title={tooltipTitle} key={title}>
                <span
                  className="RenderAutomationStepsTag"
                  data-automation-step={data ? '1' : '0'}
                >
                  {title}
                </span>
              </PF2MTooltip>
            ))}
          </div>
        </>
      )
  );
};

RenderDistanceManagerField.propTypes = {
  transportReport: PropTypes.object,
};

RenderDistanceManagerField.defaultProps = {
  transportReport: {},
};

export default RenderDistanceManagerField;

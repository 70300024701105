/* eslint-disable no-param-reassign */
import * as types from './types';
import api from '~/services/api';
import { clearFileUploadError } from '../errors/actions';
import {
  exportData, importData, getTemplate, handleErrors,
} from '../common';

const getEndpoints = {
  rpmRanges: {
    url: '/telemetry/rpmranges',
    responseObject: 'rpm_ranges',
    typeAction: types.SET_RPM_RANGES_LIST,
  },
  hourmeterLimits: {
    url: '/telemetry/hourmetersettings',
    responseObject: 'telemetry_hourmeter_settings',
    typeAction: types.SET_HOURMETER_LIMITS_LIST,
  },
};

export const get = endpoint => async (dispatch) => {
  try {
    dispatch(clearFileUploadError());
    const {
      data: {
        result: { [endpoint.responseObject]: data = [] },
      },
    } = await api.get(endpoint.url);
    dispatch({
      type: endpoint.typeAction,
      payload: data,
    });
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const getRpmRanges = () => async (dispatch) => {
  const endpoint = getEndpoints.rpmRanges;
  return dispatch(get(endpoint));
};

export const getHourmeterLimits = () => async (dispatch) => {
  const endpoint = getEndpoints.hourmeterLimits;
  return dispatch(get(endpoint));
};

export const getAll = ({
  domain,
  clearUploadError = true,
  all = false,
}) => async (dispatch) => {
  try {
    if (clearUploadError) {
      dispatch(clearFileUploadError());
    }
    const endpoint = getEndpoints[domain];
    const endpointUrl = `${endpoint.url}${all ? '?all' : ''}`;
    const {
      data: {
        result: { [endpoint.responseObject]: data = [] },
      },
    } = await api.get(endpointUrl);
    dispatch({
      type: endpoint.typeAction,
      payload: data,
    });
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const exportRpmRanges = () => async dispatch => dispatch(exportData('telemetry/rpmranges'));
export const importRpmRanges = file => async dispatch => dispatch(importData('telemetry/rpmranges', file, getRpmRanges));
export const getRpmRangesTemplate = () => async dispatch => dispatch(getTemplate('telemetry/rpmranges'));
export const exportHourmeterLimits = () => async dispatch => dispatch(exportData('telemetry/hourmetersettings'));
export const importHourmeterLimits = file => async dispatch => dispatch(importData('telemetry/hourmetersettings', file, getHourmeterLimits));
export const getHourmeterLimitsTemplate = () => async dispatch => dispatch(getTemplate('telemetry/hourmetersettings'));
